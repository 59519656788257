/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';
import { useState } from 'react';

// import components below
import TabsUI from 'components/UI/TabsUI';
import OrderWorklist from './OrderWorklist';
import Clearance from './Clearance';

// main component
const PharmacyTabs = (props) => {
  const { onChange } = props;

  const [activeTab, setActiveTab] = useState('Order Worklist');

  const handleTabOnChange = (eventKey) => {
    setActiveTab(eventKey);
    onChange(eventKey);
  };

  return (
    <div className='PharmacyTabs'>
      <TabsUI>
        <Tabs
          defaultActiveKey='Order Worklist'
          onSelect={(eventKey) => handleTabOnChange(eventKey)}
        >
          <Tab eventKey='Order Worklist' title='Order Worklist' className='p-4'>
            <OrderWorklist />
          </Tab>
          <Tab eventKey='Over the Counter' title='Over the Counter' className='p-4'>
            Over the Counter
          </Tab>
          <Tab eventKey='Cancellation Request' title='Cancellation Request' className='p-4'>
            Cancellation Request
          </Tab>
          <Tab eventKey='Return Request' title='Return Request' className='p-4'>
            Return Request
          </Tab>
          <Tab eventKey='Inventory' title='Inventory' className='p-4'>
            Inventory
          </Tab>
          <Tab eventKey='Clearance' title='Clearance' className='p-4'>
            <Clearance />
          </Tab>
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default PharmacyTabs;
