/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';

// import components below
import CardUI from 'components/UI/CardUI';

// main component
const FilterCard = (props) => {
  const { object, state, setState } = props;
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    axios.get('/room').then((response) => {
      setRooms(response.data.data.room_list);
    });
  }, []);

  function status(title) {
    switch (title) {
      case 'Occupied':
        return 'occupied';
      case 'Available':
        return 'available';
      case 'For Cleaning':
        return 'cleaning';
      case 'Unavailable':
        return 'unavailable';
      default:
        return 'S';
    }
  }

  const handle = (title) => {
    if (title === 'See all beds') {
      setState(rooms);
    } else {
      const input = status(title);
      const newFilter = rooms?.filter((value) => {
        return value?.status === input;
      });
      setState(newFilter);
    }
  };

  return (
    <div className='filterCard'>
      <CardUI>
        <div className='card'>
          <div className='card__body' style={{ background: object.color }}>
            <span>{object.value}</span>
            {object.icon}
          </div>
          <div className='card__footer' style={{ borderColor: object.color }}>
            <span>{object.title}</span>
            <Button
              onClick={() => {
                handle(object.title);
              }}
            >
              {object.arrow}
            </Button>
          </div>
        </div>
      </CardUI>
    </div>
  );
};

export default FilterCard;
