// import core & vendor packages below
import { Modal, Button } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './SuccessModal.scss';
import { ReactComponent as SignupApprovedSVG } from 'assets/svg/signup_approved_modal.svg';

// main componet
const LoginModal = (props) => {
    const { status, onToggle } = props;

    return (
        <div className='loginModal'>
            <ModalUI>
                <Modal.Dialog>
                    <Modal.Header>
                        <h6>Login Success!</h6>
                        <Button onClick={() => onToggle(!status)}>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='loginModal__svgContainer'>
                            <SignupApprovedSVG />
                        </div>
                        <br></br>
                        <p>
                            Congratulations! Login Success
                        </p>
                    </Modal.Body>

                    <Modal.Footer>

                    </Modal.Footer>
                </Modal.Dialog>
            </ModalUI>
        </div>
    );
};

export default LoginModal;
