/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import './index.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormControl, Row } from 'react-bootstrap';

import { getAllSpecialties } from 'reducers/thunks/signupThunks';
import { selectSignup } from 'reducers/signupReducer';

const EmployeeProfile = ({ empData }) => {
  const dispatch = useDispatch();
  const signupStore = useSelector(selectSignup);
  const { data, subData } = signupStore;
  const [specialties, setSpecialties] = useState([]);

  const getSpecialties = () => {
    dispatch(getAllSpecialties({ per_page: 100000 /** , is_sub_position: false */ }));
  };

  renderWhenDataIsChanged(data, subData, setSpecialties, empData);
  renderOnFirstRun(getSpecialties);

  return (
    <div className='employee-profile-tab pt-3'>
      <Row className='bottom-line mb-4'>
        <Col md={12}>
          <h4 className='my-4'>EMPLOYEE DETAILS</h4>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label style={{ flex: 1 }}>User Name:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.attributes?.username || '-'}
              readOnly
            />
          </Form.Group>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label style={{ flex: 1 }}>Company Email Address:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.attributes?.email || '-'}
              readOnly
            />
          </Form.Group>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label style={{ flex: 1 }}>Contact Number:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.attributes?.contact_number || '-'}
              readOnly
            />
          </Form.Group>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label style={{ flex: 1 }}>Employment Type:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.attributes?.employment.employment_type || '-'}
              readOnly
            />
          </Form.Group>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label style={{ flex: 1 }}>Department:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.attributes?.department || '-'}
              readOnly
            />
          </Form.Group>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label style={{ flex: 1 }}>Role:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.attributes?.role || '-'}
              readOnly
            />
          </Form.Group>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label style={{ flex: 1 }}>Professional Fee (PHP):</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.attributes?.default_professional_fee || '-'}
              readOnly
            />
          </Form.Group>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label style={{ flex: 1 }}>Specialty:</Form.Label>
            <FormControl type='text' className='no-border' value={specialties || '-'} readOnly />
          </Form.Group>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label style={{ flex: 1 }}>Sub-Specialty:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.attributes?.sub_specialty_id || '-'}
              readOnly
            />
          </Form.Group>
          {/* <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>PRC Licensed Number:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.employee_detail?.prc_license_number || '-'}
              readOnly
            />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Designation:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.employee_detail?.designation || '-'}
              readOnly
            />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Department:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.attributes?.department || '-'}
              readOnly
            />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Contact Number:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.contact_number || '-'}
              readOnly
            />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Company Email Address:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.employee_detail?.company_email || '-'}
              readOnly
            />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Date Hired:</Form.Label>
            <FormControl
              type='text'
              className='no-border'
              value={empData?.employee_detail?.date_hired || '-'}
              readOnly
            />
          </Form.Group> */}
        </Col>
      </Row>
      {/*      <Row className='bottom-line mb-4'>
        <Col md={6}>
          <h4 className='my-4'>PERSONAL INFORMATION</h4>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Gender:</Form.Label>
            <FormControl type='text' className='no-border' value={gender[empData?.gender] || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Age:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.age || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Birthdate:</Form.Label>
            <FormControl type='text' className='no-border' value={moment(empData?.birthday).format('MMMM-DD-YYYY') || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Street Address:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.street_address || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>City:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.city || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>State / Province:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.state_province || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Postal Code:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.postal_code || '-'} readOnly />
          </Form.Group>
        </Col>
      </Row>
      <Row className='bottom-line mb-4'>
        <Col md={6}>
          <h4 className='my-4'>EDUCATION</h4>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Year Graduated:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.employee_education?.year_graduated || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Course Competed:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.employee_education?.course || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Educational Achievement:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.employee_education?.achievement || '-'} readOnly />
          </Form.Group>
        </Col>
      </Row>
      <Row className='bottom-line mb-4'>
        <Col md={6}>
          <h4 className='my-4'>EMERGENCY CONTACT DETAILS</h4>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Contact Person Name:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.employee_emergency_contact?.contact_name || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Relation:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.employee_emergency_contact?.relationship || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Contact Number:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.employee_emergency_contact?.contact_number || '-'} readOnly />
          </Form.Group>

          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>Address:</Form.Label>
            <FormControl type='text' className='no-border' value={empData?.employee_emergency_contact?.address || '-'} readOnly />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className='mb-2 inline-group date-cont'>
            <Form.Label>File Upload:</Form.Label>
            <FormControl type='text' className='no-border' placeholder='DETAILS HERE' />
          </Form.Group>
        </Col>
        <Col></Col>
      </Row> */}
    </div>
  );
};

const renderWhenDataIsChanged = (data, subData, setSpecialties, empData) => {
  useEffect(() => {
    async function fetchSpecialty() {
      if (data && data.length > 0) {
        const specialtyDesc = await data.map((item) => {
          return { id: parseInt(item.id), description: item.attributes.description };
        });

        setSpecialties(
          empData?.attributes?.specialty_id.map((i) => {
            const item = specialtyDesc.find((item) => item.id === i);
            return item?.description;
          }),
        );
      }
    }

    fetchSpecialty();
  }, [data]);
};

const renderOnFirstRun = (getSpecialties) => {
  useEffect(() => {
    getSpecialties();
  }, []);
};

export default EmployeeProfile;
