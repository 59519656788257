/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { selectPatientData } from 'reducers/patientReducer';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import './Filter.scss';
import { ReactComponent as AddGroupSVG } from 'assets/svg/add-group.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchPatientFilter = ({ setState }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectPatientData);
  const [patients, setPatient] = useState([]);

  const [searchData, setSearchData] = useState({
    id: '',
    patient_name: '',
    date_admitted: '',
    currently_admitted: false,
    currently_er_patient: false,
  });

  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;

    const newFilter = data.filter((value) => {
      return (
        value.full_name.toLowerCase().includes(searchedWord.toLowerCase()) ||
        value.MRN.toLowerCase().includes(searchedWord.toLowerCase())
      );
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  const handleChange = (key) => (e) => {
    if (e.target.type === 'checkbox') {
      setSearchData((prevState) => {
        return {
          ...prevState,
          [key]: e.target.checked,
        };
      });

      return;
    }

    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const search = () => {
    /*  axios
      .get('/patient', {
        params: {
          MRN: searchData.id,
          full_name: searchData.patient_name,
          admission_date: searchData.date_admitted,
        },
      })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      }); */
    const newFilter = data.filter((value) => {
      return (
        (!searchData.patient_name
          ? value?.full_name
          : value?.full_name.toLowerCase().includes(searchData.patient_name.toLowerCase())) &&
        (!searchData.id ? value?.MRN : value?.MRN.includes(searchData.id)) &&
        (!searchData.date_admitted
          ? value?.admission_date
          : value?.admission_date.includes(searchData.date_admitted)) &&
        (!searchData.currently_admitted
          ? value?.patient_status
          : value?.patient_status.includes('admitted')) &&
        (!searchData.currently_er_patient
          ? value?.patient_status
          : value?.patient_status.includes('emergency'))
      );
    });

    setState(newFilter);
    return;
  };

  useEffect(() => {
    axios
      .get('/patient', {
        params: {
          patient_status: ['for_admission', 'admitted'],
        },
      })
      .then((response) => {
        setState(response.data.data);
        setPatient(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='searchPatientFilter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Basic Search</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  placeholder='Search via Last Name or PIN…'
                  value={searchData.basic_search}
                  onChange={onChangeHandler}
                />
                <Button variant='search'>SEARCH</Button>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <div className='buttonWrapper'>
              {/* <Link */}
              {/*  className='btn btn-dark-blue me-3' */}
              {/*  to='/dashboard/admissions/create-temporary-profile' */}
              {/* > */}
              {/*  <AddGroupSVG /> */}
              {/*  <span>Create Temporary Profile</span> */}
              {/* </Link> */}
              {/* <Link
                    className='btn btn-light-blue'
                    to='/dashboard/admissions/create-new-patient'
                  >
                    <AddGroupSVG />
                    <span>Register New Patient</span>
                  </Link> */}
            </div>
          </Col>
        </Row>

        {/* Advanced Search */}
        <Row>
          <Col>
            <Accordion className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Advance Search</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={3}>
                      <Form.Group className='mb-4'>
                        <Form.Label>MRN / PIN</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Enter Here'
                          value={searchData.id}
                          onChange={handleChange('id')}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Patient Name</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Enter Patient Name'
                          value={searchData.patient_name}
                          onChange={handleChange('patient_name')}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className='mb-4'>
                        <Form.Label>Date Admitted</Form.Label>
                        <FormControl
                          type='date'
                          className='bg-white'
                          placeholder='Enter Here'
                          value={searchData.date_admitted}
                          onChange={handleChange('date_admitted')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <div className='buttonWrapper'>
                      <Form.Check
                        type={'checkbox'}
                        id={`admitted`}
                        label={`Currently Admitted`}
                        value={searchData.currently_admitted}
                        onChange={handleChange('currently_admitted')}
                      />
                      <Form.Check
                        type={'checkbox'}
                        id={`er-patient`}
                        label={`Currently an ER Patient`}
                        value={searchData.currently_er_patient}
                        onChange={handleChange('currently_er_patient')}
                      />

                      <Button variant='search' onClick={search}>
                        SEARCH
                      </Button>

                      <Button
                        variant='clear'
                        onClick={() => {
                          setState(data);
                          setSearchData({
                            id: '',
                            patient_name: '',
                            date_admitted: '',
                            currently_admitted: false,
                            currently_er_patient: false,
                          });
                        }}
                      >
                        CLEAR
                      </Button>
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

SearchPatientFilter.defaultProps = {
  setState: () => {},
};

SearchPatientFilter.propTypes = {
  setState: PropTypes.func,
};

export default SearchPatientFilter;
