/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// import assets below
import './style.scss';
import FormUI from 'components/UI/FormUI';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const ModalAddRecord = ({ showModal, setShowModal }) => {
  const [userData, setUserData] = useState({
    id: '',
    donors_id: '',
    blood_type: '',
    donation_date: '',
    no_of_bags: '',
    bag_type: '',
    expiration: '',
    serial_number: '',
    status: '',
    donation_place: '',
    pathologist: '',
  });

  const handleChange = (key) => (e) => {
    if (e.target.type === 'radio') {
      setUserData({
        ...userData,
        [key]: e.target.value,
      });

      return;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  // Submit data here
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      size='lg'
      show={showModal.addModal}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            addModal: false,
          };
        })
      }
      aria-labelledby='Add Diagnosis'
      className='case-history-modal'
    >
      <Modal.Body>
        <div className='case-history-request'>
          <div className='d-flex header'>
            <p>Case History</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowModal((prevState) => {
                  return { ...prevState, addModal: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>ID</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.id}
                    onChange={handleChange('id')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Donors ID</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.donors_id}
                    onChange={handleChange('donors_id')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Blood Type</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.blood_type}
                    onChange={handleChange('blood_type')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Donation Date</Form.Label>
                  <FormControl
                    type='date'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.donation_date}
                    onChange={handleChange('donation_date')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>No. of Bags</Form.Label>
                  <FormControl
                    type='number'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.no_of_bags}
                    onChange={handleChange('no_of_bags')}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className=''>
                  <Form.Label>Bag Type</Form.Label>
                  <Form.Select
                    aria-label='Doctor'
                    value={userData.bag_type}
                    onChange={handleChange('bag_type')}
                  >
                    <option value='' hidden>
                      - Select -
                    </option>
                    <option value='1'>One</option>
                    <option value='2'>Two</option>
                    <option value='3'>Three</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Expiration</Form.Label>
                  <FormControl
                    type='date'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.expiration}
                    onChange={handleChange('expiration')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Serial Number</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.serial_number}
                    onChange={handleChange('serial_number')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Status</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.status}
                    onChange={handleChange('status')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Donation Place</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.donation_place}
                    onChange={handleChange('donation_place')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Pathologist</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.pathologist}
                    onChange={handleChange('pathologist')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    type='submit'
                    onClick={(e) => {
                      handleSubmit(e);
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          addModal: false,
                          showView: true,
                        };
                      });
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() =>
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          addModal: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddRecord;
