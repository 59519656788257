import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import EmergencyDepartmentTabs from './components/Tabs';
import EmergencyDepartmentHeader from './components/PageHeader';

// import assets below
import './style.scss';

// main component
const EmergencyDepartment = () => {
  const [activeTab, setActiveTab] = useState('Search Patient');

  return (
    <Layout pageTitle='Emergency Department'>
      <EmergencyDepartmentHeader active={activeTab} />
      <EmergencyDepartmentTabs onChange={setActiveTab} />
    </Layout>
  );
};

export default EmergencyDepartment;
