/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

import Table from 'components/ReactTable';
import LeaveCreditsModal from './components/Modal';

import './index.scss';
import { ReactComponent as UploadSVG } from 'assets/svg/upload.svg';

const state = [
  {
    id: '001',
    employee_name: 'Juan Dela Cruz',
    available_credits: '10',
    used_credits: '10',
  },
  {
    id: '002',
    employee_name: 'Juan Dela Cruz',
    available_credits: '10',
    used_credits: '10',
  },
];

const index = () => {
  const [openModal, setOpenModal] = useState();

  const tableColumns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Employee Name', accessor: 'employee_name' },
      { Header: 'Available Credits', accessor: 'available_credits' },
      { Header: 'Used Credits', accessor: 'used_credits' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        used_credits: dd?.used_credits,
        employee_name: dd?.employee_name,
        available_credits: dd?.available_credits,
      })),
    [state],
  );

  return (
    <div className='leave-credits'>
      <div className='header'>
        <h2>EMPLOYEE LIST</h2>

        <div className='functions'>
          <Form.Group className='inline-group'>
            <Form.Label>Search:</Form.Label>

            <FormControl className='seven' placeholder='Enter Here' />
          </Form.Group>

          <Button className='upload'>
            <UploadSVG />
            Upload Excel
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <span
                className='badge green'
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  setOpenModal({
                    data: data,
                    isOpen: true,
                  })
                }
              >
                Leave Credits
              </span>
            );
          }}
        />
      </div>

      <LeaveCreditsModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default index;
