// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from 'components/UI/TabsUI';
import OrdersTab from './Orders';

// import assets below
import './Tabs.scss';

// main component
const PatientProfileTabs = ({ showProfile, setShowProfile }) => {
  const [activeKey, setActiveKey] = useState();
  const { setActive } = showProfile;

  useEffect(() => {

    if (setActive && setActive === '') {
      setActiveKey('Orders');
    } else {
      setActiveKey(setActive);
    }
  }, [setActive]);

  return (
    <div className='worklistOrdersTab'>
      <TabsUI>
        <Tabs defaultActiveKey='Orders' activeKey={activeKey} onSelect={(tab) => setActiveKey(tab)}>
          <Tab
            eventKey='Orders'
            title='Orders'
            className='orders-main-tab'
          >
            <OrdersTab showProfile={showProfile} setShowProfile={setShowProfile} />
          </Tab>
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default PatientProfileTabs;
