/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import Table from 'components/ReactTable';
import {
  getPriceLogs,
  getGroupItems,
  resetPriceLogs,
  selectInventory,
} from 'reducers/inventoryReducer';

// import assets below
import './style.scss';
import 'react-tagsinput/react-tagsinput.css';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const PriceLogs = ({ showProfile, setShowProfile, getInventoryItems }) => {
  const dispatch = useDispatch();

  const inventoryState = useSelector(selectInventory);
  const { inventoryGroupData, priceLogsItems, priceLogs } = inventoryState;

  const [search, setSearch] = useState({
    q: '',
    group_id: '',
  });

  const [stateData, setStateData] = useState([]);

  const closeModal = () => {
    setShowProfile((prev) => ({ ...prev, priceLogs: false }));
  };

  const handleSearch = () => {
    const params = {
      q: search?.q,
      group_id: search?.group_id,
    };

    dispatch(getPriceLogs(params));
  };

  const handleReset = () => {
    setSearch({
      q: '',
      group_id: '',
    });

    dispatch(getPriceLogs());
  };

  const handleSearchChange = (e) => {
    setSearch((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const getPriceLogsData = () => {
    dispatch(getPriceLogs());
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Changed By', accessor: 'changed_by' },
      { Header: 'Item Code', accessor: 'code' },
      { Header: 'Item Name', accessor: 'name' },
      { Header: 'Item Group', accessor: 'item_group' },
      { Header: 'Date Adjusted', accessor: 'date_adjusted' },
      { Header: 'Old Piece Price', accessor: 'old_piece_price' },
      { Header: 'New Piece Price', accessor: 'new_piece_price' },
      { Header: 'Old Box Price', accessor: 'old_box_price' },
      { Header: 'New Box Price', accessor: 'new_box_price' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      stateData?.map((dd) => {
        const itemID = dd?.attributes?.item_id;
        const itemData = priceLogsItems.find((dd) => +dd?.id === +itemID && dd?.type === 'items');

        const groupID = itemData?.attributes?.group_id;
        const groupData = priceLogsItems.find(
          (dd) => +dd?.id === +groupID && dd?.type === 'groups',
        );

        return {
          data: dd,
          id: dd?.id,
          code: itemData?.attributes?.code,
          name: itemData?.attributes?.name,
          item_group: groupData?.attributes?.name,
          changed_by: dd?.attributes?.changed_by,
          old_box_price: dd?.attributes?.old_box_price,
          new_box_price: dd?.attributes?.new_box_price,
          old_piece_price: dd?.attributes?.old_piece_price,
          new_piece_price: dd?.attributes?.new_piece_price,
          date_adjusted: moment(dd?.attributes?.created_at).format('MMMM Do YYYY'),
        };
      }),
    [stateData],
  );

  runOnModalShow(dispatch, showProfile, getPriceLogsData);

  runAfterAPICall(inventoryState, dispatch, showProfile, setStateData, resetPriceLogs);

  useEffect(() => {
    // console.log('stateData', stateData);
  }, [stateData]);

  return (
    <Modal
      size='lg'
      onHide={closeModal}
      className='price-logs-modal'
      show={showProfile.priceLogs}
      aria-labelledby='ITEM DETAILS'
    >
      <Modal.Body>
        <div className='price-logs-request'>
          <div className='d-flex header'>
            <p>PRICE LOGS</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={closeModal} />
          </div>
        </div>

        <FormUI>
          <Form className='price-logs-form'>
            <Row className='mb-4'>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Item Code / Name</Form.Label>
                  <FormControl
                    type='text'
                    name='q'
                    value={search?.q}
                    onChange={handleSearchChange}
                    className='bg-white'
                    placeholder='Search Here'
                  />
                </Form.Group>
              </Col>
              {/* <Col sm={2}>
                <Form.Group>
                  <Form.Label>Item Group</Form.Label>
                  <Form.Select
                    name='group_id'
                    value={search?.group_id}
                    onChange={handleSearchChange}
                  >
                    <option value='' hidden>
                      Select Here
                    </option>
                    {inventoryGroupData &&
                      inventoryGroupData.length > 0 &&
                      inventoryGroupData.map((data) => {
                        return (
                          <option key={data?.id} value={data?.id}>
                            {data?.attributes?.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              </Col> */}
              <Col>
                <div className='buttonWrapper'>
                  <Button variant='search' onClick={handleSearch}>
                    APPLY
                  </Button>

                  <Button variant='clear' onClick={handleReset}>
                    RESET
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Table
                  logs={priceLogs}
                  data={tableData}
                  columns={tableColumns}
                  pagination={['BOTTOM']}
                />
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const runOnModalShow = (dispatch, showProfile, getPriceLogsData) => {
  useEffect(() => {
    if (showProfile?.priceLogs) {
      dispatch(getGroupItems('?q=&per_page=100'));
      getPriceLogsData();
    }
  }, [showProfile]);
};

const runAfterAPICall = (inventoryState, dispatch, showProfile, setStateData, resetPriceLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed, error } = inventoryState.priceLogs;
    const { priceLogsData } = inventoryState;

    if (isSuccess && showProfile?.priceLogs) {
      setStateData(priceLogsData);
      // dispatch(resetPriceLogs());
    }

    if (isFailed) {
      toast.error(error);
      dispatch(resetPriceLogs());
    }
  }, [inventoryState.priceLogs]);
};

PriceLogs.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

PriceLogs.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default PriceLogs;
