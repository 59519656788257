import { Button, Col, Row } from 'react-bootstrap';

const PrintForm = ({ setShowModal, soaData }) => {
    let printSoa = soaData?.bill?.bills_payments.length > 0 ? soaData?.bill?.bills_payments : [];
    printSoa = printSoa.slice(-1)[0];

    const handleShow = () => setShowModal({ isPartial: false, print: false });
    return (
        <Row className='print container d-flex align-items-center justify-content-center"'>
            <Row>
                <Col className='center-block  mb-3'>
                    <center>
                        <span className='fw-bolder fs-4'>{printSoa?.name_of_payor}</span>
                        <span className='fs-6'>Name of Payor</span>
                    </center>
                </Col>
            </Row>
            <Row>
                <Col className='center-block mb-4'>
                    <center>
                        <span className='fw-bolder fs-4'>{printSoa?.payment_method}</span>
                        <span className='fs-6'>Payment Method</span>
                    </center>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col className='fw-bolder'>
                    AMOUNT RENDERED: Php {soaData?.totalGrossBill}
                </Col>
                <Col className='fw-bold'>
                    AMOUNT DEDUCTED: PHP {soaData?.totalAdjustments}
                </Col>
            </Row>
            <Row>
                <Col className='center-block mt-4 mb-4 fw-bolder'>
                    <center>
                        <span><b>REMAINING BALANCE:</b> PHP {soaData?.totalNetBill}</span>
                    </center>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className='me-3 btn btn-light-green' type='button'>
                        PRINT
                    </Button>
                    <Button className='btn-clear' type='button' onClick={handleShow}>
                        Cancel
                    </Button></Col>
            </Row>
        </Row>
    );
};

export default PrintForm;