/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getDepartmentInventory = createAsyncThunk(
  'department/getDepartmentInventory',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/department/inventory/${params?.id}`,
      params: params?.payload,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const submitReturnItems = createAsyncThunk(
  'department/submitReturnItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/return`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateReturnItems = createAsyncThunk(
  'department/updateReturnItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `api/return/${params?.id}`,
      data: params?.payload,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getReturnTableList = createAsyncThunk(
  'department/getReturnTableList',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/return`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getSingleReturnList = createAsyncThunk(
  'department/getSingleReturnList',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `/api/return/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const completeReturnItem = createAsyncThunk(
  'department/completeReturnItem',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/return/complete/${params?.id}`,
      data: params?.payload,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteRequestReturn = createAsyncThunk(
  'department/deleteRequestReturn',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'delete',
      url: `api/return/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
