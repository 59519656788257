/* eslint-disable no-unused-vars */
import { Tabs, Tab } from 'react-bootstrap';
import { useState } from 'react';

// import components below
import TabsUI from 'components/UI/TabsUI';
import AddFinancialStatement from './components/AddFinancialStatement';
import IncomeStatement from './components/IncomeStatement';
import CashFlowStatement from './components/CashFlowStatement';

// CSS
import './index.scss';

// main component
const Reports = () => {
  const [activeTab, setActiveTab] = useState('Add Financial Statement');

  const handleTabOnChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  return (
    <div className='financial-statement'>
      <TabsUI>
        <Tabs
          defaultActiveKey='Financial Statement'
          onSelect={(eventKey) => handleTabOnChange(eventKey)}
        >
          <Tab eventKey='Financial Statement' title='Financial Statement'>
            <AddFinancialStatement />
          </Tab>
          <Tab eventKey='Income Statement' title='Income Statement'>
            <IncomeStatement />
          </Tab>
          <Tab eventKey='Cash Flow Statement' title='Cash Flow Statement'>
            <CashFlowStatement />
          </Tab>
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default Reports;
