/* eslint-disable */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from 'components/UI/TabsUI';
import SearchPatient from './NewSearchPatient';
import FormCreateNewPatient from 'components/FormCreateNewPatient';
import InventoryPerDepartment from 'components/InventoryPerDepartment';
import EDWorkList from './EDWorklist';

// main component
const EmergencyDepartmentTabs = ({ onChange }) => {
  const [activeTab, setActiveTab] = useState('Search Patient');

  const handleTabOnChange = (eventKey) => {
    setActiveTab(eventKey);
    onChange(eventKey);
  };

  const tabs = [
    {
      title: 'Patient List',
      component: SearchPatient,
      componentProps: { handleTabOnChange },
    },
    // {
    //   title: 'ED Registration',
    //   component: FormCreateNewPatient
    // },
    {
      title: 'Triage',
      component: EDWorkList,
    },
    {
      title: 'Inventory',
      component: InventoryPerDepartment,
    },
  ];

  return (
    <div className='EmergencyDepartmentTabs'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title} onSelect={(eventKey) => handleTabOnChange(eventKey)}>
          {tabs.map(({ title, component: Component, componentProps }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component departmentID={'5'} {...componentProps} />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

EmergencyDepartmentTabs.defaultProps = {
  onChange: () => {},
};

EmergencyDepartmentTabs.propTypes = {
  onChange: PropTypes.func,
};

export default EmergencyDepartmentTabs;
