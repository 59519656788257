/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getAppointment = createAsyncThunk(
  'appointment/getAppointment',
  async (filter = {}, { rejectWithValue }) => {
    return await axios
      .get('/appointment', {
        params: {
          appointment_date: filter?.appointment_date,
          name_of_doctor: filter?.name_of_doctor,
          services_type: filter?.services_type,
          department: filter?.department,
          full_name: filter?.full_name,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showAppointment = createAsyncThunk(
  'appointment/showAppointment',
  async (id = 0, { rejectWithValue }) => {
    return await axios
      .get(`/appointment/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createAppointment = createAsyncThunk(
  'appointment/createAppointment',
  async (payload, { rejectWithValue }) => {
    return await axios
      .post('/appointment', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateAppointment = createAsyncThunk(
  'appointment/updateAppointment',
  async (payload, { rejectWithValue }) => {
    return await axios
      .put('/appointment', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const setAppointmentComType = createAsyncThunk(
  'appointment/setAppointmentComType',
  async (payload, { rejectWithValue }) => {
    return await axios
      .post('/appointment/set_communication_type', payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
