/* eslint-disable */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import SearchRoom from './SearchRoom';
import HouseKeeping from './HouseKeeping';
import TabsUI from 'components/UI/TabsUI';
import InventoryPerDepartment from 'components/InventoryPerDepartment';

// import assets
import './Tabs.scss';

// main component
const RoomBedTabs = ({ onChange }) => {
  const [activeTab, setActiveTab] = useState('Bed Management');

  const handleTabOnChange = (eventKey) => {
    setActiveTab(eventKey);
    onChange(eventKey);
  };

  const tabs = [
    {
      title: 'Bed Management',
      component: SearchRoom,
    },
    {
      title: 'Housekeeping',
      component: HouseKeeping,
    },
    {
      title: 'Inventory',
      component: InventoryPerDepartment,
    },
  ];

  return (
    <div className='RoomBedTabs'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title} onSelect={(eventKey) => handleTabOnChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component departmentID={'4'} />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

RoomBedTabs.defaultProps = {
  onChange: () => {},
};

RoomBedTabs.propTypes = {
  onChange: PropTypes.func,
};

export default RoomBedTabs;
