/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Popover,
  Dropdown,
  Pagination,
  OverlayTrigger,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

// import components below
import Table from 'components/ReactTable';
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';
import { ReactComponent as ThreeDotsSVG } from 'assets/svg/three-dots-svgrepo-com.svg';
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/angle-left.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/svg/angle-right.svg';
import { ReactComponent as EyeIcon } from 'assets/svg/eye-icon.svg';
import { ReactComponent as CartIcon } from 'assets/svg/cart-plus.svg';

const filterList = [
  { id: 1, key: 'all', name: 'All Patient' },
  {
    id: 2,
    key: 'triage',
    name: 'Triage',
  },
  {
    id: 3,
    key: 'awaiting_bed',
    name: 'Awating Bed',
  },
  {
    id: 5,
    key: 'for_admission',
    name: 'For Admission',
  },
  {
    id: 4,
    key: 'for_discharge',
    name: 'For Discharge',
  },
];

// main component
const WorklistTable = ({ state, setState, setShowProfile }) => {
  const [lengthOfStay, setLengthOfStay] = useState('');
  const [active, setActive] = useState('all');
  const [allData, setAllData] = useState([]);

  const calculateDayDiff = (admission_date) => {
    console.log(admission_date);
    if (admission_date && admission_date.length > 0) {
      const date = new Date();
      date.setDate(date.getDate());

      const date1 = new Date(admission_date);
      const date2 = new Date(date);
      const diffTime = Math.abs(date2 - date1);
      setLengthOfStay(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'ROOM BED', accessor: 'room_bed' }, // accessor is the "key" in the data
      { Header: 'PATIENT ID', accessor: 'patient_id' },
      { Header: 'PATIENT NAME', accessor: 'patient_name' },
      { Header: 'DATE OF BIRTH', accessor: 'date_of_birth' },
      { Header: 'AGE / SEX', accessor: 'age_sex' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state
        ? state?.map((dd) => ({
            data: dd,
            patient_id: dd?.id,
            patient_name: dd?.full_name,
            date_of_birth: dd?.date_of_birth,
            age_sex: `${dd?.age} / ${dd?.sex}`,
            room_bed: dd?.assigned_room?.room?.bed ? dd?.assigned_room?.room?.bed : '-',
          }))
        : null,
    [state],
  );

  const handleOnClickFilter = (status) => {
    let filteredData = allData;

    if (status !== 'all') {
      filteredData = allData?.filter((dd) => {
        if (dd?.er_status) {
          return dd?.er_status?.replace(' ', '_').toLowerCase() === status;
        }
      });
    }

    setState(filteredData);
    setActive(status);
  };

  const filterlistContainer = filterList.map((list) => (
    <Button
      key={list.key}
      className={list.key === active ? 'active' : ''}
      onClick={() => {
        setState((prev) => {
          return { ...prev, table_active_status: list.key };
        });
        handleOnClickFilter(list.key);
      }}
    >
      {list.name}
    </Button>
  ));

  useEffect(() => {
    axios
      .get('/medication')
      .then((response) => {
        setState(response.data.data);
        setAllData(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='worklist-action-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        pagination={['BOTTOM']}
        filter={{
          top: <div className='buttons flex-nowrap'>{filterlistContainer}</div>,
        }}
        customAction={(id, data) => (
          <OverlayTrigger
            trigger='focus'
            placement='bottom'
            overlay={
              <Popover id='popover-positioned-bottom'>
                <Popover.Body className='d-flex justify-content-between p-0'>
                  <CartIcon
                    className='p-1 text-success'
                    style={{ width: '2em', cursor: 'pointer' }}
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          showWorkList: false,
                          showAddOrder: true,
                          showProfile: false,
                          profileData: data,
                          profileId: data.id,
                        };
                      });
                    }}
                  />
                  <EyeIcon
                    className='p-1 text-success'
                    style={{ width: '2em', cursor: 'pointer' }}
                    onClick={() => {
                      console.log('data', data);
                      console.log('id', id);
                      return;

                      // eslint-disable-next-line no-unreachable
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          showWorkList: false,
                          showAddOrder: false,
                          showProfile: true,
                          profileData: data,
                          profileId: data.id,
                          admissionDays: calculateDayDiff(data?.admission_date),
                        };
                      });
                    }}
                  />
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant='secondary' className='popoverButton bg-transparent p-0 border-0'>
              <ThreeDotsSVG style={{ width: '1.5em' }} />
            </Button>
          </OverlayTrigger>
        )}
      />
    </div>
  );
};

export default WorklistTable;
