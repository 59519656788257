/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

// Packages
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, FormControl, Accordion } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import StockCheckFilterTable from './Table';

// Assets
import './Filter.scss';

// Main component
const StockCheckFilter = ({ setState, setShowProfile, showProfile }) => {
  return (
    <div className='StockCheckFilter'>
      <FormUI>
        <Row>
          <Col className='create-button'>
            <Button
              onClick={() => {
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    showScreen: 2,
                  };
                });
              }}
            >
              Create New Stock Check
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion activeKey='0' defaultActiveKey='0' className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Stock Check</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>Current Store</Form.Label>
                        <Form.Select>
                          <option>Main Warehouse</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>Stock Check No.</Form.Label>
                        <FormControl type='text' className='bg-white' placeholder='Search Here' />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Stock Check Date From</Form.Label>
                        <FormControl type='date' className='bg-white' placeholder='Search Here' />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>Stock Check Date To</Form.Label>
                        <FormControl type='date' className='bg-white' placeholder='Search Here' />
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className='buttonWrapper mb-4'>
                        {/* <Form.Check type={'checkbox'} id={`admitted`} label={`Currently Admitted`} />
                      <Form.Check type={'checkbox'} id={`er-patient`} label={`Currently an ER Patient`} /> */}

                        <Button variant='search'>GO</Button>

                        <Button variant='clear'>RESET</Button>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <StockCheckFilterTable setState={setState} showProfile={showProfile} setShowProfile={setShowProfile} />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

StockCheckFilter.defaultProps = {
  setState: () => {},
  setShowProfile: () => {},
  showProfile: {},
};

StockCheckFilter.propTypes = {
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
  showProfile: PropTypes.objects,
};

export default StockCheckFilter;
