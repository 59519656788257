// import core & vendor packages below
import { Container, Row } from 'react-bootstrap';

// main components below
import Layout from 'components/Layout';
import Profile from './Profile';
import CreateTemporaryProfileHeader from './PageHeader';
// import EditProfile from 'pages/ACL/components/FormEditProfile';

// main component
const EditAccountProfile = () => {
  return (
    <Layout pageTitle='Edit Account Profile'>
      <CreateTemporaryProfileHeader />
      <Container fluid>
        <Row>
          {/* <EditProfile /> */}
          <Profile />
        </Row>
      </Container>
    </Layout>
  );
};

export default EditAccountProfile;
