/* eslint-disable */
/* eslint-disable camelcase */
// import core & vendor packages 
import React from 'react';
import { toast } from 'react-toastify';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';

// import components 
import FormUI from 'components/UI/FormUI';

// import assets 
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const ApproveModal = ({ showProfile, setShowProfile, state, approveRestockById }) => {
  return (
    <Modal
      show={showProfile?.isApproveRequestModal}
      onHide={() => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isApproveRequestModal: false,
          };
        });
      }}
      aria-labelledby='Request Item Modal'
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>Confirmation</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    isApproveRequestModal: false,
                  };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='remove-request-form'>
            <Row>
              <Col>
                <h3 className='mb-4'>Do you want to approve this request?</h3>

                <Form.Group className='buttons'>
                  <Button
                    className='yes-button'
                    onClick={() => {
                      approveRestockById(state?.id);
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          isApproveRequestModal: false,
                        };
                      });
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() =>
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          isApproveRequestModal: false,
                        };
                      })
                    }
                    className='no-button'
                  >
                    No
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ApproveModal;
