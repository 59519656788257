/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getEquipments = createAsyncThunk(
  'department/getEquipments',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/items`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getBorrowedList = createAsyncThunk(
  'department/getBorrowedList',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/borrow`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getSingleBorrowedData = createAsyncThunk(
  'department/getSingleBorrowedData',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/borrow/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const submitBorrowItem = createAsyncThunk(
  'department/submitBorrowItem',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/borrow`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateBorrowItem = createAsyncThunk(
  'department/updateBorrowItem',
  async (payload, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `api/borrow/${payload?.id}`,
      data: payload?.params,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const returnItem = createAsyncThunk(
  'department/returnItem',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/borrow/return/${params?.id}`,
      data: params?.payload,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const completeItem = createAsyncThunk(
  'department/completeItem',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/borrow/complete/${params?.id}`,
      data: params?.payload,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
