/* eslint-disable */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useEffect } from 'react';
import Table from 'components/ReactTable';
import moment from 'moment';
import axios from 'axios';

// import assets below
import './Table.scss';

// Helpers
import { patientStatus } from 'helpers';

// main component
const PatientTable = ({ state, setState, setShowProfile }) => {

  useEffect(() => {
    axios
      .get('/patient',
        {
          params: {
            patient_status: ['new', 'for_discharge'],
          },
        })
      .then((response) => {
        setState(response.data.data);
        // setState(response.data.data.filter((data) => data.patient_origin !== 'emergency'));
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Date/Time', accessor: 'date_time' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Patient ID', accessor: 'id' },
      { Header: 'Patient MRN', accessor: 'mrn' },
      { Header: 'Age/Sex', accessor: 'age_sex' },
      { Header: 'Status', accessor: 'status' },
    ],
    []
  )

  const tableData = React.useMemo(
    () => state?.map(dd => ({
      date_time: moment(dd.createdAt).format('MMMM-DD-YYYY hh:mm A'),
      name: dd?.full_name,
      id: dd?.id,
      mrn: dd?.MRN,
      age_sex: `${dd?.age}/${dd?.sex}`,
      status: patientStatus(dd?.patient_status),
    })),
    [state]
  )

  const handleTableAction = (eventKey, id) => {
    const action = {
      profile: () => {
        setShowProfile({ isShowProfile: true, profileId: id })
      },
      view: () => { },
      edit: () => { },
      delete: () => { }
    }

    action[eventKey]()
  }

  return (
    <div className='outpatient-search-patient-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        pagination={['BOTTOM']}
        onAction={handleTableAction}
        actions={{ hasProfile: true }}
      />
    </div>
  );
};

export default PatientTable;
