/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getPatient } from 'reducers/patientReducer';
import { selectPatientData } from 'reducers/patientReducer';
import { getBilling, selectBillingData } from 'reducers/billingReducer';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import './Filter.scss';
import { ReactComponent as AddGroupSVG } from 'assets/svg/add-group.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchPatientFilter = ({ setState, state }) => {
  const dispatch = useDispatch();

  const [searchData, setSearchData] = useState({
    id: '',
    patient_name: '',
    date_admitted: '',
    currently_admitted: false,
    currently_er_patient: false,
  });

  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    dispatch(getBilling(searchedWord));
  };

  const handleChange = (key) => (e) => {
    if (e.target.type === 'checkbox') {
      setSearchData((prevState) => {
        return {
          ...prevState,
          [key]: e.target.checked,
        };
      });

      return;
    }

    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const search = () => {
    axios
      .get('/patient', {
        params: {
          MRN: searchData.id,
          full_name: searchData.patient_name,
          admission_date: searchData.date_admitted,
        },
      })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  return (
    <div className='searchPatientFilter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Basic Search</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  placeholder='Search via Last Name or PIN…'
                  value={searchData.basic_search}
                  onChange={onChangeHandler}
                />
                <Button variant='search'>SEARCH</Button>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

SearchPatientFilter.defaultProps = {
  state: [],
  setState: () => {},
};

SearchPatientFilter.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
};

export default SearchPatientFilter;
