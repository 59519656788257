/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';

// import components below
import Shifting from './Shifting';
import TabsUI from 'components/UI/TabsUI';
import UserAccounts from './UserAccounts';
import EmployeesList from './EmployeesList';
import InventoryPerDepartment from 'components/InventoryPerDepartment';

import './Tabs.scss';
import 'react-calendar/dist/Calendar.css';

// main component
const EmployeesTabs = ({ onChange, setEmpData, setActiveScreen, activeTab, setActiveTab }) => {
  return (
    <div className='EmployeesTabs'>
      <Row>
        <Col>
          <TabsUI>
            <Tabs
              defaultActiveKey='Employees List'
              activeKey={activeTab}
              onSelect={(eventKey) => setActiveTab(eventKey)}
            >
              <Tab
                eventKey='Employees List'
                title='Employees List'
                className='p-4'
              >
                <EmployeesList
                  setEmpData={setEmpData}
                  setActiveScreen={setActiveScreen}
                  setActiveTab={setActiveTab}
                />
              </Tab>
              <Tab eventKey='Shifting' title='Shifting' className='p-4'>
                <Shifting
                  setEmpData={setEmpData}
                  setActiveScreen={setActiveScreen}
                  setActiveTab={setActiveTab}
                />
              </Tab>
              <Tab eventKey='Inventory' title='Inventory' className='p-4'>
                <InventoryPerDepartment departmentID='8' />
              </Tab>
              {/* <Tab eventKey='Pending Accounts' title='Pending Accounts' className='p-4'>
                <UserAccounts />
              </Tab> */}
            </Tabs>
          </TabsUI>
        </Col>
      </Row>
    </div>
  );
};

EmployeesTabs.defaultProps = {
  onChange: () => {},
  setActiveTab: () => {},
};

EmployeesTabs.propTypes = {
  onChange: PropTypes.func,
  setActiveTab: PropTypes.func,
};

export default EmployeesTabs;
