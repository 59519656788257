/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import { getDisposalList, selectFixedAssets, getSingleDisposalData, addDisposalItems } from 'reducers/fixedAssetsReducer';

// import assets below
import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

// main comnponent

const index = ({ setShowScreen, setRequestData }) => {
  const dispatch = useDispatch();

  const fixedAssetsState = useSelector(selectFixedAssets);
  const { disposalData, disposalItems, disposalLogs } = fixedAssetsState;

  const [tableList, setTableList] = useState([]);
  const [itemList, setItemList] = useState([]);
  
  const tableColumns = React.useMemo(
    () => [
      { Header: 'Date', accessor: 'date' },
      { Header: 'Transaction Type', accessor: 'transaction_type' },
      { Header: 'Account', accessor: 'account' },
      { Header: 'Value Model', accessor: 'value_model' },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Debit', accessor: 'debit' },
      { Header: 'Credit', accessor: 'credit' },
      { Header: 'Offset Account Type', accessor: 'offset_account_type' },
      { Header: 'Offset Account', accessor: 'offset_account' },
    ],
  );

  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({
        data: dd,
        debit: dd?.debit,
        credit: dd?.credit,
        account: dd?.account,
        value_model: dd?.value_model,
        description: dd?.description,
        offset_account: dd?.offset_account,
        transaction_type: dd?.transaction_type,
        date: moment(dd?.date).format('MM/DD/YYYY'),
        offset_account_type: dd?.offset_account_type,
      })),
    [tableList],
  );

  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, disposalData, disposalLogs);

  return (
    <div className='credentials-credits'>
      <div className='header'>
      
        <div className='functions'>
          <Button className='upload' onClick={() => setShowScreen(3)}>
            <AddSVG />
            Add Record
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns} 
          pagination={['BOTTOM']}
        />
      </div>
   
      </div>
  );
};

const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getDisposalList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, disposalData, disposalLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = disposalLogs;

    if (isSuccess) {
      const newData = disposalData.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          date: dd?.date,
          debit: dd?.debit,
          credit: dd?.credit,
          account: dd?.account,
          description: dd?.description,
          value_model: dd?.value_model,
          offset_account: dd?.offset_account,
          transaction_type: dd?.transaction_type,
          offset_account_type: dd?.offset_account_type,
        };
      });
      console.log(newData);
      setTableList(newData);
    }
  }, [disposalLogs]);
};

export default index;
