/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_SIZE } from 'components/ReactTable';

import { getAllEmployees, deleteEmployeeById } from 'reducers/employeesReducer';

// import assets below
import './ConfirmModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// Queries
const params = {
  current_page: 1,
  per_page: PAGE_SIZE[10], // [10, 20, 30, 100]
};

// main component
const ConfirmModal = ({ openModal, setOpenModal }) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    setOpenModal({
      id: '',
      isOpen: false,
    });
  };

  const deleteData = async () => {
    const result = await dispatch(deleteEmployeeById(openModal?.id));

    if (result) {
      if (result.meta.requestStatus === 'fulfilled') {
        toast.success('Successfully deleted employee data.');
        closeModal();
        dispatch(getAllEmployees());
      }
    }
  };

  return (
    <Modal
      centered
      onHide={closeModal}
      show={openModal?.isOpen}
      className='deleteModal-confirmation'
    >
      <Modal.Header>
        <h6>CONFIRM DELETE?</h6>
        <Button onClick={closeModal}>
          <CloseSVG />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Button onClick={deleteData} className='remove'>
          Delete
        </Button>

        <Button onClick={closeModal} className='cancel'>
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  data: {},
  openModal: false,
  getDatas: () => {},
  setOpenModal: () => {},
  searchAllTabs: () => {},
};

ConfirmModal.propTypes = {
  data: PropTypes.object,
  getDatas: PropTypes.func,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  searchAllTabs: PropTypes.func,
};

export default ConfirmModal;
