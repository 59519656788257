/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Button } from 'react-bootstrap';

// import components below
import CardUI from 'components/UI/CardUI';

// main component
const FilterCard = (props) => {
    const { object } = props;

    return (
        <div className='filterCard'>
            <CardUI>
                <div className='card'>
                    <div className='card__body' style={{ background: object.color }}>
                        <span>{object.value}</span>
                        {object.icon}
                    </div>
                    <div className='card__footer' style={{ borderColor: object.color }}>
                        <span>{object.title}</span>
                        {/* <Button>{object.arrow}</Button> */}
                    </div>
                </div>
            </CardUI>
        </div>
    );
};

export default FilterCard;
