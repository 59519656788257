import TabsUI from 'components/UI/TabsUI';
import { Tabs, Tab } from 'react-bootstrap';

import './style.scss';
/* import HospitalBill from '../HospitalBill';
   import RoomCharges from '../RoomCharges';
   import ProfessionalFees from '../ProfessionalFees';
   import PhilHealth from '../PhilHealth';
   import DiscountPayors from '../DiscountPayors'; */
import Soa from '../SOA';

const ProcessBillTab = (props) => {
    const { onChange } = props;

    const tabs = [
        /* {
               title: 'Hospital Bill',
               component: HospitalBill,
           },
           {
               title: 'Room Charges',
               component: RoomCharges,
           },
           {
               title: 'Professional Fees',
               component: ProfessionalFees,
           },
           {
               title: 'PhilHealth',
               component: PhilHealth,
           },
           {
               title: 'Discount & Payors',
               component: DiscountPayors,
           }, */
        {
            title: 'SOA',
            component: Soa,
        },
    ];

    return (
        <div className='subTab'>
            <TabsUI>
                <Tabs defaultActiveKey='SOA' onSelect={(eventKey) => onChange(eventKey)}>
                    {tabs.map(({ title, component: Component }, idx) => (
                        <Tab key={idx} eventKey={title} title={title}>
                            <Component />
                        </Tab>
                    ))}
                </Tabs>
            </TabsUI>
        </div>
    );
};

export default ProcessBillTab;
