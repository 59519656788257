/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Button, Col, Row } from 'react-bootstrap';
import React from 'react';

import { ReactComponent as EditSVG } from 'assets/svg/writing-green.svg';
import { ReactComponent as DeleteSVG } from 'assets/svg/circle-xmark-red.svg';
import { ReactComponent as CirclePlusSVG } from 'assets/svg/circle-plus-white.svg';
import _ from 'lodash';

const List = (props) => {

    const { result, title, setShowModal, type, date, handlePopupTitle, setFields, handleDeleteRequest } = props;

    const handleAddField = () => {
        setShowModal({ isShowForm: true, type: type, date: date });
        handlePopupTitle(type);
    };

    const onEdit = (data) => {
        setShowModal({ isShowForm: true, type: type, assetId: data?.id, assetData: data });
        handlePopupTitle(type);
        setFields({
            ...data,
            asset_type: type,
        });
    };

    const onDelete = (id) => {
        handleDeleteRequest(id);
    };

    const filterList = (list) => {
        return list?.filter((data) => data?.asset_type === type);
    };

    const filteredList = filterList(result);

    const calculateTotalCurrent = () => {
        const currrents = filteredList?.filter((data) => data?.category !== 'Non - Current Assets');
        const total = _.sum(_.map(currrents, (data) => data?.amount));
        return total === 0 ? 0 : total;
    };

    const calculateTotal = () => {
        const total = _.sum(_.map(filteredList, (data) => data?.amount));
        return total === 0 ? 0 : total;
    };

    return (
        <>
            <h5 className='mt-2 mb-4'>{title} List</h5>

            <div className='button-list mt-2 mb-4'>
                <Button className='me-3 btn-light-green' type='button' onClick={handleAddField}>
                    <CirclePlusSVG /> ADD FIELDS
                </Button>
            </div>
            { filteredList?.map((row) => {
                return (
                    <Row className='mb-4' key={row?.id}>
                        <Col md={4} className='asset-name'>
                            { row?.details }
                        </Col>
                        <Col md={1}>
                            { row?.amount }
                        </Col>
                        <Col md={2} className={ row.type === 'asset' ? 'current-asset' : 'non-current-asset'}>
                            { row.category }
                        </Col>
                        <Col md={2} className='icon'>
                            <EditSVG className='p-1' onClick={() => onEdit(row)} />
                            <DeleteSVG className='p-1' onClick={() => onDelete(row.id)} />
                        </Col>
                    </Row>
                );
            }) }

            <Row className='mt-4 mb-2'>
                <Col className='total-cont'>
                    <p>
                        Total Current {title} : <span>Php {calculateTotalCurrent()}</span>
                    </p>
                </Col>
                <Col className='total-cont'>
                    <p>
                        Total {title} : <span>Php {calculateTotal()}</span>
                    </p>
                </Col>
            </Row>

            <hr />
        </>
    );
};

export default List;
