/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal, Table } from 'react-bootstrap';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import TableUI from 'components/UI/TableUI';

// main component
const ModalViewRecord = ({ showModal, setShowModal }) => {
  return (
    <Modal
      size='lg'
      show={showModal.viewRecordModal}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            viewRecordModal: false,
          };
        })
      }
      aria-labelledby='View Patient Medical Records'
      className='view-record-modal'
    >
      <Modal.Body>
        <div className='add-record-request'>
          <div className='d-flex header'>
            <p>DISCHARGE SUMMARY</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowModal((prevState) => {
                  return { ...prevState, viewRecordModal: false };
                })
              }
            />
          </div>
        </div>
        <div className='view-patient-details'>
          <Row style={{ marginBottom: 60 }}>
            <Col>
              <p>Patient ID</p>
              <h5>{showModal.profileId}</h5>
            </Col>
            <Col xs={3}>
              <p>Patient Name</p>
              <h5>{showModal.profileData?.full_name}</h5>
            </Col>
            <Col>
              <p>Room / Bed</p>
              <h5>
                {showModal.profileData?.room || '-'} / {showModal.profileData?.bed || '-'}
              </h5>
            </Col>
            <Col>
              <p>Age / Sex</p>
              <h5>
                {showModal.profileData?.age} / {showModal.profileData?.sex}
              </h5>
            </Col>
            <Col>
              <p>Date of Birth</p>
              <h5>{showModal.profileData?.date_of_birth}</h5>
            </Col>
            <Col>
              <p>Status</p>
              <span
                className='bg-info'
                style={{ textAlign: 'end', padding: '3px 6px', borderRadius: 5, color: '#fff' }}
              >
                {showModal.profileData?.patient_status}
              </span>
            </Col>
          </Row>
          <TableUI>
            <Table responsive>
              <thead>
                <tr>
                  <th>Test Here</th>
                  <th style={{ textAlign: 'end' }}>Action</th>
                </tr>
              </thead>
            </Table>
          </TableUI>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() =>
            setShowModal((prevState) => {
              return { ...prevState, viewRecordModal: false };
            })
          }
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalViewRecord.defaultProps = {
  showModal: {},
  setShowModal: () => {},
};

ModalViewRecord.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default ModalViewRecord;
