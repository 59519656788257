// import components below
import PropTypes from 'prop-types';
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const PageHeader = () => {
  return (
    <div className='accountingHeader'>
      <PageHeaderUI>
        <h4>Credentials Management Module</h4>
        <p>Dashboard / Credentials Management</p>
      </PageHeaderUI>
    </div>
  );
};

PageHeader.defaultProps = {
  active: '',
};

PageHeader.propTypes = {
  active: PropTypes.string,
};

export default PageHeader;
