/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getInventories = createAsyncThunk(
  'inventory/',
  async (filter = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: ``,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      params: {
        inventory_status: filter?.inventory_status,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getInventory = createAsyncThunk(
  'inventory/getInventory',
  async (filter = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `search`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      params: {
        MRN: filter?.MRN,
        first_name: filter?.first_name,
        middle_name: filter?.middle_name,
        last_name: filter?.last_name,
        suffix: filter?.suffix,
        date_of_birth: filter?.date_of_birth,
        sex: filter?.sex,
        inventory_status: filter?.inventory_status,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showInventory = createAsyncThunk(
  'inventory/showInventory',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `${id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showInventoryItems = createAsyncThunk(
  'inventory/showInventoryItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/items${params}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createInventoryItems = createAsyncThunk(
  'inventory/createInventoryItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/items`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getGroupItems = createAsyncThunk(
  'inventory/getGroupItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/groups${params}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const addInventoryItems = createAsyncThunk(
  'inventory/addInventoryItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `api/items/add-item/${params.id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params.payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getRequestNumberList = createAsyncThunk(
  'inventory/getRequestNumberList',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/restock/request-number/${id}?per_page=1000`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const submitAdjustment = createAsyncThunk(
  'inventory/submitAdjustment',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/adjustment`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getInventoryDepartments = createAsyncThunk(
  'inventory/getInventoryDepartments',
  async (string, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/department`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const uploadExcelFile = createAsyncThunk(
  'inventory/uploadExcelFile',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/import/item`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateItem = createAsyncThunk(
  'inventory/updateItem',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `api/items/${params?.id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params?.payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateItemPrice = createAsyncThunk(
  'inventory/updateItemPrice',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `/api/items/price-update/${params?.id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params?.payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getPriceLogs = createAsyncThunk(
  'inventory/getPriceLogs',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/price-history`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getActivityLog = createAsyncThunk(
  'inventory/getActivityLog',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/activity-log`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
