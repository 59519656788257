/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import CredentialsCreditsModal from './components/Modal';

// import assets below
import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

// main comnponent
const state = [
  {
    network_address: '191.168.1.0',
  },
  {
    network_address: '191.168.1.0',
  },
];

const index = ({ setShowScreen, setRequestData }) => {
  const [openModal, setOpenModal] = useState();
  const [modalType, setModalType] = useState();

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Internet or Network Address', accessor: 'network_address' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        data: dd,
        network_address: dd?.network_address,
      })),
    [state],
  );

  return (
    <div className='credentials-credits'>
      <div className='header'>
        <h2>Certificate-Based Credentials </h2>
        
        <div className='functions'>

          <Button className='upload' onClick={() => setShowScreen(4)}>
            <AddSVG />
            Add Record
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <span
                className='badge blue'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setRequestData(data);
                  setOpenModal({
                    isOpen: true,
                  });
                }
              }
              >
                View Certificates
              </span>
            );
          }}
        />
      </div>
      <CredentialsCreditsModal modalType={modalType} openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default index;
