/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// main components below
import InventoryListTable from './Table';
import SearchInventoryFilter from './Filter';

import './style.scss';

// main component
const ReturnedItemToInventory = ({ departmentID }) => {
  const [searchData, setSearchData] = useState({
    search: '',
  });

  return (
    <div className='admissions-inventory-tab-inventory-items'>
      <SearchInventoryFilter
        departmentID={departmentID}
        searchData={searchData}
        setSearchData={setSearchData}
      />

      <InventoryListTable
        departmentID={departmentID}
        searchData={searchData}
        setSearchData={setSearchData}
      />
    </div>
  );
};

ReturnedItemToInventory.defaultProps = {
  departmentID: '',
};

ReturnedItemToInventory.propTypes = {
  departmentID: PropTypes.string,
};

export default ReturnedItemToInventory;
