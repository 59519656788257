/* eslint-disable */
// import core & vendor packages below
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

// Components
import ReturnModal from './ReturnModal';
import Table from 'components/ReactTable';
import seturlParams from 'helpers/seturlParams';
import {
  getDepartmentInventory,
  selectDepartmentInventory,
} from 'reducers/departmentInventoryReducer';
import { PrintContent } from '../PrintContent';

// Assets below
import './Table.scss';
import ViewItemsModal from './ViewItemsModal';

// main component
const InventoryListTable = ({
  activeKey,
  searchData,
  refreshData,
  departmentID,
  setRefreshData,
}) => {
  const dispatch = useDispatch();

  const inventoryState = useSelector(selectDepartmentInventory);

  const { data: inventoryData, items: inventoryItems, logs } = inventoryState;

  const [showModal, setShowModal] = useState(false);

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page { size: landscape }',
  });

  const getGroupName = (id) => {
    if (!inventoryItems || inventoryItems.length === 0) return;

    const selectedArray = inventoryItems.filter(
      (data) => parseInt(data.id) === parseInt(id) && data?.type === 'groups',
    );

    if (selectedArray.length === 0) {
      return 'N/A';
    }

    return selectedArray[0]?.attributes?.name;
  };

  const fetchAPI = () => {
    dispatch(
      getDepartmentInventory({
        id: departmentID,
        search: seturlParams(searchData?.search, 'q'),
        per_page: 100000,
      }),
    );
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Item Code', accessor: 'item_code' },
      { Header: 'Item Name', accessor: 'item_name' },
      { Header: 'Item Group', accessor: 'item_group' },
      { Header: 'Generic Name', accessor: 'generic_name' },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Storage Bin', accessor: 'storage_bin' },
      { Header: 'Piece Price', accessor: 'piece_price' },
      { Header: 'Box Price', accessor: 'box_price' },
      { Header: 'Piece Quantity', accessor: 'piece_quantity' },
      { Header: 'Box Quantity', accessor: 'box_quantity' },
      { Header: 'Piece Per Box', accessor: 'piece_per_box' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      inventoryData?.map((dd) => {
        const item = inventoryItems.filter(
          (d) => parseInt(d.id) === parseInt(dd?.attributes?.item_id) && d.type === 'items',
        );
        const selectedItem = item[0];

        return {
          id: dd?.id,
          item_id: dd?.attributes?.item_id,
          item_code: selectedItem?.attributes?.code,
          item_name: selectedItem?.attributes?.name,
          item_group: getGroupName(selectedItem?.attributes?.group_id),
          generic_name: selectedItem?.attributes?.generic_name,
          description: selectedItem?.attributes?.description,
          storage_bin: selectedItem?.attributes?.storage_bin,
          piece_price: selectedItem?.attributes?.piece_price,
          box_price: selectedItem?.attributes?.box_price,
          piece_per_box: selectedItem?.attributes?.piece_per_box,
          piece_quantity: dd?.attributes?.piece_quantity,
          box_quantity: dd?.attributes?.box_quantity,
        };
      }),
    [inventoryData],
  );

  const handleTableAction = (eventKey, id, data) => {
    const row = {
      data: inventoryData.find((dd) => parseInt(dd.id) === parseInt(id)),
      items: inventoryItems.find(
        (dd) => parseInt(dd.id) === parseInt(data?.item_id) && dd?.type === 'items',
      ),
    };

    const action = {
      profile: () => {},
      view: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            isItemShow: true,
            itemData: row,
            itemId: id,
          };
        });
      },
      edit: () => {},
      complete: () => {},
      remove: () => {},
    };

    action[eventKey]();
  };

  runOnFirstRender(fetchAPI);
  runWhenTabIsActive(refreshData, activeKey, fetchAPI, setRefreshData);

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent 
          ref={printRef} 
          tableCol={tableColumns}
        >
          {tableData?.length 
            ? tableData?.map((data) => (
                <tr key={data?.id}>
                    <td>{data?.item_code}</td>
                    <td>{data?.item_name}</td>
                    <td>{data?.item_group}</td>
                    <td>{data?.generic_name}</td>
                    <td>{data?.description}</td>
                    <td>{data?.storage_bin}</td>
                    <td>{data?.piece_price}</td>
                    <td>{data?.box_price}</td>
                    <td>{data?.piece_quantity}</td>
                    <td>{data?.box_quantity}</td>
                    <td>{data?.piece_per_box}</td>
                </tr>
            )) : (
                <tr>
                    <td style={{ textAlign: 'center' }} colSpan={11}>No Record Found</td>
                </tr>
            )
          }
        </PrintContent>
      </div>
      <div className='admissions-inventory-list-table'>
        <Row>
          <Col className='table-header'>
            <h4 className='mb-0 fw-bold mt-4 mb-2'>Store Inventory List</h4>

            <div className='table-buttons'>
              <Button
                onClick={() => {
                  setShowModal((prev) => {
                    return {
                      ...prev,
                      returnItem: true,
                    };
                  });
                }}
                className='return-button'
              >
                Return Items
              </Button>
              <Button className='print-button' onClick={handlePrint}>Print</Button>
            </div>
          </Col>
        </Row>

        <Table
          logs={logs}
          data={tableData}
          columns={tableColumns}
          customAction={(id, data) => (
            <span
              onClick={() => handleTableAction('view', id, data)}
              className='badge blue'
              style={{ cursor: 'pointer' }}
            >
              View
            </span>
          )}
          pagination={['BOTTOM']}
        />
      </div>

      <ReturnModal
        showModal={showModal}
        getDepartment={fetchAPI}
        setShowModal={setShowModal}
        departmentID={departmentID}
      />

      <ViewItemsModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

const runWhenTabIsActive = (refreshData, activeKey, fetchAPI, setRefreshData) => {
  useEffect(() => {
    if (refreshData && activeKey === 'Inventory Items') {
      fetchAPI();
      setRefreshData(false);
    }
  }, [refreshData]);
};

const runOnFirstRender = (fetchAPI) => {
  useEffect(() => {
    fetchAPI();
  }, []);
};

InventoryListTable.defaultProps = {
  searchData: {},
  departmentID: '',
};

InventoryListTable.propTypes = {
  searchData: PropTypes.object,
  departmentID: PropTypes.string,
};

export default InventoryListTable;
