// import assets below
import './style.scss';

// main component
const AccordionUI = (props) => {
  const { children } = props;
  return <div className='accordionUI'>{children}</div>;
};

export default AccordionUI;
