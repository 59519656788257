/* eslint-disable camelcase */
// import core & vendor packages below
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Col, Button, Modal, Table } from 'react-bootstrap';

// import components below
import Moment from 'moment';
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import { forSubmitRequestReorder } from 'reducers/refillRequest';

// import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const DenyModal = ({ showProfile, setShowProfile, setTrigger, state }) => {
  const { itemID } = state;
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remark, setRemark] = useState('');

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(username, password);
    const params = {
      username: username,
      password: password,
      remark: remark,
      restock_id: state?.itemID?.id,
      url: 'deny-reorder',
    };
    if (!username || !password) {
      toast.warning('Dont leave empty fields');
      return;
    }
    setTrigger((prev) => {
      return { ...prev, forSubmitRequestReorderTrigger: true };
    });
    dispatch(forSubmitRequestReorder(params));

    setShowProfile((prevState) => {
      return {
        ...prevState,
        isDenyModal: false,
      };
    });
  };

  return (
    <Modal
      size='lg'
      show={showProfile?.isDenyModal}
      onHide={() => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isDenyModal: false,
          };
        });
      }}
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>ITEM FOR REJECTED</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    isDenyModal: false,
                  };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='add-item-form' onSubmit={submitHandler}>
            <Row>
              <Col>
                <div>
                  You are about to approve this purchase request. Please review the items that will
                  be approved and/or rejected.
                </div>

                <div className='search-table my-4'>
                  <TableUI>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Request Date/Time</th>
                          <th>Request No</th>

                          <th>Requested By</th>
                          <th>No. of Items</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {Moment(itemID?.attributes?.requested_at).format(
                              'MMMM Do YYYY, h:mm:ss a',
                            )}
                          </td>

                          <td>{itemID?.attributes?.request_number}</td>
                          <td>{itemID?.attributes?.requested_by}</td>
                          <td>{itemID?.attributes?.number_of_items}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </TableUI>
                  <div className='my-4'>
                    You must enter username and password in order to proceed with the approval this
                    purchase request. <br />
                    <span className='text-danger'>
                      NOTE: Once approved, this request will be sent to the Purchasing Department
                      for processing.
                    </span>
                  </div>

                  <Form>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label className='fw-bold'>Username</Form.Label>
                          <Form.Control
                            type='text'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label className='fw-bold'>Password</Form.Label>
                          <Form.Control
                            type='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label className='fw-bold mt-4'>Remarks</Form.Label>
                          <Form.Control
                            as='textarea'
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col>
                      <Form.Group className='mt-4 buttons-cont'>
                        <Button className='add-button bg-danger' variant='primary' type='submit'>
                          REJECT
                        </Button>

                        <Button
                          onClick={() => {
                            setShowProfile((prevState) => {
                              return {
                                ...prevState,
                                isDenyModal: false,
                              };
                            });
                          }}
                          className='clear-button'
                          variant='secondary'
                        >
                          CANCEL
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default DenyModal;
