/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  updateItem,
  getPriceLogs,
  getInventory,
  showInventory,
  getGroupItems,
  getInventories,
  getActivityLog,
  uploadExcelFile,
  updateItemPrice,
  submitAdjustment,
  addInventoryItems,
  showInventoryItems,
  getRequestNumberList,
  createInventoryItems,
  getInventoryDepartments,
} from './thunks/inventoryThunks';
import _, { initial } from 'lodash';

const initialLog = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  error: null,
};

const initialState = {
  data: [],
  item: null,
  logs: initialLog,

  // For Store Inventory
  storeInventoryData: [],
  storeInventoryItems: null,
  storeInventoryItemsLogs: initialLog,

  // For Create Inventory Item
  createInventoryItemLogs: initialLog,

  // For Group Items
  inventoryGroupData: [],
  inventoryGroupItems: null,
  inventoryGroupItemsLogs: initialLog,

  // For Add Inventory Items
  addInventoryItemItemsLogs: initialLog,

  // For get request nubers
  getRequestNumberListData: [],
  getRequestNumberListLogs: initialLog,

  // For submit adjustment
  submitAdjustmentLogs: initialLog,

  // For inventory departments
  getInventoryDepartmentsData: [],
  getInventoryDepartmentsLogs: initialLog,

  // For Upload Excel File
  uploadExcelFileLogs: initialLog,

  // For Item Update
  updateItemData: {},
  updateItemLogs: initialLog,

  // For Item Price Updatge
  updateItemPriceLogs: initialLog,

  // For Price Logs
  priceLogsData: [],
  priceLogsItems: [],
  priceLogs: initialLog,

  // For Activity Log
  activityLogData: [],
  activityLogItem: [],
  activityLogs: initialLog,
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    resetLogState: (state, action) => {
      state.uploadExcelFileLogs = {
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        error: null,
        arrErrors: [],
      };
    },
    resetUpdateState: (state) => {
      state.updateItemData = {};
      state.updateItemLogs = initialLog;
      state.updateItemPriceLogs = initialLog;
    },
    resetPriceLogs: (state) => {
      state.priceLogs = initialLog;
    },
    resetcreateInventoryItemLogs: (state) => {
      state.createInventoryItemLogs = initialLog;
    },
  },
  extraReducers: {
    // Start Price Logs
    [getActivityLog.fulfilled]: (state, action) => {
      state.activityLogData = action.payload.data;
      state.activityLogItem = action.payload.included;
      state.activityLogs.isSuccess = true;
      state.activityLogs.isLoading = false;
    },
    [getActivityLog.pending]: (state) => {
      state.activityLogs.error = '';
      state.activityLogs.isFailed = false;
      state.activityLogs.isLoading = true;
      state.activityLogs.isSuccess = false;
    },
    [getActivityLog.rejected]: (state, action) => {
      state.activityLogs.isFailed = true;
      state.activityLogs.isLoading = false;
      state.activityLogs.error = action?.payload?.errors[0]?.detail;
    },
    // Start Price Logs
    [getPriceLogs.fulfilled]: (state, action) => {
      state.priceLogsData = action.payload.data;
      state.priceLogsItems = action.payload.included;
      state.priceLogs.isSuccess = true;
      state.priceLogs.isLoading = false;
    },
    [getPriceLogs.pending]: (state) => {
      state.priceLogs.error = '';
      state.priceLogs.isFailed = false;
      state.priceLogs.isLoading = true;
      state.priceLogs.isSuccess = false;
    },
    [getPriceLogs.rejected]: (state, action) => {
      state.priceLogs.isFailed = true;
      state.priceLogs.isLoading = false;
      state.priceLogs.error = action?.payload?.errors[0]?.detail;
    },
    // Start for update items
    [updateItemPrice.fulfilled]: (state) => {
      state.updateItemPriceLogs.isSuccess = true;
      state.updateItemPriceLogs.isLoading = false;
    },
    [updateItemPrice.pending]: (state) => {
      state.updateItemPriceLogs.isLoading = true;
      state.updateItemPriceLogs.isSuccess = false;
      state.updateItemPriceLogs.isFailed = false;
    },
    [updateItemPrice.rejected]: (state, action) => {
      state.updateItemPriceLogs.isFailed = true;
      state.updateItemPriceLogs.isLoading = false;
      state.updateItemPriceLogs.error = action?.payload?.errors[0]?.detail;
    },
    // Start for update items
    [updateItem.fulfilled]: (state, action) => {
      state.updateItemData = action.payload.data;
      state.updateItemLogs.isSuccess = true;
      state.updateItemLogs.isLoading = false;
    },
    [updateItem.pending]: (state) => {
      state.updateItemLogs.isLoading = true;
      state.updateItemLogs.isSuccess = false;
      state.updateItemLogs.isFailed = false;
    },
    [updateItem.rejected]: (state, action) => {
      state.updateItemLogs.isFailed = true;
      state.updateItemLogs.isLoading = false;
      state.updateItemLogs.error = action?.payload?.errors[0]?.detail;
    },
    // Start submit adjustment
    [uploadExcelFile.fulfilled]: (state, action) => {
      const { failures } = action.payload;

      if (failures.length > 0) {
        state.uploadExcelFileLogs.isFailed = true;
        state.uploadExcelFileLogs.isLoading = false;
        state.uploadExcelFileLogs.arrErrors = failures;
      } else {
        state.uploadExcelFileLogs.isSuccess = true;
        state.uploadExcelFileLogs.isLoading = false;
      }
    },
    [uploadExcelFile.pending]: (state) => {
      state.uploadExcelFileLogs.isLoading = true;
      state.uploadExcelFileLogs.isSuccess = false;
      state.uploadExcelFileLogs.isFailed = false;
      state.uploadExcelFileLogs.error = '';
      state.uploadExcelFileLogs.arrErrors = [];
    },
    [uploadExcelFile.rejected]: (state, action) => {
      state.uploadExcelFileLogs.isLoading = false;
      state.uploadExcelFileLogs.isFailed = true;
      state.uploadExcelFileLogs.error = action?.payload?.errors[0]?.detail;
    },
    // Start inventory departments
    [getInventoryDepartments.fulfilled]: (state, action) => {
      state.getInventoryDepartmentsData = action.payload.data;
      state.getInventoryDepartmentsLogs.isSuccess = true;
      state.getInventoryDepartmentsLogs.isLoading = false;
    },
    [getInventoryDepartments.pending]: (state) => {
      state.getInventoryDepartmentsLogs.isLoading = true;
      state.getInventoryDepartmentsLogs.isSuccess = false;
      state.getInventoryDepartmentsLogs.isFailed = false;
      state.getInventoryDepartmentsLogs.error = '';
    },
    [getInventoryDepartments.rejected]: (state, action) => {
      state.getInventoryDepartmentsLogs.isLoading = false;
      state.getInventoryDepartmentsLogs.isFailed = true;
      state.getInventoryDepartmentsLogs.error = action?.payload?.errors[0]?.detail;
    },
    // Start submit adjustment
    [submitAdjustment.fulfilled]: (state, action) => {
      state.submitAdjustmentLogs.isSuccess = true;
      state.submitAdjustmentLogs.isLoading = false;
    },
    [submitAdjustment.pending]: (state) => {
      state.submitAdjustmentLogs.isLoading = true;
      state.submitAdjustmentLogs.isSuccess = false;
      state.submitAdjustmentLogs.isFailed = false;
      state.submitAdjustmentLogs.error = '';
    },
    [submitAdjustment.rejected]: (state, action) => {
      state.submitAdjustmentLogs.isLoading = false;
      state.submitAdjustmentLogs.isFailed = true;
      state.submitAdjustmentLogs.error = action?.payload?.errors[0]?.detail;
    },
    // End submit adjustment
    // Start for get request number list
    [getRequestNumberList.fulfilled]: (state, action) => {
      state.getRequestNumberListData = action.payload.data;
      state.getRequestNumberListLogs.isSuccess = true;
      state.getRequestNumberListLogs.isLoading = false;
    },
    [getRequestNumberList.pending]: (state) => {
      state.getRequestNumberListLogs.isLoading = true;
      state.getRequestNumberListLogs.isSuccess = false;
      state.getRequestNumberListLogs.isFailed = false;
      state.getRequestNumberListLogs.error = '';
    },
    [getRequestNumberList.rejected]: (state, action) => {
      state.getRequestNumberListLogs.isLoading = false;
      state.getRequestNumberListLogs.isFailed = true;
      state.getRequestNumberListLogs.error = action?.payload?.errors[0]?.detail;
    },
    // End for get request number list
    // Start for Add Inventory Items
    [addInventoryItems.fulfilled]: (state, action) => {
      state.addInventoryItemItemsLogs.isSuccess = true;
      state.addInventoryItemItemsLogs.isLoading = false;
    },
    [addInventoryItems.pending]: (state) => {
      state.addInventoryItemItemsLogs.isLoading = true;
      state.addInventoryItemItemsLogs.isSuccess = false;
      state.addInventoryItemItemsLogs.isFailed = false;
      state.addInventoryItemItemsLogs.error = '';
    },
    [addInventoryItems.rejected]: (state, action) => {
      state.addInventoryItemItemsLogs.isLoading = false;
      state.addInventoryItemItemsLogs.isFailed = true;
      state.addInventoryItemItemsLogs.error = action?.payload?.errors[0]?.detail;
    },
    // End for Add Inventory Items
    // Start for get group items
    [getGroupItems.fulfilled]: (state, action) => {
      state.inventoryGroupData = action.payload.data;
      state.inventoryGroupItemsLogs.isSuccess = true;
      state.inventoryGroupItemsLogs.isLoading = false;
    },
    [getGroupItems.pending]: (state) => {
      state.inventoryGroupItemsLogs.isLoading = true;
      state.inventoryGroupItemsLogs.isSuccess = false;
      state.inventoryGroupItemsLogs.isFailed = false;
      state.inventoryGroupItemsLogs.error = '';
    },
    [getGroupItems.rejected]: (state, action) => {
      state.inventoryGroupItemsLogs.isLoading = false;
      state.inventoryGroupItemsLogs.isFailed = true;
      state.inventoryGroupItemsLogs.error = action?.payload?.errors[0]?.detail;
    },
    // End for get group items
    // Start For Create Inventory Item
    [createInventoryItems.fulfilled]: (state, action) => {
      state.createInventoryItemLogs.isSuccess = true;
      state.createInventoryItemLogs.isLoading = false;
    },
    [createInventoryItems.pending]: (state) => {
      state.createInventoryItemLogs.isLoading = true;
      state.createInventoryItemLogs.isSuccess = false;
      state.createInventoryItemLogs.isFailed = false;
      state.createInventoryItemLogs.error = '';
    },
    [createInventoryItems.rejected]: (state, action) => {
      state.createInventoryItemLogs.isLoading = false;
      state.createInventoryItemLogs.isFailed = true;
      state.createInventoryItemLogs.error = action?.payload?.errors[0]?.detail;
    },
    // End For Create Inventory Item
    // Start For Show Inventory Items
    [showInventoryItems.fulfilled]: (state, action) => {
      state.storeInventoryData = action.payload.data;
      state.storeInventoryItemsLogs.isSuccess = true;
      state.storeInventoryItemsLogs.isLoading = false;
    },
    [showInventoryItems.pending]: (state) => {
      state.storeInventoryItemsLogs.isLoading = true;
      state.storeInventoryItemsLogs.isSuccess = false;
      state.storeInventoryItemsLogs.isFailed = false;
    },
    [showInventoryItems.rejected]: (state) => {
      state.storeInventoryData = [];
      state.storeInventoryItemsLogs.isLoading = false;
      state.storeInventoryItemsLogs.isFailed = true;
    },
    // End For Show Inventory Items
    [getInventories.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [getInventories.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getInventories.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = true;
    },
    [getInventory.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [getInventory.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getInventory.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = true;
    },
    [showInventory.fulfilled]: (state, action) => {
      state.item = action.payload.data;
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [showInventory.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [showInventory.rejected]: (state, action) => {
      state.item = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action.payload.data;
    },
  },
});

export const { actions: inventoryActions, reducer: inventoryReducer } = slice;

export const { resetLogState, resetUpdateState, resetPriceLogs, resetcreateInventoryItemLogs } =
  inventoryActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.inventory || initialState;

export const selectInventory = createSelector([selectDomain], (inventoryState) => inventoryState);

export const selectInventoryData = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.data,
);

export const selectInventoryItem = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.item,
);

export const selectInventoryLogs = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.activityLogData,
);

/* --------------------------------------------------------------------------------- */

export {
  updateItem,
  getPriceLogs,
  getInventory,
  getGroupItems,
  showInventory,
  getActivityLog,
  getInventories,
  uploadExcelFile,
  updateItemPrice,
  submitAdjustment,
  addInventoryItems,
  showInventoryItems,
  getRequestNumberList,
  createInventoryItems,
  getInventoryDepartments,
};
