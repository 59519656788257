/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// import components below
import QRCode from 'react-qr-code';
import { QRCodeCanvas } from 'qrcode.react';
import FormUI from 'components/UI/FormUI';
import PatientProfileTabs from './components/Tabs';
import ConditionalRender from 'components/ConditionalRender';
import { selectPatientItem, showPatient, selectPatient } from 'reducers/patientReducer';
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import './style.scss';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark2.svg';

// main component
const FormPatientProfile = ({ showProfile, setShowProfile, setShowScreen }) => {
  const dispatch = useDispatch();

  const patientProfile = useSelector(selectPatientItem);
  const patientState = useSelector(selectPatient);
  const { itemLogs, item } = patientState;

  // const editPatient = () => {
  //   alert('EDIT');
  // };

  // const admitPatient = () => {
  //   const params = {
  //     id: patientProfile.id,
  //     patient_status: 'admitted',
  //     // eslint-disable-next-line newline-per-chained-call
  //     admission_date: new Date().toISOString().slice(0, 10),
  //   };
  //   axios
  //     .put('patient', params)
  //     .then((response) => {
  //       toast.success('Successfully admitted patient');

  //       setTimeout(() => {
  //         setShowProfile({ isShowProfile: false, profileId: null });
  //         // setShowProfile({ isShowProfile: false, profileId: null });
  //       }, 2000);
  //     })
  //     .catch((err) => {
  //       console.log('AXIOS ERROR: ', err);
  //     });
  // };

  const patientStatus = (status) => {
    switch (status) {
      case 'admitted':
        break;
      case 'for_discharge':
        break;
      default:
      // return (
      //   <Button className='' variant='light-blue' onClick={admitPatient}>
      //     ADMIT PATIENT
      //   </Button>
      // );
    }
  };

  const handleOnPrint = () => {
    toast.info('Printing Outpatient Form.');

    const id = patientProfile?.id;

    axios
      .post(
        `patient/outpatient-record/${id}`,
        {},
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split(';')[0];

        saveAs(response.data, filename);
      })
      .catch((err) => {
        toast.error('Error printing Outpatient Form.');
      });
  };

  const printIdentificationCard = () => {
    toast.info('Printing Identification Card.');

    const id = patientProfile?.id;

    axios
      .post(
        `patient/identification-card/${id}`,
        {},
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split(';')[0];

        saveAs(response.data, filename);
      })
      .catch((err) => {
        toast.error('Error printing Identification Card.');
      });
    // logic here
  };

  useEffect(() => {
    const { isSuccess, isFailed, isLoading } = itemLogs;

    if (isSuccess) {
      if (patientProfile === null && item === null && showProfile.profileId === null) {
        setShowProfile({ isShowProfile: false, profileId: null });
        toast.dismiss();
        toast.error('User Not Found/ Invalid QR Code');
        return;
      }
    }

    // if (isFailed) {
    //   toast.error('User Not Found/ Invalid QR Code');
    //   setShowProfile({ isShowProfile: false, profileId: null });
    //   return;
    // }
  }, [itemLogs]);

  useEffect(() => {
    if (showProfile.profileId === null) {
      toast.error('QR Code returned null. Please use better images');
      setShowProfile({
        profileId: null,
        isShowProfile: false,
      });
    } else {
      dispatch(showPatient(showProfile.profileId));
    }
  }, [showProfile]);

  const [disabled, setDisabled] = useState(false);

  const referToER = (patientId) => {
    const params = {
      patient_status: 'for_admission_referred_from_opd',
      id: showProfile.profileId,
    };

    axios
      .put('/patient', params)
      .then((response) => {
        toast.warning('Patient have been referred to ER');
        setDisabled(true);
        setTimeout(() => {
          setShowProfile({ isShowProfile: false, profileId: null });
        }, 2000);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  return (
    <>
      <ConditionalRender
        condition={itemLogs?.isLoading === true}
        renderIf={
          <div className='formPatientProfile'>
            <div className='d-flex justify-content-end'>
              <CircleXMark
                style={{ width: '2em', cursor: 'pointer' }}
                onClick={() => {
                  if (setShowProfile) {
                    setShowProfile({
                      profileId: null,
                      isShowProfile: false,
                    });
                  }

                  if (setShowScreen) {
                    setShowScreen(1);
                  }
                }}
              />
            </div>

            <FormUI>
              <div className='formPatientProfile__header'>
                <h4>Loading...</h4>
              </div>
            </FormUI>
          </div>
        }
      />

      <ConditionalRender
        condition={itemLogs?.isSuccess === true}
        renderIf={
          <div className='formPatientProfile'>
            <div className='d-flex justify-content-end'>
              <CircleXMark
                style={{ width: '2em', cursor: 'pointer' }}
                onClick={() => {
                  if (setShowProfile) {
                    setShowProfile({
                      profileId: null,
                      isShowProfile: false,
                    });
                  }

                  if (setShowScreen) {
                    setShowScreen(1);
                  }
                }}
              />
            </div>

            <FormUI>
              <div className='formPatientProfile__header'>
                <h4>
                  {patientProfile?.patient_status === 'temporary'
                    ? 'Patient Temporary Profile'
                    : 'Patient Profile'}
                </h4>
                <div>
                  <Button className='print-button me-3' onClick={referToER}>
                    REFER TO EMERGENCY
                  </Button>
                  <Button className='print-button me-3' onClick={handleOnPrint}>
                    Print Form
                  </Button>
                  <Button className='print-button me-2' onClick={printIdentificationCard}>
                    Print Identification Card
                  </Button>
                  <Link
                    className='btn btn-save me-3'
                    to={{ pathname: '/dashboard/outpatient-department/edit-patient-profile' }}
                  >
                    EDIT PATIENT PROFILE
                  </Link>
                  {patientStatus(patientProfile?.patient_status)}
                </div>
              </div>

              <div className='avatar__container'>
                <div className={'d-flex align-items-center'}>
                  {patientProfile?.id && (
                    <div className='HpQrcode' onClick={handleDownloadQR}>
                      {/* <QRCode
                        size={150}
                        style={{ height: 'auto', marginRight: '10px' }}
                        value={`${patientProfile?.id}`}
                      /> */}
                      <QRCodeCanvas
                        size={250}
                        level='H'
                        includeMargin={true}
                        value={`${patientProfile?.id}`}
                      />
                    </div>
                  )}
                  {patientProfile?.filename ? (
                    <div className='svg__container'>
                      <img
                        src={`${process.env.REACT_APP_API_BASE_URL}/files/${patientProfile?.filename}`}
                        style={{ 'border-radius': '50%', 'width': '100%', 'height': '100%' }}
                      ></img>
                    </div>
                  ) : (
                    <div className='svg__container'>
                      <UserSVG />
                    </div>
                  )}
                </div>

                <h2 className='mt-4 text-uppercase'>
                  <span className='patient__lastName fw-bold'>{patientProfile?.last_name}</span>,{' '}
                  {patientProfile?.first_name}
                </h2>
                <h5 className='patient__number mt-1'>{patientProfile?.id}</h5>
                <div className='patient__info mt-1'>
                  <span>
                    {patientProfile?.age} / {patientProfile?.sex}
                  </span>
                  <span>{moment(patientProfile?.date_of_birth).format('MMMM-DD-YYYY')}</span>
                </div>
              </div>
              {patientProfile?.patient_status === 'new' && (
                <div className='d-flex justify-content-center'>
                  {/* <Link className='btn btn-save me-3' to='/dashboard/admissions/create-inpatient-visit'>
      Create Inpatient Visit
    </Link> */}
                  {patientProfile?.patient_status !== 'for_discharge' && (
                    <Button
                      onClick={() => {
                        setShowProfile((prevState) => {
                          return {
                            ...prevState,
                            labRequest: true,
                          };
                        });
                      }}
                      className='btn btn-click me-3'
                    >
                      <small> DIAGNOSTIC REQUEST</small>
                    </Button>
                  )}

                  {/* {patientProfile?.patient_status !== 'for_discharge' &&
                    <ConditionalRender
                      condition={canAddOrder}
                      renderIf={
                        <Button
                          onClick={() => {
                            setShowProfile((prevState) => {
                              return {
                                ...prevState,
                                addOrder: true,
                              };
                            });
                          }}
                          className='btn btn-click me-3'
                        >
                          <small> Add Order</small>
                        </Button>
                      }
                    />
                  } */}

                  {patientProfile?.patient_status !== 'for_discharge' && (
                    <Button
                      onClick={() => {
                        setShowProfile((prevState) => {
                          return {
                            ...prevState,
                            procedure: true,
                          };
                        });
                      }}
                      className='btn btn-click me-3'
                    >
                      <small>Procedure</small>
                    </Button>
                  )}

                  {patientProfile?.patient_status !== 'for_discharge' && (
                    <Button
                      onClick={() => {
                        setShowProfile((prevState) => {
                          return {
                            ...prevState,
                            medication: true,
                          };
                        });
                      }}
                      className='btn btn-click me-3'
                    >
                      <small> Add Medication</small>
                    </Button>
                  )}

                  {/* {patientProfile?.patient_status !== 'for_discharge' && (
                    <ConditionalRender
                      condition={canRequest}
                      renderIf={
                        <Button
                          onClick={() => {
                            setShowProfile((prevState) => {
                              return {
                                ...prevState,
                                supply: true,
                              };
                            });
                          }}
                          className='btn btn-click me-3'
                        >
                          <small> Request for Supply</small>
                        </Button>
                      }
                    />
                  )} */}

                  {patientProfile?.patient_status !== 'for_discharge' && (
                    <Button
                      onClick={() => {
                        setShowProfile((prevState) => {
                          return {
                            ...prevState,
                            note: true,
                          };
                        });
                      }}
                      className='btn btn-click me-3'
                    >
                      <small> Add Note</small>
                    </Button>
                  )}
                </div>
              )}

              {/* Sample Button */}
              {/* <Button
                onClick={() => {
                  setShowProfile((prevState) => {
                    return {
                      ...prevState,
                      note: true,
                    };
                  });
                }}
                className='btn btn-click me-3'
              >
                <small> Add Note</small>
              </Button> */}

              <br></br>
              <div className='formPatientProfile__banner'>
                <Row>
                  <Col lg='3'>
                    <div className='p-3'>
                      <h6>Allergies:</h6>
                      <div className='d-flex flex-column'>
                        {patientProfile?.patient_allergies?.length > 0 ? (
                          patientProfile?.patient_allergies?.map((item, key) => {
                            return (
                              <span key={key} className='fw-bold'>
                                {' '}
                                {item?.allergy}
                              </span>
                            );
                          })
                        ) : (
                          <span className='fw-bold'>N/A</span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg='6'>
                    <div className='d-flex flex-column align-items-between h-100 p-3'>
                      <Row>
                        <Col>
                          <div className='mt-2'>
                            <span>Location: </span>{' '}
                            <span className='fw-bold'>
                              {patientProfile?.assigned_room
                                ? patientProfile?.assigned_room?.room?.room
                                : '-'}
                            </span>
                          </div>
                        </Col>
                        <Col>
                          <div className='mt-2'>
                            <span>Admission Date: </span>{' '}
                            <span className='fw-bold'>
                              <span>
                                {patientProfile?.admission_date
                                  ? moment(patientProfile?.admission_date).format('MMMM-DD-YYYY')
                                  : '-'}
                              </span>
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='mt-2'>
                            <span>Visit Number: </span>
                            <span className='fw-bold'>
                              {patientProfile?.active_visit_number
                                ? patientProfile?.active_visit_number
                                : '-'}
                            </span>
                          </div>
                        </Col>
                        <Col>
                          <div className='mt-2'>
                            <span>Length of Stay: </span>{' '}
                            <span className='fw-bold'>
                              {showProfile?.admissionDays ? showProfile?.admissionDays : '-'}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='mt-2'>
                            <span>Reference #: </span>
                            <span className='fw-bold'>
                              {patientProfile?.reference_number
                                ? patientProfile?.reference_number
                                : '-'}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg='3'>
                    <div className='p-3'>
                      <h6>Doctors:</h6>
                      <div className='d-flex flex-column'>
                        {patientProfile?.assigned_doctors?.length > 0 ? (
                          patientProfile?.assigned_doctors?.map((item) => {
                            return (
                              <span key={item?.id} className='fw-bold'>
                                Dr. {item?.user?.first_name} {item?.user?.last_name}
                              </span>
                            );
                          })
                        ) : (
                          <span className='fw-bold'>N/A</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <PatientProfileTabs showProfile={showProfile} setShowProfile={setShowProfile} />
            </FormUI>
          </div>
        }
      />
    </>
  );
};

const handleDownloadQR = () => {
  const canvas = document.querySelector('.HpQrcode > canvas');
  // eslint-disable-next-line prefer-const
  let link = document.createElement('a');
  link.href = canvas.toDataURL();
  link.download = 'QR.png';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

FormPatientProfile.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
  setShowScreen: () => {},
};

FormPatientProfile.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
  setShowScreen: PropTypes.func,
};

export default FormPatientProfile;
