/* eslint-disable */
// import core & vendor packages below
import axios from 'axios';
import { Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

// components
import Table from 'components/ReactTable';

// main component
const BedManagementTable = ({ setState, state }) => {
  useEffect(() => {
    axios
      .get('/room')
      .then((response) => {
        setState(response.data.data.room_list);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Floor', accessor: 'floor' }, // accessor is the "key" in the data
      { Header: 'Unit', accessor: 'unit' },
      { Header: 'Room Type', accessor: 'room_type' },
      { Header: 'Room', accessor: 'room' },
      { Header: 'Bed', accessor: 'bed' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Reserved', accessor: 'reserved' },
      { Header: 'Remarks', accessor: 'remarks' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        bed: dd?.bed,
        room: dd?.room,
        unit: dd?.unit,
        floor: dd?.floor,
        remarks: dd?.status,
        room_type: dd?.type,
        reserved: dd?.isReserved,
        status: <span className={dd?.status}>{dd?.status}</span>,
      })),
    [state],
  );

  // const handleTableAction = (eventKey, id) => {
  //   const row = listOfRooms.find(dd => dd.id === id);
  //   const action = {
  //     profile: () => { },
  //     view: () => { },
  //     edit: () => { },
  //   }

  //   action[eventKey]()
  // }

  return (
    <div className='admissions-bed-management-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        // actions={{ hasProfile: true, hasEdit: true, hasDelete: true }}
        // onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default BedManagementTable;
