/* eslint-disable camelcase */
// Packages
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

// Components
import InventoryTable from './Table';
import FormUI from 'components/UI/FormUI';

// Assets
import './Filter.scss';

// main component
const InventoryFilter = ({ state, setState, setShowProfile, showProfile }) => {
  return (
    <div className='InventoryFilter'>
      <FormUI>
        <Row>
          <Col>
            <InventoryTable
              state={state}
              setState={setState}
              setShowProfile={setShowProfile}
              showProfile={showProfile}
            />
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

InventoryFilter.defaultProps = {
  setState: () => {},
  setShowProfile: () => {},
};

InventoryFilter.propTypes = {
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default InventoryFilter;
