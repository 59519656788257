/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { loanListStatus } from 'helpers';
import Table from 'components/ReactTable';
import { Modal, Button } from 'react-bootstrap';
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './BalanceModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const BalanceModal = ({ openModal, setOpenModal, modalData }) => {
  const closeModal = () => {
    setOpenModal(false);
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Year', accessor: 'year' },
      { Header: 'Principal (A)', accessor: 'principal' },
      { Header: 'Interest (B)', accessor: 'interest' },
      { Header: 'Principal + Interest (A+B)', accessor: 'total' },
      { Header: 'Balance', accessor: 'balance' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(() => {
    if (openModal && modalData && modalData.loan_list.length > 0) {
      return modalData?.loan_list.map((dd) => ({
        year: dd?.year,
        total: dd?.total,
        balance: dd?.balance,
        interest: dd?.interest,
        principal: dd?.principal,
        status: loanListStatus(dd?.status),
      }));
    }

    return [];
  }, [modalData, openModal]);

  useEffect(() => {
    console.log('modalData', modalData);
  }, [modalData]);

  return (
    <Modal
      centered
      size={'lg'}
      show={openModal}
      onHide={closeModal}
      className='accept-decline-modal'
    >
      <Modal.Header>
        <h6>View Balance</h6>
        <Button onClick={closeModal}>
          <CloseSVG />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className='loan-list-table'>
          <p>Loans List</p>

          <Table data={tableData} columns={tableColumns} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

BalanceModal.defaultProps = {
  openModal: false,
  setOpenModal: () => {},
  data: {},
  searchAllTabs: () => {},
  getDatas: () => {},
};

BalanceModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  data: PropTypes.object,
  searchAllTabs: PropTypes.func,
  getDatas: PropTypes.func,
};

export default BalanceModal;
