/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-indent-props */
// import core & vendor packages
import { useState } from 'react';
import PropTypes from 'prop-types';

// import components
// import EditRoom from './components/EditRoom';
import SearchRoomFilter from './components/Filter';
import FilterCardList from './components/CardList';
import HouseKeepingTable from './components/Table';
import ConditionalRender from 'components/ConditionalRender';

// main component
const SearchRoom = ({ handleTabOnChange }) => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    isDefault: true,
    createRoom: false,
    editRoom: false,
    editValues: null,
    id: null,
  });

  const isDefault = showProfile.isDefault === true;

  return (
    <div>
      <ConditionalRender
        condition={isDefault}
        renderIf={
          <div className='searchRoom'>
            <FilterCardList setState={setState} />

            <SearchRoomFilter
              setState={setState}
              setShowProfile={setShowProfile}
              handleTabOnChange={handleTabOnChange}
            />

            <HouseKeepingTable
              state={state}
              setState={setState}
              showProfile={showProfile}
              setShowProfile={setShowProfile}
            />
          </div>
        }
      />
    </div>
  );
};

SearchRoom.defaultProps = {
  handleTabOnChange: () => {},
};

SearchRoom.propTypes = {
  handleTabOnChange: PropTypes.func,
};

export default SearchRoom;
