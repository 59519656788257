// import components below
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const AdmissionHeader = () => {

  return (
    <div className='admissionHeader'>
      <PageHeaderUI>
        <h4>Medical Records</h4>
      </PageHeaderUI>
    </div>
  );
};

export default AdmissionHeader;
