import { configureStore } from '@reduxjs/toolkit';
import { aclReducer } from 'reducers/aclReducer';
import { authReducer } from 'reducers/authReducer';
import { roomReducer } from 'reducers/roomReducer';
import { nurseReducer } from 'reducers/nurseReducer';
import { returnReducer } from 'reducers/returnReducer';
import { signupReducer } from 'reducers/signupReducer';
import { reportReducer } from 'reducers/reportReducer';
import { patientReducer } from 'reducers/patientReducer';
import { cashFlowReducer } from 'reducers/cashFlowReducer';
import { billingReducer } from '../reducers/billingReducer';
import { inventoryReducer } from 'reducers/inventoryReducer';
import { employeesReducer } from 'reducers/employeesReducer';
import { refillRequestReducer } from 'reducers/refillRequest';
import { appointmentReducer } from 'reducers/appointmentReducer';
import { fixedAssetsReducer } from 'reducers/fixedAssetsReducer';
import { accountingReducer } from '../reducers/accountingReducer';
import { stockAdjustmentReducer } from 'reducers/stockAdjustment';
import { notificationReducer } from 'reducers/notificationReducer';
import { borrowReturnReducer } from 'reducers/borrowReturnReducer';
import { medicalRecordReducer } from 'reducers/medicalRecordReducer';
import { forgotPasswordReducer } from 'reducers/forgotPasswordReducer';
import { professionalFeeReducer } from 'reducers/professionalFeeReducer';
import { employeeScheduleReducer } from 'reducers/employeeScheduleReducer';
import { overtimeTrackingReducer } from 'reducers/overtimeTrackingReducer';
import { departmentInventoryReducer } from 'reducers/departmentInventoryReducer';
import { hospitalOperationReducer } from 'reducers/hospitalOperationReportReducer';
import { credentialsManagementReducer } from 'reducers/credentialsManagementReducer';
export const store = configureStore({
  reducer: {
    acl: aclReducer,
    auth: authReducer,
    room: roomReducer,
    nurse: nurseReducer,
    report: reportReducer,
    return: returnReducer,
    signup: signupReducer,
    patient: patientReducer,
    billing: billingReducer,
    cashFlow: cashFlowReducer,
    inventory: inventoryReducer,
    employees: employeesReducer,
    accounting: accountingReducer,
    fixedAssets: fixedAssetsReducer,
    appointment: appointmentReducer,
    borrowReturn: borrowReturnReducer,
    notification: notificationReducer,
    refillRequest: refillRequestReducer,
    medicalRecord: medicalRecordReducer,
    forgotPassword: forgotPasswordReducer,
    stockAdjustment: stockAdjustmentReducer,
    professionalFee: professionalFeeReducer,
    overtimeTracking: overtimeTrackingReducer,
    employeeSchedule: employeeScheduleReducer,
    hospitalOperation: hospitalOperationReducer,
    departmentInventory: departmentInventoryReducer,
    credentialsManagement: credentialsManagementReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
