/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import components below
import Filter from './components/Filter';
import ReturnItemsTable from './components/Table';
import { objectCleaner } from 'helpers/objectCleaner';
import ViewItemModal from './components/ViewItemModal';
import { getReturnTableList } from 'reducers/returnReducer';
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './index.scss';

// Main Component
const ReturnItems = () => {
  const dispatch = useDispatch();

  const [showProfile, setShowProfile] = useState({
    showView: false,
    profileId: null,
    isShowProfile: false,
  });

  const [search, setSearch] = useState('');
  const [active, setActive] = useState('');

  const fetchData = (status) => {
    let params = {
      q: search,
      per_page: 100000,
    };

    if (status !== '') {
      params.department_id = status;
    }

    dispatch(getReturnTableList(params));
  };

  const resetData = () => {
    let params = {
      q: '',
      per_page: 100000,
      department_id: active,
    };

    const { parameters } = objectCleaner(params);

    dispatch(
      getReturnTableList(parameters),
    );
  };

  const isDefault = showProfile.isShowProfile === false;

  useEffect(() => {
    // console.log('active', active);
  }, [active]);

  return (
    <>
      <div className='mt-3'>
        <ConditionalRender
          condition={isDefault}
          renderIf={
            <>
              <Filter
                active={active}
                search={search}
                setSearch={setSearch}
                fetchData={fetchData}
                resetData={resetData}
              />

              <ReturnItemsTable
                search={search}
                active={active}
                fetchData={fetchData}
                setActive={setActive}
                setSearch={setSearch}
                setShowProfile={setShowProfile}
              />
            </>
          }
        />
      </div>

      <ViewItemModal showProfile={showProfile} setShowProfile={setShowProfile} />
    </>
  );
};

export default ReturnItems;
