/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Packages
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, Table, FormControl } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import ConditionalRender from 'components/ConditionalRender';
import { submitProfessionalFee, selectprofessionalFee } from 'reducers/professionalFeeReducer';
import {
  getItems,
  selectDepartmentInventory,
  itemRequestForRepairSubmit,
} from 'reducers/departmentInventoryReducer';

// import assets below
import './style.scss';
import { ReactComponent as CirclePlus } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as CircleMinus } from 'assets/svg/circle-minus.svg';
import { ReactComponent as FileUploadSVG } from 'assets/svg/file-upload.svg';
import { toast } from 'react-toastify';

// main component
const SetProfessionalModal = ({ showModal, setShowModal, refreshTable }) => {
  const dispatch = useDispatch();
  const mainState = useSelector(selectprofessionalFee);
  const { updateLogs } = mainState;

  const [formData, setFormData] = useState({ professional_fee: '' });

  const handleSave = () => {
    const params = {
      professional_fee: formData?.professional_fee,
      id: showModal.id,
    };

    dispatch(submitProfessionalFee(params));
  };

  const handleChange = (key) => (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  };

  const closeModal = () => {
    setFormData({ professional_fee: '' });

    setShowModal((prevState) => {
      return {
        ...prevState,
        id: null,
        data: null,
        showSet: false,
      };
    });
  };

  useEffect(() => {
    const { isSuccess } = updateLogs;

    if (isSuccess) {
      toast.success('Professional fee set successfully');
      refreshTable();
      closeModal();
    }
  }, [updateLogs]);

  useEffect(() => {
    // console.log('showModal', showModal);
  }, [showModal]);

  return (
    <Modal
      size='lg'
      show={showModal.showSet}
      onHide={() => closeModal()}
      aria-labelledby='SET PROFESSIONAL FEE'
      className='set-professional-modal'
    >
      <Modal.Body>
        <div className='request-for-repair-request'>
          <div className='d-flex header'>
            <p>SET PROFESSIONAL FEE</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowModal((prevState) => {
                  return { ...prevState, showSet: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='adjustment-form'>
            <Row>
              <Col>
                <Form.Group className='mb-2'>
                  <Form.Label>Professional Fee :</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='Enter fee'
                    value={formData?.professional_fee}
                    onChange={handleChange('professional_fee')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col>
                <div className='buttons-cont'>
                  <Button onClick={handleSave} className='save'>
                    SAVE
                  </Button>
                  <Button
                    onClick={() => {
                      closeModal();
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

SetProfessionalModal.defaultProps = {
  showModal: {},
  setShowModal: () => {},
};

SetProfessionalModal.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default SetProfessionalModal;
