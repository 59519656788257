/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import { useDispatch, useSelector } from 'react-redux';
import { getEntryDateList, selectFixedAssets } from 'reducers/fixedAssetsReducer';

// import assets below
import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

// main comnponent
const index = ({ setShowScreen, setRequestData, setFormData }) => {
  const dispatch = useDispatch();

  const fixedAssetsState = useSelector(selectFixedAssets);
  const { entryDateData, entryDateItem, entryDateLogs } = fixedAssetsState;

  const [tableList, setTableList] = useState([]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Entry Date', accessor: 'entry_date' },
    ],
  );

  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({
        data: dd,
        id: dd?.id,
        years: dd?.years,
        amount: dd?.amount,
        location: dd?.location,
        asset_no: dd?.asset_no,
        asset_gl: dd?.asset_gl,
        description: dd?.description,
        deep_expense: dd?.deep_expense,
        purchased_price: dd?.purchased_price,
        year_convention: dd?.year_convention,
        acc_depreciation: dd?.acc_depreciation,
        depreciation_date: dd?.depreciation_date,
        depreciation_method: dd?.depreciation_method,
        entry_date: moment(dd?.entry_date).format('MM/DD/YYYY'),
      })),
    [tableList],
  );

  const handleViewDetails = (tableData) => {
    console.log(tableData);
    const { data } = tableData;

    setFormData((prev) => {
      return {
        ...prev,
        id: data?.id,
        years: data?.years,
        amount: data?.amount,
        assetNo: data?.asset_no,
        assetGL: data?.asset_gl,
        location: data?.location,
        description: data?.description,
        deepExpense: data?.deep_expense,
        yearConvention: data?.year_convention,
        purchasedPrice: data?.purchased_price,
        accDepreciation: data?.acc_depreciation,
        depreciationMethod: data?.depreciation_method,
        entryDate: moment(data?.entry_date).format('MM/DD/YYYY'),
        depreciationDate: moment(data?.depreciation_date).format('MM/DD/YYYY'),
      };
    });

    setShowScreen(8.5);
    setRequestData(data);
  };

  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, entryDateData, entryDateLogs);

  return (
    <div className='credentials-credits'>
      <div className='header'>

        <div className='functions'>
          <Button className='upload' onClick={() => setShowScreen(8)}>
            <AddSVG />
            Add Record
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <span
                className='badge yellow'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // setRequestData(data);

                  // setShowScreen(8.5);
                  handleViewDetails(data);
                }
                }
              >
                View Details
              </span>
            );
          }}
        />
      </div>
    </div>
  );
};

const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getEntryDateList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, entryDateData, entryDateLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = entryDateLogs;

    if (isSuccess) {
      const newData = entryDateData.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          years: dd?.years,
          amount: dd?.amount,
          location: dd?.location,
          asset_no: dd?.asset_no,
          asset_gl: dd?.asset_gl,
          entry_date: dd?.entry_date,
          description: dd?.description,
          deep_expense: dd?.deep_expense,
          year_convention: dd?.year_convention,
          purchased_price: dd?.purchased_price,
          acc_depreciation: dd?.acc_depreciation,
          depreciation_date: dd?.depreciation_date,
          depreciation_method: dd?.depreciation_method,
        };
      });
      console.log(newData);
      setTableList(newData);
    }
  }, [entryDateLogs]);
};
export default index;
