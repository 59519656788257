/* eslint-disable camelcase */

// import core & vendor packages 
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';

// import components 
import FormUI from 'components/UI/FormUI';

// import assets
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const ViewItem = ({ showProfile, setShowProfile, state }) => {
  return (
    <Modal
      size='lg'
      show={showProfile.viewItem}
      onHide={() => {
        setShowProfile((prev) => {
          return { ...prev, viewItem: false };
        });
      }}
      aria-labelledby='Goods receipt inforation'
      className='goods-receipt-information-modals'
    >
      <Modal.Body>
        <div className='inventory-good-receipts'>
          <div className='d-flex header'>
            <p>GOODS RECEIPT INFORMATION</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prev) => {
                  return { ...prev, viewItem: false, isShowViewRequestModal: true };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='view-form' style={{ borderBottom: '1px solid #03A8FF' }}>
            <Row className='mb-4'>
              <Col>
                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Code :</Form.Label>
                  <Form.Control
                    className='no-border'
                    type='text'
                    value={state?.selected_item?.attributes?.code}
                    style={{ color: '#006397', background: 'none' }}
                    disabled
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Name :</Form.Label>
                  <Form.Control
                    className='no-border'
                    type='text'
                    value={state?.selected_item?.attributes?.name}
                    style={{ color: '#006397', background: 'none' }}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Purchase Order No. :</Form.Label>
                  <Form.Control
                    className='no-border'
                    type='text'
                    value={state?.itemID?.attributes?.request_number}
                    style={{ color: '#006397', background: 'none' }}
                    disabled
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Ordered Quantity & UOM :</Form.Label>
                  <Form.Control
                    className='no-border'
                    type='text'
                    value={`${state?.selected_item?.quantity} ${state?.selected_item?.attributes?.unit_of_measure}`}
                    style={{ color: '#006397', background: 'none' }}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={8}>
                <Form.Group className='mb-3'>
                  <Form.Label>Item Code Name</Form.Label>
                  <Form.Control
                    className='no-border'
                    type='text'
                    value={state?.selected_item?.attributes?.name}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={9}>
                <Row>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Requested Quantity</Form.Label>
                      <Form.Control
                        disabled
                        className='no-border'
                        type='text'
                        value={state?.selected_item?.quantity}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Partial / Completed</Form.Label>
                      <Form.Control
                        disabled
                        className='no-border'
                        type='text'
                        value={state?.selected_item?.status}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Delivered Quantity</Form.Label>
                      <Form.Control
                        disabled
                        className='no-border'
                        type='text'
                        value={state?.selected_item?.delivered_quantity}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <div className='text-center mt-5'>
              <h6>Upload Image</h6>
              <p>PNG and JPG filres are allowed</p>
              <img
                style={{ height: '200px' }}
                className='img-fluid'
                src='https://cdn.shopify.com/s/files/1/0472/7118/2499/products/DowellTF9A16AS.jpg?v=1632392918'
                alt='img'
              />
            </div> */}
            <div className='d-flex justify-content-end'>
              <Button
                className='mx-2 bg-danger '
                style={{ border: 'none' }}
                onClick={() => {
                  setShowProfile((prev) => {
                    return { ...prev, viewItem: false, isShowViewRequestModal: false };
                  });
                }}
              >
                PRINT RECEIPT
              </Button>
              <Button
                className='mx-2 bg-secondary no-border'
                style={{ border: 'none' }}
                onClick={() => {
                  setShowProfile((prev) => {
                    return { ...prev, viewItem: false, isShowViewRequestModal: true };
                  });
                }}
              >
                CANCEL
              </Button>
            </div>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ViewItem.defaultProps = {
  showModals: {},
  setShowModals: () => {},
};

ViewItem.propTypes = {
  showModals: PropTypes.object,
  setShowModals: PropTypes.func,
};

export default ViewItem;
