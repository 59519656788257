/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, approveDenyUser } from 'reducers/aclReducer';

import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './ConfirmModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const ConfirmModal = ({
  data,
  getDatas,
  modalType,
  openModal,
  setModalType,
  setOpenModal,
  searchAllTabs,
}) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    setOpenModal(false);
  };

  if (!modalType) return;

  const isDelete = modalType === 'delete';
  const isDeny = modalType === 'deny';
  const isApprove = modalType === 'approve';

  const deleteData = async () => {
    const result = await dispatch(deleteUser(data.id));

    if (result) {
      if (result.meta.requestStatus === 'fulfilled') {
        getDatas();
        closeModal();
      }
    }
  };

  const denyApproveUser = async () => {
    let params = {};

    if (isApprove) {
      params = {
        id: data?.id,
        payload: {
          account_status: isApprove,
          is_denied: false,
        },
      };
    }

    if (isDeny) {
      params = {
        id: data?.id,
        payload: {
          account_status: isDeny,
          is_denied: true,
        },
      };
    }

    const result = await dispatch(approveDenyUser(params));

    if (result) {
      if (result.meta.requestStatus === 'fulfilled') {
        getDatas();
        closeModal();

        if (isApprove) {
          toast.success('Successully approved.');
        } else {
          toast.success('Successully denied. User will be added to denied users.');
        }
      }
    }
  };

  return (
    <Modal className='deleteModal-confirmation' show={openModal} onHide={closeModal} centered>
      <Modal.Header>
        <h6>CONFIRMATION</h6>
        <Button onClick={closeModal}>
          <CloseSVG />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <ConditionalRender
          condition={isDelete}
          renderIf={
            <Button onClick={deleteData} className='remove'>
              Remove Access
            </Button>
          }
        />

        <ConditionalRender
          condition={isDeny}
          renderIf={
            <Button onClick={denyApproveUser} className='remove'>
              Deny User
            </Button>
          }
        />

        <ConditionalRender
          condition={isApprove}
          renderIf={
            <Button onClick={denyApproveUser} className='approve'>
              Approve User
            </Button>
          }
        />

        <Button onClick={closeModal} className='cancel'>
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  openModal: false,
  setOpenModal: () => {},
  data: {},
  searchAllTabs: () => {},
  getDatas: () => {},
};

ConfirmModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  data: PropTypes.object,
  searchAllTabs: PropTypes.func,
  getDatas: PropTypes.func,
};

export default ConfirmModal;
