/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { sendOTP } from './thunks/forgotPasswordThunks';

const initialLogs = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  data: [],
  logs: initialLogs,
};

const slice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    resetSubmitLogs: (state) => {
      state.submitLogs = initialLogs;
    },
  },
  extraReducers: {
    [sendOTP.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.logs = {
        ...state.logs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [sendOTP.pending]: (state) => {
      state.logs = {
        ...state.logs,
        isFailed: false,
        isLoading: true,
        isSuccess: false,
        error: '',
      };
    },
    [sendOTP.rejected]: (state) => {
      state.data = [];
      state.logs = {
        ...state.logs,
        isFailed: true,
        isLoading: false,
      };
    },
  },
});

export const { actions: forgotPasswordActions, reducer: forgotPasswordReducer } = slice;

export const { resetSubmitLogs } = forgotPasswordActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.forgotPassword || initialState;

export const selectforgotPassword = createSelector(
  [selectDomain],
  (forgotPasswordState) => forgotPasswordState,
);

/* --------------------------------------------------------------------------------- */

export { sendOTP };
