/* eslint-disable */
import { Modal, Form, Table, Button } from "react-bootstrap"
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";

import { createChartOfAccounts } from 'reducers/accountingReducer';

import './UploadSheet.scss';

const UploadSheet = ({
    showSheetUpload,
    handleCloseUploadModal,
    handleFileUpload,
    uploadedData,
    setUploadedData,
}) => {
    const dispatch = useDispatch();

    const handleAddFileData = () => {
        uploadedData?.forEach((data) => {
            dispatch(createChartOfAccounts(data));
        });
        setUploadedData([]);
        handleCloseUploadModal();
        toast.success('Successfully added data.')
    }

    return (
        <Modal size='lg' show={showSheetUpload} onHide={handleCloseUploadModal}>
            <Modal.Header className="upload_header" closeButton>
                <Modal.Title>Upload File</Modal.Title>
            </Modal.Header>
            <Modal.Body className="upload_body" closeButton>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload file using the standard excel template</Form.Label>
                    <Form.Control type="file" onChange={handleFileUpload} />
                </Form.Group>
                {uploadedData.length ? (
                    <>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>ACCOUNT TYPE</th>
                                    <th>DETAIL TYPE</th>
                                    <th>DESCRIPTION</th>
                                    <th>DEBIT</th>
                                    <th>CREDIT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {uploadedData.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data?.name}</td>
                                        <td>{data?.account_type}</td>
                                        <td>{data?.detail_type}</td>
                                        <td>{data?.description}</td>
                                        <td>{data?.debit}</td>
                                        <td>{data?.credit}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Button 
                            className='add_file_data_btn' 
                            variant='success'
                            onClick={handleAddFileData}
                        >
                            Add data to list
                        </Button>
                    </>
                ): null}
            </Modal.Body>
        </Modal>
    )
}

export default UploadSheet;