/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getAppointment,
  showAppointment,
  createAppointment,
  updateAppointment,
  setAppointmentComType,
} from './thunks/appointmentThunks';
import { toast } from 'react-toastify';
import _ from 'lodash';

const initialState = {
  data: [],
  item: null,
  logs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  activeTab: 'Search Patient',
  action: {
    isCreate: true,
    isView: false,
    isEdit: false,
  },
};

const slice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    resetLogState: (state, action) => {
      state.logs.isSuccess = false;
      state.logs.isFailed = false;
      state.logs.error = null;
    },
    setAppointmentAction: (state, action) => {
      state.action.isCreate = action.payload.isCreate ?? false;
      state.action.isView = action.payload.isView ?? false;
      state.action.isEdit = action.payload.isEdit ?? false;
    },
    resetAppointmentAction: (state) => {
      state.action.isCreate = true;
      state.action.isView = false;
      state.action.isEdit = false;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    resetActiveTab: (state) => {
      state.activeTab = 'Search Patient';
    },
  },
  extraReducers: {
    [getAppointment.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [getAppointment.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getAppointment.rejected]: (state, action) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action.payload.data;
    },

    [showAppointment.fulfilled]: (state, action) => {
      state.item = action.payload.data;
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [showAppointment.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [showAppointment.rejected]: (state, action) => {
      state.item = null;
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action.payload.data;
    },

    [createAppointment.fulfilled]: (state, action) => {
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
      toast.success('Successfully created!');
    },
    [createAppointment.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [createAppointment.rejected]: (state, action) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action.payload.data;
      toast.success('Something went wrong!');
    },

    [updateAppointment.fulfilled]: (state, action) => {
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
      state.action.isCreate = true;
      state.action.isView = false;
      state.action.isEdit = false;
      state.activeTab = 'New Appointment Request';
      toast.success('Successfully updated!');
    },
    [updateAppointment.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [updateAppointment.rejected]: (state, action) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action.payload.data;
      toast.success('Something went wrong!');
    },

    [setAppointmentComType.fulfilled]: (state, action) => {
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
      state.action.isCreate = true;
      state.action.isView = false;
      state.action.isEdit = false;
      toast.success('Successfully updated!');
    },
    [setAppointmentComType.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [setAppointmentComType.rejected]: (state, action) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action.payload.data;
      toast.success('Something went wrong!');
    },
  },
});

export const { actions: appointmentActions, reducer: appointmentReducer } = slice;

export const {
  resetLogState,
  setAppointmentAction,
  resetAppointmentAction,
  setActiveTab,
  resetActiveTab,
} = appointmentActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.appointment || initialState;

export const selectAppointment = createSelector(
  [selectDomain],
  (appointmentState) => appointmentState,
);

export const selectAppointmentData = createSelector(
  [selectDomain],
  (appointmentState) => appointmentState.data,
);

export const selectAppointmentItem = createSelector(
  [selectDomain],
  (appointmentState) => appointmentState.item,
);

export const selectAppointmentLogs = createSelector(
  [selectDomain],
  (appointmentState) => appointmentState.logs,
);

export const selectAppointmentActiveTab = createSelector(
  [selectDomain],
  (appointmentState) => appointmentState.activeTab,
);

export const selectAppointmentAction = createSelector(
  [selectDomain],
  (appointmentState) => appointmentState.action,
);

/* --------------------------------------------------------------------------------- */

export {
  getAppointment,
  showAppointment,
  createAppointment,
  updateAppointment,
  setAppointmentComType,
};
