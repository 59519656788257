/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getPatients = createAsyncThunk(
  'patient/',
  async (filter = {}, { rejectWithValue }) => {
    return await axios
      .get('/patient/', {
        params: {
          patient_status: filter?.patient_status,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getPatient = createAsyncThunk(
  'patient/getPatient',
  async (filter = {}, { rejectWithValue }) => {
    return await axios
      .get('/patient/search', {
        params: {
          MRN: filter?.MRN,
          first_name: filter?.first_name,
          middle_name: filter?.middle_name,
          last_name: filter?.last_name,
          suffix: filter?.suffix,
          date_of_birth: filter?.date_of_birth,
          sex: filter?.sex,
          patient_status: filter?.patient_status,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showPatient = createAsyncThunk(
  'patient/showPatient',
  async (id, { rejectWithValue }) => {
    return await axios
      .get(`/patient/${id}`, {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getPatientDiets = createAsyncThunk(
  'patient/getDiets',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `/api/diets`,
      baseURL: process.env.REACT_APP_API_BASE_PATIENT_SVC,
      params: params,
    }).catch((error) => {
      return rejectWithValue(error.response.data);
    });
  },
);

export const createPatientDiet = createAsyncThunk(
  'patient/createDiet',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `/api/diets`,
      baseURL: process.env.REACT_APP_API_BASE_PATIENT_SVC,
      data: params,
    }).catch((error) => {
      return rejectWithValue(error.response.data);
    });
  },
);

export const getPatientMeals = createAsyncThunk(
  'patient/getMeals',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `/api/meals`,
      baseURL: process.env.REACT_APP_API_BASE_PATIENT_SVC,
      params: params,
    }).catch((error) => {
      return rejectWithValue(error.response.data);
    });
  },
);

export const createPatientMeal = createAsyncThunk(
  'patient/createMeals',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `/api/meals`,
      baseURL: process.env.REACT_APP_API_BASE_PATIENT_SVC,
      data: params,
    }).catch((error) => {
      return rejectWithValue(error.response.data);
    });
  },
);

export const updatePatientDiet = createAsyncThunk(
  'patient/updateDiet',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `/api/diets/` + params.id,
      baseURL: process.env.REACT_APP_API_BASE_PATIENT_SVC,
      data: params,
    }).catch((error) => {
      return rejectWithValue(error.response.data);
    });
  },
);

export const updatePatientMeal = createAsyncThunk(
  'patient/updateMeal',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `/api/meals/` + params.id,
      baseURL: process.env.REACT_APP_API_BASE_PATIENT_SVC,
      data: params,
    }).catch((error) => {
      return rejectWithValue(error.response.data);
    });
  },
);

export const deletePatientDiet = createAsyncThunk(
  'patient/deleteDiet',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'delete',
      url: `/api/diets/` + params.id,
      baseURL: process.env.REACT_APP_API_BASE_PATIENT_SVC,
      data: params,
    }).catch((error) => {
      return rejectWithValue(error.response.data);
    });
  },
);

export const deletePatientMeal = createAsyncThunk(
  'patient/deleteMeal',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'delete',
      url: `/api/meals/` + params.id,
      baseURL: process.env.REACT_APP_API_BASE_PATIENT_SVC,
      data: params,
    }).catch((error) => {
      return rejectWithValue(error.response.data);
    });
  },
);

export const getCurrentDiet = createAsyncThunk(
  'patient/getCurrentDiet',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `/api/diets/current-diet?user_id=` + params.user_id,
      baseURL: process.env.REACT_APP_API_BASE_PATIENT_SVC,
      data: params,
    }).catch((error) => {
      return rejectWithValue(error.response.data);
    });
  },
);
