/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { toast } from 'react-toastify';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// import components below
import FormLoginUI from 'components/UI/FormLoginUI';
import { sendOTP } from 'reducers/forgotPasswordReducer';

// import assets below
import './Form.scss';
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/chevron-left.svg';

// main component
const FormForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [stepper, setStepper] = useState(1);

  const handleSendOTP = async () => {
    const data = {
      email: email,
    };

    const response = await dispatch(sendOTP(data));

    if (response.meta.requestStatus === 'rejected') {
      console.log(response);
      toast.error('Something went wrong, Please refresh the page.');

      return;
    }

    if (response.meta.requestStatus === 'fulfilled') {
      toast.success('Successfully send OTP.');
      setStepper(2);
      return;
    }
  };

  return (
    <Fragment>
      {stepper === 1 && (
        <FormLoginUI>
          <div className='formContent'>
            <div className='form__header'>
              <div className='wrapper'>
                <Button variant='transparent' onClick={() => navigate('/')}>
                  <ArrowLeftSVG />
                </Button>
                <h6>Request User Account</h6>
              </div>
              <Row>
                <p className='my-4'>
                  Please Enter your email address to receive a verification code.
                </p>
              </Row>
            </div>
            <div className='form__body'>
              <Form.Group className='mb-3'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  value={email}
                  placeholder='Enter here'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className='form__footer'>
              <Button className='my-3' onClick={handleSendOTP}>
                Send
              </Button>
            </div>
          </div>
        </FormLoginUI>
      )}
      {stepper === 2 && (
        <FormLoginUI>
          <div className='formContent'>
            <div className='form__header'>
              <div className='wrapper'>
                <Button variant='transparent' onClick={() => navigate('/')}>
                  <ArrowLeftSVG />
                </Button>
                <h6>Verify Your Email</h6>
              </div>
              <Row>
                <p className='my-4'>Please enter the 4 Digit Code sent to {email}.</p>
              </Row>
            </div>
            <div className='form__body'>
              <Form.Group className='codeWrapper'>
                <Form.Control className='form-control--code' type='text' />
                <Form.Control className='form-control--code' type='text' />
                <Form.Control className='form-control--code' type='text' />
                <Form.Control className='form-control--code' type='text' />
              </Form.Group>
            </div>
            <div className='form__footer'>
              <Button className='my-3' onClick={() => setStepper(3)}>
                Send
              </Button>
            </div>
          </div>
        </FormLoginUI>
      )}
      {stepper === 3 && (
        <FormLoginUI>
          <div className='formContent'>
            <div className='form__header'>
              <div className='wrapper'>
                <Button variant='transparent' onClick={() => navigate('/')}>
                  <ArrowLeftSVG />
                </Button>
                <h6>Create New Password</h6>
              </div>
              <Row>
                <p className='my-4'>
                  Your new password must be different from previously used password.
                </p>
              </Row>
            </div>
            <div className='form__body'>
              <Form.Group className='mb-3'>
                <Form.Label>New Passwordd</Form.Label>
                <Form.Control type='password' placeholder='Enter here' />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type='password' placeholder='Enter here' />
              </Form.Group>
            </div>
            <div className='form__footer'>
              <Button className='my-3' onClick={() => alert('Clicked!')}>
                Save
              </Button>
            </div>
          </div>
        </FormLoginUI>
      )}
    </Fragment>
  );
};

export default FormForgotPassword;
