import React, { useState, useEffect } from 'react';
/* eslint-disable camelcase */
// import core & vendor packages below
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';
import FormUI from 'components/UI/FormUI';

// import assets below
import './CreateDietaryModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createPatientDiet, selectPatient } from 'reducers/patientReducer';
import { toast } from 'react-toastify';

// main componet
const CreateDietaryModal = (props) => {
  const dispatch = useDispatch();

  const { status, onToggle } = props;

  const groupState = useSelector(selectPatient);
  const { createdDiet, item } = groupState;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    user_id: item?.id + '',
    start_date: '',
    end_date: '',
    diet_type: '',
  });

  const handleSubmit = () => {
    toast.info(`Creating Diet`);
    setIsSubmitted(true);
    dispatch(createPatientDiet({ ...formData }));
  };

  useEffect(() => {
    if (isSubmitted) {
      const { isFailed, isSuccess, error } = createdDiet;

      if (isFailed) {
        error?.map((error) => {
          toast.error(`${error.detail} (${error.code})`);
        });
      }

      if (isSuccess) {
        toast.info(`Diet created successfully`);
        onToggle(false);
        setIsSubmitted(false);
      }
    }
  }, [createdDiet]);

  const handleChange = (key) => (e) => {
    let value = e.target.value;
    switch (key) {
      case 'start_date':
        value = value + ' 00:00';
        break;
      case 'end_date':
        value = value + ' 23:59';
        break;
    }

    setFormData({
      ...formData,
      [key]: value,
    });

    return;
  };

  return (
    <div className='createDietaryModal'>
      <ModalUI>
        <Modal.Dialog>
          <Modal.Header>
            <h4>CREATE DIET</h4>
            <Button onClick={() => onToggle(!status)} variant='transparent'>
              <CloseSVG />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className='createDietaryModal__content'>
              <FormUI>
                <Row>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Diet Type</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter here'
                        onChange={handleChange('diet_type')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Start Date/Time</Form.Label>
                      <Form.Control type='date' onChange={handleChange('start_date')} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>End Date/Time</Form.Label>
                      <Form.Control type='date' onChange={handleChange('end_date')} />
                    </Form.Group>
                  </Col>
                </Row>
              </FormUI>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSubmit} variant='addField' className='m-2'>
              Create
            </Button>
            <Button onClick={() => onToggle(!status)} variant='cancel'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </ModalUI>
    </div>
  );
};

export default CreateDietaryModal;
