/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

// import components below
import { billingHistoryClass } from 'helpers';
import Table from 'components/ReactTable';

// import assets below
import './index.scss';
// main component
const BillingHistoryTable = () => {
  // const state = useSelector(selectBillingData);

  // Sample Table List
  const state = [
    {
      patientId: 1,
      amount: '15,000',
      billing_number: '14578220',
      date_time: '01/20/2023',
      status: 'For Billing',
    },
    {
      patientId: 1,
      amount: '15,000',
      billing_number: '14578220',
      date_time: '01/20/2023',
      status: 'Completed',
    },
  ];

  useEffect(() => {
    console.log('TABLE', state);
  }, [state]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Billing Number', accessor: 'billing_number' },
      { Header: 'Date / Time', accessor: 'date_time' },
      { Header: 'Amount', accessor: 'amount' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        data: dd,
        amount: `P${dd?.amount}`,
        patientId: dd?.patientId,
        status: billingHistoryClass(dd?.status),
        billing_number: dd?.billing_number,
        date_time: moment(dd?.createdAt).format('MMM-DD-YYYY hh:mm A'),
      })),
    [state],
  );

  return (
    <div className='billingHistoryTable'>
      <Table
        data={tableData}
        columns={tableColumns}
        customAction={(id) => {
          const row = state.find((dd) => dd.patientId === id);

          return (
            <div className='table-buttons'>
              <Button className='export'>Export</Button>
              <Button className='print'>Print</Button>
            </div>
          );
        }}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default BillingHistoryTable;
