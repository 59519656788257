// import components below
import PropTypes from 'prop-types';
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const AdmissionHeader = () => {
  return (
    <div className='admissionHeader'>
      <PageHeaderUI>
        <h4>Laboratory</h4>
      </PageHeaderUI>
    </div>
  );
};

AdmissionHeader.defaultProps = {
  active: '',
};

AdmissionHeader.propTypes = {
  active: PropTypes.string,
};

export default AdmissionHeader;
