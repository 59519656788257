/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table, { PAGE_SIZE } from 'components/ReactTable';
import { selectEmployeeSchedules, getAllEmployeeSchedules } from 'reducers/employeeScheduleReducer';
// import axios from 'axios';

// Queries
const params = {
  current_page: 1,
  per_page: PAGE_SIZE[0], // [10, 20, 30, 100]
};

const Shifting = ({ employeeId }) => {
  const [empScheduleData, setEmpScheduleData] = useState([]);
  const schedules = useSelector(selectEmployeeSchedules);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEmployeeSchedules(params));
  }, []);

  useEffect(() => {
    if (employeeId) {
      if (schedules?.data?.length > 0) {
        const scheduleData = schedules.data.find((sched) => sched.id === employeeId);
        if (scheduleData) {
          setEmpScheduleData(scheduleData?.employee_schedules || []);
        }
      }
    }
  }, [employeeId]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Date', accessor: 'date' }, // accessor is the "key" in the data
      { Header: 'Start of Shift Time', accessor: 'start_time' },
      { Header: 'End of Shift Time', accessor: 'end_time' },
      { Header: 'Deployment', accessor: 'deployment' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      empScheduleData?.map((dd) => ({
        id: dd?.id,
        date: dd?.assigned_date,
        start_time: dd?.time_in,
        end_time: dd?.time_out,
        deployment: dd?.deployment,
      })),
    [empScheduleData],
  );

  return (
    <div className='shifting-tab pt-3'>
      <p className='shifting-schedule'>Shifting Schedule</p>
      <Table
        data={tableData}
        columns={tableColumns}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default Shifting;
