import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import PharmacyHeader from './components/PageHeader';
import PharmacyTabs from './components/Tabs';

// import assets below
import './style.scss';

// main component
const Pharmacy = () => {
  const [activeTab, setActiveTab] = useState('Order Worklist');

  return (
    <Layout pageTitle='Pharmacy'>
      <PharmacyHeader active={activeTab} />
      <PharmacyTabs onChange={setActiveTab} />
    </Layout>
  );
};

export default Pharmacy;
