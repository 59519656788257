/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getChartOfAccounts = createAsyncThunk(
  'accounting/chartOfAccounts',
  async (filter = {}, { rejectWithValue }) => {
    return await axios
      .get('accounting/chart_account', {
          name: filter?.name,
          account_type: filter?.account_type,
          detail_type: filter?.detail_type,
          description: filter?.description,
          is_sub_account: filter?.is_sub_account,
          parent_account: filter?.parent_account,
          debit: filter?.debit,
          credit: filter?.credit,
          as_of: filter?.as_of,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showChartOfAccount = createAsyncThunk(
  'SHOW_CHART_OF_ACCOUNTS',
  async (id = {}, { rejectWithValue }) => {
    return await axios
      .get(`accounting/chart_account/${id}`, {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createChartOfAccounts = createAsyncThunk(
  'accounting/chartOfAccounts',
  async (params = {}, { rejectWithValue }) => {
    return await axios
      .post('accounting/chart_account', {
        name: params?.name,
        account_type: params?.account_type,
        detail_type: params?.detail_type,
        description: params?.description,
        is_sub_account: params?.is_sub_account,
        parent_account: params?.parent_account,
        debit: params?.debit,
        credit: params?.credit,
        as_of: params?.as_of,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateChartOfAccounts = createAsyncThunk(
  'UPDATE_CHART_OF_ACCOUNTS',
  async (params = {}, { rejectWithValue }) => {
    return await axios
      .patch(`accounting/chart_account/${params.id}`, {
        name: params?.name,
        account_type: params?.account_type,
        detail_type: params?.detail_type,
        description: params?.description,
        is_sub_account: params?.is_sub_account,
        parent_account: params?.parent_account,
        debit: params?.debit,
        credit: params?.credit,
        as_of: params?.as_of,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getCashFlow = createAsyncThunk(
  'accounting/financial-statement/cashflow',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'GET',
      url: `cash_flow`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
export const setCashFlow = createAsyncThunk(
  'accounting/financial-statement/add-cashflow',
  async (params = {}, { rejectWithValue }) => {
    return await axios
      .post('/cash_flow', {
        field: params?.field,
        amount: params?.amount,
        cash_in: params?.cash_in,
        cash_out: params?.cash_out,
        date: params?.date,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
export const updateCashFlow = createAsyncThunk(
  'accounting/financial-statement/update-cashflow',
  async (params = {}, { rejectWithValue }) => {
    return await axios
      .put(`/cash_flow/${params.id}`, {
        field: params?.field,
        amount: params?.amount,
        cash_in: params?.cash_in,
        cash_out: params?.cash_out,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const removeCashFlow = createAsyncThunk(
  'accounting/financial-statement/remove-cashflow',
  async (id, { rejectWithValue }) => {
    return await axios
      .delete(`/cash_flow/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const searchReportIncomeStatement = createAsyncThunk(
  'reports/income-statement',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `income_statement`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getFinancialStatement = createAsyncThunk(
  'accounting/financial-statement',
  async ({ search }, { rejectWithValue }) => {
    return axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/financial_statement?date=${search}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);
