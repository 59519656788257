import React from 'react';
import { Table } from 'react-bootstrap';
function TableHospitalBill({
  roomCharges,
  laboratory,
  LaboratoryGross,
  roomAndBoardGross,
  totalHospitalBill,
}) {
  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th style={{ borderBottom: 'none' }}>Billing Group</th>
            <th style={{ borderBottom: 'none' }}>Net Amount Due</th>
          </tr>
        </thead>
      </Table>
      {/* Room and Board  */}
      {roomCharges ? (
        <Table responsive>
          <thead>
            <tr>
              <th>Room and Board</th>
              <th>{roomAndBoardGross[0]?.total | '--'}</th>
            </tr>
          </thead>
          <tbody>
            {roomCharges
              ? roomCharges.map((data, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{data?.room}</td>
                      <td>{data?.amount}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      ) : null}

      {/* Pharmacy  */}
      {/* <Table responsive>
        <thead>
          <tr>
            <th>Pharmacy</th>
            <th>6,500.00</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Room 1</td>
            <td>2,000</td>
          </tr>
          <tr>
            <td>Room 2</td>
            <td>2,000</td>
          </tr>
          <tr>
            <td>Bed 1</td>
            <td>2,000</td>
          </tr>
          <tr>
            <td>Bed 2</td>
            <td>2,000</td>
          </tr>
        </tbody>
      </Table> */}
      {/* Radio X-ray Department  */}
      {LaboratoryGross[0]?.total === 0 ? (
        <Table responsive>
          <thead>
            <tr>
              <th>Radio X-ray Department</th>
              <th>{LaboratoryGross[0]?.total | '--'}</th>
            </tr>
          </thead>
          <tbody>
            {laboratory
              ? laboratory.map((data, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{data?.lab_type}</td>
                      <td>{data?.price}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      ) : null}

      {/* Central Sterile Supply  */}
      {/* <Table responsive>
        <thead>
          <tr>
            <th>Central Sterile Supply</th>
            <th>6,500.00</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Room 1</td>
            <td>2,000</td>
          </tr>
          <tr>
            <td>Room 2</td>
            <td>2,000</td>
          </tr>
          <tr>
            <td>Bed 1</td>
            <td>2,000</td>
          </tr>
          <tr>
            <td>Bed 2</td>
            <td>2,000</td>
          </tr>
        </tbody>
      </Table> */}
      <Table responsive>
        <thead>
          <tr>
            <th style={{ background: '#D5D5D5', borderBottom: 'none' }}>TOTAL HOSPITAL BILL</th>
            <th style={{ background: '#A5A5A5', borderBottom: 'none' }}>Php {totalHospitalBill}</th>
          </tr>
        </thead>
      </Table>
    </>
  );
}

export default TableHospitalBill;
