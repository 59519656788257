/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Row, Form, Col, Button, Image } from 'react-bootstrap';

import './MedicalHistory.scss';

const MedicalHistory = ({ showProfile, setShowProfile }) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const handleChangePage = (data) => {
    const stateToPass = {
      ...data,
      isModalShow: true,
      active: 'Active Patient',
      profileId: showProfile.profileId,
    };

    navigate('/dashboard/medical-records', { state: stateToPass });
  };

  useEffect(() => {
    axios
      .get(`/medical_records/${showProfile.profileId}`)
      // .get(`/medical_records/72`)
      .then((response) => {
        setData(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  useEffect(() => {
    // console.log('showProfile', showProfile);
  }, [showProfile]);

  return (
    <div className='medical-history'>
      <Row className='mb-4'>
        <Col className='card-container'>
          <Form.Group>
            {/* <Form.Label>Visit History</Form.Label> */}

            {data?.history?.length > 0 ? (
              data?.history?.map((item, key) => {
                return (
                  <div
                    onClick={() => {
                      handleChangePage(item);
                    }}
                    className='card mb-2'
                    key={key}
                  >
                    <Row>
                      <Col sm={3}>
                        <p>
                          Visit Date <span>{item?.date}</span>
                        </p>
                      </Col>
                      <Col sm={3}>
                        <p>
                          Department / Clinic <span>{item?.department}</span>
                        </p>
                      </Col>
                      <Col sm={3}>
                        <p>
                          Doctors:
                          {item?.doctors?.length > 0 ? (
                            item?.doctors?.map((item, key) => {
                              return (
                                <span className='small' key={key}>
                                  Dr. {item?.user?.first_name} {item?.user?.middle_name}{' '}
                                  {item?.user?.last_name}
                                </span>
                              );
                            })
                          ) : (
                            <p>-</p>
                          )}
                        </p>
                      </Col>
                      <Col className='arrow'>{/* <ArrowSVG /> */}</Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <p>No past record found</p>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default MedicalHistory;
