// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

// Component
import GoodsReceiptsTable from './Table';

// Main Component
const PurchaseOrderList = ({ setState, setShowProfile, state, showProfile }) => {
  return (
    <div className='purchase-order-list'>
      <Row>
        <Col>
          <GoodsReceiptsTable
            state={state}
            showProfile={showProfile}
            setState={setState}
            setShowProfile={setShowProfile}
          />
        </Col>
      </Row>
    </div>
  );
};

PurchaseOrderList.defaultProps = {
  setState: () => {},
  setShowProfile: () => {},
};

PurchaseOrderList.propTypes = {
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default PurchaseOrderList;
