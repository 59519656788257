/* eslint-disable react/jsx-indent-props */
import TableUI from 'components/UI/TableUI';
import { FormControl, InputGroup, Table } from 'react-bootstrap';

import './TestingTable.scss';

const TestingTable = (props) => {
    const { item } = props;
    return (<div className='mt-3 testing'>
        <TableUI>
            <Table striped hover style={{ width: '80%' }} className='mt-3'>
                <thead>
                    <tr>
                        <th>Total number of medical imaging tests (all types including x-rays, ultrasound, CT scans, etc., whether ER, OPD or Inpatient.(DO NOT INCLUDE PROCEDURES DONE OR REFERRED OUTSIDE)</th>
                        <th>Number</th>
                    </tr>
                </thead>
                <tbody>
                    {item.imagingTests.map((value, index) => {
                        return (
                            <tr key={index + 1}>
                                <td>{index + 1}. {value.testing}</td>
                                <td><InputGroup className='mb-1 mt-1'>
                                    <FormControl
                                        placeholder='Enter Here'
                                        disabled
                                        value={value.number}
                                    />
                                </InputGroup></td>
                            </tr>);
                    })}
                </tbody>
            </Table>
        </TableUI>

        <TableUI>
            <Table striped hover style={{ width: '80%' }} className='mt-3'>
                <thead>
                    <tr>
                        <th>Total number of laboratory and diagnostic tests (all types, excluding medical imaging whether ER, OPD or Inpatient. ( DO NOT INCLUDE TESTS DONE OR REFERRED OUTSIDE)</th>
                        <th>Number</th>
                    </tr>

                </thead>
                <tbody>
                    {item.labTests.map((value, index) => {
                        return (
                            <tr key={index + 1}>
                                <td>{index + 1}. {value.testing}</td>
                                <td><InputGroup className='mb-1 mt-1'>
                                    <FormControl
                                        placeholder='Enter Here'
                                        disabled
                                        value={value.number}
                                    />
                                </InputGroup></td>
                            </tr>);
                    })}
                </tbody>
            </Table>
        </TableUI>

        <TableUI>
            <Table striped hover style={{ width: '80%' }} className='mt-3'>
                <thead>
                    <tr>
                        <th>Emergency visits (NON-EMERGENCY CASES SEEN IN ER SHALL BE INCLUDED IN OPD REPORT)</th>
                        <th>Number</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Total number of emergency department visits</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesEV.emergencyvisits}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Total number of emergency department visits, adult</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesEV.emergencyvisitsadult}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Total number of emergency department visits, pediatric</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesEV.emergencyvisitspediatric}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Total number of patients transported TOTHIS FACILITY’S EMERGENCY DEPARTMENT from other health facilities i.e. RHU, Medical Clinic, Infirmary, other hospital)</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesEV.evfromfacilitytoanother}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Total number of patients transported FROM THIS FACILITY’S EMERGENCY DEPARTMENT to another facility for inpatient care</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesEV.evtofacilityfromanother}
                            />
                        </InputGroup></td>
                    </tr>
                </tbody>
            </Table>
        </TableUI>

        <TableUI>
            <Table striped hover style={{ width: '80%' }} className='mt-3'>
                <thead>
                    <tr>
                        <th>Ten Leading ER Consultations (DO NOT INCLUDE NON-EMERGENCY CASES)</th>
                        <th>Number</th>
                        <th>ICD-10 Code (Tabular)</th>
                    </tr>
                </thead>
                <tbody>
                    {item.hospOptDischargesER?.map((item, index) => (
                        <tr key={index + 1}>
                            <td>{index + 1}. {item.name}</td>
                            <td><InputGroup className='mb-1 mt-1'>
                                <FormControl
                                    placeholder='Enter Here'
                                    disabled
                                    value={item.number}
                                />
                            </InputGroup></td>
                            <td><InputGroup className='mb-1 mt-1'>
                                <FormControl
                                    placeholder='Enter Here'
                                    disabled
                                    value={item.icd_code}
                                />
                            </InputGroup></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </TableUI>
    </div>);
};

export default TestingTable;
