/* eslint-disable no-unused-vars */
import { useState } from 'react';
// import components below
import Layout from 'components/Layout';
import PhilhealthHeader from './components/PageHeader';
import PhilhealthTabs from './components/Tabs';

// import assets below
import './style.scss';

// main component
const Philhealth = () => {
  const [activeTab, setActiveTab] = useState('Patient Worklist');
  return (
    <Layout pageTitle='Philhealth'>
      <PhilhealthHeader active={activeTab} />
      <PhilhealthTabs onChange={setActiveTab} />
    </Layout>
  );
};

export default Philhealth;
