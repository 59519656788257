// import components below
import NotesTabs from './Tabs';

// import assets below
import './style.scss';

// main component
const Notes = () => {
  return (
    <div className='notes'>
      <NotesTabs />
    </div>
  );
};

export default Notes;
