/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, OverlayTrigger, Popover, Button } from 'react-bootstrap';

import TableData from './TableData';
import ConfirmModal from './ConfirmModal';
import TableUI from 'components/UI/TableUI';
import TablePagination from './TablePagination';

import './index.scss';

const Tables = ({
  type,
  getDatas,
  tableData,
  changePage,
  isAllUsers,
  isNewUsers,
  setUserData,
  isDeniedUsers,
  searchAllTabs,
  setShowScreen,
  showResultsNumber,
  setShowResultsNumber,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState();
  const [modalType, setModalType] = useState();
  const isNotEmpty = Object.keys(tableData).length > 0;

  const meta = tableData?.meta;
  const links = tableData?.links;

  if (!isNotEmpty) {
    return;
  }

  return (
    <>
      <div className='tables-cont'>
        <TableUI>
          <Table responsive>
            <thead>
              <tr>
                <th>Account</th>
                <th>Username</th>
                <th>ID No</th>
                <th>Assigned Management</th>
                <th>User Role</th>
                <th>Status</th>
                {!isDeniedUsers && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              <TableData
                setData={setData}
                tableData={tableData}
                modalType={modalType}
                isAllUsers={isAllUsers}
                isNewUsers={isNewUsers}
                setUserData={setUserData}
                setOpenModal={setOpenModal}
                setModalType={setModalType}
                setShowScreen={setShowScreen}
                isDeniedUsers={isDeniedUsers}
              />
            </tbody>
          </Table>

          <TablePagination
            meta={meta}
            type={type}
            links={links}
            changePage={changePage}
            showResultsNumber={showResultsNumber}
            setShowResultsNumber={setShowResultsNumber}
          />
        </TableUI>
      </div>

      <ConfirmModal
        data={data}
        getDatas={getDatas}
        modalType={modalType}
        openModal={openModal}
        setModalType={setModalType}
        setOpenModal={setOpenModal}
        searchAllTabs={searchAllTabs}
      />
    </>
  );
};

Tables.defaultProps = {
  type: '',
  tableData: {},
  isAllUsers: true,
  isNewUsers: false,
  changePage: () => { },
  showResultsNumber: 10,
  searchAllTabs: () => { },
  setShowResultsNumber: () => { },
  getDatas: () => { },
};

Tables.propTypes = {
  tableData: {},
  changePage: () => { },
  showResultsNumber: 10,
  type: PropTypes.string,
  searchAllTabs: () => { },
  isAllUsers: PropTypes.bool,
  isNewUsers: PropTypes.bool,
  setShowResultsNumber: PropTypes.func,
  getDatas: PropTypes.func,
};

export default Tables;
