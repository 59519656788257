/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Button, Modal, Form, Pagination, Table } from 'react-bootstrap';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

import TableUI from 'components/UI/TableUI';
import FormUI from 'components/UI/FormUI';

import './CartModal.scss';

const CartModal = ({ openCart, setOpenCart, setCartData, cartData, setShowProfile }) => {
  // Add Quantity
  const addQuantity = (id) => {
    const newTableData = cartData.map((data) => {
      if (data.id === id) {
        const newQuantity = parseInt(data.qty) + parseInt(1);
        return { ...data, qty: newQuantity };
      } else {
        return data;
      }
    });

    setCartData(newTableData);
  };

  const deleteItem = (id) => {
    const newTableData = cartData.filter((data) => {
      return data.id !== id;
    });

    setCartData(newTableData);
  };

  return (
    <Modal
      size='lg'
      show={openCart}
      onHide={() => {
        setOpenCart(false);
        setShowProfile((prevState) => {
          return { ...prevState, addOrder: true };
        });
      }}
      aria-labelledby='My Cart Modal'
      className='my-cart-modal'
    >
      <Modal.Body>
        <div className='my-cart'>
          <div className='d-flex header'>
            <p>Order Cart</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setOpenCart(false);
                setShowProfile((prevState) => {
                  return { ...prevState, addOrder: true };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='my-cart-form'>
            <Row>
              <Col>
                {/* Filters */}
                <Row>
                  <Col sm={4}>
                    <div className='order-filter-buttons'>
                      <Button>Medication</Button>
                      <Button>Supply</Button>
                    </div>
                  </Col>
                  <Col sm={8}>
                    <div className='order-pagination'>
                      <div className='results'>
                        <p>0 Result</p>
                      </div>
                      <div className='pagination-cont'>
                        <Form.Group className='mb-4 filters'>
                          <Form.Label>Show:</Form.Label>
                          <Form.Select
                            aria-label='Show'
                            defaultValue='10'
                            onChange={() => {
                              // console.log('Changed');
                            }}
                          >
                            <option value='10'>10</option>
                            <option value='20'>20</option>
                            <option value='30'>30</option>
                          </Form.Select>
                        </Form.Group>

                        <Pagination>
                          <Pagination.Prev />
                          <Pagination.Item active>{1}</Pagination.Item>
                          <Pagination.Item>{2}</Pagination.Item>
                          <Pagination.Item>{3}</Pagination.Item>
                          <Pagination.Item>{4}</Pagination.Item>
                          <Pagination.Item>{5}</Pagination.Item>
                          <Pagination.Next />
                        </Pagination>
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* Table */}
                <Row>
                  <Col>
                    <TableUI>
                      <Table responsive className='order-table'>
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Service Unit</th>
                            <th>Unit Price</th>
                            <th>QTY</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartData &&
                            cartData.length > 0 &&
                            cartData.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    <p>{data?.code}</p>
                                  </td>
                                  <td>
                                    <p>
                                      {data?.description} <span>{data?.sub_description}</span>{' '}
                                    </p>
                                  </td>
                                  <td>
                                    <p>{data?.service_unit}</p>
                                  </td>
                                  <td>
                                    <p>{data?.unit_price}</p>
                                  </td>
                                  <td>
                                    <div className='quantity'>
                                      <p>{`${data?.qty} ${data?.unit_type || ''}`}</p>
                                      <Button
                                        className='add-quantity'
                                        onClick={() => {
                                          addQuantity(data?.id);
                                        }}
                                      >
                                        +
                                      </Button>
                                    </div>
                                  </td>
                                  <td>
                                    <p>
                                      <Button
                                        className='delete'
                                        onClick={() => {
                                          deleteItem(data?.id);
                                        }}
                                      >
                                        x
                                      </Button>
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </TableUI>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button className='add-button' variant='primary' type='submit'>
                    Save
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() => {
                      setOpenCart(false);
                      setShowProfile((prevState) => {
                        return { ...prevState, addOrder: true };
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default CartModal;
