/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getPatientRoom,
  getAvailableRooms,
  submitTransferRoom,
  getPatientsWithRooms,
} from './thunks/roomThunks';

const initialState = {
  // For Patients
  data: [],
  logs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Rooms
  roomData: [],
  roomLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Single Patient
  singleData: [],
  singleLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For submit transfer
  transferLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    resetLogState: (state, action) => {
      state.transferLogs.isSuccess = false;
      state.transferLogs.isFailed = false;
      state.transferLogs.isLoading = false;
      state.transferLogs.error = null;
    },
  },
  extraReducers: {
    // For Patients
    [getPatientsWithRooms.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
    },
    [getPatientsWithRooms.pending]: (state) => {
      state.logs.isSuccess = false;
      state.logs.isFailed = false;
      state.logs.error = '';
      state.logs.isLoading = true;
    },
    [getPatientsWithRooms.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
    },

    // For Rooms
    [getAvailableRooms.fulfilled]: (state, action) => {
      state.roomLogs.isSuccess = true;
      state.roomLogs.isLoading = false;
      state.roomData = action.payload.data;
    },
    [getAvailableRooms.pending]: (state) => {
      state.roomLogs.isSuccess = false;
      state.roomLogs.isFailed = false;
      state.roomLogs.error = '';
      state.roomLogs.isLoading = true;
    },
    [getAvailableRooms.rejected]: (state) => {
      state.roomData = [];
      state.roomLogs.isLoading = false;
      state.roomLogs.isFailed = true;
    },

    // For Rooms
    [getPatientRoom.fulfilled]: (state, action) => {
      state.singleLogs.isSuccess = true;
      state.singleLogs.isLoading = false;
      state.singleData = action.payload.data;
    },
    [getPatientRoom.pending]: (state) => {
      state.singleLogs.isSuccess = false;
      state.singleLogs.isFailed = false;
      state.singleLogs.error = '';
      state.singleLogs.isLoading = true;
    },
    [getPatientRoom.rejected]: (state) => {
      state.singleData = [];
      state.singleLogs.isLoading = false;
      state.singleLogs.isFailed = true;
    },

    // Submit Transfer
    [submitTransferRoom.fulfilled]: (state, action) => {
      state.transferLogs.isSuccess = true;
      state.transferLogs.isLoading = false;
    },
    [submitTransferRoom.pending]: (state) => {
      state.transferLogs.isSuccess = false;
      state.transferLogs.isFailed = false;
      state.transferLogs.error = '';
      state.transferLogs.isLoading = true;
    },
    [submitTransferRoom.rejected]: (state) => {
      state.transferLogs.isLoading = false;
      state.transferLogs.isFailed = true;
    },
  },
});

export const { actions: roomActions, reducer: roomReducer } = slice;

export const { resetLogState } = roomActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.room || initialState;

export const selectroom = createSelector([selectDomain], (inventoryState) => inventoryState);

/* --------------------------------------------------------------------------------- */

export { getPatientsWithRooms, getAvailableRooms, getPatientRoom, submitTransferRoom };
