/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, InputGroup, Form } from 'react-bootstrap';
import React from 'react';

// import style below
import UserAccount from 'components/UserAccount';
import { logout, setIsAuthenticated } from 'reducers/authReducer';
import { removeItem } from 'utils/storage';

import './Topbar.scss';
import { ReactComponent as MenuSVG } from 'assets/svg/menu.svg';
import { ReactComponent as UserSVG } from 'assets/svg/user_circle.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

import Select from 'react-select';
import Links from 'constant/links';

// main component
const Topbar = (props) => {
  const { toggle, onToggle } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    // dispatch(logout());
    removeItem('accessToken');

    setTimeout(() => {
      dispatch(logout());
      window.location.href = `/`;
    }, 100);
  };

  const selectPath = (path) => {
    navigate(path);
  };

  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const handleMenuButtonClick = () => {
    if (window.innerWidth <= 1200) {
      onToggle(!toggle);
    }
  };

  return (
    <div className='topbar'>
      <div className='topbar__header'>
        <span className='topbar__brand'>BigHealthMed</span>
      </div>
      <Button onClick={(handleMenuButtonClick)}>
        <MenuSVG />
      </Button>
      <InputGroup>
        <div className='search-container'>
          <Select
            className='select-search'
            menuIsOpen={menuIsOpen}
            menuPlacement='auto'
            menuPosition='fixed'
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            onInputChange={(input) => {
              setMenuIsOpen(input);
            }}
            onChange={(search) => selectPath(search.path)}
            isClearable={true}
            options={Links}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.path}
            placeholder={
              <div className='d-flex justify-content-between'>
                <span>Search</span>
                <div className='magnifying-background'>
                  <MagnifyingGlassSVG width='15' className={'magnifying'} />
                </div>
              </div>
            }
            styles={{
              control: (provided) => ({
                ...provided,
                borderRadius: '100px',
                padding: '2px 0 2px 8px',
                font: '14px',
                background: '#f0f1f2',
              }),
            }}
          />
        </div>
      </InputGroup>

      <div className='user-btn'>
        <UserAccount />
      </div>
    </div>
  );
};

export default Topbar;