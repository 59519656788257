/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

// import components below
import Table from 'components/ReactTable';
import TableUI from 'components/UI/TableUI';
import { selectDepartmentInventory } from 'reducers/departmentInventoryReducer';

// import assets below
import './Table.scss';

// main component
const StockAdjustmentFilterTable = ({
  active,
  filter,
  setState,
  setFilter,
  setActive,
  fetchData,
  setShowProfile,
}) => {
  // const dispatch = useDispatch();
  const storeState = useSelector(selectDepartmentInventory);
  const {
    restockItemsLogs: logs,
    restockItemsData: data,
    restockItemsItems: included,
  } = storeState;

  const [tableList, setTableList] = useState([]);

  // eslint-disable-next-line prefer-const
  let filterList = [
    { id: 1, key: '10', name: 'Requested' },
    {
      id: 2,
      key: '7',
      name: 'Partial',
      permission: '',
    },
    {
      id: 3,
      key: '8',
      name: 'Completed',
      permission: '',
    },
  ];

  const statusAction = (status) => (
    <>
      {status === 'new' && <span className='new'>{status}</span>}
      {status === 'in progress' && <span className='in-progress'>{status}</span>}
      {status === 'denied' && <span style={{ backgroundColor: '#ff0000' }}>{status}</span>}
      {status !== 'new' && status !== 'in progress' && status !== 'denied' && (
        <span className='new'>{status}</span>
      )}
    </>
  );

  const tableAction = (id) => (
    <div className='action-button'>
      <Button
        className='view-item'
        onClick={() => {
          setShowProfile((prev) => {
            return {
              ...prev,
              profileId: id,
              showView: true,
            };
          });
        }}
      >
        View Item
      </Button>
    </div>
  );

  const handleOnClickFilter = (status) => {
    setActive(status);
    fetchData(status);
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Adjustment Date/Time', accessor: 'requested_datetime' }, // accessor is the "key" in the data
      { Header: 'Adjustment No.', accessor: 'requested_no' },
      { Header: 'No. of Items', accessor: 'no_of_items' },
      { Header: 'Status', accessor: 'status' },
      // { Header: 'Adjusted By', accessor: 'adjusted_by' },
      { Header: 'Action', accessor: 'action' },
    ],
    [],
  );

  const tableData = React.useMemo(() => {
    if (tableList) {
      return tableList?.map((dd) => {
        const restockID = dd?.relationships?.restock?.data[0]?.id;
        const reStockData = included.filter(
          (data) => data?.id === restockID && data?.type === 'restocks',
        );

        return {
          id: dd?.id,
          requested_datetime: dd?.attributes?.requested_at,
          requested_no: dd?.attributes?.request_number,
          no_of_items: dd?.attributes?.number_of_items,
          // adjusted_by: dd?.attributes?.requested_by,
          status: statusAction(dd?.attributes?.status),
          action: tableAction(dd?.id),
        };
      });
    }
  }, [tableList]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const { isSuccess } = logs;

    if (isSuccess) {
      setTableList(data);
    }
  }, [logs]);

  return (
    <div className='stock-adjustment-filter-table'>
      <TableUI>
        <Table
          data={tableData}
          columns={tableColumns}
          filter={{
            top: (
              <div className='buttons' style={{ flex: 'none', whiteSpace: 'nowrap' }}>
                {filterList.map((list) => (
                  <Button
                    key={list.key}
                    className={list.key === active ? 'active' : ''}
                    onClick={() => handleOnClickFilter(list.key)}
                  >
                    {list.name}
                  </Button>
                ))}
              </div>
            ),
          }}
          pagination={['BOTTOM']}
          logs={logs}
        />
      </TableUI>
    </div>
  );
};

export default StockAdjustmentFilterTable;
