/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Row, Col, Form, Button, FormControl, InputGroup } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchPatientFilter = ({ search, setSearch, handleSearch }) => {
  const handleChange = (key) => (e) => {
    if (e.target.value === '') {
      handleSearch(true);
    }
    
    setSearch((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  };

  return (
    <div className='searchPatientFilter'>
      <FormUI>
        <Row>
          <Col>
            <Form.Group className='mb-4 mt-3 basic-search'>
              <Form.Label>Basic Search</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  placeholder='Search via Last Name or PIN…'
                  value={search.search}
                  onChange={handleChange('search')}
                />
                <Button variant='search' onClick={() => handleSearch()}>
                  SEARCH
                </Button>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default SearchPatientFilter;
