/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, Table, FormControl, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import ConditionalRender from 'components/ConditionalRender';
import { getRestockItem, selectDepartmentInventory } from 'reducers/departmentInventoryReducer';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

const initialState = {
  data: {},
  included: [],
};

// main component
const ViewItemModal = ({ showProfile, setShowProfile }) => {
  const dispatch = useDispatch();

  const [viewData, setViewData] = useState(initialState);
  const storeState = useSelector(selectDepartmentInventory);
  const { restockItemData, restockItemItems, restockItemLogs } = storeState;

  const closeModal = () => {
    setShowProfile((prevState) => {
      return { ...prevState, showView: false, profileId: null };
    });
    setViewData(initialState);
  };

  const statusAction = (status) => (
    <>
      {status === 'new' && <span className='new'>{status}</span>}
      {status === 'in progress' && <span className='in-progress'>{status}</span>}
      {status === 'denied' && <span style={{ backgroundColor: '#ff0000' }}>{status}</span>}
      {status !== 'new' && status !== 'in progress' && status !== 'denied' && (
        <span className='new'>{status}</span>
      )}
    </>
  );

  const getPrice = (item) => {
    if (!item?.attributes) return;

    const { unit_of_measure, piece_price, box_price } = item.attributes;

    if (unit_of_measure === 'piece') {
      return piece_price;
    }

    if (unit_of_measure === 'box') {
      return box_price;
    }
  };

  const handleViewImage = (url, title) => {
    window.open(url, title);
  };

  const renderRow = () => {
    if (Object.keys(viewData.data).length === 0) return;

    const { included } = viewData;
    const { relationships } = viewData.data;

    const mapData = relationships?.restock_items?.data;

    console.log('viewData', viewData);

    const adjustmentDatas = viewData?.included.filter((d) => d?.type === 'adjustments');

    return mapData.map(({ id }, idx) => {
      // get restock data from included using id
      const reStockData = included.filter(
        (data) => parseInt(data?.id) === parseInt(id) && data?.type === 'restock_items',
      );

      // get item ID from restockData
      const itemID = reStockData[0]?.attributes?.item_id;

      // get itemData from included using itemID
      const itemData = included.filter(
        (data) => parseInt(data?.id) === parseInt(itemID) && data?.type === 'items',
      );

      // get adjustmentID from reStockData
      const adjustmentID = reStockData[0]?.relationships?.adjustments?.data[0]?.id;

      // eslint-disable-next-line prefer-const
      let imageURL = '';
      if (adjustmentID) {
        // get AdjustmentData from included using adjustmentID

        const adjustmentData = included.filter(
          (data) => parseInt(data?.id) === parseInt(id) && data?.type === 'adjustments',
        );

        imageURL = adjustmentData[0]?.attributes?.image_path;
      }

      return (
        <tr key={idx}>
          <td>{itemData[0]?.attributes?.code}</td>
          <td>{itemData[0]?.attributes?.name}</td>
          <td>{itemData[0]?.attributes?.unit_of_measure}</td>
          <td>{getPrice(itemData[0])}</td>
          <td>{itemData[0]?.attributes?.quantity}</td>
          <td>{reStockData[0]?.attributes?.delivered_quantity}</td>
          <td>{statusAction(reStockData[0]?.attributes?.status)}</td>
          <td>
            <Button
              className={`view-button ${imageURL === '' ? 'no-image' : ''}`}
              onClick={() => {
                if (adjustmentDatas.length > 0) {
                  adjustmentDatas.forEach((d, idx) => {
                    if (
                      d?.attributes?.image_path === '' ||
                      d?.attributes?.image_path ===
                        'https://internal.manda-his-be.highlysucceed.com/inventory/storage/'
                    ) {
                      return;
                    } else {
                      handleViewImage(d?.attributes?.image_path, idx);
                    }
                  });
                }
              }}
            >
              {imageURL === '' ? 'No Image Uploaded' : 'View'}
            </Button>
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    if (showProfile?.profileId) {
      dispatch(getRestockItem(showProfile?.profileId));
    }
  }, [showProfile]);

  useEffect(() => {
    const { isSuccess } = restockItemLogs;

    if (isSuccess) {
      setViewData({
        data: restockItemData,
        included: restockItemItems,
      });
    }
  }, [restockItemLogs]);

  return (
    <Modal
      size='lg'
      show={showProfile.showView}
      onHide={() => {
        closeModal();
      }}
      aria-labelledby='ADJUSTMENT DETAILS'
      className='view-adjustment-modal'
    >
      <Modal.Body>
        <div className='view-adjustment-request'>
          <div className='d-flex header'>
            <p>ADJUSTMENT DETAILS</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='adjustment-form'>
            <Row className='mb-3'>
              <Col className='request-details'>
                <Form.Group className='inline-group'>
                  <Form.Label>Adjustment Date/Time :</Form.Label>
                  <FormControl
                    className='no-border'
                    value={moment(viewData?.data?.attributes?.requested_at).format(
                      'MMM-DD-YYYY hh:mm A',
                    )}
                  />
                </Form.Group>

                <Form.Group className='inline-group'>
                  <Form.Label>Adjusted By :</Form.Label>
                  <FormControl
                    className='no-border'
                    value={viewData?.data?.attributes?.requested_by}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Accordion className='advanced-search' defaultActiveKey={'0'}>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Items</Accordion.Header>
                <Accordion.Body>
                  <div className='search-table mb-4'>
                    <TableUI>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Item Code</th>
                            <th>Item Name</th>
                            <th>UOM</th>
                            <th>Unit Price</th>
                            <th>On Hand</th>
                            <th>Adjustment Quantity</th>
                            <th>Status</th>
                            <th>Uploaded Image</th>
                          </tr>
                        </thead>

                        <tbody>
                          <ConditionalRender
                            condition={
                              Object.keys(viewData?.data).length > 0 &&
                              viewData?.data.constructor === Object
                            }
                            renderIf={renderRow()}
                          />
                        </tbody>
                      </Table>
                    </TableUI>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ViewItemModal.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

ViewItemModal.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default ViewItemModal;
