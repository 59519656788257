// import components below
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const CreateTemporaryProfileHeader = () => {
  return (
    <div className='createTemporaryProfileHeader'>
      <PageHeaderUI>
        <h4>Outpatient Department Module</h4>
        <p>Dashboard / Outpatient Department / Create Temporary Profile</p>
      </PageHeaderUI>
    </div>
  );
};

export default CreateTemporaryProfileHeader;
