/* eslint-disable camelcase */
export const regionAPI = [
    {
      id: 1,
      psgc_code: '010000000',
      region_name: 'Region I (Ilocos Region)',
      region_code: '01',
    },
    {
      id: 2,
      psgc_code: '020000000',
      region_name: 'Region II (Cagayan Valley)',
      region_code: '02',
    },
    {
      id: 3,
      psgc_code: '030000000',
      region_name: 'Region III (Central Luzon)',
      region_code: '03',
    },
    {
      id: 4,
      psgc_code: '040000000',
      region_name: 'Region IV-A (CALABARZON)',
      region_code: '04',
    },
    {
      id: 5,
      psgc_code: '170000000',
      region_name: 'Region IV-B (MIMAROPA)',
      region_code: '17',
    },
    {
      id: 6,
      psgc_code: '050000000',
      region_name: 'Region V (Bicol Region)',
      region_code: '05',
    },
    {
      id: 7,
      psgc_code: '060000000',
      region_name: 'Region VI (Western Visayas)',
      region_code: '06',
    },
    {
      id: 8,
      psgc_code: '070000000',
      region_name: 'Region VII (Central Visayas)',
      region_code: '07',
    },
    {
      id: 9,
      psgc_code: '080000000',
      region_name: 'Region VIII (Eastern Visayas)',
      region_code: '08',
    },
    {
      id: 10,
      psgc_code: '090000000',
      region_name: 'Region IX (Zamboanga Peninzula)',
      region_code: '09',
    },
    {
      id: 11,
      psgc_code: '100000000',
      region_name: 'Region X (Northern Mindanao)',
      region_code: '10',
    },
    {
      id: 12,
      psgc_code: '110000000',
      region_name: 'Region XI (Davao Region)',
      region_code: '11',
    },
    {
      id: 13,
      psgc_code: '120000000',
      region_name: 'Region XII (SOCCSKSARGEN)',
      region_code: '12',
    },
    {
      id: 14,
      psgc_code: '130000000',
      region_name: 'National Capital Region (NCR)',
      region_code: '13',
    },
    {
      id: 15,
      psgc_code: '140000000',
      region_name: 'Cordillera Administrative Region (CAR)',
      region_code: '14',
    },
    {
      id: 16,
      psgc_code: '150000000',
      region_name: 'Autonomous Region in Muslim Mindanao (ARMM)',
      region_code: '15',
    },
    {
      id: 17,
      psgc_code: '160000000',
      region_name: 'Region XIII (Caraga)',
      region_code: '16',
    },
  ];