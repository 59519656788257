/* eslint-disable no-unused-vars */
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';

import './index.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import React, { useState } from 'react';

import FormUI from 'components/UI/FormUI';

const AddModal = ({ 
  setOpenModal, 
  openModal, 
  handleFieldChange, 
  fields, 
  setFields,
  handleUpdateRequest,
  handlePostRequest,
}) => {
  const [validated, setValidated] = useState(false);

  const handleHide = () => {
    setOpenModal({ isOpen: false, type: '' });
    setFields({
      detail: '',
      amount: '',
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() !== false) {
      if (!openModal?.singleData) {
        handlePostRequest();
        handleHide();
        return;
      }
      
      handleUpdateRequest();
      handleHide();
      return;
    }

    setValidated(true);
  };

  return (
    <Modal show={openModal.isOpen} size='md' className='add-field'>
      <Modal.Body>
        <div className='add-field-header'>
          <div className='d-flex header'>
            <p>Add {openModal?.type}</p>
            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleHide} />
          </div>
        </div>

        <FormUI>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='mb-3 width-90 right-auto inline-label'>
                  <Form.Label>Detail type</Form.Label>

                  <Form.Control
                    required
                    type='text'
                    name='details'
                    onChange={handleFieldChange(openModal.type)}
                    placeholder='Enter Here'
                    value={fields?.details}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='mb-3 width-90 right-auto inline-label'>
                  <Form.Label>Amount</Form.Label>

                  <Form.Control
                    required
                    type='number'
                    name='amount'
                    onChange={handleFieldChange(openModal.type)}
                    placeholder='Enter Here'
                    value={fields?.amount}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <hr />
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mb-4'>
                <Form.Group className='me-2'>
                  <Button className='btn btn-save' type='submit'>
                    Save
                  </Button>
                </Form.Group>
                <Form.Group className='ms-2'>
                  <Button className='btn btn-cancel' type='button' onClick={handleHide}>
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;
