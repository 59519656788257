/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

const TreatmentRecord = ({ handleChange, stateData, setStateData }) => {
    return (
        <>
            <Row>
                <Col>
                    <Form.Group className='mb-4'>
                        <Form.Label>Description</Form.Label>
                        <FormControl
                            as='textarea'
                            rows={3}
                            className='bg-white'
                            placeholder='Enter Here'
                            value={stateData?.description}
                            onChange={handleChange('description')}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className='mb-4'>
                        <Form.Label>Additional Notes</Form.Label>
                        <FormControl
                            as='textarea'
                            rows={3}
                            className='bg-white'
                            placeholder='Enter Here'
                            value={stateData?.tr_additional_notes}
                            onChange={handleChange('tr_additional_notes')}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default TreatmentRecord;
