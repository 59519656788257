import React from 'react';
import PatientDisbursedTable from './components/Table';
import SearchPatientFilter from './components/Filter';
const PatientDisbursed = () => {
  return (
    <>
      <SearchPatientFilter />
      <PatientDisbursedTable />
    </>
  );
};

export default PatientDisbursed;
