/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
// import core & vendor packages below
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// import components below
import RefillTable from './components/RefillTable';
import ReOrderTable from './components/ReOrderTable';
import ConditionalRender from 'components/ConditionalRender';
import {
  selectGroups,
  getrefillGroups,
  getRefillRequest,
  getReorderRequest,
  selectrefillRequest,
  selectrefillRequestData,
  selectrefillRequestItem,
  selectreorderRequestData,
} from 'reducers/refillRequest';
import PurchaseRequestModal from './components/PurchaseRequestModal';
import ViewModal from './components/ViewRequestModal/ViewRequestModal';

// import assets below
import './index.scss';

// main component
const ItemsForReplenishment = ({ setTabState, handleTabOnChange }) => {
  const dispatch = useDispatch();

  const items = useSelector(selectrefillRequestItem);
  const datafetch = useSelector(selectrefillRequestData);
  const refillRequestState = useSelector(selectrefillRequest);
  const datareorderfetch = useSelector(selectreorderRequestData);

  const { getRefillRequestlogs, getReorderRequestlogs } = refillRequestState;

  const groups = useSelector(selectGroups);

  const [state, setState] = useState([]);
  const [data, setData] = useState([]);
  const [firstTrigger, setFirstTrigger] = useState(false);
  const [showProfile, setShowProfile] = useState({
    showView: false,
    profileId: null,
    showrefill: true,
    profileData: null,
    showreorder: false,
    refillModal: false,
    isPurchaseRequestModal: false,
  });
  const { showrefill, showreorder } = showProfile;
  useEffect(() => {
    dispatch(getrefillGroups());
    dispatch(getRefillRequest({ restock_type: 'refill' }));
  }, []);

  useEffect(() => {
    if (showrefill && firstTrigger) {
      setData([]);
      dispatch(getRefillRequest({ restock_type: 'refill' }));
    }
    if (showreorder && firstTrigger) {
      setData([]);
      dispatch(getReorderRequest({ restock_type: 'reorder' }));
    }
    if (!firstTrigger) {
      setFirstTrigger(true);
    }
  }, [showrefill, showreorder]);

  useEffect(() => {
    setData(datafetch);
  }, [getRefillRequestlogs]);

  useEffect(() => {
    setData(datareorderfetch);
  }, [getReorderRequestlogs]);

  return (
    <>
      <div className='items-for-replenishment'>
        <div className='inventory-table'>
          <Row>
            <Col className='mb-2'>
              <div className='table-option'>
                <Button
                  onClick={() => {
                    setShowProfile((prevState) => {
                      return {
                        ...prevState,
                        showrefill: true,
                        showreorder: false,
                      };
                    });
                  }}
                  className={`${showrefill ? 'active' : ''}`}
                >
                  For Refill
                </Button>
                <Button
                  onClick={() => {
                    setShowProfile((prevState) => {
                      return {
                        ...prevState,
                        showreorder: true,
                        showrefill: false,
                      };
                    });
                  }}
                  className={`${showreorder ? 'active' : ''}`}
                >
                  For Re-Order
                </Button>
              </div>
            </Col>
            <ConditionalRender
              condition={showreorder}
              renderIf={
                <Col className='d-flex justify-content-end'>
                  <Button
                    className='purchase-button'
                    onClick={() => {
                      setShowProfile((prev) => {
                        return { ...prev, isPurchaseRequestModal: true };
                      });
                    }}
                  >
                    Purchase Request
                  </Button>
                </Col>
              }
            />
          </Row>
        </div>
        <ConditionalRender
          condition={showrefill}
          renderIf={
            <RefillTable
              data={data}
              state={state}
              setState={setState}
              showProfile={showProfile}
              setShowProfile={setShowProfile}
              handleTabOnChange={handleTabOnChange}
              getRefillRequestlogs={getRefillRequestlogs}
            />
          }
        />

        <ConditionalRender
          condition={showreorder}
          renderIf={
            <ReOrderTable
              data={data}
              state={state}
              items={items}
              setState={setState}
              setTabState={setTabState}
              showProfile={showProfile}
              setShowProfile={setShowProfile}
              handleTabOnChange={handleTabOnChange}
              getReorderRequestlogs={getReorderRequestlogs}
            />
          }
        />
      </div>

      <PurchaseRequestModal
        state={state}
        setState={setState}
        showProfile={showProfile}
        setShowProfile={setShowProfile}
      />

      <ViewModal
        state={state}
        items={items}
        groups={groups}
        setState={setState}
        datafetch={datafetch}
        showProfile={showProfile}
        setShowProfile={setShowProfile}
      />
    </>
  );
};

ItemsForReplenishment.defaultProps = {
  setTabState: () => {},
  handleTabOnChange: () => {},
};

ItemsForReplenishment.propTypes = {
  setTabState: PropTypes.func,
  handleTabOnChange: PropTypes.func,
};

export default ItemsForReplenishment;
