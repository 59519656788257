/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

// Packagws
import PropTypes from 'prop-types';
import { Table, Button, Row, Col, Form } from 'react-bootstrap';

// Components
import TableUI from 'components/UI/TableUI';
import TablePagination from 'components/TablePagination/Pagination';

// Assets
import './Table.scss';
import { ReactComponent as LockSVG } from 'assets/svg/lock white.svg';
import { ReactComponent as LockRedSVG } from 'assets/svg/lock-red.svg';
import { ReactComponent as LockOpenSVG } from 'assets/svg/lock-open.svg';

const sampleData = [
  {
    id: 1,
    code: 'PHA0001',
    name: 'Augmentin 500mg / 125mg tab',
    lock: true,
    UOM: 'Piece',
    storage_bin: 'S-1',
    unit_cost: '89.00',
    supplying_store: 'None',
    on_hand: '199',
    safety_level: '80',
    min: '65',
    max: '400',
    re_order_paint: '80',
  },
  {
    id: 2,
    code: 'PHA0002',
    name: 'Aeknil 150mg ampule',
    lock: false,
    UOM: 'Piece',
    storage_bin: 'S-1',
    unit_cost: '89.00',
    supplying_store: 'None',
    on_hand: '199',
    safety_level: '80',
    min: '65',
    max: '400',
    re_order_paint: '80',
  },
];

// Main component
const StoreItemSetupFilterTable = ({ setState, setShowProfile }) => {
  return (
    <div className='stock-adjustment-filter-table'>
      <Row>
        <Col sm={2}>
          <div className='buttons'>
            <Form.Group className='mb-1 actions'>
              <Form.Select>
                <option>Action</option>
              </Form.Select>
            </Form.Group>
          </div>
        </Col>
        <Col>
          <div className='table-button mb-1'>
            <Button
              onClick={() => {
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    itemConfiguration: true,
                  };
                });
              }}
            >
              Item Configuration
            </Button>
          </div>
        </Col>
      </Row>

      <TableUI>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <Form.Check></Form.Check>
              </th>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>
                <LockSVG style={{ width: '2em' }} />
              </th>
              <th>UOM</th>
              <th>Storage Bin</th>
              <th>Unit Cost</th>
              <th>Supplying Store</th>
              <th>On Hand</th>
              <th>Safety Level</th>
              <th>Min</th>
              <th>Max</th>
              <th>Re-Order Point</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sampleData &&
              sampleData.map((data) => {
                return (
                  <tr key={data.code}>
                    <td>
                      <Form.Check value={data.code}></Form.Check>
                    </td>
                    <td>{data.code}</td>
                    <td>{data.name}</td>
                    <td>
                      {data.lock ? (
                        <LockRedSVG style={{ width: '2em' }} />
                      ) : (
                        <LockOpenSVG style={{ width: '2em' }} />
                      )}
                    </td>
                    <td>{data.UOM}</td>
                    <td>{data.storage_bin}</td>
                    <td>{data.unit_cost}</td>
                    <td>{data.supplying_store}</td>
                    <td>{data.on_hand}</td>
                    <td>{data.safety_level}</td>
                    <td>{data.min}</td>
                    <td>{data.max}</td>
                    <td>{data.re_order_paint}</td>
                    <td className='action'>
                      <Button className='edit'>Edit</Button>
                      <Button className='issue'>Remove</Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </TableUI>

      <TablePagination />
    </div>
  );
};

StoreItemSetupFilterTable.defaultProps = {
  setState: () => {},
  setShowProfile: () => {},
};

StoreItemSetupFilterTable.propTypes = {
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default StoreItemSetupFilterTable;
