/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Table } from 'react-bootstrap';

function TableProfessionalAllocation({ Doctors, professionalsBill }) {
  return (
    <>
      <h5 className='fw-bold mt-4'>PROFESSIONAL FEE ALLOCATION</h5>

      {/* Pharmacy  */}
      <Table responsive>
        <thead>
          <tr>
            <th style={{ borderBottom: 'none' }}>Doctor's Name (Code)</th>
            <th style={{ borderBottom: 'none' }}>Professional Fee</th>
          </tr>
        </thead>
        <tbody>
          {Doctors
            ? Doctors.map((data, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {data?.user?.fullname ? data?.user?.fullname : data?.user?.first_name} (
                      {data?.visit_number})
                    </td>
                    <td>{data?.default_professional_fee}</td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>

      <Table responsive>
        <thead>
          <tr>
            <th style={{ background: '#D5D5D5', borderBottom: 'none' }}>TOTAL PROFESSIONAL FEE</th>
            <th style={{ background: '#A5A5A5', borderBottom: 'none' }}>Php {professionalsBill}</th>
          </tr>
        </thead>
      </Table>
    </>
  );
}

export default TableProfessionalAllocation;
