import React from 'react';
import { Col, Row } from 'react-bootstrap';

const NetIncreaseList = ({ data }) => {
  return (
    <>
      <h6 className='mb-3'>Net Increase In cash and cash equivalents</h6>
      {data?.map(({ id, field, amount }) => {
        return (
          <Row className='mb-4' key={id}>
            <Col xs={6} md={4} className='asset-name'>
              {field}
            </Col>
            <Col md={1} className='asset-amount'>
              {amount.toLocaleString()}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default NetIncreaseList;
