import { useState } from 'react';
import PropTypes from 'prop-types';

// main components below
import PatientTable from './components/Table';
import SearchPatientFilter from './components/Filter';
import FormPatientProfile from './components/FormPatientProfile';

// main component
const SearchPatient = ({ handleTabOnChange }) => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    isShowView: false,
    profileId: null,
  });

  return (
    <div>
      {!showProfile.isShowView ? (
        <div className='searchPatient'>
          <SearchPatientFilter
            state={state}
            setState={setState}
            handleTabOnChange={handleTabOnChange}
          />

          <PatientTable state={state} setShowProfile={setShowProfile} />
        </div>
      ) : (
        <FormPatientProfile showProfile={showProfile} setShowProfile={setShowProfile} />
      )}
    </div>
  );
};

SearchPatient.defaultProps = {
  handleTabOnChange: () => {},
};

SearchPatient.propTypes = {
  handleTabOnChange: PropTypes.func,
};

export default SearchPatient;
