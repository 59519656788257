/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as EyeSVG } from 'assets/svg/eye.svg';
import { ReactComponent as XmarkSVG } from 'assets/svg/circle-xmark-red.svg';

const TableBody = ({ data, setShowProfile }) => {
  if (!data || data.length === 0) {
    return (
      <tr>
        <td colSpan={13}>No new stock checks</td>
      </tr>
    );
  }

  return (
    <>
      {data &&
        data.map((data) => {
          return (
            <tr key={data.code}>
              <td>
                <p>
                  {data.date} <span>{data.time}</span>
                </p>
              </td>
              <td>{data.stockCheckNo}</td>
              <td>{data.name}</td>
              <td>{data.totalItems}</td>
              <td>{data.itemsCounted}</td>
              <td>{data.completed}</td>
              <td>{data.totalHandCost}</td>
              <td>{data.countedCost}</td>
              <td>{data.varianceCost}</td>
              <td>{data.variance}</td>
              <td>
                <span className={`${data.status === 'Completed' ? 'completed' : 'in-progress'}`}>
                  {data.status}
                </span>
              </td>
              <td>{data.createdBy}</td>
              <td>
                <div className='action'>
                  <EyeSVG
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          showScreen: 3,
                          viewData: data,
                        };
                      });
                    }}
                    style={{ width: '1.5em', cursor: 'pointer' }}
                  />
                  <XmarkSVG style={{ width: '1.5em', cursor: 'pointer' }} />
                </div>
              </td>
            </tr>
          );
        })}
    </>
  );
};

TableBody.defaultProps = {
  TableBody: [],
  setShowProfile: () => {},
};

TableBody.propsType = {
  TableBody: PropTypes.array,
  setShowProfile: PropTypes.func,
};

export default TableBody;
