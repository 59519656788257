// import core & vendor packages below
import { Fragment } from 'react';

// import components below
import FormSignup from './components/Form';
import LoginUI from 'components/UI/LoginUI';
import ReactHelmet from 'components/ReactHelmet';

// import assets below
import './style.scss';

// main component
const Admissions = () => {
  return (
    <Fragment>
      <ReactHelmet title='Sign Up' />
      <LoginUI>
        <div className='containerSignup'>
          <div className='containerSignup__body'>
            <FormSignup />
          </div>
        </div>
      </LoginUI>
    </Fragment>
  );
};

export default Admissions;
