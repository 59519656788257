/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// import components below
import FormUI from 'components/UI/FormUI';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
import { getPatient } from 'reducers/patientReducer';
import { useSelector } from 'react-redux';
import { ReactComponent as AddGroupSVG } from 'assets/svg/add-group.svg';
import { selectPatientData } from 'reducers/patientReducer';
import axios from 'axios';

// import assets below
import './Filter.scss';

// main component
const SearchPatientFilter = ({ state, setState, handleTabOnChange }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectPatientData);

  const [erPatient, setErPatient] = useState([]);
  const [searchData, setSearchData] = useState({
    id: '',
    er_status: '',
    patient_name: '',
    date_admitted: '',
    hospital_service: '',
    currently_admitted: false,
    currently_er_patient: false,
  });

  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;

    const newFilter = data.filter((value) => {
      return (
        value.full_name.toLowerCase().includes(searchedWord.toLowerCase()) ||
        value.MRN.toLowerCase().includes(searchedWord.toLowerCase())
      );
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(erPatient);
    }
  };

  const handleChange = (key) => (e) => {
    if (e.target.type === 'checkbox') {
      setSearchData((prevState) => {
        return {
          ...prevState,
          [key]: e.target.checked,
        };
      });

      return;
    }

    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const search = () => {
    axios
      .get('/patient', {
        params: {
          MRN: searchData.id,
          er_status: searchData.er_status,
          full_name: searchData.patient_name,
          admission_date: searchData.date_admitted,
          hospital_service: searchData.hospital_service,
          currently_admitted: searchData.currently_admitted,
          currently_er_patient: searchData.currently_er_patient,
        },
      })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  const clearSearch = () => {
    axios
      .get('/patient', {
        params: {
          id: '',
          er_status: '',
          patient_name: '',
          date_admitted: '',
          hospital_service: '',
          currently_admitted: false,
          currently_er_patient: false,
        },
      })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  useEffect(() => {
    dispatch(getPatient());

    axios
      .get('/patient', {
        params: {
          patient_status: ['emergency', 'for_discharge', 'for_admission'],
        },
      })
      .then((response) => {
        setState(response.data.data);
        setErPatient(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='searchPatientFilter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Basic Search</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  onChange={onChangeHandler}
                  value={searchData.basic_search}
                  placeholder='Search via Last Name or PIN…'
                />
                <Button variant='search'>SEARCH</Button>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <div className='buttonWrapper'>
              {/* <Link
                className='btn btn-dark-blue me-3'
                to='/dashboard/admissions/create-temporary-profile'
              >
                <AddGroupSVG />
                <span>Create Temporary Profile</span>
              </Link> */}
              <Link
                className='btn btn-light-blue px-3'
                to='/dashboard/emergency-department/create-new-patient'
              >
                <AddGroupSVG />
                <span>Register New Patient</span>
              </Link>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Accordion className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Advance Search</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>MRN / PIN</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          value={searchData.id}
                          placeholder='Enter Here'
                          onChange={handleChange('id')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Patient Name</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          value={searchData.patient_name}
                          placeholder='Enter Patient Name'
                          onChange={handleChange('patient_name')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>Date Admitted</Form.Label>
                        <FormControl
                          type='date'
                          className='bg-white'
                          placeholder='Enter Here'
                          value={searchData.date_admitted}
                          onChange={handleChange('date_admitted')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Triage Categorization</Form.Label>
                        <Form.Select
                          value={searchData.er_status}
                          onChange={handleChange('er_status')}
                        >
                          <option value='' hidden>
                            -Select-
                          </option>
                          <option value='Resuscitation'>RESUSCITATION</option>
                          <option value='Emergent'>EMERGENT</option>
                          <option value='Urgent'>URGENT</option>
                          <option value='Less_Urgent'>LESS URGENT</option>
                          <option value='Non_Urgent'>NON-URGENT</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Hospital Service</Form.Label>
                        <Form.Select
                          value={searchData.hospital_service}
                          onChange={handleChange('hospital_service')}
                        >
                          <option value='' hidden>
                            -Select-
                          </option>
                          <option value='ent'>ENT</option>
                          <option value='medicine'>Medicine</option>
                          <option value='ob_gyne'>OB-GYNE</option>
                          <option value='urology'>Urology</option>
                          <option value='cardiology'>Cardiology</option>
                          <option value='pulmonary Medicine'>Pulmonary Medicine</option>
                          <option value='opthalmology'>Opthalmology</option>
                          <option value='surgery'>Surgery</option>
                          <option value='orthopedic Surgery'>Orthopedic Surgery</option>
                          <option value='endocrinology'>Endocrinology</option>
                          <option value='pediatrics'>Pediatrics</option>
                          <option value='dermatology'>Dermatology</option>
                          <option value='oncology'>Oncology</option>
                          <option value='neurology'>Neurology</option>
                          <option value='gastroenterology'>Gastroenterology</option>
                          <option value='internal_medicine'>Internal Medicine</option>
                          {/* <option value='medico_legal'>Medico Legal</option> */}
                          <option value='nephrology'>Nephrology</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <div className='buttonWrapper'>
                      <Form.Check
                        id={`admitted`}
                        type={'checkbox'}
                        label={`Currently Admitted`}
                        checked={searchData.currently_admitted}
                        onChange={handleChange('currently_admitted')}
                      />
                      <Form.Check
                        type={'checkbox'}
                        id={`er-patient`}
                        label={`Currently an ER Patient`}
                        checked={searchData.currently_er_patient}
                        onChange={handleChange('currently_er_patient')}
                      />

                      <Button variant='search' onClick={() => search()}>
                        SEARCH
                      </Button>

                      <Button
                        variant='clear'
                        onClick={() => {
                          setSearchData({
                            id: '',
                            er_status: '',
                            patient_name: '',
                            date_admitted: '',
                            hospital_service: '',
                            currently_admitted: false,
                            currently_er_patient: false,
                          });

                          clearSearch();
                        }}
                      >
                        CLEAR
                      </Button>
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default SearchPatientFilter;
