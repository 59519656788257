/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';

// import components below
import Reports from './Reports';
import Templates from './Templates';
import TabsUI from 'components/UI/TabsUI';
import ChartOfAccount from './ChartOfAccounts';
import FinancialStatement from './FinancialStatement';

import './Tabs.scss';

// main component
const PageTabs = ({ onChange }) => {
  const [activeTab, setActiveTab] = useState('Add Financial Statement');

  const tabs = [
    {
      title: 'Financial Statement',
      component: FinancialStatement,
    },
    {
      title: 'Chart of Accounts',
      component: ChartOfAccount,
    },
    {
      title: 'Templates',
      component: Templates,
    },
    {
      title: 'Reports',
      component: Reports,
    },
  ];
  return (
    <div className='accounting-tabs'>
      <Row>
        <Col>
          <TabsUI>
            <Tabs defaultActiveKey={tabs[0].title} onSelect={(eventKey) => onChange(eventKey)}>
              {tabs.map(({ title, component: Component }, idx) => (
                <Tab key={idx} eventKey={title} title={title}>
                  <Component />
                </Tab>
              ))}
            </Tabs>
          </TabsUI>
        </Col>
      </Row>
    </div>
  );
};

export default PageTabs;
