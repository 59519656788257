/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Accordion, FormControl } from 'react-bootstrap';

import FormUI from 'components/UI/FormUI';

import './index.scss';

const EditLabTestForm = ({ showProfile, setShowProfile, setState }) => {
  const [disabled, setDisabled] = useState(true);

  const [params, setParams] = useState({
    name: '',
    internal_external: '',
    price: '',
    isActive: '',
    id: showProfile?.id,
  });

  const handleChange = (key) => (e) => {
    setParams({
      ...params,
      [key]: e.target.value,
    });

    if (params.name.length > 0 && params.internal_external.length > 0 && params.price.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = () => {
    axios
      .put('/lab_test', params)
      .then((response) => {
        setParams({
          name: '',
          internal_external: '',
          price: '',
          isActive: '',
          id: null,
        });
        toast.warning('Successfully Edited!');

        axios.get('/lab_test').then((response) => {
          setState(response.data.data);
        });

      })
      .catch((error) => {
        console.log('ERROR', error);
      });
  };

  useEffect(() => {
    if (showProfile.editValues) {
      const { editValues } = showProfile;

      setParams({
        ...params,
        name: editValues.name,
        internal_external: editValues.internal_external,
        price: editValues.price,
        isActive: editValues.isActive,
      });
    }
  }, [showProfile]);

  return (
    <FormUI>
      <Form>
        <div className='createroom-cont'>
          <h3>Edit Laboratory ID: {showProfile.id}</h3>

          <Row>
            <Col>
              <Accordion defaultActiveKey={'0'} className='mb-3 advanced-search'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>Request Details</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col className='mb-3'>
                        <Form.Group className='mb-3' style={{ width: '100%' }}>
                          <Form.Label>
                            Test Name<span className='asterisk'> *</span>
                          </Form.Label>
                          <FormControl
                            type='text'
                            className='bg-white'
                            placeholder='Enter Here'
                            onChange={handleChange('name')}
                            value={params.name}
                          />
                        </Form.Group>
                      </Col>
                      <Col className='mb-3'>
                        <Form.Group className='mb-3' style={{ width: '100%' }}>
                          <Form.Label>
                            Internal/External?<span className='asterisk'> *</span>
                          </Form.Label>
                          <Form.Select
                            onChange={handleChange('internal_external')}
                            value={params.internal_external}
                          >
                            <option hidden value=''>
                              -Select-
                            </option>
                            <option value='External'>External</option>
                            <option value='Internal'>Internal</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className='mb-3'>
                        <Form.Group className='mb-3' style={{ width: '100%' }}>
                          <Form.Label>
                            Price<span className='asterisk'> *</span>
                          </Form.Label>
                          <Form.Control
                            type='number'
                            placeholder='Enter Price here'
                            onChange={handleChange('price')}
                            value={params.price}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className='mb-3' sm={4}>
                        <Form.Group className='mb-3' style={{ width: '100%' }}>
                          <Form.Label>
                            Status<span className='asterisk'> *</span>
                          </Form.Label>
                          <Form.Select onChange={handleChange('isActive')} value={params.isActive}>
                            <option hidden value=''>
                              -Select-
                            </option>
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <div className='buttonWrapper'>
                        <Button variant='search' onClick={() => handleSubmit()}>
                          SAVE LAB
                        </Button>
                        <Button
                          variant='clear'
                          onClick={() => {
                            setShowProfile((prevState) => {
                              return {
                                ...prevState,
                                showScreen: 1,
                              };
                            });
                          }}
                        >
                          CANCEL
                        </Button>
                      </div>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </div>
      </Form>
    </FormUI>
  );
};

EditLabTestForm.defaultProps = {
  showProfile: {},
  setShowProfile: () => { },
  setState: () => { },
};

EditLabTestForm.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
  setState: PropTypes.func,
};

export default EditLabTestForm;
