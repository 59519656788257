import './helpers.scss';

export function redirectBackToPreviousPage() {
  window.history.back();
}

export function calculateDayDiff(admissionDate) {
  if (admissionDate && admissionDate.length > 0) {
    const date = new Date();
    date.setDate(date.getDate());

    const date1 = new Date(admissionDate);
    const date2 = new Date(date);
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
}

export function employeeStatus(status) {
  switch (status) {
    case 'done':
      return <span className='badge bg-danger'>{status?.toUpperCase()}</span>;
    case 'on duty':
      return <span className='badge bg-success'>{status?.toUpperCase()}</span>;
    default:
      return <span className='badge bg-secondary'>{status?.toUpperCase()}</span>;
  }
}

export function patientClass(status) {
  switch (status) {
    case 'new':
      return <span className='badge bg-info'>{'New'}</span>;
    case 'OPD':
      return <span className='badge bg-success'>{'Outpatient'}</span>;
    case 'emergency':
      return <span className='badge bg-warning'>{'Emergency'}</span>;
    case 'admitted':
      return <span className='badge bg-success'>{'Admitted'}</span>;
    default:
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
  }
}

export function billingHistoryClass(status) {
  switch (status) {
    case 'For Billing':
      return <span className='badge bg-success'>{'For Billing'}</span>;
    case 'Completed':
      return <span className='badge bg-warning'>{'Completed'}</span>;
    default:
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
  }
}

export function patientStatus(status) {
  switch (status) {
    case 'awaiting bed':
      return <span className='badge bg-warning'>{status?.toUpperCase()}</span>;
    case 'new':
      return <span className='badge bg-success'>{'OUTPATIENT'}</span>;
    // return <span className='badge bg-success'>{status.toUpperCase()}</span>;
    case 'admitted':
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
    case 'for_admission':
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
    case 'declined':
      return <span className='badge bg-danger'>{status?.toUpperCase()}</span>;
    case 'emergency':
      return <span className='badge bg-dark'>{status?.toUpperCase()}</span>;
    case 'died':
      return <span className='badge bg-danger'>{status?.toUpperCase()}</span>;
    case 'discharged':
      return <span className='badge bg-danger'>{status?.toUpperCase()}</span>;
    case 'dead_on_arrival':
      return <span className='badge bg-danger'>{status?.toUpperCase()}</span>;
    case 'still_birth':
      return <span className='badge bg-danger'>{status?.toUpperCase()}</span>;
    case 'for_admission_referred_from_opd':
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
    default:
      return <span className='badge bg-secondary'>{status?.toUpperCase()}</span>;
  }
}

export function roomStatus(status) {
  switch (status) {
    case 'available':
      return <span className='badge bg-success'>{status?.toUpperCase()}</span>;
    case 'occupied':
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
    case 'cleaning':
      return <span className='badge bg-warning'>{status?.toUpperCase()}</span>;
    case 'unavailable':
      return <span className='badge bg-danger'>{status?.toUpperCase()}</span>;
    default:
      return <span className='badge bg-secondary'>{status?.toUpperCase()}</span>;
  }
}

export function labTestStatus(status) {
  switch (status) {
    case true:
      return <span className={'active'}>YES</span>;
    case false:
      return <span className={'in-active'}>NO</span>;
    default:
      break;
  }
}

export function currentLocation(status) {
  switch (status) {
    case 'awaiting bed':
      return 'AWAITING BED';
    case 'new':
      return 'NEWLY REGISTERED';
    case 'admitted':
      return 'ADMITTED PATIENT';
    case 'declined':
      return 'DECLINED ROOM REQUEST';
    case 'emergency':
      return 'ER PATIENT';
    case 'for_discharge':
      return 'ON DISCHARGE PROCESS';
    default:
      return 'N/A';
  }
}

export const resizeImage = (base64Str, maxWidth = 160, maxHeight = 160) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64Str;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

export function patientDisbursementStatus(status) {
  switch (status) {
    case 'for_clearance':
      return <span className='badge bg-warning'>{status?.toUpperCase()}</span>;
    case 'for_billing':
      return <span className='badge bg-success'>{status?.toUpperCase()}</span>;
    case 'for_admission':
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
    case 'for_discharge':
      return <span className='badge bg-danger'>{status?.toUpperCase()}</span>;
    default:
      return <span className='badge bg-secondary'>{status?.toUpperCase()}</span>;
  }
}

export function dohTransactionStatus(status) {
  switch (status) {
    case 'In Progress':
      return (
        <span className='badge bg-in-progress'>
          <p style={{ color: '#00A8FF' }}>{status?.toLocaleString()}</p>
        </span>
      );
    case 'Complete':
      return (
        <span className='badge bg-completed'>
          <p style={{ color: '#02961C' }}>{status?.toLocaleString()}</p>
        </span>
      );
    case 'Error':
      return (
        <span className='badge bg-denied'>
          <p style={{ color: '#DE0000' }}>{status?.toLocaleString()}</p>
        </span>
      );
    default:
      return (
        <span className='badge bg-neutral'>
          <p>standy</p>
        </span>
      );
  }
}

export function leaveStatus(status) {
  switch (status) {
    case 'Approved':
      return <span className='badge green'>Approved</span>;
    case 'Denied':
      return <span className='badge red'>Denied</span>;
    case 'Pending':
      return <span className='badge yellow'>Pending</span>;
    default:
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
  }
}

export function overtimeStatus(status) {
  switch (status?.toUpperCase()) {
    case 'APPROVED':
      return <span className='badge green'>Approved</span>;
    case 'REJECTED':
      return <span className='badge red'>Rejected</span>;
    default:
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
  }
}

export function payrollStatus(status) {
  switch (status) {
    case 'Complete':
      return <span className='badge green'>Complete</span>;
    default:
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
  }
}

export function loanStatus(status) {
  switch (status) {
    case 'Active':
      return <span className='badge green'>Active</span>;
    case 'Inactive':
      return <span className='badge red'>Inactive</span>;
    case 'Paid':
      return <span className='badge green'>Paid</span>;
    case 'Ongoing':
      return <span className='badge blue'>Ongoing</span>;
    default:
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
  }
}

export function loanListStatus(status) {
  switch (status) {
    case 'Active':
      return <span className='badge green'>Active</span>;
    default:
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
  }
}

export function requestStatus(status) {
  switch (status) {
    case 'Approved':
      return <span className='badge green'>Approved</span>;
    case 'Denied':
      return <span className='badge red'>Denied</span>;
    case 'Pending':
      return <span className='badge yellow'>Pending</span>;
    default:
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
  }
}

export function projectStatus(status) {
  switch (status) {
    case 'Completed':
      return <span className='badge green'>Completed</span>;
    default:
      return <span className='badge bg-info'>{status}</span>;
  }
}

export function taskStatus(status) {
  switch (status) {
    case 'Completed':
      return <span className='badge green'>Completed</span>;
    default:
      return <span className='badge bg-info'>{status}</span>;
  }
}
export function certificateStatus(status) {
  switch (status) {
    case 'Active':
      return <span className='badge green'>Active</span>;
    case 'Inactive':
      return <span className='badge red'>Inactive</span>;
    default:
      return <span className='badge bg-info'>{status?.toUpperCase()}</span>;
  }
}
