/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

// import components below
import Table from 'components/ReactTable';
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const MedicalRecordActionTable = ({ setShowModal, filter, filteredData }) => {
  const patientData = useSelector(selectPatientData);
  const [data, setData] = useState([]);
  const [active, setActive] = useState('all');

  useEffect(() => {
    axios
      .get('/patient', {
        params: {
          // patient_status: ['admitted'],
        },
      })
      .then((response) => {
        setData(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, [patientData]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Room / Bed', accessor: 'room_bed' },
      { Header: 'Age / Sex', accessor: 'age_sex' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const displayedData =
    !filter.patient_name && !filter.bed_category && !filter.status ? data : filteredData;

  const tableData = React.useMemo(
    () =>
      displayedData?.map((dd) => ({
        id: dd?.id,
        name: dd?.full_name,
        room_bed: `${dd?.room} / ${dd?.bed}`,
        age_sex: `${dd?.age} / ${dd?.sex}`,
        date_of_birth: dd?.date_of_birth,
        status: (
          <span className={`status ${dd?.patient_status === 'admitted' ? 'admitted' : ''}`}>
            {dd?.patient_status}
          </span>
        ),
      })),
    [data, filteredData],
  );

  const handleTableAction = (eventKey, id) => {
    const row = data.find((dd) => dd.id === id);
    const action = {
      profile: () => {},
      view: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            viewRecordModal: true,
            profileData: row,
            profileId: row?.id,
          };
        });
      },
      edit: () => {},
      delete: () => {},
    };

    action[eventKey]();
  };

  const filterList = [
    { key: 'all', name: 'All Patients' },
    { key: 'triage', name: 'Triage' },
    { key: 'awaiting_bed', name: 'Awaiting Bed' },
    { key: 'for_admission', name: 'For Admission' },
    { key: 'for_discharge', name: 'For Discharge' },
  ];

  const handleOnClickFilter = (status) => {
    let filteredData = patientData;
    if (status !== 'all') {
      filteredData = patientData.filter((dd) => dd.patient_status === status);
    }
    setData(filteredData);
    setActive(status);
  };

  return (
    <div className='nursing-medical-records-action-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasView: true }}
        onAction={handleTableAction}
        filter={{
          top: (
            <div className='buttons'>
              {filterList.map((list) => (
                <Button
                  key={list.key}
                  className={list.key === active ? 'active' : ''}
                  onClick={() => handleOnClickFilter(list.key)}
                >
                  {list.name}
                </Button>
              ))}
            </div>
          ),
        }}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

MedicalRecordActionTable.defaultProps = {
  setShowModal: () => {},
};

MedicalRecordActionTable.propTypes = {
  setShowModal: PropTypes.func,
};

export default MedicalRecordActionTable;
