/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Tabs, Tab, Row, Col, Form, InputGroup, FormControl, Button } from 'react-bootstrap';

import BillingHistoryTable from './Table';

import './style.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

const BillingHistory = (props) => {
  const [searchData, setSearchData] = useState({
    basic_search: '',
  });

  const onChangeHandler = (event) => {
    setSearchData((prev) => {
      return {
        ...prev,
        basic_search: event.target.value,
      };
    });
  };

  const handleSearch = () => {
    // Submit function here...
  };

  return (
    <div className='billing-history'>
      <Form>
        <Row className='mb-2'>
          <Col>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Billing Number</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  placeholder='Search here…'
                  value={searchData.basic_search}
                  onChange={onChangeHandler}
                />
                <Button variant='search' onClick={handleSearch}>
                  SEARCH
                </Button>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <BillingHistoryTable />
      </Form>
    </div>
  );
};

export default BillingHistory;
