/* eslint-disable no-unused-vars */
// import components below
import PropTypes from 'prop-types';
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const PageHeader = () => {
  return (
    <div className='overtimetrackingHeader'>
      <PageHeaderUI>
        <h4>Overtime Tracker Module</h4>
        <p>Dashboard / Overtime Tracker</p>
      </PageHeaderUI>
    </div>
  );
};

PageHeader.defaultProps = {
  active: '',
};

PageHeader.propTypes = {
  active: PropTypes.string,
};

export default PageHeader;
