/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, FormControl, Accordion } from 'react-bootstrap';

// components
import FormUI from 'components/UI/FormUI';

// assets
import './index.scss';

const EditRoom = ({ setState, showProfile, setShowProfile }) => {
  const [disabled, setDisabled] = useState(true);
  const [params, setParams] = useState({
    id: showProfile?.id,
    type: '',
    floor: '',
    room: '',
    bed: '',
    price: '',
    unit: '',
    status: '',
    pricing_scheme: '',
    isReserved: 'no',
  });

  const handleChange = (key) => (e) => {
    setParams({
      ...params,
      [key]: e.target.value,
    });

    if (
      params.type.length > 0 &&
      params.floor.length > 0 &&
      params.room.length > 0 &&
      params.bed.length > 0 &&
      params.price.length > 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = () => {
    axios
      .put('/room', params)
      .then((response) => {
        toast.success('Successfully updated!');

        axios.get('/room').then((response) => {
          setState(response.data.data.room_list);
        });
      })
      .catch((error) => {
        toast.warning('ERROR!');
      });
  };

  useEffect(() => {
    if (showProfile.editValues) {
      const { editValues } = showProfile;

      setParams({
        ...params,
        type: editValues.type,
        floor: editValues.floor,
        room: editValues.room,
        bed: editValues.bed,
        price: editValues.price,
        status: editValues.status,
      });
    }
  }, [showProfile]);

  return (
    <FormUI>
      <div className='edit-room-cont'>
        <h3>Edit Room Information</h3>
        <Row>
          <Col>
            <Accordion defaultActiveKey={'0'} className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Room Information</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Type*</Form.Label>
                        <Form.Select onChange={handleChange('type')} value={params.type}>
                          <option hidden>-Select-</option>
                          <option value='Private'>Private</option>
                          <option value='Suite'>Suite</option>
                          <option value='Ward'>Ward</option>
                          <option value='Operating Room'>Operating Room</option>
                          <option value='Delivery Room'>Delivery Room</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Floor*</Form.Label>
                        <Form.Select onChange={handleChange('floor')} value={params.floor}>
                          <option>-Select-</option>
                          <option value='1st'>1st</option>
                          <option value='2nd'>2nd</option>
                          <option value='3rd'>3rd</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {/* <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Unit*</Form.Label>
                        <Form.Select>
                          <option>-Select-</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                  </Row>

                  <Row>
                    <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Room*</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Enter Here'
                          onChange={handleChange('room')}
                          value={params.room}
                        />
                      </Form.Group>
                    </Col>
                    <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Bed*</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Enter Here'
                          onChange={handleChange('bed')}
                          value={params.bed}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Status*</Form.Label>
                        <Form.Select>
                          <option>-Select-</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                  </Row>

                  <Row>
                    {/* <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Floor Pricing Scheme**</Form.Label>
                        <Form.Select>
                          <option>-Select-</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                    <Col md={6} className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Price*</Form.Label>
                        <FormControl
                          type='number'
                          className='bg-white'
                          value={params.price}
                          placeholder='Enter Here'
                          onChange={handleChange('price')}
                          onKeyDown={(evt) =>
                            (evt.key === 'e' ||
                              evt.key === 'E' ||
                              evt.key === '-' ||
                              evt.key === '.') &&
                            evt.preventDefault()
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>status*</Form.Label>
                        <Form.Select onChange={handleChange('status')} value={params.status}>
                          <option>-Select-</option>
                          <option value='available'>Available</option>
                          <option value='occupied'>Occupied</option>
                          <option value='cleaning'>Cleaning</option>
                          <option value='unavailable'>Unavailable</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {/* <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Reserved?*</Form.Label>
                        <FormControl type='text' className='bg-white' placeholder='Enter Here' />
                      </Form.Group>
                    </Col> */}
                  </Row>

                  <Row>
                    <div className='buttonWrapper'>
                      <Button variant='search' onClick={() => handleSubmit()}>
                        SAVE
                      </Button>
                      <Button
                        variant='clear'
                        onClick={() => {
                          setShowProfile((prevState) => {
                            return {
                              ...prevState,
                              createRoom: false,
                              editRoom: false,
                              isDefault: true,
                            };
                          });
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </FormUI>
  );
};

EditRoom.defaultProps = {
  showProfile: {},
  setState: () => {},
  setShowProfile: () => {},
};

EditRoom.propTypes = {
  setState: PropTypes.func,
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default EditRoom;
