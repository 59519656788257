/* eslint-disable */
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TabsUI from '../UI/TabsUI';

const CustomTabs = ({ tabKey, options, ...props }) => (
  <TabsUI>
    <Tabs {...props}>
      {options.map(({ component: Component, componentProps = {}, ...resProps }, idx) => (
        <Tab key={`${tabKey || 'tab'}-${idx}`} {...resProps}>
          {props.activeKey === resProps.eventKey && Component && (
            <Component activeKey={props.activeKey} {...componentProps} />
          )}
        </Tab>
      ))}
    </Tabs>
  </TabsUI>
);

CustomTabs.defaultProps = {
  activeKey: '',
  defaultActiveKey: '',
  options: [],
  onSelect: () => {},
};

CustomTabs.propTypes = {
  activeKey: PropTypes.string,
  defaultActiveKey: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      eventKey: PropTypes.string.isRequired,
      className: PropTypes.string,
      component: PropTypes.any,
      componentProps: PropTypes.object,
    }),
  ),
  onSelect: PropTypes.func,
};

export default CustomTabs;
