/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';

// components
import FormUI from 'components/UI/FormUI';

// import assets below
import './style.scss';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const ModalProcedure = ({ showProfile, setShowProfile, refreshData }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    action: 'complete',
    request_id: showProfile?.procedure?.request_id,
  });

  const [procedures, setProcedures] = useState([]);
  const [rooms, setRooms] = useState([]);

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const resetState = () => {
    setUserData({
      action: '',
      request_id: '',
    });
  };

  // Submit data here
  const handleSubmit = async () => {
    const userData = { action: 'complete', id: showProfile?.procedure?.request_id };
    console.log(userData);
    await axios
      .put('/requisition/procedure', userData)
      .then((response) => {
        console.log(response);
        toast.success('Successfully created procedure');
        // resetState();
        setShowProfile({ isShowForm: false });
        console.log('put response: ', response.data);
        dispatch(showPatient(showProfile.profileId));
      })
      .catch((err) => {
        err.response.data.errors.map((item) => {
          toast.warning(item?.msg);
        });
      });
  };

  const handleProcedure = (label, value, code, price) => {
    setUserData({
      ...userData,
      procedure: label,
      ctp_code: code,
      price: price,
    });
  };

  // console.log('user data xxx: ', showProfile);

  const loadProcedures = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterProcedures(input));
      }, 1000);
    });
  };

  const filterProcedures = (input) => {
    return procedures.filter((i) => {
      return i.value.toLowerCase().includes(input.toLowerCase());
    });
  };

  const filterRooms = (input) => {
    return rooms.filter((i) => {
      return i.value.toLowerCase().includes(input.toLowerCase());
    });
  };

  const loadRooms = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterRooms(input));
      }, 1000);
    });
  };

  const handelRoom = (roomId) => {
    setUserData({
      ...userData,
      roomId: roomId,
    });
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      padding: '0.45em',
      color: '#404040',
      borderColor: '#c8c8c8',
      borderRadius: '10px',
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: '100',
    }),
  };

  return (
    <Modal
      size='lg'
      show={showProfile.isShowForm}
      onExit={() => refreshData()}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isShowForm: false,
          };
        })
      }
      aria-labelledby='Lab Request Modal'
      className='add-procedure-modal'
    >
      <Modal.Body>
        <div className='add-procedure-request'>
          <div className='d-flex header'>
            <p>PROCEDURE</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, isShowForm: false };
                });
                resetState();
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col sm={5}>
                <Form.Group className='mb-4'>
                  <Form.Label>Procedure</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey'
                    placeholder='Procedure'
                    aria-readonly
                    readOnly
                    value={showProfile?.procedure?.procedure}
                  />
                </Form.Group>
              </Col>

              <Col m={5}>
                <Form.Label>Physician Name</Form.Label>
                <FormControl
                  type='text'
                  className='bg-grey'
                  placeholder='Enter Here'
                  aria-readonly
                  readOnly
                  value={showProfile?.procedure?.doctor}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={5}>
                <Form.Group className='mb-4'>
                  <Form.Label>CPT Code</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey'
                    placeholder='Enter Here'
                    aria-readonly
                    readOnly
                    value={showProfile?.procedure?.ctp_code}
                  />
                </Form.Group>
              </Col>

              <Col sm={5}>
                <Form.Group className='mb-4'>
                  <Form.Label>Major/ Minor?</Form.Label>
                  <Form.Select disabled onChange={handleChange('major_minor')} value={showProfile?.procedure?.major_minor}>
                    <option hidden>-Select-</option>
                    <option value='Major'>Major</option>
                    <option value='Minor'>Minor</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Procedure Description</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={2}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={showProfile?.procedure?.procedure}
                    onChange={handleChange('procedure_description')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <Form.Group className='mb-4'>
                  <Form.Label>Room Request</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={2}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={showProfile?.procedure?.bed}
                    onChange={handleChange('procedure_description')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Remarks</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={2}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={showProfile?.procedure?.remarks}
                    onChange={handleChange('remarks')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() => {
                      resetState();
                      setShowProfile((prevState) => {
                        return { ...prevState, isShowForm: false };
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ModalProcedure.defaultProps = {
  showProfile: () => {},
  setShowProfile: () => {},
};

ModalProcedure.propTypes = {
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default ModalProcedure;
