/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

import { selectAuth } from 'reducers/authReducer';
import { getProfile, getACLState, updateProfile } from 'reducers/aclReducer';
import ConditionalRender from 'components/ConditionalRender';

import styles from './index.module.scss';
import axios from 'axios';

const OtherDetails = () => {
  const dispatch = useDispatch();

  const authState = useSelector(selectAuth);
  const profileState = useSelector(getACLState);

  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({});
  const [employmentData, setEmploymentData] = useState({
    id: '',
    department: '',
    tin_number: '',
    sss_number: '',
    employed_at: '',
    worked_hours: '',
    terminated_at: '',
    pagibig_number: '',
    employment_type: '',
    philhealth_number: '',
    employment_status: '',
  });

  const isReadOnly = () => {
    if (isEdit) {
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    const { value, name, type } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleEmploymentDetailsChange = (e) => {
    const { value, name, type } = e.target;

    setEmploymentData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleEnableUpdate = () => {
    setIsEdit(true);
  };

  const handleDisableUpdate = () => {
    setIsEdit(false);

    if (authState?.userInfo) {
      setFormData(authState?.userInfo);
    }
  };

  const removeEmptyData = () => {
    // eslint-disable-next-line prefer-const
    let obj2 = {};
    // eslint-disable-next-line prefer-const
    for (let key in employmentData) {
      if (
        employmentData[key] !== '' &&
        employmentData[key] !== null &&
        employmentData[key] !== undefined
      ) {
        obj2[key] = employmentData[key];
      }
    }

    return obj2;
  };

  const handleSave = async () => {
    const uploadData = removeEmptyData();

    console.log('the payload data: ', {
      payload: uploadData,
      id: profileState?.profile?.id,
    });

    // eslint-disable-next-line no-unreachable
    dispatch(
      updateProfile({
        payload: uploadData,
        id: formData?.id,
      }),
    );

    axios
      .put(`${process.env.REACT_APP_API_BASE_ACL}/api/users/${profileState?.profile?.id}/role`, {
        role: profileState?.profile.attributes?.role,
      })
      .then((response) => console.log('successfully updated role:', response));

    setIsEdit(false);
    toast.success('Profile successfully updated.');
  };

  renderWhenProfileIsPresent(authState, setFormData, profileState, setEmploymentData);

  return (
    <div className={`${styles['other-details']} shadow`}>
      <div className={styles['text-container']}>
        <div className={styles['header']}>
          <h3>Other Information</h3>

          <div className={styles['buttons-container']}></div>
        </div>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>Employment Status :</Form.Label>
          <FormControl
            name='employment_status'
            readOnly={isReadOnly()}
            value={employmentData?.employment_status}
            onChange={handleEmploymentDetailsChange}
            placeholder='Details Here'
          />
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>Employment Type :</Form.Label>
          <Form.Select
            name='employment_type'
            required
            onChange={handleEmploymentDetailsChange}
            value={employmentData?.employment_type}
          >
            <option value='' hidden>
              Select Employment Type
            </option>

            {employmentType.map(({ id, name, type }) => {
              return (
                <option key={id} value={type}>
                  {name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>Department :</Form.Label>
          <FormControl
            name='department'
            readOnly={isReadOnly()}
            value={employmentData?.department}
            onChange={handleEmploymentDetailsChange}
            placeholder='Details Here'
          />
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>Worked Hours :</Form.Label>
          <FormControl
            name='worked_hours'
            readOnly={isReadOnly()}
            value={employmentData?.worked_hours}
            onChange={handleEmploymentDetailsChange}
            placeholder='Details Here'
          />
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>Date Hired :</Form.Label>
          <FormControl
            name='employed_at'
            readOnly={isReadOnly()}
            value={employmentData?.employed_at}
            onChange={handleEmploymentDetailsChange}
            placeholder='Details Here'
          />
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>Last Day Duty :</Form.Label>
          <FormControl
            name='terminated_at'
            readOnly={isReadOnly()}
            value={employmentData?.terminated_at}
            onChange={handleEmploymentDetailsChange}
            placeholder='Details Here'
          />
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>HMO Provider :</Form.Label>
          <FormControl
            type='number'
            name='hmoProvider'
            readOnly={isReadOnly()}
            value={formData?.hmoProvider}
            onChange={handleChange}
            placeholder='Details Here'
            disabled
          />
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>HMO Number :</Form.Label>
          <FormControl
            type='number'
            name='hmoNumber'
            readOnly={isReadOnly()}
            value={formData?.hmoNumber}
            onChange={handleChange}
            placeholder='Details Here'
            disabled
          />
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>Life Insurance Provider :</Form.Label>
          <FormControl
            type='number'
            name='lifeInsuranceProvider'
            readOnly={isReadOnly()}
            value={formData?.lifeInsuranceProvider}
            onChange={handleChange}
            placeholder='Details Here'
            disabled
          />
        </Form.Group>
      </div>

      <div className={styles['text-container']}>
        <div className={styles['header']}>
          <h3>Government IDs</h3>

          <div className={styles['buttons-container']}>
            <ConditionalRender
              condition={
                isEdit === false /* && profileState?.profile?.attributes?.role === 'Super Admin' */
              }
              renderIf={
                <Button variant='outline-primary' onClick={handleEnableUpdate}>
                  Update
                </Button>
              }
            />

            <ConditionalRender
              condition={isEdit === true}
              renderIf={
                <>
                  <Button variant='outline-success' onClick={handleSave}>
                    Save
                  </Button>
                  <Button
                    variant='outline-secondary'
                    className={styles['cancel-button']}
                    onClick={handleDisableUpdate}
                  >
                    Cancel
                  </Button>
                </>
              }
            />
          </div>
        </div>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>SSS Number :</Form.Label>
          <FormControl
            name='sss_number'
            readOnly={isReadOnly()}
            value={employmentData?.sss_number}
            onChange={handleEmploymentDetailsChange}
            placeholder='Details Here'
            type='number'
          />
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>Philhealth Number :</Form.Label>
          <FormControl
            name='philhealth_number'
            readOnly={isReadOnly()}
            value={employmentData?.philhealth_number}
            onChange={handleEmploymentDetailsChange}
            placeholder='Details Here'
            type='number'
          />
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>Pagibig Number :</Form.Label>
          <FormControl
            name='pagibig_number'
            readOnly={isReadOnly()}
            value={employmentData?.pagibig_number}
            onChange={handleEmploymentDetailsChange}
            placeholder='Details Here'
            type='number'
          />
        </Form.Group>

        <Form.Group className={styles['inline-group']}>
          <Form.Label>Tax Identification Number (TIN):</Form.Label>
          <FormControl
            name='tin_number'
            readOnly={isReadOnly()}
            value={employmentData?.tin_number}
            onChange={handleEmploymentDetailsChange}
            placeholder='Details Here'
            type='number'
          />
        </Form.Group>
      </div>
    </div>
  );
};

const renderWhenProfileIsPresent = (authState, setFormData, profileState, setEmploymentData) => {
  useEffect(() => {
    if (authState?.userInfo) {
      setFormData({
        ...profileState?.profile?.attributes,
        id: profileState?.profile?.id,
      });
      // setEmploymentData(profileState?.profile?.attributes?.employment);
      const otherDetails = profileState?.profile?.attributes?.employment;

      setEmploymentData({
        id: profileState?.profile?.id,
        tin_number: otherDetails?.tin_number,
        sss_number: otherDetails?.sss_number,
        employed_at: otherDetails?.employed_at,
        worked_hours: otherDetails?.worked_hours,
        terminated_at: otherDetails?.terminated_at,
        pagibig_number: otherDetails?.pagibig_number,
        employment_type: otherDetails?.employment_type,
        philhealth_number: otherDetails?.philhealth_number,
        employment_status: otherDetails?.employment_status,
        department: profileState?.profile?.attributes?.department,
      });
    }
  }, [authState, profileState?.profile]);
};

const employmentType = [
  {
    id: 1,
    name: 'Full Time Permanent',
    type: 'full-time-permanent',
  },
  {
    id: 2,
    name: 'Full Time Contractual',
    type: 'full-time-contractual',
  },
  {
    id: 3,
    name: 'Part Time Permanent',
    type: 'part-time-permanent',
  },
  {
    id: 4,
    name: 'Part Time Contractual',
    type: 'part-time-contractual',
  },
  {
    id: 5,
    name: 'Rotating/Visiting/Affiliate',
    type: 'rotating/visiting/affiliate',
  },
  {
    id: 6,
    name: 'Outsource',
    type: 'outsource',
  },
];

export default OtherDetails;
