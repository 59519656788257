import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import './Modal.scss';

const ConfirmCreateModal = ({ showModal, setShowModal, onNext }) => {
  const handleClose = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    onNext();
    setShowModal(false);
  };

  return (
    <Modal
      aria-labelledby='Confirm Create Modal'
      centered
      className='confirm-create-modal'
      show={showModal}
      onHide={handleClose}
      size='lg'
    >
      <Modal.Header>
        <Modal.Title>CREATE STOCK CHECK</Modal.Title>
        <button
          type='button'
          className='btn-close btn-close-white'
          aria-label='Close'
          onClick={handleClose}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <p className='mt-3'>Are you sure you want to create this stock check?</p>

        <p>Once created, you will not be able to add or remove items included in the stock check.</p>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button type='submit' variant='search' onClick={handleConfirm}>
          CREATE STOCK CHECK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmCreateModal.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  onNext: () => {},
};

ConfirmCreateModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onNext: PropTypes.func,
};

export default ConfirmCreateModal;
