/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import TagsInput from 'react-tagsinput';
import { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { selectInventory, updateItemPrice } from 'reducers/inventoryReducer';
import { getrefillGroups, selectGroups } from 'reducers/refillRequest';

// import assets below
import './style.scss';
import 'react-tagsinput/react-tagsinput.css';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import ConditionalRender from 'components/ConditionalRender';

// main component
const PriceUpdate = ({ showProfile, setShowProfile, getInventoryItems }) => {
  if (!showProfile?.rowData) return;

  const dispatch = useDispatch();
  const groupState = useSelector(selectGroups);
  const inventoryState = useSelector(selectInventory);
  const { updateItemPriceLogs, updateItemData } = inventoryState;

  const [userData, setUserData] = useState({});

  const closeModal = () => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        priceUpdate: false,
        showView: true,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleGroupChange = (e) => {
    setUserData((prev) => {
      return {
        ...prev,
        group: { label: e.value, value: e.value },
      };
    });

    return;
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    const params = {
      id: userData?.id,
      payload: {
        new_box_price: userData?.box_price,
        new_piece_price: userData?.piece_price,
        piece_per_box: userData?.piece_per_box,
      },
    };

    dispatch(updateItemPrice(params));
  };

  runOnFirstRender(dispatch);

  getDataFromRowData(setUserData, showProfile, groupState);

  runAfterSubmit(
    showProfile,
    updateItemPriceLogs,
    getInventoryItems,
    setShowProfile,
    updateItemData,
  );

  return (
    <Modal
      size='lg'
      onHide={closeModal}
      className='price-update-modal'
      show={showProfile.priceUpdate}
      aria-labelledby='ITEM DETAILS'
    >
      <Modal.Body>
        <div className='price-update-request'>
          <div className='d-flex header'>
            <p>PRICE UPDATE</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={closeModal} />
          </div>
        </div>

        <FormUI>
          <Form className='price-update-form' onSubmit={handleUpdate}>
            <div className='item-header mb-4'>
              <h3>ITEM PRICE INFORMATION</h3>
            </div>

            <Row className='mb-3'>
              <Col>
                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Piece Price :</Form.Label>
                  <FormControl
                    required
                    type='number'
                    name='piece_price'
                    onChange={handleChange}
                    value={userData?.piece_price}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Box Price :</Form.Label>
                  <FormControl
                    required
                    type='number'
                    name='box_price'
                    onChange={handleChange}
                    value={userData?.box_price}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Piece Per Box :</Form.Label>
                  <FormControl
                    required
                    type='number'
                    name='piece_per_box'
                    onChange={handleChange}
                    value={userData?.piece_per_box}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-3 buttons-cont'>
                  <Button
                    type='submit'
                    variant='primary'
                    className='add-button'
                    disabled={updateItemPriceLogs?.isLoading === true}
                  >
                    Update
                  </Button>
                  <Button className='cancel-button' onClick={closeModal}>
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const runOnFirstRender = (dispatch) => {
  useEffect(() => {
    dispatch(getrefillGroups());
  }, []);
};

const getDataFromRowData = (setUserData, showProfile, groupState) => {
  useEffect(() => {
    if (showProfile.rowData && showProfile.priceUpdate && groupState) {
      const group = groupState.find((dd) => +dd.id === +showProfile?.rowData?.attributes?.group_id);

      setUserData({
        ...showProfile.rowData.attributes,
        id: showProfile?.rowData?.id,
        group: { id: group?.id, label: group?.attributes?.name, value: group?.attributes?.name },
      });
    }
  }, [showProfile, groupState]);
};

const runAfterSubmit = (
  showProfile,
  updateItemPriceLogs,
  getInventoryItems,
  setShowProfile,
  updateItemData,
) => {
  useEffect(() => {
    const { isSuccess, isFailed, error } = updateItemPriceLogs;

    if (isSuccess && showProfile?.priceUpdate) {
      toast.success('Successfully updated!');
      setTimeout(() => {
        setShowProfile((prev) => {
          return {
            ...prev,
            priceUpdate: false,
            profileId: null,
            rowData: [],
          };
        });
      }, 200);

      getInventoryItems();
    }

    if (isFailed) {
      toast.error(error);
    }
  }, [updateItemPriceLogs]);
};

const customStyles = {
  control: () => ({
    height: '50px',
    display: 'flex',
    borderRadius: '10px',
    border: '1px solid #c8c8c8',
  }),
};

PriceUpdate.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

PriceUpdate.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default PriceUpdate;
