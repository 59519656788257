/* eslint-disable */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

// Components
import Table from 'components/ReactTable';
import { selectnurse } from 'reducers/nurseReducer';

// Assets below
import './Table.scss';
import { toast } from 'react-toastify';

const defaultList = [
  {
    id: 1,
    key: '1',
    name: 'Medication',
  },
  {
    id: 2,
    key: '0',
    name: 'Supply',
  },
];

// main component
const AddOrderTable = ({
  active,
  search,
  getItems,
  cartData,
  setActive,
  tableState,
  setCartData,
  setTableState,
}) => {
  const nurseState = useSelector(selectnurse);
  const { data, items, logs } = nurseState;

  const handleOnClickFilter = (status) => {
    setActive(status);
    getItems(status);
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Code', accessor: 'code' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Piece Price', accessor: 'unit_price' },
      { Header: 'Box Price', accessor: 'box_price' },
      { Header: 'Unit Type', accessor: 'unit_type' },
      { Header: 'QTY', accessor: 'qty' },
      { Header: 'Action', accessor: 'action' },
    ],
    [],
  );

  const tableData = React.useMemo(() => {
    if (search?.handleSearch) {
      const filteredData = tableState.filter((value) => {
        return (
          value.name.toLowerCase().includes(search?.search.toLowerCase()) ||
          String(value.code) === String(search?.search) ||
          String(value.description) === String(search?.search)
        );
      });

      return filteredData.map((dd) => {
        return {
          id: dd?.id,
          name: dd?.name,
          code: dd?.code,
          description: dd?.description,
          unit_price: dd?.unit_price,
          box_price: dd?.box_price,
          unit_type: (
            <Form.Select
              className='unit-type-select'
              value={dd.selectedUnit}
              onChange={(e) => {
                changeType(dd.id, e.target.value);
              }}
            >
              <option value='' hiden>
                Select Type
              </option>
              <option value='piece'>Piece</option>
              <option value='box'>Box</option>
            </Form.Select>
          ),
          qty_text: dd?.qty,
          qty: (
            <div className='quantity'>
              <Button
                className='quantity minus'
                onClick={() => {
                  decreaseQuantity(dd?.id);
                }}
              >
                -
              </Button>

              <p>{dd?.qty}</p>

              <Button
                className='quantity add'
                onClick={() => {
                  addQuantity(dd?.id);
                }}
              >
                +
              </Button>
            </div>
          ),
          action: (
            <p>
              <Button
                className='add-to-cart'
                onClick={() => {
                  addToCart(dd);
                }}
              >
                Add to Cart
              </Button>
            </p>
          ),
        };
      });
    } else {
      return tableState.map((dd) => {
        return {
          id: dd?.id,
          name: dd?.name,
          code: dd?.code,
          description: dd?.description,
          unit_price: dd?.unit_price,
          box_price: dd?.box_price,
          unit_type: (
            <Form.Select
              className='unit-type-select'
              value={dd?.selectedUnit}
              onChange={(e) => {
                changeType(dd?.id, e.target.value);
              }}
            >
              <option value='' hidden>
                Select Type
              </option>
              <option value='piece'>Piece</option>
              <option value='box'>Box</option>
            </Form.Select>
          ),
          qty_text: dd?.qty,
          qty: (
            <div className='quantity'>
              <Button
                className='quantity minus'
                onClick={() => {
                  decreaseQuantity(dd?.id);
                }}
              >
                -
              </Button>

              <p>{`${dd?.qty}`}</p>

              <Button
                className='quantity add'
                onClick={() => {
                  addQuantity(dd?.id);
                }}
              >
                +
              </Button>
            </div>
          ),
          action: (
            <p>
              <Button
                className='add-to-cart'
                onClick={() => {
                  addToCart(dd);
                }}
              >
                Add to Cart
              </Button>
            </p>
          ),
        };
      });
    }
  }, [tableState, search]);

  const addQuantity = (id) => {
    const newTableData = tableState.map((data) => {
      if (data.id === id) {
        const newQuantity = parseInt(data.qty) + parseInt(1);
        return { ...data, qty: newQuantity };
      } else {
        return data;
      }
    });

    setTableState(newTableData);
  };

  const decreaseQuantity = (id) => {
    const newTableData = tableState.map((data) => {
      if (data.id === id) {
        const newQuantity = parseInt(data.qty) - parseInt(1);
        return { ...data, qty: newQuantity > 0 ? newQuantity : 1 };
      } else {
        return data;
      }
    });

    setTableState(newTableData);
  };

  const changeType = (id, value) => {
    const newTableData = tableState.map((data) => {
      if (data.id === id) {
        return { ...data, selectedUnit: value };
      } else {
        return data;
      }
    });

    setTableState(newTableData);
  };

  const addToCart = (rowData) => {
    let isSameProduct = false;

    cartData.forEach((cart) => {
      if (cart.id === rowData.id) {
        isSameProduct = true;
      }
    });

    if (!rowData?.selectedUnit) {
      toast.warning('Please select type.');
      return;
    }

    if (isSameProduct) {
      toast.warning('Product is already added to cart.');
      return;
    }

    setCartData((prevState) => {
      return [rowData, ...prevState];
    });

    // To reset the table data
    const resetData = tableState.map((data) => {
      if (data?.id === rowData?.id) {
        return {
          ...data,
          selectedUnit: 'piece',
          qty: '1',
        };
      }

      return data;
    });

    setTableState(resetData);
  };

  getItemsOnRender(getItems);

  setTableStateOnSuccess(setTableState, logs, data, items);

  return (
    <Row>
      <Col>
        <Table
          logs={logs}
          data={tableData}
          columns={tableColumns}
          filter={{
            top: (
              <div className='buttons'>
                {defaultList.map((list) => (
                  <Button
                    key={list.key}
                    className={list.key === active ? 'active' : ''}
                    onClick={() => handleOnClickFilter(list.key)}
                  >
                    {list.name}
                  </Button>
                ))}
              </div>
            ),
          }}
          pagination={['BOTTOM']}
        />
      </Col>
    </Row>
  );
};

const setTableStateOnSuccess = (setTableState, logs, data, items) => {
  useEffect(() => {
    const { isSuccess, isFailed } = logs;

    if (isSuccess) {
      const newData = data.map((d) => {
        const itemID = d?.relationships?.item?.data[0]?.id;
        const itemData = items.find((data) => data?.id == itemID && data?.type === 'items');

        return {
          id: d?.id,
          qty: '1',
          itemData: itemData,
          data: d?.attributes,
          selectedUnit: 'piece',
          name: itemData?.attributes?.name,
          code: itemData?.attributes?.code,
          box_price: itemData?.attributes?.box_price,
          unit_price: itemData?.attributes?.piece_price,
          description: itemData?.attributes?.description,
          unit_of_measure: d?.attributes?.unit_of_measure,
        };
      });

      setTableState(newData);
    }

    if (isFailed) {
      console.log('Something went wrong.');
    }
  }, [logs]);
};

const getItemsOnRender = (getItems) => {
  useEffect(() => {
    getItems();
  }, []);
};

AddOrderTable.defaultProps = {
  searchData: {},
  departmentID: '',
};

AddOrderTable.propTypes = {
  searchData: PropTypes.object,
  departmentID: PropTypes.string,
};

export default AddOrderTable;
