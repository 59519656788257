/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  returnItem,
  completeItem,
  getEquipments,
  getBorrowedList,
  submitBorrowItem,
  updateBorrowItem,
  getSingleBorrowedData,
} from './thunks/borrowReturnThunks';

const initialLogs = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  error: null,
};

const initialState = {
  // For Table List
  data: [],
  items: [],
  logs: initialLogs,

  // For Equipments
  dataItems: [],
  dataItemsMeta: [],
  dataItemsIncluded: [],
  dataItemsLogs: initialLogs,

  // For submit Borrow
  submitLogs: initialLogs,

  // For Single Data
  singleData: [],
  singleDataIncluded: [],
  singleDataLogs: initialLogs,

  // For Update Data
  updateLogs: initialLogs,

  // For return item
  returnLogs: initialLogs,

  // For Complete item
  completeLogs: initialLogs,
};

const slice = createSlice({
  name: 'borrowReturn',
  initialState,
  reducers: {
    resetLogState: (state) => {
      state.logs = initialLogs;
      state.submitLogs = initialLogs;
      state.updateLogs = initialLogs;
      state.returnLogs = initialLogs;
      state.completeLogs = initialLogs;
      state.dataItemsLogs = initialLogs;
      state.singleDataLogs = initialLogs;
    },
  },
  extraReducers: {
    // For Complete Item
    [completeItem.fulfilled]: (state) => {
      state.completeLogs.isSuccess = true;
      state.completeLogs.isLoading = false;
    },
    [completeItem.pending]: (state) => {
      state.completeLogs.error = '';
      state.completeLogs.isFailed = false;
      state.completeLogs.isLoading = true;
      state.completeLogs.isSuccess = false;
    },
    [completeItem.rejected]: (state, action) => {
      state.completeLogs.isFailed = true;
      state.completeLogs.isLoading = false;
      state.completeLogs.error = action.payload.errors;
    },

    // For Update Borrow
    [returnItem.fulfilled]: (state, action) => {
      state.returnLogs.isSuccess = true;
      state.returnLogs.isLoading = false;
    },
    [returnItem.pending]: (state) => {
      state.returnLogs.error = '';
      state.returnLogs.isFailed = false;
      state.returnLogs.isLoading = true;
      state.returnLogs.isSuccess = false;
    },
    [returnItem.rejected]: (state, action) => {
      state.returnLogs.isFailed = true;
      state.returnLogs.isLoading = false;
      state.returnLogs.error = action.payload.errors;
    },

    // For Update Borrow
    [updateBorrowItem.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [updateBorrowItem.pending]: (state) => {
      state.updateLogs.error = '';
      state.updateLogs.isFailed = false;
      state.updateLogs.isLoading = true;
      state.updateLogs.isSuccess = false;
    },
    [updateBorrowItem.rejected]: (state, action) => {
      state.updateLogs.error = action.payload.errors;
      state.updateLogs.isFailed = true;
      state.updateLogs.isLoading = false;
    },

    // For Show Single Data
    [getSingleBorrowedData.fulfilled]: (state, action) => {
      state.singleDataLogs.isSuccess = true;
      state.singleDataLogs.isLoading = false;
      state.singleData = action.payload.data;
      state.singleDataIncluded = action.payload.included;
    },
    [getSingleBorrowedData.pending]: (state) => {
      state.singleDataLogs.error = '';
      state.singleDataLogs.isFailed = false;
      state.singleDataLogs.isLoading = true;
      state.singleDataLogs.isSuccess = false;
    },
    [getSingleBorrowedData.rejected]: (state) => {
      state.singleData = [];
      state.singleDataIncluded = [];
      state.singleDataLogs.isFailed = true;
      state.singleDataLogs.isLoading = false;
    },

    // For Submit Borrow
    [submitBorrowItem.fulfilled]: (state, action) => {
      state.submitLogs.isSuccess = true;
      state.submitLogs.isLoading = false;
    },
    [submitBorrowItem.pending]: (state) => {
      state.submitLogs.error = '';
      state.submitLogs.isFailed = false;
      state.submitLogs.isLoading = true;
      state.submitLogs.isSuccess = false;
    },
    [submitBorrowItem.rejected]: (state, action) => {
      state.submitLogs.error = action.payload.errors;
      state.submitLogs.isFailed = true;
      state.submitLogs.isLoading = false;
    },

    // For Borrow Table List
    [getBorrowedList.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
      state.items = action.payload.included;
    },
    [getBorrowedList.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getBorrowedList.rejected]: (state) => {
      state.data = [];
      state.items = [];
      state.logs.isFailed = true;
      state.logs.isLoading = false;
    },

    // For Get Equipments
    [getEquipments.fulfilled]: (state, action) => {
      state.dataItemsLogs.isSuccess = true;
      state.dataItemsLogs.isLoading = false;
      state.dataItems = action.payload.data;
      state.dataItemsMeta = action.payload.meta;
      state.dataItemsIncluded = action.payload.included;
    },
    [getEquipments.pending]: (state) => {
      state.dataItemsLogs.error = '';
      state.dataItemsLogs.isFailed = false;
      state.dataItemsLogs.isLoading = true;
      state.dataItemsLogs.isSuccess = false;
    },
    [getEquipments.rejected]: (state) => {
      state.dataItems = [];
      state.dataItemsMeta = [];
      state.dataItemsIncluded = [];
      state.dataItemsLogs.isFailed = true;
      state.dataItemsLogs.isLoading = false;
    },
  },
});

export const { actions: borrowReturnActions, reducer: borrowReturnReducer } = slice;

export const { resetLogState } = borrowReturnActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.borrowReturn || initialState;

export const selectborrowReturn = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState,
);

/* --------------------------------------------------------------------------------- */

export {
  returnItem,
  completeItem,
  getEquipments,
  getBorrowedList,
  submitBorrowItem,
  updateBorrowItem,
  getSingleBorrowedData,
};
