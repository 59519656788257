/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

// import components below
import Layout from 'components/Layout';
import Header from './components/Header';
import Cards from './components/Card';
import UserAccounts from './components/UserAccounts';
import SideBar from 'components/ACLComponents/SideBar';

// main component
const Acl = () => {
  return (
    <Layout pageTitle='ACL'>
      <Header />

      <Row className='mt-4'>
        <Col sm={9}>
          <Cards />
          <UserAccounts />
        </Col>
        <Col sm={3}>
          <SideBar />
        </Col>
      </Row>
    </Layout>
  );
};

export default Acl;
