/* eslint-disable */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import components below
import { roomStatus } from 'helpers';
import Table from 'components/ReactTable';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const HouseKeepingTable = ({ state, setState, setShowProfile }) => {
  const [params, setParams] = useState({
    status: 'available',
  });

  const changeStatus = (id) => {
    axios
      .put('/room', { ...params, id: id })
      .then((response) => {
        toast.warning('Successfully updated!');

        axios.get('/room?status=cleaning').then((response) => {
          setState(response.data.data.room_list);
        });
      })
      .catch((error) => {
        toast.warning('ERROR!');
      });
  };

  useEffect(() => {
    axios
      .get('/room?status=cleaning')
      .then((response) => {
        setState(response.data.data.room_list);
      })
      .catch((error) => {
        console.log(error);
        toast.error('ERROR FETCHING ROOMS');
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Type', accessor: 'type' }, // accessor is the "key" in the data
      { Header: 'Floor', accessor: 'floor' },
      { Header: 'Room', accessor: 'room' },
      { Header: 'Bed', accessor: 'bed' },
      { Header: 'Price', accessor: 'price' },
      { Header: 'Reserved', accessor: 'reserved' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        type: dd?.type,
        floor: dd?.floor,
        room: dd?.room,
        bed: dd?.bed,
        price: dd?.price,
        reserved: dd?.isReserved,
        status: roomStatus(dd?.status),
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      profile: () => {},
      view: () => {},
      edit: () => {},
      approve: () => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            id: row?.id,
          };
        });
        changeStatus(row?.id);
      },
    };

    action[eventKey]();
  };

  return (
    <div className='room-bed-management-house-keeping-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasApprove: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

HouseKeepingTable.defaultProps = {
  state: [],
  setState: () => {},
  showProfile: () => {},
  setShowProfile: () => {},
};

HouseKeepingTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default HouseKeepingTable;
