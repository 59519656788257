// import components below
import PropTypes from 'prop-types';
import PageHeaderUI from 'components/UI/PageHeaderUI';

// import assets below

// main component
const OutpatientHeader = ({ active }) => {
  return (
    <div className='outpatientHeader'>
      <PageHeaderUI>
        <h4>Outpatient Department Module</h4>
        <p>Dashboard / Outpatient Department / {active}</p>
      </PageHeaderUI>
    </div>
  );
};

OutpatientHeader.defaultProps = {
  active: '',
};

OutpatientHeader.propTypes = {
  active: PropTypes.string,
};

export default OutpatientHeader;
