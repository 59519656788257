import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import './Modal.scss';

const ConfirmStartStockCheck = ({ show, showModal, setStockCheck }) => {
  const handleClose = () => {
    showModal(false);
  };

  const handleConfirm = () => {
    showModal(false);
    setStockCheck(true);
  };

  return (
    <Modal
      aria-labelledby='Confirm Create Modal'
      centered
      className='confirm-create-modal'
      show={show}
      onHide={handleClose}
      size='lg'
    >
      <Modal.Header>
        <Modal.Title>START STOCK CHECK</Modal.Title>
        <button
          type='button'
          className='btn-close btn-close-white'
          aria-label='Close'
          onClick={handleClose}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <p className='mt-3'>
          The stock check will be started and items will automatically be locked for ordering.
        </p>

        <p>Are you sure you want to proceed?</p>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button type='submit' variant='search' onClick={handleConfirm}>
          START STOCK CHECK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmStartStockCheck.defaultProps = {
  show: false,
  showModal: () => {},
  setStockCheck: () => {},
};

ConfirmStartStockCheck.propTypes = {
  show: PropTypes.bool,
  showModal: PropTypes.func,
  setStockCheck: PropTypes.func,
};

export default ConfirmStartStockCheck;
