/* eslint-disable */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './PhysicalDischargeModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main componet
const PhysicalDischargeModal = ({
  id,
  status,
  onToggle,
  onConfirm,
  setShowProfile,
  patientProfile,
}) => {
  const navigate = useNavigate();

  const [icdCat, setIcdCat] = useState('');
  const [button, setButton] = useState(true);
  const [icdCode, setIcdCode] = useState('');
  const [password, setPassword] = useState('');
  const [condition, setCondition] = useState('');
  const [causeOfDeath, setCauseOfDeath] = useState('');
  const [causeOfDeathOptions, setCauseOfDeathOptions] = useState([]);
  const [newBornData, setNewBornData] = useState([]);

  const passwordCheck = () => {
    axios
      .post(`/user/auth/check`, { password: password })
      .then((response) => {
        const newborn_deliveries = newBornData.map((d) => {
          return {
            id: d?.id,
            icdCat: d?.icdCat,
            icdCode: d?.icdCode,
            condition: d?.condition,
            causeOfDeath: d?.causeOfDeath,
          };
        });

        const params = { condition, causeOfDeath, icdCode, icdCat, newborn_deliveries };

        axios.post(`/patient/discharge/${id}`, params).then((response) => {
          if (response.data.error) {
            toast.warning('Patient have pending clearance request');
          } else {
            setTimeout(() => {
              setShowProfile(false);
              setShowProfile({ isShowProfile: false, profileId: null });
            }, 2000);
            toast.success('Successfully discharged Patient');

            onToggle(!status);
            onConfirm((prevState) => !prevState);
          }
        });
      })
      .catch((error) => {
        toast.error('Invalid Password');
      });
  };

  const filterOptions = (input) => {
    return causeOfDeathOptions.filter((i) => {
      return (
        i.value.toLowerCase().includes(input.toLowerCase()) ||
        i.label.toLowerCase().includes(input.toLowerCase())
      );
    });
  };

  const loadOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(input));
      }, 1000);
    });
  };

  const handleInputSelectChange = (input, type) => {
    const res = input.replace(/\W/g, '');
    if (type === 'conditions') setCondition(res);
    if (type === 'diagnosis') setCauseOfDeath(input);
    return res;
  };

  const handleInputSelectChangeNewBorn = (input, type, data) => {
    const res = input.replace(/\W/g, '');

    if (type === 'conditions') {
      const newData = newBornData.map((dd) => {
        if (+dd?.id === +data?.id) {
          return {
            ...dd,
            condition: res,
            isDead: res === 'D',
          };
        }
        return dd;
      });

      setNewBornData(newData);
    }

    if (type === 'diagnosis') {
      const newData = newBornData.map((dd) => {
        if (+dd?.id === +data?.id) {
          return {
            ...dd,
            causeOfDeath: input,
          };
        }
        return dd;
      });

      setNewBornData(newData);
    }
  };

  const handleCauseOfDeath = (icddesc, icdcode, icdcat) => {
    setCauseOfDeath(icddesc);
    setIcdCode(icdcode);
    setIcdCat(icdcat);
  };

  const handleCauseOfDeathNewBorn = (icddesc, icdcode, icdcat, data) => {
    const newData = newBornData.map((dd) => {
      if (+dd?.id === +data?.id) {
        return {
          ...dd,
          icdCat: icdcat,
          icdCode: icdcode,
          causeOfDeath: icddesc,
        };
      }
      return dd;
    });

    setNewBornData(newData);
  };

  const getPatientNewBorn = () => {
    axios
      .get(`/patient/newborn_deliveries/${patientProfile?.id}`)
      .then((response) => {
        const newData = response?.data?.data.map((d) => {
          return {
            ...d,
            icdCat: '',
            icdCode: '',
            isDead: false,
            condition: '',
            causeOfDeath: '',
          };
        });

        setNewBornData(newData);
      })
      .catch((error) => {
        toast.error('Error Occured in retrieving New Born data');
      });
  };

  useEffect(() => {
    if (
      (password.length > 3 && condition && condition !== 'D') ||
      (condition === 'D' && causeOfDeath)
    ) {
      setButton(false);
    } else {
      setButton(true);
    }
  }, [password, condition, causeOfDeath]);

  useEffect(() => {
    axios.get('/public/ricd10').then((response) => {
      const options = response.data?.map((data) => ({
        id: data.id,
        value: data.icd10_code,
        label: data.icd10_desc,
        category: data.icd10_cat,
      }));
      setCauseOfDeathOptions(options);
    });
  }, []);

  useEffect(() => {
    if (status) {
      getPatientNewBorn();
    }
  }, [status]);

  useEffect(() => {
    console.log('newBornData', newBornData);
  }, [newBornData]);

  return (
    <Modal
      centered
      size='lg'
      show={status}
      onHide={() => onToggle(!status)}
      className='physicalDischargeModal'
      aria-labelledby='contained-modal-title-vcenter'
    >
      <ModalUI customClass={'enableScroll'}>
        <Modal.Dialog>
          <Modal.Header>
            <h4>PATIENT PHYSICAL DISCHARGE</h4>
            <Button onClick={() => onToggle(!status)} variant='transparent'>
              <CloseSVG />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className='physicalDischargeModal__content'>
              <div className='physicalDischargeModal__header'>
                <h1>Warning!</h1>
              </div>
              <div className='physicalDischargeModal__body'>
                <h6>You are about to officially discharge this patient from the system.</h6>
                {/* <h6>
                  Once discharged, the room will be tagged as <b>For Cleaning</b> and the
                  patient will be removed for your unit&#39;s Patient List.
                </h6> */}
                <h5 className='mt-4 mb-0'>Condition on Discharge</h5>

                <Row>
                  <Col>
                    <Form.Group className='confirmation inline-group'>
                      <Form.Label>{patientProfile?.full_name}</Form.Label>

                      <Select
                        isSearchable={false}
                        styles={selectStyles}
                        options={conditionsOptions}
                        className='select-condition'
                        placeholder='Select condition'
                        onChange={(e) => handleInputSelectChange(e.value, 'conditions')}
                      />
                    </Form.Group>

                    {condition === 'D' && (
                      <Form.Group className='confirmation inline-group'>
                        <Form.Label>Cause of Death</Form.Label>

                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          styles={selectStyles}
                          loadOptions={loadOptions}
                          className='select-condition'
                          placeholder='Search Diagnosis'
                          onChange={(e) => handleCauseOfDeath(e.label, e.value, e.category)}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>

                {newBornData && newBornData.length > 0 && (
                  <Row className='mt-4 newborn-container'>
                    <Col>
                      <h5>New Born</h5>

                      {newBornData.map((data) => {
                        return (
                          <>
                            <Form.Group className='confirmation inline-group'>
                              <Form.Label>{`${data?.fname} ${data?.lname}`}</Form.Label>

                              <Select
                                isSearchable={false}
                                styles={selectStyles}
                                options={conditionsOptions}
                                className='select-condition'
                                placeholder='Select condition'
                                onChange={(e) =>
                                  handleInputSelectChangeNewBorn(e.value, 'conditions', data)
                                }
                              />
                            </Form.Group>

                            {data?.isDead && (
                              <Form.Group className='confirmation inline-group'>
                                <Form.Label>Cause of Death</Form.Label>

                                <AsyncSelect
                                  cacheOptions
                                  defaultOptions
                                  styles={selectStyles}
                                  loadOptions={loadOptions}
                                  className='select-condition'
                                  placeholder='Search Diagnosis'
                                  onChange={(e) =>
                                    handleCauseOfDeathNewBorn(e.label, e.value, e.category, data)
                                  }
                                />
                              </Form.Group>
                            )}
                          </>
                        );
                      })}
                    </Col>
                  </Row>
                )}

                <Form.Group className='confirmation'>
                  <Form.Label>Please enter your Password to proceed.</Form.Label>
                  <Form.Control type='password' onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='light-red'
              disabled={button}
              onClick={() => {
                passwordCheck();
              }}
            >
              DISCHARGE PATIENT
            </Button>
            <Button onClick={() => onToggle(!status)} variant='cancel'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </ModalUI>
    </Modal>
  );
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.45em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

const conditionsOptions = [
  { value: 'RI', label: 'Recovered / Improved' },
  { value: 'H', label: 'Home Against Medical Advice' },
  { value: 'T', label: 'Transferred' },
  { value: 'A', label: 'Absconded' },
  { value: 'U', label: 'Unimproved' },
  { value: 'D', label: 'Died' },
];

export default PhysicalDischargeModal;
