/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Button, Image } from 'react-bootstrap';

import { resizeImage } from 'helpers';
import FormUI from 'components/UI/FormUI';
import { createEmployee, updateEmployee } from 'reducers/employeesReducer';
import { alphabeCharFieldRules } from 'helpers/fieldValidations';

// import assets below
import './index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import cameraLogo from 'assets/images/cameraLogo.png';
import { ReactComponent as ProfileSVG } from 'assets/svg/circle-user.svg';
import {
  getAllDepartments,
  getAllRoles,
  getAllSpecialties,
  getSubSpecialties,
} from 'reducers/thunks/signupThunks';
import { resetSubmitLogs, selectSignup } from '../../../../../../reducers/signupReducer';
import ConditionalRender from '../../../../../../components/ConditionalRender';

const { inValidateInput } = alphabeCharFieldRules;

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.37em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

const initialState = {
  id_no: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  username: '',
  password: '',
  email: '',
  contact_number: '',
  department_id: 0,
  management: '',
  role: '',
  account_status: true,
  employment_type: '',
  default_professional_fee: '',
  positions: '',
  /*gender: '',
  age: '',
  birthday: '',
  street_address: '',
  city: '',
  state_province: '',
  country: '',
  postal_code: '',
  suffix: '',
  certificate: '',
  employee_detail: {
    avatar: '',
    position: '',
    department: '',
    designation: '',
    company_email: '',
    prc_license_number: '',
    date_hired: '',
    salutation: '',
    abbreviation: '',
    eId: '',
  },
  employee_education: {
    year_graduated: '',
    course: '',
    achievement: '',
  },
  employee_emergency_contact: {
    contact_name: '',
    contact_number: '',
    relationship: '',
    address: '',
  },*/
};

/*const GENDER_OPTIONS = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
];

const SALUTATIONS_OPTIONS = [
  { value: 'mr', label: 'Mr.' },
  { value: 'ms', label: 'Ms.' },
  { value: 'dr', label: 'Dr.' },
  { value: 'dra', label: 'Dra.' },
  { value: 'atty', label: 'Atty.' },
  { value: 'engr', label: 'Engr.' },
];*/

const AddEditEmployee = ({ type, title, setActiveScreen, setActiveTab }) => {
  const [payload, setPayload] = useState(initialState);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const signupStore = useSelector(selectSignup);
  const { data, submitLogs, rolesData, departmentsData, subData } = signupStore;

  // Form Options
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [specialties, setSpecialties] = useState({
    parentSpecialty: [],
    subSpecialty: [],
  });
  const getDepartments = () => {
    dispatch(getAllDepartments({ per_page: 100000 }));
  };

  const getRoles = () => {
    dispatch(getAllRoles({ per_page: 100000 }));
  };

  const getSpecialties = () => {
    dispatch(getAllSpecialties({ per_page: 100000 /** , is_sub_position: false */ }));
  };

  const apiSubSpecialties = () => {
    dispatch(getSubSpecialties({ per_page: 100000, is_sub_position: true }));
  };

  const onChangeHandler = (validation, value, path) => {
    const validationAction = {
      text: !!value.match('^[a-zA-Z ]*$'),
      number: !!value.match('^[0-9]*$'),
      email: !!value.match('^[a-zA-Z0-9@.]*$'),
      address: !!value.match('^[a-zA-Z0-9 ,.]*$'),
      all: true,
    };

    if (validationAction[validation]) {
      let newPayload;
      newPayload = {
        ...payload,
        [path]: value,
      };
      if (path === 'management') {
        newPayload = {
          ...payload,
          department_id:
            value === 'Patient Care Management'
              ? 1
              : value === 'Property Management'
              ? 2
              : value === 'Accounting Management'
              ? 3
              : value === 'HR Management'
              ? 4
              : value === 'Hospital Management'
              ? 5
              : undefined,
          [path]: value,
          role: '',
        };
        console.log('management here', newPayload);

        allRoles.forEach((roleData) => {
          if (value === roleData.id) {
            const { acceptedRoles } = roleData;

            const newFilteredRoles = roles.filter((data) => {
              const { name } = data.attributes;
              const result = acceptedRoles.indexOf(name);
              if (result > -1) {
                return data;
              }
            });

            setFilteredRoles(newFilteredRoles);
          }
        });

        setPayload(newPayload);
      }

      setPayload(newPayload);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('payload', payload);

    const form = e.currentTarget;

    if (form.checkValidity() !== false) {
      const addUpdateEmployee =
        type === 'add' ? createEmployee : type === 'edit' ? updateEmployee : null;
      if (!addUpdateEmployee) return;

      try {
        const response = await dispatch(addUpdateEmployee(payload));
        if (response?.error?.message === 'Rejected') {
          toast.error(response.payload.errors[0].detail);

          return;
        }
        toast.success('Successfully added');
      } catch (err) {
        toast.error('Something went wrong');
      }
    }

    setValidated(true);
  };

  const avatarRef = React.useRef(null);

  const uploadAvatar = () => {
    if (avatarRef.current?.files) {
      let reader = new FileReader();
      reader.readAsDataURL(avatarRef.current?.files[0]);
      reader.addEventListener('loadend', () => {
        resizeImage(reader.result, 100, 100).then((result) => {
          let newPayload = {
            ...payload,
            employee_detail: {
              ...payload.employee_detail,
              avatar: result,
            },
          };
          setPayload({ ...newPayload });
        });
      });
    }
  };

  const handleSpecialtyChange = (e, type) => {
    if (type === 'positions') {
      let toArray = Object.values(e);
      let idOnly = toArray.map((i) => parseInt(i.id));
      setPayload((prev) => {
        console.log(
          'e',
          toArray.map((element) => parseInt(element.id)),
        );
        return {
          ...prev,
          positions: idOnly,
          positions_name: e,
          sub_specialty_id: [],
        };
      });
    } else if (type === 'sub_specialty_id') {
      setPayload((prev) => {
        return {
          ...prev,
          sub_specialty_id: e,
        };
      });
    }
  };

  const getSubSpecialtyOptions = () => {
    if (!payload?.specialty) return;

    const newData = specialties?.subSpecialty.filter((data) => {
      let isIncluded = false;

      payload?.specialty.forEach((d) => {
        if (+data?.attributes?.position === +d?.id) {
          isIncluded = true;
        }
      });

      return isIncluded === true;
    });

    return newData?.map((data) => {
      return {
        data: data,
        id: data?.id,
        label: data?.attributes?.position,
        value: data?.attributes?.position,
      };
    });
  };

  useEffect(() => {
    // console.log('payload', payload);
  }, [payload]);

  renderWhenRolesIsChanged(rolesData, setRoles);
  renderWhenDataIsChanged(data, subData, setSpecialties);
  renderWhenDepartmentsIsChanged(departmentsData, setDepartments);
  renderOnFirstRun(getRoles, getDepartments, getSpecialties, apiSubSpecialties);
  renderWhenSubmitLogsisUpdated(submitLogs, setPayload, dispatch);

  return (
    <div className='employees-add-edit'>
      <FormUI>
        <Form
          className='add-edit-employee'
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <h3>{title}</h3>
          <Row>
            <Col>
              <div className='employee-details'>
                <h5 className='my-4'>Employee Details</h5>
                <Row>
                  <Col sm={2}>
                    {/*<Row className='mb-4'>
                      <Col>
                        <Form.Group className='profile-upload inline-label'>
                          <Form.Label htmlFor='profile' className='profile-svg'>
                            {payload?.employee_detail?.avatar ? (
                              <img
                                alt=''
                                src={payload?.employee_detail?.avatar}
                                style={{ width: '10rem', height: '10rem', borderRadius: '50%' }}
                              />
                            ) : (
                              <div className='upload-image'>
                                <Image className='image' src={cameraLogo} alt='Camera Logo' />
                                <ProfileSVG
                                  className='svg'
                                  style={{ width: '10rem', height: '10rem' }}
                                />
                              </div>
                            )}
                          </Form.Label>
                          <Form.Control
                            id='profile'
                            type='file'
                            ref={avatarRef}
                            onChange={uploadAvatar}
                          />
                        </Form.Group>
                      </Col>
                    </Row>*/}
                    <Row className='mb-4'>
                      <Col>
                        <Form.Group className='inline-label mx-auto'>
                          <Form.Label>
                            Employee ID <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Employee ID'
                            value={payload?.id_no}
                            onChange={(e) => {
                              if (e.target.value.length > e.target.maxLength) {
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                              }
                              onChangeHandler('number', e.target.value, 'id_no');
                            }}
                            maxLength={4}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={10}>
                    <Row className='mb-4'>
                      {/*<Col sm={2}>
                        <Form.Group className='inline-label'>
                          <Form.Label>Salutation</Form.Label>
                          <Select
                            options={SALUTATIONS_OPTIONS}
                            value={SALUTATIONS_OPTIONS.find(
                              (opt) => opt.value === payload?.employee_detail?.salutation,
                            )}
                            onChange={(e) =>
                              onChangeHandler('all', e.value, 'employee_detail.salutation')
                            }
                            placeholder='Select'
                            isSearchable={false}
                            styles={selectStyles}
                          />
                        </Form.Group>
                      </Col>*/}
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            First Name <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter First Name'
                            value={payload?.attributes?.first_name}
                            onChange={(e) => onChangeHandler('text', e.target.value, 'first_name')}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>Middle Name</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Middle Name'
                            value={payload?.middle_name}
                            onChange={(e) => onChangeHandler('text', e.target.value, 'middle_name')}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Last Name <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Last Name'
                            value={payload?.last_name}
                            onChange={(e) => onChangeHandler('text', e.target.value, 'last_name')}
                          />
                        </Form.Group>
                      </Col>
                      {/*<Col sm={2}>
                        <Form.Group className='inline-label'>
                          <Form.Label>Suffix</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.suffix}
                            onChange={(e) => onChangeHandler('text', e.target.value, 'suffix')}
                          />
                        </Form.Group>
                      </Col>*/}
                      {/*<Col sm={2}>
                        <Form.Group className='inline-label'>
                          <Form.Label>Abbreviation</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.employee_detail?.abbreviation}
                            onChange={(e) =>
                              onChangeHandler(
                                'text',
                                e.target.value,
                                'employee_detail.abbreviation',
                              )
                            }
                          />
                          <Select
                            options={[{ value: 'md', label: 'MD.' }]}
                            value={payload?.employee_detail?.abbreviation}
                            onChange={(e) =>
                              onChangeHandler('all', e.value, 'employee_detail.abbreviation')
                            }
                            placeholder='Select'
                            isSearchable={false}
                            styles={selectStyles}
                          />
                        </Form.Group>
                      </Col>*/}
                    </Row>
                    <Row className='mb-4'>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            User Name <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Username'
                            value={payload?.username}
                            onChange={(e) => onChangeHandler('text', e.target.value, 'username')}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Password <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Password'
                            value={payload?.password}
                            onChange={(e) => onChangeHandler('all', e.target.value, 'password')}
                          />
                        </Form.Group>
                      </Col>
                      {/*<Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            PRC Licensed Number <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.employee_detail?.prc_license_number}
                            onChange={(e) =>
                              onChangeHandler(
                                'number',
                                e.target.value,
                                'employee_detail.prc_license_number',
                              )
                            }
                          />
                        </Form.Group>
                      </Col>*/}
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Department <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Select
                            name='management'
                            required
                            onChange={(e) => onChangeHandler('all', e.target.value, 'management')}
                            value={payload?.attributes?.department}
                          >
                            <option value='' hidden>
                              Select Department
                            </option>

                            {departments.map((data) => {
                              return (
                                <option key={data.id} value={data.attributes.name}>
                                  {data.attributes.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Role <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Select
                            name='role'
                            required
                            onChange={(e) => onChangeHandler('all', e.target.value, 'role')}
                            value={payload?.attributes?.role}
                          >
                            <option value='' hidden>
                              Select Role
                            </option>

                            {filteredRoles.length > 0 &&
                              filteredRoles.map((data) => {
                                return (
                                  <option key={data.id} value={data.attributes.id}>
                                    {data.attributes.name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                          {/* <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.role}
                            onChange={(e) => onChangeHandler('text', e.target.value, 'role')}
                          />*/}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>Professional Fee (PHP)</Form.Label>
                          <Form.Control
                            type='text'
                            autoComplete='off'
                            name='professionalFee'
                            onChange={(e) =>
                              onChangeHandler('number', e.target.value, 'default_professional_fee')
                            }
                            placeholder='Enter Here'
                            value={payload?.default_professional_fee}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>Specialty</Form.Label>

                          <Select
                            isMulti
                            isSearchable={false}
                            styles={selectStyles}
                            value={payload?.positions_name}
                            placeholder='Select Specialty'
                            onChange={(e) => handleSpecialtyChange(e, 'positions')}
                            options={specialties?.parentSpecialty.map((data) => {
                              return {
                                // data: data,
                                id: data?.id,
                                // label: `${data?.attributes?.position} (${data?.id})`,
                                label: `${data?.attributes?.description} ${data?.id}`,
                                value: data?.id,
                              };
                            })}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>Sub Specialty</Form.Label>

                          <Select
                            isMulti
                            isSearchable={false}
                            styles={selectStyles}
                            value={payload?.sub_specialty_id}
                            placeholder='Select Sub Specialty'
                            onChange={(e) => handleSpecialtyChange(e, 'sub_specialty_id')}
                            options={getSubSpecialtyOptions()}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Employment Type <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Select
                            name='employment_type'
                            required
                            onChange={(e) =>
                              onChangeHandler('all', e.target.value, 'employment_type')
                            }
                            value={payload?.employment_type}
                          >
                            <option value='' hidden>
                              Select Employment Type
                            </option>

                            {employmentType.map(({ id, name, type }) => {
                              return (
                                <option key={id} value={type}>
                                  {name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          {/* <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.role}
                            onChange={(e) => onChangeHandler('text', e.target.value, 'role')}
                          />*/}
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Contact Number <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Contact Number'
                            value={payload?.contact_number}
                            onChange={(e) =>
                              onChangeHandler('number', e.target.value, 'contact_number')
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Company Email Address  <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='email'
                            placeholder='Enter Email Address'
                            value={payload?.email}
                            onChange={(e) => onChangeHandler('email', e.target.value, 'email')}
                          />
                        </Form.Group>
                      </Col>
                      {/*<Col sm={2}>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Date Hired <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='date'
                            value={payload?.employee_detail?.date_hired}
                            onChange={(e) =>
                              onChangeHandler(
                                'all',
                                moment(e.target.value).format('YYYY-MM-DD'),
                                'employee_detail.date_hired',
                              )
                            }
                          />
                        </Form.Group>
                      </Col>*/}
                    </Row>
                  </Col>
                </Row>

                {/*        <h5 className='my-4'>Personal Information</h5>
                <Row className='bottom-line'>
                  <Col>
                    <Row className='mb-4'>
                      <Col sm={2}>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Gender <span className='text-red'>*</span>
                          </Form.Label>
                          <Select
                            required
                            options={GENDER_OPTIONS}
                            defaultValue={GENDER_OPTIONS.find(
                              (opt) => opt.value === payload?.gender,
                            )}
                            onChange={(e) => onChangeHandler('all', e.value, 'gender')}
                            placeholder='Select'
                            isSearchable={false}
                            styles={selectStyles}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={1}>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Age <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.age}
                            onChange={(e) => onChangeHandler('number', e.target.value, 'age')}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Birthday <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='date'
                            value={payload?.birthday}
                            onChange={(e) =>
                              onChangeHandler(
                                'all',
                                moment(e.target.value).format('YYYY-MM-DD'),
                                'birthday',
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col sm={4}>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Street Address <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.street_address}
                            onChange={(e) =>
                              onChangeHandler('address', e.target.value, 'street_address')
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.city}
                            onChange={(e) => onChangeHandler('address', e.target.value, 'city')}
                            // pattern='[A-Za-z]'
                            isInvalid={inValidateInput(payload?.city)}
                          />
                          <Form.Control.Feedback type='invalid'>
                            City should be alphabet letters only.
                          </Form.Control.Feedback>
                           <Select
                            options={[{ value: '', label: '' }]}
                            value={payload?.city}
                            onChange={(e) => onChangeHandler('all',e.value, 'city')}
                            placeholder='Select'
                            isSearchable={false}
                            styles={selectStyles}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>State / Province</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.state_province}
                            onChange={(e) =>
                              onChangeHandler('address', e.target.value, 'state_province')
                            }
                            // pattern='[A-Za-z]'
                            isInvalid={inValidateInput(payload?.state_province)}
                          />
                          <Form.Control.Feedback type='invalid'>
                            State / Province should be alphabet letters only.
                          </Form.Control.Feedback>
                           <Select
                            options={[{ value: '', label: '' }]}
                            value={payload?.state_province}
                            onChange={(e) => onChangeHandler('all',e.value, 'state_province')}
                            placeholder='Select'
                            isSearchable={false}
                            styles={selectStyles}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.country}
                            onChange={(e) => onChangeHandler('address', e.target.value, 'country')}
                            // pattern='[A-Za-z]'
                            isInvalid={inValidateInput(payload?.country)}
                          />
                          <Form.Control.Feedback type='invalid'>
                            Country should be alphabet letters only.
                          </Form.Control.Feedback>
                           <Select
                            options={[{ value: '', label: '' }]}
                            value={payload?.country}
                            onChange={(e) => onChangeHandler('all',e.value, 'country')}
                            placeholder='Select'
                            isSearchable={false}
                            styles={selectStyles}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Postal Code <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.postal_code}
                            onChange={(e) =>
                              onChangeHandler('number', e.target.value, 'postal_code')
                            }
                          />
                           <Select
                            options={[{ value: '', label: '' }]}
                            value={payload?.postal_code}
                            onChange={(e) => onChangeHandler('all',e.value, 'postal_code')}
                            placeholder='Select'
                            isSearchable={false}
                            styles={selectStyles}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>*/}

                {/*        <Row className='bottom-line'>
                  <Col sm={6}>
                    <h5 className='my-4'>Education</h5>
                    <Row className='mb-4'>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Year Graduated <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='date'
                            value={payload?.employee_education.year_graduated}
                            onChange={(e) =>
                              onChangeHandler(
                                'all',
                                moment(e.target.value).format('YYYY-MM-DD'),
                                'employee_education.year_graduated',
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Course Competed <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.employee_education?.course}
                            onChange={(e) =>
                              onChangeHandler('text', e.target.value, 'employee_education.course')
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>
                            Educational Achievement <span className='text-red'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.employee_education?.achievement}
                            onChange={(e) =>
                              onChangeHandler(
                                'text',
                                e.target.value,
                                'employee_education.achievement',
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={6}>
                    <h5 className='my-4'>Certification</h5>
                    <Row className='mb-4'>
                      <Col>
                        <Form.Group className='file-upload inline-label'>
                          <Form.Label className='header-text' htmlFor='upload-certificate'>
                            Upload Certificate
                          </Form.Label>
                          <Form.Control
                            id='upload-certificate'
                            type='file'
                            placeholder='Enter Here'
                            // value={payload?.certificate}
                            onChange={(e) => onChangeHandler('all', e.target.value, 'certificate')}
                          />
                          <Form.Label htmlFor='upload-certificate' className='upload'>
                            <span>Upload</span>
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>*/}

                {/*     <h5 className='my-4'>Emergency Contact</h5>
                <Row>
                  <Col>
                    <Row className='mb-4'>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>Contact Person Name</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.employee_emergency_contact?.contact_name}
                            onChange={(e) =>
                              onChangeHandler(
                                'text',
                                e.target.value,
                                'employee_emergency_contact.contact_name',
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>Contact Number</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.employee_emergency_contact?.contact_number}
                            onChange={(e) =>
                              onChangeHandler(
                                'number',
                                e.target.value,
                                'employee_emergency_contact.contact_number',
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>Relation</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.employee_emergency_contact?.relationship}
                            onChange={(e) =>
                              onChangeHandler(
                                'text',
                                e.target.value,
                                'employee_emergency_contact.relationship',
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col>
                        <Form.Group className='inline-label'>
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={payload?.employee_emergency_contact?.address}
                            onChange={(e) =>
                              onChangeHandler(
                                'address',
                                e.target.value,
                                'employee_emergency_contact.address',
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>*/}
                <Row>
                  <Col className=' mt-4 buttons'>
                    <Button className='save-btn' variant='success' type='submit'>
                      SAVE
                    </Button>
                    <Button
                      onClick={() => {
                        setActiveScreen(0);
                        setActiveTab('Employees List');
                      }}
                      className='cancel-btn'
                      variant='secondary'
                    >
                      CANCEL
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      </FormUI>
    </div>
  );
};

const renderWhenRolesIsChanged = (rolesData, setRoles) => {
  useEffect(() => {
    if (rolesData && rolesData.length > 0) {
      setRoles(rolesData);
    }
  }, [rolesData]);
};

const renderOnFirstRun = (getRoles, getDepartments, getSpecialties, apiSubSpecialties) => {
  useEffect(() => {
    getRoles();
    getDepartments();
    getSpecialties();
    apiSubSpecialties();
  }, []);
};

const renderWhenDataIsChanged = (data, subData, setSpecialties) => {
  useEffect(() => {
    if (data && data.length > 0) {
      // const parentSpecialty = data.filter((data) => {
      //   return data?.attributes?.parent_position_id === '';
      // });

      // const subSpecialty = data.filter((data) => {
      //   return data?.attributes?.parent_position_id !== '';
      // });

      // setSpecialties({
      //   parentSpecialty: parentSpecialty,
      //   subSpecialty: subSpecialty,
      // });

      setSpecialties((prev) => {
        return {
          ...prev,
          parentSpecialty: data,
        };
      });
    }

    if (subData && subData.length > 0) {
      setSpecialties((prev) => {
        return {
          ...prev,
          subSpecialty: subData,
        };
      });
    }
  }, [data, subData]);
};

const renderWhenDepartmentsIsChanged = (departmentsData, setDepartments) => {
  useEffect(() => {
    if (departmentsData && departmentsData.length > 0) {
      setDepartments(departmentsData);
    }
  }, [departmentsData]);
};

const renderWhenSubmitLogsisUpdated = (submitLogs, setPayload, dispatch) => {
  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = submitLogs;

    if (isFailed) {
      toast.error(error || 'Something went wrong, Please refresh the page.');
    }

    if (isSuccess) {
      setPayload({});
      dispatch(resetSubmitLogs());
    }
  }, [submitLogs]);
};

/*const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.45em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};*/

const allRoles = [
  {
    id: 'Patient Care Management',
    acceptedRoles: [
      'Admission',
      'Doctor',
      'Er Nurse',
      'Regular Nurse',
      'Station Head Nurse',
      'Nurse Manager',
      'Lab. Tech',
      'Radiology Tech',
      'Encoder',
      'Cashier',
    ],
  },
  {
    id: 'Property Management',
    acceptedRoles: ['Inventory Manager'],
  },
  {
    id: 'Accounting Management',
    acceptedRoles: ['Accounting'],
  },
  {
    id: 'HR Management',
    acceptedRoles: ['HR'],
  },
  {
    id: 'Hospital Management',
    acceptedRoles: ['Medical Records', 'Maintenance', 'Social Services'],
  },
];

const employmentType = [
  {
    id: 1,
    name: 'Full Time Permanent',
    type: 'full-time-permanent',
  },
  {
    id: 2,
    name: 'Full Time Contractual',
    type: 'full-time-contractual',
  },
  {
    id: 3,
    name: 'Part Time Permanent',
    type: 'part-time-permanent',
  },
  {
    id: 4,
    name: 'Part Time Contractual',
    type: 'part-time-contractual',
  },
];

AddEditEmployee.defaultProps = {
  setActiveTab: () => {},
};

AddEditEmployee.propTypes = {
  setActiveTab: PropTypes.func,
};

export default AddEditEmployee;
