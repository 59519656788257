// import core & vendor packages below
import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import LaboratoryTabs from './components/Tabs';
import AdmissionHeader from './components/PageHeader';

// import assets below
import './style.scss';

// main component
const Laboratory = () => {
  const [activeTab, setActiveTab] = useState('Results');

  return (
    <Layout pageTitle='Laboratory'>
      <AdmissionHeader active={activeTab} />
      <LaboratoryTabs onChange={setActiveTab} />
    </Layout>
  );
};

export default Laboratory;
