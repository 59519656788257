// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from 'components/UI/TabsUI';

// import assets below
import './tabs.scss';
import ProcessBillTab from './ProcessBillTab';
import BillingHistory from './BillingHistory';

// main component
// eslint-disable-next-line no-unused-vars
const BillingTabs = ({ showProfile, setShowProfile }) => {
  const [activeKey, setActiveKey] = useState();
  // eslint-disable-next-line no-unused-vars
  const [activeSubTab, setActiveSubTab] = useState();
  const { setActive } = showProfile;

  useEffect(() => {
    if (setActive && setActive === '') {
      setActiveKey('Process Bill');
    } else {
      setActiveKey(setActive);
    }
  }, [setActive]);

  return (
    <div className='billingPatientProfileTabs'>
      <TabsUI>
        <Tabs
          defaultActiveKey='Process Bill'
          activeKey={activeKey}
          onSelect={(tab) => setActiveKey(tab)}
        >
          <Tab eventKey='Process Bill' title='Process Bill'>
            <ProcessBillTab onChange={setActiveSubTab} />
          </Tab>
          <Tab
            eventKey='Billing History'
            /** Patient Chart as per bob */
            title='Billing History'
          >
            <BillingHistory />
          </Tab>
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default BillingTabs;
