/* eslint-disable no-unused-vars */
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import TabsUI from 'components/UI/TabsUI';
import PatientDisbursed from './PatientDisbursed';
import PatientForDisbursement from './PatientForDisbursement';

import './style.scss';

const Cashier = () => {
  const tabs = [
    {
      title: 'PATIENT FOR DISBURSEMENT',
      component: PatientForDisbursement,
    },
    {
      title: 'PATIENT DISBURSED',
      component: PatientDisbursed,
    },
  ];

  return (
    <div className='cashier-tabs'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component departmentID={'1'} />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default Cashier;
