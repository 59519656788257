/* eslint-disable */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/ReactTable';

// import components below
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './TableShow.scss';

// main component
const ViewDiagnosisListTable = ({ setShowProfile }) => {
  const data = useSelector(selectPatientData);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Room / Bed', accessor: 'room_bed' },
      { Header: 'Age / Sex', accessor: 'age_sex' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      data?.map((dd) => ({
        id: dd?.id,
        name: dd?.full_name,
        room_bed: `${dd?.room} / ${dd?.bed}`,
        age_sex: `${dd?.age}/${dd?.sex}`,
        date_of_birth: dd?.date_of_birth,
        status: (
          <span
            className={`${dd?.patient_status === 'Available' ? 'available' : ''} ${
              dd?.patient_status === 'Busy' ? 'busy' : ''
            }`}
          >
            {dd?.patient_status}
          </span>
        ),
      })),
    [data],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      profile: () => {},
      view: () => {
        setShowProfile((prevState) => ({
          ...prevState,
          viewModal: true,
          profileId: row?.id,
        }));
      },
      edit: () => {},
      delete: () => {},
    };

    action[eventKey]();
  };

  return (
    <div className='view-diagnosis-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasView: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

ViewDiagnosisListTable.defaultProps = {
  setShowProfile: () => {},
};

ViewDiagnosisListTable.propTypes = {
  setShowProfile: PropTypes.func,
};

export default ViewDiagnosisListTable;
