/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup, Table } from 'react-bootstrap';
import axios from 'axios';

// import assets below
import FormUI from 'components/UI/FormUI';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
import TableUI from 'components/UI/TableUI';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatientItem, showPatient, selectPatient } from 'reducers/patientReducer';

// CSS
import './style.scss';

// main component
const LabResultModal = ({ showModal, setShowModal, patientData, setPatientData }) => {
  const dispatch = useDispatch();

  const patientProfile = useSelector(selectPatientItem);

  const [userData, setUserData] = useState({
    patient_name: '',
    doctor: '',
    age_gender: '',
    location: '',
  });

  useEffect(() => {
    dispatch(showPatient(patientData?.id));
  }, [patientData]);

  return (
    <Modal
      size='lg'
      show={showModal.isShowView}
      onHide={() => {
        setShowModal({ ...showModal, isShowView: false });
        setPatientData({ ...patientData, id: '' });

      }
      }
      aria-labelledby='Lab Result'
      className='lab-result-modal'
    >
      <Modal.Body>
        <div className='lab-result'>
          <div className='d-flex header'>
            <p>LABORATORY RESULT {patientData?.id}</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowModal((prevState) => {
                  return {
                    ...prevState,
                    isShowView: false,
                  };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='view-form'>
            <Row>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Patient Name:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Patient Name Here'
                    value={patientData?.name}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Doctor:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Doctor Here'
                    value={patientProfile?.assigned_doctors?.map((item, key) => {
                      return (
                        'Dr. ' + item.user.first_name + ' ' + item?.user?.last_name
                      );
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Age / Gender:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Age / Gender Here'
                    value={patientData?.age + '/' + patientData?.sex}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Location:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Location Here'
                    value={patientData.location ? patientData.location : 'No room assigned'}
                  />
                </Form.Group>
              </Col>
            </Row>

            <TableUI>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Date Requested</th>
                    <th>Lab Test</th>
                    <th>Conducted By:</th>
                    <th>PRC Licensed #:</th>
                    <th>Verified By:</th>
                    <th>Internal/External?</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.laboratory_requests &&
                    patientProfile?.laboratory_requests.length > 0 &&
                    patientProfile?.laboratory_requests.map((list) => {
                      return (
                        <tr key={list?.id}>
                          <td>{moment(list?.createdAt).format('MMMM DD, YYYY hh:mm A')}</td>
                          <td><b>{list?.lab_type}</b></td>
                          <td>{list?.laboratory_result?.conducted_by ? list?.laboratory_result?.conducted_by : <span style={{ color: 'red' }}>No result</span>}</td>
                          <td>{list?.laboratory_result?.licensed_number ? list?.laboratory_result?.licensed_number : <span style={{ color: 'red' }}>No result</span>}</td>
                          <td>{list?.laboratory_result?.signed_by ? list?.laboratory_result?.signed_by : <span style={{ color: 'red' }}>No result</span>}</td>
                          <td>{list?.lab_test}</td>
                          <td>
                            {' '}
                            {list?.laboratory_result?.filename ? (
                              <span className='value'>
                                <a
                                  href={`${process.env.REACT_APP_API_BASE_URL}/files/${list?.laboratory_result?.filename}`}
                                  target='_blank'
                                >
                                  View Result
                                </a>
                              </span>
                            ) : (
                              <span className='value' style={{ color: 'red' }}>
                                No file uploaded
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  {/* <Button
                    className='add-button'
                    variant='primary'
                    onClick={(e) => {
                      // handleSubmit(e);
                      alert('PRINT??');
                    }}
                  >
                    Print
                  </Button> */}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default LabResultModal;
