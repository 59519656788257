/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormControl, Button, Tabs, Tab, Image } from 'react-bootstrap';

import AddRecord from './AddRecord';
import ViewRecord from './ViewRecord';
import TabsUI from 'components/UI/TabsUI';
import AdvancesTable from './AdvancesTable';
import ConditionalRender from 'components/ConditionalRender';
import { TableFiltersPermission, checkStringPermission } from 'helpers/filteredPermissions';
import { showUsers, getACLState, getRoles, getDepartments, getLogs } from 'reducers/aclReducer';

import './index.scss';
import logoImage from 'assets/images/logo2.png';
import { ReactComponent as PlusSVG } from 'assets/svg/circle-plus-blue.svg';

const LeaveContainer = () => {
  const [showScreen, setShowScreen] = useState(1);
  const [requestData, setRequestData] = useState();

  return (
    <>
      {/* Default View */}
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <div className='advances-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ADVANCES LIST</Form.Label>

                <FormControl className='blue-control' placeholder='Search Employee' />
              </Form.Group>

              <Button className='add-record-button' onClick={() => setShowScreen(3)}>
                <PlusSVG />
                Add Record
              </Button>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0 px-0'>
              <AdvancesTable setShowScreen={setShowScreen} setRequestData={setRequestData} />
            </div>
          </div>
        }
      />

      {/* View Record */}
      <ConditionalRender
        condition={showScreen === 2}
        renderIf={<ViewRecord requestData={requestData} setShowScreen={setShowScreen} />}
      />

      {/* Add Record */}
      <ConditionalRender
        condition={showScreen === 3}
        renderIf={<AddRecord setShowScreen={setShowScreen} />}
      />
    </>
  );
};

export default LeaveContainer;
