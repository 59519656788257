/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

import { projectStatus } from 'helpers';
import Table from 'components/ReactTable';
import ConditionalRender from 'components/ConditionalRender';

import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

const state = [
  {
    id: '001',
    project_name: 'Project 1',
    project_manager: 'Juan Dela Cruz',
    assigned: 'Juan Dela Cruz',
    status: 'Ongoing',
    start_date: 'January 5, 2023',
    due_date: 'April 10, 2023',
    description: 'Details Here',
  },
  {
    id: '001',
    project_name: 'Project 2',
    project_manager: 'Juan Dela Cruz',
    assigned: 'Juan Dela Cruz',
    status: 'Completed',
    start_date: 'January 5, 2023',
    due_date: 'April 10, 2023',
    description: 'Details Here',
  },
];

const index = ({ setShowScreen, setRequestData }) => {
  const tableColumns = React.useMemo(
    () => [
      { Header: 'Project Name', accessor: 'project_name' },
      { Header: 'Project Manager', accessor: 'project_manager' },
      { Header: 'Assigned', accessor: 'assigned' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Start Date', accessor: 'start_date' },
      { Header: 'Due Date', accessor: 'due_date' },
      { Header: 'Description', accessor: 'description' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        project_name: dd?.project_name,
        project_manager: dd?.project_manager,
        assigned: dd?.assigned,
        status: projectStatus(dd?.status),
        start_date: dd?.start_date,
        due_date: dd?.due_date,
        description: dd?.description,
      })),
    [state],
  );

  return (
    <div className='leave-request'>
      <div className='header'>
        <h2>Project List</h2>

        <div className='functions'>
          <Button className='upload' onClick={() => setShowScreen(3)}>
            <AddSVG />
            Add Project
          </Button>
        </div>
      </div>

      <dib className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
        />
      </dib>
    </div>
  );
};

export default index;
