/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Button, Modal, Form, Pagination, Table } from 'react-bootstrap';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

import TableUI from 'components/UI/TableUI';
import FormUI from 'components/UI/FormUI';

import './Modal.scss';

const CartModal = ({ modalShow, setModalShow }) => {
  return (
    <Modal
      size='lg'
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      aria-labelledby='Order Cart'
      className='order-cart-modal'
    >
      <Modal.Body>
        <div className='order-cart'>
          <div className='d-flex header'>
            <p>Order Cart</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setModalShow(false);
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='order-cart-form'>
            <Row>
              <Col>
                <Row>
                    <Col>
                        <div className=' mb-3 cart-header'>
                            Your Cart
                        </div>
                    </Col>
                </Row>

                {/* Table */}
                <Row>
                  <Col>
                    <TableUI>
                      <Table responsive className='order-table mt-4'>
                        <thead>
                          <tr>
                            <th>Date/Time</th>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Service Unit</th>
                            <th>Ordering Physician</th>
                            <th>Status</th>
                            <th>Submitted by</th>
                            <th>QTY</th>
                            <th>Unit Price</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </Table>
                    </TableUI>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button className='add-button' variant='primary' type='submit'>
                    Save
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() => {
                      setModalShow(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default CartModal;
