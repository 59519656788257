/* eslint-disable */
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';

// import components below
import ConfirmModal from './ConfirmModal';
import Table from 'components/ReactTable';
import { calculateDayDiff, patientStatus } from 'helpers';
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import './Table.scss';

// main component
const DoctorTable = ({ state, setState, setShowProfile }) => {
  const [showModal, setShowModal] = useState({
    id: '',
    show: false,
    data: null,
  });

  const refreshData = () => {
    axios
      .get('/clearance/patients')
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient ID', accessor: 'patient_id' }, // accessor is the "key" in the data
      { Header: 'MRN / PIN', accessor: 'mrn' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Sex', accessor: 'sex' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Is Cleared?', accessor: 'is_cleared' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        sex: dd?.patient?.sex,
        mrn: dd?.patient?.MRN,
        patient_id: dd?.patientId,
        name: dd?.patient?.full_name,
        is_cleared: dd?.isCleared ? 'Yes' : 'No',
        status: patientStatus(dd?.patient?.patient_status),
        date_of_birth: moment(dd?.patient?.date_of_birth).format('MMMM-DD-YYYY'),
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);

    const action = {
      profile: () => {
        setShowProfile({
          isShowProfile: true,
          profileId: row?.patient.id,
          admissionDays: calculateDayDiff(row?.patient?.admission_date),
        });
      },
      view: () => {},
      edit: () => {},
      approve: () => {
        setShowModal({
          id: id,
          show: true,
          data: row,
        });
      },
    };

    action[eventKey]();
  };

  return (
    <div className='doctors-clearance-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{
          hasProfile: checkStringPermission('Can View Profile in Doctor Clearance Tab'),
          hasApprove: true,
        }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />

      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        setState={setState}
        refreshData={refreshData}
      />
    </div>
  );
};

DoctorTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowProfile: () => {},
};

DoctorTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default DoctorTable;
