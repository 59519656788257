/* eslint-disable prefer-const */
import { useSelector } from 'react-redux';
import { selectAuth } from 'reducers/authReducer';

export const checkStringPermission = (string) => {
  const userData = useSelector(selectAuth);
  const { permissions } = userData;

  let result = false;

  if (!permissions.length > 0) {
    return;
  }

  permissions.forEach((data) => {
    switch (data) {
      case string:
        result = true;
        break;
    }
  });

  return result;
};

export const TableFiltersPermission = (defaultArr, arr) => {
  const userData = useSelector(selectAuth);
  const { permissions } = userData;

  let resultArr = defaultArr;

  if (!permissions.length > 0) {
    return defaultArr;
  }

  arr.forEach((arrData) => {
    const { permission } = arrData;

    permissions.forEach((data) => {
      switch (data) {
        case permission:
          resultArr.push(arrData);
          break;

        default:
          break;
      }
    });
  });

  return resultArr;
};
