import { Route } from 'react-router-dom';

import Acl from 'pages/ACL';
import HRManagement from 'pages/HRManagement';
import ProtectedRoute from 'components/ProtectedRoute';
import HospitalManagement from 'pages/HospitalManagement';
import PropertyManagement from 'pages/PropertyManagement';
import AccountingManagement from 'pages/AccountingManagement';
import Attendance from 'pages/Attendance';
import PatientCareManagement from 'pages/PatientCareManagement';
import EditAccountProfile from 'pages/ACL/components/AccountProfile';
// import ACLPermissions from 'pages/ACLPermissions';

export default [
  <Route
    key='main'
    path='/dashboard/acl'
    element={<ProtectedRoute department={'acl'} component={Acl} />}
  />,
 
  <Route
    key='main'
    path='/dashboard/attendance'
    element={<ProtectedRoute department={'attendance'} component={Attendance} />}
  />,
  <Route
    key='patient care all users'
    path='/dashboard/acl/patient-care-management'
    element={<ProtectedRoute department={'acl'} component={PatientCareManagement} />}
  />,
  <Route
    key='patient care new users'
    path='/dashboard/acl/patient-care-management/new-users'
    element={<ProtectedRoute department={'acl'} component={PatientCareManagement} />}
  />,
  <Route
    key='patient care denied users'
    path='/dashboard/acl/patient-care-management/denied-users'
    element={<ProtectedRoute department={'acl'} component={PatientCareManagement} />}
  />,
  <Route
    key='property management all users'
    path='/dashboard/acl/property-management'
    element={<ProtectedRoute department={'acl'} component={PropertyManagement} />}
  />,
  <Route
    key='property management new users'
    path='/dashboard/acl/property-management/new-users'
    element={<ProtectedRoute department={'acl'} component={PropertyManagement} />}
  />,
  <Route
    key='property management denied users'
    path='/dashboard/acl/property-management/denied-users'
    element={<ProtectedRoute department={'acl'} component={PropertyManagement} />}
  />,
  <Route
    key='accounting management all users'
    path='/dashboard/acl/accounting-management'
    element={<ProtectedRoute department={'acl'} component={AccountingManagement} />}
  />,
  <Route
    key='accounting management new users'
    path='/dashboard/acl/accounting-management/new-users'
    element={<ProtectedRoute department={'acl'} component={AccountingManagement} />}
  />,
  <Route
    key='accounting management denied users'
    path='/dashboard/acl/accounting-management/denied-users'
    element={<ProtectedRoute department={'acl'} component={AccountingManagement} />}
  />,
  <Route
    key='hr management all users'
    path='/dashboard/acl/hr-management'
    element={<ProtectedRoute department={'acl'} component={HRManagement} />}
  />,
  <Route
    key='hr management new users'
    path='/dashboard/acl/hr-management/new-users'
    element={<ProtectedRoute department={'acl'} component={HRManagement} />}
  />,
  <Route
    key='hr management denied users'
    path='/dashboard/acl/hr-management/denied-users'
    element={<ProtectedRoute department={'acl'} component={HRManagement} />}
  />,
  <Route
    key='hospital management all users'
    path='/dashboard/acl/hospital-management'
    element={<ProtectedRoute department={'acl'} component={HospitalManagement} />}
  />,
  <Route
    key='hospital management new users'
    path='/dashboard/acl/hospital-management/new-users'
    element={<ProtectedRoute department={'acl'} component={HospitalManagement} />}
  />,
  <Route
    key='hospital management denied users'
    path='/dashboard/acl/hospital-management/denied-users'
    element={<ProtectedRoute department={'acl'} component={HospitalManagement} />}
  />,
  <Route key='edit profile' path='/dashboard/acl/edit-profile' element={<EditAccountProfile />} />,
];
