// import components below
import Employee from './Employee';
import PropTypes from 'prop-types';

// import assets below
import './index.scss';

// main component
const EmployeesList = ({ setEmpData, setActiveScreen, setActiveTab }) => {
  return (
    <div className='employees-list'>
      <Employee setEmpData={setEmpData} setActiveScreen={setActiveScreen} setActiveTab={setActiveTab} />
    </div>
  );
};

EmployeesList.defaultProps = {
  setActiveTab: () => { },
};

EmployeesList.propTypes = {
  setActiveTab: PropTypes.func,
};

export default EmployeesList;
