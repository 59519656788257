/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, OverlayTrigger, Popover, Button } from 'react-bootstrap';

import TableUI from 'components/UI/TableUI';
import TablePagination from './TablePagination';
import ConditionalRender from 'components/ConditionalRender';
import ConfirmModal from './ConfirmModal';

import './index.scss';
import { ReactComponent as XmarkSVG } from 'assets/svg/circle-xmark-red.svg';
import { ReactComponent as ThreeDotsSVG } from 'assets/svg/three-dots-svgrepo-com.svg';

const samplePhoto = 'https://picsum.photos/100';

const Tables = ({
  type,
  tableData,
  changePage,
  showResultsNumber,
  setShowResultsNumber,
  searchAllTabs,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [dataToDelete, setDataToDelete] = useState();
  const isNotEmpty = Object.keys(tableData).length > 0;

  const meta = tableData?.meta;
  const links = tableData?.links;

  if (!isNotEmpty) {
    return;
  }

  return (
    <>
      <div className='tables-cont'>
        <TableUI>
          <Table responsive>
            <thead>
              <tr>
                <th>Account</th>
                <th>Assigned Management</th>
                <th>User Role</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <ConditionalRender
                condition={isNotEmpty}
                renderIf={tableData.data.map((data) => {
                  return (
                    <tr key={data.id}>
                      <td>
                        <div className='account'>
                          <img src={data?.photo || samplePhoto} alt='account photo' />
                          <p>{data?.attributes?.full_name}</p>
                        </div>
                      </td>
                      <td className={`department-cont ${data?.attributes?.department}`}>
                        <p>{data?.attributes?.department || 'N/A'}</p>
                      </td>
                      <td className={`department-cont ${data?.attributes?.role}`}>
                        <p>{data?.attributes?.role || 'N/A'}</p>
                      </td>
                      <td className={data?.attributes?.account_status ? 'Active' : 'Inactive'}>
                        <p>{data?.attributes?.account_status ? 'Active' : 'Inactive'}</p>
                      </td>
                      <td>
                        <OverlayTrigger
                          trigger='focus'
                          placement='bottom'
                          overlay={
                            <Popover id='popover-positioned-bottom'>
                              <Popover.Body className='d-flex justify-content-between p-0'>
                                <Button
                                  onClick={() => {
                                    setOpenModal(true);
                                    setDataToDelete(data);
                                  }}
                                  className='remove-access'
                                >
                                  <XmarkSVG /> Remove Access
                                </Button>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <Button
                            variant='secondary'
                            className='popoverButton bg-transparent p-0 border-0'
                          >
                            <ThreeDotsSVG style={{ width: '1.5em' }} />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  );
                })}
              />
            </tbody>
          </Table>
          <TablePagination
            meta={meta}
            links={links}
            changePage={changePage}
            type={type}
            showResultsNumber={showResultsNumber}
            setShowResultsNumber={setShowResultsNumber}
          />
        </TableUI>
      </div>

      <ConfirmModal
        searchAllTabs={searchAllTabs}
        openModal={openModal}
        dataToDelete={dataToDelete}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

Tables.defaultProps = {
  type: '',
  tableData: {},
  changePage: () => {},
  showResultsNumber: 10,
  setShowResultsNumber: () => {},
  searchAllTabs: () => {},
};

Tables.propTypes = {
  type: PropTypes.string,
  tableData: PropTypes.object,
  changePage: PropTypes.func,
  showResultsNumber: PropTypes.number,
  setShowResultsNumber: PropTypes.func,
  searchAllTabs: PropTypes.func,
};

export default Tables;
