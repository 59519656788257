// import core & vendor packages below
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './Modal.scss';

// main componet
// eslint-disable-next-line no-unused-vars
const SignUpModal = ({ setOpenModal, openModal }) => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  const redirectToLogin = () => {
    setOpenModal(false);

    setTimeout(() => {
      navigate('/');
    }, [1000]);
  };

  return (
    <div className='signUpModal'>
      {openModal && (
        <ModalUI>
          <Modal.Dialog>
            <Modal.Header>
              <div className='signUpModal__header'></div>
            </Modal.Header>
            <Modal.Body>
              <p>
                Thank you for requesting your account, your account is being approved by the admin.
                Please allow 24-28 hours to have you account approved.
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button className='signUpModal__button' onClick={() => redirectToLogin()}>
                Continue
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </ModalUI>
      )}
    </div>
  );
};

SignUpModal.defaultProps = {
  status: false,
  setOpenModal: () => {},
};

SignUpModal.propTypes = {
  status: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export default SignUpModal;
