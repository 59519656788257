/* eslint-disable */
import _, { forEach } from 'lodash'
import { Button, Row, Dropdown, DropdownButton } from 'react-bootstrap';

import './Filter.scss';
import { ReactComponent as CirclePlusSVG } from 'assets/svg/circle-plus-white.svg';
import { ReactComponent as ImportSVG } from 'assets/svg/file-import.svg';
import { ReactComponent as PencilSVG } from 'assets/svg/pencil-white.svg';
import { updateChartOfAccounts, getChartOfAccounts, showChartOfAccount } from 'reducers/accountingReducer';

import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {useEffect} from 'react';

const ChartOfAccountFilter = (props) => {
    const { 
        setShowModal, 
        setBatchEditMode, 
        setBatchEdit, 
        state, 
        batchEditMode, 
        batchEdit,
        handlePrint,
        handleShowUploadModal,
        setFilter,
    } = props;
    const dispatch = useDispatch();
      
    useEffect(() => {
        setBatchEdit(state);
        dispatch(showChartOfAccount());
    }, [state]);
      
    console.log(state, 'state');
    const handleBatchEdit = useCallback((e) => {
        setBatchEditMode(true);
        setBatchEdit(state);
        return;
    }, [batchEdit, state]);

    const handleCancelBatchEdit = () => {
        setBatchEditMode(false);
        return;
    };

    const isLoading = useSelector((state) => state.accounting.logs.isLoading);
    console.log(isLoading)

    const saveBatchUpdate = useCallback(() => {
        const accountsForUpdate = batchEdit?.filter((batchData) => {
            return !state.some((stateData) => {
                return _.isEqual(batchData, stateData)
            })
        })
        accountsForUpdate.length && accountsForUpdate.forEach((account) => {
            dispatch(updateChartOfAccounts(account));
            console.log(account, 'account');
        });

        if (!isLoading) {
            dispatch(getChartOfAccounts());
        }
        setBatchEditMode(false);
        toast.success('Successfully updated list')
    }, [state, batchEdit, dispatch, isLoading])

    const filter = (type, filter) => {
        setFilter({ type, filter })
    }

    return (
        <div className='chart-of-account-filter'>
            <Row>
                <div className="controls">
                    <div className='filters'>
                        <DropdownButton title='Filters' variant='light'>
                            <Dropdown.Item onClick={() => filter('name', 'name')}>By Name</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Header>By Account Type</Dropdown.Header>
                            <Dropdown.Item onClick={() => filter('account_type', 'Outpatient')}>Outpatient</Dropdown.Item>
                            <Dropdown.Item onClick={() => filter('account_type', 'Emergency')}>Emergency</Dropdown.Item>
                            <Dropdown.Item onClick={() => filter('account_type', 'Admitted')}>Admitted</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Header>By Detail Type</Dropdown.Header>
                            <Dropdown.Item onClick={() => filter('detail_type', 'Outpatient')}>Outpatient</Dropdown.Item>
                            <Dropdown.Item onClick={() => filter('detail_type', 'Emergency')}>Emergency</Dropdown.Item>
                            <Dropdown.Item onClick={() => filter('detail_type', 'Admitted')}>Admitted</Dropdown.Item>
                        </DropdownButton>
                    </div>
                    <div className='buttons'>
                        {!batchEditMode ? (
                            <>
                                <Button className='btn-light-red' onClick={handlePrint}><ImportSVG />  PRINT</Button>
                                <Button className='btn-light-green' onClick={handleBatchEdit}><PencilSVG /> BATCH EDIT</Button>
                                <Button className='btn-light-green' onClick={handleShowUploadModal}>
                                    <ImportSVG /> IMPORT</Button>
                                <Button className='btn-light-blue' onClick={() => setShowModal({ isShowForm: true })}>
                                    <CirclePlusSVG /> ADD NEW</Button>
                            </>
                        ) : (
                            <>
                                <Button variant='secondary' onClick={handleCancelBatchEdit}>CANCEL</Button>
                                <Button className='btn-light-green' onClick={saveBatchUpdate}>SAVE</Button>
                            </>
                        )}
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default ChartOfAccountFilter;
