 /* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getGroupItems, selectInventory } from 'reducers/inventoryReducer';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const ItemDetails = ({ showProfile, setShowProfile }) => {
  const { rowData } = showProfile;
  const inventoryState = useSelector(selectInventory);
  const { inventoryGroupData } = inventoryState;

  const closeModal = () => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        showView: false,
      };
    });
  };

  const handleOpenEditItem = () => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        showView: false,
        editItem: true,
      };
    });
  };

  const handlePriceUpdate = () => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        showView: false,
        priceUpdate: true,
      };
    });
  };

  const getItemGroup = () => {
    if (!rowData || !inventoryGroupData) return '...';

    const groupId = rowData?.attributes?.group_id;

    const groupData = inventoryGroupData.find((dd) => +dd?.id === +groupId);

    return groupData?.attributes?.name;
  };

  runWhenTabIsActive(showProfile);

  return (
    <Modal
      size='lg'
      show={showProfile.showView}
      onHide={closeModal}
      aria-labelledby='ITEM DETAILS'
      className='item-details-modal'
    >
      <Modal.Body>
        <div className='item-details-request'>
          <div className='d-flex header'>
            <p>ITEM DETAILS</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, showView: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='item-details-form'>
            <Row className='mb-5'>
              <Col>
                <div className='item-header'>
                  <h3 className='mb-4'>ITEM INFORMATION</h3>

                  <div className='buttons'>
                    <Button onClick={handleOpenEditItem} className='edit-item'>
                      Edit Item
                    </Button>
                    <Button onClick={handlePriceUpdate} className='price-update'>
                      Price Update
                    </Button>
                  </div>
                </div>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Code :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.code || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Name :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.name || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Generic Name :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.generic_name || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Manufacturer :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.manufacturer || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Unit Price :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={
                      rowData?.attributes?.unit_of_measure === 'piece'
                        ? rowData?.attributes?.piece_price
                        : rowData?.attributes?.piece_per_box
                    }
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Description :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.description || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Group :</Form.Label>
                  <FormControl type='text' className='no-border' value={getItemGroup()} readOnly />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Date Created :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.created_at || '---'}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className='mb-4'>STORE ITEM INFORMATION</h3>
                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Piece Quantity :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.piece_quantity || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Box Quantity :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.box_quantity || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Unit of Measurement :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.unit_of_measure || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Storage Bin :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.storage_bin || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <div className='quantities-header'>
                    <Form.Label className='mb-3 stock-quantities'>
                      Stock Quantities Minimum:
                    </Form.Label>
                    <Form.Label className='mb-3 stock-quantities'>Maximum:</Form.Label>
                  </div>

                  <div className='quantities'>
                    <Form.Group className='mb-3 inline-group'>
                      <FormControl
                        type='text'
                        className='no-border'
                        value={rowData?.attributes?.min_stock || '---'}
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 inline-group'>
                      <FormControl
                        type='text'
                        className='no-border'
                        value={rowData?.attributes?.max_stock || '---'}
                        readOnly
                      />
                    </Form.Group>
                  </div>
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Average Consumption :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.average_consumption || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Estimated Time of Arrival :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.estimated_time_arrival || '---'}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Re-Order Point :</Form.Label>
                  <FormControl
                    type='text'
                    className='no-border'
                    value={rowData?.attributes?.re_order_point || '---'}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const runWhenTabIsActive = (showProfile) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (showProfile?.showView) {
      dispatch(getGroupItems('?q=&per_page=1000'));
    }
  }, [showProfile]);
};

ItemDetails.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

ItemDetails.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default ItemDetails;
