/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
// import core & vendor packages
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RiQrScan2Line } from 'react-icons/ri';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';

// main components
import FormUI from 'components/UI/FormUI';
import QrScannerModal from 'components/QR/qrReader';
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './BasicSearch.scss';
import { ReactComponent as AddGroupSVG } from 'assets/svg/add-group.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const BasicSearch = ({ setState, setShowProfile, showProfile, show, setShow }) => {
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [listOfPatients, setListofPatients] = useState([]);

  useEffect(() => {
    axios
      .get('/patient')
      .then((response) => {
        setListofPatients(response.data.data.filter((data) => data.patient_status !== 'emergency'));
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    const newFilter = listOfPatients.filter((value) => {
      return value.full_name.toLowerCase().includes(searchedWord.toLowerCase())
        || String(value.id) === String(searchedWord)
        || String(value.MRN) === String(searchedWord);
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(listOfPatients);
    }
  };

  return (
    <div className='basic-search-container'>
      <ConditionalRender
        condition={show === true}
        renderIf={
          <QrScannerModal
            setShow={setShow}
            setShowProfile={setShowProfile}
          />
        }
      />

      <div className='basicSearch'>
        <Col className='d-flex align-items-end justify-content-between'>
          <Row>
            <FormUI>
              <h6>Basic Search</h6>
              <InputGroup>
                <InputGroup.Text>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl placeholder='Search via Last Name or PIN...' type='text' id='name' onChange={onChangeHandler} />
              </InputGroup>
            </FormUI>
          </Row>
          <Row>
            <div className='buttonWrapper'>
              <Button
                style={{ marginRight: '10px', borderWidth: '0px' }}
                className={'btn btn-light-blue'}
                onClick={() => setShow(true)}
              // eslint-disable-next-line multiline-comment-style
              // onClick={() => {
              //   handleQrScanner();
              //   setShowProfile((prev) => {
              //     return {
              //       ...prev,
              //       profileId: '76',
              //     };
              //   });
              // }}
              >
                <RiQrScan2Line />
                <span>QR Scanner</span>
              </Button>
              {/* Hide Create temporary profile */}
              {/* <Link
              className='btn btn-dark-blue me-3 px-3'
              to='/dashboard/outpatient-department/create-temporary-profile'
            >
              <AddGroupSVG />
              <span>Create Temporary Profile</span>
            </Link> */}
              <Link
                className='btn btn-light-blue px-3'
                to='/dashboard/outpatient-department/create-new-patient'
              >
                <AddGroupSVG />
                <span>Register New Patient</span>
              </Link>
            </div>
          </Row>
        </Col>
      </div>
    </div>
  );
};

export default BasicSearch;
