// import components below
import PageHeaderUI from 'components/UI/PageHeaderUI';

// import assets below
import './Tabs.scss';

// main component
const AdmissionHeader = (props) => {
  const { active } = props;

  return (
    <div className='doctorHeader'>
      <PageHeaderUI>
        <h4>Doctor Module</h4>
        <p>Dashboard / Doctors / {active}</p>
      </PageHeaderUI>
    </div>
  );
};

export default AdmissionHeader;
