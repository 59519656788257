// Packages
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

// Main Component
function NewStockStepOne({
  onNext,
  onPrevious,
}) {
  return (
    <Fragment>
      <Row>
          <Col>
            <Form.Group className='mb-4'>
              <Form.Label>Stock Check Name</Form.Label>
              <FormControl type='text' className='bg-white' placeholder='Search Here' />
            </Form.Group>
          </Col>
          <Col className='d-flex align-items-end'>
            <Form.Check
              id='lock-item'
              type='checkbox'
              className='mb-4'
              label='Automatically lock all items in Stock Check once Started'
            />
          </Col>
          <Col>
            <Form.Group className='mb-4'>
              <Form.Label>Remarks</Form.Label>
              <FormControl type='text' className='bg-white' placeholder='Search Here' />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className='d-flex justify-content-between action-btns'>
            <Button variant='danger' type='button' onClick={onPrevious}>
              Back to Stock Check list
            </Button>

            <Button variant='success' type='button' onClick={onNext}>
              Next
            </Button>
          </Col>
        </Row>
    </Fragment>
  );
}

NewStockStepOne.defaultProps = {
  onNext: () => {},
  onPrevious: () => {},
};

NewStockStepOne.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
};

export default NewStockStepOne;
