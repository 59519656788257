/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const FileUploadModal = ({ showProfile, setShowProfile }) => {
  const ref = useRef();

  const dispatch = useDispatch();

  const patientProfile = useSelector(selectPatientItem);

  const [labResult, setLabResult] = useState([]);
  const [userData, setUserData] = useState({
    doze: '',
    notes: '',
    patientId: '',
    medication: '',
    route_of_administration: '',
    times_of_administration: '',
  });

  const handleFile = (e) => {
    setLabResult(e.target.files);
  };

  // Submit data here
  const handleSubmit = async () => {
    const formdata = new FormData();

    for (const key of Object.keys(labResult)) {
      formdata.append('FILES', labResult[key]);
    }

    formdata.append('patientId', patientProfile?.id);

    axios
      .post('/patient/file_upload', formdata)
      .then((response) => {
        ref.current.value = '';
        dispatch(showPatient(patientProfile.id));
        toast.success('Successfully uploaded files');
      }, [])
      .catch((err) => {
        err?.response?.data?.errors.map((item) => {
          toast.warning(item.msg + ' ' + item.param);
        });
      });
  };

  useEffect(() => {
    if (patientProfile) {
      setUserData({ ...userData, patientId: patientProfile.id });
    }
  }, [patientProfile]);

  return (
    <Modal
      size='lg'
      show={showProfile.fileUpload}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            fileUpload: false,
          };
        })
      }
      aria-labelledby='Lab Request Modal'
      className='add-lab-request-modal'
    >
      <Modal.Body>
        <div className='add-lab-request'>
          <div className='d-flex header'>
            <p>Upload Files</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowProfile((prevState) => {
                  return { ...prevState, fileUpload: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='lab-request-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>
                    Select Files<span className='asterisk'>*</span>{' '}
                  </Form.Label>
                  <FormControl
                    type='file'
                    name='FILES'
                    className='bg-white'
                    placeholder='Enter Here'
                    onChange={(e) => handleFile(e)}
                    multiple
                    ref={ref}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() =>
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          fileUpload: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

FileUploadModal.defaultProps = {
  showProfile: () => {},
  setShowProfile: () => {},
};

FileUploadModal.propTypes = {
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default FileUploadModal;
