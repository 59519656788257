/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

// import components below
import OrdersTab from './OrdersTab';
import TabsUI from 'components/UI/TabsUI';
import MedicalHistory from './MedicalHistory';
import PatientProfileTab from './PatientProfileTab';
// import VisitInformationTab from './VisitInformationTab';
import DietInformationTab from './DietInformationTab';
import DischargePlanningTab from './DischargePlanningTab';
import PatientChart from './Tabs/PatientChart';
import { useSelector } from 'react-redux';
import { selectPatientItem } from 'reducers/patientReducer';

// import assets below
import './Tabs.scss';

// main component
const PatientProfileTabs = ({ showProfile, setShowProfile }) => {
  const [activeKey, setActiveKey] = useState();

  const { setActive } = showProfile;

  const patientProfile = useSelector(selectPatientItem);

  useEffect(() => {
    if (setActive && setActive === '') {
      setActiveKey('Patient Profile');
    } else {
      setActiveKey(setActive);
    }
  }, [setActive]);

  return (
    <div className='NursingPatientProfileTabs'>
      <TabsUI>
        <Tabs
          activeKey={activeKey}
          defaultActiveKey='Patient Profile'
          onSelect={(tab) => setActiveKey(tab)}
        >
          <Tab eventKey='Patient Profile' title='Patient Profile'>
            <PatientProfileTab />
          </Tab>

          <Tab eventKey='Medical History' title='Medical History'>
            <MedicalHistory showProfile={showProfile} setShowProfile={setShowProfile} />
          </Tab>

          <Tab
            eventKey='Visit Information'
            /** Patient Chart as per bob */
            title='Patient Chart'
          >
            <PatientChart showProfile={showProfile} setShowProfile={setShowProfile} />
          </Tab>

          <Tab eventKey='Orders' title='Orders'>
            <OrdersTab showProfile={showProfile} setShowProfile={setShowProfile} />
          </Tab>

          <Tab eventKey='Diet Information' title='Diet Information'>
            <DietInformationTab />
          </Tab>
          {patientProfile?.patient_status !== 'new' &&
            <Tab eventKey='Discharge Planning' title='Discharge Planning'>
              <DischargePlanningTab setShowProfile={setShowProfile} />
            </Tab>
          }
        </Tabs>
      </TabsUI>
    </div>
  );
};

PatientProfileTabs.defaultProps = {
  permission: '',
  showProfile: {
    setActive: '',
  },
  setShowProfile: () => { },
};

PatientProfileTabs.propTypes = {
  permission: PropTypes.func,
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default PatientProfileTabs;
