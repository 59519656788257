/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Form, FormControl } from 'react-bootstrap';

// import components below
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './index.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const BackUpModal = ({ requestData, openModal, setOpenModal }) => {

  const [data, setData] = useState([]);
  const [showScreen, setShowScreen] = useState(1);
  
  const [userData, setUserData] = useState({
    file_destination: '',
  });

  const handleChange = (key) => (e) => {
    if (e.target.type === 'radio') {
      setUserData({
        ...userData,
        [key]: e.target.value,
      });

      return;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const closeModal = () => {
    setOpenModal({
    });
  };

  useEffect(() => {
    if (requestData) {
      //   console.log('yeata', requestData?.logs_data);
      setData(requestData?.data);
    }
  }, [requestData]);

  return (
    <>
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <Modal
            size='lg'
            centered
            onHide={closeModal}
            show={openModal?.isOpen}
            className='credentials-credits'
          >
            <Modal.Header>
              <h6>Back up to:</h6>
              <Button onClick={closeModal}>
                <CloseSVG />
              </Button>
            </Modal.Header>

            <Modal.Body>
              <div className='main-content'>
                
                <Form.Group className='vertical-align'>
                  <Form.Label>Back up to:</Form.Label>

                  <FormControl placeholder='Enter Here' className='border-seven' />
                </Form.Group>

                <Button 
                  className='browse'
                  onClick={() => setShowScreen(2)}
                >
                  Browse
                </Button>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={closeModal} className='save'>
                Save
              </Button>

              <Button onClick={closeModal} className='cancel'>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        }
      />

      <ConditionalRender
        condition={showScreen === 2}
        renderIf={
          <Modal
            size='lg'
            centered
            onHide={closeModal}
            show={openModal?.isOpen}
            className='credentials-credits'
          >
            <Modal.Header>
              <h6>Select File Destination</h6>

              <Button onClick={closeModal}>
                <CloseSVG />
              </Button>
            </Modal.Header>

            <Modal.Body>
              <div className='main-content'>
                
                <Form.Group className='vertical-align'>
                  <FormControl placeholder='Search...' className='border-seven' />
                 <br />
                  <Form.Check
                    type={'radio'}
                    className='radio'
                    name={'File Destination'}
                    value='Select File Destination 1`'
                    label={`Select File Destination 1`}
                    onChange={handleChange('file_destination')}
                  />
                  <Form.Check
                    type={'radio'}
                    className='radio'
                    name={'File Destination'}
                    value='Select File Destination 2`'
                    label={`Select File Destination 2`}
                    onChange={handleChange('file_destination')}
                  />
                  <Form.Check
                    type={'radio'}
                    className='radio'
                    name={'File Destination'}
                    value='Select File Destination 3`'
                    label={`Select File Destination 3`}
                    onChange={handleChange('file_destination')}
                  />
                  <Form.Check
                    type={'radio'}
                    className='radio'
                    name={'File Destination'}
                    value='Select File Destination 4`'
                    label={`Select File Destination 4`}
                    onChange={handleChange('file_destination')}
                  />
                </Form.Group>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={() => setShowScreen(1)} className='save'>
                Save
              </Button>

              <Button onClick={() => setShowScreen(1)} className='cancel'>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        }
      />
    </>
  );
};

export default BackUpModal;
