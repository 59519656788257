/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import assets below
import './style.scss';
import FormUI from 'components/UI/FormUI';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// main component
const ModalSupply = ({ showProfile, setShowProfile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    patientId: '',
    supply: '',
    quantity: '',
    priority: '',
    notes: '',
  });

  const patientProfile = useSelector(selectPatientItem);

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  useEffect(() => {
    if (patientProfile) {
      setUserData({ ...userData, patientId: patientProfile.id });
    }
  }, [patientProfile]);

  // Submit data here
  const handleSubmit = async () => {
    axios
      .post('/supply', userData)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error.message);
        } else {
          toast.success('Successfully added supply!');
          setUserData({
            patientId: patientProfile.id,
            supply: '',
            quantity: '',
            priority: '',
            notes: '',
          });

          dispatch(showPatient(showProfile.profileId));
        }
      })
      .catch((error) => {
        error.response.data.errors.map((item) => {
          toast.warning(item.msg + ' ' + item.param);
        });
      });
  };

  return (
    <Modal
      size='lg'
      show={showProfile.supply}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            supply: false,
          };
        })
      }
      aria-labelledby='Add Lab Request Modal'
      className='add-lab-request-modal'
    >
      <Modal.Body>
        {/* <div className='add-lab-request'>
                    <div className='d-flex header'>
                        <p>Request for supply</p>

                        <CircleXMark
                            style={{ width: '2em', cursor: 'pointer' }}
                            onClick={() =>
                                setShowProfile((prevState) => {
                                    return { ...prevState, supply: false };
                                })
                            }
                        />
                    </div>
                </div> */}

        <FormUI>
          <Form className='lab-request-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Supplies</Form.Label>
                  <Form.Select onChange={handleChange('supply')} value={userData.supply}>
                    <option hidden>-Select-</option>
                    <option value='Blanket'>Blanket</option>
                    <option value='Pillow'>Pillow</option>
                    <option value='TV'>TV</option>
                    <option value='Aircon'>Aircon</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Quantity</Form.Label>
                  <FormControl
                    type='number'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.quantity}
                    onChange={handleChange('quantity')}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Priority</Form.Label>
                  <Form.Select onChange={handleChange('priority')} value={userData.priority}>
                    <option hidden>-Select-</option>
                    <option value='Highest'>Highest</option>
                    <option value='High'>High</option>
                    <option value='Mid'>Mid</option>
                    <option value='Low'>Low</option>
                    <option value='Lowest'>Lowest</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Notes</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.notes}
                    onChange={handleChange('notes')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() =>
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          supply: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSupply;
