/* eslint-disable */
import React from 'react';
import Table from 'react-bootstrap/Table';

import './PrintContent.scss';

export class PrintContent extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableCol, children } = this.props;
        const { 
            REACT_APP_HOSPITAL_NAME, 
            REACT_APP_HOSPITAL_STREET_ADDRESS,
            REACT_APP_LOGO,
        } = process.env;

        return (
            <div className='print_body' ref={(el) => (this.printRef = el)}>
                <header>
                    <div className="header_logo">
                        <img src={REACT_APP_LOGO} alt='print_logo' />
                    </div>
                    <div className="header_title">{REACT_APP_HOSPITAL_NAME}</div>
                    <div className="header_subtitle">{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
                </header>
                <Table striped bordered>
                    <thead>
                        <tr>
                            {tableCol.map((col) => (
                                col.Header !== 'Action' && <th key={col.accessor}>{col.Header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {children}
                    </tbody>
                </Table>
            </div>
        );
    }
}