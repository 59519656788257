/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getItems,
  getInventory,
  showInventory,
  getRestockItem,
  getRestockItems,
  denyRequestItem,
  submitRequestItem,
  updateRequestItem,
  deleteRequestItem,
  completeRequestItem,
  getNonMedicineItems,
  getSingleRequestItem,
  submitCreditMemorandum,
  getDepartmentInventory,
  getDepartmentRequestList,
  getSingleRepairMaintenance,
  itemRequestForRepairSubmit,
  itemRequestForRepairUpdate,
  getDepartmentMaintenanceRepairList,
} from './thunks/departmentInventoryThunks';

const initialState = {
  data: [],
  items: [],
  meta: null,
  logs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Request Table
  requestData: [],
  requestItems: [],
  requestMeta: null,
  requestLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For inventory Items
  itemData: [],
  itemIncluded: [],
  itemMeta: [],
  itemLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Request Item Submit
  submitRequestItemLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Single Request Item Data
  singleRequestData: [],
  singleRequestItems: [],
  singleRequestMeta: null,
  singleRequestLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Request Item Update Submit
  updateRequestItemLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Request item Delete submit
  deleteRequestItemLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Complete item submit
  completeRequestItemLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Deny Request complete
  denyRequestCompleteLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For restock items
  restockItemsData: [],
  restockItemsItems: [],
  restockItemsMeta: null,
  restockItemsLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For restock item
  restockItemData: [],
  restockItemItems: [],
  restockItemMeta: null,
  restockItemLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Repair Maintenance
  repairData: [],
  repairItems: [],
  repairLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Repair Maintenance
  itemRequestForRepairSubmitLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For single repair maintenance list
  singleRepairData: [],
  singleRepairItems: [],
  singleRepairLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For update repair aaintenance
  updateRepairLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For submit credit memorandum  submitCreditMemorandum
  memorandumLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For Non Medicine Items
  nonMedicineItemData: [],
  nonMedicineItemIncluded: [],
  nonMedicineItemMeta: [],
  nonMedicineItemLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'departmentInventory',
  initialState,
  reducers: {
    resetLogState: (state, action) => {
      state.updateRepairLogs = {
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        error: null,
      };
    },
    resetItemRequestForRepairSubmitLogs: (state, action) => {
      state.itemRequestForRepairSubmitLogs = {
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        error: null,
      };
    },
  },
  extraReducers: {
    [getNonMedicineItems.fulfilled]: (state, action) => {
      state.nonMedicineItemData = action.payload.data;
      state.nonMedicineItemIncluded = action.payload.included;
      state.nonMedicineItemMeta = action.payload.meta;
      state.nonMedicineItemLogs.isSuccess = true;
      state.nonMedicineItemLogs.isLoading = false;
    },
    [getNonMedicineItems.pending]: (state) => {
      state.nonMedicineItemLogs.isLoading = true;
      state.nonMedicineItemLogs.isFailed = false;
      state.nonMedicineItemLogs.isSuccess = false;
    },
    [getNonMedicineItems.rejected]: (state) => {
      state.nonMedicineItemData = [];
      state.nonMedicineItemLogs.isLoading = false;
      state.nonMedicineItemLogs.isFailed = true;
    },
    [submitCreditMemorandum.fulfilled]: (state, action) => {
      state.memorandumLogs.isSuccess = true;
      state.memorandumLogs.isLoading = false;
    },
    [submitCreditMemorandum.pending]: (state) => {
      state.memorandumLogs.isLoading = true;
      state.memorandumLogs.isFailed = false;
      state.memorandumLogs.isSuccess = false;
    },
    [submitCreditMemorandum.rejected]: (state) => {
      state.memorandumLogs.isLoading = false;
      state.memorandumLogs.isFailed = true;
    },
    [itemRequestForRepairUpdate.fulfilled]: (state, action) => {
      state.updateRepairLogs.isSuccess = true;
      state.updateRepairLogs.isLoading = false;
    },
    [itemRequestForRepairUpdate.pending]: (state) => {
      state.updateRepairLogs.isLoading = true;
      state.updateRepairLogs.isFailed = false;
      state.updateRepairLogs.isSuccess = false;
    },
    [itemRequestForRepairUpdate.rejected]: (state) => {
      state.updateRepairLogs.isLoading = false;
      state.updateRepairLogs.isFailed = true;
    },
    [getSingleRepairMaintenance.fulfilled]: (state, action) => {
      state.singleRepairData = action.payload.data;
      state.singleRepairItems = action.payload.included;
      state.singleRepairLogs.isSuccess = true;
      state.singleRepairLogs.isLoading = false;
    },
    [getSingleRepairMaintenance.pending]: (state) => {
      state.singleRepairLogs.isLoading = true;
      state.singleRepairLogs.isFailed = false;
      state.singleRepairLogs.isSuccess = false;
    },
    [getSingleRepairMaintenance.rejected]: (state) => {
      state.singleRepairData = [];
      state.singleRepairLogs.isLoading = false;
      state.singleRepairLogs.isFailed = true;
    },
    [itemRequestForRepairSubmit.fulfilled]: (state, action) => {
      state.itemRequestForRepairSubmitLogs.isSuccess = true;
      state.itemRequestForRepairSubmitLogs.isLoading = false;
    },
    [itemRequestForRepairSubmit.pending]: (state) => {
      state.itemRequestForRepairSubmitLogs.isLoading = true;
      state.itemRequestForRepairSubmitLogs.isFailed = false;
      state.itemRequestForRepairSubmitLogs.isSuccess = false;
    },
    [itemRequestForRepairSubmit.rejected]: (state) => {
      state.itemRequestForRepairSubmitLogs.isLoading = false;
      state.itemRequestForRepairSubmitLogs.isFailed = true;
    },
    [getDepartmentMaintenanceRepairList.fulfilled]: (state, action) => {
      state.repairData = action.payload.data;
      state.repairItems = action.payload.included;
      state.repairLogs.isSuccess = true;
      state.repairLogs.isLoading = false;
    },
    [getDepartmentMaintenanceRepairList.pending]: (state) => {
      state.repairLogs.isLoading = true;
      state.repairLogs.isFailed = false;
      state.repairLogs.isSuccess = false;
    },
    [getDepartmentMaintenanceRepairList.rejected]: (state) => {
      state.repairData = [];
      state.repairLogs.isLoading = false;
      state.repairLogs.isFailed = true;
    },
    [getRestockItem.fulfilled]: (state, action) => {
      state.restockItemData = action.payload.data;
      state.restockItemItems = action.payload.included;
      state.restockItemLogs.isSuccess = true;
      state.restockItemLogs.isLoading = false;
    },
    [getRestockItem.pending]: (state) => {
      state.restockItemLogs.isLoading = true;
      state.restockItemLogs.isFailed = false;
      state.restockItemLogs.isSuccess = false;
    },
    [getRestockItem.rejected]: (state) => {
      state.restockItemData = [];
      state.restockItemLogs.isLoading = false;
      state.restockItemLogs.isFailed = true;
    },
    [getRestockItems.fulfilled]: (state, action) => {
      state.restockItemsData = action.payload.data;
      state.restockItemsItems = action.payload.included;
      state.restockItemsLogs.isSuccess = true;
      state.restockItemsLogs.isLoading = false;
    },
    [getRestockItems.pending]: (state) => {
      state.restockItemsLogs.isLoading = true;
      state.restockItemsLogs.isFailed = false;
      state.restockItemsLogs.isSuccess = false;
    },
    [getRestockItems.rejected]: (state) => {
      state.restockItemsData = [];
      state.restockItemsLogs.isLoading = false;
      state.restockItemsLogs.isFailed = true;
    },
    [getDepartmentInventory.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.items = action.payload.included;
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
    },
    [getDepartmentInventory.pending]: (state) => {
      state.logs.isLoading = true;
      state.logs.isFailed = false;
      state.logs.isSuccess = false;
    },
    [getDepartmentInventory.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
    },
    [getDepartmentRequestList.fulfilled]: (state, action) => {
      state.requestData = action.payload.data;
      state.requestItems = action.payload.included;
      state.requestLogs.isSuccess = true;
      state.requestLogs.isLoading = false;
    },
    [getDepartmentRequestList.pending]: (state) => {
      state.requestLogs.isLoading = true;
      state.requestLogs.isFailed = false;
      state.requestLogs.isSuccess = false;

      state.submitRequestItemLogs = {
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        error: null,
      };

      state.updateRequestItemLogs = {
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        error: null,
      };

      state.completeRequestItemLogs = {
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        error: null,
      };

      state.deleteRequestItemLogs = {
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        error: null,
      };

      state.denyRequestCompleteLogs = {
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        error: null,
      };
    },
    [getDepartmentRequestList.rejected]: (state) => {
      state.requestData = [];
      state.requestLogs.isLoading = false;
      state.requestLogs.isFailed = true;
    },
    [getInventory.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.logs.isLoading = false;
    },
    [getInventory.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getInventory.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = true;
    },
    [showInventory.fulfilled]: (state, action) => {
      state.item = action.payload.data;
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [showInventory.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [showInventory.rejected]: (state, action) => {
      state.item = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action.payload.data;
    },
    [getItems.fulfilled]: (state, action) => {
      state.itemData = action.payload.data;
      state.itemIncluded = action.payload.included;
      state.itemMeta = action.payload.meta;
      state.itemLogs.isSuccess = true;
      state.itemLogs.isLoading = false;
    },
    [getItems.pending]: (state) => {
      state.itemLogs.isLoading = true;
      state.itemLogs.isFailed = false;
      state.itemLogs.isSuccess = false;
    },
    [getItems.rejected]: (state) => {
      state.itemData = [];
      state.itemLogs.isLoading = false;
      state.itemLogs.isFailed = true;
    },
    [submitRequestItem.fulfilled]: (state, action) => {
      state.submitRequestItemLogs.isSuccess = true;
      state.submitRequestItemLogs.isLoading = false;
    },
    [submitRequestItem.pending]: (state) => {
      state.submitRequestItemLogs.isLoading = true;
      state.submitRequestItemLogs.isFailed = false;
      state.submitRequestItemLogs.isSuccess = false;
    },
    [submitRequestItem.rejected]: (state, action) => {
      state.submitRequestItemLogs.isLoading = false;
      state.submitRequestItemLogs.isFailed = true;
      state.submitRequestItemLogs.error = action.payload.errors;
    },
    [getSingleRequestItem.fulfilled]: (state, action) => {
      state.singleRequestData = action.payload.data;
      state.singleRequestItems = action.payload.included;
      state.singleRequestLogs.isSuccess = true;
      state.singleRequestLogs.isLoading = false;
    },
    [getSingleRequestItem.pending]: (state) => {
      state.singleRequestLogs.isLoading = true;
      state.singleRequestLogs.isFailed = false;
      state.singleRequestLogs.isSuccess = false;
    },
    [getSingleRequestItem.rejected]: (state) => {
      state.singleRequestData = [];
      state.singleRequestLogs.isLoading = false;
      state.singleRequestLogs.isFailed = true;
    },
    [updateRequestItem.fulfilled]: (state, action) => {
      state.updateRequestItemLogs.isSuccess = true;
      state.updateRequestItemLogs.isLoading = false;
    },
    [updateRequestItem.pending]: (state) => {
      state.updateRequestItemLogs.isLoading = true;
      state.updateRequestItemLogs.isFailed = false;
      state.updateRequestItemLogs.isSuccess = false;
    },
    [updateRequestItem.rejected]: (state, action) => {
      state.updateRequestItemLogs.isLoading = false;
      state.updateRequestItemLogs.isFailed = true;
      state.updateRequestItemLogs.error = action.payload.errors;
    },
    [deleteRequestItem.fulfilled]: (state, action) => {
      state.deleteRequestItemLogs.isSuccess = true;
      state.deleteRequestItemLogs.isLoading = false;
    },
    [deleteRequestItem.pending]: (state) => {
      state.deleteRequestItemLogs.isLoading = true;
      state.deleteRequestItemLogs.isFailed = false;
      state.deleteRequestItemLogs.isSuccess = false;
    },
    [deleteRequestItem.rejected]: (state, action) => {
      state.deleteRequestItemLogs.isLoading = false;
      state.deleteRequestItemLogs.isFailed = true;
      state.deleteRequestItemLogs.error = action.payload.errors;
    },
    [completeRequestItem.fulfilled]: (state, action) => {
      state.completeRequestItemLogs.isSuccess = true;
      state.completeRequestItemLogs.isLoading = false;
    },
    [completeRequestItem.pending]: (state) => {
      state.completeRequestItemLogs.isLoading = true;
      state.completeRequestItemLogs.isFailed = false;
      state.completeRequestItemLogs.isSuccess = false;
    },
    [completeRequestItem.rejected]: (state, action) => {
      state.completeRequestItemLogs.isLoading = false;
      state.completeRequestItemLogs.isFailed = true;
      state.completeRequestItemLogs.error = action.payload.errors;
    },
    [denyRequestItem.fulfilled]: (state, action) => {
      state.denyRequestCompleteLogs.isSuccess = true;
      state.denyRequestCompleteLogs.isLoading = false;
    },
    [denyRequestItem.pending]: (state) => {
      state.denyRequestCompleteLogs.isLoading = true;
      state.denyRequestCompleteLogs.isFailed = false;
      state.denyRequestCompleteLogs.isSuccess = false;
    },
    [denyRequestItem.rejected]: (state, action) => {
      state.denyRequestCompleteLogs.isLoading = false;
      state.denyRequestCompleteLogs.isFailed = true;
      state.denyRequestCompleteLogs.error = action.payload.errors;
    },
  },
});

export const { actions: departmentInventoryActions, reducer: departmentInventoryReducer } = slice;

export const { resetLogState, resetItemRequestForRepairSubmitLogs } = departmentInventoryActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.departmentInventory || initialState;

export const selectDepartmentInventory = createSelector(
  [selectDomain],
  (departmentInventoryState) => departmentInventoryState,
);
export const selectDepartmentInventoryData = createSelector(
  [selectDomain],
  (departmentInventoryState) => departmentInventoryState.data,
);
export const selectDepartmentInventoryItemsData = createSelector(
  [selectDomain],
  (departmentInventoryState) => departmentInventoryState.items,
);
export const selectDepartmentInventoryMeta = createSelector(
  [selectDomain],
  (departmentInventoryState) => departmentInventoryState.meta,
);
export const selectDepartmentInventoryLogs = createSelector(
  [selectDomain],
  (departmentInventoryState) => departmentInventoryState.logs,
);

/* --------------------------------------------------------------------------------- */

export {
  getItems,
  getInventory,
  showInventory,
  getRestockItem,
  getRestockItems,
  denyRequestItem,
  submitRequestItem,
  updateRequestItem,
  deleteRequestItem,
  completeRequestItem,
  getNonMedicineItems,
  getSingleRequestItem,
  getDepartmentInventory,
  submitCreditMemorandum,
  getDepartmentRequestList,
  getSingleRepairMaintenance,
  itemRequestForRepairSubmit,
  itemRequestForRepairUpdate,
  getDepartmentMaintenanceRepairList,
};
