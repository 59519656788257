/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

// import components below
import Layout from 'components/Layout';
import PageHeader from './components/PageHeader';
import SideBar from 'components/ACLComponents/SideBar';
import OvertimeTrackingContainer from './components/OvertimeTrackingContainer';

// main component
const OvertimeTracking = () => {
  return (
    <Layout pageTitle='Overtime Tracking'>
      <PageHeader />

      <Row className='mt-4'>
        <Col sm={9}>
          <OvertimeTrackingContainer />
        </Col>
        <Col sm={3}>
          <SideBar />
        </Col>
      </Row>
    </Layout>
  );
};

export default OvertimeTracking;
