/* eslint-disable */
// import core & vendor packages below
import PropTypes from 'prop-types';
import React from 'react';

// import components below
import Table from 'components/ReactTable';
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const DischargeSummaryActionTable = ({ setShowModal, data, filter, filteredData }) => {
  // const data = useSelector(selectPatientData);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Room / Bed', accessor: 'room_bed' },
      { Header: 'Age / Sex', accessor: 'age_sex' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const displayedData =
    !filter.patient_name && !filter.bed_category && !filter.status ? data : filteredData;

  const tableData = React.useMemo(
    () =>
      displayedData?.map((dd) => ({
        id: dd?.id,
        name: dd?.full_name,
        room_bed: dd?.bed_room,
        age_sex: `${dd?.age} / ${dd?.sex}`,
        date_of_birth: dd?.date_of_birth,
        status: (
          <span className={`status ${dd?.patient_status === 'admitted' ? 'admitted' : ''}`}>
            {dd.patient_status}
          </span>
        ),
      })),
    [data, filteredData],
  );

  const handleTableAction = (eventKey, id) => {
    const row = data.find((dd) => dd.id === id);
    const action = {
      profile: () => {},
      view: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            viewRecordModal: true,
            profileData: row,
            profileId: row?.id,
          };
        });
      },
      edit: () => {},
      delete: () => {},
    };

    action[eventKey]();
  };

  return (
    <div className='nursing-discharge-summary-action-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasView: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

DischargeSummaryActionTable.defaultProps = {
  setShowModal: () => {},
};

DischargeSummaryActionTable.propTypes = {
  setShowModal: PropTypes.func,
};

export default DischargeSummaryActionTable;
