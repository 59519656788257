// import core & vendor packages below
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, InputGroup, Button } from 'react-bootstrap';

// import components below
import Modal from './SuccessModal';
import Text from 'components/Text';
import FormLoginUI from 'components/UI/FormLoginUI';

// import assets below
import './Form.scss';
import { ReactComponent as EyeSVG } from 'assets/svg/eye.svg';
import { ReactComponent as LockSVG } from 'assets/svg/lock.svg';
import { ReactComponent as UserSVG } from 'assets/svg/user_circle.svg';
import { ReactComponent as CheckmarkSVG } from 'assets/svg/checkmark.svg';

import {
  login,
  selectIsAuthenticated,
  selectToken,
  setIsAuthenticated,
} from 'reducers/authReducer';

// main component
const FormLogin = () => {
  const dispatch = useDispatch();
  const secretPass = 'daB34$afaN7@';

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = useSelector(selectToken);

  const [remember, setRemember] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const submitLogin = () => {
    const data = { username: username, password: password };
    dispatch(login(data));
  };

  const handleRememberChange = () => {
    setRemember((prev) => !prev);
  };

  const handleRemember = () => {
    const values = {
      username: username,
      password: password,
    };

    if (remember) {
      const data = CryptoJS.AES.encrypt(JSON.stringify(values), secretPass).toString();

      localStorage.setItem('remember_manda', data);
    } else {
      localStorage.removeItem('remember_manda');
    }
  };

  const onFormSubmit = (e) => {
    submitLogin();
    e.preventDefault();
    // send state to server with e.g. `window.fetch`
  };

  useEffect(() => {
    if (token && isAuthenticated === false) {
      handleRemember();
      setOpenModal(true);

      setTimeout(() => {
        dispatch(setIsAuthenticated());
      }, 2000);
    }
  }, [{ token, isAuthenticated }]);

  useEffect(() => {
    const rememberMe = localStorage.getItem('remember_manda');

    if (rememberMe) {
      const bytes = CryptoJS.AES.decrypt(rememberMe, secretPass);
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setPassword(data?.password);
      setUsername(data?.username);
    }
  }, []);

  return (
    <div className='formLogin'>
      <Form onSubmit={onFormSubmit}>
        <FormLoginUI>
          <div className='formContent'>
            <div className='form__header'>
              <h5 className='mb-4'>Sign in to start your Session </h5>
            </div>
            <div className='form__body'>
              <Form.Group>
                <Form.Label htmlFor='basic-url'>Username</Form.Label>
                <InputGroup className='mb-3'>
                  <div className='svgContainer'>
                    <UserSVG />
                  </div>
                  <div className='divider'></div>
                  <Form.Control
                    value={username}
                    placeholder='Enter username'
                    onChange={(event) => {
                      setUsername(event.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor='basic-url'>Password</Form.Label>
                <InputGroup className='mb-3 password'>
                  <div className='svgContainer'>
                    <LockSVG />
                  </div>
                  <div className='divider'></div>
                  <Form.Control
                    value={password}
                    className='no-right-border'
                    placeholder='Enter password'
                    type={showPassword ? 'text' : 'password'}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                  <div className='svgContainer'>
                    <Button onClick={() => setShowPassword(!showPassword)}>
                      <EyeSVG />
                    </Button>
                  </div>
                </InputGroup>
              </Form.Group>
              <div className='d-flex justify-content-between'>
                <div className='d-flex flex-nowrap'>
                  <Button className='button--rememberMe' onClick={handleRememberChange}>
                    {remember && <CheckmarkSVG />}
                  </Button>
                  <Text color='#00A8FF' fontSize={15}>
                    Remember me
                  </Text>
                </div>
                <Link to='/forgot-password'>Forgot password?</Link>
              </div>
            </div>
            <div className='form__footer'>
              <Button type='submit'>Login</Button>
              <div className='d-flex justify-content-center align-items-center'>
                <Text color='#2E2E2E' fontSize={15}>
                  No account?&nbsp;
                </Text>
                <Link to='/sign-up'>Request for Account</Link>
              </div>
            </div>
          </div>
        </FormLoginUI>
      </Form>
      {openModal && <Modal />}
    </div>
  );
};

export default FormLogin;
