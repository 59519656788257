/* eslint-disable react/jsx-indent-props */
import { FormControl, InputGroup, Table } from 'react-bootstrap';

import TableUI from 'components/UI/TableUI';

import './MortalityDeathTable.scss';

const MortalityDeathTable = (props) => {
    const { results } = props;

    return (<div className='mt-3 mortality'>
        <TableUI>
            <Table striped hover style={{ width: '80%' }}>
                <thead>
                    <tr>
                        <th>Mortality/Deaths (Underlying cause of death) (DO NOT INCLUDE CARDIO RESPIRATORY ARREST)</th>
                        <th>Number</th>
                        <th>ICD-10 Code (Tabular)</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((item, index) => (
                        <tr key={index + 1}>
                            <td>{index + 1}. {item.morbidity}</td>
                            <td><InputGroup className='mb-1 mt-1'>
                                <FormControl
                                    placeholder='Enter Here'
                                    disabled
                                    value={item.number}
                                />
                            </InputGroup></td>
                            <td><InputGroup className='mb-1 mt-1'>
                                <FormControl
                                    placeholder='Enter Here'
                                    disabled
                                    value={item.icd_code}
                                />
                            </InputGroup></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </TableUI>
    </div>
    );
};

export default MortalityDeathTable;
