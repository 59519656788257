import { Fragment, useEffect, useState } from 'react';

import ReactHelmet from '../ReactHelmet';
import LoadingScreen from '../LoadingScreen';
import Content from '../Layout/components/Content';

// import assets below
import './style.scss';

const LayoutReport = (props) => {
    const { pageTitle, children } = props;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setTimeout(() => setLoading(false), 600);
        }

        return () => {
            mounted = false;
            setLoading(true);
        };
    }, []);

    return (
        <Fragment>
            <ReactHelmet title={pageTitle} />
            {loading && <LoadingScreen />}
            {!loading && (
                <div className='layout'>
                    <div className='layout__body'>
                        <Content>{children}</Content>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default LayoutReport;
