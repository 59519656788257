/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// components
import FormUI from 'components/UI/FormUI';
import { selectAuth } from 'reducers/authReducer';
import { updateProfile } from 'reducers/thunks/authThunks';
import { selectprofessionalFee } from 'reducers/professionalFeeReducer';

// import assets below
import './DoctorsFee.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const DoctorsFee = ({ showProfile, setShowProfile }) => {
  const dispatch = useDispatch();
  const userState = useSelector(selectAuth);
  const professionalFeeState = useSelector(selectprofessionalFee);

  const { userInfo, updateProfileLogs } = userState;

  const [userData, setUserData] = useState({
    id: '',
    professional_fee: '',
    is_negotiable: '',
  });
  const [formChanged, setFormChanged] = useState(false);

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
    setFormChanged(true);
  };

  const closeModal = () => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        showDoctorFee: false,
        data: null,
      };
    });
  };

  const handleUpdateFee = async () => {
    if (!formChanged) {
      return;
    }
    const params = {
      id: userData?.id,
      payload: {
        default_professional_fee: userData?.professional_fee,
        patient_default_negotiable: userData?.is_negotiable === 'yes',
      },
    };

    await dispatch(updateProfile(params));
    toast.success('Successfully updated.');
    setFormChanged(false);
  };

  useEffect(() => {
    if (userInfo) {
      setUserData((prev) => {
        return {
          ...prev,
          id: userInfo?.id,
          professional_fee: userInfo?.default_professional_fee,
          is_negotiable:
            userInfo?.patient_default_negotiable === 1 ||
            userInfo?.patient_default_negotiable === true
              ? 'yes'
              : 'no',
        };
      });
    }
  }, [userInfo]);

  return (
    <Modal
      show={showProfile.showDoctorFee}
      size={'lg'}
      onHide={() => {
        closeModal();
      }}
      aria-labelledby='Doctor Professional Fee'
      className='professional-fee-modal'
    >
      <Modal.Body>
        <div className='professional-fee-header'>
          <div className='d-flex header'>
            <p>Your Professional Fee</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Default Professional fee</Form.Label>
                  <FormControl
                    type='number'
                    required={true}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.professional_fee}
                    onChange={handleChange('professional_fee')}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Is Negotiable?</Form.Label>
                  <Form.Select
                    value={userData.is_negotiable}
                    onChange={handleChange('is_negotiable')}
                  >
                    <option value='' hidden>
                      Select option
                    </option>
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    disabled={updateProfileLogs?.isLoading === true}
                    variant='primary'
                    className='add-button'
                    onClick={handleUpdateFee}
                  >
                    Update
                  </Button>
                  <Button className='cancel-button' vvariant='secondary' onClick={closeModal}>
                    Close
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

DoctorsFee.defaultProps = {
  showProfile: () => {},
  setShowProfile: () => {},
};

DoctorsFee.propTypes = {
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default DoctorsFee;
