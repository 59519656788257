/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from 'components/UI/TabsUI';
import IncomeStatement from './components/IncomeStatement';
import CashFlowStatement from './components/CashFlowStatement';
import AddFinancialStatement from './components/AddFinancialStatement';

// CSS
import './index.scss';

// main component
const FinancialStatement = () => {
  const tabs = [
    {
      title: 'Add Financial Statement',
      component: AddFinancialStatement,
    },
    {
      title: 'Income Statement',
      component: IncomeStatement,
    },
    {
      title: 'Cash Flow Statement',
      component: CashFlowStatement,
    },
  ];

  return (
    <div className='financial-statement'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default FinancialStatement;
