import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getChartOfAccounts,
  createChartOfAccounts,
  showChartOfAccount,
  updateChartOfAccounts,
  getCashFlow,
  searchReportIncomeStatement,
  getFinancialStatement,
} from './thunks/accountingThunks';

const initialState = {
  date: {
    financialStatementDate: '',
    incomeStatementDate: '',
    cashFlowStatementDate: '',
  },
  data: [],
  item: null,
  logs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  financialStatementData: [],
  cashFlowData: [[]],
  reportIncomeStatementData: [],
};

const slice = createSlice({
  name: 'accounting',
  initialState,
  reducers: {
    getFinancialStatementDate:  (state, action) => {
      state.date.financialStatementDate = action.payload;
    },
    getIncomeStatementDate:  (state, action) => {
      state.date.incomeStatementDate = action.payload;
    },
    getCashFlowStatementDate:  (state, action) => {
      state.date.cashFlowStatementDate = action.payload;
    },
  },
  extraReducers: {
    [getChartOfAccounts.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [getChartOfAccounts.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getChartOfAccounts.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = true;
    },
    [showChartOfAccount.fulfilled]: (state, action) => {
      state.item = action.payload.data;
      state.isLoading = false;
    },
    [showChartOfAccount.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [showChartOfAccount.rejected]: (state) => {
      state.item = [];
      state.logs.isLoading = true;
    },
    [createChartOfAccounts.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [createChartOfAccounts.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [createChartOfAccounts.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = true;
    },
    [updateChartOfAccounts.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [updateChartOfAccounts.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [updateChartOfAccounts.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = true;
    },
    [getCashFlow.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getCashFlow.fulfilled]: (state, action) => {
      state.logs.isLoading = false;
      state.cashFlowData = action.payload.data;
    },
    [getCashFlow.rejected]: (state, action) => {
      state.logs.isLoading = false;
      console.log = action.payload;
    },
    [searchReportIncomeStatement.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [searchReportIncomeStatement.fulfilled]: (state, action) => {
      state.logs.isLoading = false;
      state.reportIncomeStatementData = action.payload.data;
    },
    [searchReportIncomeStatement.rejected]: (state, action) => {
      state.logs.isLoading = false;
      console.log = action.payload;
    },
    [getFinancialStatement.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getFinancialStatement.fulfilled]: (state, action) => {
      state.logs.isLoading = false;
      state.financialStatementData = action.payload.data.list;
    },
    [getFinancialStatement.rejected]: (state) => {
      state.logs.isLoading = false;
    },
  },
});

export const { actions: accountActions, reducer: accountingReducer } = slice;

const selectDomain = (state) => state.accounting || initialState;

export const selectChartOfAccounts = createSelector(
  [selectDomain],
  (accountingState) => accountingState.data,
);

export const selectCashFlow = createSelector(
  [selectDomain],
  (accountingState) => accountingState.cashFlowData,
);
export const selectLoading = createSelector(
  [selectDomain],
  (accountingState) => accountingState.logs.isLoading,
);

export const selectChartOfAccountItem = createSelector(
  [selectDomain],
  (accountingState) => accountingState.item,
);
export const selectReportIncomeStatement = createSelector(
  [selectDomain],
  (accountingState) => accountingState.reportIncomeStatementData,
);

export const selectFinancialStatementDate = createSelector(
  [selectDomain],
  (accountingState) => accountingState.date.financialStatementDate,
);

export const selectIncomeStatementDate = createSelector(
  [selectDomain],
  (accountingState) => accountingState.date.incomeStatementDate,
);

export const selectCashFlowStatementDate = createSelector(
  [selectDomain],
  (accountingState) => accountingState.date.cashFlowStatementDate,
);

export const selectFinancialStatementData = createSelector(
  [selectDomain],
  (accountingState) => accountingState.financialStatementData,
);

export {
  getChartOfAccounts,
  showChartOfAccount,
  createChartOfAccounts,
  updateChartOfAccounts,
  getCashFlow,
  searchReportIncomeStatement,
};

export const { 
  getFinancialStatementDate, 
  getIncomeStatementDate, 
  getCashFlowStatementDate,
} = slice.actions;