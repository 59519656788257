// import components below
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const CreateInpatientVisitHeader = () => {
  return (
    <div className='CreateInpatientVisitHeader'>
      <PageHeaderUI>
        <h4>Admission Module</h4>
        <p>Dashboard / Admission / Create Inpatient Visit</p>
      </PageHeaderUI>
    </div>
  );
};

export default CreateInpatientVisitHeader;
