/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// import components
import FormUI from 'components/UI/FormUI';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const ModalAddRecord = ({ showModal, setShowModal }) => {
  const [userData, setUserData] = useState({
    patient_name: '',
    bed_number: '',
    room: '',
    general_hospital: '',
    teaching_hospital: '',
    referral_hospital: '',
    obstetrics_gynaecology: '',
    pediatrics: '',
    discharge_date: '',
    occupancy_rate_percent: '',
    average_length_of_stay: '',
    bed_turnover: '',
  });

  const handleChange = (key) => (e) => {
    if (e.target.type === 'radio') {
      setUserData({
        ...userData,
        [key]: e.target.value,
      });

      return;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  // Submit data here
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      size='lg'
      show={showModal.addModal}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            addModal: false,
          };
        })
      }
      aria-labelledby='Add Diagnosis'
      className='add-record-modal'
    >
      <Modal.Body>
        <div className='add-record-request'>
          <div className='d-flex header'>
            <p>DISCHARGE SUMMARY</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowModal((prevState) => {
                  return { ...prevState, addModal: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Patient Name</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.patient_name}
                    onChange={handleChange('patient_name')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Bed Number</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.bed_number}
                    onChange={handleChange('bed_number')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Room</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.room}
                    onChange={handleChange('room')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Service Provided</Form.Label>
                </Form.Group>

                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>General Hospital</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.general_hospital}
                    onChange={handleChange('general_hospital')}
                  />
                </Form.Group>

                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Teaching Hospital</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.teaching_hospital}
                    onChange={handleChange('teaching_hospital')}
                  />
                </Form.Group>

                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Referral Hospital</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.referral_hospital}
                    onChange={handleChange('referral_hospital')}
                  />
                </Form.Group>

                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Obstetrics and Gynaecology</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.obstetrics_gynaecology}
                    onChange={handleChange('obstetrics_gynaecology')}
                  />
                </Form.Group>

                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Pediatrics</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.pediatrics}
                    onChange={handleChange('pediatrics')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Discharge Date</Form.Label>
                  <FormControl
                    type='date'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.discharge_date}
                    onChange={handleChange('discharge_date')}
                  />
                </Form.Group>

                <Form.Group className='mb-4'>
                  <Form.Label>Occupancy Rate Percent</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.occupancy_rate_percent}
                    onChange={handleChange('occupancy_rate_percent')}
                  />
                </Form.Group>

                <Form.Group className='mb-4'>
                  <Form.Label>Average Length of Stay</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.average_length_of_stay}
                    onChange={handleChange('average_length_of_stay')}
                  />
                </Form.Group>

                <Form.Group className='mb-4'>
                  <Form.Label>Bed Turnover</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.bed_turnover}
                    onChange={handleChange('bed_turnover')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    type='submit'
                    onClick={(e) => {
                      handleSubmit(e);
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          addModal: false,
                          showView: true,
                        };
                      });
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() =>
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          addModal: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ModalAddRecord.defaultProps = {
  showModal: {},
  setShowModal: () => {},
};

ModalAddRecord.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default ModalAddRecord;
