const regex = {
    allIntegers: /^[0-9\b]+$/,
    allAplhabet: /^[A-Za-z\s]+$/,
};

export const contactNoRules = {
    length: 11,
    inValidateInput: (input) => input.length !== 0 && (!regex.allIntegers.test(input) || input.length < 11),
    showValidationMsg: (field) => `${field} should be numeric and has 11 characters.`,
};

export const integerCharFieldRules = {
    inValidateInput: (input) => input.length !== 0 && !regex.allIntegers.test(input),
    showValidationMsg: (field) => `${field} should be numeric.`,
};

export const alphabeCharFieldRules = {
    inValidateInput: (input) => input.length !== 0 && !regex.allAplhabet.test(input),
    showValidationMsg: (field) => `${field} should be alphabet letters only.`,
};