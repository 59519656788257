/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
import constant from 'constant';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import {
  showReport,
  showReportsACL,
  getReportCensus,
  getReportGeneral,
  getReportExpenses,
  getReportHospital,
  getReportRevenues,
  getReportStaffing,
} from 'reducers/reportReducer';

import ReportUIList from './ReportUIList';

import './ReportList.scss';
import { ReactComponent as PrintSVG } from 'assets/svg/print.svg';

const ReportList = () => {
  const dispatch = useDispatch();
  const [year, setYear] = useState(new Date().getFullYear());

  const print = () => {
    window.print();
  };

  /*  const handleViewTransactionModal = () => {
    setIsViewTransactionModalVisible(true);
  }; */

  const selectYear = (value) => {
    setYear(value);
    report(value);
  };

  const report = (value) => {
    dispatch(showReport({ type: 'general', year: value }));
    dispatch(showReport({ type: 'hospital', year: value }));
    dispatch(showReportsACL({ year: value }));
    dispatch(showReport({ type: 'revenues', year: value }));
    dispatch(showReport({ type: 'expenses', year: value }));
    dispatch(showReport({ type: 'census', year: value }));
  };

  useEffect(() => {
    report(year);
  }, []);

  const list = [
    {
      button: { name: 'Add Report', type: constant.GENERALINFORMATION },
      title: 'GENERAL INFORMATION',
      report: useSelector(getReportGeneral),
    },
    {
      button: { name: 'Add Report', type: constant.HOSPITALOPERATION },
      title: 'HOSPITAL OPERATIONS',
      report: useSelector(getReportHospital),
    },
    {
      button: { name: 'Add Report', type: constant.STAFFINGPATTERNS },
      title: 'STAFFING PATTERN',
      report: useSelector(getReportStaffing),
    },
    {
      button: { name: 'Add Report', type: constant.EXPENSES },
      title: 'EXPENSES',
      report: useSelector(getReportExpenses),
    },
    {
      button: { name: 'Add Report', type: constant.REVENUES },
      title: 'REVENUES',
      report: useSelector(getReportRevenues),
    },
    {
      button: { name: 'Add Report', type: constant.CENSUS },
      title: 'HOSPITAL DAILY CENSUS REPORT',
      report: useSelector(getReportCensus),
    },
  ];

  return (
    <div className='reports'>
      <Row className='filterUI'>
        <Col md='1'>
          <span>ALL REPORTS</span>
        </Col>
        <Col md='3'>
          <Form.Select onChange={(e) => selectYear(e.target.value)} defaultValue={year}>
            <option value=''>- Select -</option>
            <option value='2010'>2010</option>
            <option value='2011'>2011</option>
            <option value='2012'>2012</option>
            <option value='2013'>2013</option>
            <option value='2014'>2014</option>
            <option value='2015'>2015</option>
            <option value='2016'>2016</option>
            <option value='2017'>2017</option>
            <option value='2018'>2018</option>
            <option value='2019'>2019</option>
            <option value='2020'>2020</option>
            <option value='2021'>2021</option>
            <option value='2022'>2022</option>
            <option value='2023'>2023</option>
          </Form.Select>
        </Col>
        <Col className='d-flex flex-row-reverse'>
          {/* <Button onClick={handleViewTransactionModal}>View Transactions</Button> */}
          <Button className='me-3 btn-light-red' variant='filter' onClick={() => print()}>
            <PrintSVG /> PRINT REPORTS
          </Button>
        </Col>
      </Row>
      <Row className='reports-type mt-3'>
        <Row className='header'>
          <Col className='type'>
            <span>Reports Type</span>
          </Col>
          <Col className='action col-2'>
            <span>Action</span>
          </Col>
        </Row>
        {list.map((item) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <ReportUIList button={item.button} data={item.report} title={item.title} year={year} />
          );
        })}
      </Row>
    </div>
  );
};

export default ReportList;
