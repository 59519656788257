/* eslint-disable */
// import core & vendor packages below
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

// Get Generic Credentials List
export const getGenericCredentialList = createAsyncThunk(
  'hr/getGenericCredentialList',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: filter,
      url: `credential_management/generic_credential`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Add Generic Credential Item
export const addGenericCredentialItems = createAsyncThunk(
  'hr/addGenericCredentialItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `credential_management/generic_credential`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// show Generic Credential Item
export const showGenericCredentialItems = createAsyncThunk(
  'hr/addGenericCredentialItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `credential_management/generic_credential/${params}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Update GenericCredential Item
export const updateGenericCredential = createAsyncThunk(
  'hr/updateGenericCredential',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'patch',
      url: `credential_management/generic_credential/${params?.id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params?.payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Get Window Credential List
export const getWindowCredentialList = createAsyncThunk(
  'hr/getWindowCredentialList',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: filter,
      url: `credential_management/window_credential`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Add Window Credential Item
export const addWindowCredentialItems = createAsyncThunk(
  'hr/addWindowCredentialItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `credential_management/window_credential`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);



