/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getPatients,
  getPatient,
  showPatient,
  createPatientDiet,
  getPatientDiets,
  createPatientMeal,
  getPatientMeals,
  getCurrentDiet,
  updatePatientDiet,
  updatePatientMeal,
  deletePatientMeal,
  deletePatientDiet,
} from './thunks/patientThunks';
import _ from 'lodash';

const initialState = {
  data: [],
  item: null,
  diets: {
    data: [],
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  meals: {
    data: [],
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  logs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  itemLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  createdDiet: {
    data: [],
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  createdMeal: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  updatedDiet: {
    data: [],
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  updatedMeal: {
    data: [],
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  currentDiet: {
    data: [],
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  deletedDiet: {
    data: [],
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  deletedMeal: {
    data: [],
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatientState: (state, action) => {
      state.item = action.payload;
    },
  },
  extraReducers: {
    [getPatients.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [getPatients.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getPatients.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = true;
    },
    [getPatient.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [getPatient.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getPatient.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = true;
    },
    [showPatient.fulfilled]: (state, action) => {
      state.item = action.payload.data;
      state.itemLogs.isLoading = false;
      state.itemLogs.isSuccess = true;
    },
    [showPatient.pending]: (state) => {
      state.itemLogs.isLoading = true;
      state.itemLogs.isSuccess = false;
      state.itemLogs.isFailed = false;
      state.itemLogs.error = '';
    },
    [showPatient.rejected]: (state, action) => {
      state.item = [];
      state.itemLogs.isLoading = false;
      state.itemLogs.isFailed = true;
      state.itemLogs.isSuccess = false;
      state.itemLogs.error = action.payload.data;
    },
    [createPatientDiet.fulfilled]: (state, action) => {
      state.createdDiet.data = action.payload.data;
      state.createdDiet.isLoading = false;
      state.createdDiet.isSuccess = true;
    },
    [createPatientDiet.pending]: (state) => {
      state.createdDiet.isLoading = true;
      state.createdDiet.isSuccess = false;
      state.createdDiet.isFailed = false;
      state.createdDiet.error = '';
    },
    [createPatientDiet.rejected]: (state, action) => {
      state.createdDiet.isLoading = false;
      state.createdDiet.isFailed = true;
      state.createdDiet.isSuccess = false;
      state.createdDiet.error = action?.payload?.errors;
    },
    [getPatientDiets.fulfilled]: (state, action) => {
      state.diets.data = action.payload.data;
      state.diets.isLoading = false;
      state.diets.isSuccess = true;
    },
    [getPatientDiets.pending]: (state) => {
      state.diets.isLoading = true;
      state.diets.isSuccess = false;
      state.diets.isFailed = false;
      state.diets.error = '';
    },
    [getPatientDiets.rejected]: (state, action) => {
      state.diets.isLoading = false;
      state.diets.isFailed = true;
      state.diets.isSuccess = false;
      state.diets.error = action?.payload?.errors;
    },
    [getPatientMeals.fulfilled]: (state, action) => {
      state.meals.data = action.payload.data;
      state.meals.isLoading = false;
      state.meals.isSuccess = true;
    },
    [getPatientMeals.pending]: (state) => {
      state.meals.isLoading = true;
      state.meals.isSuccess = false;
      state.meals.isFailed = false;
      state.meals.error = '';
    },
    [getPatientMeals.rejected]: (state, action) => {
      state.meals.isLoading = false;
      state.meals.isFailed = true;
      state.meals.isSuccess = false;
      state.meals.error = action?.payload?.errors;
    },
    [createPatientMeal.fulfilled]: (state, action) => {
      state.createdMeal.isLoading = false;
      state.createdMeal.isSuccess = true;
    },
    [createPatientMeal.pending]: (state) => {
      state.createdMeal.isLoading = true;
      state.createdMeal.isSuccess = false;
      state.createdMeal.isFailed = false;
      state.createdMeal.error = '';
    },
    [createPatientMeal.rejected]: (state, action) => {
      state.createdMeal.isLoading = false;
      state.createdMeal.isFailed = true;
      state.createdMeal.isSuccess = false;
      state.createdMeal.error = action?.payload?.errors;
    },
    [updatePatientDiet.fulfilled]: (state, action) => {
      state.updatedDiet.data = action.payload.data;
      state.updatedDiet.isLoading = false;
      state.updatedDiet.isSuccess = true;
    },
    [updatePatientDiet.pending]: (state) => {
      state.updatedDiet.isLoading = true;
      state.updatedDiet.isSuccess = false;
      state.updatedDiet.isFailed = false;
      state.updatedDiet.error = '';
    },
    [updatePatientDiet.rejected]: (state, action) => {
      state.updatedDiet.isLoading = false;
      state.updatedDiet.isFailed = true;
      state.updatedDiet.isSuccess = false;
      state.updatedDiet.error = action?.payload?.errors;
    },
    [updatePatientMeal.fulfilled]: (state, action) => {
      state.updatedMeal.data = action.payload.data;
      state.updatedMeal.isLoading = false;
      state.updatedMeal.isSuccess = true;
    },
    [updatePatientMeal.pending]: (state) => {
      state.updatedMeal.isLoading = true;
      state.updatedMeal.isSuccess = false;
      state.updatedMeal.isFailed = false;
      state.updatedMeal.error = '';
    },
    [updatePatientMeal.rejected]: (state, action) => {
      state.updatedMeal.isLoading = false;
      state.updatedMeal.isFailed = true;
      state.updatedMeal.isSuccess = false;
      state.updatedMeal.error = action?.payload?.errors;
    },
    [deletePatientDiet.fulfilled]: (state, action) => {
      state.deletedDiet.data = action.payload.data;
      state.deletedDiet.isLoading = false;
      state.deletedDiet.isSuccess = true;
    },
    [deletePatientDiet.pending]: (state) => {
      state.deletedDiet.isLoading = true;
      state.deletedDiet.isSuccess = false;
      state.deletedDiet.isFailed = false;
      state.deletedDiet.error = '';
    },
    [deletePatientMeal.rejected]: (state, action) => {
      state.deletedDiet.isLoading = false;
      state.deletedDiet.isFailed = true;
      state.deletedDiet.isSuccess = false;
      state.deletedDiet.error = action?.payload?.errors;
    },
    [deletePatientMeal.fulfilled]: (state, action) => {
      state.deletedMeal.data = action.payload.data;
      state.deletedMeal.isLoading = false;
      state.deletedMeal.isSuccess = true;
    },
    [deletePatientMeal.pending]: (state) => {
      state.deletedMeal.isLoading = true;
      state.deletedMeal.isSuccess = false;
      state.deletedMeal.isFailed = false;
      state.deletedMeal.error = '';
    },
    [deletePatientMeal.rejected]: (state, action) => {
      state.deletedMeal.isLoading = false;
      state.deletedMeal.isFailed = true;
      state.deletedMeal.isSuccess = false;
      state.deletedMeal.error = action?.payload?.errors;
    },
    [getCurrentDiet.fulfilled]: (state, action) => {
      state.currentDiet.data = action.payload.data;
      state.currentDiet.isLoading = false;
      state.currentDiet.isSuccess = true;
    },
    [getCurrentDiet.pending]: (state) => {
      state.currentDiet.isLoading = true;
      state.currentDiet.isSuccess = false;
      state.currentDiet.isFailed = false;
      state.currentDiet.error = '';
    },
    [getCurrentDiet.rejected]: (state, action) => {
      state.currentDiet.data = [];
      state.currentDiet.isLoading = false;
      state.currentDiet.isFailed = true;
      state.currentDiet.isSuccess = false;
      state.currentDiet.error = action?.payload?.errors;
    },
  },
});

export const { actions: patientActions, reducer: patientReducer } = slice;

export const { setPatientState } = patientActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.patient || initialState;

export const selectPatient = createSelector([selectDomain], (patientState) => patientState);

export const selectPatientData = createSelector(
  [selectDomain],
  (patientState) => patientState.data,
);

export const selectPatientItem = createSelector(
  [selectDomain],
  (patientState) => patientState.item,
);

export const selectPatientLogs = createSelector(
  [selectDomain],
  (patientState) => patientState.logs,
);

export const selectPatientDiets = createSelector(
  [selectDomain],
  (patientState) => patientState.diets.data.data,
);
export const selectPatientMeals = createSelector(
  [selectDomain],
  (patientState) => patientState.meals.data.data,
);
export const selectCurrentDiet = createSelector(
  [selectDomain],
  (patientState) => patientState.currentDiet.data.data,
);
/* --------------------------------------------------------------------------------- */

export {
  getPatients,
  getPatient,
  showPatient,
  getPatientDiets,
  createPatientDiet,
  getPatientMeals,
  createPatientMeal,
  getCurrentDiet,
  updatePatientDiet,
  deletePatientMeal,
  deletePatientDiet,
  updatePatientMeal,
};
