/* eslint-disable */
// import core & vendor packages below
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import components below
import { patientStatus } from 'helpers';
import Table from 'components/ReactTable';
import { showPatient } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const PatientTable = ({ state, setState, setShowProfile }) => {
  // eslint-disable-next-line prefer-const
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const EditProfile = (item) => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        profileId: item?.id,
      };
    });

    dispatch(showPatient(item.id));
    navigate('/dashboard/outpatient-department/edit-patient-profile', { replace: true });
  };

  const handleDeletePatient = (patientId) => {
    axios
      .delete(`/patient/${patientId}`)
      .then((response) => {
        if (response.data.data) {
          axios
            .get('/patient', {
              params: {
                patient_status: ['for_admission', 'admitted'],
              },
            })
            .then((response) => {
              setState(response.data.data);
            }, [])
            .catch((err) => {
              console.log('AXIOS ERROR: ', err);
            });
        }
      })
      .catch((err) => {
        console.log('AXIOS ERROR', err);
      });
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Admission Date', accessor: 'admission_timestamp' }, // accessor is the "key" in the data
      { Header: 'MRN / PIN', accessor: 'mrn' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Sex', accessor: 'sex' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      // { Header: 'Currently', accessor: 'currently' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        admission_timestamp: moment(dd?.admission_date).format('MMM-DD-YYYY'),
        id: dd?.id,
        mrn: dd?.MRN,
        name: dd?.full_name,
        sex: dd?.sex,
        date_of_birth: dd?.date_of_birth,
        // currently: dd?.currently,
        status: patientStatus(dd?.patient_status),
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      profile: () => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isShowView: true,
            profileId: row?.id,
          };
        });
      },
      view: () => {},
      edit: () => {
        EditProfile(row);
      },
      delete: () => {
        handleDeletePatient(row.id);
      },
    };

    action[eventKey]();
  };

  return (
    <div className='admissions-search-patient-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasProfile: true, hasEdit: true, hasDelete: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

PatientTable.defaultProps = {
  state: [],
  setShowProfile: () => {},
};

PatientTable.propTypes = {
  state: PropTypes.array,
  setShowProfile: PropTypes.func,
};

export default PatientTable;
