/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// Main components below
import TabsUI from 'components/UI/TabsUI';

// Tab components
import AllItem from './Tabs/AllItem';
import ReturnedItem from './Tabs/ReturnedItem';
import CurrentBorrowedItem from './Tabs/CurrentBorrowedItem';
import { getBorrowedList } from 'reducers/borrowReturnReducer';

// Assets
import './style.scss';

// Main component
const BorrowItem = ({ departmentID }) => {
  const dispatch = useDispatch();
  const [refreshTab, setRefreshTab] = useState(false);
  const [activeTab, setActiveTab] = useState('All Item');

  const tabs = [
    {
      title: 'All Item',
      component: AllItem,
    },
    {
      title: 'Current Borrowed Item',
      component: CurrentBorrowedItem,
    },
    {
      title: 'Returned Item',
      component: ReturnedItem,
    },
  ];

  const onChange = (key) => {
    setActiveTab(key);

    let params = { per_page: 100000, department_id: departmentID, q: '' };

    if (key === 'Current Borrowed Item') {
      params.status = 13;
    } else if (key === 'Returned Item') {
      params.status = 14;
    }

    dispatch(getBorrowedList(params));
    setRefreshTab(true);
  };

  return (
    <div className='doctorTabs'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title} onSelect={(eventKey) => onChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component
                activeTab={activeTab}
                refreshTab={refreshTab}
                departmentID={departmentID}
                setRefreshTab={setRefreshTab}
              />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

BorrowItem.defaultProps = {
  departmentID: '',
};

BorrowItem.propTypes = {
  departmentID: PropTypes.string,
};

export default BorrowItem;
