/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
import { useState } from 'react';

import Filter from './components/Filter';
import PhilhealthModal from './components/Modal';
import SearchPatientListTable from './components/Table';

// import assets below
import './style.scss';

// main component
const SearchPatient = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    billId: false,
    profileId: null,
    isShowProfile: false,
  });
  
  return (
    <div className='search-patient'>
      <Filter state={state} setState={setState} />

      <SearchPatientListTable state={state} setState={setState} setShowProfile={setShowProfile} />

      {/* <PhilhealthModal showProfile={showProfile} setShowProfile={setShowProfile} /> */}
    </div>
  );
};

export default SearchPatient;
