/* eslint-disable react/jsx-indent-props */
import TableUI from 'components/UI/TableUI';
import { FormControl, InputGroup, Table } from 'react-bootstrap';

import './DeathTable.scss';

const DeathTable = (props) => {
  const { item } = props;

  return (
    <div className='mt-3 testing'>
      <TableUI>
        <Table striped hover style={{ width: '80%' }} className='mt-3'>
          <thead>
            <tr>
              <th>Types of deaths</th>
              <th>Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total deaths</td>
              <td>
                <InputGroup className='mb-1 mt-1'>
                  <FormControl placeholder='Enter Here' disabled value={item[0].totaldeaths} />
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td><b>Total number of inpatient deaths</b></td>
              <td></td>
            </tr>
            <tr>
              <td>Total deaths {'<'} 48 hours</td>
              <td>
                <InputGroup className='mb-1 mt-1'>
                  <FormControl
                    placeholder='Enter Here'
                    disabled
                    value={item[0].totaldeaths48down}
                  />
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>Total deaths {'>'} 48 hours</td>
              <td>
                <InputGroup className='mb-1 mt-1'>
                  <FormControl placeholder='Enter Here' disabled value={item[0].totaldeaths48up} />
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>
                Total number of emergency room deaths(Arrived at ER Still with signs of life and
                died at the ER before patient is admitted)Do not include deaths of admitted patients
                staying in ER awaiting available room or bed.
              </td>
              <td>
                <InputGroup className='mb-1 mt-1'>
                  <FormControl placeholder='Enter Here' disabled value={item[0].totalerdeaths} />
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>
                Total number of cases declared ‘dead on arrival’(Arrived at the ER with no signs of
                life even after resuscitation)
              </td>
              <td>
                <InputGroup className='mb-1 mt-1'>
                  <FormControl placeholder='Enter Here' disabled value={item[0].totaldoa} />
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>-Linear Accelerator</td>
              <td>
                <InputGroup className='mb-1 mt-1'>
                  <FormControl placeholder='Enter Here' disabled value={item[0].totaldeaths} />
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>
                Total number of stillbirths (Newborn delivered with no signs of life even after
                resuscitation).
              </td>
              <td>
                <InputGroup className='mb-1 mt-1'>
                  <FormControl placeholder='Enter Here' disabled value={item[0].totalstillbirths} />
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>Total number of neonatal deaths(deaths among infants, 0-28 days)</td>
              <td>
                <InputGroup className='mb-1 mt-1'>
                  <FormControl
                    placeholder='Enter Here'
                    disabled
                    value={item[0].totalneonataldeaths}
                  />
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>
                Total number of maternal deaths (Death related to pregnancy or delivery until 42nd
                day post partum).
              </td>
              <td>
                <InputGroup className='mb-1 mt-1'>
                  <FormControl
                    placeholder='Enter Here'
                    disabled
                    value={item[0].totalmaternaldeaths}
                  />
                </InputGroup>
              </td>
            </tr>
          </tbody>
        </Table>
      </TableUI>
    </div>
  );
};

export default DeathTable;
