/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import { getJobOrderList, selectFixedAssets } from 'reducers/fixedAssetsReducer';

// import assets below
import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

// main component
const index = ({ setShowScreen, setRequestData, setFormData }) => {
  const dispatch = useDispatch();

  const fixedAssetsState = useSelector(selectFixedAssets);
  const { jobOrderData, jobOrderItem, jobOrderLogs } = fixedAssetsState;

  const [tableList, setTableList] = useState([]);

  const tableColumns = React.useMemo(() => [{ Header: 'Job Orders', accessor: 'name' }]);

  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({
        data: dd,
        id: dd?.id,
        name: dd?.name,
        type: dd?.type,
        parent_id: dd?.parent_id,
        related_id: dd?.related_id,
        criticality: dd?.criticality,
        description: dd?.description,
        service_level: dd?.service_level,
        life_cycle_state: dd?.life_cycle_state,
      })),
    [tableList],
  );

  const handleViewDetails = (tableData) => {
    console.log(tableData);
    const { data } = tableData;

    setFormData((prev) => {
      return {
        ...prev,
        id: data?.id,
        lines: data?.lines,
        jobOrders: data?.name,
        jobOrdersType: data?.type,
        costType: data?.cost_type,
        description: data?.description,
        criticality: data?.criticality,
        parentJobOrders: data?.parent_id,
        serviceLevel: data?.service_level,
        relatedJobOrders: data?.related_id,
        jobOrdersPool: data?.job_orders_pool,
        lifeCycleState: data?.life_cycle_state,
      };
    });

    setShowScreen(2.5);
    setRequestData(data);
  };

  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, jobOrderData, jobOrderLogs);

  return (
    <div className='credentials-credits'>
      <div className='header'>
        <div className='functions'>
          <Button className='upload' onClick={() => setShowScreen(2)}>
            <AddSVG />
            Add Record
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <span
                className='badge yellow'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // setRequestData(data);

                  // setShowScreen(2.5);
                  handleViewDetails(data);
                }}
              >
                View Details
              </span>
            );
          }}
        />
      </div>
    </div>
  );
};

const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getJobOrderList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, jobOrderData, jobOrderLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = jobOrderLogs;

    if (isSuccess) {
      const newData = jobOrderData.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          name: dd?.name,
          type: dd?.type,
          parent_id: dd?.parent_id,
          related_id: dd?.related_id,
          criticality: dd?.criticality,
          description: dd?.description,
          service_level: dd?.service_level,
          life_cycle_state: dd?.life_cycle_state,
        };
      });
      console.log(newData);
      setTableList(newData);
    }
  }, [jobOrderLogs]);
};

export default index;
