// import core & vendor packages
import React, { useState } from 'react';

// import components
import Filter from './components/Filter';
import ConditionalRender from 'components/ConditionalRender';

// import assets
import './index.scss';

// main component
const RefillRequest = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    showView: false,
    profileId: null,
    profileData: null,
    isShowProfile: false,
    isShowDenyItemModal: false,
    isApproveRequestModal: false,
    isShowDenyRequestModal: false,
    isShowViewRequestModal: false,
    isShowForIssuanceModal: false,
  });

  const isDefaultView = showProfile.isShowProfile === false;

  return (
    <div>
      <ConditionalRender
        condition={isDefaultView}
        renderIf={
          <Filter
            state={state}
            setState={setState}
            showProfile={showProfile}
            setShowProfile={setShowProfile}
          />
        }
      />
    </div>
  );
};

export default RefillRequest;
