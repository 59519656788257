/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// import components below
import Table from 'components/ReactTable';
import OvertimeListModal from './components/Modal';
import { getOvertimesList, selectOvertimeTracking } from 'reducers/overtimeTrackingReducer';

//  import assets below
import './index.scss';

// main component
const samplePhoto = 'https://picsum.photos/id/237/200/300';

const index = ({ setShowScreen, setRequestData }) => {
  const dispatch = useDispatch();
  
  const overtimeTrackingState = useSelector(selectOvertimeTracking);
  const { overtimeListData, overtimeListLogs } = overtimeTrackingState;
  const [tableList, setTableList] = useState([]);
  
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Avatar', accessor: 'avatar' },
      { Header: 'ID', accessor: 'employee_id' },
      { Header: 'Name', accessor: 'fullname' },
      { Header: 'Date', accessor: 'overtime_date' },
      { Header: 'Time-in', accessor: 'time_in' },
      { Header: 'Time-out', accessor: 'time_out' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({  
        data: dd,  
        id: dd?.id,
        employee_id: dd?.employee_id,
        overtime_date:moment(dd?.overtime_date).format('MM-DD-YYYY'),
        time_in: dd?.time_in,
        time_out: dd?.time_out,
        status: dd?.status,
      })),
    [tableList],
  );
  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, overtimeListData, overtimeListLogs);

  const handleOvertime = (data) => {
    setModalData(data);
  };

  return (
    <div className='overtime-list'>

      <div className='overtime-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <span
                className='badge brown'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenModal({
                    data: data,
                    isOpen: true,
                  });
                  handleOvertime(data);
                }
                }
              >
                Details
              </span>
            );
          }}
        />
      </div>
      <OvertimeListModal openModal={openModal} setOpenModal={setOpenModal} modalData={modalData} />
    </div>
  );
};
const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getOvertimesList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, overtimeListData, overtimeListLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = overtimeListLogs;
    
    if (isSuccess) {
      const newData = overtimeListData.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          employee_id: dd?.employee_id,
          overtime_date: dd?.overtime_date,
          time_in: dd?.time_in,
          time_out: dd?.time_out,
          status: dd?.status,
          };

      });
      console.log(newData);
      setTableList(newData);
    }

  }, [overtimeListLogs]);

};
export default index;
