/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getOrderListItems = createAsyncThunk(
  'department/getOrderListItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params?.payload,
      url: `api/department/inventory/${params?.id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const addOrder = createAsyncThunk(
  'department/addOrder',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: params,
      url: `api/orders`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getSuppliesList = createAsyncThunk(
  'department/getSuppliesList',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/orders`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getOrdersList = createAsyncThunk(
  'department/getOrdersList',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/orders`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteOrder = createAsyncThunk(
  'department/deleteOrder',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'delete',
      url: `api/orders/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
