import TableUI from 'components/UI/TableUI';
import { FormControl, InputGroup, Table } from 'react-bootstrap';

import './MorbilityTable.scss';

const MorbilityTable = (props) => {

    const { items } = props;

    return (<div className='mt-3 morbility'>
        <TableUI>
            <Table striped hover style={{ width: '80%' }}>
                <thead>
                <tr>
                    <th>Cause of Morbidity/Illness/Injury Do not include deliveries.</th>
                    <th>Number</th>
                    <th>ICD-10 Code (Tabular)</th>
                </tr>
                </thead>
                <tbody>

                { items?.map((item, index) => {
                        return (<tr key={index + 1}>
                        <td>{index + 1}. {item.morbidity}</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                              placeholder='Enter Here'
                              disabled
                              value={item.number}
                            />
                        </InputGroup></td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                              placeholder='Enter Here'
                              disabled
                              value={item.icd_code}
                            />
                        </InputGroup></td>
                    </tr>);
                }) }
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan='3' className='fs-6 fw-bold'>Do not include deliveries</td>
                    </tr>
                </tfoot>
            </Table>
        </TableUI>
    </div>);
};

export default MorbilityTable;
