/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import { getInventoryList, selectFixedAssets } from 'reducers/fixedAssetsReducer';

// import assets below
import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

// main component

const index = ({ setShowScreen, setRequestData, setFormData }) => {
  const dispatch = useDispatch();

  const fixedAssetsState = useSelector(selectFixedAssets);
  const { inventoryData, inventoryItem, inventoryLogs } = fixedAssetsState;

  const [tableList, setTableList] = useState([]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Asset No.', accessor: 'asset_number' },
      { Header: 'Description', accessor: 'description' },
    ],
  );

  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({
        data: dd,
        id: dd?.id,
        vendor: dd?.vendor,
        location: dd?.location,
        po_number: dd?.po_number,
        description: dd?.description,
        asset_number: dd?.asset_number,
        purchase_price: dd?.purchase_price,
        acquisition_date: dd?.acquisition_date,
      })),
    [tableList],
  );

  const handleViewDetails = (tableData) => {
    console.log(tableData);
    const { data } = tableData;

    setFormData((prev) => {
      return {
        ...prev,
        id: data?.id,
        vendor: data?.vendor,
        location: data?.location,
        poNumber: data?.po_number,
        description: data?.description,
        assetNumber: data?.asset_number,
        purchasePrice: data?.purchase_price,
        acquisitionDate: moment(data?.acquisition_date).format('MM/DD/YYYY'),
      };
    });

    setShowScreen(6.5);
    setRequestData(data);
  };

  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, inventoryData, inventoryLogs);

  return (
    <div className='credentials-credits'>
      <div className='header'>
        
        <div className='functions'>

          <Button className='upload' onClick={() => setShowScreen(6)}>
            <AddSVG />
            Add Record
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <span
                className='badge yellow'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // setRequestData(data);

                  // setShowScreen(2.5);
                  handleViewDetails(data);
                }}
              >
                View Details
              </span>
            );
          }}
        />
      </div>
    </div>
  );
};

const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getInventoryList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, inventoryData, inventoryLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = inventoryLogs;

    if (isSuccess) {
      const newData = inventoryData.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          vendor: dd?.vendor,
          location: dd?.location,
          po_number: dd?.po_number,
          description: dd?.description,
          asset_number: dd?.asset_number,
          purchase_price: dd?.purchase_price,
          acquisition_date: dd?.acquisition_date, 
        };
      });
      console.log(newData);
      setTableList(newData);
    }
  }, [inventoryLogs]);
};

export default index;
