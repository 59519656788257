/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
import axios from 'axios';
import moment from 'moment';
import Constants from 'constant';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Table, InputGroup, FormControl, Button } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';
import LayoutReport from 'components/LayoutReport';

import './style.scss';

const HospitalOperation = () => {
  const input = useParams();

  const [payload, setPayload] = useState({
    type: 'expenses',
    salarieswages: '',
    employeebenefits: '',
    allowances: '',
    totalps: '',
    totalamountmedicine: '',
    totalamountmedicalsupplies: '',
    totalamountutilities: '',
    totalamountnonmedicalservice: '',
    totalmooe: '',
    amountinfrastructure: '',
    amountequipment: '',
    totalco: '',
    grandtotal: '',
    reportingyear: moment().format('YYYY'),
    year: input.year,
  });

  const [expenses, setExpenses] = useState({});

  const handleChange = (key) => (e) => {
    const values = {
      ...payload,
      [key]: e.target.value,
    };

    // setPayload(values);
    calculateGrandTotal(values);
  };

  const generate = () => {
    axios
      .post('/report/expenses', payload)
      .then((response) => {
        toast.success('Success!');

        setTimeout(() => {
          // window.history.back();
        }, 500);
      })
      .catch((error) => {
        toast.danger('ERROR!');
      });
  };

  useEffect(() => {
    if (typeof input.id === 'string') {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/report/expenses/${input.id}`)
        .then((response) => {
          setExpenses(response.data.data);
        }, []);
    }
  }, []);

  const updateDoh = async (id) => {
    const params = { type: Constants.EXPENSES };
    axios
      .put(`/report/doh/${id}`, params)
      .then((response) => {
        toast.success('Success!');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculateGrandTotal = (values) => {
    console.log(values);
    const salarieswagesToInt = values.salarieswages ? parseFloat(values.salarieswages) : 0;
    const employeebenefitsToInt = values.employeebenefits ? parseFloat(values.employeebenefits) : 0;
    const allowancesToInt = values.allowances ? parseFloat(values.allowances) : 0;
    const totalpsToInt = values.totalps ? parseFloat(values.totalps) : 0;

    const totalamountmedicineToInt = values.totalamountmedicine
      ? parseFloat(values.totalamountmedicine)
      : 0;
    const totalamountmedicalsuppliesToInt = values.totalamountmedicalsupplies
      ? parseFloat(values.totalamountmedicalsupplies)
      : 0;
    const totalamountutilitiesToInt = values.totalamountutilities
      ? parseFloat(values.totalamountutilities)
      : 0;
    const totalamountnonmedicalserviceToInt = values.totalamountnonmedicalservice
      ? parseFloat(values.totalamountnonmedicalservice)
      : 0;

    const totalmooeToInt = values.totalmooe ? parseFloat(values.totalmooe) : 0;
    const amountinfrastructureToInt = values.amountinfrastructure
      ? parseFloat(values.amountinfrastructure)
      : 0;
    const amountequipmentToInt = values.amountequipment ? parseFloat(values.amountequipment) : 0;
    const totalcoToInt = values.totalco ? parseFloat(values.totalco) : 0;

    // TOTAL amount spent on all personnel including wages, salaries, benefits and allowances for last year (PS)
    const TOTALPS = salarieswagesToInt + employeebenefitsToInt + allowancesToInt;

    // TOTAL amount spent on maintenance and other operating expenditures (MOOE)
    const TOTALMOE =
      totalamountmedicineToInt +
      totalamountmedicalsuppliesToInt +
      totalamountutilitiesToInt +
      totalamountnonmedicalserviceToInt;

    const TOTALCOE = amountinfrastructureToInt + amountequipmentToInt;

    const grandtotal = TOTALPS + TOTALMOE + TOTALCOE;

    console.log('grand total: ', grandtotal);

    setPayload({
      ...payload,
      ...values,
      grandtotal: grandtotal.toString(),
      totalps: TOTALPS.toString(),
      totalmooe: TOTALMOE.toString(),
      totalco: TOTALCOE.toString(),
    });
  };

  return (
    <LayoutReport pageTitle='Expenses'>
      <row className='operation-report'>
        <Row className='header'>
          <h1>Expenses</h1>
        </Row>
        <Container>
          <Row className='body'>
            <Row className='mb-3 mt-3'>
              <label className='fw-bold'>
                Report all money spent by the facility on each category.
              </label>
              <div className='mt-3'>
                <TableUI>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Expenses</th>
                        <th>Amount in Pesos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Amount spent on personnel salaries and wages</td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              onChange={handleChange('salarieswages')}
                              value={expenses?.report_expense?.salarieswages}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Amount spent on benefits for employees (benefits are in addition to
                          wages/salaries. Benefits include for example; social security
                          contributions, health insurance)
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              onChange={handleChange('employeebenefits')}
                              value={expenses?.report_expense?.employeebenefits}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Allowances provided to employees at this facility (Allowances are in
                          addition to wages/salaries. Allowances include for example: clothing
                          allowance, PERA, vehicle maintenance allowance and hazard pay.)
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              onChange={handleChange('allowances')}
                              value={expenses?.report_expense?.allowances}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            TOTAL amount spent on all personnel including wages, salaries, benefits
                            and allowances for last year (PS)
                          </b>
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              disabled
                              placeholder='Enter Here'
                              onChange={handleChange('totalps')}
                              value={expenses?.report_expense?.totalps ?? payload?.totalps}
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>Total amount spent on medicines</td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              onChange={handleChange('totalamountmedicine')}
                              value={expenses?.report_expense?.totalamountmedicine}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Total amount spent on medical supplies (i.e. syringe, gauze, etc.; exclude
                          pharmaceuticals)
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              onChange={handleChange('totalamountmedicalsupplies')}
                              value={expenses?.report_expense?.totalamountmedicalsupplies}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Total amount spent on utilities( water, electricity, communication, etc.)
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              onChange={handleChange('totalamountutilities')}
                              value={expenses?.report_expense?.totalamountutilities}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Total amount spent on non-medical services (For example: security, food
                          service, laundry, waste management)
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              onChange={handleChange('totalamountnonmedicalservice')}
                              value={expenses?.report_expense?.totalamountnonmedicalservice}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            TOTAL amount spent on maintenance and other operating expenditures
                            (MOOE)
                          </b>
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              disabled
                              placeholder='Enter Here'
                              onChange={handleChange('totalmooe')}
                              value={expenses?.report_expense?.totalmooe ?? payload?.totalmooe}
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Amount spent on infrastructure (i.e., new hospital wing, installation of
                          ramps)
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              onChange={handleChange('amountinfrastructure')}
                              value={expenses?.report_expense?.amountinfrastructure}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>Amount spent on equipment (i.e. x-ray machine, CT scan)</td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              onChange={handleChange('amountequipment')}
                              value={expenses?.report_expense?.amountequipment}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>TOTAL amount spent on capital outlay (CO)</b>
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              disabled
                              type='number'
                              placeholder='Enter Here'
                              onChange={handleChange('totalco')}
                              value={expenses?.report_expense?.totalco ?? payload?.totalco}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>GRAND TOTAL</b>
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              disabled
                              placeholder='Enter Here'
                              onChange={handleChange('grandtotal')}
                              value={expenses?.report_expense?.grandtotal ?? payload?.grandtotal}
                            />
                          </InputGroup>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <br></br>
                  <div className='d-flex justify-content-end'>
                    {typeof input.type !== 'string' ? (
                      <Button className='btn-light-green' onClick={generate}>
                        Save Report
                      </Button>
                    ) : (
                      ''
                    )}
                    &nbsp;&nbsp;
                    {typeof input.type === 'string' ? (
                      <Button
                        className='btn-light-green'
                        onClick={(e) => {
                          updateDoh(expenses?.id);
                        }}
                      >
                        Update DOH
                      </Button>
                    ) : (
                      ''
                    )}
                    <Link to='/dashboard/reports'>
                      <Button variant='secondary ms-4'>Cancel</Button>
                    </Link>
                  </div>
                </TableUI>
              </div>
            </Row>
          </Row>
        </Container>
      </row>
    </LayoutReport>
  );
};

export default HospitalOperation;
