/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Row, Col, Button, Modal, Form, Table, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';

// import assets below
import './style.scss';
import { ReactComponent as CirclePlus } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as CircleMinus } from 'assets/svg/circle-minus.svg';
import { ReactComponent as FileUploadSVG } from 'assets/svg/file-upload.svg';
import { isArray } from 'lodash';
import ConditionalRender from 'components/ConditionalRender';

// main component
const GoodsReceiptsInformation = ({
  showProfile,
  setShowProfile,
  completeHandler,
  items,
  trigger,
}) => {
  // console.log(items?.attributes?.image_path);

  const [data, setData] = useState({ delivered_by: '', received_by: '', deployed_to: '' });
  return (
    <Modal
      size='lg'
      show={showProfile.showView}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showView: false,
          };
        })
      }
      aria-labelledby='ITEM FOR REQUEST TO REPAIR'
      className='view-request-for-repair-modal'
    >
      <Modal.Body>
        <div className='view-request-for-repair-request'>
          <div className='d-flex header'>
            <p>REQUEST ITEM FOR REPAIR/MAINTENANCE</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, showView: false };
                });
              }}
            />
          </div>
        </div>
        {trigger ? (
          <div className='text-center'>
            <p>Loading</p>
          </div>
        ) : (
          <FormUI>
            <Form className='adjustment-form'>
              <Row>
                <Col sm={5}>
                  <img
                    className='image-container'
                    alt='img'
                    src={
                      items?.attributes?.image_path
                        ? items?.attributes?.image_path
                        : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'
                    }
                  />
                </Col>
                <Col sm={7} className='align-items-center'>
                  <Form.Group className='mb-2'>
                    <Form.Label>Control Number :</Form.Label>
                    <span className='mx-3'>{items?.attributes?.control_number}</span>
                  </Form.Group>
                  <Form.Group className='mb-2'>
                    <Form.Label>Equipment Name :</Form.Label>
                    <span className='mx-3'>{items?.equipment_name}</span>
                  </Form.Group>
                  <Form.Group className='mb-2'>
                    <Form.Label>Equipment Description :</Form.Label>
                    <span className='mx-3'>
                      {Array.isArray(items) !== 'undefined' ? items?.attributes?.description : ''}
                    </span>
                  </Form.Group>
                  <Form.Group className='mb-2'>
                    <Form.Label>Item Count :</Form.Label>
                    <span className='mx-3'>{items?.attributes?.item_count}</span>
                  </Form.Group>
                  <Form.Group className='mb-2'>
                    <Form.Label>Item Status :</Form.Label>
                    <span
                      className='mx-3 px-3 py-1 text-white'
                      style={{
                        border: 'none',
                        backgroundColor:
                          items?.attributes?.status === 'for condemnation' ? '#05B10C' : '#ff0000',
                        borderRadius: '5px',
                      }}
                    >
                      {items?.attributes?.status}
                    </span>
                  </Form.Group>
                  <Form.Group className='mb-2'>
                    <Form.Label>Requested by :</Form.Label>
                    <span className='mx-3'>{items?.attributes?.requested_by}</span>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <ConditionalRender
                condition={!items?.attributes?.completed_at}
                renderIf={
                  <>
                    <div className='mb-4'>
                      <Row>
                        <Col>
                          <Form.Label>Delivered By:</Form.Label>
                          <Form.Control
                            onChange={(e) =>
                              setData((prev) => {
                                return { ...prev, delivered_by: e.target.value };
                              })
                            }
                          />
                        </Col>
                        <Col>
                          <Form.Label>Received By:</Form.Label>
                          <Form.Control
                            onChange={(e) =>
                              setData((prev) => {
                                return { ...prev, received_by: e.target.value };
                              })
                            }
                          />
                        </Col>
                        <Col>
                          <Form.Label>Deployed To:</Form.Label>
                          <Form.Control
                            onChange={(e) =>
                              setData((prev) => {
                                return { ...prev, deployed_to: e.target.value };
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className='text-center'>
                      <Button
                        className=' text-white'
                        style={{ border: 'none', backgroundColor: '#05B10C' }}
                        onClick={() => completeHandler(data)}
                      >
                        COMPLETE ITEM
                      </Button>
                    </div>
                  </>
                }
              />
            </Form>
          </FormUI>
        )}
      </Modal.Body>
    </Modal>
  );
};

GoodsReceiptsInformation.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

GoodsReceiptsInformation.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default GoodsReceiptsInformation;
