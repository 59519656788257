// import assets below
import './style.scss';

// main component
const FilterUI = (props) => {
  const { children } = props;
  return <div className='filterUI'>{children}</div>;
};

export default FilterUI;
