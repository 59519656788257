// import core & vendor packages below
import { Modal, Button } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './Modal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as SignupApprovedSVG } from 'assets/svg/signup_approved_modal.svg';

// main componet
const LoginModal = (props) => {
  const { status, onToggle } = props;

  return (
    <div className='loginModal'>
      <ModalUI>
        <Modal.Dialog>
          <Modal.Header>
            <h6>ACCOUNT APPROVED!</h6>
            <Button onClick={() => onToggle(!status)}>
              <CloseSVG />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className='loginModal__svgContainer'>
              <SignupApprovedSVG />
            </div>
            <p>
              Congratulations! Your account has been approved, click the button below to login to
              the platform.
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button className='loginModal__button' onClick={() => onToggle(!status)}>
              Sign in
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </ModalUI>
    </div>
  );
};

export default LoginModal;
