/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Helpers
import {
    calculateDayDiff,
    patientStatus,
    currentLocation,
} from 'helpers';

// import components below
import Table from 'components/ReactTable';
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
// import './Table.scss';

// main component
const SearchPatientListTable = ({ state, setState, setShowProfile }) => {
    const data = useSelector(selectPatientData);

    useEffect(() => {
        axios
            .get('/patient')
            .then((response) => {
                const data = response.data.data.filter((item, key) => {
                    if (item?.bills?.length > 0) {
                        return item;
                    }
                });
                setState(data);
            }, [])
            .catch((err) => {
                console.log('AXIOS ERROR: ', err);
            });
    }, []);

    const tableColumns = React.useMemo(
        () => [
            { Header: 'Bill ID', accessor: 'billId' }, // accessor is the "key" in the data
            { Header: 'ID', accessor: 'id' }, // accessor is the "key" in the data
            { Header: 'MRN / PIN', accessor: 'mrn' },
            { Header: 'Patient Name', accessor: 'name' },
            { Header: 'Sex', accessor: 'sex' },
            { Header: 'Date of Birth', accessor: 'date_of_birth' },
            { Header: 'Currently', accessor: 'currently' },
            { Header: 'Status', accessor: 'status' },
        ],
        []
    )

    const tableData = React.useMemo(
        () => state?.map(dd => ({
            billId: dd?.bills[0]?.id,
            id: dd?.id,
            mrn: dd?.MRN,
            name: dd?.full_name,
            sex: dd?.sex,
            date_of_birth: moment(dd?.date_of_birth).format('MMMM-DD-YYYY'),
            currently: currentLocation(dd?.patient_status),
            status: patientStatus(dd?.patient_status),
        })),
        [state]
    )

    const handleTableAction = (eventKey, id, billId) => {
        const row = state.find(dd => dd.id === id);
        const action = {
            profile: () => {
                setShowProfile({
                    isShowProfile: true,
                    profileId: row?.id,
                    billId: row?.bills[0]?.id,
                    admissionDays: calculateDayDiff((row?.admission_date)),
                })
            },
            view: () => { },
            edit: () => { },
            delete: () => { }
        }

        action[eventKey]()
    }

    return (
        <div className='searchPatientListTable'>
            <Table
                data={tableData}
                columns={tableColumns}
                actions={{ hasProfile: true }}
                onAction={handleTableAction}
                pagination={['BOTTOM']}
            />
        </div>
    );
};

export default SearchPatientListTable;
