/* eslint-disable */
// import core & vendor packages below
import moment from 'moment';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

// Components
import Table from 'components/ReactTable';
import ViewModal from './Modals/ViewModal';
import DeleteModal from './Modals/DeleteModal';
import ViewEditModal from './Modals/ViewEditModal';
import ConditionalRender from 'components/ConditionalRender';
import { getReturnTableList, selectReturn } from 'reducers/returnReducer';
import { PrintContent } from '../../../PrintContent';

// Assets below
import './Table.scss';

// main component
const InventoryListTable = ({ searchData, departmentID, subTabActiveKey }) => {
  const dispatch = useDispatch();

  const returnState = useSelector(selectReturn);
  const { returnData, returnItems, returnLogs } = returnState;

  const [showModal, setShowModal] = useState({});
  
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page { size: landscape }',
  });

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Date / Time Return', accessor: 'created_at' },
      { Header: 'Control Number', accessor: 'control_number' },
      { Header: 'Received by', accessor: 'received_by' },
      { Header: 'Received at', accessor: 'received_at' },
      { Header: 'Remarks', accessor: 'remarks' },
      { Header: 'Number of items', accessor: 'number_of_items' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      returnData?.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          created_at: moment(dd?.attributes?.created_at).format('MMM-DD-YYYY hh:mm A'),
          control_number: dd?.attributes?.control_number,
          received_by: dd?.attributes?.received_by,
          received_at: dd?.attributes?.received_at
            ? moment(dd?.attributes?.received_at).format('MMM-DD-YYYY hh:mm A')
            : '',
          remarks: dd?.attributes?.remarks,
          number_of_items: dd?.relationships?.return_request_items?.data.length,
          status: <span className={dd?.attributes?.status}>{dd?.attributes?.status}</span>,
        };
      }),
    [returnData],
  );

  const getReturnListData = () => {
    const params = { per_page: 100000, q: searchData?.search, department_id: departmentID };
    dispatch(getReturnTableList(params));
  };

  useEffect(() => {
    // console.log('returnData', returnData);
  }, [returnData]);

  runWhenTabIsActive(getReturnListData, subTabActiveKey);

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent 
          ref={printRef} 
          tableCol={tableColumns}
        >
          {tableData?.length 
            ? tableData?.map((data) => (
                <tr key={data?.id}>
                    <td>{data?.created_at}</td>
                    <td>{data?.control_number}</td>
                    <td>{data?.received_by}</td>
                    <td>{data?.received_at}</td>
                    <td>{data?.remarks}</td>
                    <td>{data?.number_of_items}</td>
                    <td>{data?.status}</td>
                </tr>
            )) : (
                <tr>
                    <td style={{ textAlign: 'center' }} colSpan={7}>No Record Found</td>
                </tr>
            )
          }
        </PrintContent>
      </div>
      <div className='returned-item-to-inventory'>
        <Row>
          <Col className='table-header'>
            <h4 className='mb-0 fw-bold mt-4 mb-2'>Returned Item to inventory</h4>
            <Button className='print-button' onClick={handlePrint}>Print</Button>
          </Col>
        </Row>

        <Table
          data={tableData}
          logs={returnLogs}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id) => {
            const row = tableData.find((dd) => dd.id === id);
            const { status } = row?.data?.attributes;

            const buttons = [
              {
                text: 'View',
                color: 'blue',
                action: () => {
                  setShowModal((prevState) => {
                    return {
                      ...prevState,
                      data: row,
                      viewEditItem: true,
                    };
                  });
                },
                condition: status === 'for receiving',
              },
              {
                text: 'Delete',
                color: 'red',
                action: () => {
                  setShowModal((prevState) => {
                    return {
                      ...prevState,
                      data: row,
                      deleteModal: true,
                    };
                  });
                },
                condition: status === 'for receiving',
              },
              {
                text: 'View',
                color: 'blue',
                action: () => {
                  setShowModal((prevState) => {
                    return {
                      ...prevState,
                      data: row,
                      viewItem: true,
                    };
                  });
                },
                condition: status === 'completed',
              },
            ];

            return (
              <div className='action-buttons'>
                {buttons.map((buttonData) => {
                  return (
                    <ConditionalRender
                      condition={buttonData?.condition}
                      renderIf={
                        <span
                          className={`badge ${buttonData?.color}`}
                          style={{ cursor: 'pointer' }}
                          onClick={buttonData?.action}
                        >
                          {buttonData?.text}
                        </span>
                      }
                    />
                  );
                })}
              </div>
            );
          }}
        />
      </div>

      <ViewEditModal
        showModal={showModal}
        setShowModal={setShowModal}
        departmentID={departmentID}
        getDepartment={getReturnListData}
      />

      <ViewModal
        showModal={showModal}
        setShowModal={setShowModal}
        departmentID={departmentID}
        getDepartment={getReturnListData}
      />

      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        departmentID={departmentID}
        getDepartment={getReturnListData}
      />
    </>
  );
};

const runWhenTabIsActive = (getReturnListData, subTabActiveKey) => {
  useEffect(() => {
    if (subTabActiveKey === 'Returned Item to inventory') {
      getReturnListData();
    }
  }, [subTabActiveKey]);
};

InventoryListTable.defaultProps = {
  searchData: {},
  departmentID: '',
};

InventoryListTable.propTypes = {
  searchData: PropTypes.object,
  departmentID: PropTypes.string,
};

export default InventoryListTable;
