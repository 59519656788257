/* eslint-disable */
/* eslint-disable camelcase */
// import core & vendor packages 
import Moment from 'moment';
import { useDispatch } from 'react-redux';
import Popover from 'react-bootstrap/Popover';
import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { approverequesteditem } from 'reducers/refillRequest';
import { Form, Row, Col, FormControl, Button, Modal, Accordion } from 'react-bootstrap';

// import components 
import FormUI from 'components/UI/FormUI';
import Table from 'components/ReactTable';

// import assets 
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as CirleMinusSVG } from 'assets/svg/circle-minus.svg';
import { ReactComponent as CircleCheck } from 'assets/svg/circle-check-green.svg';

// main component
const ViewRequestModal = ({
  state,
  items,
  groups,
  setState,
  setTrigger,
  showProfile,
  setShowProfile,
}) => {
  // Redux
  const dispatch = useDispatch();
  const { item_list, filtered_item_list } = state;
  // States
  const [enteredSearch, setEnteredSearch] = useState('');
  const [groupchange, setGroupchange] = useState('');

  // Get group name from included api
  const getGroupName = (id) => {
    const selectedArray = items.filter((data) => data.id === id && data.type === 'groups');

    if (selectedArray.length === 0) {
      return 'N/A';
    }

    return selectedArray[0]?.attributes?.name;
  };

  //approve item
  const handleTableAction = (eventKey, id) => {
    const action = {
      approve: () => {
        const params = {
          restock_id: state?.itemID?.id,
          requested_item: id,
          url: 'approve',
        };
        dispatch(approverequesteditem(params));
        setTrigger((prev) => {
          return { ...prev, approverequesteditemTrigger: true };
        });
      },
      deny: () => {
        setShowProfile((prev) => {
          return { ...prev, isShowDenyItemModal: true, isShowViewRequestModal: false };
        });
        setState((prev) => {
          return { ...prev, requested_item_id: id };
        });
      },
    };
    action[eventKey]();
  };

  // table action handler
  const actionHandler = (id, status) => {
    return (
      <>
        {status === 'new' ? (
          <div>
            <CircleCheck
              onClick={() => {
                handleTableAction('approve', id);
              }}
              style={{
                fill: '#01B425',
                width: '2em',
                cursor: 'pointer',
                margin: '2px',
              }}
            />
            <CirleMinusSVG
              onClick={() => {
                handleTableAction('deny', id);
              }}
              style={{
                fill: '#01B425',
                width: '2em',
                cursor: 'pointer',
                margin: '2px',
              }}
            />
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  };

  const statusAction = (remark, status) => (
    <>
      {status === 'new' && (
        <span
          className='px-3 py-1 text-white'
          style={{ backgroundColor: '#00a8ff', borderRadius: '5px' }}
        >
          {status}
        </span>
      )}
      {status === 'approved' && (
        <span
          className='px-3 py-1 text-white'
          style={{ backgroundColor: '#20C997', borderRadius: '5px' }}
        >
          {status}
        </span>
      )}
      {status === 'denied' &&
        (remark ? (
          <OverlayTrigger
            placement='right'
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover>
                <Popover.Header as='h3' style={{ backgroundColor: '#03A8FF', color: 'white' }}>
                  Remark
                </Popover.Header>
                <Popover.Body>{remark}</Popover.Body>
              </Popover>
            }
          >
            <Button
              className='px-3 py-1 text-white'
              style={{ backgroundColor: '#ff0000', borderRadius: '5px' }}
            >
              {status}
            </Button>
          </OverlayTrigger>
        ) : (
          <Button
            className='px-3 py-1 text-white'
            style={{ backgroundColor: '#ff0000', borderRadius: '5px' }}
          >
            {status}
          </Button>
        ))}
      {status === 'completed' && (
        <span
          className='px-3 py-1 text-white'
          style={{ backgroundColor: '#02961C', borderRadius: '5px' }}
        >
          {status}
        </span>
      )}
      {status !== 'new' && status !== 'approved' && status !== 'completed' && status !== 'denied' && (
        <span
          className='px-3 py-1 text-white'
          style={{ backgroundColor: '#0069F2', borderRadius: '5px' }}
        >
          {status}
        </span>
      )}
    </>
  );

  const tableColumns = React.useMemo(
    () =>
      state.itemID?.attributes?.status === 'approved' ||
      state.itemID?.attributes?.status === 'in progress'
        ? [
            { Header: 'Item Code', accessor: 'item_code' }, // accessor is the "key" in the data
            { Header: 'Item Name', accessor: 'item_name' },
            { Header: 'Item Group', accessor: 'item_group' },
            { Header: 'Storage Bin', accessor: 'storage_bin' },
            { Header: 'Piece Price', accessor: 'piece_price' },
            { Header: 'Box Price', accessor: 'box_price' },
            { Header: 'Requested UOM', accessor: 'uom' },
            { Header: 'Requested Quantity', accessor: 'requested_quantity' },
            { Header: 'Status', accessor: 'status' },
            { Header: 'Action', accessor: 'action' },
          ]
        : [
            { Header: 'Item Code', accessor: 'item_code' }, // accessor is the "key" in the data
            { Header: 'Item Name', accessor: 'item_name' },
            { Header: 'Item Group', accessor: 'item_group' },
            { Header: 'Storage Bin', accessor: 'storage_bin' },
            { Header: 'Piece Price', accessor: 'piece_price' },
            { Header: 'Box Price ', accessor: 'box_price' },
            { Header: 'Requested UOM', accessor: 'uom' },
            { Header: 'Requested Quantity', accessor: 'requested_quantity' },
            { Header: 'Status', accessor: 'status' },
          ],
    [state?.itemID],
  );

  const tableData = React.useMemo(
    () =>
      filtered_item_list
        ? filtered_item_list?.map((dd) => {
            const reStockID = dd?.restock_id;
            const restockData = items.find(
              (d) => d?.id == reStockID && d?.type === 'restock_items',
            );

            return {
              id: dd?.id,
              item_code: dd?.attributes?.code,
              item_name: dd?.attributes?.name,
              item_group: getGroupName(dd?.relationships?.group?.data[0].id),
              storage_bin: dd?.attributes?.storage_bin,
              
              piece_price: dd?.attributes?.piece_price,
              box_price: dd?.attributes?.box_price,
              requested_quantity: dd?.quantity,
              uom: restockData?.attributes?.unit_of_measure,
              status: statusAction(dd?.remarks, dd?.status),
              action: actionHandler(dd?.restock_id, dd?.status),
            };
          })
        : [],
    [filtered_item_list],
  );

  useEffect(() => {
    setState((prev) => {
      return { ...prev, filtered_item_list: item_list };
    });
  }, []);

  useEffect(() => {
    const identifier = setTimeout(() => {
      // store temporary the item list
      let filtered_item_list = item_list;
      // filter item list
      if (enteredSearch !== '') {
        filtered_item_list = item_list.filter(
          (data) =>
            data.attributes.code === enteredSearch || data.attributes.name === enteredSearch,
        );

        setState((prev) => {
          return { ...prev, filtered_item_list: filtered_item_list };
        });
      }

      // return the original item list
      if (enteredSearch === '') {
        setState((prev) => {
          return { ...prev, filtered_item_list: filtered_item_list };
        });
      }
    }, 500);

    return () => {
      clearTimeout(identifier);
    };
  }, [enteredSearch]);

  useEffect(() => {
    // store temporary the item list
    let filtered_item_list = item_list;
    if (groupchange !== '') {
      filtered_item_list = item_list.filter((data) => {
        const groupID = data.relationships.group.data[0].id;
        return groupID == groupchange;
      });

      setState((prev) => {
        return { ...prev, filtered_item_list: filtered_item_list };
      });
    }
    // return the original item list
    if (groupchange === '') {
      setState((prev) => {
        return { ...prev, filtered_item_list: filtered_item_list };
      });
    }
  }, [groupchange]);

  useEffect(() => {
    // return the original item list
    if (filtered_item_list) {
      setGroupchange('');
      setState((prev) => {
        return { ...prev, filtered_item_list: item_list };
      });
    }
  }, [showProfile?.isShowViewRequestModal]);

  return (
    <Modal
      size='lg'
      show={showProfile?.isShowViewRequestModal}
      onHide={() => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isShowViewRequestModal: false,
          };
        });
      }}
      aria-labelledby='Request Item Modal'
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>REQUEST Details</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                // setGroupchange('');

                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    isShowViewRequestModal: false,
                  };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <div className='add-item-form'>
            <Row>
              <Col>
                <div>
                  <Row>
                    <Col className='request-details'>
                      <Form.Group className='inline-group'>
                        <Form.Label>Request Date/Time :</Form.Label>
                        <span className='ms-5'>
                          {Moment(state?.itemID?.attributes?.requested_at).format(
                            'MMMM Do YYYY, h:mm:ss a',
                          )}
                        </span>
                      </Form.Group>
                      <Form.Group className='inline-group'>
                        <Form.Label>Request No.</Form.Label>
                        <span className='ms-5'>{state?.itemID?.attributes?.request_number}</span>
                      </Form.Group>
                    </Col>
                    <Col className='text-end'>
                      <Form.Group>
                        <Form.Label>Request By :</Form.Label>
                        <span className='ms-5 fw-bold' style={{ color: '#006397' }}>
                          {state?.itemID?.attributes?.requested_by}
                        </span>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Status :</Form.Label>
                        <span
                          className='ms-5  px-4 py-1 '
                          style={{
                            backgroundColor: '#00A8FF',
                            color: 'white',
                            borderRadius: '8px',
                          }}
                        >
                          {state?.itemID?.attributes?.status}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>

                <div className='search-item'>
                  <Accordion className='advanced-search' defaultActiveKey={'0'}>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Items</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col sm={6}>
                            <Row>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Item Code/Name</Form.Label>
                                  <FormControl
                                    type='text'
                                    onChange={(e) => setEnteredSearch(e.target.value)}
                                    className='bg-white'
                                    placeholder='Search '
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Sort By</Form.Label>
                                  <Form.Select
                                    value={groupchange}
                                    onChange={(e) => setGroupchange(e.target.value)}
                                  >
                                    <option value='' hidden>
                                      Select Item Group
                                    </option>
                                    {groups?.map((data) => (
                                      <option key={data.id} value={data.id}>
                                        {data.attributes.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div className='search-table mb-4'>
                  <Table data={tableData} columns={tableColumns} pagination={['BOTTOM']} />

                  {state.itemID?.attributes?.status === 'for issuance' ? (
                    <Row>
                      <Col>
                        <Form.Group className='mt-4 buttons-cont'>
                          <Button
                            onClick={() =>
                              setShowProfile((prev) => {
                                return {
                                  ...prev,
                                  isShowViewRequestModal: false,
                                  isShowForIssuanceModal: true,
                                };
                              })
                            }
                          >
                            Finalize Issuances
                          </Button>

                          <Button
                            className='clear-button'
                            onClick={() =>
                              setShowProfile((prev) => {
                                return {
                                  ...prev,
                                  isShowViewRequestModal: false,
                                };
                              })
                            }
                          >
                            Close
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <Form.Group className='mt-4 buttons-cont'>
                          <Button
                            className='clear-button'
                            onClick={() => {
                              setShowProfile((prev) => {
                                return {
                                  ...prev,
                                  isShowViewRequestModal: false,
                                };
                              });
                            }}
                          >
                            Close
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ViewRequestModal;
