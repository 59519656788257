/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import Select from 'react-select';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table, { PAGE_SIZE } from 'components/ReactTable';
import { Form, Row, Col, FormControl, InputGroup, Button } from 'react-bootstrap';
import { selectEmployees, getAllEmployees, deleteEmployeeById } from 'reducers/employeesReducer';

// Import assets below
import FormUI from 'components/UI/FormUI';
import ConfirmModal from './ConfirmDelete/ConfirmModal';

// Styles and Assets
import './index.scss';
import DefaultAvatar from 'assets/images/avatar.jpg';
import { ReactComponent as CirclePlus } from 'assets/svg/circle-plus-white.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// Queries
const params = {
  current_page: 1,
  per_page: PAGE_SIZE[10], // [10, 20, 30, 100]
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.37em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

const Employee = ({ setEmpData, setActiveScreen, setActiveTab }) => {
  const dispatch = useDispatch();

  const employees = useSelector(selectEmployees);

  const [query, setQuery] = useState({});
  const [openModal, setOpenModal] = useState({
    id: '',
    isOpen: false,
  });
  const [filteredData, setFilteredData] = useState(employees.data);

  const debouncedChangeHandler = React.useCallback(
    debounce((value, key) => {
      setQuery((prevState) => ({ ...prevState, [key]: value }));
    }, 300),
    [],
  );

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Avatar', accessor: 'avatar' },
      { Header: 'ID', accessor: 'eId' },
      { Header: 'Name', accessor: 'name' },
      // { Header: 'Position', accessor: 'position' },
      { Header: 'Department', accessor: 'department' },
      { Header: 'Contact Number', accessor: 'contact_number' },
      { Header: 'Company Email Address', accessor: 'company_email_address' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      filteredData?.map((dd) => ({
        avatar: (
          <img src={dd?.employee_detail.avatar || DefaultAvatar} height={50} width={50} />
        ),
        id: dd?.id,
        eId: dd?.employee_detail.eId,
        name: `${dd?.last_name}, ${dd?.first_name} ${dd?.middle_name}`,
        // position: dd?.employee_detail?.position,
        department: dd?.employee_detail?.department,
        contact_number: dd?.contact_number,
        company_email_address: dd?.employee_detail?.company_email,
      })),
    [filteredData],
  );

  const handleTableAction = (eventKey, id) => {
    const row = filteredData.find((dd) => dd.id === id);
    const action = {
      profile: () => {},
      view: () => {
        setEmpData(row);
        setActiveScreen(2);
        // setActiveTab('Employee Profile');
      },
      edit: () => {
        setEmpData(row);
        setActiveScreen(3);
        // setActiveTab('Employee Profile');
      },
      delete: () => {
        // dispatch(deleteEmployeeById(id));
        setOpenModal({
          id: id,
          isOpen: true,
        });
      },
    };

    action[eventKey]();
  };

  useEffect(() => {
    dispatch(getAllEmployees(params));
  }, []);

  useEffect(() => {
    if (query?.id_name || query?.department) {
      let _filteredData = employees.data;

      if (query?.department) {
        _filteredData = employees.data.filter((employee) =>
          employee?.attributes?.department.toLowerCase().includes(query.department.toLowerCase()),
        );
      }

      if (query?.id_name) {
        _filteredData = _filteredData.filter((employee) =>
          [
            employee?.attributes.id_no,
            employee?.attributes.first_name,
            employee?.attributes.middle_name,
            employee?.attributes.last_name,
          ]
            .join(' ')
            .toLowerCase()
            .includes(query.id_name.toLowerCase()),
        );
      }

      setFilteredData(_filteredData);
    } else setFilteredData(employees.data);
  }, [employees.data, query]);

  return (
    <div className='employee-tab mt-4'>
      <FormUI>
        <Row className='mb-4'>
          <Col>
            <Form.Group className='mb-3 basic-search'>
              <Form.Label>Search Employee</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-white'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='text'
                  className='bg-white'
                  placeholder='Search ID/name'
                  style={{ maxWidth: '100%' }}
                  onChange={(e) => debouncedChangeHandler(e.target.value, 'id_name')}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          {/* <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Position</Form.Label>
              <Select
                options={[
                  { value: 'dr', label: 'Dr.' },
                ]}
                // onChange={e => (handleInputSelectChange(e.value, 'conditions'))}
                placeholder="Select"
                isSearchable={false}
                styles={selectStyles}
              />
            </Form.Group>
          </Col> */}

          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Department</Form.Label>
              <FormControl
                type='text'
                className='bg-white'
                placeholder='Search department'
                style={{ maxWidth: '100%' }}
                onChange={(e) => debouncedChangeHandler(e.target.value, 'department')}
              />
              {/* <Select
                options={[
                  { value: 'dr', label: 'Dr.' },
                ]}
                // onChange={e => (handleInputSelectChange(e.value, 'conditions'))}
                placeholder="Select"
                isSearchable={false}
                styles={selectStyles}
              /> */}
            </Form.Group>
          </Col>

          <Col className='mb-3 d-flex justify-content-end align-items-end'>
            <Button
              onClick={() => {
                setActiveScreen(1);
                setActiveTab('Add New Employee');
                return;
              }}
              className='add-employee-btn'
            >
              <CirclePlus />
              <span>ADD EMPLOYEE</span>
            </Button>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Table
            data={tableData}
            columns={tableColumns}
            actions={{ hasView: true, hasEdit: true, hasDelete: true }}
            onAction={handleTableAction}
            pagination={['BOTTOM']}
            // pagination={{
            //   position: ['BOTTOM'],
            //   currentPage: params.current_page,
            //   pageSize: params.per_page,
            // }}
          />
        </Row>
      </FormUI>

      <ConfirmModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

Employee.defaultProps = {
  setActiveTab: () => {},
};

Employee.propTypes = {
  setActiveTab: PropTypes.func,
};

export default Employee;
