// Packages
import React, { useState } from 'react';

// Components
import PurchaseOrderList from './components/PurchaseOrderList';

// Assets
import './index.scss';

// Main Component
const GoodsReceipts = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    isShowProfile: false,
    profileId: null,
    showView: false,
    profileData: null,
    isShowViewRequestModal: false,
    viewItem: false,
  });

  return (
    <div className='goods-receipts'>
      <PurchaseOrderList
        state={state}
        setState={setState}
        showProfile={showProfile}
        setShowProfile={setShowProfile}
      />
    </div>
  );
};

export default GoodsReceipts;
