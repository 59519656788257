/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const ModalMedication = ({ showProfile, setShowProfile }) => {
  const dispatch = useDispatch();

  const patientProfile = useSelector(selectPatientItem);

  const [userData, setUserData] = useState({
    uom: '',
    doze: '',
    notes: '',
    quantity: 0,
    unit_price: 0,
    patientId: '',
    medication: '',
    total_amount: 0,
    route_of_administration: '',
    times_of_administration: '',
  });

  const handleChange = (key) => (e) => {
    const keys = ['quantity', 'unit_price'];

    if (keys.includes(key)) {
      setUserData({
        ...userData,
        [key]: e.target.value,
        total_amount: userData.quantity * userData.unit_price,
      });
      return true;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    // eslint-disable-next-line no-unreachable
    axios
      .post('/medication', userData)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error.message);
        } else {
          toast.success('Successfully added medication!');
          setUserData({
            uom: '',
            doze: '',
            notes: '',
            quantity: 0,
            unit_price: 0,
            medication: '',
            total_amount: 0,
            route_of_administration: '',
            times_of_administration: '',
            patientId: patientProfile.id,
          });

          dispatch(showPatient(showProfile.profileId));
        }
      })
      .catch((error) => {
        error.response.data.errors.map((item) => {
          toast.warning(item.msg + ' ' + item.param);
        });
      });
  };

  useEffect(() => {
    setUserData({
      ...userData,
      total_amount: userData?.quantity * userData?.unit_price,
    });
  }, []);

  useEffect(() => {
    if (patientProfile) {
      setUserData({ ...userData, patientId: patientProfile.id });
    }
  }, [patientProfile]);

  return (
    <Modal
      size='lg'
      show={showProfile.medication}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            medication: false,
          };
        })
      }
      aria-labelledby='Lab Request Modal'
      className='add-lab-request-modal'
    >
      <Modal.Body>
        <div className='add-lab-request'>
          <div className='d-flex header'>
            <p>Medication</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowProfile((prevState) => {
                  return { ...prevState, medication: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='lab-request-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Medication</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.medication}
                    onChange={handleChange('medication')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Doze</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.doze}
                    onChange={handleChange('doze')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Route of Administration</Form.Label>
                  <Form.Select
                    onChange={handleChange('route_of_administration')}
                    value={userData.route_of_administration}
                  >
                    <option hidden>-Select-</option>
                    <option value='Ext - External use'>Ext - External use</option>
                    <option value='PR - Per Rectum'>PR - Per Rectum</option>
                    <option value='IM - Intramuscular'>IM - Intramuscular</option>
                    <option value='PV - Per Vagina'>PV - Per Vagina</option>
                    <option value='Inh - Inhalation'>Inh - Inhalation</option>
                    <option value='SC - Subcutaneous'>SC - Subcutaneous</option>
                    <option value='IV - Intravenous'>IV - Intravenous</option>
                    <option value='SL - Sublingual'>SL - Sublingual</option>
                    <option value='Neb - Nebulisation'>Neb - Nebulisation</option>
                    <option value='Top - Topical, indicating the specific area or eye/ear'>
                      Top - Topical, indicating the specific area or eye/ear
                    </option>
                    <option value='O/PO - By Mouth'>O/PO - By Mouth</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Times of Administration</Form.Label>
                  <Form.Select
                    onChange={handleChange('times_of_administration')}
                    value={userData.times_of_administration}
                  >
                    <option hidden>-Select-</option>
                    <option value='OD - Once a day'>OD - Once a day</option>
                    <option value='OM - Each morning'>OM - Each morning</option>
                    <option value='ON - Each night'>ON - Each night</option>
                    <option value='BD - Twice daily'>BD - Twice daily</option>
                    <option value='TDS - Three times daily'>TDS - Three times daily</option>
                    <option value='QDS - Four times daily'>QDS - Four times daily</option>
                    <option value='Mane - Morning'>Mane - Morning</option>
                    <option value='Nocte - At bedtime'>Nocte - At bedtime</option>
                    <option value='PRN - As required (with indication and interval stated)'>
                      PRN - As required (with indication and interval stated)
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Unit of Measure1</Form.Label>

                  <Form.Select value={userData.oum} onChange={handleChange('uom')}>
                    <option hidden>-Select-</option>
                    <option value='g'>g(gram)</option>
                    <option value='mg'>mg(miligram)</option>
                    <option value='mcg'>mcg(microgram)</option>
                    <option value='u'>U(unit)</option>
                    <option value='TU'>TU(thousand units)</option>
                    <option value='MU'>MU(million units)</option>
                    <option value='mmol'>mmol(millimole)</option>
                    <option value='ml'>ml(milliliter(e.g. eyedrops)</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Unit Price</Form.Label>
                  <FormControl
                    type='number'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.unit_price}
                    onChange={handleChange('unit_price')}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Quantity</Form.Label>
                  <FormControl
                    type='number'
                    className='bg-white'
                    placeholder='Enter Here'
                    // onKeyUp={console.log('aaa')}
                    onKeyDown={(evt) => {
                      evt.key === 'e' && evt.preventDefault();
                      evt.key === '-' && evt.preventDefault();
                      evt.key === '+' && evt.preventDefault();
                    }}
                    value={userData.quantity}
                    onChange={handleChange('quantity')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Total Amount</Form.Label>
                  <FormControl
                    type='text'
                    disabled
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.quantity * userData.unit_price}
                    onChange={handleChange('total_amount')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Notes</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={2}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.notes}
                    onChange={handleChange('notes')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() =>
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          medication: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ModalMedication.defaultProps = {
  showProfile: () => { },
  setShowProfile: () => { },
};

ModalMedication.propTypes = {
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default ModalMedication;
