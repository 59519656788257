/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// main components below
import InventoryListTable from './Table';
import SearchInventoryFilter from './Filter';

import './style.scss';

// main component
const ItemInventory = ({ departmentID }) => {
  const [searchData, setSearchData] = useState({
    search: '',
  });

  return (
    <div className='admissions-inventory-tab-inventory-items'>
      <SearchInventoryFilter
        searchData={searchData}
        departmentID={departmentID}
        setSearchData={setSearchData}
      />

      <InventoryListTable
        searchData={searchData}
        departmentID={departmentID}
        setSearchData={setSearchData}
      />
    </div>
  );
};

ItemInventory.defaultProps = {
  departmentID: '',
};

ItemInventory.propTypes = {
  departmentID: PropTypes.string,
};

export default ItemInventory;
