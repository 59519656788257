/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import uuidv4 from 'react-uuid';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { useDispatch } from 'react-redux';
import { showPatient } from 'reducers/patientReducer';

// import assets below
import './index.scss';
import styles from './index.module.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main componet
const PhysicalExaminationModal = ({
  showProfile,
  openPhysicalExamination,
  setOpenPhysicalExamination,
}) => {
  const dispatch = useDispatch();

  const [stateData, setStateData] = useState();

  const closeModal = () => {
    setOpenPhysicalExamination({ isOpen: false, modalValue: null });
  };

  const handleChange = (e, type) => {
    const { name, value, checked } = e.target;

    if (name === 'generalSurvey') {
      setStateData((prev) => {
        return {
          ...prev,
          [name]: value,
          alteredSensorium: '',
        };
      });

      return;
    }

    setStateData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, type } = e.target;

    setStateData((prev) => {
      return {
        ...prev,
        [name]: checked,
      };
    });
  };

  const handleUpdate = () => {
    const { general_survey, bp, hr, rr, temp, height, weight } = stateData;

    if (!general_survey || !bp || !hr || !rr || !temp || !height || !weight) {
      toast.warning('Please fill in the required fields');
      return;
    }

    axios
      .put(`/physical_exam/${stateData?.id}`, stateData)
      .then((response) => {
        axios.get('/user/pending').then((response) => {
          toast.success('Success');
          dispatch(showPatient(showProfile?.profileId));

          setTimeout(() => {
            closeModal();
          }, [2000]);
        }, []);
      })
      .catch((err) => {
        console.log('ERROR', err);
        toast.error('Something went wrong. Please refresh the page');
      });
  };

  useEffect(() => {
    console.log('openPhysicalExamination', openPhysicalExamination);
    if (openPhysicalExamination) {
      setStateData(openPhysicalExamination?.modalValue);
    }
  }, [openPhysicalExamination]);

  return (
    <Modal
      size='lg'
      className='view-physical-examination-modal'
      show={openPhysicalExamination.isOpen}
      onHide={() => {
        closeModal();
      }}
    >
      <Modal.Body>
        <div className='physical-examination'>
          <div className='d-flex header'>
            <p>Physical Examination on Admission</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='physical-examination-form'>
            <Row>
              <Col>
                <div className={styles['note-container']}>
                  {/* First Row Header */}
                  <div className={styles['note-row']}>
                    <div className={styles['row-header']}>
                      <h3>General Survey *</h3>
                    </div>
                  </div>

                  {/* First Row Body */}
                  <div className={styles['note-row']}>
                    <div className={`${styles['note-column']} ${styles['flex-3']}`}>
                      <Form.Group className={styles['note-data-inline']}>
                        <Form.Check
                          type='radio'
                          name='general_survey'
                          value='Awake and alert'
                          label='Awake and alert'
                          checked={stateData?.general_survey === 'Awake and alert'}
                          onChange={(e) => {
                            handleChange(e, 'Awake and alert');
                          }}
                        />

                        <div className={styles['inline-check-with-input']}>
                          <Form.Check
                            type='radio'
                            name='general_survey'
                            label='Altered sensorium'
                            value='Altered sensorium'
                            checked={stateData?.general_survey === 'Altered sensorium'}
                            onChange={(e) => {
                              handleChange(e, 'Altered sensorium');
                            }}
                          />

                          <Form.Group className={styles['input-no-border']}>
                            <FormControl
                              placeholder='...'
                              name='altered_sensorium'
                              onChange={handleChange}
                              value={stateData?.altered_sensorium}
                              disabled={stateData?.general_survey !== 'Altered sensorium'}
                            />
                          </Form.Group>
                        </div>
                      </Form.Group>
                    </div>
                    <div className={`${styles['note-column-last']} ${styles['flex-1']}`}>
                      <Form.Group
                        className={`mb-2 ${styles['inline-group']} ${styles['note-data']}`}
                      >
                        <Form.Label>Weight (kg)</Form.Label>
                        <FormControl
                          name='weight'
                          type='number'
                          placeholder='...'
                          value={stateData?.weight}
                          onChange={handleChange}
                          className={styles['note-input']}
                        />
                      </Form.Group>

                      <Form.Group className={styles['inline-group']}>
                        <Form.Label>Height (cm)</Form.Label>
                        <FormControl
                          name='height'
                          placeholder='...'
                          onChange={handleChange}
                          value={stateData?.height}
                          className={styles['note-input']}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  {/* Second Row Header */}
                  <div className={styles['note-row']}>
                    <div className={styles['row-header']}>
                      <h3>Vital Signs *</h3>
                    </div>
                  </div>

                  {/* Second Row Body */}
                  <div className={styles['note-row']}>
                    <div className={styles['note-column']}>
                      <Form.Group className={styles['note-data']}>
                        <Form.Label>BP</Form.Label>
                        <FormControl
                          name='bp'
                          placeholder='...'
                          value={stateData?.bp}
                          onChange={handleChange}
                          className={styles['note-input']}
                        />
                      </Form.Group>
                    </div>
                    <div className={styles['note-column']}>
                      <Form.Group className={styles['note-data']}>
                        <Form.Label>HR</Form.Label>
                        <FormControl
                          name='hr'
                          placeholder='...'
                          value={stateData?.hr}
                          s
                          onChange={handleChange}
                          className={styles['note-input']}
                        />
                      </Form.Group>
                    </div>
                    <div className={styles['note-column']}>
                      <Form.Group className={styles['note-data']}>
                        <Form.Label>RR</Form.Label>
                        <FormControl
                          name='rr'
                          placeholder='...'
                          value={stateData?.rr}
                          onChange={handleChange}
                          className={styles['note-input']}
                        />
                      </Form.Group>
                    </div>
                    <div className={styles['note-column-last']}>
                      <Form.Group className={styles['note-data']}>
                        <Form.Label>Temp°C</Form.Label>
                        <FormControl
                          name='temp'
                          type='number'
                          placeholder='...'
                          value={stateData?.temp}
                          onChange={handleChange}
                          className={styles['note-input']}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  {/* Third Row Header */}
                  <div className={styles['note-row']}>
                    <div className={styles['row-header']}>
                      <h3>HEENT</h3>
                    </div>
                  </div>

                  {/* Third Row Body */}
                  <div className={styles['note-row']}>
                    <div className={styles['note-column-last']}>
                      <Form.Group className={styles['checkbox-inline-group']}>
                        <Form.Check
                          type='checkbox'
                          label='Essentially normal'
                          onChange={handleCheckboxChange}
                          name='essentially_normal_heent'
                          className={styles['form-check']}
                          checked={stateData?.essentially_normal_heent === true}
                        />

                        <Form.Check
                          type='checkbox'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          name='abnormal_pupillary_reaction'
                          label='Abnormal pupillary reaction'
                          checked={stateData?.abnormal_pupillary_reaction === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Cervical lymphadenopathy'
                          onChange={handleCheckboxChange}
                          name='cervical_lymphadenopathy'
                          className={styles['form-check']}
                          checked={stateData?.cervical_lymphadenopathy === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Dry mucous membrane'
                          name='dry_mucous_membrane'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.dry_mucous_membrane === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Icteric sclerae'
                          name='icteric_sclerae'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.icteric_sclerae === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Pale conjunctivae'
                          name='pale_conjunctivae'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.pale_conjunctivae === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Sunken eyeballs'
                          name='sunken_eyeballs'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.sunken_eyeballs === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Sunken fontanelle'
                          name='sunken_fontanelle'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.sunken_fontanelle === true}
                        />

                        <Form.Group
                          className={`${styles['inline-group']} ${styles['other-group']}`}
                        >
                          <Form.Label>Others: </Form.Label>
                          <FormControl
                            type='text'
                            placeholder='...'
                            name='heent_others'
                            onChange={handleChange}
                            value={stateData?.heent_others}
                            className={styles['note-input']}
                          />
                        </Form.Group>
                      </Form.Group>
                    </div>
                  </div>

                  {/* Fourth Row Header */}
                  <div className={styles['note-row']}>
                    <div className={styles['row-header']}>
                      <h3>CHEST/LUNGS</h3>
                    </div>
                  </div>

                  {/* Fourth Row Body */}
                  <div className={styles['note-row']}>
                    <div className={styles['note-column-last']}>
                      <Form.Group className={styles['checkbox-inline-group']}>
                        <Form.Check
                          type='checkbox'
                          label='Essentially normal'
                          name='essentially_normal_chest_lungs'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.essentially_normal_chest_lungs === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Asymmetrical chest expansion'
                          name='asymmetrical_chest_expansion'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.asymmetrical_chest_expansion === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Decreased breath sounds'
                          name='decreased_breath_sounds'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.decreased_breath_sounds === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Wheezes'
                          name='wheezes'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.wheezes === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Lump/s over breast(s)'
                          name='lump_over_breast'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.lump_over_breast === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Rales/crackles/rhonchi'
                          name='rales_crackles_rhonchi'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.rales_crackles_rhonchi === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Intercostal rib/clavicular retraction'
                          name='intercostal_rib_clavicular_retraction'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.intercostal_rib_clavicular_retraction === true}
                        />

                        <Form.Group
                          className={`${styles['inline-group']} ${styles['other-group']}`}
                        >
                          <Form.Label>Others: </Form.Label>
                          <FormControl
                            type='text'
                            placeholder='...'
                            name='chest_lungs_others'
                            onChange={handleChange}
                            className={styles['note-input']}
                            value={stateData?.chest_lungs_others}
                          />
                        </Form.Group>
                      </Form.Group>
                    </div>
                  </div>

                  {/* Fifth Row Header */}
                  <div className={styles['note-row']}>
                    <div className={styles['row-header']}>
                      <h3>CJS</h3>
                    </div>
                  </div>

                  {/* Fifth Row Body */}
                  <div className={styles['note-row']}>
                    <div className={styles['note-column-last']}>
                      <Form.Group className={styles['checkbox-inline-group']}>
                        <Form.Check
                          type='checkbox'
                          label='Essentially normal'
                          name='essentially_normal_cjs'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.essentially_normal_cjs === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Displaced apex beat'
                          name='displaced_apex_beat'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.displaced_apex_beat === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Heaves and/or thrills'
                          name='heaves_or_thrills'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.heaves_or_thrills === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Pericardia! bulge'
                          name='pericardia_bulge'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.pericardia_bulge === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Irregular rhythm'
                          name='pericardia_bulge'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.pericardia_bulge === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Muffled heart sounds'
                          name='muffled_heart_sound'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.muffled_heart_sound === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Murmur'
                          name='murmur'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.murmur === true}
                        />

                        <Form.Group
                          className={`${styles['inline-group']} ${styles['other-group']}`}
                        >
                          <Form.Label>Others: </Form.Label>
                          <FormControl
                            type='text'
                            placeholder='...'
                            name='cjs_others'
                            onChange={handleChange}
                            className={styles['note-input']}
                            value={stateData?.cjs_others}
                          />
                        </Form.Group>
                      </Form.Group>
                    </div>
                  </div>

                  {/* Sixth Row Header */}
                  <div className={styles['note-row']}>
                    <div className={styles['row-header']}>
                      <h3>ABDOMEN</h3>
                    </div>
                  </div>

                  {/* Sixth Row Body */}
                  <div className={styles['note-row']}>
                    <div className={styles['note-column-last']}>
                      <Form.Group className={styles['checkbox-inline-group']}>
                        <Form.Check
                          type='checkbox'
                          label='Essentially normal'
                          name='essentially_normal_abdomen'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.essentially_normal_abdomen === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Abdominal rigidity'
                          name='abdominal_rigidity'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.abdominal_rigidity === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Abdomen tenderness'
                          name='abdomen_tenderness'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.abdomen_tenderness === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Hyperactive bowel sounds'
                          name='hyperactive_bowel_sounds'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.hyperactive_bowel_sounds === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Palpable mass(es)'
                          name='palpable_mass'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.palpable_mass === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Tympanitic/dull abdomen'
                          name='tympanitic_dull_abdomen'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.tympanitic_dull_abdomen === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Uterine contraction'
                          name='uterine_contraction'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.uterine_contraction === true}
                        />

                        <Form.Group
                          className={`${styles['inline-group']} ${styles['other-group']}`}
                        >
                          <Form.Label>Others: </Form.Label>
                          <FormControl
                            type='text'
                            placeholder='...'
                            name='abdomen_others'
                            onChange={handleChange}
                            className={styles['note-input']}
                            value={stateData?.abdomen_others}
                          />
                        </Form.Group>
                      </Form.Group>
                    </div>
                  </div>

                  {/* Seventh Row Header */}
                  <div className={styles['note-row']}>
                    <div className={styles['row-header']}>
                      <h3>GU (IE)</h3>
                    </div>
                  </div>

                  {/* Seventh Row Body */}
                  <div className={styles['note-row']}>
                    <div className={styles['note-column-last']}>
                      <Form.Group className={styles['checkbox-inline-group']}>
                        <Form.Check
                          type='checkbox'
                          label='Essentially normal'
                          name='essentially_normal_gu'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.essentially_normal_gu === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Blood stained in exam finger'
                          name='blood_stained_in_exam_finger'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.blood_stained_in_exam_finger === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Cervical dilatation'
                          name='cervical_dilatation'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.cervical_dilatation === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Presence of abnormal discharge'
                          name='presense_of_abnormal_discharge'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.presense_of_abnormal_discharge === true}
                        />

                        <Form.Group
                          className={`${styles['inline-group']} ${styles['other-group']}`}
                        >
                          <Form.Label>Others: </Form.Label>
                          <FormControl
                            type='text'
                            placeholder='...'
                            name='gu_others'
                            onChange={handleChange}
                            className={styles['note-input']}
                            value={stateData?.gu_others}
                          />
                        </Form.Group>
                      </Form.Group>
                    </div>
                  </div>

                  {/* Eight Row Header */}
                  <div className={styles['note-row']}>
                    <div className={styles['row-header']}>
                      <h3>SKIN/EXTREMITIES</h3>
                    </div>
                  </div>

                  {/* Eight Row Body */}
                  <div className={styles['note-row']}>
                    <div className={styles['note-column-last']}>
                      <Form.Group className={styles['checkbox-inline-group']}>
                        <Form.Check
                          type='checkbox'
                          label='Essentially normal'
                          name='essentially_normal_skin'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.essentially_normal_skin === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Clubbing'
                          name='clubbing'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.clubbing === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Cold clammy skin'
                          name='cold_clammy_skin'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.cold_clammy_skin === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Cyanosis/mottled skin'
                          name='cyanosis_mottled_skin'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.cyanosis_mottled_skin === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Edema/swelling'
                          name='edema_swelling'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.edema_swelling === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Decreased mobility'
                          name='decreased_mobility'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.decreased_mobility === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Pale nailbeds'
                          name='pale_nailbeds'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.pale_nailbeds === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Poor skin turgor'
                          name='poor_skin_turgor'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.poor_skin_turgor === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Rashes/petechiae'
                          name='rashes_petechiae'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.rashes_petechiae === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Weak pulses'
                          name='weak_pulses'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.weak_pulses === true}
                        />

                        <Form.Group
                          className={`${styles['inline-group']} ${styles['other-group']}`}
                        >
                          <Form.Label>Others: </Form.Label>
                          <FormControl
                            type='text'
                            placeholder='...'
                            name='skin_others'
                            onChange={handleChange}
                            className={styles['note-input']}
                            value={stateData?.skin_others}
                          />
                        </Form.Group>
                      </Form.Group>
                    </div>
                  </div>

                  {/* Ninth Row Header */}
                  <div className={styles['note-row']}>
                    <div className={styles['row-header']}>
                      <h3>NEURO·EXAM</h3>
                    </div>
                  </div>

                  {/* Ninth Row Body */}
                  <div className={styles['note-row']}>
                    <div className={styles['note-column-last']}>
                      <Form.Group className={styles['checkbox-inline-group']}>
                        <Form.Check
                          type='checkbox'
                          label='Essentially normal'
                          name='essentially_normal_neuro'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.essentially_normal_neuro === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Abnormal gait'
                          name='abnormal_gait'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.abnormal_gait === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Abnormal position sense'
                          name='abnormal_position_sense'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.abnormal_position_sense === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Abnormal/decreased sensation'
                          name='abnormal_decreased_sensation'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.abnormal_decreased_sensation === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Abnormal reflex(es)'
                          name='abnormal_reflex'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.abnormal_reflex === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Poor/altered memory'
                          name='poor_altered_memory'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.poor_altered_memory === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Poor muscle tone/strength'
                          name='poor_muscle_tone_strength'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.poor_muscle_tone_strength === true}
                        />

                        <Form.Check
                          type='checkbox'
                          label='Poor coordination'
                          name='poor_coordination'
                          onChange={handleCheckboxChange}
                          className={styles['form-check']}
                          checked={stateData?.poor_coordination === true}
                        />

                        <Form.Group
                          className={`${styles['inline-group']} ${styles['other-group']}`}
                        >
                          <Form.Label>Others: </Form.Label>
                          <FormControl
                            type='text'
                            placeholder='...'
                            name='neuro_others'
                            onChange={handleChange}
                            className={styles['note-input']}
                            value={stateData?.neuro_others}
                          />
                        </Form.Group>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button className='add-button' variant='primary' onClick={handleUpdate}>
                    Update
                  </Button>
                  <Button className='cancel-button' vvariant='secondary' onClick={closeModal}>
                    Close
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default PhysicalExaminationModal;
