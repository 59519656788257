import { citiesAPI } from './cities';
import { regionAPI } from './regions';
import { provincesAPI } from './provinces';
import { barangaysAPI } from './barangays';

/* eslint-disable camelcase */
export const regions = () => {
  try {
    const regions = regionAPI;
    return regions.map((region) => {
      return {
        id: region.id,
        psgc_code: region.psgc_code,
        region_name: region.region_name,
        region_code: region.region_code,
      };
    });
  } catch (e) {
    return e.message;
  }
};

/**
 * @param REGION_CODE
 * @returns region base on region code parameter.
 */
export const regionByCode = (code) => {
  try {
    const regions = regionAPI;
    return regions.find(({ region_code }) => region_code === code);
  } catch (e) {
    return e.message;
  }
};

/** Default
 * @param REGION_CODE
 * @returns all provinces base on region code parameter.
 */
export const provinces = (code) => {
  try {
    const provinces = provincesAPI;
    return provinces
      .filter((province) => province.region_code === code)
      .map((filtered) => {
        return {
          psgc_code: filtered.psgc_code,
          province_name: filtered.province_name,
          province_code: filtered.province_code,
          region_code: filtered.region_code,
        };
      });
  } catch (e) {
    return e.message;
  }
};

/**
 * @param REGION_CODE
 * @returns all provinces base on region code parameter.
 */
export const provincesByCode = (code) => {
  try {
    const provinces = provincesAPI;
    return provinces
      .filter((province) => province.region_code === code)
      .map((filtered) => {
        return {
          psgc_code: filtered.psgc_code,
          province_name: filtered.province_name,
          province_code: filtered.province_code,
          region_code: filtered.region_code,
        };
      });
  } catch (e) {
    return e.message;
  }
};

/**
 * @param PROVINCE_NAME
 * @returns province base on province name parameter.
 */
export const provinceByName = (name) => {
  try {
    const provinces = provincesAPI;
    return provinces.find(({ province_name }) => province_name === name);
  } catch (e) {
    return e.message;
  }
};

/**
 * @param PROVINCE_CODE
 * @returns all cities base on province code parameter.
 */
export const cities = (code) => {
  try {
    const cities = citiesAPI;
    return cities
      .filter((city) => city.province_code === code)
      .map((filtered) => {
        return {
          city_name: filtered.city_name,
          city_code: filtered.city_code,
          province_code: filtered.province_code,
          region_desc: filtered.region_desc,
        };
      });
  } catch (e) {
    return e.message;
  }
};

/**
 * @param CITY_CODE
 * @returns all barangays base on city code parameter.
 */
export const barangays = (code) => {
  try {
    const barangays = barangaysAPI;
    return barangays
      .filter((barangay) => barangay.city_code === code)
      .map((filtered) => {
        return {
          brgy_name: filtered.brgy_name,
          brgy_code: filtered.brgy_code,
          province_code: filtered.province_code,
          region_code: filtered.region_code,
        };
      });
  } catch (e) {
    return e.message;
  }
};
