// import assets below
import './style.scss';

// main component
const ModalUI = (props) => {
  const { children, customClass = '' } = props;

  return <div className={`modalUI ${customClass}`}>{children}</div>;
};

export default ModalUI;
