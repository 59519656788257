// Roles and Departments

const ACLConstants = {
  ALLUSERS: 'All',
  PATIENTCARE: 'Patient Care Management',
  NEWUSERSPATIENTCARE: 'New Users Patient Care Management',
  DENIEDUSERSPATIENTCARE: 'Denied Users Patient Care Management',
  NEWUSERSPROPERTY: 'New Users Property Management',
  DENIEDUSERSPROPERTY: 'Denied Users Property Management',
  NEWUSERSACCOUNTING: 'New Users Accounting Management',
  NEWUSERSHR: 'New Users HR Management',
  DENIEDHR: 'Denied Users HR Management',
  NEWUSERSHOSPITAL: 'New Users Hospital Management',
  PROPERTY: 'Property Management',
  ACCOUNTING: 'Accounting Management',
  DENIEDUSERSACCOUNTING: 'Denied Users Accounting Management',
  DENIEDHOSPITAL: 'Denied Users Hospital Management',
  HR: 'HR Management',
  HOSPITAL: 'Hospital Management',
  ADMISSION: 'Admission',
  DOCTORS: 'Doctors',
  NURSINGSERVICES: 'Nursing Services',
  ROOMBEDMANAGEMENT: 'Room/Bed Management',
  ERDEPARTMENT: 'ER Department',
  OPDEPARTMENT: 'OP Department',
};

export default ACLConstants;
