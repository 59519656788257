/* eslint-disable */
// import core & vendor packages below
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

// Components
import Table from 'components/ReactTable';
import ConditionalRender from 'components/ConditionalRender';
import { selectborrowReturn } from 'reducers/borrowReturnReducer';
import { PrintContent } from '../../../PrintContent';

// Modals
import ViewItemBorrowModal from './ViewItemBorrowModal';

// Assets below
import './Table.scss';

// main component
const InventoryListTable = ({ getInventoryRequestList }) => {
  const borrowReturnState = useSelector(selectborrowReturn);

  const { data: inventoryData, items: inventoryItems, logs } = borrowReturnState;

  const [showModal, setShowModal] = useState(false);

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page { size: portrait }'
  });

  const getGroupName = (id) => {
    if (!inventoryItems || inventoryItems.length === 0) return;

    const selectedArray = inventoryItems.filter(
      (data) => parseInt(data.id) === parseInt(id) && data?.type === 'groups',
    );

    if (selectedArray.length === 0) {
      return 'N/A';
    }

    return selectedArray[0]?.attributes?.name;
  };

  useEffect(() => {
    getInventoryRequestList();
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Control #', accessor: 'control_number' },
      { Header: 'Date/Time Borrowed', accessor: 'date_time_borrowed' },
      { Header: 'Item Code', accessor: 'item_code' },
      { Header: 'Item Name', accessor: 'item_name' },
      { Header: 'Item Group', accessor: 'item_group' },
      { Header: 'Borrowed by', accessor: 'borrowed_by' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      inventoryData.map((dd) => {
        // Get itemID
        const itemID = dd?.relationships?.item?.data[0]?.id;

        // Get item Data
        const itemData = inventoryItems.filter(
          (data) => parseInt(data.id) === parseInt(itemID) && data?.type === 'items',
        );

        // Get Group ID
        const groupID = itemData[0]?.attributes?.group_id;

        return {
          id: dd.id,
          control_number: dd?.attributes?.control_number,
          date_time_borrowed: moment(dd?.borrowed_at).format('MMM-DD-YYYY hh:mm A'),
          item_code: itemData[0]?.attributes?.code,
          item_name: itemData[0]?.attributes?.name,
          item_group: getGroupName(groupID),
          borrowed_by: dd?.attributes?.borrowed_by,
          status: (
            <span className={`badge status ${dd?.attributes?.status}`}>
              {dd?.attributes?.status}
            </span>
          ),
          status_text: dd?.attributes?.status,
        };
      }),
    [inventoryData],
  );

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent 
          ref={printRef} 
          tableCol={tableColumns} 
        >
          {tableData?.length 
            ? tableData?.map((data) => (
                <tr key={data?.id}>
                    <td>{data?.control_number}</td>
                    <td>{data?.date_time_borrowed}</td>
                    <td>{data?.item_code}</td>
                    <td>{data?.item_name}</td>
                    <td>{data?.item_group}</td>
                    <td>{data?.borrowed_by}</td>
                    <td>{data?.status_text}</td>
                </tr>
            )) : (
                <tr>
                    <td style={{ textAlign: 'center' }} colSpan={7}>No Record Found</td>
                </tr>
            )
          }
        </PrintContent>
      </div>
      <div className='admissions-inventory-list-table'>
        <Row>
          <Col className='table-header'>
            <h4 className='mb-0 fw-bold mt-4 mb-2'>Returned Item</h4>
            <Button className='print-button' onClick={handlePrint}>
              Print
            </Button>
          </Col>
        </Row>
        <Table
          ref={printRef}
          logs={logs}
          data={tableData}
          columns={tableColumns}
          customAction={(id) => {
            const row = tableData.find((dd) => dd.id === id);
            const { status_text } = row;

            const buttons = [
              {
                text: 'View',
                color: 'blue',
                action: () => {
                  setShowModal((prevState) => {
                    return {
                      ...prevState,
                      data: row,
                      viewItem: true,
                    };
                  });
                },
                condition:
                  status_text === 'returned' ||
                  status_text === 'for return' ||
                  status_text === 'completed',
              },
            ];

            return (
              <div className='action-buttons'>
                {buttons.map((buttonData) => {
                  return (
                    <ConditionalRender
                      condition={buttonData?.condition}
                      renderIf={
                        <span
                          className={`badge ${buttonData?.color}`}
                          style={{ cursor: 'pointer' }}
                          onClick={buttonData?.action}
                        >
                          {buttonData?.text}
                        </span>
                      }
                    />
                  );
                })}
              </div>
            );
          }}
          pagination={['BOTTOM']}
        />
      </div>

      {/* Modals */}
      <ViewItemBorrowModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

InventoryListTable.defaultProps = {
  searchData: {},
  departmentID: '',
};

InventoryListTable.propTypes = {
  searchData: PropTypes.object,
  departmentID: PropTypes.string,
};

export default InventoryListTable;
