/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getOvertimesList = createAsyncThunk(
  'hr/getOvertimesList',
  async (filter = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: filter,
      url: `employee/overtimes`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
    },
);