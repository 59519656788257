/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// import components below
import FormUI from 'components/UI/FormUI';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import PatientProfileTabs from 'components/FormPatientProfile/components/Tabs.jsx';

// import assets below
import './style.scss';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark2.svg';

// main component
const FormPatientProfile = ({ showProfile, setShowProfile }) => {
  const patientProfile = useSelector(selectPatientItem);

  const [disabled, setDisabled] = useState(false);

  const referToER = (patientId) => {
    const params = {
      patient_status: 'for_admission',
      id: showProfile.profileId,
    };

    axios
      .put('/patient', params)
      .then((response) => {
        toast.warning('Patient have been referred to Admission');
        setDisabled(true);
        // setTimeout(() => {
        //   setShowProfile({ isShowProfile: false, profileId: null });
        // }, 2000);
        setShowProfile({ isShowProfile: false, profileId: null });
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  renderOnShowProfileForm(showProfile);

  return (
    <div className='NursingPatientProfile'>
      <div className='d-flex justify-content-end'>
        {patientProfile?.patient_status === 'new' ||
          (patientProfile?.patient_status === 'emergency' && (
            <Button disabled={disabled} onClick={() => referToER()} className='btn btn-click me-3'>
              <small>REFER TO ADMISSION</small>
            </Button>
          ))}
        <Button
          onClick={() => {
            setShowProfile((prevState) => {
              return {
                ...prevState,
                fileUpload: true,
              };
            });
          }}
          className='btn btn-click me-3'
        >
          <small>UPLOAD FILES</small>
        </Button>
        <CircleXMark
          style={{ width: '2em', cursor: 'pointer' }}
          onClick={() => setShowProfile({ isShowProfile: false, profileId: null })}
        />
      </div>
      <FormUI>
        <div className='avatar__container'>
          <div className={'d-flex align-items-center'}>
            {patientProfile?.id && (
              <QRCode
                size={150}
                style={{ height: 'auto', marginRight: '10px' }}
                value={`${patientProfile?.id}`}
              />
            )}
            {patientProfile?.filename ? (
              <div className='svg__container'>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}/files/${patientProfile?.filename}`}
                  style={{ 'border-radius': '50%', 'width': '100%', 'height': '100%' }}
                ></img>
              </div>
            ) : (
              <div className='svg__container'>
                <UserSVG />
              </div>
            )}
          </div>
          <h2 className='mt-4 text-uppercase'>
            <span className='patient__lastName fw-bold'>{patientProfile?.last_name}</span>,{' '}
            {patientProfile?.first_name}
          </h2>
          <div className='patient__details'>
            <h5 className='patient__number mt-1'>
              <span>Patient ID:</span> {patientProfile?.id}
            </h5>
            {patientProfile?.is_newborn && <span className='patient__newborn mt-1'>New Born</span>}
          </div>
          <div className='patient__info mt-1'>
            <span>
              {patientProfile?.age} / {patientProfile?.sex}
            </span>
            <span>{moment(patientProfile?.date_of_birth).format('MMMM-DD-YYYY')}</span>
          </div>
        </div>
        <div className='profile_buttons d-flex justify-content-center'>
          {patientProfile?.patient_status !== 'for_discharge' && (
            <Button
              onClick={() => {
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    labRequest: true,
                  };
                });
              }}
              className='btn btn-click me-3'
            >
              <small> DIAGNOSTIC REQUEST</small>
            </Button>
          )}

          {patientProfile?.patient_status !== 'for_discharge' && (
            <Button
              onClick={() => {
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    addOrder: true,
                  };
                });
              }}
              className='btn btn-click me-3'
            >
              <small> Add Order</small>
            </Button>
          )}

          {patientProfile?.patient_status !== 'for_discharge' && (
            <Button
              onClick={() => {
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    procedure: true,
                  };
                });
              }}
              className='btn btn-click me-3'
            >
              <small>Procedure</small>
            </Button>
          )}

          {patientProfile?.patient_status !== 'for_discharge' && (
            <Button
              onClick={() => {
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    medication: true,
                  };
                });
              }}
              className='btn btn-click me-3'
            >
              <small> Emergency Medication</small>
            </Button>
          )}

          {/* {patientProfile?.patient_status !== 'for_discharge' && (
            <ConditionalRender
              condition={canRequest}
              renderIf={
                <Button
                  onClick={() => {
                    setShowProfile((prevState) => {
                      return {
                        ...prevState,
                        supply: true,
                      };
                    });
                  }}
                  className='btn btn-click me-3'
                >
                  <small> Request for Supply</small>
                </Button>
              }
            />
          )} */}

          {patientProfile?.patient_status !== 'for_discharge' && (
            <Button
              onClick={() => {
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    note: true,
                  };
                });
              }}
              className='btn btn-click me-3'
            >
              <small> Add Note</small>
            </Button>
          )}
        </div>
        <div className='formPatientProfile__banner'>
          <Row>
            <Col lg='3'>
              <div className='p-3'>
                <h6>Allergies:</h6>
                <div className='d-flex flex-column'>
                  {patientProfile?.patient_allergies.length > 0 ? (
                    patientProfile?.patient_allergies?.map((item) => {
                      return <span className='fw-bold'> {item?.allergy}</span>;
                    })
                  ) : (
                    <span className='fw-bold'>N/A</span>
                  )}
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <div className='d-flex flex-column align-items-between h-100 p-3'>
                <Row>
                  <Col>
                    <div className='mt-2'>
                      <span>Location: </span>{' '}
                      <span className='fw-bold'>
                        {patientProfile?.assigned_room
                          ? patientProfile?.assigned_room?.room?.room
                          : '-'}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className='mt-2'>
                      <span>Admission Date: </span>{' '}
                      <span className='fw-bold'>
                        <span>
                          {patientProfile?.admission_date
                            ? moment(patientProfile?.admission_date).format('MMMM-DD-YYYY')
                            : '-'}
                        </span>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='mt-2'>
                      <span>Visit Number: </span>
                      <span className='fw-bold'>
                        {patientProfile?.active_visit_number
                          ? patientProfile?.active_visit_number
                          : '-'}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className='mt-2'>
                      <span>Length of Stay: </span>{' '}
                      <span className='fw-bold'>
                        {showProfile.admissionDays ? showProfile?.admissionDays : '-'}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg='3'>
              <div className='p-3'>
                <h6>Doctors:</h6>
                <div className='d-flex flex-column'>
                  {patientProfile?.assigned_doctors.length > 0 ? (
                    patientProfile?.assigned_doctors?.map((item) => {
                      return (
                        <span className='fw-bold'>
                          {' '}
                          Dr. {item?.user?.first_name} {item?.user?.last_name}
                        </span>
                      );
                    })
                  ) : (
                    <span className='fw-bold'>N/A</span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <PatientProfileTabs
          showProfile={showProfile}
          permissionType={'Nursing'}
          setShowProfile={setShowProfile}
        />
      </FormUI>
    </div>
  );
};

const renderOnShowProfileForm = (showProfile) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (showProfile?.profileId && showProfile?.isShowProfile) {
      dispatch(showPatient(showProfile.profileId));
    }
  }, [showProfile]);
};

export default FormPatientProfile;
