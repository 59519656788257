/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal, Accordion, Table } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import ConditionalRender from 'components/ConditionalRender';
import { deleteRequestItem, selectDepartmentInventory } from 'reducers/departmentInventoryReducer';

// import assets below
import './RemoveDietModal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { deletePatientDiet, selectPatient, deletePatientMeal } from 'reducers/patientReducer';

// main componet
const RemoveDietModal = ({ showModal, setShowModal }) => {
  // Redux
  const dispatch = useDispatch();
  const groupState = useSelector(selectPatient);
  const { deletedDiet, deletedMeal } = groupState;

  const [formData, setFormData] = useState({
    remarks: '',
  });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleDelete = () => {
    const { id, type } = showModal.data;
    const params = {
      id: id,
      payload: {},
    };
    switch (type) {
      case 'diets':
        dispatch(deletePatientDiet(params));
        break;
      case 'meals':
        dispatch(deletePatientMeal(params));
        break;
    }
    dispatch(deletePatientDiet(params));
    setIsSubmitted(true);
  };

  const closeModal = () => {
    setConfirmDelete(false);
    setShowModal((prevState) => {
      return {
        ...prevState,
        confirmDelete: false,
      };
    });
  };

  useEffect(() => {
    const { type } = showModal.data;
    let target = '';

    switch (type) {
      case 'diets':
        target = deletedDiet;
        break;
      case 'meals':
        target = deletedMeal;
        break;
    }

    if (isSubmitted) {
      const { isFailed, isSuccess, error } = target;

      if (isFailed) {
        toast.error(`${error[0]?.detail} (${error[0]?.code})`);
      }

      if (isSuccess) {
        toast.success('Successfully deleted request item!');
        setShowModal(false);
      }
    }
  }, [deletedDiet, deletedMeal]);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        closeModal();
      }}
      aria-labelledby='Request Item Modal'
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>CONFIRMATION</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='remove-request-form'>
            <Row>
              <Col>
                <h3 className='mb-4'>Are you sure you want to REMOVE this item?</h3>

                <Form.Group className='buttons mb-3'>
                  <Button onClick={handleDelete} className='remove-button'>
                    Remove
                  </Button>
                  <Button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className='cancel-button'
                  >
                    Cancel
                  </Button>
                </Form.Group>

                <ConditionalRender
                  condition={confirmDelete === true}
                  renderIf={
                    <>
                      <Form.Group className='mb-3'>
                        <Form.Label>Remarks</Form.Label>
                        <FormControl
                          value={formData?.remarks}
                          onChange={handleChange('remarks')}
                          placeholder='Enter here'
                        />
                      </Form.Group>

                      <Form.Group className='buttons'>
                        <Button
                          disabled={deletedDiet?.isLoading}
                          onClick={handleDelete}
                          className='submit-button'
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </>
                  }
                />
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveDietModal;
