// import core & vendor packages below
import { Fragment } from 'react';

// import components below
import ReactHelmet from 'components/ReactHelmet';
import DashboardFooter from './components/Footer';
import DashboardTopbar from './components/Topbar';
// import DashboardBanner from './components/Banner';
import DashboardCardList from './components/CardList';

// import assets below
import './style.scss';

// main component
const dashboard = () => {
  return (
    <Fragment>
      <ReactHelmet title='Dashboard' />
      <div className='dashboard dashboard-page'>
        <DashboardTopbar />
        {/* <DashboardBanner /> */}
        <DashboardCardList />
        <DashboardFooter />
      </div>
    </Fragment>
  );
};

export default dashboard;
