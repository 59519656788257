/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// Packages
import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';

// Components
import FormUI from 'components/UI/FormUI';
import ConditionalRender from 'components/ConditionalRender';
import { ReactComponent as ProfileSVG } from 'assets/svg/circle-user.svg';
import List from './components/List';
import ModalAsset from './components/Modal';

// CSS
import './index.scss';
import { ReactComponent as EditSVG } from 'assets/svg/writing-green.svg';
import { ReactComponent as DeleteSVG } from 'assets/svg/circle-xmark-red.svg';
import axios from 'axios';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getFinancialStatementDate, selectFinancialStatementDate } from 'reducers/accountingReducer';

// Main Component
const AddFinancialStatement = () => {
  const dispatch = useDispatch();
  const date = useSelector(selectFinancialStatementDate);

  console.log('date value ', date);

  const [showModal, setShowModal] = useState({
    isShowForm: false,
    assetId: null,
    type: null,
    date: null,
    assetData: null,
  });

  const [accounts, setAccounts] = useState([]);
  const [popupTitle, setPopupTitle] = useState('');
  // const [date, setDate] = useState();
  const [fields, setFields] = useState({
    details: '',
    amount: null,
    category: '',
    asset_type: '',
    date: '',
  });

  const handleChange = (type) => (e) => {
    setFields({
      ...fields,
      asset_type: type,
      date: date,
      [e.target.name]: e.target.value,
    });
  };

  const getTotalLiabilityAndEquity = () => {
    const filteredList = accounts?.filter((data) => data?.asset_type !== 'Asset');
    const total = filteredList.length ? _.sum(_.map(filteredList, (data) => data?.amount)) : 0;
    return total === 0 ? 0 : total;
};

  // !!REVISIT - for refactoring
  const handlePostRequest = () => {
    axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/financial_statement`,
      fields,
    ).then((response) => {
      if (response?.data?.data.length) {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/financial_statement?date=${date}`)
            .then((response) => {
              setAccounts(response.data?.data?.list);
            })
            .catch((error) => console.log('Error fetching financial assets data: ', error));
      }
    })
    .catch((error) => console.log('Error posting financial assets data: ', error));
  };

  // !!REVISIT - for refactoring
  const handleUpdateRequest = () => {
    axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/financial_statement`,
      fields,
    ).then((response) => {
      if (response.data?.data?.list) {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/financial_statement?date=${date}`)
            .then((response) => {
              setAccounts(response.data?.data?.list);
            })
            .catch((error) => console.log('Error fetching financial assets data: ', error));
      }
    })
    .catch((error) => console.log('Error updating financial assets data: ', error));
  };

  // !!REVISIT - for refactoring
  const handleDeleteRequest = useCallback((id) => {
    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/financial_statement/${id}`)
      .then((deleted) => {
        console.log(deleted.data);
        if (deleted.data?.data === 1) {
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/financial_statement?date=${date}`)
            .then((response) => {
              setAccounts(response.data?.data?.list);
            })
            .catch((error) => console.log('Error fetching financial assets data: ', error));
          return;
        }
      })
      .catch((error) => console.log('Error deleting financial assets data: ', error));
  }, [accounts]);

  const handlePopupTitle = (type) => {
    setPopupTitle(type);
  };

  /*  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/financial_statement/${date}`)
      .then((response) => {
        console.log('financial assets data list', response.data?.data?.list);
        setAccounts(response.data?.data?.list);
      })
      .catch((error) => console.log('Error fetching financial assets data: ', error));
  }, [date, accounts]); */

  // !!REVISIT - for refactoring
  const handleDateChange = (e) => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/financial_statement?date=${e.target.value}`)
      .then((response) => {
        setAccounts(response.data?.data?.list);
        dispatch(getFinancialStatementDate(e.target.value));
      })
      .catch((error) => console.log('Error fetching financial assets data: ', error));
  };

  useEffect(() => {
    date === '' && setAccounts([]);
  }, [date]);
  
  return (
    <div className='add-financial-statement'>
      <ModalAsset
        setShowModal={setShowModal}
        showModal={showModal}
        date={date}
        popupTitle={popupTitle}
        handleChange={handleChange}
        setFields={setFields}
        handlePostRequest={handlePostRequest}
        fields={fields}
        handleUpdateRequest={handleUpdateRequest}
      />
      <FormUI>
        <Form>
          <Row>
            <Col>
              <div className='financial-details'>
                <Row className='mb-4'>
                  <Col sm={3}>
                    <Form.Group className='inline-label'>
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type='date'
                        placeholder='Enter Here'
                        onChange={handleDateChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {date && (
                  <List
                    title={'Assets'}
                    result={accounts}
                    setShowModal={setShowModal}
                    type={'Asset'}
                    date={date}
                    handlePopupTitle={handlePopupTitle}
                    setFields={setFields}
                    handleDeleteRequest={handleDeleteRequest}
                  />
                )}

                {date && (
                  <List
                    title={'Liabilities'}
                    result={accounts}
                    setShowModal={setShowModal}
                    type={'Liability'}
                    handlePopupTitle={handlePopupTitle}
                    setFields={setFields}
                    handleDeleteRequest={handleDeleteRequest}
                  />
                )}

                {date && (
                  <List
                    title={'Equity'}
                    result={accounts}
                    setShowModal={setShowModal}
                    type={'Equity'}
                    handlePopupTitle={handlePopupTitle}
                    setFields={setFields}
                    handleDeleteRequest={handleDeleteRequest}
                  />
                )}

                {date && (
                  <>
                    <div className='bold' />
                    <Row className='mt-4 mb-2'>
                      <Col className='total-cont'>
                        <p>
                          Total Liability + Equity : <span>Php {getTotalLiabilityAndEquity()}</span>
                        </p>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </FormUI>
    </div>
  );
};

export default AddFinancialStatement;
