/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup, Table } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import {
  selectroom,
  resetLogState,
  getPatientRoom,
  getAvailableRooms,
  submitTransferRoom,
  getPatientsWithRooms,
} from 'reducers/roomReducer';

// import assets below
import './index.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const initialState = {
  patient: '',
  room: '',
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.45em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

// main component
const TransferRoom = ({ showProfile, setShowProfile, refreshData }) => {
  const dispatch = useDispatch();
  const roomState = useSelector(selectroom);
  const { data, logs, roomData, roomLogs, singleData, transferLogs } = roomState;

  const [formData, setFormData] = useState(initialState);
  const [selectData, setSelectData] = useState({
    patients: [],
    rooms: [],
  });

  const closeModal = () => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        showTransfer: false,
      };
    });

    setFormData(initialState);
  };

  const handleSubmit = () => {
    if (!formData?.patient || !formData?.room) {
      toast.error('Please fill in the empty form');
      return;
    }

    const oldRoomId = singleData?.assigned_room?.id;
    const params = {
      request_type: 'Transfer',
      patientId: formData?.patient?.id,
      roomId: formData?.room?.id,
      oldRoomId: oldRoomId,
    };

    dispatch(submitTransferRoom(params));
  };

  const getData = () => {
    dispatch(getPatientsWithRooms());
    dispatch(getAvailableRooms());
  };

  const handleFormChange = (key) => (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: e,
      };
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const { isSuccess } = logs;
    if (isSuccess) {
      const filteredData = data.map((dd) => {
        return {
          id: dd?.id,
          value: dd?.id,
          label: dd?.full_name,
          data: dd,
        };
      });

      setSelectData((prev) => {
        return {
          ...prev,
          patients: filteredData,
        };
      });
    }
  }, [logs]);

  useEffect(() => {
    const { isSuccess } = roomLogs;

    if (isSuccess) {
      const filteredData = roomData?.room_list.map((dd) => {
        return {
          id: dd?.id,
          value: dd?.id,
          label: `${dd?.room} (${dd?.type})`,
          data: dd,
        };
      });

      setSelectData((prev) => {
        return {
          ...prev,
          rooms: filteredData,
        };
      });
    }
  }, [roomLogs]);

  useEffect(() => {
    const { isSuccess } = transferLogs;

    if (isSuccess) {
      toast.success('Successfully submitted request to transfer room');
      closeModal();
      dispatch(resetLogState());
      refreshData();
    }
  }, [transferLogs]);

  useEffect(() => {
    if (formData.patient) {
      dispatch(getPatientRoom(formData?.patient?.id));
    }
  }, [formData]);

  return (
    <Modal
      size='lg'
      show={showProfile.showTransfer}
      onHide={() => closeModal()}
      aria-labelledby='Patient room information'
      className='transfer-room-modal'
    >
      <Modal.Body>
        <div className='transfer-room-request'>
          <div className='d-flex header'>
            <p>TRANSFER ROOM</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={() => closeModal()} />
          </div>
        </div>

        <FormUI>
          <Form className='transfer-form'>
            <Row className='mb-4'>
              <Col>
                <Form.Group>
                  <Form.Label>Select Patient</Form.Label>

                  {selectData.patients.length > 0 && (
                    <Select
                      value={formData?.patient}
                      options={selectData.patients}
                      placeholder='Click to select'
                      styles={selectStyles}
                      onChange={handleFormChange('patient')}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col>
                <Form.Group>
                  <Form.Label>Select Room</Form.Label>

                  {selectData.patients.length > 0 && (
                    <Select
                      value={formData?.room}
                      options={selectData.rooms}
                      placeholder='Click to select'
                      styles={selectStyles}
                      onChange={handleFormChange('room')}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='buttons-cont'>
                <Button onClick={handleSubmit} className='add-button'>
                  Transfer
                </Button>
                <Button onClick={closeModal} className='cancel-button'>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default TransferRoom;
