/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, Table, FormControl } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const ViewItemRepair = ({ showModal, setShowModal }) => {
  const [viewData, setViewData] = useState(null);
  const [viewItem, setViewItem] = useState(null);

  useEffect(() => {
    if (showModal.itemId) {
      setViewData(showModal?.rowData);
      setViewItem(showModal?.itemData);
    }
  }, [showModal]);

  return (
    <Modal
      size='lg'
      show={showModal.showView}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            rowData: null,
            showView: false,
          };
        })
      }
      aria-labelledby='ITEM FOR REQUEST TO REPAIR'
      className='view-request-for-repair-modal'
    >
      <Modal.Body>
        <div className='view-request-for-repair-request'>
          <div className='d-flex header'>
            <p>REQUEST ITEM FOR REPAIR/MAINTENANCE</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowModal((prevState) => {
                  return { ...prevState, showView: false, rowData: null };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='adjustment-form'>
            <Row>
              <Col sm={6}>
                {viewData?.attributes?.image_path ? (
                  <img
                    alt='img'
                    className='image-container'
                    src={viewData?.attributes?.image_path}
                  />
                ) : (
                  <img
                    alt='img'
                    className='image-container'
                    src='https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'
                  />
                )}
              </Col>
              <Col sm={6} className='align-items-center'>
                <Form.Group className='mb-2'>
                  <Form.Label>Control Number :</Form.Label>
                  <span className='mx-3'>{viewData?.attributes?.control_number}</span>
                </Form.Group>
                <Form.Group className='mb-2'>
                  <Form.Label>Equipment Name :</Form.Label>
                  <span className='mx-3'>{viewItem?.attributes?.name}</span>
                </Form.Group>
                <Form.Group className='mb-2'>
                  <Form.Label>Equipment Description :</Form.Label>
                  <span className='mx-3'>
                    {Array.isArray(viewData) !== 'undefined'
                      ? viewData?.attributes?.description
                      : ''}
                  </span>
                </Form.Group>
                <Form.Group className='mb-2'>
                  <Form.Label>Item Count :</Form.Label>
                  <span className='mx-3'>{viewData?.attributes?.item_count}</span>
                </Form.Group>
                <Form.Group className='mb-2'>
                  <Form.Label>Item Status :</Form.Label>
                  <span
                    className='mx-3 px-3 py-1 text-white'
                    style={{ border: 'none', backgroundColor: '#05B10C', borderRadius: '5px' }}
                  >
                    {viewData?.attributes?.status}
                  </span>
                </Form.Group>
                <Form.Group className='mb-2'>
                  <Form.Label>Requested by :</Form.Label>
                  <span className='mx-3'>{viewData?.attributes?.requested_by}</span>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ViewItemRepair.defaultProps = {
  showModal: {},
  setShowModal: () => {},
};

ViewItemRepair.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default ViewItemRepair;
