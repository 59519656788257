import React, { useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// components
import FormUI from 'components/UI/FormUI';

// utils
import { getCashFlow, setCashFlow, updateCashFlow } from 'reducers/thunks/accountingThunks';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// css
import './index.scss';

const CashInOutModal = (props) => {
  const dispatch = useDispatch();
  const { setShowModal2, showModal2 } = props;
  const [validated, setValidated] = useState(false);
  const handleShow = () => setShowModal2({ isShowForm: false });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      const params = { ...showModal2.data, ...showModal2.id };
      if (!params.id) {
        await dispatch(setCashFlow(params));
        toast.success('Successfully Added');
      } else {
        await dispatch(updateCashFlow(params));
        toast.success('Successfully Updated');
      }
      const p = {
        search: showModal2.date,
      };

      await dispatch(getCashFlow(p));
      handleShow();
    }
    setValidated(true);
  };

  const handleChange = (event) => {
    setShowModal2({
      ...showModal2,
      data: {
        ...showModal2.data,
        [event.target.name]: event.target.value,
        date: showModal2.date,
      },
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Modal show={showModal2.isShowForm} size='md' className='cashflow-modal'>
      <Modal.Body>
        <div className='add-room-request'>
          <div className='d-flex header'>
            <p>Add Cash Flow Statement</p>
            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
          </div>
        </div>

        <FormUI>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='mb-3 width-90 right-auto inline-label'>
                  <Form.Label>Detail Type</Form.Label>
                  <Form.Control
                    type='text'
                    name='field'
                    placeholder='Enter Here'
                    value={showModal2.data?.field}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='mb-3 width-90 right-auto inline-label'>
                  <Form.Label>Cash In</Form.Label>
                  <Form.Control
                    type='number'
                    name='cash_in'
                    placeholder='Enter Here'
                    value={showModal2.data?.cash_in}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='mb-3 width-90 right-auto inline-label'>
                  <Form.Label>Cash Out</Form.Label>
                  <Form.Control
                    type='number'
                    name='cash_out'
                    placeholder='Enter Here'
                    value={showModal2.data?.cash_out}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mt-6'>
              <hr />
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mb-4'>
                <Form.Group className='me-2'>
                  <Button className='btn btn-save' type='submit'>
                    Save
                  </Button>
                </Form.Group>
                <Form.Group className='ms-2'>
                  <Button variant='secondary' onClick={handleShow}>
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default CashInOutModal;
