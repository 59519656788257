/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getDisposalList = createAsyncThunk(
  'hr/getDisposalList',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: filter,
      url: `fixed_asset/disposal`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getRegisterList = createAsyncThunk(
  'hr/getRegisterList',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: filter,
      url: `fixed_asset/registration`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getEquipmentList = createAsyncThunk(
  'hr/getEquipmentList',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: filter,
      url: `fixed_asset/equipment`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getInventoryList = createAsyncThunk(
  'hr/getInventoryList',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: filter,
      url: `fixed_asset/inventory`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

/* Entry Date*/
export const getEntryDateList = createAsyncThunk(
  'hr/getEntryDateList',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: filter,
      url: `fixed_asset/entry_date`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

/* Depreciation*/
export const getDepreciationList = createAsyncThunk(
  'hr/getDepreciationList',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: filter,
      url: `fixed_asset/depreciation`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showDisposalItems = createAsyncThunk(
  'hr/addDisposalItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `fixed_asset/disposal${params}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params.payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Display Job Order List
export const getJobOrderList = createAsyncThunk(
  'hr/getJobOrderList',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: filter,
      url: `fixed_asset/job_order`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Add Job Order Item
export const addJobOrderItems = createAsyncThunk(
  'hr/addJobOrderItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `fixed_asset/job_order`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Update Job Order Item
export const updateJobOrder = createAsyncThunk(
  'hr/updateJobOrder',
  async ({id, params}, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `fixed_asset/disposal/${id}`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Add Disposal Item
export const addDisposalItems = createAsyncThunk(
  'hr/addDisposalItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `fixed_asset/disposal`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Add Registration Item
export const addRegistrationItems = createAsyncThunk(
  'hr/addRegistrationItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `fixed_asset/registration`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Add Equipment Item
export const addEquipmentItems = createAsyncThunk(
  'hr/addEquipmentItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `fixed_asset/equipment`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Add Inventory Item
export const addInventoryItems = createAsyncThunk(
  'hr/addInventoryItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `fixed_asset/inventory`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Add Depreciation Item
export const addDepreciationItems = createAsyncThunk(
  'hr/addDepreciationItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `fixed_asset/depreciation`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Add Entry Date Item
export const addEntryDateItems = createAsyncThunk(
  'hr/addEntryDateItems',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `fixed_asset/entry_date`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);