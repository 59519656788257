import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// main components below
import Filter from './components/Filter';
import ProfessionalFee from './components/Table';
import { selectprofessionalFee, getAssignedDoctors } from 'reducers/professionalFeeReducer';
import SetProfessionalModal from './components/Modals/SetProfessionalModal/SetProfessionalModal';

// main component
const Diagnosis = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectprofessionalFee);
  const { data: stateData, logs } = reduxState;

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [search, setSearch] = useState({
    search: '',
  });

  // eslint-disable-next-line no-unused-vars
  const [showModal, setShowModal] = useState({
    id: null,
    data: null,
    showSet: false,
  });

  const handleSearch = (reset = false) => {
    const keyword = reset ? '' : search.search;

    const newFilter = data.filter((value) => {
      if (value?.patient?.full_name) {
        return value?.patient?.full_name.toLowerCase().includes(keyword.toLowerCase()) ||
          value?.patient?.MRN.includes(keyword);
      }

      if (value?.user?.full_name) {
        return value?.user?.full_name.toLowerCase().includes(keyword.toLowerCase());
      }
    });

    setFilteredData(newFilter);
  };

  useEffect(() => {
    // console.log('showModal', showModal);
  }, [showModal]);

  const refreshTable = () => {
    dispatch(getAssignedDoctors());
  };

  useEffect(() => {
    const { isSuccess } = logs;

    if (isSuccess) {
      setData(stateData);
      setFilteredData(stateData);
    }
  }, [logs]);

  return (
    <div className='diagnosis'>
      <Filter
        search={search}
        setSearch={setSearch}
        filteredData={filteredData}
        handleSearch={handleSearch}
        setFilteredData={setFilteredData}
      />

      <div className='diagnosis'>
        <div className='d-flex justify-content-between align-items-center'>
          <h5 className='fw-bold'>Table List</h5>
        </div>

        <ProfessionalFee
          logs={logs}
          data={filteredData}
          setData={setData}
          setShowModal={setShowModal}
          refreshTable={refreshTable}
        />
      </div>

      <SetProfessionalModal
        showModal={showModal}
        setShowModal={setShowModal}
        refreshTable={refreshTable}
      />
    </div>
  );
};

export default Diagnosis;
