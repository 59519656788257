/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import { getWindowCredentialList, selectCredentialsManagement } from 'reducers/credentialsManagementReducer';

// import assets below
import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

// main component
const index = ({ setShowScreen, setRequestData }) => {
  const dispatch = useDispatch();

  const credentialsManagementState = useSelector(selectCredentialsManagement);
  const { windowCredentialData, windowCredentialItem, windowCredentialLogs } = credentialsManagementState;

  const [tableList, setTableList] = useState([]);
  
  const tableColumns = React.useMemo(
    () => [
      { Header: 'Internet or Network Address', accessor: 'internet' },
      { Header: 'Username', accessor: 'username' },
      { Header: 'Password', accessor: 'password' },
    ],
    [],
  );
  
  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({
        id: dd?.id,
        internet: dd?.internet,
        username: dd?.username,
        password: dd?.password,
        data: dd,
      })),
    [tableList],
  );
  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, windowCredentialData, windowCredentialLogs);
  
  return (
    <div className='credentials-credits'>
      <div className='header'>
        <h2>Windows Credentials </h2>

        <div className='functions'>

          <Button className='upload' onClick={() => setShowScreen(3)}>
            <AddSVG />
            Add Record
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
        />
      </div>
    </div>
  );
};

const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getWindowCredentialList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, windowCredentialData, windowCredentialLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = windowCredentialLogs;

    if (isSuccess) {
      const newData = windowCredentialData.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          internet: dd?.internet,
          username: dd?.username,
          password: dd?.password,
        };
      });
      console.log(newData);
      setTableList(newData);
    }
  }, [windowCredentialLogs]);
};

export default index;
