import TableUI from 'components/UI/TableUI';
import { Table, Row, Col } from 'react-bootstrap';

import './dischargePatient.scss';

const DischargePatient = (props) => {
    const { items } = props;

    return (<>
        <label className='fs-5 fw-bold mb-1'>B. Discharges</label>
        <label className='fs-6 fw-bold'>Kindly accomplish the “Type of Service and Total Discharges According to Specialty” in the table below.</label>
        <div className='mt-3 discharged'>
            <TableUI>
                <Table striped hover>
                    <thead>
                        <tr>
                            <th rowSpan='3' colSpan='2'>Type of Service</th>
                            <th rowSpan='3'>No of Pts</th>
                            <th rowSpan='3'>Total Length of Stay/ Total No. of Days Stay</th>
                            <th colSpan='8'>Type of Accomodation</th>
                            <th colSpan='9'>Condition on Discharge</th>
                        </tr>
                        <tr>
                            <th colSpan='3'>PhilHealth</th>
                            <th colSpan='3'>Non - PhilHealth</th>
                            <th>HMO</th>
                            <th>OWWA</th>
                            <th>R/I</th>
                            <th>T</th>
                            <th>H</th>
                            <th>A</th>
                            <th>U</th>
                            <th colSpan='3'>Deaths</th>
                            <th>Total Discharges</th>
                        </tr>
                        <tr>
                            <th>Pay</th>
                            <th>Service</th>
                            <th>Total</th>
                            <th>Pay</th>
                            <th>Service</th>
                            <th>Total</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>  {'<'} 48 hrs</th>
                            <th> {'>'} 48 hrs</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items?.map((item, index) => {
                            return (<tr key={index}>
                                <td colSpan='2'>{item.service}</td>
                                <td>{item.points}</td>
                                <td>{item.total_of_stay}</td>
                                <td>{item.ph_pay}</td>
                                <td>{item.ph_service}</td>
                                <td>{item.ph_total}</td>
                                <td>{item.nph_pay}</td>
                                <td>{item.nph_service}</td>
                                <td>{item.nph_total}</td>
                                <td>{item.hmo}</td>
                                <td>{item.owwa}</td>
                                <td>{item.R_I}</td>
                                <td>{item.T}</td>
                                <td>{item.H}</td>
                                <td>{item.A}</td>
                                <td>{item.U}</td>
                                <td>{item.deaths_lt_48hr}</td>
                                <td>{item.deaths_gt_48hr}</td>
                                <td>{item.deaths_total}</td>
                                <td>{item.total_discharge}</td>
                            </tr>);
                        })}
                    </tbody>
                </Table>
            </TableUI>
            <Row className='w-50 p-2 mx-auto fs-6 fw-bold mb-1'>
                <Col md='6'>R/I - Recovered / Improved</Col>
                <Col>T - Transfered</Col>
                <Col>U - Unimproved</Col>
            </Row>
            <Row className='w-50 p-2 mx-auto fs-6 fw-bold mb-1'>
                <Col md='6'>H - Home Against Medical Advice</Col>
                <Col>A - Abscanded</Col>
                <Col>D - Died</Col>
            </Row>
        </div>
    </>
    );
};

export default DischargePatient;
