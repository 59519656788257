// import components below
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const PharmacyHeader = (props) => {
  const { active } = props;

  return (
    <div className='pharmacyHeader'>
      <PageHeaderUI>
        <h4>Pharmacy Module</h4>
        <p>Dashboard / Pharmacy / {active}</p>
      </PageHeaderUI>
    </div>
  );
};

export default PharmacyHeader;
