// Packages
import { useState } from 'react';

// main components below
import DoctorFilter from './components/Filter';
import ProcedureTable from './components/Table';

// Nursing Components
import ModalProcedure from 'pages/Doctors/components/Procedure/components/ProcedureModal';

// main component
const Procedure = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    show: false,
  });

  return (
    <>
      <div className='searchPatient'>
        <DoctorFilter state={state} setState={setState} setShowProfile={setShowProfile} />
        <ProcedureTable state={state} setState={setState} setShowProfile={setShowProfile} />
      </div>

      <ModalProcedure setShowProfile={setShowProfile} showProfile={showProfile} />
    </>
  );
};

export default Procedure;
