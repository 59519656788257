/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import { hasData } from 'jquery';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Table, Button, Dropdown, OverlayTrigger, Popover, Row, Col, Form } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';
import { selectPatientData } from 'reducers/patientReducer';
import ConditionalRender from 'components/ConditionalRender';
import TablePagination from 'components/TablePagination/Pagination';
import TableBody from './TableBody';

// import assets below
import './Table.scss';

const sampleNewData = [];
const sampleInProgressData = [
  {
    id: 1,
    date: 'May-24-2022',
    time: '10:13 AM',
    stockCheckNo: '14578220',
    name: 'Monthly Inventory Check',
    totalItems: 8,
    itemsCounted: 8,
    completed: '100%',
    totalHandCost: '200,000',
    countedCost: '300,000',
    varianceCost: '-50,000.00',
    variance: '5.1%',
    status: 'In progress',
    createdBy: 'Andrew',
  },
];
const samplecompletedData = [
  {
    id: 1,
    date: 'May-24-2022',
    time: '10:13 AM',
    stockCheckNo: '14578220',
    name: 'Monthly Inventory Check',
    totalItems: 8,
    itemsCounted: 8,
    completed: '100%',
    totalHandCost: '200,000',
    countedCost: '300,000',
    varianceCost: '-50,000.00',
    variance: '5.1%',
    status: 'Completed',
    createdBy: 'Andrew',
  },
  {
    id: 2,
    date: 'May-24-2022',
    time: '10:13 AM',
    stockCheckNo: '14578220',
    name: 'Monthly Inventory Check',
    totalItems: 8,
    itemsCounted: 8,
    completed: '100%',
    totalHandCost: '200,000',
    countedCost: '300,000',
    varianceCost: '-50,000.00',
    variance: '5.1%',
    status: 'Completed',
    createdBy: 'Andrew',
  },
];

// main component
const StockCheckFilterTable = ({ setState, showProfile, setShowProfile }) => {
  const data = useSelector(selectPatientData);

  const isNew = showProfile.tableShow === 1;
  const isInProgress = showProfile.tableShow === 2;
  const isCompleted = showProfile.tableShow === 3;

  const handleTable = (num) => {
    setShowProfile({
      ...showProfile,
      tableShow: num,
    });
  };

  return (
    <div className='stock-adjustment-filter-table'>
      <Row>
        <Col sm={6}>
          <div className='buttons'>
            <Button
              onClick={() => {
                handleTable(1);
              }}
              className={`${isNew ? 'active' : ''}`}
            >
              New
            </Button>
            <Button
              onClick={() => {
                handleTable(2);
              }}
              className={`${isInProgress ? 'active' : ''}`}
            >
              In Progress
            </Button>
            <Button
              onClick={() => {
                handleTable(3);
              }}
              className={`${isCompleted ? 'active' : ''}`}
            >
              Completed
            </Button>
          </div>
        </Col>
      </Row>

      <TableUI>
        <Table responsive>
          <thead>
            <tr>
              <th>Stock Check Date/Time</th>
              <th>Stock Check No.</th>
              <th>Name</th>
              <th>Total Items</th>
              <th>Items Completed</th>
              <th>Completed %</th>
              <th>Total on Hand Cost</th>
              <th>Counted Cost</th>
              <th>Variance Cost</th>
              <th>Variance %</th>
              <th>Status</th>
              <th>Created By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ConditionalRender
              condition={isNew}
              renderIf={<TableBody data={sampleNewData} setShowProfile={setShowProfile} />}
            />

            <ConditionalRender
              condition={isInProgress}
              renderIf={<TableBody data={sampleInProgressData} setShowProfile={setShowProfile} />}
            />

            <ConditionalRender
              condition={isCompleted}
              renderIf={<TableBody data={samplecompletedData} setShowProfile={setShowProfile} />}
            />
          </tbody>
        </Table>
      </TableUI>

      <TablePagination />
    </div>
  );
};

StockCheckFilterTable.defaultProps = {
  setState: () => {},
  showProfile: {},
  setShowProfile: () => {},
};

StockCheckFilterTable.propTypes = {
  setState: PropTypes.func,
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default StockCheckFilterTable;
