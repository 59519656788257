/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormControl, Button, Image } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import ConfirmModal from './components/ConfirmModal';

// main component
const samplePhoto = 'https://picsum.photos/id/237/200/300';

const index = ({ requestData, setShowScreen }) => {
  const [data, setData] = useState([]);
  const [modalType, setModalType] = useState();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (requestData) {
      //   console.log('yeata', requestData?.logs_data);
      setData(requestData?.data?.task);
    }
  }, [requestData]);

  return (
    <div className='advances-container shadow-sm'>
      <div className='box-container blue'>
        <Form.Group className='inline-group'>
          <Form.Label>ATTENDANCE LOGS</Form.Label>
        </Form.Group>
      </div>

      <div className='box-container justify-content-start'>
              <Image className='avatar' src={samplePhoto} style={{ width: '93px', height: '92px' }} />

              <Form.Group className='employee-detail'>
                <p>JUAN DELA CRUZ</p>
                <span>Name</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>PHYSICIAN</p>
                <span>Position</span>
              </Form.Group>
      </div>

      <hr />

      <div className='box-container'>
        <div className='view-record'>

          <Row className='mb-3'>
              <Form.Group className='inline-group'>
                <Form.Label>Log-in Date: </Form.Label>

                <FormControl placeholder='DETAILS HERE' value={requestData?.date} />
              
                <Form.Label>Log-in Time: </Form.Label>
                <FormControl placeholder='DETAILS HERE' value={requestData?.log_in} />
             
                <Form.Label>Log-out Time: </Form.Label>
                <FormControl placeholder='DETAILS HERE' value={requestData?.log_out} />
              
                <Form.Label>Status: </Form.Label>
                <FormControl placeholder='DETAILS HERE' value={requestData?.status} />

              </Form.Group>
          </Row>
          </div>
      </div>
          <hr />
          
      <div className='box-container'>
        <div className='view-record'>
          <Row className='mb-3'>
            <Col className='table-data'>
              <br></br>
              <p className='title'>Task Details:</p>
                  <Form.Group className='inline-group'>
                    <FormControl as='textarea' rows='5' value={requestData?.task} />
                  </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className='content-footer'>
              <Button
                className='back-button'
                onClick={() => {
                  setShowScreen(1);
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <ConfirmModal modalType={modalType} openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default index;
