import TableUI from 'components/UI/TableUI';
import { Table } from 'react-bootstrap';

import './MorbilityAgeSexTable.scss';

const MobilityAgeSexTable = (props) => {

    const { items } = props;

    return (<div className='mt-3 morbility-age-sex'>
        <TableUI>
            <Table striped hover>
                <thead>
                    <tr>
                        <th rowSpan='3'>Cause of Morbidity (DO NOT INCLUDE DELIVERIES)</th>
                        <th colSpan='34'>Age Distribution of Patients</th>
                        <th rowSpan='3'>Total</th>
                        <th rowSpan='3'>ICD-10CODE/ TABU-LAR LIST</th>
                    </tr>
                    <tr>
                        <th colSpan='2'>Under 1</th>
                        <th colSpan='2'>1 - 4</th>
                        <th colSpan='2'>5 - 9</th>
                        <th colSpan='2'>10 - 14</th>
                        <th colSpan='2'>15 - 19</th>
                        <th colSpan='2'>20 - 24</th>
                        <th colSpan='2'>25 - 29</th>
                        <th colSpan='2'>30 - 34</th>
                        <th colSpan='2'>35 - 39</th>
                        <th colSpan='2'>40 - 44</th>
                        <th colSpan='2'>45 - 49</th>
                        <th colSpan='2'>50 - 54</th>
                        <th colSpan='2'>55 - 59</th>
                        <th colSpan='2'>60 - 64</th>
                        <th colSpan='2'>65 - 69</th>
                        <th colSpan='2'>70 & Over</th>
                        <th colSpan='2'>Sub total</th>
                    </tr>
                    <tr>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                        <th>M</th>
                        <th>F</th>
                    </tr>
                </thead>
                <tbody>

                    {items?.map((item, index) => {
                        return (<tr key={index + 1}>
                            <td>{item.morbidity}</td>
                            <td>{item.under_1_M ? item.under_1_M : '0'}</td>
                            <td>{item.under_1_F ? item.under_1_F : '0'}</td>
                            <td>{item.M_1_to_4 ? item.M_1_to_4 : '0'}</td>
                            <td>{item.F_1_to_4 ? item.F_1_to_4 : '0'}</td>
                            <td>{item.M_5_to_9 ? item.M_5_to_9 : '0'}</td>
                            <td>{item.F_5_to_9 ? item.F_5_to_9 : '0'}</td>
                            <td>{item.M_10_to_14 ? item.M_10_to_14 : '0'}</td>
                            <td>{item.F_10_to_14 ? item.F_10_to_14 : '0'}</td>
                            <td>{item.M_15_to_19 ? item.M_15_to_19 : '0'}</td>
                            <td>{item.F_15_to_19 ? item.F_15_to_19 : '0'}</td>
                            <td>{item.M_20_to_24 ? item.M_20_to_24 : '0'}</td>
                            <td>{item.F_20_to_24 ? item.F_20_to_24 : '0'}</td>
                            <td>{item.M_25_to_29 ? item.M_25_to_29 : '0'}</td>
                            <td>{item.F_25_to_29 ? item.F_25_to_29 : '0'}</td>
                            <td>{item.M_30_to_34 ? item.M_30_to_34 : '0'}</td>
                            <td>{item.F_30_to_34 ? item.F_30_to_34 : '0'}</td>
                            <td>{item.M_35_to_39 ? item.M_35_to_39 : '0'}</td>
                            <td>{item.F_35_to_39 ? item.F_35_to_39 : '0'}</td>
                            <td>{item.M_40_to_44 ? item.M_40_to_44 : '0'}</td>
                            <td>{item.F_40_to_44 ? item.F_40_to_44 : '0'}</td>
                            <td>{item.M_45_to_49 ? item.M_45_to_49 : '0'}</td>
                            <td>{item.F_45_to_49 ? item.F_45_to_49 : '0'}</td>
                            <td>{item.M_50_to_54 ? item.M_50_to_54 : '0'}</td>
                            <td>{item.F_50_to_54 ? item.F_50_to_54 : '0'}</td>
                            <td>{item.M_55_to_59 ? item.M_55_to_59 : '0'}</td>
                            <td>{item.F_55_to_59 ? item.F_55_to_59 : '0'}</td>
                            <td>{item.M_60_to_64 ? item.M_60_to_64 : '0'}</td>
                            <td>{item.F_60_to_64 ? item.F_60_to_64 : '0'}</td>
                            <td>{item.M_65_to_69 ? item.M_65_to_69 : '0'}</td>
                            <td>{item.F_65_to_69 ? item.F_65_to_69 : '0'}</td>
                            <td>{item.M_70_UP ? item.M_70_UP : '0'}</td>
                            <td>{item.F_70_UP ? item.F_70_UP : '0'}</td>
                            <td>{item.M_SUBTOTAL ? item.M_SUBTOTAL : '0'}</td>
                            <td>{item.F_SUBTOTAL ? item.F_SUBTOTAL : '0'}</td>
                            <td>{item.TOTAL ? item.TOTAL : '0'}</td>
                            <td>{item.icd_code}</td>
                        </tr>);
                    })}

                </tbody>
            </Table>
        </TableUI>
    </div>);
};

export default MobilityAgeSexTable;
