/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

import FormUI from 'components/UI/FormUI';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const AddDoctorToPatient = ({ showModal, setShowModal, setState, result }) => {
  const [patients, setPatients] = useState([]);
  const [saveButton, setSaveButton] = useState(true);
  const [userData, setUserData] = useState({
    id: '',
    conducted_by: '',
    licensed_number: '',
    signed_by: '',
    date_performed: '',
    remarks: '',
    labRequestId: showModal.labRequestId,
    lab_test: 'Internal',
  });
  const [labResult, setLabResult] = useState([]);

  useEffect(() => {
    // console.log('data', userData);
  }, [userData]);

  const handleFile = (e) => {
    const file = e.target.files[0];

    if (file.type !== 'application/pdf') {
      toast.warning('Only PDF is allowed');
    } else {
      setLabResult(file);
      setSaveButton(false);
    }
  };

  // Submit data here
  const handleSubmit = (e) => {
    const formdata = new FormData();
    formdata.append('result', labResult);
    formdata.append('id', showModal.labRequestId);
    formdata.append('lab_test', userData.lab_test);
    formdata.append('conducted_by', userData.conducted_by);
    formdata.append('licensed_number', userData.licensed_number);
    formdata.append('signed_by', userData.signed_by);
    formdata.append('remarks', userData.remarks);
    formdata.append('date_performed', userData.date_performed);

    axios
      .post('/requisition/lab_result', formdata)
      .then((response) => {
        setSaveButton(true);
        setTimeout(() => {
          axios
            .get('/requisition/laboratory')
            .then((response) => {
              toast.success('Successfully uploaded lab result!');
              setState(response.data.data);
              setUserData({
                id: '',
                conducted_by: '',
                licensed_number: '',
                signed_by: '',
                date_performed: '',
                remarks: '',
              });
            }, [])
            .catch((err) => {
              console.log('AXIOS ERROR: ', err);
            });
        }, 1000);
      }, [])
      .catch((err) => {
        err?.response?.data?.errors.map((item) => {
          toast.warning(item.msg + ' ' + item.param);
        });
      });
  };

  const [searchedWord, setSearchedWord] = useState('');

  /** filter patients */
  const onChangeHandler = (event) => {
    setSearchedWord(event.target.value);
    const searchedWord = event.target.value;
    const newFilter = patients.filter((value) => {
      return value.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState([]);
    }
  };

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });

    if (!e.target.value.length > 0) {
      setSaveButton(true);
    }

    if (
      userData.conducted_by.length === 0 ||
      userData.licensed_number.length === 0 ||
      userData.signed_by.length === 0 ||
      userData.date_performed.length === 0
    ) {
      setSaveButton(true);
    } else {
      setSaveButton(false);
    }
  };

  useEffect(() => {
    /** Get patients for search patient */
    axios
      .get('/patient')
      .then((response) => {
        setPatients(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    setState([]);
  }, []);

  return (
    <Modal
      size='lg'
      show={showModal.isViewResult}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            isViewResult: false,
          };
        })
      }
      aria-labelledby='Add Doctor to Patient'
      className='add-doctor-to-patient-modal'
    >
      <Modal.Body>
        <div className='add-doctor-to-patient-request'>
          <div className='d-flex header'>
            <p>VIEW LAB RESULT</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowModal((prevState) => {
                  return { ...prevState, isViewResult: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col md={4}>
                <Form.Group className='mb-4'>
                  <Form.Label>Internal/External ?</Form.Label>
                  <Form.Select
                    onChange={handleChange('lab_test')}
                    value={result?.lab_test}
                    defaultValue='Internal'
                    disabled
                  >
                    <option hidden>-Select-</option>
                    <option value='Internal'>Internal</option>
                    <option value='External'>External</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>File Upload: </Form.Label>
                  &nbsp;&nbsp;
                  {result?.originalName ? (
                    // <span className='value'><a href={`${process.env.REACT_APP_API_BASE_URL}/files/1666531675077.pdf`} target='_blank'>{result?.originalName}</a></span>
                    <span className='value'>
                      <a
                        href={`${process.env.REACT_APP_API_BASE_URL}/files/${result?.filename}`}
                        target='_blank'
                      >
                        {result?.originalName}
                      </a>
                    </span>
                  ) : (
                    <span className='value' style={{ color: 'red' }}>
                      N/A
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>
                    Conducted By: <span className='text-danger'>*</span>
                  </Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={result?.conducted_by}
                    disabled
                    required={true}
                    onChange={handleChange('conducted_by')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>
                    PRC Licensed #: <span className='text-danger'>*</span>
                  </Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={result?.licensed_number}
                    disabled
                    readOnly
                    onChange={handleChange('licensed_number')}
                    required={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>
                    Verified By: <span className='text-danger'>*</span>
                  </Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={result?.signed_by}
                    disabled
                    readOnly
                    onChange={handleChange('signed_by')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>
                    PRC Licensed #: <span className='text-danger'>*</span>
                  </Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    disabled
                    readOnly
                    onChange={handleChange('verified_by_licensed_number')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>
                    Date Performed: <span className='text-danger'>*</span>
                  </Form.Label>
                  <FormControl
                    type='date'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={result?.date_performed}
                    disabled
                    onChange={handleChange('date_performed')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Remarks</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={3}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={result?.remarks}
                    disabled
                    onChange={handleChange('remarks')}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default AddDoctorToPatient;
