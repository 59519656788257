// import core & vendor packages below
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

// import components below
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './ConfirmModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const ConfirmModal = ({ modalType, openModal, setOpenModal }) => {
  const closeModal = () => {
    setOpenModal(false);
  };

  if (!modalType) return;

  const isDeleted = modalType === 'delete';

  const deleteAttendance = () => {};

  return (
    <Modal className='view-delete-modal' show={openModal} onHide={closeModal} centered>
      <Modal.Header>
        <h6>CONFIRM DELETE?</h6>
        <Button onClick={closeModal}>
          <CloseSVG />
        </Button>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to delete this log?</p>
      </Modal.Body>

      <Modal.Footer>
        <ConditionalRender
          condition={isDeleted}
          renderIf={
            <Button onClick={deleteAttendance} className='remove'>
              Delete
            </Button>
          }
        />

        <Button onClick={closeModal} className='cancel'>
          Cancel
        </Button>
      </Modal.Footer>
      
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  openModal: false,
  setOpenModal: () => {},
  data: {},
  searchAllTabs: () => {},
  getDatas: () => {},
};

ConfirmModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  data: PropTypes.object,
  searchAllTabs: PropTypes.func,
  getDatas: PropTypes.func,
};

export default ConfirmModal;