// import assets below
import './style.scss';

// main component
const TableUI = (props) => {
  const { children } = props;
  return <div className='tableUI'>{children}</div>;
};

export default TableUI;
