/* eslint-disable camelcase */
// import core & vendor packages below
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import Moment from 'moment';

// import components below
import Table from 'components/ReactTable';
import { selectrefillRequestItem } from 'reducers/refillRequest';
import UseItemList from '../../../hooks/UseItemList';
import UseStatusAction from 'pages/Inventory/hooks/UseStatusAction';

// import assets below
import './RefillTable.scss';

// main component
const RefillTable = ({ setState, setShowProfile, showProfile, data, getRefillRequestlogs }) => {
  const items = useSelector(selectrefillRequestItem);

  const viewbuttonHandler = (id) => {
    const row = data.find((dd) => dd.id === id);

    setState((prev) => {
      return { ...prev, itemID: row };
    });

    const { restockID } = UseItemList(row, items);
    
    setState((prev) => {
      return { ...prev, item_list: restockID, filtered_item_list: restockID };
    });

    setShowProfile({
      ...showProfile,
      showView: true,
    });
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Request Date/Time', accessor: 'requested_at' }, // accessor is the "key" in the data
      { Header: 'Request No.', accessor: 'request_number' },
      { Header: 'Requesting Department', accessor: 'requesting_department' },
      { Header: 'No. of Items', accessor: 'number_of_items' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Issuance No', accessor: 'issuance_no' },
      { Header: 'Issued By', accessor: 'issued_by' },
      { Header: 'Action', accessor: 'action' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      data
        ? data?.map((dd) => {
            return {
              id: dd?.id,
              requested_at: Moment(dd?.attributes?.requested_at).format('MMMM Do YYYY, h:mm:ss a'),
              request_number: dd?.attributes?.request_number,
              requesting_department: dd?.attributes?.requesting_department,
              number_of_items: dd?.attributes?.number_of_items,
              status: UseStatusAction(dd?.attributes?.status),
              issuance_no: dd?.attributes?.request_number,
              issued_by: dd?.attributes?.issued_by,
              action: (
                <div className='custom-actions-refill-request'>
                  <Button
                    style={{ backgroundColor: '#03A8FF', border: 'none' }}
                    onClick={() => viewbuttonHandler(dd?.id)}
                  >
                    View Item
                  </Button>
                </div>
              ),
            };
          })
        : null,
    [data],
  );

  return (
    <Table
      data={tableData}
      logs={getRefillRequestlogs}
      columns={tableColumns}
      pagination={['BOTTOM']}
    />
  );
};

RefillTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowProfile: () => {},
  handleTabOnChange: () => {},
};

RefillTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
  handleTabOnChange: PropTypes.func,
};

export default RefillTable;
