/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */

// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import { hasData } from 'jquery';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dropdown, OverlayTrigger, Popover, Row, Col, Form } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import TableUI from 'components/UI/TableUI';
import ConditionalRender from 'components/ConditionalRender';
import { selectborrowReturn } from 'reducers/borrowReturnReducer';
import { TableFiltersPermission } from 'helpers/filteredPermissions';

// Modals
import ViewItemModal from './ViewItemModal';
import CompleteReturnModal from './CompleteReturnModal';

// import assets below
import './Table.scss';

// main component
const ReturnedItemsTable = ({ active, setActive, fetchData, setShowProfile }) => {
  // const dispatch = useDispatch();
  const borrowReturnState = useSelector(selectborrowReturn);
  const { data, items, logs } = borrowReturnState;

  const [tableList, setTableList] = useState([]);
  const [showModal, setShowModal] = useState({
    viewItem: false,
    data: null,
  });

  const defaultList = [
    { id: 1, key: '', name: 'All Items' },
    {
      id: 2,
      key: '1',
      name: 'Admissions',
      permission: '',
    },
    {
      id: 3,
      key: '2',
      name: 'Doctors',
      permission: '',
    },
    {
      id: 3,
      key: '3',
      name: 'Nursing',
      permission: '',
    },
    {
      id: 4,
      key: '4',
      name: 'Room & Bed Mgmt.',
      permission: '',
    },
    {
      id: 5,
      key: '5',
      name: 'ER Department',
      permission: '',
    },
    {
      id: 6,
      key: '6',
      name: 'OPD Department',
      permission: '',
    },
    {
      id: 7,
      key: '7',
      name: 'Laboratory',
      permission: '',
    },
    {
      id: 8,
      key: '8',
      name: 'Employees',
      permission: '',
    },
    {
      id: 9,
      key: '9',
      name: 'Billing',
      permission: '',
    },
  ];

  const lists = [];

  // eslint-disable-next-line prefer-const
  let filterList = TableFiltersPermission(defaultList, lists);

  filterList.sort((a, b) => {
    return a.id - b.id;
  });

  const handleOnClickFilter = (status) => {
    setActive(status);
    fetchData(status);
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Control Number', accessor: 'control_number' },
      { Header: 'Date / Time Return', accessor: 'date_time_return' },
      { Header: 'Department', accessor: 'department' },
      { Header: 'Item Code', accessor: 'item_code' },
      { Header: 'Item Name', accessor: 'item_name' },
      { Header: 'Item Group', accessor: 'item_group' },
      { Header: 'Borrowed By', accessor: 'borrowed_by' },
      { Header: 'Returned By', accessor: 'returned_by' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Action', accessor: 'action' },
    ],
    [],
  );

  const tableData = React.useMemo(() => {
    if (tableList) {
      // eslint-disable-next-line multiline-comment-style
      return tableList?.map((dd) => {
        const departmentID = dd?.relationships?.department?.data[0]?.id;
        const departmentData = items.find(
          (item) => parseInt(item?.id) === parseInt(departmentID) && item?.type === 'department',
        );

        const itemID = dd?.relationships?.item?.data[0]?.id;
        const itemData = items.find(
          (item) => parseInt(item?.id) === parseInt(itemID) && item?.type === 'items',
        );

        const groupID = itemData?.attributes?.group_id;
        const groupData = items.find(
          (item) => parseInt(item?.id) === parseInt(groupID) && item?.type === 'groups',
        );

        return {
          id: dd?.id,
          status_text: dd?.attributes?.status,
          item_code: itemData?.attributes?.code,
          item_name: itemData?.attributes?.name,
          item_group: groupData?.attributes?.name,
          borrowed_by: dd?.attributes?.borrowed_by,
          department: departmentData?.attributes?.name,
          control_number: dd?.attributes?.control_number,
          returned_by: dd?.attributes?.returned_by || 'N/A',
          date_time_return: moment(dd?.attributes?.borrowed_at).format('MMM-DD-YYYY hh:mm A'),
          status: (
            <span className={`status ${dd?.attributes?.status}`}>{dd?.attributes?.status}</span>
          ),
        };
      });
    }
  }, [tableList]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const { isSuccess } = logs;

    if (isSuccess) {
      setTableList(data);
    }
  }, [logs]);

  return (
    <div className='stock-adjustment-filter-table borrowed-item'>
      <TableUI>
        <Table
          data={tableData}
          columns={tableColumns}
          filter={{
            top: (
              <div className='buttons' style={{ flex: 'none', whiteSpace: 'nowrap' }}>
                {filterList.map((list) => (
                  <Button
                    key={list.key}
                    className={list.key === active ? 'active' : ''}
                    onClick={() => handleOnClickFilter(list.key)}
                  >
                    {list.name}
                  </Button>
                ))}
              </div>
            ),
          }}
          customAction={(id) => {
            const row = tableData.find((dd) => dd.id === id);
            const { status_text } = row;

            const buttons = [
              {
                text: 'View',
                color: 'blue',
                action: () => {
                  setShowModal((prevState) => {
                    return {
                      ...prevState,
                      data: row,
                      viewItem: true,
                    };
                  });
                },
                condition: status_text === 'borrowed' || status_text === 'completed',
              },
              {
                text: 'Complete',
                color: 'green',
                action: () => {
                  setShowModal((prevState) => {
                    return {
                      ...prevState,
                      data: row,
                      completeReturn: true,
                    };
                  });
                },
                condition: status_text === 'returned' || status_text === 'for return',
              },
            ];

            return (
              <div className='action-buttons'>
                {buttons.map((buttonData, idx) => {
                  return (
                    <ConditionalRender
                      key={idx}
                      condition={buttonData?.condition}
                      renderIf={
                        <span
                          className={`badge ${buttonData?.color}`}
                          style={{ cursor: 'pointer' }}
                          onClick={buttonData?.action}
                        >
                          {buttonData?.text}
                        </span>
                      }
                    />
                  );
                })}
              </div>
            );
          }}
          pagination={['BOTTOM']}
          logs={logs}
        />
      </TableUI>

      <ViewItemModal showModal={showModal} setShowModal={setShowModal} fetchData={fetchData} />
      <CompleteReturnModal
        showModal={showModal}
        setShowModal={setShowModal}
        fetchData={fetchData}
      />
    </div>
  );
};

export default ReturnedItemsTable;
