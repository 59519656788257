/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/ReactTable';
import axios from 'axios';

// import components below
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const AddDoctorStatusTable = ({ setShowModal }) => {
  const data = useSelector(selectPatientData);

  const dummyData = [
    {
      id: '000001',
      room_bed: 'ED / 201',
      patient_name: 'Juan Dela Cruz',
      age: '53',
      sex: 'Male',
      physician_name: 'Dr. JB. Dela Cruz',
      status: 'Awaiting Bed',
    },
    {
      id: '000002',
      room_bed: 'ED / 201',
      patient_name: 'Juan Dela Cruz',
      age: '53',
      sex: 'Male',
      physician_name: 'Dr. JB. Dela Cruz',
      status: 'Awaiting Bed',
    },
  ];

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Room / Bed', accessor: 'room_bed' },
      { Header: 'Age/Sex', accessor: 'age_sex' },
      { Header: 'Status', accessor: 'physician_name' },
      { Header: 'status', accessor: 'status' },
    ],
    []
  )

  const tableData = React.useMemo(
    () => dummyData?.map(dd => ({
      id: dd?.id,
      id: dd?.patient_name,
      id: dd?.room_bed,
      id: `${dd?.age} / ${dd?.sex}`,
      id: dd?.physician_name,
      id: (<span className='awaiting-bed'>{list?.status}</span>),
    })),
    [dummyData]
  )

  // const handleTableAction = (eventKey, id) => {
  //   const row = state.find(dd => dd.id === id);
  //   const action = {
  //     profile: () => { },
  //     view: () => { },
  //     edit: () => { },
  //     delete: () => { }
  //   }

  //   action[eventKey]()
  // }

  return (
    <div className='add-doctor-status-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        // actions={{ hasView: true }}
        // onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default AddDoctorStatusTable;
