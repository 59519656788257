/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Modal, Button } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './ConfirmationModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import SucessModal from './SuccessModal';
import { useState } from 'react';
import { toast } from 'react-toastify';

// main componet
// eslint-disable-next-line no-unused-vars
const ConfirmationModal = ({ setOpenModal, params, uploadedFiles, setUploadedFiles }) => {

    const [modal, setModal] = useState(false);
    const navigate = useNavigate();

    const closeModal = () => {
        setOpenModal(false);
    };

    const createNewPatient = async () => {
        await axios
            .post('/patient', params)
            .then((response) => {
                const formdata = new FormData();
                
                for (const key of Object.keys(uploadedFiles)) {
                    formdata.append('FILES', uploadedFiles[key]);
                }

                formdata.append('patientId', response.data.data[0].id);

                axios.post('/patient/file_upload', formdata)
                    .then((response) => {
                        setUploadedFiles([]);
                    }, [])
                    .catch((err) => {
                        err?.response?.data?.errors.map((item) => {
                            toast.warning(item.msg + ' ' + item.param);
                        });
                    });

                // setModal(true);
                setOpenModal(false);
                toast.success('Successfully Created new patient!');
                navigate('/dashboard/emergency-department');
            })
            .catch((err) => {
                console.log('AXIOS ERROR: ', err);
                toast.warning(err.response.data.errors.message);

                setTimeout(() => {
                    setOpenModal(false);
                }, 2000);
            });
    };

    return (
        <div className='loginModal'>
            <ModalUI>
                <Modal.Dialog>
                    <Modal.Header id='confirmation-modal-header'>
                        <h6>CONFIRMATION</h6>
                        <Button onClick={closeModal}>
                            <CloseSVG />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>

                        <p>
                            A new patient record will be created.
                        </p>
                        <p>
                            Are you sure you want to save and create new patient record?
                        </p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className='loginModal__button' onClick={createNewPatient}>
                            CREATE NEW PATIENT
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </ModalUI>
            {/* {modal && <SucessModal />} */}
        </div>
    );
};

export default ConfirmationModal;
