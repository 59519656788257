/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormControl, Button } from 'react-bootstrap';

// import components below

// import assets below

// main component
const initialFormData = {
  date_of_advance: '',
  reason_of_advance: '',
  amount_to_deduct_from_next_salaries: '',
};

const index = ({ setShowScreen }) => {
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <div className='advances-container shadow-sm'>
      <div className='box-container blue'>
        <Form.Group className='inline-group'>
          <Form.Label>ADD SALARY ADVANCE RECORD</Form.Label>
        </Form.Group>
      </div>

      <div className='box-container'>
        <div className='add-record'>
          <h4 className='mb-3'>Salary Advance Details</h4>

          <Row className='mb-3'>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Date of Advance</Form.Label>

                <FormControl
                  type='date'
                  name='date_of_advance'
                  onChange={handleChange}
                  value={formData?.date_of_advance}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Amount to Deduct from Next Salaries</Form.Label>

                <FormControl
                  type='number'
                  onChange={handleChange}
                  name='amount_to_deduct_from_next_salaries'
                  value={formData?.amount_to_deduct_from_next_salaries}
                  onKeyDown={(evt) =>
                    (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') && evt.preventDefault()
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-5'>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Reason of Advance</Form.Label>

                <FormControl
                  onChange={handleChange}
                  name='reason_of_advance'
                  value={formData?.reason_of_advance}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className='content-footer'>
              <Button className='save-button'>Save</Button>

              <Button
                className='back-button'
                onClick={() => {
                  setShowScreen(1);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default index;
