export const vaginalDeliveries = ['O80.0', 'O80.0/0', 'O80.9', 'O80.9/0'];

export const otherDeliveries = [
  'O70',
  'O70.0',
  'O70.1',
  'O70.2',
  'O70.3',
  'O70.9',
  'O74',
  'O74.0',
  'O74.1',
  'O74.2',
  'O74.3',
  'O74.4',
  'O74.5',
  'O74.6',
  'O74.7',
  'O74.8',
  'O74.9',
  'O75',
  'O75.0',
  'O75.1',
  'O75.5',
  'O75.6',
  'O75.8',
  'O75.9',
  'O77.0',
  'O77.1',
  'O86.1',
  'O86.2',
  'O86.3',
  'O86.4',
  'O96',
];

export const caesareanDeliviries = [
  'O14.9/0',
  'O15.1/0',
  'O32.1/0',
  'O32.3/0',
  'O32.4/0',
  'O32.9/0',
  'O33.9/0',
  'O34.2/0',
  'O36.3/0',
  'O44.0/0',
  'O44.1/0',
  'O45.9/1',
  'O60.1/5',
  'O60.1/2',
  'O60.2/0',
  'O60.3/0',
  'O63.1/0',
  'O63.1/1',
  'O63.1/2',
  'O63.1/3',
  'O63.9/0',
  'O63.9/1',
  'O64.1/0',
  'O64.8/0',
  'O64.9/0',
  'O64.9/1',
  'O65.4/0',
  'O68.1/0',
  'O68.9/0',
  'O68.9/1',
  'O69.0/0',
  'O69.0/1',
  'O82.0/0',
  'O82.0/1',
  'O82.0/2',
  'O82.0/3',
  'O82.0/4',
  'O82.0/5',
  'O82.0/6',
  'O82.0/7',
  'O82.0/8',
  'O82.0/9',
  'O82.0/10',
  'O82.0/11',
  'O82.0/12',
  'O82.0/13',
  'O82.0/14',
  'O82.1/0',
  'O82.1/1',
  'O82.1/2',
  'O82.1/3',
  'O82.1/4',
  'O82.1/5',
  'O82.1/6',
  'O82.1/7',
  'O82.1/8',
  'O82.1/9',
  'O82.1/10',
  'O82.1/11',
  'O82.1/12',
  'O82.1/13',
  'O82.1/14',
  'O82.1/15',
  'O82.1/16',
  'O14.9',
  'O32.3',
  'O32.4',
  'O32.9',
  'O33.9',
  'O34.2',
  'O36.3',
  'O44.0',
  'O44.1',
  'O45.9',
  'O63.1',
  'O63.9',
  'O64.8',
  'O64.9',
  'O65.4',
  'O68.1',
  'O69.0',
  'O75.7',
  'O82',
  'O82.0',
  'O82.1',
  'O82.2',
  'O82.8',
  'O82.9',
  'O84.2',
  'O84.2',
];
