// import core & vendor packages 
import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import RoomBedTabs from './components/Tabs';
import RoomBedManagementHeader from './components/PageHeader';

// import assets below
import './style.scss';

// main component
const RoomBedManagement = () => {
  const [activeTab, setActiveTab] = useState('Search Room');
  
  return (
    <Layout pageTitle='Room & Bed Management'>
      <RoomBedManagementHeader active={activeTab} />
      <RoomBedTabs onChange={setActiveTab} />
    </Layout>
  );
};

export default RoomBedManagement;
