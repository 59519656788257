/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */

// import components below
import { useSelector } from 'react-redux';
import FormEdit from './components/FormEdit';
import FormCreate from './components/FormCreate';
import ViewDetails from './components/ViewDetails';
import { selectAppointmentAction } from 'reducers/appointmentReducer';

// main Appointments
const Appointments = () => {
  const appointmentAction = useSelector(selectAppointmentAction);

  return (
    <div className='Appointment'>
      {appointmentAction.isCreate && <FormCreate />}
      {appointmentAction.isView && <ViewDetails />}
      {appointmentAction.isEdit && <FormEdit />}
    </div>
  );
};

export default Appointments;
