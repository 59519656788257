// import components below
import PropTypes from 'prop-types';
// import PageHeaderUI from 'components/UI/PageHeaderUI';

// const SocialHeader = ({ active }) => {
const SocialHeader = () => {
  // const navigate = useNavigate();

  return (
    <>
      {/* <button onClick={() => navigate(-1)} className='Back-btn'>
        <MdOutlineKeyboardArrowLeft />

        <span>Back To List</span>
      </button> */}

      <div className='SocialHeader'>
        {/* <PageHeaderUI> */}

        <h4>Admission</h4>
        <span className='ACLP-s-header'>Option to be access</span>
        {/* <p>Dashboard / Audit Logs / {active}</p> */}
        {/* </PageHeaderUI> */}
      </div>
    </>
  );
};

SocialHeader.defaultProps = {
  active: '',
};

SocialHeader.propTypes = {
  active: PropTypes.string,
};

export default SocialHeader;
