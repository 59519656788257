import { useState } from 'react';
import { Container } from 'react-bootstrap';

// import components below
import Layout from 'components/Layout';
import ReportList from './components/ReportList';
import ReportsHeader from './components/PageHeader';

// import assets below
import './style.scss';

// main component
const ReportPage = () => {
    const [activeTab] = useState('Reports');

    return (
        <Layout pageTitle='Reports'>
            <ReportsHeader active={activeTab} />
            <Container fluid>
                <ReportList />
            </Container>
    </Layout>);
};

export default ReportPage;
