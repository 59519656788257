import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import './DropdownFilter.scss';

const DropdownFilter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const options = ['Nurse', 'Doctor', 'Super Admin'];

  const handleSelect = () => {
    setIsOpen(false);
  };

  return (
    <div className='dropdown'>
      <button className='dropdown-button' onClick={() => setIsOpen(!isOpen)}>
        ALL Categories {isOpen ? <IoIosArrowUp style={{ marginLeft: '40px' }} /> : <IoIosArrowDown style={{ marginLeft: '40px' }} /> }
      </button>
      {isOpen && (
        <div className='dropdown-options'>
          {options.map((option, index) => (
            <div key={index} className='dropdown-item' onClick={() => handleSelect(option)}>
              {option}
              {index < options.length - 1 && <div className='separator'></div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownFilter;
