/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// import components below
import Select from 'react-select';
import FormUI from 'components/UI/FormUI';
import {
  selectInventory,
  submitAdjustment,
  getRequestNumberList,
  getInventoryDepartments,
} from 'reducers/inventoryReducer';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
import { ReactComponent as FileUploadSVG } from 'assets/svg/file-upload.svg';
import ConditionalRender from 'components/ConditionalRender';

// main component
const userInitialState = {
  // immutable
  id: '',
  store: '',
  uom: '',
  unit_price: '',
  on_hand: '',

  // mutable
  image: '',
  restock_id: '',
  adjustment_type: '',
  request_number: '',
  purchase_number: '',
  transaction_type: '',
  adjustment_quantity: '',
  department: '',
};

const formInitialState = {
  requestNumbers: [],
  departments: [],
};

const typeInitialState = '';

// main component
const AdjustmentModal = ({ showProfile, setShowProfile, getInventoryItems }) => {
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectInventory);
  const {
    submitAdjustmentLogs,
    getRequestNumberListData,
    getRequestNumberListLogs,
    getInventoryDepartmentsData,
    getInventoryDepartmentsLogs,
  } = inventoryState;

  const [type, setType] = useState(typeInitialState);
  const [userData, setUserData] = useState(userInitialState);

  const [formData, setFormData] = useState(formInitialState);

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (!userData?.image) {
      toast.error('Please upload image/s.');
      return;
    }

    const formData = new FormData();

    formData.append('item_id', userData?.id);
    formData.append('image', userData?.image);
    formData.append('quantity', userData?.adjustment_quantity);
    formData.append('adjustment_type', userData?.adjustment_type);

    if (type === 'received') {
      formData.append('restock_id', userData?.request_number?.id);
      formData.append('purchase_number', userData?.purchase_number);
      formData.append('transaction_type', userData?.transaction_type);
    } else {
      formData.append('department_id', userData?.department?.id);
    }

    dispatch(submitAdjustment(formData));

    const { isFailed, isSuccess, isLoading, error } = submitAdjustmentLogs;

    if (isFailed || error) {
      toast.error(error || 'Something went wrong. Please refresh the page');
      return;
    }

    toast.success('Successfully adjusted.');
  };

  const closeModal = () => {
    setShowProfile((prevState) => {
      return { ...prevState, showAdjust: false };
    });

    setFormData(formInitialState);
    setUserData(userInitialState);
    setType(typeInitialState);
  };

  const customStyles = {
    control: () => ({
      height: '50px',
      display: 'flex',
      borderRadius: '10px',
      border: '1px solid #c8c8c8',
    }),
  };

  useEffect(() => {
    dispatch(getInventoryDepartments(''));
  }, []);

  useEffect(() => {
    if (showProfile?.rowData) {
      setUserData((prevState) => {
        const { rowData } = showProfile;

        return {
          ...prevState,
          id: rowData?.id,
          store: rowData?.attributes?.name,
          uom: rowData?.attributes?.unit_of_measure,
          unit_price: rowData?.attributes?.piece_price,
          on_hand: rowData?.attributes?.quantity,
        };
      });
    }
  }, [showProfile]);

  useEffect(() => {
    if (userData?.id) {
      dispatch(getRequestNumberList(userData?.id));
    }
  }, [userData]);

  useEffect(() => {
    const { isFailed, isSuccess, isLoading, error } = getRequestNumberListLogs;

    if (isSuccess) {
      setFormData((prev) => {
        return {
          ...prev,
          requestNumbers: getRequestNumberListData.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.attributes?.request_number,
              requestNumber: data?.attributes?.request_number,
            };
          }),
        };
      });
    }

    if (isFailed) {
      toast.error(error || 'Something went wrong. Please refresh the page');
    }
  }, [getRequestNumberListLogs]);

  useEffect(() => {
    const { isFailed, isSuccess, isLoading, error } = submitAdjustmentLogs;

    if (isSuccess) {
      // toast.success('Successfully adjusted.');

      getInventoryItems();

      setUserData((prev) => {
        return {
          ...prev,
          image: '',
          restock_id: '',
          adjustment_type: '',
          request_number: '',
          purchase_number: '',
          transaction_type: '',
          adjustment_quantity: '',
          department: '',
        };
      });

      setShowProfile((prev) => {
        return {
          ...prev,
          showAdjust: false,
        };
      });
    }

    /* if (isFailed) {
      toast.error(error || 'Something went wrong. Please refresh the page');
    } */
  }, [submitAdjustmentLogs]);

  useEffect(() => {
    const { isFailed, isSuccess, isLoading, error } = getInventoryDepartmentsLogs;

    if (isSuccess) {
      setFormData((prev) => {
        return {
          ...prev,
          departments: getInventoryDepartmentsData.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.attributes?.name,
              department: data?.attributes?.name,
            };
          }),
        };
      });
    }

    if (isFailed) {
      toast.error(error || 'Failed to retrieve departments. Please refresh the page');
    }
  }, [getInventoryDepartmentsLogs]);

  useEffect(() => {
    'formData', formData;
  }, [formData]);

  // Conditions
  const isIssue = type === 'issue';
  const isReceive = type === 'received';

  const canSubmit = () => {
    if (type === '') return true;
    if (userData?.adjustment_quantity === '') return true;

    if (type === 'receive') {
      if (userData?.purchase_number === '') return true;
      if (userData?.transaction_type === '') return true;
    }

    if (type === 'issue') {
      if (userData?.department === '') return true;
    }
  };

  return (
    <Modal
      size='lg'
      show={showProfile.showAdjust}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showAdjust: false,
          };
        })
      }
      aria-labelledby='CREATE NEW ADJUSTMENT'
      className='create-new-adjustment-modal'
    >
      <Modal.Body>
        <div className='create-new-adjustment-request'>
          <div className='d-flex header'>
            <p>CREATE NEW ADJUSTMENT</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='update-form'>
            <Row>
              <Col sm={4}>
                <Form.Group className='mb-4 basic-search'>
                  <Form.Label>Item Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text className='bg-grey'>
                      <MagnifyingGlassSVG />
                    </InputGroup.Text>
                    <FormControl
                      disabled
                      type='search'
                      className='bg-grey'
                      value={userData?.store}
                      placeholder='Pharmacy (S000003)'
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4 bg-grey'>
                  <Form.Label>UOM</Form.Label>
                  <FormControl
                    disabled
                    type='text'
                    className='bg-grey'
                    placeholder='Piece'
                    value={userData?.uom}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4 bg-grey'>
                  <Form.Label>Unit Price</Form.Label>
                  <FormControl
                    disabled
                    type='text'
                    className='bg-grey'
                    placeholder='52.00'
                    value={userData?.unit_price}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4 bg-grey'>
                  <Form.Label>On Hand</Form.Label>

                  <FormControl
                    disabledtype='text'
                    className='bg-grey'
                    placeholder='12'
                    value={userData?.on_hand}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={10}>
                <Form.Group>
                  <Form.Label>Receive / Issue</Form.Label>
                  <Form.Select
                    value={userData.adjustment_type}
                    onChange={(e) => {
                      handleChange('adjustment_type')(e);
                      setType(e.target.value);
                    }}
                  >
                    <option value='' hidden>
                      Select Adjustment
                    </option>
                    <option value='received'>Receive ( + )</option>
                    <option value='issue'>Issue ( - )</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4 bg-grey'>
                  <Form.Label>Adjustment Quantity</Form.Label>
                  <FormControl
                    type='number'
                    placeholder='Enter Here'
                    value={userData.adjustment_quantity}
                    onChange={handleChange('adjustment_quantity')}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <ConditionalRender
              condition={isReceive}
              renderIf={
                <Row>
                  <Col>
                    <Form.Group className='mb-4 bg-grey'>
                      <Form.Label>Request Number</Form.Label>
                      <Select
                        value={userData?.request_number}
                        onChange={(e) => {
                          setUserData((prev) => {
                            return {
                              ...prev,
                              request_number: e,
                            };
                          });
                        }}
                        styles={customStyles}
                        className='select-form-input'
                        options={formData?.requestNumbers}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-4 bg-grey'>
                      <Form.Label>Purchase Number</Form.Label>
                      <FormControl
                        type='text'
                        placeholder='Enter Here'
                        value={userData.purchase_number}
                        onChange={handleChange('purchase_number')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              }
            />

            <ConditionalRender
              condition={isReceive}
              renderIf={
                <Row>
                  <Col>
                    <Form.Group className='mb-4 bg-grey'>
                      <Form.Label>Complete / Partial</Form.Label>
                      <Form.Select
                        value={userData.transaction_type}
                        onChange={handleChange('transaction_type')}
                      >
                        <option value='' hidden>
                          -Select-
                        </option>
                        <option value='complete'>Complete</option>
                        <option value='partial'>Partial</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>
              }
            />

            <ConditionalRender
              condition={isIssue}
              renderIf={
                <Row>
                  <Col>
                    <Form.Group className='mb-4 bg-grey'>
                      <Form.Label>Department</Form.Label>
                      <Select
                        value={userData?.department}
                        onChange={(e) => {
                          setUserData((prev) => {
                            return {
                              ...prev,
                              department: e,
                            };
                          });
                        }}
                        styles={customStyles}
                        options={formData?.departments}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    {/* <Form.Group className='mb-4 bg-grey'>
                      <Form.Label>Complete / Partial</Form.Label>
                      <Form.Select
                        value={userData.transaction_type}
                        onChange={handleChange('transaction_type')}
                      >
                        <option value='' hidden>
                          -Select-
                        </option>
                        <option value='complete'>Complete</option>
                        <option value='partial'>Partial</option>
                      </Form.Select>
                    </Form.Group> */}
                  </Col>
                </Row>
              }
            />

            <Row>
              <Col>
                <Form.Group className='upload-image mb-4 bg-grey'>
                  <p>
                    Upload Image <span>PNG and JPG files are allowed</span>
                  </p>

                  <div className='cont'>
                    <ConditionalRender
                      condition={userData?.image === ''}
                      renderIf={<FileUploadSVG />}
                    />
                    <ConditionalRender
                      condition={userData?.image !== ''}
                      renderIf={<span>{userData?.image?.name}</span>}
                    />

                    <Form.Label htmlFor='upload-image'>
                      {/* Drag and drop or <b>browse</b> your file */}
                      Click to <b>browse</b> your file
                    </Form.Label>

                    <FormControl
                      type='file'
                      id='upload-image'
                      accept='image/png, image/jpeg'
                      onChange={(e) => {
                        // eslint-disable-next-line prefer-const
                        let docuArr = [];

                        Array.from(e.target.files).forEach((file) => {
                          docuArr.push(file);
                        });

                        if (e?.target?.files[0]) {
                          setUserData((prevState) => {
                            return {
                              ...prevState,
                              image: e.target.files[0],
                            };
                          });
                        }
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    disabled={canSubmit()}
                    className='add-button'
                    variant='primary'
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                  <Button
                    className='cancel-button'
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

AdjustmentModal.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

AdjustmentModal.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default AdjustmentModal;
