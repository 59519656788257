/* eslint-disable react/jsx-indent-props */
import TableUI from 'components/UI/TableUI';
import { FormControl, InputGroup, Table } from 'react-bootstrap';

import './OutPatientTable.scss';

const OutPatientTable = (props) => {
    const { item } = props;

    return (<div className='mt-3 outpatient'>
        <TableUI>
            <Table striped hover style={{ width: '80%' }} className='mt-3'>
                <thead>
                    <tr>
                        <th>Outpatient visits (Count visits not patients)</th>
                        <th>Number</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Number of outpatient visits, new patient ( New patient-a patient seeking consult in your facility for the first time)</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesOPV.newpatient}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Number of outpatient visits, re-visit</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesOPV.revisit}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Number of outpatient visits, adult (Age 19 years old and above)</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesOPV.adult}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Number of outpatient visits, pediatrics ( Age 0 to 18 yrs old; before 19th birthday)</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesOPV.pediatric}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Number of adult general medicine outpatient visits</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesOPV.adultgeneralmedicine}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Number of specialty (non-surgical) outpatient visits (Ex. ENT, Ophthalmology, Urology, Oncology, etc.)</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesOPV.specialtynonsurgical}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Number of surgical outpatient visits</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesOPV.surgical}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Number of antenatal/prenatal care visits</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesOPV.antenatal}
                            />
                        </InputGroup></td>
                    </tr>
                    <tr>
                        <td>Number of postnatal care visits. Until the 42ndday post partum/post delivery.</td>
                        <td><InputGroup className='mb-1 mt-1'>
                            <FormControl
                                placeholder='Enter Here'
                                disabled
                                value={item.hospOptDischargesOPV.postnatal}
                            />
                        </InputGroup></td>
                    </tr>
                </tbody>
            </Table>
        </TableUI>

        <TableUI>
            <Table striped hover style={{ width: '80%' }}>
                <thead>
                    <tr>
                        <th>Ten Leading OPD Consultations</th>
                        <th>Number</th>
                        <th>ICD-10 Code (Tabular)</th>
                    </tr>
                </thead>
                <tbody>
                    {item.hospOptDischargesOPD?.map((item, index) => (
                        <tr key={index + 1}>
                            <td>{index + 1}. {item.name}</td>
                            <td><InputGroup className='mb-1 mt-1'>
                                <FormControl
                                    placeholder='Enter Here'
                                    disabled
                                    value={item.number}
                                />
                            </InputGroup></td>
                            <td><InputGroup className='mb-1 mt-1'>
                                <FormControl
                                    placeholder='Enter Here'
                                    disabled
                                    value={item.icd_code}
                                />
                            </InputGroup></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </TableUI>
    </div>);
};

export default OutPatientTable;
