/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

// import core & vendor packages below
import uuidv4 from 'react-uuid';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormControl, Button } from 'react-bootstrap';

// import assets below
import { ReactComponent as XmarkSVG } from 'assets/svg/circle-xmark-red.svg';

// main component
const initialFormData = {
  penalties: '',
  department: '',
  employee_id: '',
  designation: '',
  employee_number: '',
  charges: [
    {
      details: '',
      id: uuidv4(),
    },
  ],
};

const index = ({ setShowScreen }) => {
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleChangeMulti = (e, id) => {
    const { name, value } = e.target;

    const newData = formData.charges.map((dd) => {
      if (dd?.id === id) {
        return {
          ...dd,
          [name]: value,
        };
      }
      return dd;
    });

    setFormData((prev) => {
      return {
        ...prev,
        charges: newData,
      };
    });
  };

  const handleAddField = () => {
    const newField = formData?.charges.concat({
      type: '',
      details: '',
      id: uuidv4(),
    });

    console.log('result', newField);

    setFormData((prev) => {
      return {
        ...prev,
        charges: newField,
      };
    });
  };

  const handleRemoveField = (id) => {
    const filteredData = formData.charges.filter((dd) => dd?.id !== id);

    setFormData((prev) => {
      return {
        ...prev,
        charges: filteredData,
      };
    });
  };

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  return (
    <div className='charges-container shadow-sm'>
      <div className='box-container blue'>
        <Form.Group className='inline-group'>
          <Form.Label>ADD RECORDS</Form.Label>
        </Form.Group>
      </div>

      <div className='box-container'>
        <div className='add-record'>
          <h4 className='mb-3'>Input Employee Details</h4>

          <Row className='mb-3'>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Employee ID</Form.Label>

                <FormControl
                  name='employee_id'
                  onChange={handleChange}
                  value={formData?.employee_id}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Employee Number</Form.Label>

                <FormControl
                  name='employee_number'
                  onChange={handleChange}
                  value={formData?.employee_number}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Department</Form.Label>

                <Form.Select name='department' onChange={handleChange} value={formData?.department}>
                  <option value='' hidden>
                    - Select -
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Designation</Form.Label>

                <Form.Select
                  name='designation'
                  onChange={handleChange}
                  value={formData?.designation}
                >
                  <option value='' hidden>
                    - Select -
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Penalties</Form.Label>

                <Form.Select name='penalties' value={formData?.penalties} onChange={handleChange}>
                  <option value='' hidden>
                    - Select -
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>

          {formData?.charges &&
            formData?.charges.length > 0 &&
            formData?.charges.map((data) => {
              return (
                <Row key={data?.id} className='mb-3'>
                  <Col>
                    <Form.Group className='normal-group'>
                      <Form.Label>
                        You are Hereby Charged with the following acts of Misconduct.
                      </Form.Label>

                      <div className='charges-list-container'>
                        <FormControl
                          name='details'
                          value={data?.details}
                          onChange={(e) => handleChangeMulti(e, data?.id)}
                        />

                        {formData?.charges.length > 1 && (
                          <XmarkSVG
                            className='x-mark'
                            onClick={() => handleRemoveField(data?.id)}
                          />
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}

          <Row className='mb-5'>
            <Col>
              <Button className='add-field' onClick={handleAddField}>
                Add Field
              </Button>
            </Col>
          </Row>

          <Row>
            <Col className='content-footer'>
              <Button className='save-button'>Save</Button>

              <Button
                className='back-button'
                onClick={() => {
                  setShowScreen(1);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default index;
