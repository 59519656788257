/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getProcedures } from './thunks/notificationThunks';

const initialLogs = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  // For Patients
  procedures: [],
  procedureLogs: initialLogs,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    resetLogState: (state, action) => {
      state.procedureLogs = initialLogs;
    },
  },
  extraReducers: {
    // For Procedures
    [getProcedures.fulfilled]: (state, action) => {
      state.procedures = action.payload.data;
      state.procedureLogs = { ...state.procedureLogs, isSuccess: true, isLoading: false };
    },
    [getProcedures.pending]: (state) => {
      state.procedureLogs = {
        ...state.procedureLogs,
        isFailed: false,
        isLoading: true,
        isSuccess: false,
        error: '',
      };
    },
    [getProcedures.rejected]: (state, action) => {
      state.procedures = [];
      state.procedureLogs = { ...state.procedureLogs, isFailed: true, isLoading: false };
    },
  },
});

export const { actions: notificationActions, reducer: notificationReducer } = slice;

export const { resetLogState } = notificationActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.notification || initialState;

export const selectNotification = createSelector(
  [selectDomain],
  (notificationState) => notificationState,
);

/* --------------------------------------------------------------------------------- */

export { getProcedures };
