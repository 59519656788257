/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Row, Col, Table, Button, Form } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';

// import assets below
import './RoomTable.scss';

import { useEffect, useState } from 'react';
import axios from 'axios';

// main component
const RoomTable = ({ roomList, setSelectedRoom }) => {
    const [rooms, setRoom] = useState([]);
    const [state, setState] = useState({
        selected_room: '',
        floor_number: '',
        pricing_scheme: '',
        price: '',
    });

    const roomStatus = (status) => {
        switch (status) {
            case 'available':
                return <span className='badge bg-success'>{status.toUpperCase()}</span>;
            case 'occupied':
                return <span className='badge bg-info'>{status.toUpperCase()}</span>;
            case 'cleaning':
                return <span className='badge bg-warning'>{status.toUpperCase()}</span>;
            case 'unavailable':
                return <span className='badge bg-danger'>{status.toUpperCase()}</span>;
            default:
                return <span className='badge bg-secondary'>{status.toUpperCase()}</span>;
        }
    };

    useEffect(() => {
        axios
            .get('/room')
            .then((response) => {
                setRoom(response.data.data);
            }, [])
            .catch((err) => {
                console.log('AXIOS ERROR: ', err);
            });
    }, []);

    const getSelectedRoom = async (event, selectedRoom, floorNumber, pricingScheme, price, id) => {
        setSelectedRoom({ ...setSelectedRoom, selected_room: selectedRoom, floor_number: selectedRoom, floor_price_scheme: pricingScheme, price: price, roomId: id });
        setState({ ...state, selected_room: selectedRoom, floor_number: floorNumber, pricing_scheme: pricingScheme, price: price });
    };

    return (
        <div className='createInpatientBedTable'>
            <TableUI>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Floor</th>
                            <th>Unit</th>
                            <th>Room</th>
                            <th>Bed</th>
                            <th>Status</th>
                            <th>Reserved</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {roomList?.length > 0 ? roomList?.map((item, index) => {

                            return (
                                <tr key={index}>
                                    <td>{item.type}</td>
                                    <td>{item.floor}</td>
                                    <td>{item.unit}</td>
                                    <td>{item.room}</td>
                                    <td>{item.bed}</td>
                                    <td> {roomStatus(item.status)}</td>
                                    <td>{item.isReserved}</td>
                                    <td><Button variant='action' onClick={event => getSelectedRoom(event, item.type, item.unit, item.pricing_scheme, item.price, item.id)} disabled={item.status !== 'available'}>Select</Button></td>
                                </tr>
                            );
                        })
                            :
                            rooms?.room_list?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.type}</td>
                                        <td>{item.floor}</td>
                                        <td>{item.unit}</td>
                                        <td>{item.room}</td>
                                        <td>{item.bed}</td>
                                        <td> {roomStatus(item.status)}</td>
                                        <td>{item.isReserved}</td>
                                        {/* <td><Button variant='action' onClick={selectRoom}>Select</Button></td> */}
                                        <td><Button variant='action' onClick={event => getSelectedRoom(event, item.type, item.floor, item.pricing_scheme, item.price, item.id)} disabled={item.status !== 'available'}>Select</Button></td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
                <Row className='mt-4'>
                    <Col>
                        <Form.Group>
                            <Form.Label>Selected Room / Bed</Form.Label>
                            <Form.Control type='text' value={state.selected_room} disabled />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Floor / Unit</Form.Label>
                            <Form.Control type='text' value={state.floor_number} disabled />
                        </Form.Group>
                    </Col>
                    {/* <Col>
                        <Form.Group>
                            <Form.Label>Floor Pricing Scheme</Form.Label>
                            <Form.Control type='text' value={state.pricing_scheme} disabled />
                        </Form.Group>
                    </Col> */}
                    <Col>
                        <Form.Group>
                            <Form.Label>Price</Form.Label>
                            <Form.Control type='text' value={state.price} disabled />
                        </Form.Group>
                    </Col>
                </Row>
            </TableUI>
        </div>
    );
};

export default RoomTable;
