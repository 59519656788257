// Roles and Departments

const Tabs = {
    MEDICALRECORD: {
        ACTIVE: 'active',
        DISCHARGED: 'discharged',
        OUTPATIENT: 'outpatient',
        ARCHIVE: 'archive',
        FORMS: 'forms',
    },
};

export default Tabs;
