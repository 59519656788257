const BgStatusColor = {
  'Out of Stock': 'red',
  'In Stock': 'green',
  'completed': 'green',
  'new': 'blue', 
  'in progress': 'blue',
  'approved': 'blue',
  'for issuance': 'darkblue',
  'for receiving': 'darkblue',
  'denied': 'red',
};

export default BgStatusColor;