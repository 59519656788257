/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import TableUI from 'components/UI/TableUI';

// import assets below
import './RoomTable.scss';
import RoomInfoModal from './Modal';

// main component
const RoomTable = ({ roomList, setSelectedRoom }) => {
    const [rooms, setRoom] = useState([]);
    const [roomInfo, setRoomInfo] = useState({});
    const [state, setState] = useState({
        selected_room: '',
        unit: '',
        pricing_scheme: '',
        price: '',
        roomId: '',
        viewRoom: false,
    });

    const roomStatus = (status) => {
        switch (status) {
            case 'available':
                return <span className='badge bg-success'>{status.toUpperCase()}</span>;
            case 'occupied':
                return <span className='badge bg-info'>{status.toUpperCase()}</span>;
            case 'cleaning':
                return <span className='badge bg-warning'>{status.toUpperCase()}</span>;
            case 'unavailable':
                return <span className='badge bg-danger'>{status.toUpperCase()}</span>;
            default:
                return <span className='badge bg-secondary'>{status.toUpperCase()}</span>;
        }
    };

    useEffect(() => {
        axios
            .get('/room')
            .then((response) => {
                setRoom(response?.data.data.room_list);
            }, [])
            .catch((err) => {
                console.log('AXIOS ERROR: ', err);
            });
    }, []);

    const tableColumns = React.useMemo(
        () => [
            { Header: 'ID', accessor: 'id' }, // accessor is the "key" in the data
            { Header: 'Type', accessor: 'type' },
            { Header: 'Floor', accessor: 'floor' },
            { Header: 'Room', accessor: 'room' },
            { Header: 'Bed', accessor: 'bed' },
            { Header: 'Price', accessor: 'price' },
            { Header: 'Status', accessor: 'status' },
            { Header: 'Action', accessor: 'action' },
        ],
        [],
    );

    const handleTableAction = (id) => {
        const row = rooms.find(dd => dd.id === id);
        if (row.status === 'available') {
            setSelectedRoom({
                ...setSelectedRoom,
                selected_room: row.room,
                unit: row.room,
                pricing_scheme: row.price,
                price: row.price,
                roomId: id,
            });
            setState({
                ...state,
                selected_room: row.room,
                floor_number: row.floor,
                pricing_scheme: row.price,
                price: row.price,
            });
        } else {
            axios
                .get(`/room/${id}`)
                .then((response) => {
                    setRoomInfo(response.data.data);
                }, [])
                .catch((err) => {
                    console.log('AXIOS ERROR: ', err);
                });
            setState({
                ...state,
                viewRoom: true,
                roomId: id,
            });
        }

    };

    const tableData = React.useMemo(
        () =>
            rooms.map((dd) => ({
                id: dd.id,
                bed: dd.bed,
                type: dd.type,
                room: dd.room,
                floor: dd.floor,
                price: dd.price,
                status: roomStatus(dd.status),
                action: <Button variant='action' onClick={() => { handleTableAction(dd.id); }}>
                    {dd.status === 'available' && 'Select'}
                    {dd.status === 'occupied' && 'View'}
                </Button>,
            })),
        [rooms],
    );

    return (
        <div className='createInpatientBedTable'>
            <Table data={tableData} columns={tableColumns} pagination={['BOTTOM']} onAction={handleTableAction} />
            <Row className='mt-4'>
                <Col>
                    <Form.Group>
                        <Form.Label>Selected Room / Bed <span className='asterisk'>*</span></Form.Label>
                        <Form.Control type='text' value={state.selected_room} disabled />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Floor / Unit <span className='asterisk'>*</span></Form.Label>
                        <Form.Control type='text' value={state.floor_number} disabled />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Price <span className='asterisk'>*</span></Form.Label>
                        <Form.Control type='text' value={state.price} disabled />
                    </Form.Group>
                </Col>
            </Row>
            <RoomInfoModal state={state} setState={setState} roomInfo={roomInfo} />
        </div>
    );
};

export default RoomTable;
