/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import Procedure from './Procedure';
import TabsUI from 'components/UI/TabsUI';
import RequestLaboratory from './RequestLaboratory';
import PharmacyInventory from './PharmacyInventory';

// main component
const RequisitionTabs = () => {
  const [activeTab, setActiveTab] = useState('Request Laboratory');

  const handleTabOnChange = (eventKey) => {
    setActiveTab(eventKey);
  };
  return (
    <div className='RequisitionTabs'>
      <TabsUI>
        <Tabs defaultActiveKey='Request Laboratory' onSelect={(eventKey) => handleTabOnChange(eventKey)}>
          <Tab eventKey='Request Laboratory' title='Request Laboratory'>
            {activeTab === 'Request Laboratory' && <RequestLaboratory />}
          </Tab>
          {/* <Tab eventKey='Pharmacy & Inventory' title='Pharmacy & Inventory'>
            {activeTab === 'Pharmacy & Inventory' && <PharmacyInventory />}
          </Tab> */}
          <Tab eventKey='Procedure' title='Procedure'>
            {activeTab === 'Procedure' && <Procedure />}
          </Tab>
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default RequisitionTabs;
