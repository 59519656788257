/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// components
import FormUI from 'components/UI/FormUI';
import { showPatient } from 'reducers/patientReducer';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as RedXMark } from 'assets/svg/circle-xmark-red.svg';

// diagnosis
import { vaginalDeliveries, otherDeliveries, caesareanDeliviries } from 'helpers/diagnosis';

// main component
const ModalAddDiagnosis = ({ showProfile, setShowProfile }) => {
  const dispatch = useDispatch();

  const [isNewBorn, setIsNewBorn] = useState(false);
  const [causeOfDeathOptions, setCauseOfDeathOptions] = useState([]);
  const [userData, setUserData] = useState({
    newborn: [],
    remarks: '',
    patientId: '',
    infection: '',
    ICD_10_code: '',
    diagnosis_type: '',
    final_diagnosis: '',
    discharge_diagnosis: '',
    associated_infection: '',
  });

  const handleChange = (key) => (e) => {
    if (e.target.type === 'radio') {
      setUserData({
        ...userData,
        [key]: e.target.value,
      });

      return;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const handleDropdownValue = (event, field) => {
    switch (field) {
      case 'infection':
        setUserData({ ...userData, infection: event.target.value });
        break;
    }
  };

  const filterOptions = (input) => {
    return causeOfDeathOptions.filter((i) => {
      return (
        i.value.toLowerCase().includes(input.toLowerCase()) ||
        i.label.toLowerCase().includes(input.toLowerCase())
      );
    });
  };

  const loadOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(input));
      }, 1000);
    });
  };

  const checkForDeliveryRelated = (icddesc) => {
    if (icddesc.includes('caesarean')) return true;
    if (icddesc.includes('cesarean')) return true;
    if (icddesc.includes('delivery')) return true;
  };

  const handleICDCodes = (icddesc, icdcode, icdcat) => {
    // icdcode ang gamitin
    const allDeliveries = vaginalDeliveries.concat(otherDeliveries, caesareanDeliviries);

    // const isDeliveryRelated = allDeliveries.includes(icdcode);
    const isDeliveryRelated = checkForDeliveryRelated(icddesc);

    setIsNewBorn(isDeliveryRelated);

    if (isDeliveryRelated) {
      setUserData({
        ...userData,
        ICD_10_code: icdcode,
        discharge_diagnosis: icddesc,
        icd_category: icdcat,
        newborn: [
          {
            category: '',
            fname: '',
            mname: '',
            lname: '',
          },
        ],
      });
    } else {
      setUserData({
        ...userData,
        ICD_10_code: icdcode,
        discharge_diagnosis: icddesc,
        icd_category: icdcat,
        newborn: [],
      });
    }
  };

  // Submit data here
  const handleSubmit = async () => {
    await axios
      .post('/discharge_diagnosis', userData)
      .then((response) => {
        dispatch(showPatient(showProfile?.profileId));
        
        toast.success('Successfully added diagnosis to patient');
        setUserData({
          remarks: '',
          newborn: [],
          infection: '',
          ICD_10_code: '',
          diagnosis_type: '',
          final_diagnosis: '',
          discharge_diagnosis: '',
          associated_infection: '',
          patientId: showProfile?.profileId,
        });
      })
      .catch((err) => {
        err.response.data.errors.map((item) => {
          toast.warning(item?.msg + ' ' + item.param);
        });
      });
  };

  const handleNewBorn = (data, idx, type) => (e) => {
    const { value } = e.target;

    const newData = userData?.newborn.map((dd, index) => {
      if (idx === index) {
        return {
          ...dd,
          [type]: value,
        };
      }
      return dd;
    });

    setUserData((prev) => {
      return {
        ...prev,
        newborn: newData,
      };
    });
  };

  const handleAddDetails = () => {
    const newData = {
      category: '',
      fname: '',
      mname: '',
      lname: '',
    };

    const newBornData = userData?.newborn?.concat(newData);

    setUserData((prev) => {
      return {
        ...prev,
        newborn: newBornData,
      };
    });
  };

  const handleRemove = (idx) => {
    const newData = userData?.newborn.filter((dd, index) => index !== idx);

    setUserData((prev) => {
      return {
        ...prev,
        newborn: newData,
      };
    });
  };

  useEffect(() => {
    if (showProfile) {
      setUserData({ ...userData, patientId: showProfile?.profileId });
    }
  }, [showProfile]);

  useEffect(() => {
    if (showProfile.isShowDiagnosis) {
      axios
        .get(`/public/ricd10${showProfile?.status === 'admitted' ? '' : '?filter=no-deliveries'}`)
        .then((response) => {
          const options = response.data?.map((data) => ({
            id: data.id,
            value: data.icd10_code,
            label: data.icd10_desc,
            category: data.icd10_cat,
          }));
          setCauseOfDeathOptions(options);
        });
    }
  }, [showProfile]);

  useEffect(() => {
    // console.log('userData.newborn', userData.newborn);
  }, [userData.newborn]);

  return (
    <Modal
      size='lg'
      aria-labelledby='Diagnosis'
      className='add-diagnosis-modal'
      show={showProfile.isShowDiagnosis}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            status: '',
            isShowDiagnosis: false,
          };
        })
      }
    >
      <Modal.Body>
        <div className='add-diagnosis-request'>
          <div className='d-flex header'>
            <p>
              DIAGNOSIS{' '}
              {/* {!new RegExp('doctors', 'g').test(location.pathname) && showProfile?.profileId} */}
            </p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowProfile((prevState) => {
                  return { ...prevState, status: '', isShowDiagnosis: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col sm={6}>
                <Form.Group className='mb-4'>
                  <Form.Label>ICD - 10 Codes</Form.Label>
                  <div style={{ width: '70%', margin: '0 auto' }}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      styles={selectStyles}
                      loadOptions={loadOptions}
                      placeholder='Search Diagnosis'
                      onChange={(e) => handleICDCodes(e.label, e.value, e.category)}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Final Diagnosis</Form.Label>
                  <div className='inline-check'>
                    <Form.Check
                      type={'radio'}
                      label={`Yes`}
                      name={'Final Diagnosis'}
                      value='Yes'
                      checked={userData.final_diagnosis === 'Yes'}
                      onChange={handleChange('final_diagnosis')}
                    />

                    <Form.Check
                      type={'radio'}
                      label={`No`}
                      name={'Final Diagnosis'}
                      value='No'
                      checked={userData.final_diagnosis === 'No'}
                      onChange={handleChange('final_diagnosis')}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Diagnosis Type</Form.Label>
                  <div className='inline-check'>
                    <Form.Check
                      type={'radio'}
                      label={`Primary`}
                      name={'Diagnosis Type'}
                      value='Primary'
                      checked={userData.diagnosis_type === 'Primary'}
                      onChange={handleChange('diagnosis_type')}
                    />

                    <Form.Check
                      type={'radio'}
                      label={`Secondary`}
                      name={'Diagnosis Type'}
                      value='Secondary'
                      checked={userData.diagnosis_type === 'Secondary'}
                      onChange={handleChange('diagnosis_type')}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Healthcare Associated Infection</Form.Label>
                  <div className='vertical-group'>
                    <Form.Check
                      type={'radio'}
                      label={`Device Related Infections`}
                      name={'Healthcare Associated Infection'}
                      value='Device Related Infections'
                      checked={userData.associated_infection === 'Device Related Infections'}
                      onChange={(e) => {
                        setUserData((prevState) => {
                          return { ...prevState, associated_infection: e.target.value };
                        });
                      }}
                    />

                    <Form.Check
                      type={'radio'}
                      label={`Non-Device Related Infections`}
                      name={'Healthcare Associated Infection'}
                      value='Non-Device Related Infections'
                      checked={userData.associated_infection === 'Non-Device Related Infections'}
                      onChange={(e) => {
                        setUserData((prevState) => {
                          return { ...prevState, associated_infection: e.target.value };
                        });
                      }}
                    />
                  </div>
                </Form.Group>

                {userData.associated_infection === 'Device Related Infections' && (
                  <Form.Group className='mb-4'>
                    <Form.Label>Select Infection</Form.Label>
                    <Form.Select
                      onChange={(event) => handleDropdownValue(event, 'infection')}
                      value={userData.lab_test}
                    >
                      <option hidden>-Select-</option>
                      <option value='Ventilator Associated Pneumonia'>
                        Ventilator Associated Pneumonia
                      </option>
                      <option value='Blood Stream Infection'>Blood Stream Infection</option>
                      <option value='Urinary Tract Infections with Catheter'>
                        Urinary Tract Infections with Catheter
                      </option>
                    </Form.Select>
                  </Form.Group>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Form.Group className='mb-4'>
                  <Form.Label>ICD CODE</Form.Label>
                  <Form.Control type='text' readOnly value={userData.ICD_10_code} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Discharge Diagnosis</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={3}
                    className='bg-white'
                    disabled
                    value={userData.discharge_diagnosis}
                    onChange={handleChange('discharge_diagnosis')}
                  />
                </Form.Group>
              </Col>
            </Row>

            {isNewBorn && (
              <Row>
                <Col>
                  {userData?.newborn.map((data, idx) => {
                    return (
                      <>
                        <Form.Group key={idx} className={`mb-4 ${idx > 0 ? 'line-top' : ''}`}>
                          <Form.Label>
                            Newborn Information{' '}
                            {userData?.newborn.length > 1 ? (
                              <RedXMark
                                onClick={() => {
                                  handleRemove(idx);
                                }}
                                className='x-mark-red'
                              />
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Select
                            value={data?.category}
                            onChange={handleNewBorn(data, idx, 'category')}
                          >
                            <option value='' hidden>
                              Select Type
                            </option>
                            <option value='pathologic'>Pathologic</option>
                            <option value='non-pathologic'>Non Pathologic</option>
                          </Form.Select>
                        </Form.Group>

                        <Row>
                          <Col>
                            <Form.Group key={idx} className='mb-4'>
                              <Form.Label>First Name</Form.Label>
                              <FormControl
                                value={data?.fname}
                                onChange={handleNewBorn(data, idx, 'fname')}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group key={idx} className='mb-4'>
                              <Form.Label>Middle Name</Form.Label>
                              <FormControl
                                value={data?.mname}
                                onChange={handleNewBorn(data, idx, 'mname')}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group key={idx} className='mb-4'>
                              <Form.Label>Last Name</Form.Label>
                              <FormControl
                                value={data?.lname}
                                onChange={handleNewBorn(data, idx, 'lname')}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    );
                  })}

                  <Button className='mb-5 add-details' onClick={handleAddDetails}>
                    Add another details
                  </Button>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Remarks</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={3}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.remarks}
                    onChange={handleChange('remarks')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() =>
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          isShowDiagnosis: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.45em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

ModalAddDiagnosis.defaultProps = {
  showProfile: () => {},
  setShowProfile: () => {},
};

ModalAddDiagnosis.propTypes = {
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default ModalAddDiagnosis;
