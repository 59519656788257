/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

import Table from 'components/ReactTable';
import LeaveCreditsModal from './components/Modal';
import { taskStatus } from 'helpers';

import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

const state = [
  {
    id: '001',
    task_name: 'Task Name 1',
    assigned: 'Juan Dela Cruz',
    status: 'Ongoing',
    start_date: 'January 5, 2023',
    due_date: 'April 10, 2023',
    description: 'DETAILS HERE',
  },
  {
    id: '002',
    task_name: 'Task Name 2',
    assigned: 'Juan Dela Cruz',
    status: 'Completed',
    start_date: 'January 5, 2023',
    due_date: 'April 10, 2023',
    description: 'DETAILS HERE',
  },
];

const index = ({ setShowScreen }) => {
  const [openModal, setOpenModal] = useState();

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Task Name', accessor: 'task_name' },
      { Header: 'Assigned', accessor: 'assigned' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Start Date', accessor: 'start_date' },
      { Header: 'Due Date', accessor: 'due_date' },
      { Header: 'Description', accessor: 'description' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        task_name: dd?.task_name,
        assigned: dd?.assigned,
        status: taskStatus(dd?.status),
        start_date: dd?.start_date,
        due_date: dd?.due_date,
        description: dd?.description,
      })),
    [state],
  );

  return (
    <div className='leave-credits'>
      <div className='header'>
        <h2>List of Task</h2>

        <div className='functions'>
          <Button className='upload' onClick={() => setShowScreen(2)}>
            <AddSVG />
            Add Task
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
        />
      </div>

      <LeaveCreditsModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default index;
