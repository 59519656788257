// import components below
import PropTypes from 'prop-types';
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const SocialHeader = ({ active }) => {
    return (
        <div className='SocialHeader'>
            <PageHeaderUI>
                <h4>Social Services Module</h4>
                <p>Dashboard / Social Services / {active}</p>
            </PageHeaderUI>
        </div>
    );
};

SocialHeader.defaultProps = {
    active: '',
};

SocialHeader.propTypes = {
    active: PropTypes.string,
};

export default SocialHeader;
