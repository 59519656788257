/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getAllEmployeeSchedules,
  createEmployeeSchedule,
  updateEmployeeSchedule,
  deleteEmployeeScheduleById,
  deleteEmployeeSchedulesByEmployeeId,
} from './thunks/employeeScheduleThunks';
import _ from 'lodash';

const initialState = {
  data: [],
  isLoading: true,
};

const slice = createSlice({
  name: 'employeeSchedule',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllEmployeeSchedules.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllEmployeeSchedules.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [getAllEmployeeSchedules.rejected]: (state) => {
      state.data = [];
      state.isLoading = false;
    },
    /* ------------------------------------------- */
    [createEmployeeSchedule.pending]: (state) => {
      state.isLoading = true;
    },
    [createEmployeeSchedule.fulfilled]: (state, action) => {
      state.data.push(action.payload.data);
      state.isLoading = false;
    },
    [createEmployeeSchedule.rejected]: (state) => {
      state.isLoading = false;
    },
    /* ------------------------------------------- */
    [updateEmployeeSchedule.pending]: (state) => {
      state.isLoading = true;
    },
    [updateEmployeeSchedule.fulfilled]: (state, action) => {
      if (action.payload.status.toLowerCase() === 'success') {
        let copyData = _.cloneDeep(state.data);
        const copySchedulesData = _.flatten(copyData.map((dd) => dd.employee_schedules));
        const scheduleData = copySchedulesData.find((e) => e.id !== action.payload.id);
        let newData = copyData;

        if (scheduleData.employeeId) {
          const idx = copyData.findIndex((e) => e.id === scheduleData.employeeId);

          if (idx !== -1) {
            newData[idx] = {
              ...newData[idx],
              employee_schedules: [
                ...newData[idx].employee_schedules.map((schedule) => {
                  if (schedule.id !== action.payload.id) return schedule;
                  else return { ...schedule, ...action.payload.data };
                }),
              ],
            };
            state.data = newData;
          }
        }
      }
      state.isLoading = false;
    },
    [updateEmployeeSchedule.rejected]: (state) => {
      state.isLoading = false;
    },
    /* ------------------------------------------- */
    [deleteEmployeeScheduleById.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteEmployeeScheduleById.fulfilled]: (state, action) => {
      if (action.payload.status.toLowerCase() === 'success') {
        const copyData = _.cloneDeep(state.data);
        const copySchedulesData = _.flatten(copyData.map((dd) => dd.employee_schedules));
        const scheduleData = copySchedulesData.find((e) => e.id === action.payload.id);
        let newData = copyData;

        if (scheduleData.employeeId) {
          const idx = copyData.findIndex((e) => e.id === scheduleData.employeeId);

          if (idx > -1) {
            newData[idx] = {
              ...newData[idx],
              employee_schedules: [
                ...newData[idx].employee_schedules.filter((e) => e.id !== action.payload.id),
              ],
            };
            state.data = newData;
          }
        }
      }
      state.isLoading = false;
    },
    [deleteEmployeeScheduleById.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteEmployeeSchedulesByEmployeeId.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteEmployeeSchedulesByEmployeeId.fulfilled]: (state, action) => {
      if (action.payload.status.toLowerCase() === 'success') {
        const copyData = _.cloneDeep(state.data);
        const newData = copyData.filter((e) => e.id !== action.payload.id);
        state.data = newData;
      }
      state.isLoading = false;
    },
    [deleteEmployeeSchedulesByEmployeeId.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { actions: employeeScheduleActions, reducer: employeeScheduleReducer } = slice;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.employeeSchedule || initialState;

export const selectEmployeeSchedules = createSelector(
  [selectDomain],
  (employeeScheduleState) => employeeScheduleState,
);

export const selectEmployeeSchedulesData = createSelector(
  [selectDomain],
  (employeeScheduleState) => employeeScheduleState.data,
);

/* --------------------------------------------------------------------------------- */

export {
  getAllEmployeeSchedules,
  createEmployeeSchedule,
  updateEmployeeSchedule,
  deleteEmployeeScheduleById,
  deleteEmployeeSchedulesByEmployeeId,
};
