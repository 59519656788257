/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getAssignedDoctors,
  submitProfessionalFee,
} from './thunks/professionalFeeThunks';

const initialState = {
  data: [],
  logs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  updateLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'professionalFee',
  initialState,
  reducers: {},
  extraReducers: {
    // For Get
    [getAssignedDoctors.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
    },
    [getAssignedDoctors.pending]: (state) => {
      state.logs.isSuccess = false;
      state.logs.isFailed = false;
      state.logs.error = '';
      state.logs.isLoading = true;
    },
    [getAssignedDoctors.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
    },

    // For Update
    [submitProfessionalFee.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [submitProfessionalFee.pending]: (state) => {
      state.updateLogs.isSuccess = false;
      state.updateLogs.isFailed = false;
      state.updateLogs.error = '';
      state.updateLogs.isLoading = true;
    },
    [submitProfessionalFee.rejected]: (state) => {
      state.updateLogs.isLoading = false;
      state.updateLogs.isFailed = true;
    },
  },
});

export const { actions: professionalFeeActions, reducer: professionalFeeReducer } = slice;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.professionalFee || initialState;

export const selectprofessionalFee = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState,
);

/* --------------------------------------------------------------------------------- */

export { getAssignedDoctors, submitProfessionalFee };
