/* eslint-disable */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Form, Pagination } from 'react-bootstrap';
import _ from 'lodash';

// import components below
import PaginationUI from 'components/UI/PaginationUI';
import { PAGE_SIZE } from 'components/ReactTable';

// import assets below
import './Pagination.scss';
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/angle-left.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/svg/angle-right.svg';

// main component
const TablePagination = ({
  total,
  pageSize,
  gotoPage,
  nextPage,
  pageCount,
  setPageSize,
  canNextPage,
  previousPage,
  canPreviousPage,
  ...resProps
}) => {
  const sliceData = (data, page, length) => {
    if (page === 0 || page <= Math.round(length / 2)) {
      return data.slice(0, length);
    } else if (page >= data.length - Math.round(length / 2)) {
      return data.slice(data.length - length, data.length);
    } else return data.slice(page - Math.round(length / 2), page + Math.round(length / 2));
  };

  let range = _.range(1, pageCount - 1);
  let stepper = sliceData(range, resProps?.state?.pageIndex, 5);

  return (
    <div className='manda-pagination'>
      <PaginationUI>
        <span className='summary'>
          {total} Result{!!total && 's'}
        </span>
        <div className='selectWrapper'>
          <Form.Label>Show: </Form.Label>
          <Form.Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {PAGE_SIZE.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select>
        </div>
        <Pagination>
          <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </Pagination.First>
          <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage}>
            <ArrowLeftSVG />
          </Pagination.Prev>
          {pageCount && pageCount > 5 ? (
            <>
              <Pagination.Item
                onClick={() => gotoPage(0)}
                className={resProps?.state?.pageIndex === 0 ? 'active' : ''}
              >
                1
              </Pagination.Item>
              {resProps?.state?.pageIndex >= 5 && <Pagination.Ellipsis />}
              {(stepper || []).map((count) => (
                <Pagination.Item
                  key={`count-${count}`}
                  onClick={() => gotoPage(count)}
                  className={resProps?.state?.pageIndex === count ? 'active' : ''}
                >
                  {count + 1}
                </Pagination.Item>
              ))}
              {resProps?.state?.pageIndex < pageCount - 5 && <Pagination.Ellipsis />}
              <Pagination.Item
                onClick={() => gotoPage(pageCount - 1)}
                className={resProps?.state?.pageIndex === pageCount - 1 ? 'active' : ''}
              >
                {pageCount}
              </Pagination.Item>
            </>
          ) : (
            <>
              {[...Array(pageCount).keys()].map((count) => (
                <Pagination.Item
                  key={`count-${count}`}
                  onClick={() => gotoPage(count)}
                  className={resProps?.state?.pageIndex === count ? 'active' : ''}
                >
                  {count + 1}
                </Pagination.Item>
              ))}
            </>
          )}
          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage}>
            <ArrowRightSVG />
          </Pagination.Next>
          <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </Pagination.Last>
        </Pagination>
      </PaginationUI>
    </div>
  );
};

export default TablePagination;
