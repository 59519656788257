/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// main components below
import CurrentBorrowedItemTable from './Table';
import SearchInventoryFilter from './Filter';
import seturlParams from 'helpers/seturlParams';
import { getBorrowedList } from 'reducers/borrowReturnReducer';

import './style.scss';
import { useEffect } from 'react';

// main component
const CurrentBorrowedItem = ({ departmentID, setRefreshTab, refreshTab, activeTab }) => {
  const dispatch = useDispatch();

  const [searchData, setSearchData] = useState({
    search: '',
  });

  const getInventoryRequestList = () => {
    if (activeTab === 'Current Borrowed Item') {
      // This code is to get table list data
      dispatch(
        getBorrowedList({
          status: 13,
          per_page: 100000,
          department_id: departmentID,
          q: seturlParams(searchData?.search, 'q'),
        }),
      );
    }
  };

  useEffect(() => {
    if (refreshTab) {
      setSearchData({
        search: '',
      });
    }

    setRefreshTab(false);
  }, [refreshTab]);

  return (
    <div className='admissions-inventory-tab-inventory-items'>
      <SearchInventoryFilter
        searchData={searchData}
        departmentID={departmentID}
        setSearchData={setSearchData}
      />

      <CurrentBorrowedItemTable getInventoryRequestList={getInventoryRequestList} />
    </div>
  );
};

CurrentBorrowedItem.defaultProps = {
  departmentID: '',
};

CurrentBorrowedItem.propTypes = {
  departmentID: PropTypes.string,
};

export default CurrentBorrowedItem;
