// import core & vendor packages below
import { Modal, Form, Button } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';
import FormUI from 'components/UI/FormUI';

// import assets below
import './AddNewServiceModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main componet
const AddNewServiceModal = (props) => {
  const { status, onToggle, onConfirm } = props;

  return (
    <div className='addNewServiceModal'>
      <ModalUI>
        <Modal.Dialog>
          <Modal.Header>
            <h4>Add Service Unit</h4>
            <Button onClick={() => onToggle(!status)} variant='transparent'>
              <CloseSVG />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <FormUI>
              <Form.Group className='mb-3'>
                <Form.Label>Search Service Unit</Form.Label>
                <Form.Control type='text' placeholder='Search' />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Remarks</Form.Label>
                <Form.Control as='textarea' rows={5} placeholder='Enter here' />
              </Form.Group>
            </FormUI>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='addField'
              onClick={() => {
                onToggle(!status);
                onConfirm((prevState) => !prevState);
              }}
            >
              Add and Notify Service Unit
            </Button>
            <Button onClick={() => onToggle(!status)} variant='cancel'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </ModalUI>
    </div>
  );
};

export default AddNewServiceModal;
