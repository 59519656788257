// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';
import { useState } from 'react';

// import components below
import Allergies from './Allergies';
import BloodType from './BloodType';
import CaseHistory from './CaseHistory';
import TabsUI from 'components/UI/TabsUI';
import MedicalRecords from './MedicalRecords';
import DischargeSummary from './DischargeSummary';

// main component
const NotesTabs = () => {
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = useState('Electronic Health/ Medical Records');

  const handleTabOnChange = (eventKey) => {
    setActiveTab(eventKey);
  };
  return (
    <div className='notestabs'>
      <TabsUI>
        <Tabs
          defaultActiveKey='Electronic Health/ Medical Records'
          onSelect={(eventKey) => handleTabOnChange(eventKey)}
        >
          <Tab
            eventKey='Electronic Health/ Medical Records'
            title='Electronic Health/ Medical Records'
          >
            <MedicalRecords />
          </Tab>
          <Tab eventKey='Discharge Summary' title='Discharge Summary'>
            <DischargeSummary />
          </Tab>
          <Tab eventKey='Case History' title='Case History'>
            <CaseHistory />
          </Tab>
          <Tab eventKey='Blood Type' title='Blood Type'>
            <BloodType />
          </Tab>
          <Tab eventKey='Allergies' title='Allergies'>
            <Allergies />
          </Tab>
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default NotesTabs;
