import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { ReactComponent as EditSVG } from 'assets/svg/writing-green.svg';
import { ReactComponent as DeleteSVG } from 'assets/svg/circle-xmark-red.svg';
import { ReactComponent as CirclePlusSVG } from 'assets/svg/circle-plus-white.svg';
import { getCashFlow, removeCashFlow } from 'reducers/thunks/accountingThunks';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const NetIncreaseList = (props) => {
  const dispatch = useDispatch();
  const { data, setShowModal, date } = props;
  const AddFields = () => setShowModal({ isShowForm: true, date: date });

  const onEdit = (id) =>
    setShowModal({
      isShowForm: true,
      assetId: id,
      data: data.find((item) => item.id === id),
      date: data.date,
    });

  const onDelete = async (id) => {
    await dispatch(removeCashFlow(id));
    await toast.success('Successfully Deleted');

    const p = {
      search: date,
    };
    dispatch(getCashFlow(p));
  };

  return (
    <>
      <h5 className='mt-2 mb-4'>Net increase in cash and cash equivalents</h5>
      <div className='button-list mt-2 mb-4'>
        <Button className='btn-light-green' onClick={AddFields}>
          <CirclePlusSVG /> ADD FIELDS
        </Button>
      </div>
      {data?.map((row, id) => {
        return (
          <Row className='mb-4' key={id}>
            <Col md={4} className='asset-name'>
              {row?.field}
            </Col>
            <Col md={1}>{row?.amount.toLocaleString()}</Col>
            <Col md={2} className='icon'>
              <EditSVG className='p-1' onClick={() => onEdit(row.id)} />
              <DeleteSVG className='p-1' onClick={() => onDelete(row.id)} />
            </Col>
          </Row>
        );
      })}
      <hr />
    </>
  );
};

export default NetIncreaseList;
