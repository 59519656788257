/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

const PatientHistory = ({ handleChange, stateData, setStateData }) => {
  return (
    <Row>
      <Col>
        <Form.Group className='mb-4'>
          <FormControl
            as='textarea'
            rows={5}
            className='bg-white'
            placeholder='Enter Here'
            value={stateData?.description}
            onChange={handleChange('description')}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default PatientHistory;
