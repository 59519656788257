/* eslint-disable multiline-comment-style */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import uuidv4 from 'react-uuid';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Accordion, Form, Button } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import RoomTable from './components/RoomTable';
import AccordionUI from 'components/UI/AccordionUI';
import SuccessModal from './components/SuccessModal';
import { redirectBackToPreviousPage } from 'helpers';
import { selectPatientItem } from 'reducers/patientReducer';
import { contactNoRules, alphabeCharFieldRules } from 'helpers/fieldValidations';
import CreateInpatientBedTable from 'pages/Admissions/components/CreateInpatientVisit/components/Table';

// import assets below
import './style.scss';
import { ReactComponent as CirclePlusSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CircleMinusSVG } from 'assets/svg/circle-minus.svg';

// main component
const FormCreateInpatientVisit = () => {
  const navigate = useNavigate();
  const patientProfile = useSelector(selectPatientItem);

  const [text, setText] = useState('');
  const [users, setUsers] = useState([]);
  const [icdCat, setIcdCat] = useState('');
  const [modal, setModal] = useState(false);
  const [icdCode, setIcdCode] = useState('');
  const [roomList, setRoomList] = useState([]);
  const [tempUuid, setTempUuid] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showReview, setShowReview] = useState(false);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [confirmDetails, setConfirmDetails] = useState(false);
  const [healthCareTeam, setHealthCareTeam] = useState([
    {
      uuid: uuidv4(),
      role: null,
      physician_name: null,
      userId: null,
      specialty_subspecialty: null,
      department: null,
      is_attending_physician: false,
    },
    {
      uuid: uuidv4(),
      role: null,
      physician_name: null,
      userId: null,
      specialty_subspecialty: null,
      department: null,
      is_attending_physician: false,
    },
    {
      uuid: uuidv4(),
      role: null,
      physician_name: null,
      userId: null,
      specialty_subspecialty: null,
      department: null,
      is_attending_physician: false,
    },
  ]);
  const [departments, setDepartments] = useState([]);

  const [selectedRoom, setSelectedRoom] = useState({
    selected_room: '',
    unit: '',
    pricing_scheme: '',
    price: '',
    roomId: '',
  });

  const [otherAdmittingDiagnosis, setOtherAdmittingDiagnosis] = useState([
    {
      uuid: uuidv4(),
      icd_code: null,
      icd_desc: null,
      icd_cat: null,
    },
  ]);

  const [state, setState] = useState({
    hospital_service: '',
    patientId: patientProfile?.id,
    employer: '',
    employer_address: '',
    employer_contact_no: '',
    father_name: '',
    mother_maiden_name: '',
    parent_contact_no: '',
    spouse_name: '',
    spouse_address: '',
    spouse_contact_no: '',
    admission_date: '',
    admission_type: '',
    philhealth_category: '',
    attending_physician_id: '',
    attending_physician_name: '',
    attending_clerk: '',
    date_furnished_by: '',
    furnished_by_address: '',
    relation_to_patient: '',
    informant_signature: '',
    is_philhealth_member: false,
    roomId: null,
  });

  /** primary payor */
  const [payorGuarantors, setPayorGuarantors] = useState({
    rank: null,
    payor_type: null,
    HMO_or_guarantor_name: null,
    purpose: null,
    coverage: null,
    is_percentage: false,
    is_amount: false,
    LOA_number: null,
    LOA_date: null,
    path: null,
    approval_code: null,
    approval_date: null,
  });

  const [filterState, setFilterState] = useState({
    type: '',
    status: '',
    isReserved: '',
  });

  /** other payors */
  const [otherPayors, setOtherPayors] = useState({
    rank: null,
    prefix: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    suffix: null,
    contact_number: null,
    relationship: null,
    link: null,
    LOA_date: null,
  });

  /** Other payors guarantors dynamic fields */
  const [otherPayorGuarantors, setOtherPayorGuarantors] = useState([
    {
      rank: null,
      payor_type: null,
      HMO_or_guarantor_name: null,
      purpose: null,
      coverage: null,
      is_percentage: false,
      is_amount: false,
      LOA_number: null,
      LOA_date: null,
      path: null,
      approval_code: null,
      approval_date: null,
    },
  ]);
  const [causeOfDeath, setCauseOfDeath] = useState('');
  const [causeOfDeathOptions, setCauseOfDeathOptions] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  // const resetFilterState = async () => {
  //   setFilterState({
  //     type: '',
  //     status: '',
  //     isReserved: '',
  //   });
  //   setRoomList([]);
  // };

  // const handleRadioButton = (event, field) => {
  //   switch (field) {
  //     case 'percentage':
  //       setPayorGuarantors({ ...payorGuarantors, is_percentage: true, is_amount: false });
  //       break;

  //     case 'amount':
  //       setPayorGuarantors({ ...payorGuarantors, is_percentage: false, is_amount: true });
  //       break;
  //   }
  // };

  const handleDropdownValue = (event, field) => {
    switch (field) {
      case 'patient_class':
        setState({ ...state, patient_class: event.target.value });
        break;
      case 'visit_type':
        setState({ ...state, visit_type: event.target.value });
        break;
      case 'admission_type':
        setState({ ...state, admission_type: event.target.value });
        break;
      case 'hospital_service':
        setState({ ...state, hospital_service: event.target.value });
        break;
      case 'priority':
        setState({ ...state, priority: event.target.value });
        break;
      case 'rank':
        setPayorGuarantors({ ...payorGuarantors, rank: event.target.value });
        break;
      case 'payor_type':
        setPayorGuarantors({ ...payorGuarantors, payor_type: event.target.value });
        break;
      case 'type':
        setFilterState({ ...filterState, type: event.target.value });
        break;
      case 'status':
        setFilterState({ ...filterState, status: event.target.value });
        break;
      case 'isReserved':
        setFilterState({ ...filterState, isReserved: event.target.value });
        break;
    }
  };

  const [philhealth, setPhilHealth] = useState({
    is_philhealth_member: false,
  });

  const handleChange = (key) => (e) => {
    if (e.target.type === 'radio') {
      setPhilHealth({
        ...philhealth,
        [key]: e.target.value,
      });

      return;
    }

    setState({
      ...state,
      [key]: e.target.value,
    });
  };

  const createInpatientVisit = async (event) => {
    // eslint-disable-next-line multiline-comment-style

    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      const params = {
        ...state,
        ...selectedRoom,
      };

      if (!state.attending_physician_id.length > 0 || !params.roomId > 0) {
        toast.warning('Some fields are required!');
      } else {
        await axios
          .post('/inpatient_visit', params)
          .then((response) => {
            if (response.data.error) {
              alert(response.data.error.message);
            } else {
              // setModal(true);
              toast.success('Successfully Created inpatient visit!');
              setTimeout(() => {
                navigate('/dashboard/outpatient-department');
              }, 2000);
            }
          })
          .catch((err) => {
            console.log('AXIOS ERROR: ', err);
          });
      }
    }
    setValidated(true);
  };

  const filterDoctorOptions = (input) => {
    return doctorOptions.filter((i) => {
      return i.full_name.toLowerCase().includes(input.toLowerCase());
    });
    // return causeOfDeathOptions;
  };

  const loadDoctorOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterDoctorOptions(input));
      }, 1000);
    });
  };

  const filterOptions = (input) => {
    return causeOfDeathOptions.filter((i) => {
      return (
        i.value.toLowerCase().includes(input.toLowerCase()) ||
        i.label.toLowerCase().includes(input.toLowerCase())
      );
    });
  };

  const loadOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(input));
      }, 1000);
    });
  };

  const handleCauseOfDeath = (icddesc, icdcode, icdcat) => {
    setState({
      ...state,
      admitting_diagnosis: icddesc,
      icd_code: icdcode,
      icd_category: icdcat,
    });
  };
  const handleDoctor = (e) => {
    setState({
      ...state,
      attending_physician_id: e.id,
      attending_physician_name: e.label,
    });
  };
  // Doctors AsyncSelect
  const loadDoctors = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterDoctors(input));
      }, 1000);
    });
  };
  const filterDoctors = (input) => {
    return doctorList.filter((i) => {
      return i.value.toLowerCase().includes(input.toLowerCase());
    });
  };

  useEffect(() => {
    /** Get Patients for auto complete suggestion */
    axios
      .get(`${process.env.REACT_APP_API_BASE_ACL}/api/users?roles[]=Doctor`)
      .then((response) => {
        const options = response.data?.data?.map((data) => ({
          id: data?.id,
          value: data?.attributes.full_name,
          label: data?.attributes.full_name,
        }));
        setDoctorList(options);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, [state]);

  useEffect(() => {
    axios.get('/public/ricd10').then((response) => {
      const options = response.data?.map((data) => ({
        id: data.id,
        value: data.icd10_code,
        label: data.icd10_desc,
        category: data.icd10_cat,
      }));
      setCauseOfDeathOptions(options);
    });
  }, []);

  useEffect(() => {
    axios.get('/user').then((response) => {
      const options = response?.data?.data?.map((data) => ({
        id: data.id,
        value: data.full_name,
        label: data.full_name,
        category: data.full_name,
        full_name: data.full_name,
      }));
      setDoctorOptions(options);
    });
  }, []);

  useEffect(() => {
    axios
      .get('/user')
      .then((response) => {
        setUsers(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    axios
      .get('/public/department')
      .then((response) => {
        setDepartments(response.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const [validated, setValidated] = useState(false);

  return (
    <div className='formCreateInpatientVisit'>
      <FormUI>
        <Form noValidate validated={validated} onSubmit={createInpatientVisit}>
          <div className='formCreateInpatientVisit__header'>
            <h4>Create Inpatient Visit</h4>
            <div>
              <Button
                className='me-3'
                variant='save'
                // onClick={createInpatientVisit}
              >
                Continue
              </Button>
              <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
                CANCEL
              </Button>
            </div>
          </div>
          <div className='formCreateInpatientVisit__banner'>
            <div>
              <h2 className='text-uppercase'>
                <span className='patient__lastName fw-bold'>{patientProfile?.last_name}</span>,{' '}
                {patientProfile?.first_name}
              </h2>
              <h5 className='patient__number mt-1'>{patientProfile?.id}</h5>
            </div>
            <div className='patient__info mt-1'>
              <span>
                {patientProfile?.age}/{patientProfile?.sex}
              </span>
              <span>{moment(patientProfile?.date_of_birth).format('MMMM-DD-YYYY')}</span>
            </div>
          </div>
          <AccordionUI>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey={'0'}>
                <Accordion.Header>Inpatient Admission Details</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={4}>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Hospital Service<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          onChange={(event) => handleDropdownValue(event, 'hospital_service')}
                        >
                          <option value=''>-Select-</option>
                          <option value='ent'>ENT</option>
                          <option value='medicine'>Medicine</option>
                          <option value='ob_gyne'>OB-GYNE</option>
                          <option value='urology'>Urology</option>
                          <option value='cardiology'>Cardiology</option>
                          <option value='pulmonary Medicine'>Pulmonary Medicine</option>
                          <option value='opthalmology'>Opthalmology</option>
                          <option value='surgery'>Surgery</option>
                          <option value='orthopedic Surgery'>Orthopedic Surgery</option>
                          <option value='endocrinology'>Endocrinology</option>
                          <option value='pediatrics'>Pediatrics</option>
                          <option value='dermatology'>Dermatology</option>
                          <option value='oncology'>Oncology</option>
                          <option value='neurology'>Neurology</option>
                          <option value='gastroenterology'>Gastroenterology</option>
                          <option value='internal_medicine'>Internal Medicine</option>
                          {/* <option value='medico_legal'>Medico Legal</option> */}
                          <option value='nephrology'>Nephrology</option>
                          <option value='Pathologic'>Pathologic</option>
                          <option value='Non-Pathologic'>Non-Pathologic</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Preffix</Form.Label>
                        <Form.Select>
                          <option>-Select-</option>
                          <option>Mr.</option>
                          <option>Ms.</option>
                          <option>Mrs.</option>
                          <option>Dr.</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type={'text'} required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type={'text'} required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Middle Initial</Form.Label>
                        <Form.Control type={'text'} required />
                      </Form.Group>
                    </Col>
                  </Row> */}

                  <Row>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Employer</Form.Label>
                        <Form.Control
                          type={'text'}
                          onChange={handleChange('employer')}
                          pattern='[A-Za-z\s]+'
                          isInvalid={
                            alphabeCharFieldRules.inValidateInput(state.employer) ||
                            state.employer_contact_no !== ''
                          }
                          required={state.employer_contact_no !== ''}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {!state.employer && 'Employer is required'}
                          {alphabeCharFieldRules.inValidateInput(state.employer) &&
                            'Employer should be alphabet characters only.'}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Employer Address</Form.Label>
                        <Form.Control
                          type={'text'}
                          onChange={handleChange('employer_address')}
                          isInvalid={state.employer_contact_no !== ''}
                          required={state.employer_contact_no !== ''}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {!state.employer_address && 'Employer Address is required'}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Employer Contact No.</Form.Label>
                        <Form.Control
                          type='number'
                          maxLength='11'
                          pattern='[0-9\b]{11}'
                          onChange={handleChange('employer_contact_no')}
                          isInvalid={contactNoRules.inValidateInput(state.employer_contact_no)}
                          onKeyDown={(evt) =>
                            (evt.key === 'e' ||
                              evt.key === 'E' ||
                              evt.key === '-' ||
                              evt.key === '.') &&
                            evt.preventDefault()
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          Employer contact number should be numeric and has 11 characters
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Father&apos;s Name</Form.Label>
                        <Form.Control
                          type={'text'}
                          onChange={handleChange('father_name')}
                          pattern='[A-Za-z\s]+'
                          isInvalid={
                            alphabeCharFieldRules.inValidateInput(state.father_name) ||
                            (state.parent_contact_no !== '' && state.mother_maiden_name === '')
                          }
                          required={
                            state.parent_contact_no !== '' && state.mother_maiden_name === ''
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          {!state.father_name && `Father's or Mother's Name is required.`}
                          {alphabeCharFieldRules.inValidateInput(state.father_name) &&
                            `Father's Name should be alphabet characters only.`}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Mother&apos;s Maiden Name</Form.Label>
                        <Form.Control
                          type={'text'}
                          onChange={handleChange('mother_maiden_name')}
                          pattern='[A-Za-z\s]+'
                          isInvalid={
                            alphabeCharFieldRules.inValidateInput(state.mother_maiden_name) ||
                            (state.parent_contact_no !== '' && state.father_name === '')
                          }
                          required={state.parent_contact_no !== '' && state.father_name === ''}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {!state.mother_maiden_name && `Father's or Mother's Name is required.`}
                          {alphabeCharFieldRules.inValidateInput(state.mother_maiden_name) &&
                            `Mother's Nmae should be alphabet characters only.`}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Parent Contact No.</Form.Label>
                        <Form.Control
                          type='number'
                          maxLength='11'
                          pattern='[0-9\b]{11}'
                          onChange={handleChange('parent_contact_no')}
                          isInvalid={contactNoRules.inValidateInput(state.parent_contact_no)}
                          onKeyDown={(evt) =>
                            (evt.key === 'e' ||
                              evt.key === 'E' ||
                              evt.key === '-' ||
                              evt.key === '.') &&
                            evt.preventDefault()
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          Parent contact number should be numeric and has 11 characters
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Spouse Name</Form.Label>
                        <Form.Control
                          type={'text'}
                          onChange={handleChange('spouse_name')}
                          pattern='[A-Za-z\s]+'
                          isInvalid={alphabeCharFieldRules.inValidateInput(state.spouse_name)}
                          required={state.spouse_contact_no !== ''}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {alphabeCharFieldRules.inValidateInput(state.spouse_name) &&
                            'Spouse Name should be alphabet characters only.'}
                          {!state.spouse_name && 'Spouse Name is required.'}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Spouse Address</Form.Label>
                        <Form.Control
                          type={'text'}
                          onChange={handleChange('spouse_address')}
                          isInvalid={state.spouse_contact_no !== ''}
                          required={state.spouse_contact_no !== ''}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {!state.spouse_address && 'Spouse Address is required'}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Spouse Contact No.</Form.Label>
                        <Form.Control
                          type='number'
                          maxLength='11'
                          pattern='[0-9\b]{11}'
                          onChange={handleChange('spouse_contact_no')}
                          isInvalid={contactNoRules.inValidateInput(state.spouse_contact_no)}
                          onKeyDown={(evt) =>
                            (evt.key === 'e' ||
                              evt.key === 'E' ||
                              evt.key === '-' ||
                              evt.key === '.') &&
                            evt.preventDefault()
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          Spouse contact number should be numeric and has 11 characters
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Admission Discharge</Form.Label>
                        <div className={'d-flex align-items-center'}>
                          <Form.Control type={'date'} onChange={handleChange('admission_date')} />
                          -
                          <Form.Control type={'date'} />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Admission Type</Form.Label>
                        <Form.Select
                          onChange={(event) => handleDropdownValue(event, 'admission_type')}
                        >
                          <option>-Select-</option>
                          <option value='ambulatory'>Ambulatory</option>
                          <option value='walk-in'>Walk-in</option>
                          <option value='public-services-unknown'>Public Services/Unknown</option>
                          <option value='transferred'>Transferred</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>
                          Philhealth Member? <span className='asterisk'>*</span>
                        </Form.Label>
                        <div className={'d-flex align-items-center'}>
                          <Form.Check
                            name='philHealthMemberType'
                            inline
                            label='Yes'
                            type='radio'
                            required
                            value='yes'
                            onChange={(e) =>
                              setState({
                                ...state,
                                is_philhealth_member: true,
                              })
                            }
                          />
                          <Form.Check
                            name='philHealthMemberType'
                            inline
                            label='No'
                            required
                            type='radio'
                            value='no'
                            onChange={(e) =>
                              setState({
                                ...state,
                                is_philhealth_member: false,
                              })
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Philhealth Category</Form.Label>
                        <Form.Control
                          type={'text'}
                          required={state.is_philhealth_member}
                          onChange={handleChange('philhealth_category')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Attending Physician <span className='asterisk'>*</span>
                        </Form.Label>
                        <div style={{ width: '100%', margin: '0 auto' }}>
                          <AsyncSelect
                            required
                            cacheOptions
                            // value={diagnosis}
                            styles={selectStyles}
                            onChange={handleDoctor}
                            loadOptions={loadDoctors}
                            placeholder='Search Doctor'
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Attending Clerk</Form.Label>
                        <Form.Control type={'text'} onChange={handleChange('attending_clerk')} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Date Furnished By</Form.Label>
                        <Form.Control type={'text'} onChange={handleChange('date_furnished_by')} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Furnished By Address</Form.Label>
                        <Form.Control
                          type={'text'}
                          onChange={handleChange('furnished_by_address')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Relation To Patient</Form.Label>
                        <Form.Control
                          type={'text'}
                          onChange={handleChange('relation_to_patient')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={'mb-3'}>
                        <Form.Label>Informant Signature</Form.Label>
                        <Form.Control
                          type={'text'}
                          onChange={handleChange('informant_signature')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Patient Class<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          onChange={(event) => handleDropdownValue(event, 'patient_class')}
                        >
                          <option>-Select-</option>
                          <option value='inpatient'>Inpatient</option>
                          <option value='emergency'>Emergency</option>
                          <option value='outpatient'>Outpatient</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Visit Type<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          onChange={(event) => handleDropdownValue(event, 'visit_type')}
                        >
                          <option>-Select-</option>
                          <option value='new-admission'>New Admission</option>
                          <option value='emergency'>Emergency</option>
                          <option value='outpatient'>Outpatient</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Mode of Arrival<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          onChange={(event) => handleDropdownValue(event, 'mode_of_arrival')}
                        >
                          <option>-Select-</option>
                          <option value='ambulatory'>Ambulatory</option>
                          <option value='walk-in'>Walk-in</option>
                          <option value='public-services-unknown'>Public Services/Unknown</option>
                          <option value='tranferred'>Transferred</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Priority<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          onChange={(event) => handleDropdownValue(event, 'priority')}
                        >
                          <option>-Select-</option>
                          <option value='urgent'>Urgent</option>
                          <option value='not-urgent'>Not Urgent</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col md={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Chief Complaint<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setState({ ...state, chief_complaint: event.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row> */}

                  {/* <Row>
                    <Col md={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Admitting Diagnosis<span className='asterisk'>*</span>
                        </Form.Label>
                        <div style={{ width: '100%', margin: '0 auto' }}>
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={loadOptions}
                            onChange={(e) => handleCauseOfDeath(e.label, e.value, e.category)}
                            placeholder='Search Diagnosis'
                            styles={selectStyles}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row> */}
                  {/* {otherAdmittingDiagnosis &&
                    otherAdmittingDiagnosis.map((item, key) => {
                      return (
                        <Row key={item}>
                          <Col md={6}>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                Admitting Diagnosis<span className='asterisk'>*</span>
                              </Form.Label>
                              <div style={{ width: '100%', margin: '0 auto' }}>
                                <AsyncSelect
                                  cacheOptions
                                  defaultOptions
                                  loadOptions={loadOptions}
                                  onChange={(e) =>
                                    handleOtherAdmittingDiagnosis(
                                      item.uuid,
                                      e.label,
                                      e.value,
                                      e.category,
                                    )
                                  }
                                  placeholder='Search Diagnosis'
                                  styles={selectStyles}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={4} className='d-flex align-items-center'>
                            {key > 0 && (
                              <Button
                                className='mb-3'
                                variant='transparent'
                                id='healthcare-teams-minus-button'
                                onClick={handleDeleteDiagnosis}
                              >
                                <CircleMinusSVG />
                              </Button>
                            )}
                            <Button
                              className='mb-3'
                              variant='transparent'
                              id='healthcare-teams-minus-button'
                              onClick={handleAddDiagnosis}
                            >
                              <CirclePlusSVG />
                            </Button>
                          </Col>
                        </Row>
                      );
                    })} */}
                  <Row></Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>PhilHealth Eligibility Form</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row className='d-flex align-items-center'>
                      <Col lg={3}>
                        <h6 className='mb-3 fw-bold'>Member&#39;s PhilHealth Information</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='4'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Member&#39;s PhilHealth Identification No
                            <span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={philhealth.MPIN}
                            onChange={handleChange('MPIN')}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            First Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter First Name'
                            value={philhealth.first_name}
                            onChange={handleChange('first_name')}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Middle Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Middle Name'
                            value={philhealth.middle_name}
                            onChange={handleChange('middle_name')}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Last Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Last Name'
                            value={philhealth.last_name}
                            onChange={handleChange('last_name')}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg='2'>
                        <Form.Group className='mb-3'>
                          <Form.Label>Suffix</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Suffix'
                            value={philhealth.suffix}
                            onChange={handleChange('suffix')}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Member Type<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            onChange={handleChange('member_type')}
                            value={philhealth.member_type}
                          >
                            <option value=''>-</option>
                            <option value='basic'>Basic</option>
                            <option value='advance'>Advance</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col lg='auto'>
                        <Form.Group className='mb-3'>
                          <Form.Label>Member is the Patient?</Form.Label>
                          <div className='d-flex flex-row nowrap align-items-center mt-2'>
                            <Form.Check
                              name='philHealthMemberType'
                              inline
                              label='Yes'
                              type='radio'
                              value={true}
                              onChange={handleChange('is_own_account')}
                            />
                            <Form.Check
                              name='philHealthMemberType'
                              inline
                              label='No'
                              type='radio'
                              value={false}
                              onChange={handleChange('is_own_account')}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg='auto'>
                        <Form.Group className='mb-3'>
                          <Form.Label>Relationship to Member</Form.Label>
                          <div className='d-flex flex-row nowrap align-items-center mt-2'>
                            <Form.Check
                              inline
                              label='Spouse'
                              name='relationship'
                              value='spouse'
                              type='radio'
                              defaultChecked={false}
                              onChange={handleChange('relation_to_member')}
                            />
                            <Form.Check
                              inline
                              label='Child'
                              defaultChecked={false}
                              name='relationship'
                              value='child'
                              type='radio'
                              onChange={handleChange('relation_to_member')}
                            />
                            <Form.Check
                              inline
                              label='Parent'
                              name='relationship'
                              value='parent'
                              type='radio'
                              defaultChecked={false}
                              onChange={handleChange('relation_to_member')}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Bed Assignment</Accordion.Header>
                <Accordion.Body>
                  <Row className='d-flex align-items-center'>
                    <Col>
                      <p className='mb-3'>
                        Select a room or bed to reserve for the patient.{' '}
                        <span className='text-danger'>
                          <b>
                            {' '}
                            NOTE: If no room or bed has been selected, the patient will be added to
                            the Waiting List.
                          </b>
                        </span>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <h5 className='my-3'>Select a Room or Bed</h5>

                    <RoomTable roomList={roomList} setSelectedRoom={setSelectedRoom} />
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </AccordionUI>
          <div className='d-flex justify-content-end mt-5'>
            <Button className='me-3' variant='save' type='submit'>
              Continue
            </Button>
            <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
              CANCEL
            </Button>
          </div>
        </Form>
      </FormUI>
      {modal && <SuccessModal />}
    </div>
  );
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.45em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

export default FormCreateInpatientVisit;
