import Calendar from 'react-calendar';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getACLState } from 'reducers/aclReducer';
import ConditionalRender from 'components/ConditionalRender';

import './index.scss';
import 'react-calendar/dist/Calendar.css';
// import { has } from 'lodash';

const SideBar = () => {
  const [value, onChange] = useState(new Date());
  const storeData = useSelector(getACLState);
  const allLogs = storeData?.logs;
  const hasLogs = allLogs && allLogs.length > 0;

  const getTimeAndDate = (dataDate) => {
    const newDate = new Date(dataDate);
    let hours = newDate.getHours();
    let minutes = newDate.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;

    return `${strTime} ${newDate.toLocaleDateString()}`;
  };

  return (
    <div className='sidebar-cont'>
      <Calendar onChange={onChange} value={value} />

      <div className='mt-4 recent-activities'>
        <h3>Recent Activities</h3>

        <ul>
          <ConditionalRender
            condition={hasLogs}
            renderIf={allLogs.map((data) => {
              return (
                <li key={data.id}>
                  {data?.attributes?.event === 'updated' && (
                    <p>
                      Updated User (
                      {data?.attributes?.properties?.attributes?.name ||
                        data?.attributes?.properties?.attributes?.email}
                      ) <span>{getTimeAndDate(data?.attributes?.updated_at)}</span>
                    </p>
                  )}

                  {data?.attributes?.event === 'created' && (
                    <p>
                      Added New User (
                      {data?.attributes?.properties?.attributes?.name ||
                        data?.attributes?.properties?.attributes?.email}
                      ) <span>{getTimeAndDate(data?.attributes?.updated_at)}</span>
                    </p>
                  )}

                  {data?.attributes?.event === 'deleted' && (
                    <p>
                      Deleted User (
                      {data?.attributes?.properties?.old?.name ||
                        data?.attributes?.properties?.old?.email}
                      ) <span>{getTimeAndDate(data?.attributes?.updated_at)}</span>
                    </p>
                  )}
                </li>
              );
            })}
          />

          <ConditionalRender
            condition={!hasLogs}
            renderIf={
              <li>
                <p>
                  No Activities Found
                  <span></span>
                </p>
              </li>
            }
          />
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
