/* eslint-disable */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from 'components/UI/TabsUI';
import Appointments from './Appointments';
import SearchPatient from './SearchPatient';
import DailyWorkList from './DailyWorkList';
import AppointmentList from './AppointmentList';
import { setActiveTab } from 'reducers/appointmentReducer';
import InventoryPerDepartment from 'components/InventoryPerDepartment';

// import assets below
import './Tabs.scss';

const initialState = {
  clinicWorkList: false,
  appointmentSearch: false,
};

// main component
const OutpatientTabs = ({ activeTab }) => {
  const dispatch = useDispatch();
  const [refreshTable, setRefreshTable] = useState(initialState);

  const handleTabOnChange = (key) => {
    dispatch(setActiveTab(key));

    if (key === 'Clinic Work List') {
      setRefreshTable({ ...initialState, clinicWorkList: true });
    } else if (key === 'Appointment Search') {
      setRefreshTable({ ...initialState, appointmentSearch: true });
    } else {
      setRefreshTable(initialState);
    }
  };

  let tabs = [
    {
      id: 1,
      title: 'Search Patient',
      component: SearchPatient,
    },
    {
      id: 2,
      title: 'Create Appointment',
      component: Appointments,
    },
    {
      id: 3,
      title: 'Clinic Work List',
      component: DailyWorkList,
    },
    {
      id: 4,
      title: 'Appointment Search',
      component: AppointmentList,
    },
    {
      id: 5,
      title: 'Inventory',
      component: InventoryPerDepartment,
    },
  ];

  return (
    <div className='outpatientTabs'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title} onSelect={(eventKey) => handleTabOnChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component departmentID={'6'} refreshTable={refreshTable} />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

OutpatientTabs.defaultProps = {
  activeTab: () => {},
};

OutpatientTabs.propTypes = {
  activeTab: PropTypes.func,
};

export default OutpatientTabs;
