/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import Shifting from '../Shifting';
import LeaveCredits from '../LeaveCredits';
import EmployeeProfile from '../EmployeeProfile';
import TabsUI from 'components/UI/TabsUI';
import FormUI from 'components/UI/FormUI';
import { ReactComponent as PencilSVG } from 'assets/svg/pencil-white.svg';
import DefaultAvatar from 'assets/images/avatar.jpg';

// import assets below
import './index.scss';

const ViewEmployee = ({ empData, setActiveScreen, setActiveTab }) => {
  return (
    <div className='employees-view'>
      <FormUI>
        <Form className='employee-profile'>
          <Row className='mb-4'>
            <Col>
              <div className='profile-header'>
                <img src='https://picsum.photos/200/300?grayscale' className='img-header' />
                <div className='header-information'>
                  <Col sm={2}>
                    <img
                      className='profile-picture'
                      src={empData?.attributes?.profile_picture || DefaultAvatar}
                    />
                  </Col>
                  <Col sm={10}>
                    <Row>
                      <div className='p-header'>
                        <p>{`${empData?.attributes.first_name} ${empData?.attributes.middle_name} ${empData?.attributes.last_name}`}</p>
                        <span>Employee Name</span>
                      </div>
                      {/* <div className='p-header'>
                    <p>{empData?.position}</p>
                    <span>Designation</span>
                  </div> */}
                      <div className='p-header'>
                        <p>{empData?.attributes?.id_no}</p>
                        <span>Employee ID</span>
                      </div>
                    </Row>
                    <Row>
                      <button
                        className='go-back'
                        onClick={() => {
                          setActiveScreen(0);
                          setActiveTab('Employees List');
                        }}
                      >
                        {'< Go Back to Employee List'}
                      </button>
                    </Row>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col>
              <div className='employee-details'>
                <Row>
                  <Col>
                    <TabsUI>
                      <Tabs
                        defaultActiveKey='EMPLOYEE PROFILE'
                        onSelect={(eventKey) => {
                          if (eventKey === 'LEAVE CREDITS') {
                            setActiveTab('Employee Leave Credits');
                          }

                          if (eventKey === 'EMPLOYEE PROFILE') {
                            setActiveTab('Employee Profile');
                          }

                          if (eventKey === 'SHIFTING SCHEDULE') {
                            setActiveTab('Employee Shifting Schedule');
                          }
                        }}
                      >
                        <Tab eventKey='EMPLOYEE PROFILE' title='EMPLOYEE PROFILE' className='p-4'>
                          <EmployeeProfile empData={empData} />
                        </Tab>
                        {/* <Tab eventKey='LEAVE CREDITS' title='LEAVE CREDITS' className='p-4'>
                          <LeaveCredits />
                        </Tab> */}
                        <Tab eventKey='SHIFTING SCHEDULE' title='SHIFTING SCHEDULE' className='p-4'>
                          <Shifting employeeId={empData.id} />
                        </Tab>
                      </Tabs>

                      <div className='edit-profile-cont'>
                        <Button
                          onClick={() => {
                            setActiveScreen(3);
                          }}
                          className='edit-profile'
                        >
                          <PencilSVG style={{ width: '1rem' }} />
                          Edit Profile
                        </Button>
                      </div>
                    </TabsUI>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      </FormUI>
    </div>
  );
};

ViewEmployee.defaultProps = {
  setActiveTab: () => {},
};

ViewEmployee.propTypes = {
  setActiveTab: PropTypes.func,
};

export default ViewEmployee;
