/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Modal,
  Table,
  Button,
  Pagination,
  InputGroup,
  FormControl,
} from 'react-bootstrap';

// components below
import ProcedureTable from './Table';
import FormUI from 'components/UI/FormUI';

// CSS
import './style.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const Procedure = () => {
  const [state, setState] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [showModal, setShowModal] = useState({
    isShowView: false,
    profileData: null,
  });
  const [userData, setUserData] = useState({
    status: '',
    // room_bed: '',
    patient_name: '',
    attending_physician: '',
  });

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    const key = event.target.name;

    const newFilter = procedures.filter((value) => {
      return value.patient?.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(procedures);
    }

    setUserData({
      ...userData,
      [key]: searchedWord,
    });
  };

  const onChangeHandlerStatus = (event) => {
    const status = event.target.value;
    const key = event.target.name;

    const newFilter = procedures.filter((value) => {
      return value.status.toLowerCase().includes(status.toLowerCase());
    });

    if (status.length > 0) {
      setState(newFilter);
    } else {
      setState(procedures);
    }

    setUserData({
      ...userData,
      [key]: status,
    });
  };

  const resetFilter = () => {
    setUserData({
      status: '',
      patient_name: '',
      attending_physician: '',
    });

    setState(procedures);
  };

  useEffect(() => {
    axios
      .get('/requisition/procedure')
      .then((response) => {
        setProcedures(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <FormUI>
      <div className='procedure-assessment mt-4'>
        <Form>
          <Row className='mb-2'>
            <Col>
              <Form.Group className='basic-search'>
                <Form.Label>Patient Name</Form.Label>
                <InputGroup>
                  <InputGroup.Text className='bg-white'>
                    <MagnifyingGlassSVG />
                  </InputGroup.Text>
                  <FormControl
                    type='search'
                    name='patient_name'
                    className='bg-white'
                    placeholder='Enter Here'
                    onChange={onChangeHandler}
                    value={userData?.patient_name}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-4'>
                <Form.Label>Attending Physician</Form.Label>
                <FormControl
                  type='text'
                  className='bg-white'
                  placeholder='Enter Here'
                  name='attending_physician'
                  value={userData.attending_physician}
                  onChange={handleChange('attending_physician')}
                />
              </Form.Group>
            </Col>
            {/* <Col>
              <Form.Group className=''>
                <Form.Label>Room / Bed</Form.Label>
                <Form.Select aria-label='Doctor' value={status} onChange={handleChange('room_bed')}>
                  <option value='' hidden>
                    - Select -
                  </option>
                  <option value='private'>Private</option>
                  <option value='suite'>Suite</option>
                  <option value='ward'>Ward</option>
                  <option value='operating_room'>Operating Room</option>
                  <option value='delivery_room'>Delivery Room</option>
                </Form.Select>
              </Form.Group>
            </Col> */}
            <Col>
              <Form.Group className=''>
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name='status'
                  value={userData?.status}
                  onChange={onChangeHandlerStatus}
                >
                  <option value='' hidden>
                    - Select -
                  </option>
                  <option value='pending'>Pending</option>
                  <option value='completed'>Completed</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='category-buttons'>
                {/* <Button
                  onClick={() => {
                    // Update Data on Table?
                  }}
                  className='add-button'
                  variant='primary'
                  type='submit'
                >
                  Go
                </Button> */}
                <Button className='reset-button' variant='secondary' onClick={() => resetFilter()}>
                  Reset
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>

        <h2 className='mb-3'>Nursing Patient List</h2>

        {/* Table */}
        <ProcedureTable state={state} setState={setState} setShowModal={setShowModal} />
      </div>
    </FormUI>
  );
};

export default Procedure;
