/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  showActivePatients,
  showPatientHistory,
  showVisitDetails,
  createCertificateConfinement,
  getMedicalForms,
  createMedicalCertificate,
  createMedicoLegal,
  createDismembermentForm,
  createMedicalRecord,
  createAdTestificandum,
  createDucesTecum,
  createOutpatientRecord,
  createEmergencyTreatmentRecord,
  createAdmissionandDischargeRecord,
  createDischargeSummaryRecord,
  createHistoryandPhysicalExamRecord,
  createLiveBirthFormRecord,
  createCertofDeath,
  createCertofFetalDeath,
  getMedicalFormsbyID,
  getMedicalRecordID,
} from './thunks/medicalRecordThunks';
import Tabs from 'constant/medicalRecordTabs';
import _ from 'lodash';

const initialState = {
  data: [], // active patients
  discharged: [], // discharged patients
  outpatient: [], // opd patients
  archive: [],
  forms: [],
  patientID: [],
  medicalRecordID: [],
  history: {}, // patient history
  visit_details: {}, // patient visit details based on visit_number
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  successData: [],
  isFetchViewSuccess: false,
  isFetchRecordSuccess: false,
};

const slice = createSlice({
  name: 'medicalRecord',
  initialState,
  reducers: {},
  extraReducers: {
    [showActivePatients.pending]: (state) => {
      state.isLoading = true;
    },
    [showActivePatients.fulfilled]: (state, actions) => {
      if (actions.payload.tab === Tabs.MEDICALRECORD.ACTIVE) {
        state.data = actions.payload.data;
      }
      if (actions.payload.tab === Tabs.MEDICALRECORD.DISCHARGED) {
        state.discharged = actions.payload.data;
      }
      if (actions.payload.tab === Tabs.MEDICALRECORD.OUTPATIENT) {
        state.outpatient = actions.payload.data;
      }
      if (actions.payload.tab === Tabs.MEDICALRECORD.ARCHIVE) {
        state.archive = actions.payload.data;
      }

      state.isLoading = false;
    },
    [showActivePatients.rejected]: (state) => {
      state.data = [];
      state.isLoading = false;
    },
    /**---------------------------------- */
    [showPatientHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [showPatientHistory.fulfilled]: (state, action) => {
      state.history = action.payload.data;
      state.isLoading = false;
    },
    [showPatientHistory.rejected]: (state, actions) => {
      state.history = [];
      state.isLoading = false;
    },
    /**---------------------------------- */
    [showVisitDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [showVisitDetails.fulfilled]: (state, action) => {
      state.visit_details = action.payload.data;
      state.isLoading = false;
    },
    [showVisitDetails.rejected]: (state, actions) => {
      state.history = [];
      state.isLoading = false;
    },
    [createCertificateConfinement.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
    },
    [createCertificateConfinement.rejected]: (state, actions) => {
      state.confinement = [];
      state.isLoading = false;
    },
    [createMedicalCertificate.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createMedicalCertificate.rejected]: (state, actions) => {
      state.confinement = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [createMedicalCertificate.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createMedicoLegal.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createMedicoLegal.rejected]: (state, actions) => {
      state.confinement = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [createMedicoLegal.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createDismembermentForm.fulfilled]: (state, action) => {
      state.successData = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createDismembermentForm.rejected]: (state, actions) => {
      state.confinement = [];
      state.isLoading = false;
      state.isFailed = true;
    },
    [createDismembermentForm.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createAdTestificandum.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createAdTestificandum.rejected]: (state, actions) => {
      state.confinement = [];
      state.isLoading = false;
      state.isFailed = true;
    },
    [createAdTestificandum.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },

    [createDucesTecum.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createDucesTecum.rejected]: (state, actions) => {
      state.confinement = [];
      state.isLoading = false;
      state.isFailed = true;
    },
    [createDucesTecum.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createOutpatientRecord.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createOutpatientRecord.rejected]: (state, actions) => {
      state.confinement = [];
      state.isLoading = false;
      state.isFailed = true;
    },
    [createOutpatientRecord.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createMedicalRecord.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createMedicalRecord.rejected]: (state, actions) => {
      state.confinement = [];
      state.isLoading = false;
      state.isFailed = true;
    },
    [createMedicalRecord.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getMedicalForms.fulfilled]: (state, action) => {
      const confinmentList = action.payload.data.filter((item) => {
        return item.type == 'certificate_of_confinement';
      });

      state.confinement = confinmentList;

      state.records = action.payload.data;
      state.isLoading = false;
    },
    [getMedicalForms.rejected]: (state, actions) => {
      state.records = [];
      state.isLoading = false;
    },
    [createEmergencyTreatmentRecord.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createEmergencyTreatmentRecord.rejected]: (state, actions) => {
      state.confinement = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [createEmergencyTreatmentRecord.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createAdmissionandDischargeRecord.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createAdmissionandDischargeRecord.rejected]: (state, actions) => {
      state.confinement = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [createAdmissionandDischargeRecord.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createDischargeSummaryRecord.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createDischargeSummaryRecord.rejected]: (state, actions) => {
      state.confinement = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [createDischargeSummaryRecord.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createHistoryandPhysicalExamRecord.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createHistoryandPhysicalExamRecord.rejected]: (state, actions) => {
      state.confinement = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [createHistoryandPhysicalExamRecord.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createLiveBirthFormRecord.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createLiveBirthFormRecord.rejected]: (state, actions) => {
      state.confinement = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [createLiveBirthFormRecord.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createCertofDeath.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createCertofDeath.rejected]: (state, actions) => {
      state.confinement = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [createCertofDeath.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createCertofFetalDeath.fulfilled]: (state, action) => {
      state.confinement = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createCertofFetalDeath.rejected]: (state, actions) => {
      state.confinement = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [createCertofFetalDeath.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getMedicalFormsbyID.fulfilled]: (state, action) => {
      state.patientID = action.payload.data;
      state.isLoading = false;
      state.isFetchViewSuccess = true;
    },
    [getMedicalFormsbyID.rejected]: (state, actions) => {
      state.patientID = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [getMedicalFormsbyID.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getMedicalRecordID.fulfilled]: (state, action) => {
      state.medicalRecordID = action.payload.data;
      state.isLoading = false;
      state.isFetchRecordSuccess = true;
    },
    [getMedicalRecordID.rejected]: (state, actions) => {
      state.medicalRecordID = [];
      state.isFailed = true;
      state.isLoading = false;
    },
    [getMedicalRecordID.pending]: (state) => {
      state.isFailed = false;
      state.isLoading = true;
      state.isSuccess = false;
    },
  },
});

export const { actions: medicalRecordActions, reducer: medicalRecordReducer } = slice;

const selectDomain = (state) => state.medicalRecord || initialState;

export const selectActivePatients = createSelector(
  [selectDomain],
  (activePatientState) => activePatientState,
);

export const selectPatientHistory = createSelector(
  [selectDomain],
  (activePatientState) => activePatientState,
);

export const selectPatientVisitDetails = createSelector(
  [selectDomain],
  (activePatientState) => activePatientState.visit_details,
);

export const selectMedicalForms = createSelector(
  [selectDomain],
  (activePatientState) => activePatientState.records,
);

export const selectConfinementRecords = createSelector(
  [selectDomain],
  (activePatientState) => activePatientState.confinement,
);
export {
  showActivePatients,
  showPatientHistory,
  showVisitDetails,
  createCertificateConfinement,
  getMedicalForms,
  createAdmissionandDischargeRecord,
  createEmergencyTreatmentRecord,
  createDischargeSummaryRecord,
  createHistoryandPhysicalExamRecord,
  createLiveBirthFormRecord,
  createCertofDeath,
  createCertofFetalDeath,
  getMedicalFormsbyID,
  getMedicalRecordID,
};
