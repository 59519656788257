/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getHospitalOperation } from './thunks/hospitalOperationReportThunks';
import _ from 'lodash';

const initialState = {
  data: [],
  item: null,
  isLoading: false,
};

const slice = createSlice({
  name: 'hospitalOperation',
  initialState,
  reducers: {},
  extraReducers: {
    [getHospitalOperation.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [getHospitalOperation.pending]: (state) => {
      state.isLoading = true;
    },
    [getHospitalOperation.rejected]: (state) => {
      state.data = [];
      state.isLoading = false;
    },
  },
});

export const { actions: hospitalOperationActions, reducer: hospitalOperationReducer } = slice;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.hospitalOperation || initialState;

export const selectAuth = createSelector(
  [selectDomain],
  (hospitalOperationState) => hospitalOperationState,
);

export const selectHospitalOperationData = createSelector(
  [selectDomain],
  (hospitalOperationState) => hospitalOperationState.data,
);

export const selectHospitalOperationItem = createSelector(
  [selectDomain],
  (hospitalOperationState) => hospitalOperationState.item,
);

export const selectHospitalOperationLoading = createSelector(
  [selectDomain],
  (hospitalOperationState) => hospitalOperationState.isLoading,
);

/* --------------------------------------------------------------------------------- */

export { getHospitalOperation };
