import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import InventoryHeader from './components/PageHeader';
import InventoryTabs from './components/Tabs';

// import assets below
import './style.scss';

// main component
const Inventory = () => {
  const [activeTab, setActiveTab] = useState('Store Inventory');

  return (
    <Layout pageTitle='Inventory'>
      <InventoryHeader active={activeTab} />
      <InventoryTabs onChange={setActiveTab} />
    </Layout>
  );
};

export default Inventory;
