/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import { getEquipmentList, selectFixedAssets } from 'reducers/fixedAssetsReducer';

// import assets below
import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

// main component

const index = ({ setShowScreen, setRequestData, setFormData }) => {
  const dispatch = useDispatch();

  const fixedAssetsState = useSelector(selectFixedAssets);
  const { equipmentData, equipmentItem, equipmentLogs } = fixedAssetsState;

  const [tableList, setTableList] = useState([]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Physical Condition', accessor: 'condition' },
      { Header: 'Financial Status', accessor: 'current_value' },
    ],
  );

  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({
        data: dd,
        id: dd?.id,
        location: dd?.location,
        condition: dd?.condition,
        loan_rate: dd?.loan_rate,
        down_payment: dd?.down_payment,
        current_value: dd?.current_value,
        intial_value: dd?.intial_value,
        serial_number: dd?.serial_number,
        expected_value: dd?.expected_value,
        date_purchased: dd?.date_purchased,
        monthly_payment: dd?.monthly_payment,
        item_description: dd?.item_description,
        total_monthly_cost: dd?.total_monthly_cost,
        loan_term_in_years: dd?.loan_term_in_years,
        years_of_service_left: dd?.years_of_service_left,
        monthly_operating_cost: dd?.monthly_operating_cost,
        annual_straight_line_dep: dd?.annual_straight_line_dep,
        monthly_straight_line_dep: dd?.monthly_straight_line_dep,
      })),
    [tableList],
  );

  const handleViewDetails = (tableData) => {
    console.log(tableData);
    const { data } = tableData;

    setFormData((prev) => {
      return {
        ...prev,
        id: data?.id,
        location: data?.location,
        loanRate: data?.loan_rate,
        condition: data?.condition,
        downpayment: data?.down_payment,
        serialNumber: data?.serial_number,
        currentValue: data?.current_value,
        initialValue: data?.intial_value,
        description: data?.item_description,
        expectedValue: data?.expected_value,
        monthlyPayment: data?.monthly_payment,
        loanTermInYears: data?.loan_term_in_years,
        totalMonthlyCost: data?.total_monthly_cost,
        yearsOfServiceLeft: data?.years_of_service_left,
        monthlyOperatingCost: data?.monthly_operating_cost,
        annualStraightLineDepreciation: data?.annual_straight_line_dep,
        monthlyStraightLineDepreciation: data?.monthly_straight_line_dep,
        datePurchased: moment(data?.date_purchased).format('MM/DD/YYYY'),
      };
    });

    setShowScreen(5.5);
    setRequestData(data);
  };

  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, equipmentData, equipmentLogs);

  return (
    <div className='credentials-credits'>
      <div className='header'>
        
        <div className='functions'>
          <Button className='upload' onClick={() => setShowScreen(5)}>
            <AddSVG />
            Add Record
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <span
                className='badge yellow'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // setRequestData(data);

                  // setShowScreen(5.5);
                  handleViewDetails(data);
                }
              }
              >
                View Details
              </span>
            );
          }}
        />
      </div>   
      </div>
  );
};

const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getEquipmentList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, equipmentData, equipmentLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = equipmentLogs;

    if (isSuccess) {
      const newData = equipmentData.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          location: dd?.location,
          condition: dd?.condition,
          loan_rate: dd?.loan_rate,
          down_payment: dd?.down_payment,
          intial_value: dd?.intial_value,
          current_value: dd?.current_value,
          serial_number: dd?.serial_number,
          expected_value: dd?.expected_value,
          date_purchased: dd?.date_purchased,
          monthly_payment: dd?.monthly_payment,
          item_description: dd?.item_description,
          total_monthly_cost: dd?.total_monthly_cost,
          loan_term_in_years: dd?.loan_term_in_years,
          years_of_service_left: dd?.years_of_service_left,
          monthly_operating_cost: dd?.monthly_operating_cost,
          annual_straight_line_dep: dd?.annual_straight_line_dep,
          monthly_straight_line_dep: dd?.monthly_straight_line_dep,
        };
      });
      console.log(newData);
      setTableList(newData);
    }
  }, [equipmentLogs]);
};

export default index;
