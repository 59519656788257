/* eslint-disable */
/* eslint-disable camelcase */
// import core & vendor packages below
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, FormControl, InputGroup } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import seturlParams from 'helpers/seturlParams';
import { selectPatientData } from 'reducers/patientReducer';
import { getDepartmentRequestList } from 'reducers/departmentInventoryReducer';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchInventoryFilter = ({ departmentID, active, searchData, setSearchData }) => {
  const dispatch = useDispatch();

  const handleChange = (key) => (e) => {
    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const handleSearch = (reset = false) => {
    dispatch(
      getDepartmentRequestList({
        status: seturlParams(active, 'status'),
        search: seturlParams(reset ? '' : searchData?.search, 'q'),
        department: `&department_id=${departmentID}`,
      }),
    );
  };

  return (
    <div className='admissions-search-inventory-filter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col className='d-flex align-items-center'>
            <Form.Group className='mb-3 me-3'>
              <Form.Label>Request Number</Form.Label>

              <InputGroup className='search'>
                <InputGroup.Text>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  value={searchData?.search}
                  onChange={handleChange('search')}
                  type='text'
                  placeholder='Search here...'
                />
              </InputGroup>
            </Form.Group>
            <div style={{ marginTop: '15px' }}>
              <Button
                onClick={() => {
                  handleSearch();
                }}
                style={{ height: '50px' }}
                className='btn btn-success me-3'
              >
                APPLY
              </Button>
              <Button
                style={{ height: '50px' }}
                className='btn btn-secondary me-3'
                onClick={() => {
                  setSearchData({
                    ...searchData,
                    search: '',
                  });
                  handleSearch(true);
                }}
              >
                RESET
              </Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default SearchInventoryFilter;
