// import components below
import ShiftingComponent from './ShiftingComponent';
import PropTypes from 'prop-types';

// import assets below
import './index.scss';

// main component
const Shifting = ({ setEmpData, setActiveScreen, setActiveTab }) => {
  return (
    <div className='shifting-list'>
      <ShiftingComponent setEmpData={setEmpData} setActiveScreen={setActiveScreen} setActiveTab={setActiveTab} />
    </div>
  );
};

Shifting.defaultProps = {
  setActiveTab: () => { },
};

Shifting.propTypes = {
  setActiveTab: PropTypes.func,
};

export default Shifting;
