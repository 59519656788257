/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Form, FormControl, Accordion } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import { certificateStatus } from 'helpers';
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './index.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const state = [
  {
    certificate: 'CERTIFICATE 1',
  },
  {
    certificate: 'CERTIFICATE 2',
  },
  {
    certificate: 'CERTIFICATE 3',
  },
];

const details = [
  {
    status: 'Active',
    available_path: 'DETAILS',
  },
];

const index = ({ setRequestData, openModal, setOpenModal }) => {

  const [showScreen, setShowScreen] = useState(1);

  const closeModal = () => {
    setOpenModal({
    });
  };

    const tableColumns = React.useMemo(
      () => [
        { Header: 'Certificates', accessor: 'certificate' },
      ],
      [],
    );

    const pathColumns = React.useMemo(
      () => [
        { Header: 'Available Path', accessor: 'available_path' },
        { Header: 'Certificate Status', accessor: 'status' },
      ],
      [],
    );
  
    const tableData = React.useMemo(
      () =>
        state?.map((dd) => ({
          certificate: dd?.certificate,
          data: dd,
        })),
      [state],
    );

    const pathData = React.useMemo(
      () =>
        details?.map((dd) => ({
          status: certificateStatus(dd?.status),
          available_path: dd?.available_path,
          data: dd,
        })),
      [details],
    );

  return (
    <>
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <Modal
            size='lg'
            centered
            onHide={closeModal}
            show={openModal?.isOpen}
            className='credentials-credits'
          >
            <Modal.Header>
              <h6>Select Certificate</h6>
              <Button onClick={closeModal}>
                <CloseSVG />
              </Button>
            </Modal.Header>

            <Modal.Body>
              <div className='main-content'>
                <p>Certificates List</p>
                <div className='credits-table'>
                  <Table
                    data={tableData}
                    columns={tableColumns}
                    customAction={(id, data) => {
                      return (
                        <a href
                          className='link'
                          style={{ cursor: 'pointer' }}
                          onClick={() => setShowScreen(2)}
                        >
                          Click here to view certificate properties
                        </a>
                      );
                    }}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        }
      />

      <ConditionalRender
        condition={showScreen === 2}
        renderIf={
            <Modal
              size='lg'
              centered
              onHide={closeModal}
              show={openModal?.isOpen}
              className='credentials-credits'
            >
              <Modal.Header>
                <h6>Certificate Details</h6>
                <Button onClick={closeModal}>
                  <CloseSVG />
                </Button>
              </Modal.Header>
              
              <Modal.Body>
                <div className='main-content'>
                  <Accordion defaultActiveKey='0' alwaysOpen>
                    <Accordion.Header>  
                      <div className='text-white'>GENERAL</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <span>General Information &emsp; : &emsp; 
                        <span style={{ color: '#00AEEF' }}>DETAILS</span>
                      </span>
                    </Accordion.Body>

                  </Accordion>

                  <Accordion defaultActiveKey='0' alwaysOpen>
                    <Accordion.Header>  
                      <div className='text-white'> DETAILS </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      <div className='details-content'>
                        <Form.Group className='mb-4'>
                          <Form.Label>Show</Form.Label>

                          <Form.Select>
                            <option value='Approved'>All</option>
                            <option value='Denied'>Second Option</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className='inline-group'>
                          <Col>
                          <span>Properties &emsp; : &emsp; 
                            <span style={{ color: '#00AEEF' }}>DETAILS</span>
                          </span>
                          </Col>
                          <Col>
                          <Button className='edit'>
                            Edit Properties
                          </Button>
                          &ensp;
                          <Button className='copy'>
                            Copy to File
                          </Button>
                          </Col>
                        </Form.Group>
                      </div>
                    </Accordion.Body>

                  </Accordion>

                  <Accordion defaultActiveKey='0' alwaysOpen>
                    <Accordion.Header>  
                      <div className='text-white'>CERTIFICATION PATH</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className='main-content'>
                        <div className='credits-table'>
                          <Table
                            data={pathData}
                            columns={pathColumns}
                            customAction={(id, data) => {
                              return (
                                <Button className='copy'>
                                  View
                                </Button>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={() => setShowScreen(1)} className='save'>
                  OK
                </Button>

                <Button onClick={() => setShowScreen(1)} className='cancel'>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
        }
      />
    </>
  );
};

export default index;
