/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormControl, Button, Tabs, Tab, Image } from 'react-bootstrap';

// import components below
import EmployeeList from './EmployeeList';
import OvertimeList from './OvertimeList';
import TabsUI from 'components/UI/TabsUI';
import ConditionalRender from 'components/ConditionalRender';
import { TableFiltersPermission, checkStringPermission } from 'helpers/filteredPermissions';
import { showUsers, getACLState, getRoles, getDepartments, getLogs } from 'reducers/aclReducer';

//  import assets below
import './index.scss';

// main component
const OvertimeContainer = () => {
  const [activeKey, setActiveKey] = useState('');
  const [showScreen, setShowScreen] = useState(1);
  const [requestData, setRequestData] = useState();

  const onChange = (key) => {
    setActiveKey(key);
  };

  const defaultTabs = [
    {
      title: 'Employee List',
      component: EmployeeList,
    },
    {
      title: 'Overtime List',
      component: OvertimeList,
    },
  ];

  // eslint-disable-next-line prefer-const
  let tabs = TableFiltersPermission(defaultTabs, []);

  return (
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <div className='overtime-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>Overtime Tracker</Form.Label>
                <FormControl className='blue-control' placeholder='Search Employee' />
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='overtime-tabs'>
                <TabsUI>
                  <Tabs
                    defaultActiveKey={tabs[0].title}
                    onSelect={(eventKey) => onChange(eventKey)}
                  >
                    {tabs.map(({ title, component: Component }, idx) => (
                      <Tab key={idx} eventKey={title} title={title}>
                        <Component
                          activeKey={activeKey}
                          setShowScreen={setShowScreen}
                          setRequestData={setRequestData}
                        />
                      </Tab>
                    ))}
                  </Tabs>
                </TabsUI>
              </div>
            </div>
          </div>
        }
      />
  );
};

export default OvertimeContainer;
