// import core & vendor packages below
import React, { useState } from 'react';

// import components below
import Filter from './components/Filter';
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './index.scss';

// main component
const PurchaseRequest = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    isShowProfile: false,
    profileId: null,
    isShowViewRequestModal: false,
    isAcceptModal: false,
    isDenyModal: false,
    isShowDenyItemModal: false,
  });

  const isDefaultView = showProfile.isShowProfile === false;

  return (
    <div>
      <ConditionalRender
        condition={isDefaultView}
        renderIf={
          <Filter
            state={state}
            setState={setState}
            showProfile={showProfile}
            setShowProfile={setShowProfile}
          />
        }
      />
    </div>
  );
};

export default PurchaseRequest;
