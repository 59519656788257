/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';

// import components below
import AdvancedSearch from './AdvancedSearch';
import FormPatientProfile from 'components/FormPatientProfile';
import ModalAddDiagnosis from 'pages/Nursing/components/Diagnosis/AddDiagnosis';
import ModalNote from 'pages/Doctors/components/SearchPatient/components/Note';
import ModalSupply from 'pages/Nursing/components/SearchPatient/PatientWorklist/Supply';
import ModalProcedure from 'pages/Nursing/components/SearchPatient/PatientWorklist/Procedure';
import ModalLabRequest from 'pages/Nursing/components/SearchPatient/PatientWorklist/LabRequest';
import ModalMedication from 'pages/Nursing/components/SearchPatient/PatientWorklist/Medication';

// main component
const PerDepartment = ({ title, apiKey, users }) => {
  const [showProfile, setShowProfile] = useState({
    profileId: null,
    isShowProfile: false,
    labRequest: false,
    addOrder: false,
    procedure: false,
    medication: false,
    supply: false,
    note: false,
    fileUpload: false,
  });

  return (
    <div className='doctorFilter'>
      {!showProfile.isShowProfile ? (
        <AdvancedSearch users={users} apiKey={apiKey} setShowProfile={setShowProfile} />
      ) : (
        <>
          <FormPatientProfile setShowProfile={setShowProfile} showProfile={showProfile} />
          <ModalLabRequest setShowProfile={setShowProfile} showProfile={showProfile} />
          <ModalProcedure setShowProfile={setShowProfile} showProfile={showProfile} />
          <ModalMedication setShowProfile={setShowProfile} showProfile={showProfile} />
          <ModalAddDiagnosis showProfile={showProfile} setShowProfile={setShowProfile} />
          <ModalNote showProfile={showProfile} setShowProfile={setShowProfile} />
          <ModalSupply showProfile={showProfile} setShowProfile={setShowProfile} />
        </>
      )}
    </div>
  );
};

export default PerDepartment;
