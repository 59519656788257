/* eslint-disable */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
// import { Tabs, Tab } from 'react-bootstrap';

// import TabsUI from 'components/UI/TabsUI';
import Reports from './Reports';
import Tabs from 'components/Tabs';
import SearchPatient from './PatientWorklist';
import EligibilityCheck from './EligibilityCheck';

import './Tabs.scss';

// main component
const PhilhealthTabs = ({ onChange }) => {
  const [activeTab, setActiveTab] = useState('Patient Worklist');

  const handleTabOnChange = (eventKey) => {
    onChange(eventKey);
    setActiveTab(eventKey);
  };

  const options = [
    {
      className: 'p-4',
      title: 'Patient Worklist',
      eventKey: 'Patient Worklist',
      component: SearchPatient,
    },
    // {
    //   className: 'p-4',
    //   title: 'Eligibility Check',
    //   component: EligibilityCheck,
    //   eventKey: 'Eligibility Check',
    // },
    // {
    //   className: 'p-4',
    //   title: 'Reports',
    //   component: Reports,
    //   eventKey: 'Reports',
    // },
  ];

  const tabGroupName = 'PhilhealthTabs';
  const tabsProps = {
    activeKey: activeTab,
    defaultActiveKey: options[0].eventKey,
    onSelect: handleTabOnChange,
    tabKey: tabGroupName,
    options,
  };

  return (
    <div className={tabGroupName}>
      <Tabs {...tabsProps} />
    </div>
  );
};

PhilhealthTabs.defaultProps = {
  onChange: () => {},
};

PhilhealthTabs.propTypes = {
  onChange: PropTypes.func,
};

export default PhilhealthTabs;
