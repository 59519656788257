/* eslint-disable no-unused-vars */
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';

import QrComponent from './QrComponent';

import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

const QrScannerModal = ({ setShowProfile, setShow }) => {
  return (
    <div className='shadow-sm qr-code-container'>
      <div className='qr-code-container-header'>
        <div className='d-flex header'>
          <p>QR Scanner</p>

          <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={() => setShow(false)} />
        </div>
      </div>

      <div className='qr-code-container-body'>
        <QrComponent setShowProfile={setShowProfile} setShow={setShow} />

        <p className='mt-3'>QR code will not be detected if the captured image is too blur or the QR code itself is not in high quality mode.</p>
      </div>
    </div>
  );
};

export default QrScannerModal;
