/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Table, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { updatePermission } from 'reducers/aclReducer';

import TableUI from 'components/UI/TableUI';
import TablePagination from './TablePagination';
import ConditionalRender from 'components/ConditionalRender';

import './index.scss';

const ProfileTable = ({
  permissions,
  userData,
  changePermissionPage,
  setShowResultsNumber,
  showResultsNumber,
}) => {
  const dispatch = useDispatch();
  const [stateUser, setStateUser] = useState({});
  const [allAccess, setAllAccess] = useState(false);

  useEffect(() => {
    setStateUser(userData);
  }, []);

  useEffect(() => {
    const accessAllStatus =
      permissions?.meta?.pagination?.total === stateUser?.attributes?.permissions.length;

    setAllAccess(accessAllStatus);
  }, [stateUser, permissions]);

  const getAccess = (permission) => {
    const userPermissions = stateUser?.attributes?.permissions;

    if (!permission || !userPermissions) return;

    return userPermissions.indexOf(permission) > -1;
  };

  const tableData = permissions?.data.map((data) => {
    return {
      id: data.id,
      data: data,
      system: data?.attributes?.name,
      grant_access: getAccess(data?.attributes?.name),
    };
  });

  const onUpdatePermission = async (value, userId, permission) => {
    let params = {};

    if (permission === 'ALL' || permission === 'All') {
      params = {
        id: userId,
        payload: {
          [value ? 'add_all_permission' : 'remove_all_permission']: true,
        },
      };
    } else {
      params = {
        id: userId,
        payload: {
          [value ? 'add_permission' : 'remove_permission']: [`${permission}`],
        },
      };
    }

    const response = await dispatch(updatePermission(params));

    if (response) {
      if (response.meta.requestStatus === 'fulfilled') {
        setStateUser(response?.payload?.data);
      }
    }
  };

  const haveList = tableData.length > 0;
  const meta = permissions?.meta;
  const links = permissions?.links;

  return (
    <div className='tables-cont'>
      <TableUI>
        <Table responsive>
          <thead>
            <tr>
              <th>System</th>
              <th>Grant Access</th>
              <th className='access-all'>
                <p>Access All</p>
                <Form.Check
                  type='switch'
                  id='grant-access'
                  checked={allAccess}
                  className='grant-access'
                  onChange={(e) => {
                    onUpdatePermission(e.target.checked, userData.id, 'ALL');
                  }}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <ConditionalRender
              condition={haveList}
              renderIf={tableData.map((data) => {
                return (
                  <tr key={data.id}>
                    <td>{data.system}</td>
                    <td>
                      <Form.Check
                        type='switch'
                        id='grant-access'
                        className='grant-access'
                        checked={data.grant_access}
                        onChange={(e) => {
                          onUpdatePermission(e.target.checked, userData.id, data.system);
                        }}
                      />
                      <p>{data.grant_access}</p>
                    </td>
                    <td className={data.status}>
                      <p>{data.status}</p>
                    </td>
                  </tr>
                );
              })}
            />
          </tbody>
        </Table>

        <TablePagination
          meta={meta}
          links={links}
          changePage={changePermissionPage}
          showResultsNumber={showResultsNumber}
          setShowResultsNumber={setShowResultsNumber}
        />
      </TableUI>
    </div>
  );
};

ProfileTable.defaultProps = {
  userData: {},
  permissions: [],
  showResultsNumber: 10,
  changePermissionPage: () => {},
  setShowResultsNumber: () => {},
};

ProfileTable.propTypes = {
  userData: PropTypes.object,
  permissions: PropTypes.array,
  showResultsNumber: PropTypes.number,
  setShowResultsNumber: PropTypes.func,
  changePermissionPage: PropTypes.func,
};

export default ProfileTable;
