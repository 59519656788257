import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getBilling = createAsyncThunk(
  'GET_BILLING',
  async (filter = {}, { rejectWithValue }) => {
    return await axios
      .get(`/billing?search=${filter}`, {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showBillingByPatient = createAsyncThunk(
  'SHOW_BILLING_BY_PATIENT',
  async (id, { rejectWithValue }) => {
    return await axios
      .get(`/billing/${id}`, {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showBilling = createAsyncThunk(
  'patient/showPatient',
  async (id, { rejectWithValue }) => {
    return await axios
      .get(`/patient/${id}`, {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createBilling = createAsyncThunk(
  'CREATE_BILLING',
  async (payload, { rejectWithValue }) => {
    return await axios
      .post(`/bills_payment`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createBillDeduction = createAsyncThunk(
  'CREATE_BILL_DEDUCTION',
  async (payload, { rejectWithValue }) => {
    return await axios
      .post(`/bill_deduction`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
export const dischargeBilling = createAsyncThunk(
  'DISCHARGE_BILLING',
  async (payload, { rejectWithValue }) => {
    return await axios
      .put(`/billing/discharge`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getDisbursementPatient = createAsyncThunk(
  'GET_DISBURSEMENT_PATIENT',
  async (filter = {}, { rejectWithValue }) => {
    return await axios
      .get(`/disbursement/search/cashier/patient_disbursement?search=${filter}`, {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getDisbursementAdvancedSearchPatient = createAsyncThunk(
  'GET_DISBURSEMENT_ADVANCED_SEARCH_PATIENT',
  async ([id, patientClass, patientStatus], { rejectWithValue }) => {
    return await axios
      .get(
        `/disbursement/search/cashier/patient_for_disbursement?id=${id}&patientClass=${patientClass}&patientStatus=${patientStatus}`,
        {},
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getDisbursedPatient = createAsyncThunk(
  'GET_DISBURSED_PATIENT',
  async (filter = {}, { rejectWithValue }) => {
    return await axios
      .get(`/disbursement?search=${filter}`, {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
export const getDisbursedAdvanceSearchPatient = createAsyncThunk(
  'GET_DISBURSED_ADVANCED_SEARCH_PATIENT',
  async ([id, patientClass, patientStatus], { rejectWithValue }) => {
    return await axios
      .get(
        `/disbursement/search/cashier/patient_disbursed?id=${id}&patientClass=${patientClass}&patientStatus=${patientStatus}`,
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
