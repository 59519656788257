/* eslint-disable */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'components/ReactTable';
import { Button } from 'react-bootstrap';
// import axios from 'axios';

// import components below
import ViewModal from './ViewModal';
import ViewEditModal from './ViewEditModal';
import BgStatusColor from 'helpers/bgColors';
import seturlParams from 'helpers/seturlParams';
import RemoveItemModal from './RemoveItemModal';
import RequestItemModal from './RequestItemModal';
import RequestCompleteModal from './RequestCompleteModal';
import ConditionalRender from 'components/ConditionalRender';
import { getGroupItems } from 'reducers/inventoryReducer';
import {
  getDepartmentRequestList,
  selectDepartmentInventory,
} from 'reducers/departmentInventoryReducer';

// import assets below
import './Table.scss';

// main component
const InventoryListTable = ({ active, setActive, searchData, departmentID }) => {
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectDepartmentInventory);
  const { requestData, requestItems, requestMeta, requestLogs } = inventoryState;

  const [showModal, setShowModal] = useState({
    requestItem: false,
    viewEdit: false,
    confirmDelete: false,
    completeRequest: false,
    data: null,
  });

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Request #', accessor: 'request_no' },
      { Header: 'Requested By', accessor: 'request_by' },
      { Header: 'Requested Date / Time', accessor: 'requested_datetime' },
      { Header: 'Number of Items', accessor: 'number_of_items' },
      // { Header: 'Storage Bin', accessor: 'storage_bin' },
      // { Header: 'Quantity', accessor: 'quantity' },
      // { Header: 'UOM', accessor: 'uom' },
      { Header: 'Department', accessor: 'department' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      requestData?.map((dd) => {
        const numberOfItems = dd?.relationships?.restock_items?.data.length;

        return {
          id: dd?.id,
          // uom: selectedItem?.attributes?.unit_of_measure,
          // quantity: selectedItem?.attributes?.quantity,
          // storage_bin: selectedItem?.attributes?.storage_bin,
          number_of_items: numberOfItems,
          status_text: dd?.attributes?.status,
          request_by: dd?.attributes?.requested_by,
          request_no: dd?.attributes?.request_number,
          requested_datetime: dd?.attributes?.requested_at,
          department: dd?.attributes?.requesting_department,
          status: (
            <span
              className={`status badge ${BgStatusColor[dd?.attributes?.status]}`}
              style={{ width: '110px' }}
            >
              {dd?.attributes?.status}
            </span>
          ),
        };
      }),
    [requestData],
  );

  // Handle table filters
  const handleOnClickFilter = (status) => {
    setActive(status);
  };

  // Kinds of status
  const filterList = [
    { key: '', name: 'All Items' },
    { key: '0', name: 'New' },
    { key: '1', name: 'In Progress' },
    { key: '5', name: 'For Issuance' },
    { key: '6', name: 'For Receiving' },
    { key: '8', name: 'Completed' },
    { key: '9', name: 'Denied' },
  ];

  // api call
  const getInventoryRequestList = () => {
    dispatch(
      getDepartmentRequestList({
        status: seturlParams(active, 'status'),
        search: seturlParams(searchData?.search, 'q'),
        department: `&department_id=${departmentID}`,
      }),
    );
    dispatch(getGroupItems('?q=&per_page=100'));
  };

  useEffect(() => {
    getInventoryRequestList();
  }, []);

  useEffect(() => {
    getInventoryRequestList();
  }, [active]);

  const arrModals = [
    RequestItemModal,
    ViewEditModal,
    RemoveItemModal,
    RequestCompleteModal,
    ViewModal,
  ];

  return (
    <div className='admissions-inventory-list-table request-item'>
      <h4 className='mb-0 fw-bold mt-4 mb-2'>Store Inventory List</h4>

      <Table
        logs={requestLogs}
        data={tableData}
        columns={tableColumns}
        customAction={(id) => {
          const row = tableData.find((dd) => dd.id === id);
          const { status_text } = row;

          const buttons = [
            {
              text: 'View',
              color: 'blue',
              action: () => {
                setShowModal((prevState) => {
                  return {
                    ...prevState,
                    view: true,
                    data: row,
                  };
                });
              },
              condition:
                status_text === 'approved' ||
                status_text === 'in progress' ||
                status_text === 'for issuance' ||
                status_text === 'for receiving' ||
                status_text === 'completed' ||
                status_text === 'denied',
            },
            {
              color: 'blue',
              action: () => {
                setShowModal((prevState) => {
                  return {
                    ...prevState,
                    viewEdit: true,
                    data: row,
                  };
                });
              },
              text: 'View / Edit',
              condition: status_text === 'new',
            },
            {
              condition: status_text === 'new',
              action: () => {
                setShowModal((prevState) => {
                  return {
                    ...prevState,
                    confirmDelete: true,
                    data: row,
                  };
                });
              },
              text: 'Remove',
              color: 'red',
            },
            {
              condition: status_text === 'for receiving',
              action: () => {
                setShowModal((prevState) => {
                  return {
                    ...prevState,
                    completeRequest: true,
                    data: row,
                  };
                });
              },
              text: 'Request Complete?',
              color: 'green',
            },
          ];

          return (
            <div className='action-buttons'>
              {buttons.map((buttonData) => {
                return (
                  <ConditionalRender
                    condition={buttonData?.condition}
                    renderIf={
                      <span
                        className={`badge ${buttonData?.color}`}
                        style={{ cursor: 'pointer' }}
                        onClick={buttonData?.action}
                      >
                        {buttonData?.text}
                      </span>
                    }
                  />
                );
              })}
            </div>
          );
        }}
        pagination={['BOTTOM']}
        filter={{
          top: (
            <div className='buttons'>
              {filterList.map((list) => (
                <Button
                  key={list.key}
                  className={list.key === active ? 'active' : ''}
                  onClick={() => handleOnClickFilter(list.key)}
                >
                  {list.name}
                </Button>
              ))}

              <Button
                onClick={() => {
                  setShowModal({
                    ...showModal,
                    requestItem: true,
                  });
                }}
                style={{ background: '#6BC9DD', marginLeft: 'auto' }}
              >
                Request Item
              </Button>
            </div>
          ),
        }}
      />

      {/* Modals */}
      {arrModals.map((Component) => {
        return (
          <Component
            showModal={showModal}
            departmentID={departmentID}
            setShowModal={setShowModal}
            getInventoryRequestList={getInventoryRequestList}
          />
        );
      })}
    </div>
  );
};

export default InventoryListTable;
