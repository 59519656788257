import { Route } from 'react-router-dom';

import ProtectedRoute from 'components/ProtectedRoute';
import OutpatientDepartment from 'pages/OutpatientDepartment';
import OutpatientCreateNewPatient from 'pages/OutpatientDepartment/components/CreateNewPatient';
import EditPatientProfileOutpatient from 'pages/OutpatientDepartment/components/EditPatientProfile';
import OutpatientCreateTemporaryProfile from 'pages/OutpatientDepartment/components/CreateTemporaryProfile';

export default [
  <Route
    key='main'
    path='/dashboard/outpatient-department'
    element={<ProtectedRoute department={'outpatient'} component={OutpatientDepartment} />}
  />,
  <Route
    key='edit profile'
    path='/dashboard/outpatient-department/edit-patient-profile'
    element={<ProtectedRoute department={'outpatient'} component={EditPatientProfileOutpatient} />}
  />,
  <Route
    key='create new patient'
    path='/dashboard/outpatient-department/create-new-patient'
    element={<ProtectedRoute department={'outpatient'} component={OutpatientCreateNewPatient} />}
  />,
  <Route
    key='create temporary profile'
    path='/dashboard/outpatient-department/create-temporary-profile'
    element={
      <ProtectedRoute department={'outpatient'} component={OutpatientCreateTemporaryProfile} />
    }
  />,
];
