/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAssignedDoctors = createAsyncThunk(
  'department/getAssignedDoctors',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `assigned_doctor`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const submitProfessionalFee = createAsyncThunk(
  'department/submitProfessionalFee',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `assigned_doctor`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);