/* eslint-disable */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'components/ReactTable';
import { Button } from 'react-bootstrap';
// import axios from 'axios';

// import components below
import ViewItemRepair from './Modals/ViewRepair';
import RequestToRepair from './Modals/RequestToRepair';
import ConditionalRender from 'components/ConditionalRender';
import EditRequestToRepair from './Modals/EditRequestToRepair';
import { TableFiltersPermission } from 'helpers/filteredPermissions';

import {
  selectDepartmentInventory,
  getDepartmentMaintenanceRepairList,
} from 'reducers/departmentInventoryReducer';

// import assets below
import './Table.scss';

// main component
const InventoryListTable = ({
  active,
  setActive,
  searchData,
  refreshData,
  departmentID,
  setSearchData,
  setRefreshData,
}) => {
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectDepartmentInventory);
  const { repairData, repairItems, repairLogs } = inventoryState;

  const [showModal, setShowModal] = useState({
    data: null,
    showRequestRepair: false,
    editRequestRepair: false,
  });

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Control Number', accessor: 'control_number' },
      { Header: 'Equipment Name', accessor: 'equipment_name' },
      { Header: 'Equipment Descriptions', accessor: 'equipment_description' },
      { Header: 'Delivered by', accessor: 'delivered_by' },
      { Header: 'Received By', accessor: 'received_by' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      repairData?.map((dd) => {
        // Get item data from included
        const itemData = repairItems.filter(
          (data) =>
            parseInt(data?.id) === parseInt(dd?.attributes?.item_id) && data?.type === 'items',
        );

        return {
          id: dd?.id,
          control_number: dd?.attributes?.control_number,
          equipment_name: itemData[0]?.attributes?.name,
          equipment_description: dd?.attributes?.description,
          delivered_by: dd?.attributes?.delivery_by,
          received_by: dd?.attributes?.received_by,
          data: dd?.attributes,
          itemName: itemData[0]?.attributes?.name,
        };
      }),
    [repairData],
  );

  const handleTableAction = (eventKey, id) => {
    const row = repairData.find((dd) => dd.id === id);

    const { item_id } = row.attributes;

    const itemData = repairItems.filter(
      (data) => parseInt(data?.id) === parseInt(item_id) && data?.type === 'items',
    );

    const action = {
      profile: () => {},
      view: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            rowData: row,
            showView: true,
            itemId: row?.id,
            itemData: itemData[0],
          };
        });
      },
      edit: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            rowData: row,
            itemId: row?.id,
            editRequestRepair: true,
          };
        });
      },
      complete: () => {},
      remove: () => {},
    };

    action[eventKey]();
  };

  const arrModals = [RequestToRepair, EditRequestToRepair, ViewItemRepair];

  const defaultList = [
    { id: 3, key: '11', name: 'Completed' },
    {
      id: 1,
      key: '',
      name: 'Repair/Maintenance',
    },
    {
      id: 2,
      key: '12',
      name: 'Condemnation',
    },
  ];

  const lists = [];

  let filterList = TableFiltersPermission(defaultList, lists);
  filterList.sort(function (a, b) {
    return a.id - b.id;
  });

  const handleOnClickFilter = (status) => {
    setActive(status);
  };

  // api call
  const getInventoryMaintenanceRepair = (reset = false) => {
    let params = {
      per_page: 100000,
      department_id: departmentID,
      q: reset ? '' : searchData?.search,
    };

    if (active === '') {
      params.completed_by = false;
    }

    if (active !== '') {
      params.status = active;
    }

    if (active === '12' || active === '11') {
      params.completed_by = true;
    }

    dispatch(getDepartmentMaintenanceRepairList(params));
  };

  useEffect(() => {
    getInventoryMaintenanceRepair();
  }, []);

  useEffect(() => {
    getInventoryMaintenanceRepair();
  }, [active]);

  return (
    <div className='admissions-inventory-list-table'>
      <h4 className='mb-0 fw-bold mt-4 mb-2'>Repair Condemnation List</h4>

      <Table
        data={tableData}
        logs={repairLogs}
        columns={tableColumns}
        customAction={(id, data) => {
          const { completed_by } = data.data;

          return (
            <div className='custom-actions'>
              <span
                className='badge blue'
                style={{ cursor: 'pointer' }}
                onClick={() => handleTableAction('view', id)}
              >
                View
              </span>
              <ConditionalRender
                condition={completed_by === null}
                renderIf={
                  <span
                    className='badge green'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleTableAction('edit', id)}
                  >
                    Edit
                  </span>
                }
              />
            </div>
          );
        }}
        filter={{
          top: (
            <div className='buttons'>
              {filterList.map((list) => (
                <Button
                  key={list.key}
                  onClick={() => handleOnClickFilter(list.key)}
                  className={list.key === active ? 'active' : ''}
                >
                  {list.name}
                </Button>
              ))}
              <Button
                onClick={() => {
                  setShowModal({
                    ...showModal,
                    showRequestRepair: true,
                  });
                }}
                style={{ background: '#00A8FF', marginLeft: 'auto' }}
              >
                Request Item for Repair
              </Button>
            </div>
          ),
        }}
        pagination={['BOTTOM']}
      />

      {/* Modals */}
      {arrModals.map((Component) => {
        return (
          <Component
            showModal={showModal}
            refreshData={refreshData}
            departmentID={departmentID}
            setShowModal={setShowModal}
            setRefreshData={setRefreshData}
            getInventoryMaintenanceRepair={getInventoryMaintenanceRepair}
          />
        );
      })}
    </div>
  );
};

export default InventoryListTable;
