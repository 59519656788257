/* eslint-disable */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl, Accordion } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import FormUI from 'components/UI/FormUI';
import seturlParams from 'helpers/seturlParams';
import {
  getGroupItems,
  selectInventory,
  addInventoryItems,
  showInventoryItems,
} from 'reducers/inventoryReducer';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const AddNewItemModal = ({ showProfile, setShowProfile, getTableInventoryItems }) => {
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectInventory);
  const {
    storeInventoryData,
    inventoryGroupData,
    storeInventoryItems,
    storeInventoryItemsLogs,
    inventoryGroupItemsLogs,
    addInventoryItemItemsLogs,
  } = inventoryState;

  const [search, setSearch] = useState({
    search: '',
    groupId: '',
  });
  const [itemData, setItemData] = useState({});
  const [itemList, setItemList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [isLocked, setIsLocked] = useState(true);

  const handleChange = (key, e) => {
    setItemData({
      ...itemData,
      [key]: e.target.value,
    });
  };

  const handleSearchChange = (key) => (e) => {
    setSearch({
      ...search,
      [key]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = {
      id: itemData.id,
      payload: {
        is_locked: isLocked,
        on_hand: itemData.on_hand,
        min_stock: itemData.min_stock,
        max_stock: itemData.max_stock,
        storage_bin: itemData.storage_bin,
        piece_per_box: itemData.piece_per_box,
        re_order_point: itemData.re_order_point,
        unit_of_measure: itemData.unit_of_measure,
        average_consumption: itemData.average_consumption,
        estimated_time_arrival: itemData.estimated_time_arrival,
      },
    };

    dispatch(addInventoryItems(params));
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Item Code', accessor: 'code' }, // accessor is the "key" in the data
      { Header: 'Item Name', accessor: 'name' },
      { Header: 'Generic Name', accessor: 'generic_name' },
      { Header: 'Item Group', accessor: 'item_group' },
      { Header: 'Section', accessor: 'section' },
    ],
    [],
  );

  const tableData = React.useMemo(() => {
    const newData = itemList?.map((dd) => {
      const groupData = groupList.find(
        (d) => parseInt(d?.id) === parseInt(dd?.attributes?.group_id),
      );

      return {
        id: dd?.id,
        code: dd?.attributes?.code,
        name: dd?.attributes?.name,
        generic_name: dd?.attributes?.generic_name,
        item_group: groupData?.attributes?.name,
        department: dd?.attributes?.department,
        section: dd?.attributes?.section,
        otc: dd?.attributes?.otc,
        data: dd,
      };
    });

    return newData;
  }, [itemList]);

  const handleTableAction = (eventKey, id) => {
    const row = itemList.find((dd) => dd.id === id);
    const action = {
      select: () => {
        setItemData(row);
      },
    };

    action[eventKey]();
  };

  const getInventoryItems = (condition = false) => {
    if (condition) {
      dispatch(showInventoryItems(`?include[]=group&is_new=1`));
    } else {
      dispatch(
        showInventoryItems(
          `?include[]=group&is_new=1${seturlParams(search?.search, 'q')}${seturlParams(
            search?.groupId,
            'group_id',
          )}`,
        ),
      );
    }

    dispatch(getGroupItems('?q=&per_page=100'));
  };

  const closeModal = () => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        showAddItem: false,
      };
    });

    getTableInventoryItems();
  };

  // useEffects
  renderOnShowModal(showProfile, getInventoryItems);

  setItemListDataOnSuccess(storeInventoryItemsLogs, storeInventoryData, setItemList);

  setGroupListDataOnSuccess(inventoryGroupItemsLogs, inventoryGroupData, setGroupList);

  getResultOnAddedItem(
    setItemData,
    setShowProfile,
    getTableInventoryItems,
    addInventoryItemItemsLogs,
  );

  useEffect(() => {
    // console.log('itemData', itemData);
  }, [itemData]);

  return (
    <Modal
      size='lg'
      show={showProfile.showAddItem}
      onHide={() => closeModal()}
      aria-labelledby='Add New Item Modal'
      className='add-new-item-modal'
    >
      <Modal.Body>
        <div className='add-new-item-request'>
          <div className='d-flex header'>
            <p>ADD NEW ITEM TO STORE</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={() => closeModal()} />
          </div>
        </div>

        <FormUI>
          <Form className='add-item-form' onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col>
                <div className='search-item'>
                  <Accordion className='advanced-search'>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Search Item</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Item Code/Name</Form.Label>
                              <FormControl
                                type='text'
                                className='bg-white'
                                placeholder='Search Here'
                                value={search?.search}
                                onChange={handleSearchChange('search')}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Item Group</Form.Label>
                              <Form.Select
                                value={search?.groupId}
                                onChange={handleSearchChange('groupId')}
                              >
                                <option value='' hidden>
                                  -Select-
                                </option>
                                {groupList.map((data) => {
                                  return (
                                    <option key={data?.id} value={data?.id}>
                                      {data?.attributes?.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col>
                            <div className='buttonWrapper'>
                              <Button
                                onClick={() => {
                                  getInventoryItems();
                                }}
                                variant='search'
                              >
                                GO
                              </Button>
                              <Button
                                onClick={() => {
                                  setSearch({
                                    search: '',
                                    groupId: '',
                                  });

                                  getInventoryItems(true);
                                }}
                                variant='search'
                              >
                                RESET
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div className='search-table'>
                  <Table
                    data={tableData}
                    columns={tableColumns}
                    customAction={(id) => (
                      <div className='custom-actions'>
                        <Button onClick={() => handleTableAction('select', id)}>Select</Button>
                      </div>
                    )}
                    pagination={['BOTTOM']}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className='item-information'>
                  <Accordion className='advanced-search' defaultActiveKey={'0'}>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Item Information</Accordion.Header>
                      <Accordion.Body>
                        <Row className='selected-item mb-4'>
                          <h3>Item Information</h3>
                          <Col>
                            Item Code :{' '}
                            <span style={{ color: '#00a8ff', fontWeight: 'bold' }}>
                              {itemData?.attributes?.code}
                            </span>
                          </Col>
                          <Col>
                            Item Name :{' '}
                            <span style={{ color: '#00a8ff', fontWeight: 'bold' }}>
                              {itemData?.attributes?.name}
                            </span>
                          </Col>
                          <Col>
                            Piece Price :{' '}
                            <span style={{ color: '#00a8ff', fontWeight: 'bold' }}>
                              {itemData?.attributes?.piece_price}
                            </span>
                          </Col>
                          <Col>
                            Box Price :{' '}
                            <span style={{ color: '#00a8ff', fontWeight: 'bold' }}>
                              {itemData?.attributes?.box_price}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <h3>Store Item Information</h3>
                          <Col>
                            <Row>
                              <Col>
                                <Form.Group className='mb-2 inline-group'>
                                  <Form.Label>On Hand : </Form.Label>
                                  <FormControl
                                    type='text'
                                    value={itemData?.on_hand || ''}
                                    onChange={(e) => handleChange('on_hand', e)}
                                    placeholder='Enter Here'
                                  />
                                </Form.Group>

                                <Form.Group className='mb-2 inline-group'>
                                  <Form.Label>Unit of Measurement : </Form.Label>
                                  <Form.Select
                                    value={itemData?.unit_of_measure || ''}
                                    onChange={(e) => handleChange('unit_of_measure', e)}
                                  >
                                    <option value='' hidden>
                                      - Select -
                                    </option>
                                    <option value='piece'>Piece</option>
                                    <option value='box'>Box</option>
                                  </Form.Select>
                                </Form.Group>

                                <Form.Group className='mb-2 inline-group'>
                                  <Form.Label>Storage Bin : </Form.Label>
                                  <FormControl
                                    type='text'
                                    value={itemData?.storage_bin || ''}
                                    onChange={(e) => handleChange('storage_bin', e)}
                                    placeholder='Enter Here'
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className='mb-2 inline-group'>
                                  <Form.Label>Stock Quantities : </Form.Label>
                                  <div className='locking-input'>
                                    <Form.Group className=''>
                                      <Form.Label>Minimum</Form.Label>
                                      <FormControl
                                        type='number'
                                        placeholder='Enter Here'
                                        value={itemData?.min_stock || ''}
                                        onChange={(e) => handleChange('min_stock', e)}
                                        onKeyDown={(evt) =>
                                          (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                                          evt.preventDefault()
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group className=''>
                                      <Form.Label>Maximum</Form.Label>
                                      <FormControl
                                        type='number'
                                        placeholder='Enter Here'
                                        value={itemData?.max_stock || ''}
                                        onChange={(e) => handleChange('max_stock', e)}
                                        onKeyDown={(evt) =>
                                          (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                                          evt.preventDefault()
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                </Form.Group>

                                <Form.Group className='mb-2 inline-group'>
                                  <Form.Label>Piece per Box : </Form.Label>
                                  <FormControl
                                    type='number'
                                    value={itemData?.piece_per_box || ''}
                                    onChange={(e) => handleChange('piece_per_box', e)}
                                    placeholder='Enter Here'
                                  />
                                </Form.Group>

                                <Form.Group className='mb-2 inline-group'>
                                  <Form.Label>Daily Average Consumption : </Form.Label>
                                  <FormControl
                                    type='text'
                                    value={itemData?.average_consumption || ''}
                                    onChange={(e) => handleChange('average_consumption', e)}
                                    placeholder='Enter Here'
                                  />
                                </Form.Group>

                                <Form.Group className='mb-2 inline-group'>
                                  <Form.Label>Estimated lead time and days : </Form.Label>
                                  <FormControl
                                    type='text'
                                    value={itemData?.estimated_time_arrival || ''}
                                    onChange={(e) => handleChange('estimated_time_arrival', e)}
                                    placeholder='Enter Here'
                                  />
                                </Form.Group>

                                <Form.Group className='mb-2 inline-group'>
                                  <Form.Label>Re Order Point : </Form.Label>
                                  <FormControl
                                    type='text'
                                    value={itemData?.re_order_point || ''}
                                    onChange={(e) => handleChange('re_order_point', e)}
                                    placeholder='Enter Here'
                                  />
                                </Form.Group>

                                <Form.Group className='mb-2 switch-group'>
                                  <Form.Check
                                    type='switch'
                                    id='custom-switch'
                                    label='Lock Automatically'
                                    onChange={() => setIsLocked(!isLocked)}
                                    checked={isLocked}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    type='submit'
                    variant='primary'
                    className='add-button'
                    disabled={
                      addInventoryItemItemsLogs?.isLoading === true ||
                      Object.keys(itemData).length === 0
                    }
                  >
                    Add New Item
                  </Button>
                  <Button className='cancel-button' onClick={() => setItemData({})}>
                    Clear
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const renderOnShowModal = (showProfile, getInventoryItems) => {
  useEffect(() => {
    if (showProfile.showAddItem) {
      getInventoryItems();
    }
  }, [showProfile]);
};

const getResultOnAddedItem = (
  setItemData,
  setShowProfile,
  getTableInventoryItems,
  addInventoryItemItemsLogs,
) => {
  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = addInventoryItemItemsLogs;

    if (isSuccess) {
      toast.success('Successfully added item');

      setTimeout(() => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showAddItem: false,
          };
        });

        setItemData({});
      }, 200);

      // getInventoryItems();
      getTableInventoryItems();
    }

    if (isFailed) {
      toast.error(error);
    }
  }, [addInventoryItemItemsLogs]);
};

const setItemListDataOnSuccess = (storeInventoryItemsLogs, storeInventoryData, setItemList) => {
  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = storeInventoryItemsLogs;

    if (isSuccess) {
      setItemList(storeInventoryData);
    }
  }, [storeInventoryItemsLogs]);
};

const setGroupListDataOnSuccess = (inventoryGroupItemsLogs, inventoryGroupData, setGroupList) => {
  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = inventoryGroupItemsLogs;

    if (isSuccess) {
      setGroupList(inventoryGroupData);
    }
  }, [inventoryGroupItemsLogs]);
};

AddNewItemModal.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

AddNewItemModal.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default AddNewItemModal;
