// import core & vendor packages below
/* import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; */
import { Container, Row } from 'react-bootstrap';

// import components below
import Layout from 'components/Layout';
// import MedicalRecordsTabs from './components/Tabs';
import AdmissionHeader from './components/PageHeader';
import MedicalRecordsContent from './components/RecordsContent';

// import assets below
import './style.scss';

// main component
const MedicalRecords = () => {

  return (
    <Layout pageTitle='MedicalRecordsInventory'>
      <AdmissionHeader />
      <div className='medical-records-container'>
        <Container fluid>
          <Row>
            <MedicalRecordsContent />
          </Row>
        </Container>
        </div>
    </Layout>
  );
};

export default MedicalRecords;
