/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
// import core & vendor packages below

import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import Table from 'components/ReactTable';
import TableUI from 'components/UI/TableUI';
import ConditionalRender from 'components/ConditionalRender';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import { getSuppliesList, selectnurse, resetOrderListState } from 'reducers/nurseReducer';

import './SuppliesTable.scss';

const SuppliesTable = ({ showProfile, setShowModal }) => {
  const dispatch = useDispatch();
  const orderState = useSelector(selectnurse);
  const patientProfile = useSelector(selectPatientItem);
  const { suppliesItems } = orderState;

  const [state, setState] = useState([]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Item Ordered', accessor: 'item_ordered' },
      { Header: 'Supply Description', accessor: 'supply_description' },
      { Header: 'Quantity', accessor: 'quantity' },
      { Header: 'Priority', accessor: 'priority' },
      { Header: 'Notes', accessor: 'notes' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => {
        if (!suppliesItems) return;

        const orderList = dd?.data?.relationships?.order_items?.data;

        const getItems = (type) => {
          const newData = orderList.map((od, idx) => {
            const orderData = suppliesItems.find(
              (d) => +d?.id === +od?.id && d?.type === 'order_items',
            );

            const itemData = suppliesItems.find(
              (d) => +d?.id === +orderData?.attributes?.item_id && d?.type === 'items',
            );

            if (type === 'name') return <li>{itemData?.attributes?.name}</li>;

            if (type === 'description') return <li>{itemData?.attributes?.description}</li>;

            if (type === 'quantity') return <li>{orderData?.attributes?.quantity}</li>;

            return itemData;
          });

          return newData;
        };

        return {
          data: dd,
          id: dd?.id,
          item_ordered: <ul className='table-list'>{getItems('name')}</ul>,
          supply_description: <ul className='table-list'>{getItems('description')}</ul>,
          quantity: <ul className='table-list'>{getItems('quantity')}</ul>,
          priority: dd?.priority_level,
          notes: dd?.priority_level,
        };
      }),
    [state],
  );

  triggerAPI(showProfile, dispatch, patientProfile);

  getDataFromStore(orderState, setState, dispatch);

  useEffect(() => {
    console.log('Supplies state', state);
  }, [state]);

  return (
    <div className='patientProfile__header'>
      <h4>Supplies</h4>

      <Row>
        <Col>
          <TableUI>
            <Table
              data={tableData}
              columns={tableColumns}
              pagination={['BOTTOM']}
              logs={orderState?.suppliesLogs}
            />
          </TableUI>
        </Col>
      </Row>
    </div>
  );
};

const triggerAPI = (showProfile, dispatch, patientProfile) => {
  useEffect(() => {
    if (showProfile?.profileId && patientProfile) {
      const params = {
        per_page: 100000,
        patient_id: showProfile?.profileId,
        patient_visit_number: patientProfile?.active_visit_number,
        is_medicine: 0,
      };

      dispatch(getSuppliesList(params));
    }
  }, [showProfile, patientProfile]);
};

const getDataFromStore = (orderState, setState, dispatch) => {
  const { suppliesLogs, suppliesData, suppliesItems } = orderState;

  useEffect(() => {
    const { isSuccess, isFailed } = suppliesLogs;

    if (isSuccess) {
      const filteredData = suppliesData.map((data) => {
        return {
          data: data,
          id: data?.id,
          name: data?.attributes?.patient_name,
          patient_id: data?.attributes?.patient_id,
          ordered_by: data?.attributes?.orderer_name,
          ordered_id: data?.attributes?.orderer_id,
          ordered_date: data?.attributes?.created_at,
          priority_level: data?.attributes?.priority_level,
          total_amount: data?.attributes?.total_amount,
        };
      });

      setState(filteredData);
    }

    if (isFailed) {
      const { error } = suppliesLogs;

      toast.error(error[0]?.detail);

      dispatch(resetOrderListState());
    }
  }, [suppliesLogs]);
};

export default SuppliesTable;
