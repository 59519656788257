// import core & vendor packages below
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

// import components below
import RepairMaintenanceTable from './Table';

// main component
const PurchaseOrderList = ({ setState, setShowProfile, showProfile }) => {
  return (
    <div className='purchase-order-list'>
      {/* <Row className='mb-4'>
        <Col sm={3}>
          <Form.Group style={{ width: '100%' }}>
            <Form.Label>Purchase Order No.</Form.Label>
            <FormControl type='text' className='bg-white' placeholder='Search Here' />
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Label>Item Group</Form.Label>
            <Form.Select className='select-form'>
              <option>- Select -</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col>
          <div className='buttonWrapper'>
            <Button variant='search'>GO</Button>

            <Button variant='clear'>RESET</Button>
          </div>
        </Col>
      </Row> */}

      <Row>
        <Col>
          <RepairMaintenanceTable
            showProfile={showProfile}
            setState={setState}
            setShowProfile={setShowProfile}
          />
        </Col>
      </Row>
    </div>
  );
};

PurchaseOrderList.defaultProps = {
  setState: () => {},
  setShowProfile: () => {},
};

PurchaseOrderList.propTypes = {
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default PurchaseOrderList;
