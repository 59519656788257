// import assets below
import './style.scss';

// main component
const FormLoginUI = (props) => {
  const { children } = props;
  return <div className='formLoginUI'>{children}</div>;
};

export default FormLoginUI;
