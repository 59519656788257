// import core & vendor packages below
import { Container, Row } from 'react-bootstrap';

// import components below
import Layout from 'components/Layout';
import EditPatientProfileHeader from './PageHeader';
import FormEditPatientProfile from 'components/FormEditPatientProfile';

// main component
const EditPatientProfile = () => {
  return (
    <Layout pageTitle='Admissions'>
      <EditPatientProfileHeader />
      <Container fluid>
        <Row>
          <FormEditPatientProfile />
        </Row>
      </Container>
    </Layout>
  );
};

export default EditPatientProfile;
