/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

// Packages
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, FormControl, Accordion } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import StoreItemSetupFilterTable from './Table';

// Assets
import './Filter.scss';

// Main component
const StoreItemSetupFilter = ({ setState, setShowProfile }) => {
  return (
    <div className='StoreItemSetupFilter'>
      <FormUI>
        <Row>
          <Col>
            <Accordion activeKey='0' className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Store Item List</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>Current Store</Form.Label>
                        <Form.Select>
                          <option>Main Warehouse</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>Supplying Store</Form.Label>
                        <Form.Select>
                          <option>- Select -</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Item Group</Form.Label>
                        <Form.Select>
                          <option>- Select -</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>Item Code / Name</Form.Label>
                        <FormControl type='text' className='bg-white' placeholder='Search Here' />
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className='buttonWrapper mb-4'>
                        <Button variant='search'>GO</Button>
                        <Button variant='clear'>RESET</Button>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <StoreItemSetupFilterTable
                        setState={setState}
                        setShowProfile={setShowProfile}
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

StoreItemSetupFilter.defaultProps = {
  setState: () => {},
  setShowProfile: () => {},
};

StoreItemSetupFilter.propTypes = {
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default StoreItemSetupFilter;
