/* eslint-disable */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
// import { Tabs, Tab } from 'react-bootstrap';

// import TabsUI from 'components/UI/TabsUI';
import Tabs from 'components/Tabs';
import SearchPatient from './SearchPatient';

import './Tabs.scss';

// main component
const SocialTabs = ({ onChange }) => {
    const [activeTab, setActiveTab] = useState('Logs');

    const handleTabOnChange = (eventKey) => {
        setActiveTab(eventKey);
        onChange(eventKey);
    };

    const options = [
        {
            title: 'Logs',
            eventKey: 'Logs',
            // eventKey: 'store-inventory',
            className: 'p-4',
            component: SearchPatient,
        },
    ];

    const tabGroupName = 'SocialTabs';
    const tabsProps = {
        activeKey: activeTab,
        defaultActiveKey: options[0].eventKey,
        onSelect: handleTabOnChange,
        tabKey: tabGroupName,
        options,
    };

    return (
        <div className={tabGroupName}>
            <Tabs {...tabsProps} />
        </div>
    );
};

SocialTabs.defaultProps = {
    onChange: () => { },
};

SocialTabs.propTypes = {
    onChange: PropTypes.func,
};

export default SocialTabs;
