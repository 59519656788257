/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// components
import FormUI from 'components/UI/FormUI';
import { selectAuth } from 'reducers/authReducer';
import ConditionalRender from 'components/ConditionalRender';
import { submitProfessionalFee } from 'reducers/professionalFeeReducer';

// import assets below
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const ConfirmModal = ({ showModal, setShowModal, setState, refreshData }) => {
  const dispatch = useDispatch();
  const userState = useSelector(selectAuth);
  const { userInfo, updateProfileLogs } = userState;

  const [userData, setUserData] = useState({
    is_negotiable: '',
    professional_fee: '',
    default_professional_fee: '',
  });

  const handleChange = (key) => (e) => {
    const { id, data } = showModal;

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const tagPatientAsCleared = () => {
    const { id, data } = showModal;

    axios
      .put(`/clearance/doctors/${id}`, {
        ...userData,
        patient_id: data.patient.id,
      })
      .then((response) => {
        refreshData();

        setShowModal((prevState) => {
          return { ...prevState, show: false };
        });

        setUserData({
          ...userData,
          professional_fee: '',
        });

        toast.success('Successfully tag patient as cleared');

        const params = {
          is_approved: true,
          id: id,
        };

        dispatch(submitProfessionalFee(params));
      })
      .catch((error) => {
        console.log(error);
        toast.error('ERROR');
      });
  };

  const closeModal = () => {
    setShowModal((prevState) => {
      return {
        ...prevState,
        id: null,
        data: null,
        show: false,
      };
    });
  };

  useEffect(() => {
    if (showModal?.data && userInfo) {
      setUserData((prev) => {
        const finalFee =
          showModal?.data?.professional_fee === null
            ? userInfo?.default_professional_fee
            : showModal?.data?.professional_fee;

        const finalNegotiable =
          userInfo?.patient_default_negotiable === 1 ||
          userInfo?.patient_default_negotiable === true;

        return {
          ...prev,
          professional_fee: finalFee,
          is_negotiable: finalNegotiable,
          set_nurse_fee: showModal?.data?.professional_fee,
          default_professional_fee: userInfo?.default_professional_fee,
        };
      });
    }
  }, [showModal, userInfo]);

  return (
    <Modal
      show={showModal.show}
      className='add-procedure-modal'
      aria-labelledby='Lab Request Modal'
      onHide={() => {
        closeModal();
      }}
    >
      <Modal.Body>
        <div className='add-procedure-request'>
          <div className='d-flex header'>
            <p>Approve</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowModal((prevState) => {
                  return { ...prevState, procedure: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Professional fee</Form.Label>
                  <FormControl
                    readOnly
                    type='number'
                    required={true}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.professional_fee}
                    onChange={handleChange('professional_fee')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    variant='primary'
                    className='add-button'
                    onClick={() => {
                      tagPatientAsCleared();
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    vvariant='secondary'
                    className='cancel-button'
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  showModal: () => {},
  setShowModal: () => {},
};

ConfirmModal.propTypes = {
  showModal: PropTypes.func,
  setShowModal: PropTypes.func,
};

export default ConfirmModal;
