/* eslint-disable */
import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import InventoryHeader from './components/PageHeader';
import EmployeesTabs from './components/Tabs';
import ViewEmployee from './components/EmployeesList/Employee/ViewEmployee';
import AddEmployee from './components/EmployeesList/Employee/AddEmployee';
import EditEmployee from './components/EmployeesList/Employee/EditEmployee';
import ViewShifting from './components/Shifting/ShiftingComponent/ViewShifting';
import AddEditShifting from './components/Shifting/ShiftingComponent/AddEditShifting';

// import assets below
import './index.scss';

// main component
const Employees = () => {
  const [activeScreen, setActiveScreen] = useState(0);
  const [activeTab, setActiveTab] = useState('Employees List');
  const [empData, setEmpData] = useState();

  const isDefault = activeScreen === 0;
  const isAddEmployee = activeScreen === 1;
  const isViewEmployee = activeScreen === 2;
  const isEditEmployee = activeScreen === 3;
  const isViewShifting = activeScreen === 4;
  const isAddShifting = activeScreen === 5;
  const isEditShifting = activeScreen === 6;

  return (
    <Layout pageTitle='Employees'>
      <InventoryHeader active={activeTab} />

      {/* Tabs */}
      {isDefault && (
        <EmployeesTabs
          activeTab={activeTab}
          setEmpData={setEmpData}
          setActiveTab={setActiveTab}
          setActiveScreen={setActiveScreen}
        />
      )}

      {isViewEmployee && (
        <ViewEmployee
          empData={empData}
          setActiveTab={setActiveTab}
          setActiveScreen={setActiveScreen}
        />
      )}
      {isAddEmployee && (
        <AddEmployee
          type='add'
          title={'ADD NEW EMPLOYEE'}
          setActiveTab={setActiveTab}
          setActiveScreen={setActiveScreen}
        />
      )}
      {isEditEmployee && (
        <EditEmployee
          type='edit'
          empData={empData}
          setActiveTab={setActiveTab}
          setActiveScreen={setActiveScreen}
          title={'UPDATE EMPLOYEE INFORMATION'}
        />
      )}
      {isViewShifting && (
        <ViewShifting
          empData={empData}
          setEmpData={setEmpData}
          setActiveTab={setActiveTab}
          setActiveScreen={setActiveScreen}
        />
      )}
      {isAddShifting && (
        <AddEditShifting
          type='add'
          empData={empData}
          setEmpData={setEmpData}
          setActiveTab={setActiveTab}
          title={'ADD SHIFTING SCHEDULE'}
          setActiveScreen={setActiveScreen}
        />
      )}
      {isEditShifting && (
        <AddEditShifting
          type='edit'
          empData={empData}
          setEmpData={setEmpData}
          setActiveTab={setActiveTab}
          title={'EDIT SHIFTING SCHEDULE'}
          setActiveScreen={setActiveScreen}
        />
      )}
    </Layout>
  );
};

export default Employees;
