/* eslint-disable no-unused-vars */
// main components below
import { useState } from 'react';

import Filter from './components/Filter';
import ViewDetails from './components/ViewDetails';
import AppointmentListTable from './components/Table';

// import assets below
import './style.scss';

// main component
const AppointmentList = ({ setRefreshTable, refreshTable }) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    isShowProfile: false,
    profileId: null,
  });

  return (
    <div className='appointmentList'>
      {viewDetails ? (
        <ViewDetails setViewDetails={setViewDetails} />
      ) : (
        <>
          <Filter setState={setState} />

          <div className='appointmentList'>
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className='fw-bold mb-3'>Appointment List</h5>
            </div>

            {refreshTable?.appointmentSearch && (
              <AppointmentListTable
                state={state}
                setState={setState}
                refreshTable={refreshTable}
                setShowProfile={setShowProfile}
                setViewDetails={setViewDetails}
                setRefreshTable={setRefreshTable}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AppointmentList;
