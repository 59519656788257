import { Route } from 'react-router-dom';

import ProtectedRoute from 'components/ProtectedRoute';
import EmergencyDepartment from 'pages/EmergencyDepartment';
import EmergencyCreateNewPatient from 'pages/EmergencyDepartment/components/CreateNewPatient';

export default [
  <Route
    key='main'
    path='/dashboard/emergency-department'
    element={<ProtectedRoute key='' department={'emergency'} component={EmergencyDepartment} />}
  />,

  <Route
    key='create new patient'
    path='/dashboard/emergency-department/create-new-patient'
    element={<ProtectedRoute department={'emergency'} component={EmergencyCreateNewPatient} />}
  />,
];
