/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

// import components below
import SucessModal from './SuccessModal';
import ModalUI from 'components/UI/ModalUI';
import { setPatientState } from 'reducers/patientReducer';

// import assets below
import './ConfirmationModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
// eslint-disable-next-line no-unused-vars
const ConfirmationModal = ({ setOpenModal, params, submitType, uploadedFiles, setUploadedFiles }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const closeModal = () => {
    setOpenModal(false);
  };

  const createNewPatient = async () => {

    const formdata = new FormData(); // profile pic

    const fileuploads = new FormData(); // file uploads

    formdata.append('profile_pic', params.profilePicture);

    for (const key of Object.keys(uploadedFiles)) {
      fileuploads.append('FILES', uploadedFiles[key]);
    }

    await axios
      .post('/patient', params)
      .then((response) => {
        formdata.append('patientId', response.data.data[0].id);
        fileuploads.append('patientId', response.data.data[0].id);

        axios.post('/patient/file_upload', fileuploads)
          .then((response) => {
            setUploadedFiles([]);

            axios.post('/patient/profile_pic', formdata)
              .then((response) => {
                console.log(response);
              }, [])
              .catch((err) => {
                err?.response?.data?.errors.map((item) => {
                  toast.warning(item.msg + ' ' + item.param);
                });
              });
          }, [])
          .catch((err) => {
            err?.response?.data?.errors.map((item) => {
              toast.warning(item.msg + ' ' + item.param);
            });
          });

        setOpenModal(false);
        toast.success('Successfully created new patient!');

        if (submitType === 'savePatient') {
          navigate('/dashboard/outpatient-department');
        } else if (submitType === 'saveAndAdmit') {
          dispatch(setPatientState(response?.data?.data[0]));
          navigate('/dashboard/admissions/create-inpatient-visit');
        }
      })
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
        toast.warning(err.response.data.errors.message);

        setTimeout(() => {
          setOpenModal(false);
        }, 2000);
      });
  };

  return (
    <div className='loginModal'>
      <ModalUI>
        <Modal.Dialog>
          <Modal.Header id='confirmation-modal-header'>
            <h6>CONFIRMATION</h6>
            <Button onClick={closeModal}>
              <CloseSVG />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <p>A new patient record will be created.</p>
            <p>Are you sure you want to save and create new patient record?</p>
          </Modal.Body>

          <Modal.Footer>
            <Button className='loginModal__button' onClick={createNewPatient}>
              CREATE NEW PATIENT
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </ModalUI>
      {modal && <SucessModal />}
    </div>
  );
};

export default ConfirmationModal;
