/* eslint-disable */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import Results from './Results';
import TabsUI from 'components/UI/TabsUI';
import CreateLab from './CreateLab/SearchRoom';
import ClearanceRequest from './ClearanceRequest';
import InventoryPerDepartment from 'components/InventoryPerDepartment';

// main component
const LaboratoryTabs = ({ onChange }) => {
  const [activeTab, setActiveTab] = useState('Results');

  const handleTabOnChange = (eventKey) => {
    setActiveTab(eventKey);
    onChange(eventKey);
  };

  const tabs = [
    /*{
      title: 'Create Laboratory',
      component: CreateLab
    },
    {
      title: 'Clearance Request',
      component: ClearanceRequest
    },*/
    {
      title: 'Results',
      component: Results
    }/*,
    {
      title: 'Inventory',
      component: InventoryPerDepartment
    }*/
  ];

  return (
    <div className='nursingPatientTabs'>
      <TabsUI>
        <Tabs defaultActiveKey={'Results'} onSelect={(eventKey) => handleTabOnChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component departmentID={'7'}/>
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

LaboratoryTabs.defaultProps = {
  onChange: () => { },
};

LaboratoryTabs.propTypes = {
  onChange: PropTypes.func,
};

export default LaboratoryTabs;
