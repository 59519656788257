/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState } from 'react';

// import components below
import Filter from './components/Filter';
import ViewItemModal from './components/ViewItemModal';
import AdjustmentModal from './components/AdjustmentModal';
import ConditionalRender from 'components/ConditionalRender';
import CreateAdjustmentModal from './components/CreateAdjustmentModal';

// impor assets below
import './index.scss';

// Main Component
const StockAdjustment = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    showView: false,
    profileId: null,
    forApprove: false,
    isShowProfile: false,
    showAdjustmentModal: false,
    showCreateNewAdjustment: false,
  });

  const isDefault = showProfile.isShowProfile === false;

  return (
    <>
      <div>
        <ConditionalRender
          condition={isDefault}
          renderIf={
            <>
              <Filter setState={setState} setShowProfile={setShowProfile} />
              {/* <StockAdjustmentDetails setState={setState} setShowProfile={setShowProfile} /> */}
            </>
          }
        />
      </div>

      <CreateAdjustmentModal showProfile={showProfile} setShowProfile={setShowProfile} />
      <AdjustmentModal showProfile={showProfile} setShowProfile={setShowProfile} />
      <ViewItemModal showProfile={showProfile} setShowProfile={setShowProfile} />
    </>
  );
};

export default StockAdjustment;
