/* eslint-disable */
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

// import components below
import TabsUI from 'components/UI/TabsUI';
import { department } from 'helpers/constants';
import { getPermissionPerInventory } from 'helpers/constants';
import { TableFiltersPermission, checkStringPermission } from 'helpers/filteredPermissions';

// Component tabs
import BorrowItem from './components/BorrowItem';
import RequestItem from './components/RequestItem';
import InventoryItems from './components/InventoryItems';
import RepairCondemnation from './components/RepairCondemnation';
import DepartmentInventory from './components/DepartmentInventory';
import InventoryItemsTabs from './components/InventoryItems/Tabs';

import './style.scss';

// main component
const InventoryPerDepartment = ({ departmentID }) => {
  const [refreshData, setRefreshData] = useState(false);
  const [activeKey, setActiveKey] = useState('');

  let pageTabs = [
    {
      title: 'Inventory Items',
      component: InventoryItemsTabs,
    },
    {
      title: 'Request Item',
      component: RequestItem,
    },
    {
      title: 'Repair / Condemnation',
      component: RepairCondemnation,
    },
    {
      title: 'Borrow Item',
      component: BorrowItem,
    },
  ];

  const onChange = (key) => {
    setActiveKey(key);

    if (key === 'Repair / Condemnation' || key === 'Inventory Items') {
      setRefreshData(true);
    }
  };

  return (
    <div className='admissions-inventory-tab'>
      <TabsUI>
        <Tabs defaultActiveKey={pageTabs[0].title} onSelect={(eventKey) => onChange(eventKey)}>
          {pageTabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component
                activeKey={activeKey}
                refreshData={refreshData}
                departmentID={departmentID}
                setRefreshData={setRefreshData}
              />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

InventoryPerDepartment.defaultProps = {
  departmentID: '',
};

InventoryPerDepartment.propTypes = {
  departmentID: PropTypes.string,
};

export default InventoryPerDepartment;
