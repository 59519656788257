/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';

export const getAllEmployees = createAsyncThunk(
  'EMPLOYEES_GET_THUNK',
  async (params = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      // url: `api/users`,
      url: `employee/lists`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });

    // return await axios
    //   .get('/acl/api/users', { params })
    //   .then((response) => {
    //     return response.data;
    //   })
    //   .catch((error) => {
    //     return rejectWithValue(error.response.data);
    //   });
  },
);

export const createEmployee = createAsyncThunk(
  'EMPLOYEE_CREATE_THUNK',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: payload,
      url: `api/users`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });

    // return await axios
    //   .post('/acl/api/users', payload)
    //   .then((response) => {
    //     return response.data;
    //   })
    //   .catch((error) => {
    //     return rejectWithValue(error.response.data);
    //   });
  },
);

export const updateEmployee = createAsyncThunk(
  'EMPLOYEE_UPDATE_THUNK',
  async ([id, payload], { rejectWithValue }) => {
    return await axios({
      method: 'put',
      data: payload,
      url: `api/users/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });

    // return await axios
    //   .put(`/acl/api/users/7596`, payload)
    //   .then((response) => {
    //     return response.data;
    //   })
    //   .catch((error) => {
    //     return rejectWithValue(error.response.data);
    //   });
  },
);

export const deleteEmployeeById = createAsyncThunk(
  'EMPLOYEE_DELETE_THUNK',
  async (id, { rejectWithValue }) => {
    return await axios
      .delete(`/employee/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
