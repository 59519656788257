import TableUI from 'components/UI/TableUI';
import { FormControl, InputGroup, Table } from 'react-bootstrap';

import './DeliveriesTable.scss';

const DeliveriesTable = (props) => {
  const { item } = props;

  return (<div className='mt-3 discharged'>
    <TableUI>
      <Table striped hover style={{ width: '80%' }}>
        <thead>
          <tr>
            <th>Deliveries</th>
            <th>Number</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total number of in-facility deliveries</td>
            <td><InputGroup className='mb-1 mt-1'>
              <FormControl
                placeholder='Enter Here'
                disabled
                value={item.totalifdelivery}
              />
            </InputGroup></td>
          </tr>
          <tr>
            <td>Total number of vaginal deliveries (normalspontaneous)</td>
            <td><InputGroup className='mb-1 mt-1'>
              <FormControl
                placeholder='Enter Here'
                disabled
                value={item.totallbvdelivery}
              />
            </InputGroup></td>
          </tr>
          <tr>
            <td>Total number of live-birth C-section deliveries(Caesarians)</td>
            <td><InputGroup className='mb-1 mt-1'>
              <FormControl
                placeholder='Enter Here'
                disabled
                value={item.totallbcdelivery}
              />
            </InputGroup></td>
          </tr>
          <tr>
            <td>Total number of other deliveries(forceps delivery, vacuum extraction)</td>
            <td><InputGroup className='mb-1 mt-1'>
              <FormControl
                placeholder='Enter Here'
                disabled
                value={item.totalotherdelivery}
              />
            </InputGroup></td>
          </tr>
        </tbody>
      </Table>
    </TableUI>
  </div>);
};

export default DeliveriesTable;
