/* eslint-disable */
// import core & vendor packages below
import moment from 'moment';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Table from 'components/ReactTable';
import seturlParams from 'helpers/seturlParams';

import {
  getDepartmentInventory,
  selectDepartmentInventory,
} from 'reducers/departmentInventoryReducer';

// Assets below
import './Table.scss';

const sampleData = [
  {
    date_time_return: new Date(),
    item_code: 'PHA0001',
    item_name: 'Augmentin 500mg / 125mg tab',
    item_group: 'Medical Supplies',
    unit_price: '200.00',
    storage_bin: 'S-1',
    quantity: '20',
    reason: 'Damage Item',
    status: 'Success',
  },
  {
    date_time_return: new Date(),
    item_code: 'PHA0002',
    item_name: 'Biogesic',
    item_group: 'Medical Supplies',
    unit_price: '200.00',
    storage_bin: 'S-1',
    quantity: '20',
    reason: 'Incomplete Item',
    status: 'To Review',
  },
];

// main component
const InventoryListTable = ({ searchData, departmentID }) => {
  const dispatch = useDispatch();

  const inventoryState = useSelector(selectDepartmentInventory);

  const { data: inventoryData, items: inventoryItems, logs } = inventoryState;

  const [showModal, setShowModal] = useState(false);

  const getGroupName = (id) => {
    const selectedArray = inventoryItems.filter(
      (data) => parseInt(data.id) === parseInt(id) && data?.type === 'groups',
    );

    if (selectedArray.length === 0) {
      return 'N/A';
    }

    return selectedArray[0]?.attributes?.name;
  };

  const getPrice = (item) => {
    const { unit_of_measure, piece_price, box_price } = item?.attributes;

    if (unit_of_measure === 'piece') {
      return piece_price;
    }

    if (unit_of_measure === 'box') {
      return box_price;
    }
  };

  useEffect(() => {
    dispatch(
      getDepartmentInventory({
        id: departmentID,
        search: seturlParams(searchData?.search, 'q'),
        per_page: 100000,
      }),
    );
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Date / Time Return', accessor: 'date_time_return' },
      { Header: 'Item Code', accessor: 'item_code' },
      { Header: 'Item Name', accessor: 'item_name' },
      { Header: 'Item Group', accessor: 'item_group' },
      { Header: 'Unit Price', accessor: 'unit_price' },
      { Header: 'Storage Bin', accessor: 'storage_bin' },
      { Header: 'Quantity', accessor: 'quantity' },
      { Header: 'Reason', accessor: 'reason' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      // inventoryData?.map((dd) => {
      //   const item = inventoryItems.filter(
      //     (d) => parseInt(d.id) === parseInt(dd?.attributes?.item_id) && d.type === 'items',
      //   );
      //   const selectedItem = item[0];

      //   return {
      //     id: dd?.id,
      //     item_id: dd?.attributes?.item_id,
      //     item_code: selectedItem?.attributes?.code,
      //     item_name: selectedItem?.attributes?.name,
      //     item_group: getGroupName(selectedItem?.attributes?.group_id),
      //     generic_name: selectedItem?.attributes?.generic_name,
      //     description: selectedItem?.attributes?.description,
      //     unit_price: getPrice(selectedItem),
      //     quantity: selectedItem?.attributes?.quantity,
      //   };
      // }),
      // [inventoryData],
      sampleData.map((dd) => {
        return {
          date_time_return: moment(dd?.date_time_return).format('MMM-DD-YYYY hh:mm A'),
          item_code: dd?.item_code,
          item_name: dd?.item_name,
          item_group: dd?.item_group,
          unit_price: dd?.unit_price,
          storage_bin: dd?.storage_bin,
          quantity: dd?.quantity,
          reason: dd?.reason,
          status: <span className={`badge status ${dd?.status}`}>{dd?.status}</span>,
        };
      }),
    [sampleData],
  );

  const handleTableAction = (eventKey, id, data) => {
    const row = {
      data: inventoryData.find((dd) => parseInt(dd.id) === parseInt(id)),
      items: inventoryItems.find(
        (dd) => parseInt(dd.id) === parseInt(data?.item_id) && dd?.type === 'items',
      ),
    };

    const action = {
      profile: () => {},
      view: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            isItemShow: true,
            itemData: row,
            itemId: id,
          };
        });
      },
      edit: () => {},
      complete: () => {},
      remove: () => {},
    };

    action[eventKey]();
  };

  return (
    <>
      <div className='returned-item-to-inventory'>
        <Row>
          <Col className='table-header'>
            <h4 className='mb-0 fw-bold mt-4 mb-2'>Returned Item to inventory</h4>
            <Button className='print-button'>Print</Button>
          </Col>
        </Row>
        <Table
          logs={logs}
          data={tableData}
          columns={tableColumns}
          // customAction={(id, data) => (
          //   <span
          //     onClick={() => handleTableAction('view', id, data)}
          //     className='badge blue'
          //     style={{ cursor: 'pointer' }}
          //   >
          //     View
          //   </span>
          // )}
          pagination={['BOTTOM']}
        />
      </div>
    </>
  );
};

InventoryListTable.defaultProps = {
  searchData: {},
  departmentID: '',
};

InventoryListTable.propTypes = {
  searchData: PropTypes.object,
  departmentID: PropTypes.string,
};

export default InventoryListTable;
