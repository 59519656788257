// import core & vendor packages below
import { Modal, Button } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './SuccessModal.scss';
import { ReactComponent as SignupApprovedSVG } from 'assets/svg/signup_approved_modal.svg';

// main componet
const SucessModal = () => {

    return (
        <div className='loginModal'>
            <ModalUI id='modal'>
                <Modal.Dialog>
                    <Modal.Header>
                        <h6>SUCCESSFULY CREATED NEW PATIENT PROFILE</h6>
                        <Button>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='loginModal__svgContainer'>
                            <SignupApprovedSVG />
                        </div>
                        <br></br>
                        <p>
                            <b>SUCESSFULL!</b>
                        </p>
                    </Modal.Body>

                    <Modal.Footer>

                    </Modal.Footer>
                </Modal.Dialog>
            </ModalUI>
        </div>
    );
};

export default SucessModal;
