/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const sendOTP = createAsyncThunk('sendOTP', async (params = {}, { rejectWithValue }) => {
  return await axios({
    data: params,
    method: 'post',
    url: `api/users/forgot_password`,
    baseURL: process.env.REACT_APP_API_BASE_ACL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
