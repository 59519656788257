/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// main components below
import InventoryListTable from './Table';
import SearchInventoryFilter from './Filter';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

import {
  getNonMedicineItems,
  selectDepartmentInventory,
} from 'reducers/departmentInventoryReducer';

import './style.scss';

// main component
const RequestItem = ({ departmentID, refreshData, setRefreshData, activeKey }) => {
  const dispatch = useDispatch();
  const departmentState = useSelector(selectDepartmentInventory);
  const { nonMedicineItemLogs } = departmentState;

  const [active, setActive] = useState('11');
  const [searchData, setSearchData] = useState({
    search: '',
  });
  const [itemsRefresh, setItemsRefresh] = useState(false);

  useEffect(() => {
    if (refreshData && activeKey === 'Repair / Condemnation') {
      dispatch(getNonMedicineItems());

      setRefreshData(false);
      setItemsRefresh(false);
    }
  }, [refreshData]);

  useEffect(() => {
    const { isSuccess } = nonMedicineItemLogs;

    if (isSuccess) {
      setItemsRefresh(true);
    }
  }, [nonMedicineItemLogs]);

  return (
    <div className='admissions-inventory-tab-repair-condemnation'>
      <ConditionalRender condition={itemsRefresh === false} renderIf={<p>Loading...</p>} />

      <ConditionalRender
        condition={itemsRefresh === true}
        renderIf={
          <>
            <SearchInventoryFilter
              active={active}
              searchData={searchData}
              departmentID={departmentID}
              setSearchData={setSearchData}
            />

            <InventoryListTable
              active={active}
              setActive={setActive}
              searchData={searchData}
              refreshData={refreshData}
              departmentID={departmentID}
              setSearchData={setSearchData}
              setRefreshData={setRefreshData}
            />
          </>
        }
      />
    </div>
  );
};

export default RequestItem;
