/* eslint-disable space-before-function-paren */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

// import core & vendor packages below
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form, FormControl } from 'react-bootstrap';
import Table from 'components/ReactTable';
import { useDispatch, useSelector } from 'react-redux';
import { TableFiltersPermission } from 'helpers/filteredPermissions';
import GoodsReceiptsInformation from './ViewItemModal';

// import components below
import TableUI from 'components/UI/TableUI';
import Condemnation from './Tables/Condemnation';
import AcknowledgeItems from './Tables/AcknowledgeItems';
import RepairMaintenance from './Tables/RepairMaintenance';
import ConditionalRender from 'components/ConditionalRender';
import TablePagination from 'components/TablePagination/Pagination';

// import assets below
import './Table.scss';
import {
  getMaintenance,
  selectMaintenance,
  selectMaintenanceItem,
  selectMaintenanceItems,
  showMaintenanceItem,
  selectrefillRequest,
  forcompleteMaintenance,
} from 'reducers/refillRequest';
import UseStatusAction from 'pages/Inventory/hooks/UseStatusAction';
import { toast } from 'react-toastify';

// main component
const sampleData = [
  {
    id: 1,
    control_number: 'ELEC01',
    equipment_name: 'Wallfan',
    equipment_description: 'Description Here',
    delivered_by: 'Juan',
    received_by: 'Juan',
    deployed_to: 'Juan',
  },
  {
    id: 2,
    control_number: 'WHEEL01',
    equipment_name: 'Wheelchair',
    equipment_description: 'Description Here',
    delivered_by: 'Andress',
    received_by: 'Andress',
    deployed_to: 'Andress',
  },
];

// Main component
const RepairMaintenanceTable = ({ state, setState, setShowProfile, showProfile }) => {
  const [type, setType] = useState('Repair/Maintenance');
  const dispatch = useDispatch();

  const inventoryState = useSelector(selectrefillRequest);
  const { maintenancelogs, forcompleteMaintenancelogs } = inventoryState;

  const maintenanceItem = useSelector(selectMaintenanceItem);
  const maintenanceItems = useSelector(selectMaintenanceItems);
  const datafetch = useSelector(selectMaintenance);
  const [active, setActive] = useState('all');
  const [items, setItems] = useState(null);
  const isRepair = type === 'Repair/Maintenance';
  const isCondemnation = type === 'Condemnation';
  const isAcknowledge = type === 'Acknowledge Items';

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({ search: '', date: '' });
  const [trigger, setTrigger] = useState(false);

  const defaultList = [
    { id: 1, key: 'all', name: 'All Open' },
    {
      id: 2,
      key: 'for condemnation',
      name: 'condemnation',
      permission: '',
    },
    {
      id: 3,
      key: 'for repair',
      name: 'completed',
      permission: '',
    },
  ];

  const lists = [];

  let filterList = TableFiltersPermission(defaultList, lists);

  filterList.sort(function (a, b) {
    return a.id - b.id;
  });

  const getMaintenanceList = () => {
    dispatch(getMaintenance());
  };

  const actionHandler = (id) => (
    <div className='custom-actions-refill-request '>
      <Button
        onClick={() => {
          dispatch(showMaintenanceItem({ id }));
          setShowProfile((prevState) => {
            setItems(null);
            setTrigger(true);
            return {
              ...prevState,
              showView: true,
            };
          });
        }}
        className='view'
      >
        View
      </Button>
    </div>
  );

  const handleOnClickFilter = (status) => {
    const newdatafetch = datafetch.map((dd) => {
      const item_id = dd.attributes?.item_id;

      const itemfind = maintenanceItems?.find(
        (item) => item.id === item_id.toString() && item.type === 'items',
      );
      return { ...dd, item_name: itemfind.attributes?.name };
    });

    let filteredData = newdatafetch;

    if (status === 'for condemnation') {
      filteredData = newdatafetch?.filter((dd) => dd.attributes.status === status);
    }
    
    if (status === 'for repair') {
      filteredData = newdatafetch?.filter(
        (dd) => dd.attributes.status === status && dd.attributes.completed_by,
      );
    }

    setData(filteredData);
    setActive(status);
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Control Number', accessor: 'control_number' }, // accessor is the "key" in the data
      { Header: 'Equipment Name', accessor: 'equipment_name' },
      { Header: 'Equipment Descriptions', accessor: 'equipment_descriptions' },
      { Header: 'Delivered by', accessor: 'delivered_by' },
      { Header: 'Received By', accessor: 'received_by' },
      { Header: 'Deployed to', accessor: 'deployed_to' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Action', accessor: 'action' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      data
        ? data?.map((dd) => ({
            id: dd?.id,
            control_number: dd?.attributes?.control_number || '---',
            equipment_name: dd?.item_name || '---',
            equipment_descriptions: dd?.attributes?.description || '---',
            delivered_by: dd?.attributes?.delivered_by || '---',
            received_by: dd?.attributes?.received_by || '--',
            deployed_to: dd?.attributes?.deployed_to || '--',
            status: UseStatusAction(dd?.attributes?.status),
            action: actionHandler(dd?.id),
          }))
        : null,
    [data],
  );

  const resetfilter = () => {
    setFilter({ search: '', date: '' });

    getMaintenanceList();
  };

  const datefilter = () => {
    if (filter.search !== '' || filter.date !== '') {
      dispatch(getMaintenance({ search: filter.search }));
      return;
    }
  };

  const completeHandler = (data) => {
    if (!data.delivered_by || !data.received_by || !data.deployed_to) {
      toast.error('fill the missing fields');
      return;
    }
    setTrigger(true);
    dispatch(forcompleteMaintenance({ id: items.id, params: data }));
  };

  useEffect(() => {
    getMaintenanceList();
  }, []);

  useEffect(() => {
    if (maintenanceItems) {
      const newdatafetch = datafetch.map((dd) => {
        const item_id = dd.attributes?.item_id;

        const itemfind = maintenanceItems?.find(
          (item) => item.id === item_id.toString() && item.type === 'items',
        );
        return { ...dd, item_name: itemfind.attributes?.name };
      });

      setData(newdatafetch);
      if (state?.itemID) {
        const row = datafetch.find((dd) => dd.id === state.itemID?.id);

        setState((prev) => {
          return { ...prev, itemID: row };
        });
      }
      handleOnClickFilter(state.table_active_status || 'all');
    }
  }, [datafetch]);

  useEffect(() => {
    if (maintenanceItem !== undefined && maintenanceItem.length !== 0) {
      const { included, data } = maintenanceItem;
      const newData = { ...data, equipment_name: included[1]?.attributes?.name };
      setTrigger(false);
      setItems(newData);
    }
  }, [maintenanceItem]);

  useEffect(() => {
    // console.log(state);
  }, [state]);

  useEffect(() => {
    const { isSuccess, error } = forcompleteMaintenancelogs;
    if (isSuccess && trigger) {
      toast.success('Successfully updated resource');
      dispatch(getMaintenance());
      setTrigger(false);
    }
    if (error && trigger) {
      toast.error('Error on updating the resource ');
      setTrigger(false);
    }
    setShowProfile((prev) => {
      return { ...prev, showView: false };
    });
  }, [forcompleteMaintenancelogs]);

  return (
    <>
      <div className='repair-maintenance-table'>
        <Table
          data={tableData}
          logs={maintenancelogs}
          columns={tableColumns}
          filter={{
            top: (
              <>
                <div className='buttons' style={{ flex: 'none', whiteSpace: 'nowrap' }}>
                  {filterList.map((list) => (
                    <Button
                      key={list.key}
                      className={list.key === active ? 'active' : ''}
                      onClick={() => handleOnClickFilter(list.key)}
                    >
                      {list.name}
                    </Button>
                  ))}
                </div>
                <div className='search-filter'>
                  <Form.Group className='mx-1'>
                    <FormControl
                      type='text'
                      className='bg-white'
                      value={filter?.search}
                      placeholder='Search Here'
                      onChange={(e) => {
                        setFilter((prev) => {
                          return { ...prev, search: e.target.value };
                        });
                      }}
                    />
                  </Form.Group>
                  <Col>
                    <div className='buttonWrapper mx-1'>
                      <Button variant='search' onClick={datefilter}>
                        APPLY
                      </Button>

                      <Button onClick={resetfilter} variant='clear'>
                        RESET
                      </Button>
                    </div>
                  </Col>
                </div>
              </>
            ),
          }}
          pagination={['BOTTOM']}
        />
      </div>
      <GoodsReceiptsInformation
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        items={items}
        trigger={trigger}
        completeHandler={completeHandler}
      />
    </>
  );
};

RepairMaintenanceTable.defaultProps = {
  state: {},
  setState: () => {},
  setShowProfile: () => {},
};

RepairMaintenanceTable.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default RepairMaintenanceTable;
