/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormControl, Button, Image } from 'react-bootstrap';

// import components below

// import assets below
import logoImage from 'assets/images/logo2.png';

// main component
const index = ({ requestData, setShowScreen }) => {
  const [data, setData] = useState([]);
  const [modalType, setModalType] = useState();

  const handleAccept = () => {
    setModalType('accept');
  };

  const handleDecline = () => {
    setModalType('decline');
  };

  useEffect(() => {
    if (requestData) {
      //   console.log('yeata', requestData?.advances_data);
      setData(requestData?.data?.advances_data);
    }
  }, [requestData]);

  return (
    <div className='request-container shadow-sm'>
      <div className='box-container blue'>
        <Form.Group className='inline-group'>
          <Form.Label>REQUEST DETAILS</Form.Label>
        </Form.Group>
      </div>

      <div className='box-container department'>
        <Image className='website-logo' src={logoImage} />

        <Form.Group className='employee-detail'>
          <p>JUAN DELA CRUZ</p>
          <span>Name</span>
        </Form.Group>

        <Form.Group className='employee-detail'>
          <p>PHYSICIAN</p>
          <span>Position</span>
        </Form.Group>

        <Form.Group className='employee-detail'>
          <p>DETAILS HERE</p>
          <span>Department</span>
        </Form.Group>
      </div>

      <hr />

      <div className='box-container vertical-direction'>
        <Row className='w-100 mb-3'>
          <Col>
            <Form.Group className='inline-group-header'>
              <Form.Label>Leave Type: </Form.Label>
              <FormControl className='details' placeholder='Details Here' />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='inline-group-header'>
              <Form.Label>Leave Start: </Form.Label>
              <FormControl type='date' className='details' />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='inline-group-header'>
              <Form.Label>Leave End: </Form.Label>
              <FormControl type='date' className='details' />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='inline-group-header'>
              <Form.Label>Status: </Form.Label>
              <FormControl placeholder='Details Here' className='details' />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col>
            <Form.Group className='vertical-group'>
              <Form.Label>Leave Message/ Reason</Form.Label>
              <FormControl as='textarea' rows='5' className='details' placeholder='Details Here' />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col xs={4}>
            <Form.Group className='vertical-group'>
              <Form.Label>Leave Status</Form.Label>

              <Form.Select>
                <option value='' hidden>
                  - Select -
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <div className='details-footer'>
          <Button className='save'>Save</Button>
          <Button onClick={() => setShowScreen(1)} className='cancel'>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default index;
