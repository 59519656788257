/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import seturlParams from 'helpers/seturlParams';

// import components below
import Table from 'components/ReactTable';
import CredentialsCreditsModal from './components/Modal';
import { showGenericCredentialItems, updateGenericCredential, getGenericCredentialList, addGenericCredentialItems, selectCredentialsManagement } from 'reducers/credentialsManagementReducer';

// import assets below
import './index.scss';
import { ReactComponent as ArrowSVG } from 'assets/svg/Union 7.svg';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

// main comnponent
const index = ({ setShowScreen, setFormData, setRequestData }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState();
  const [modalType, setModalType] = useState();
  const [modalData, setModalData] = useState();

  const credentialsManagementState = useSelector(selectCredentialsManagement);
  const { data, item, logs } = credentialsManagementState;

  const [tableList, setTableList] = useState([]);
  
  const tableColumns = React.useMemo(
    () => [
      { Header: 'Internet or Network Address', accessor: 'internet' },
      { Header: 'Username', accessor: 'username' },
      { Header: 'Password', accessor: 'password' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({
        id: dd?.id,
        internet: dd?.internet,
        username: dd?.username,
        password: dd?.password,
        data: dd,
      })),
    [tableList],
  );
  
  const handleViewDetails = (eventKey, id) => {
    const row = tableList.find((dd) => dd.id === id);
    const action = {
      view: () => {
        setOpenModal((prevState) => {
          return {
            ...prevState,
            isOpen: true,
            userId: id,
            rowData: row,
          };
        });
      },
    };

    action[eventKey]();
  };
  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, data, logs);
  return (
    <div className='credentials-credits'>
      <div className='header'>
        <h2>Date Modified: </h2>
        <p> 3-6-2023 &nbsp; <ArrowSVG /> </p>

        <div className='functions'>
          <Button className='upload' onClick={() => setShowScreen(2)}>
            <AddSVG />
            Add Record
          </Button>
        </div>
      </div>

      <div className='credits-table'>
          <Table
            data={tableData}
            columns={tableColumns}
            pagination={['BOTTOM']}
            customAction={(id) => {
              return (
                <span
                  className='badge green'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleViewDetails('view', id);
                  }}
                >
                  Edit
                </span>
              );
            }}
          />
      </div>
      <CredentialsCreditsModal 
        modalType={modalType} 
        openModal={openModal}
        setOpenModal={setOpenModal} 
        modalData={modalData}
      />
    </div>
  );
};

const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getGenericCredentialList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, data, logs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = logs;
    if (isSuccess) {
      const newData = data.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          internet: dd?.internet,
          username: dd?.username,
          password: dd?.password,
        };
      });
      console.log(newData);
      setTableList(newData);
    }
  }, [logs]);
};

export default index;
