import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import './style.scss';
import FormUI from 'components/UI/FormUI';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateDoh } from 'reducers/reportReducer';
import { toast } from 'react-toastify';

const ModalUpdateDoh = (props) => {
    const { showModal, setModal, input } = props;

    const dispatch = useDispatch();
    
    const handleShow = () => setModal(false);
    const [selectDoh, setDoh] = useState();

    const updateDohButton = () => {
        dispatch(updateDoh({ ...input, doh: selectDoh }));
        toast.success('Success');
    };
    return (
        <Modal show={showModal} onHide={handleShow}>
            <Modal.Header closeButton>
                <Modal.Title>Update DOH</Modal.Title>
            </Modal.Header>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Modal.Body>
                <FormUI>
                    <Row>
                        <Col>
                            <Form.Group className='mb-4'>
                                <Form.Label>Select Report</Form.Label>
                                <Form.Select
                                  onChange={(event) => {
                                      setDoh(event.target.value);
                                    }}
                                >
                                    <option hidden>-Select-</option>
                                    <option value='hospOptSummaryOfPatients'>Summary of Patiens</option>
                                    <option value='hospOptDischargesSpecialty'>Total Discharges According to Specialty</option>
                                    <option value='hospOptDischargesSpecialtyOthers'>Total Discharges According to Specialty Others</option>
                                    <option value='hospOptDischargesMorbidity'>Ten Leading causes of Morbidity/Diseases Disaggregated as to Age and Sex</option>
                                    <option value='hospOptDischargesNumberDeliveries'>Total Number of Deliveries.</option>
                                    <option value='hospOptDischargesOPV'>Outpatient Visits, including Emergency Care, Testing and Other Services.</option>
                                    <option value='hospOptDischargesOPD'>Ten Leading OPD Consultations</option>
                                    <option value='hospOptDischargesER'>Ten Leading ER Consultations</option>
                                    <option value='hospOptDischargesTesting'>Testing and Other Services</option>
                                    <option value='hospOptDischargesEV'>Emergency Visits</option>
                                    <option value='hospitalOperationsDeaths'>Total Number of Deaths</option>
                                    <option value='hospitalOperationsMortalityDeaths'>Ten Leading causes of Mortality/Deaths Disaggregated as to Age and Sex</option>
                                    <option value='hospitalOperationsHAI'>Healthcare Associated Infections (HAI)</option>
                                    <option value='hospitalOperationsMajorOpt'>Ten Leading Major Operations</option>
                                    <option value='hospitalOperationsMinorOpt'>Ten Leading Minor Operations</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </FormUI>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={updateDohButton}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
);
};

export default ModalUpdateDoh;
