// import core & vendor packages below
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getPatient } from 'reducers/patientReducer';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchPatientFilter = ({ basicSearch, setBasicSearch, advanceSearch, setAdvanceSearch }) => {
  const dispatch = useDispatch();

  const handleChange = (key) => (e) => {
    console.log('filter');
    setAdvanceSearch({
      ...advanceSearch,
      [key]: e.target.value,
    });
  };

  /* const handleSubmit = () => {
    dispatch(getPatient(filter));
  }; */

  useEffect(() => {
    dispatch(getPatient());
  }, []);

  const handleClear = () => {
    setAdvanceSearch({
      pin: '',
      patient: '',
      createdDate: '',
    });

    setBasicSearch('');
  };

  return (
    <div className='searchPatientFilter'>
      <FormUI>
        <Row>
          <Col>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Basic Search</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  placeholder='Search via Last Name or PIN…'
                  value={basicSearch}
                  onChange={(e) => setBasicSearch(e.target.value)}
                />
                {/* <Button variant='search' onClick={() => handleSubmit()}>
                      SEARCH
                    </Button> */}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Accordion className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Advance Search</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={3}>
                      <Form.Group className='mb-4'>
                        <Form.Label>MRN / PIN</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Enter Here'
                          value={advanceSearch.pin}
                          onChange={handleChange('pin')}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Patient Name</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Enter Patient Name'
                          value={advanceSearch.patient}
                          onChange={handleChange('patient')}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className='mb-4'>
                        <Form.Label>Visit Date</Form.Label>
                        <FormControl
                          type='date'
                          className='bg-white'
                          placeholder='Enter Here'
                          value={advanceSearch.createdDate}
                          onChange={handleChange('createdDate')}
                        /* value={filter.keyword}
                            onChange={handleChange('MRN')} */
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <div className='buttonWrapper'>
                      <Form.Check type={'checkbox'} id={`admitted`} label={`Currently Admitted`} />
                      <Form.Check
                        type={'checkbox'}
                        id={`er-patient`}
                        label={`Currently an ER Patient`}
                      />

                      {/* <Button variant='search' onClick={() => handleSubmit()}>
                            SEARCH
                          </Button> */}

                      <Button variant='clear' onClick={handleClear}>
                        CLEAR
                      </Button>
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default SearchPatientFilter;
