// Tab components
import Records from './Records';

const MedicalRecordsContent = () => {
    return (
        <div>
            <Records />
        </div>
    );
};
export default MedicalRecordsContent;