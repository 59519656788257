/* eslint-disable camelcase */
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';

import './index.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import React, { useState } from 'react';

import FormUI from 'components/UI/FormUI';

const ModalAsset = (props) => {
  const {
    setShowModal,
    showModal,
    popupTitle,
    handleChange,
    setFields,
    handlePostRequest,
    fields,
    handleUpdateRequest,
  } = props;
  const [validated, setValidated] = useState(false);

  const handleShow = () => {
    setShowModal({ isShowForm: false });
    setFields({
      details: '',
      amount: null,
      category: '',
      asset_type: '',
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() !== false) {
      if (fields?.id === undefined) {
        handlePostRequest();
        setShowModal({ isShowForm: false });
        setFields({
          details: '',
          amount: null,
          category: '',
          asset_type: '',
        });
        return;
      }

      handleUpdateRequest();

      setShowModal({ isShowForm: false });
      setFields({
        details: '',
        amount: null,
        category: '',
        asset_type: '',
      });
      return;
    }
    setValidated(true);
  };

  console.log('has asset id: ', fields?.id);

  return (
    <Modal show={showModal.isShowForm} size='md' className='assets-modal'>
      <Modal.Body>
        <div className='add-room-request'>
          <div className='d-flex header'>
            <p>Add {popupTitle}</p>
            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
          </div>
        </div>

        <FormUI>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='mb-3 width-90 right-auto inline-label'>
                  <Form.Label>Detail type</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Here'
                    required
                    name='details'
                    onChange={handleChange(popupTitle)}
                    value={fields?.details}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='mb-3 width-90 right-auto inline-label'>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    required
                    type='number'
                    name='amount'
                    value={fields?.amount}
                    placeholder='Enter Here'
                    onChange={handleChange(popupTitle)}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='ms-4'>
                  <Form.Check
                    type='radio'
                    label='Current Assets'
                    name='category'
                    value='Current Assets'
                    required
                    checked={fields?.category === 'Current Assets'}
                    onChange={handleChange(popupTitle)}
                  />
                </Form.Group>
              </Col>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='mb-4'>
                  <Form.Check
                    type='radio'
                    label='Non - Current Assets'
                    name='category'
                    value='Non - Current Assets'
                    required
                    onChange={handleChange(popupTitle)}
                    checked={fields?.category === 'Non - Current Assets'}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <hr />
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mb-4'>
                <Form.Group className='me-2'>
                  <Button className='btn btn-save' type='submit'>
                    Save
                  </Button>
                </Form.Group>
                <Form.Group className='ms-2'>
                  <Button className='btn btn-cancel' type='button' onClick={handleShow}>
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAsset;
