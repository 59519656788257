/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal, Form, FormControl, Accordion, Table } from 'react-bootstrap';

// Components
import TableUI from 'components/UI/TableUI';
import TablePagination from 'components/TablePagination/Pagination';

// Assets
import './style.scss';
import FormUI from 'components/UI/FormUI';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const AddTemplateModal = ({ showModal, setShowModal }) => {
  const [value, setValue] = useState('');

  const onAddNewItem = () => {
    console.log('onAddNewItem', value);
  };

  return (
    <Modal
      size='lg'
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby='Add Template'
      className='add-template-modal'
    >
      <Modal.Body>
        <div className='add-template-request'>
          <div className='d-flex header'>
            <p>Add Template</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='add-template-form'>
            <Row>
              <Col>
                <div className='search-item'>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Template Name</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Template Name'
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className='buttons-cont'>
                      <Button className='add-button' variant='search'>
                        ADD COLUMN
                      </Button>
                      <Button className='add-text' variant='search'>
                        ADD TEXT
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 footer-buttons-cont'>
                  <Button className='cancel-button'>Cancel</Button>

                  <Button className='add-button' variant='primary' onClick={onAddNewItem}>
                    Add New Item
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

AddTemplateModal.defaultProps = {
  showModal: {},
  setShowModal: () => {},
};

AddTemplateModal.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default AddTemplateModal;
