/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal, Accordion, Table } from 'react-bootstrap';

import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import TablePagination from './TablePagination';
import { selectInventory } from 'reducers/inventoryReducer';
import ConditionalRender from 'components/ConditionalRender';
import {
  selectReturn,
  resetLogState,
  submitReturnItems,
  getDepartmentInventory,
} from 'reducers/returnReducer';

// Import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as CirlePlusSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CirleMinusSVG } from 'assets/svg/circle-minus.svg';

const ReturnModal = ({ showModal, setShowModal, departmentID, getDepartment }) => {
  // Redux
  const dispatch = useDispatch();
  const returnState = useSelector(selectReturn);
  const {
    data: itemData,
    meta: itemMeta,
    items: itemIncluded,
    logs,
    submitReturnLogs,
  } = returnState;

  const groupState = useSelector(selectInventory);
  const { inventoryGroupData, inventoryGroupItemsLogs } = groupState;

  // States
  const [search, setSearch] = useState({
    code: '',
    itemGroup: '',
  });
  const [canAdd, setCanAdd] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [finalItem, setFinalItem] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [selectedCheck, setSelectedCheck] = useState({});
  const [showResultsNumber, setShowResultsNumber] = useState(5);

  // Api call
  const getInventoryItems = (clear = false) => {
    let params;

    if (clear) {
      params = {
        id: departmentID,
        payload: {
          q: '',
          is_new: false,
          include: ['item'],
          per_page: showResultsNumber,
        },
      };

      setSearch({
        code: '',
        itemGroup: '',
      });
    } else {
      params = {
        id: departmentID,
        payload: {
          is_new: false,
          q: search?.code,
          include: ['item'],
          group_id: search?.itemGroup,
          per_page: showResultsNumber,
        },
      };
    }

    dispatch(getDepartmentInventory(params));
  };

  // Change page based on selected arrow
  const changePage = (page, showResult = showResultsNumber) => {
    const params = {
      id: departmentID,
      payload: {
        is_new: false,
        q: search?.code,
        include: ['item'],
        group_id: search?.itemGroup,
        per_page: showResultsNumber,
        page: page,
      },
    };
    dispatch(getDepartmentInventory(params));
  };

  // Get group name from included api
  const getGroupName = (id) => {
    const selectedArray = itemIncluded.filter((data) => data.id === id);

    if (selectedArray.length === 0) {
      return 'N/A';
    }

    return selectedArray[0]?.attributes?.name;
  };

  // Change input and checkbox change
  const handleDataChange = (key, type) => (e) => {
    if (type === 'search') {
      setSearch({
        ...search,
        [key]: e.target.value,
      });
    }

    if (type === 'check') {
      setSelectedCheck({
        ...selectedCheck,
        [key?.id]: !selectedCheck[key?.id],
      });

      checkIfCanAdd({
        ...selectedCheck,
        [key?.id]: !selectedCheck[key?.id],
      });
    }
  };

  // Function to check/uncheck all items
  const toggleCheckAll = (e) => {
    let object = {};

    itemData.forEach((data) => {
      object[data?.id] = e.target.checked;
    });

    setCheckAll(e.target.checked);
    setSelectedCheck(object);
    checkIfCanAdd(object);
  };

  // Add Item to final list
  const addItem = (data) => {
    const toAdd = {
      data: data,
      id: data.id,
      quantity: 0,
      remarks: '',
      unit: data?.attributes?.unit_of_measure,
    };

    let duplicate = false;
    finalItem.forEach((finalData) => {
      if (finalData?.id === data.id) {
        duplicate = true;
      }
    });

    if (duplicate) {
      toast.warning(`Duplicate Item (${data?.attributes?.name})`);
    } else {
      setFinalItem((prevState) => {
        return [toAdd].concat(prevState);
      });
    }

    setSelectedCheck((prevState) => {
      return { ...prevState, [data?.id]: false };
    });
  };

  // Delete Item from final
  const deleteItem = (data) => {
    const newData = finalItem.filter((mapData) => mapData?.id !== data?.id);

    setFinalItem(newData);
  };

  // Add item using the checkbox
  const addSelectedItems = () => {
    itemData.forEach((data) => {
      if (selectedCheck[data?.id] === true) {
        addItem(data);
      }
    });

    setCheckAll(false);
    setCanAdd(true);
  };

  // Check whether you can add checked items
  const checkIfCanAdd = (checkData) => {
    let condition = true;

    itemData.forEach((data) => {
      if (checkData[data?.id]) {
        condition = false;
        return;
      }
    });

    setCanAdd(condition);
    return;
  };

  // Change quantity based on selected final item
  const handleChange = (e, data) => {
    const { value, name } = e.target;

    const newData = finalItem.map((mapData) => {
      if (mapData.id === data?.id) {
        return { ...data, [name]: value };
      }

      return mapData;
    });

    setFinalItem(newData);
  };

  // Handle POST api
  const handleSubmit = () => {
    let error = false;

    finalItem.forEach((data) => {
      if (data?.quantity === 0 || data?.quantity === '' || data?.quantity < 0) error = true;
      if (data?.unit === '') error = true;
      if (data?.remarks === '') error = true;
    });

    if (error) {
      toast.warning('Please fill in the empty fields.');
      return;
    }

    const params = {
      department_id: departmentID,
      items: finalItem.map((data) => {
        return {
          item_id: data?.id,
          quantity: data?.quantity,
          unit_of_measure: data?.unit,
          remarks: data?.remarks,
        };
      }),
    };

    dispatch(submitReturnItems(params));
  };

  const closeModal = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        returnItem: false,
      };
    });
  };

  // useEffects
  firstRenderData(getInventoryItems);

  setCheckbox(itemData, setSelectedCheck);

  setDataToGroupList(inventoryGroupItemsLogs, inventoryGroupData, setGroupList);

  getSubmitDataResult(setFinalItem, closeModal, submitReturnLogs, dispatch, getDepartment);

  // Conditions
  const haveItems = itemData && itemData.length > 0;
  // const isLoading = logs.isLoading === true;

  return (
    <Modal
      size='lg'
      show={showModal?.returnItem}
      onHide={closeModal}
      aria-labelledby='Request Item Modal'
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>RETURN ITEM TO INVENTORY</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={closeModal} />
          </div>
        </div>

        <FormUI>
          <Form className='add-item-form'>
            <Row>
              <Col>
                <div className='search-item'>
                  <Accordion className='advanced-search' defaultActiveKey={'0'}>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Search Item</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col sm={9}>
                            <Row>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Item Code/Name</Form.Label>
                                  <FormControl
                                    type='text'
                                    value={search?.code}
                                    onChange={(e) => {
                                      handleDataChange('code', 'search')(e);

                                      if (e.target.value === '') {
                                        getInventoryItems(true);
                                      }
                                    }}
                                    className='bg-white'
                                    placeholder='Search Here'
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Item Group</Form.Label>
                                  <Form.Select
                                    value={search?.itemGroup}
                                    onChange={handleDataChange('itemGroup', 'search')}
                                  >
                                    {groupList.map((data) => {
                                      return (
                                        <option key={data?.id} value={data?.id}>
                                          {data?.attributes?.name}
                                        </option>
                                      );
                                    })}
                                    <option value='' hidden>
                                      -Select-
                                    </option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col>
                                <div className='buttonWrapper'>
                                  <Button
                                    variant='search'
                                    onClick={() => {
                                      getInventoryItems();
                                    }}
                                  >
                                    GO
                                  </Button>

                                  <Button
                                    variant='secondary'
                                    onClick={() => {
                                      getInventoryItems(true);
                                    }}
                                  >
                                    RESET
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={3}>
                            <div className='buttonWrapper add-item'>
                              <Button
                                onClick={addSelectedItems}
                                disabled={canAdd}
                                className={`add-item-button ${!canAdd}`}
                              >
                                Add Selected Item
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div className='search-table mb-4'>
                  <TableUI>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>
                            <Form.Check checked={checkAll} onChange={toggleCheckAll} />
                          </th>
                          <th>Item Code</th>
                          <th>Item Name</th>
                          <th>Item Group</th>
                          <th>Generic Name</th>
                          <th>Manufacturer</th>
                          <th>Storage Bin</th>
                          <th>Section</th>
                          <th>UOM</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ConditionalRender
                          condition={haveItems}
                          renderIf={
                            <>
                              {itemData.map((data, idx) => {
                                const itemID = data?.attributes?.item_id;
                                const itemData = itemIncluded.find(
                                  (dd) => +dd?.id === +itemID && dd?.type === 'items',
                                );

                                return (
                                  <tr key={idx}>
                                    <td>
                                      <Form.Check
                                        type='checkbox'
                                        checked={selectedCheck[data?.id]}
                                        onChangeCapture={(e) => {
                                          handleDataChange(data, 'check')(e);
                                        }}
                                      />
                                    </td>
                                    <td>{itemData?.attributes?.code}</td>
                                    <td>{itemData?.attributes?.name}</td>
                                    <td>{getGroupName(data?.id)}</td>
                                    <td>{itemData?.attributes?.generic_name}</td>
                                    <td>{itemData?.attributes?.manufacturer}</td>
                                    <td>{itemData?.attributes?.storage_bin}</td>
                                    <td>{itemData?.attributes?.section}</td>
                                    <td>{itemData?.attributes?.unit_of_measure}</td>
                                    <td>
                                      <CirlePlusSVG
                                        onClick={() => {
                                          addItem(data);
                                        }}
                                        style={{ fill: '#01B425', width: '2em', cursor: 'pointer' }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          }
                        />
                      </tbody>
                    </Table>
                  </TableUI>

                  <TablePagination
                    meta={itemMeta}
                    changePage={changePage}
                    showResultsNumber={showResultsNumber}
                    setShowResultsNumber={setShowResultsNumber}
                  />
                </div>

                <Accordion className='advanced-search' defaultActiveKey={'0'}>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Items</Accordion.Header>
                    <Accordion.Body>
                      <div className='search-table mb-4'>
                        <TableUI>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Item Code</th>
                                <th>Item Name</th>
                                {/* <th>Item Group</th>
                                <th>Storage Bin</th> */}
                                <th>Quantity</th>
                                <th>UOM</th>
                                <th>Reason</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              <ConditionalRender
                                condition={finalItem.length > 0}
                                renderIf={
                                  <>
                                    {finalItem.map((data, idx) => {
                                      const itemID = data?.data?.attributes?.item_id;
                                      const itemData = itemIncluded.find(
                                        (dd) => +dd?.id === +itemID && dd?.type === 'items',
                                      );

                                      return (
                                        <tr key={idx}>
                                          <td>{itemData?.attributes?.code}</td>
                                          <td>{itemData?.attributes?.name}</td>
                                          {/* <td>{getGroupName(data?.id)}</td>
                                          <td>{itemData?.attributes?.storage_bin}</td> */}
                                          <td>
                                            <FormControl
                                              type='number'
                                              name='quantity'
                                              value={data?.quantity}
                                              onChange={(e) => {
                                                handleChange(e, data);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <Form.Select
                                              name='unit'
                                              value={data?.unit}
                                              onChange={(e) => {
                                                handleChange(e, data);
                                              }}
                                            >
                                              <option value='' hidden>
                                                Select Unit
                                              </option>
                                              <option value='piece'>Piece</option>
                                              <option value='box'>Box</option>
                                            </Form.Select>
                                          </td>
                                          <td>
                                            <FormControl
                                              name='remarks'
                                              placeholder='...'
                                              value={data?.remarks}
                                              onChange={(e) => {
                                                handleChange(e, data);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <CirleMinusSVG
                                              onClick={() => {
                                                deleteItem(data);
                                              }}
                                              style={{
                                                fill: '#01B425',
                                                width: '2em',
                                                cursor: 'pointer',
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                }
                              />
                            </tbody>
                          </Table>
                        </TableUI>
                        {/* 
                  <TablePagination
                    meta={itemMeta}
                    changePage={changePage}
                    showResultsNumber={showResultsNumber}
                    setShowResultsNumber={setShowResultsNumber}
                  /> */}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    // disabled={isLoading}
                    onClick={handleSubmit}
                    className='add-button'
                    variant='primary'
                  >
                    Create
                  </Button>

                  <Button
                    onClick={() => {
                      setFinalItem([]);
                    }}
                    className='clear-button'
                    variant='secondary'
                  >
                    Clear
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const setCheckbox = (itemData, setSelectedCheck) => {
  useEffect(() => {
    let object = {};

    itemData.forEach((data) => {
      object[data?.id] = false;
    });

    setSelectedCheck(object);
  }, [itemData]);
};

const firstRenderData = (getInventoryItems) => {
  useEffect(() => {
    getInventoryItems();
  }, []);
};

const setDataToGroupList = (inventoryGroupItemsLogs, inventoryGroupData, setGroupList) => {
  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = inventoryGroupItemsLogs;

    if (isSuccess) {
      setGroupList(inventoryGroupData);
    }
  }, [inventoryGroupItemsLogs]);
};

const getSubmitDataResult = (
  setFinalItem,
  closeModal,
  submitReturnLogs,
  dispatch,
  getDepartment,
) => {
  useEffect(() => {
    const { isLoading, isFailed, isSuccess, error } = submitReturnLogs;

    if (isFailed) {
      dispatch(resetLogState());
      toast.error(`${error[0]?.detail} (${error[0]?.code})`);
    }

    if (isSuccess) {
      closeModal();
      getDepartment();
      setFinalItem([]);
      dispatch(resetLogState());
      toast.success('Successfully return request item!');
    }
  }, [submitReturnLogs]);
};

export default ReturnModal;
