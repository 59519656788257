/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import LabResult from './LabResult';
import Assessment from './Assessment';
import TabsUI from 'components/UI/TabsUI';
import PatientWorklist from './PatientWorklist';
import PrescribeMedication from './PrescribeMedication';
import { TableFiltersPermission } from 'helpers/filteredPermissions';

// main component
const SearchPatientTabs = () => {
  const [activeTab, setActiveTab] = useState('Patient Worklist');

  const handleTabOnChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  const tabs = [
    {
      id: 1,
      title: 'Patient Worklist',
      component: PatientWorklist,
    },
    // {
    //   id: 2,
    //   title: 'Assessment',
    //   component: Assessment,
    // },
    {
      id: 3,
      title: 'Lab Result',
      component: LabResult,
      permission: 'Can View Patient Lab Result',
    },
  ];

  return (
    <div className='SearchPatientTabs'>
      <TabsUI>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey='Patient Worklist'
          onSelect={(eventKey) => handleTabOnChange(eventKey)}
        >
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component />
            </Tab>
          ))}

          {/* <Tab eventKey='Prescribe Medication' title='Prescribe Medication'>
            {activeTab === 'Prescribe Medication' && <PrescribeMedication />}
          </Tab> */}
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default SearchPatientTabs;
