/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getProcedures = createAsyncThunk(
  'notification/getProcedures',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `notification`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
