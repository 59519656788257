// import components below
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const CreateNewPatientHeader = () => {
  return (
    <div className='createNewPatientHeader'>
      <PageHeaderUI>
        <h4>Outpatient Department Module</h4>
        <p>Dashboard / Outpatient Department / Create New Patient</p>
      </PageHeaderUI>
    </div>
  );
};

export default CreateNewPatientHeader;
