/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Form, FormControl, Accordion, Table } from 'react-bootstrap';

// import components below
import { overtimeStatus } from 'helpers';

// import assets below
import './index.scss';
import { ReactComponent as DownloadSVG } from 'assets/svg/download.svg';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const OvertimeListModal = ({ openModal, setOpenModal, modalData }) => {
  const closeModal = () => {
    setOpenModal({
      isOpen: false,
    });
  }; 

  const [data, setData] = useState([]);

  useEffect(() => {
    if (modalData) {
      setData(modalData?.data?.details);
    }
  }, [modalData]);

  const Stat = () => {
    if (modalData?.status === 'FOR APPROVAL') {
      return (
        <h6>Approve Overtime Submitted by: {modalData?.employee_name} 
          <div className='float-end'> 
            <Button className='bg-success'>Approve</Button> 
            <Button className='bg-danger'>Reject</Button>
          </div>
        </h6>
      );
    } else {
      return (
        <h6>Approve Overtime Submitted by: {modalData?.employee_name} 
          <div className='float-end'>{overtimeStatus(modalData?.status)}
          </div>
        </h6>
      );
    }
  };

  return (
    <Modal
      size='lg'
      centered
      onHide={closeModal}
      show={openModal?.isOpen}
      className='overtime-list'
    >

    <Modal.Header>
      <h5>Details</h5>
      <Button onClick={closeModal}>
        <CloseSVG />
      </Button>
    </Modal.Header>

      <Modal.Body>
        <div className='main-content'>

          <Accordion defaultActiveKey='0' className='mb-3 advanced-search' alwaysOpen>
            <Accordion.Header>  
              <div className='text-white'>Uploaded Overtime Slip</div>
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group>
                <a className='link' href='#'>
                  {modalData?.slip}
                  <DownloadSVG className='float-end' />
                </a>
              </Form.Group>
              </Accordion.Body>
          </Accordion>

          <Accordion defaultActiveKey='0' className='mb-3 advanced-search' alwaysOpen>
            <Accordion.Header>  
              <div className='text-white'>Task Finished</div>
            </Accordion.Header>
            <Accordion.Body>
              <h6>{modalData?.tasks}</h6>
            </Accordion.Body>
          </Accordion>

          <Accordion defaultActiveKey='0' className='mb-3 advanced-search' alwaysOpen>
            <Accordion.Header>  
              <div className='text-white'>Overtime Hours Rendered</div>
            </Accordion.Header>
            <Accordion.Body>
              <Stat />
            </Accordion.Body>
          </Accordion>

        </div>
      </Modal.Body>
      
      <Modal.Footer>
        <Button onClick={closeModal} className='save'>Save</Button>
        <Button onClick={closeModal} className='cancel'>Cancel</Button>
      </Modal.Footer>

    </Modal>
  );
};

export default OvertimeListModal;
