const Constants = {
  GENERALINFORMATION: 'general',
  HOSPITALOPERATION: 'hospital',
  STAFFINGPATTERNS: 'staffing',
  EXPENSES: 'expenses',
  REVENUES: 'revenues',
  CENSUS: 'census',
};

export default Constants;
