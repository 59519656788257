/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
import { Col, Modal, Row, Form, Button } from 'react-bootstrap';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

import FormUI from 'components/UI/FormUI';
import { useState } from 'react';
import { dischargeBilling } from 'reducers/thunks/billingThunks';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';

const DischargeModal = ({ setShowModal, showModal }) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        // eslint-disable-next-line camelcase
        password: '',
    });

    const handleShow = () => setShowModal({ isDischarge: false });

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        let payload = {};

        if (!form.checkValidity() === false) {
            payload = { ...formData };

            axios.post(`/user/auth/check`, { password: payload['password'] })
                .then((response) => {
                    dispatch(dischargeBilling({ visit_number: showModal?.visitNumber }));
                    toast.success('Successfully discharge patient');
                    setShowModal({ isDischarge: false });

                    setTimeout(() => {
                        window.location.reload(false);
                    }, 1500);
                })
                .catch((error) => {
                    toast.error('Invalid Password');
                });

        }

        setValidated(true);
    };

    const handleChange = (key) => (e) => {
        setFormData({
            ...formData,
            [key]: e.target.value,
        });
    };
    return (
        <Modal show={showModal.isDischarge} size='lg' className='partial-modal'>
            <Modal.Body>
                <div className='partial-header'>
                    <div className='d-flex header'>
                        <p>CONFIRM SYSTEM DISCHARGE</p>
                        <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
                    </div>
                </div>
                <div className='information'>
                    <FormUI>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col><h1 className='d-flex justify-content-center'>WARNING!</h1></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col><h4 className='d-flex justify-content-center'>You are about to financially discharge this patient form the system</h4></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col><h3 className='d-flex justify-content-center'>Please Enter your password to proceed</h3></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='d-flex justify-content-center'>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control
                                                    required
                                                    type='password'
                                                    name='password'
                                                    onChange={handleChange('password')}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Group className='d-flex justify-content-center'>
                                                <Button className='me-3 btn btn-save' type='submit'>
                                                    Discharge
                                                </Button>
                                            </Form.Group></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </FormUI>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DischargeModal;