/* eslint-disable */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */

// import core & vendor packages below
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import { createAppointment, setActiveTab } from 'reducers/appointmentReducer';

// import assets below
import './FormCreate.scss';

// main component
const FormCreate = () => {
  const [payload, setPayload] = useState({});
  const [patientId, setPatientId] = useState('');
  const patientItem = useSelector(selectPatientItem);
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState([]);

  const handleChange = (key) => (e) => {
    if (e.target.type === 'file') {
      setPayload({
        ...payload,
        [key]: e.target.files,
      });
      return;
    }

    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        setPayload({
          ...payload,
          [key]: e.target.value,
        });
      } else {
        setPayload({
          ...payload,
          [key]: '',
        });
      }

      return;
    }

    setPayload({
      ...payload,
      [key]: e.target.value,
    });

    if (key === 'patient_id') {
      dispatch(showPatient(payload.patient_id === '' ? 0 : payload.patient_id));
    }

    if (key === 'appointment_date') {
      setPayload({
        ...payload,
        appointment_date: new Date(e.target.value)
          .toLocaleString('en-GB')
          .format('YYYY-MM-DD H:m:ss'),
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!id || !patientText || !payload?.department) {
      toast.warning('Please fill in the empty blanks');
      return;
    }

    resetFilter();

    setPayload({
      ...payload,
      appointment_status: 'ACCEPTED',
    });
    payload.patientId = patientId;

    dispatch(createAppointment(payload));

    setTimeout(() => {
      dispatch(setActiveTab('Appointment List'));
    }, 2000);
  };

  function changeDepartment(e) {
    setPayload({ ...payload, department: e.target.value });
  }

  /** doctors autocomplete */
  const [users, setUsers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState('');

  // useEffect(() => {
  //   if (users) {
  //     setSuggestions(users.map((value) => ({ ...value, label: value.full_name, value: value.id })))
  //   }
  // }, [users])

  const searchDoctorHandler = (text) => {
    const newFilter = users.filter((value) => {
      return value.full_name.toLowerCase().includes(text.toLowerCase());
    });
    setText(text);
    if (text.length > 0) {
      setSuggestions(newFilter);
    } else {
      setSuggestions([]);
    }
  };

  const onClickSuggestion = (text) => {
    setPayload({
      ...payload,
      name_of_doctor: text.target.innerHTML,
    });
    setText(text.target.innerHTML);
    setSuggestions([]);
  };

  /** patient name autocomplete */
  const [patients, setPatients] = useState([]);
  const [patientSuggestions, setPatientSuggestions] = useState([]);
  const [patientText, setPatientText] = useState('');
  const [id, setId] = useState('');

  const searchPatientHandler = (text) => {
    /**
    if (!isNaN(text)) {
      setPatientId(text);
      setId(patientItem.id);
      setName(patientItem.full_name);
    } else {
      setId('696969');
      setName('m,y name');
    }
     */

    const patientFilterFilter = patients.filter((value) => {
      return (
        value.full_name.toLowerCase().includes(text.toLowerCase()) ||
        value.id === Number(text) ||
        String(value.id) === String(text)
      );
    });
    setPatientText(text);
    if (text.length > 0) {
      setPatientSuggestions(patientFilterFilter);
    } else {
      setId('');
      setPatientText('');
      setPatientSuggestions([]);
    }
  };

  const onClickPatientSuggestion = (text, name, id) => {
    setPatientText(text.target.innerHTML);
    setId(id);
    setPatientId(id);
    setPatientSuggestions([]);
  };

  const resetFilter = () => {
    setPayload({ ...payload, department: '' });
    setPatientSuggestions('');
    setId('');
    setText('');
    setPatientText('');
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(showPatient(patientId !== '' ? patientId : 0));
    }, 500);

    axios
      .get('/public/department')
      .then((response) => {
        setDepartments(response.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    /** Get Patients for auto complete suggestion */
    axios
      .get('/patient')
      .then((response) => {
        const options = response.data?.data?.map((data) => ({
          id: data?.id,
          value: data?.full_name,
          label: data?.full_name,
          MRN: data?.MRN,
        }));
        setPatients(options);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    return () => clearTimeout(delayDebounceFn);
  }, [patientId]);

  useEffect(() => {
    /** Get Doctors for auto complete suggestion */
    axios({
      method: 'get',
      url: `api/users?roles[]=Doctor`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        const options = response.data?.data?.map((data) => ({
          id: data?.id,
          value: data?.attributes.full_name,
          label: data?.attributes.full_name,
        }));
        setUsers(options);
      })
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    /** Get Doctors for auto complete suggestion */
    // axios
    //   .get('acl/api/users?roles[]=Doctor')
    //   .then((response) => {
    //     const options = response.data?.data?.map((data) => ({
    //       id: data?.id,
    //       value: data?.attributes.full_name,
    //       label: data?.attributes.full_name,
    //     }));
    //     setUsers(options);
    //   }, [])
    //   .catch((err) => {
    //     console.log('AXIOS ERROR: ', err);
    //   });
  }, []);

  // Doctors AsyncSelect
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      padding: '0.45em',
      color: '#404040',
      borderColor: '#c8c8c8',
      borderRadius: '10px',
      width: '100%',
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: '100',
    }),
  };

  const loadDoctors = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterDoctors(input));
      }, 1000);
    });
  };

  const filterDoctors = (input) => {
    return users?.filter((i) => {
      return (
        i.value.toLowerCase().includes(input.toLowerCase()) ||
        String(i.MRN) === String(input) ||
        String(i.id) === String(input)
      );
    });
  };

  const handleDoctor = (full_name) => {
    setPayload({
      ...payload,
      name_of_doctor: full_name,
    });
  };

  //Patient AsynSelect
  const loadPatients = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterPatients(input));
      }, 1000);
    });
  };

  const filterPatients = (input) => {
    return patients?.filter((i) => {
      return (
        i.value.toLowerCase().includes(input.toLowerCase()) ||
        String(i.MRN) === String(input) ||
        String(i.id) === String(input)
      );
    });
  };

  const handlePatient = (id, full_name) => {
    setId(id);
    setPatientId(id);
    setPatientText(full_name);
  };

  return (
    <div className='formCreateAppointment'>
      <FormUI>
        <div id='formAppointment__header'>
          <h4>Create New Appointment (Pre-Registration)</h4>
          <hr></hr>
        </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col lg='5'>
              <Form.Group className='mb-3'>
                <Form.Label>Search Patient</Form.Label>
                <div style={{ width: '100%', margin: '0 auto' }}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={patients}
                    // value={diagnosis}
                    loadOptions={loadPatients}
                    onChange={(e) => handlePatient(e.id, e.value)}
                    placeholder='Search Patient'
                    styles={selectStyles}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Patient ID</Form.Label>
                <Form.Control type='text' disabled={true} value={id} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Patient Name</Form.Label>
                <Form.Control type='text' disabled={true} value={patientText} />
              </Form.Group>
            </Col>
          </Row>

          <p className='fw-bold mb-4 mt-3'>
            Please fill out fields marked with <span className='text-danger'>*</span>
          </p>

          <Row>
            {/* <Col lg='5'>
              <Form.Group className='mb-3'>
                <Form.Label>Doctor / Physician Name</Form.Label>
                <div style={{ width: '100%', margin: '0 auto' }}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    // value={diagnosis}
                    loadOptions={loadDoctors}
                    onChange={(e) => handleDoctor(e.value)}
                    placeholder='Search Doctor'
                    styles={selectStyles}
                  />
                </div>
              </Form.Group>
            </Col> */}
            <Col lg='4'>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Clinic <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Select onChange={changeDepartment}>
                  <option hidden value=''>
                    -Select-
                  </option>
                  <option value='all'>-Select-</option>
                  <option value='ent'>ENT</option>
                  <option value='medicine'>Medicine</option>
                  <option value='ob_gyne'>OB-GYNE</option>
                  <option value='urology'>Urology</option>
                  <option value='cardiology'>Cardiology</option>
                  <option value='pulmonary Medicine'>Pulmonary Medicine</option>
                  <option value='opthalmology'>Ophthalmology</option>
                  <option value='surgery'>Surgery</option>
                  <option value='orthopedic Surgery'>Orthopedic Surgery</option>
                  <option value='endocrinology'>Endocrinology</option>
                  <option value='pediatrics'>Pediatrics</option>
                  <option value='dermatology'>Dermatology</option>
                  <option value='oncology'>Oncology</option>
                  <option value='neurology'>Neurology</option>
                  <option value='gastroenterology'>Gastroenterology</option>
                  <option value='internal_medicine'>Internal Medicine</option>
                  {/* <option value='medico_legal'>Medico Legal</option> */}
                  <option value='nephrology'>Nephrology</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className='d-flex justify-content-end'>
            <Col md={4}>
              <Button variant='search' type='submit' className='w-100'>
                Create Appointment
              </Button>
            </Col>
          </Row>
        </Form>
      </FormUI>
    </div>
  );
};

export default FormCreate;
