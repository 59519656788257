/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import { getDepreciationList, selectFixedAssets } from 'reducers/fixedAssetsReducer';
import { useDispatch, useSelector } from 'react-redux';
// import assets below
import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

// main comnponent

const index = ({ setShowScreen, setRequestData, setFormData }) => {
  const dispatch = useDispatch();

  const fixedAssetsState = useSelector(selectFixedAssets);
  const { depreciationData, depreciationItem, depreciationLogs } = fixedAssetsState;

  const [tableList, setTableList] = useState([]);
  const tableColumns = React.useMemo(
    () => [
      { Header: 'Depreciate to Date', accessor: 'depreciation_date' },
    ],
  );

  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({
        data: dd,
        id: dd?.id,
        class: dd?.class,
        block: dd?.block,
        number: dd?.number,
        asset_type: dd?.asset_type,
        depreciation_date: moment(dd?.depreciation_date).format('MM/DD/YYYY'),
      })),
    [tableList],
  );

  const handleViewDetails = (tableData) => {
    console.log(tableData);
    const { data } = tableData;

    setFormData((prev) => {
      return {
        ...prev,
        id: data?.id,
        class: data?.class,
        block: data?.block,
        number: data?.number,
        assetType: data?.asset_type,
        depreciationDate: moment(data?.depreciation_date).format('MM/DD/YYYY'),
      };
    });

    setShowScreen(7.5);
    setRequestData(data);
  };

  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, depreciationData, depreciationLogs);

  return (
    <div className='credentials-credits'>
      <div className='header'>

        <div className='functions'>
          <Button className='upload' onClick={() => setShowScreen(7)}>
            <AddSVG />
            Add Record
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <span
                className='badge yellow'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // setRequestData(data);
                  
                  // setShowScreen(7.5);
                  handleViewDetails(data);
                }
                }
              >
                View Details
              </span>
            );
          }}
        />
      </div>

    </div>
  );
};
const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getDepreciationList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, depreciationData, depreciationLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = depreciationLogs;

    if (isSuccess) {
      const newData = depreciationData.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          class: dd?.class,
          block: dd?.block,
          number: dd?.number,
          asset_type: dd?.asset_type,
          depreciation_date: dd?.depreciation_date,

        };
      });
      console.log(newData);
      setTableList(newData);
    }
  }, [depreciationLogs]);
};

export default index;
