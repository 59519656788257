/* eslint-disable */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Form, Pagination } from 'react-bootstrap';

// import components below
import PaginationUI from 'components/UI/PaginationUI';

// import assets below
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/angle-left.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/svg/angle-right.svg';

// main component
const TablePagination = ({
  meta,
  links,
  changePage,
  showResultsNumber,
  setShowResultsNumber,
}) => {
  const total = meta?.pagination?.total;
  const totalPages = meta?.pagination?.total_pages
  const currentPage = meta?.pagination?.current_page;
  const canPreviousPage = meta?.pagination?.current_page !== 1;
  const canNextPage = meta?.pagination?.current_page !== meta?.pagination?.total_pages;

  const gotoPage = (pageCount) => {
    changePage(pageCount);
  };

  const nextPage = () => {
    changePage(currentPage + 1);
  };
  const previousPage = () => {
    changePage(currentPage - 1);
  };

  return (
    <div className='manda-pagination'>
      <PaginationUI>
        <span className='summary'>
          {total} Result{!!total && 's'}
        </span>
        <div className='selectWrapper'>
          <Form.Label>Show: </Form.Label>
          <Form.Select
            value={showResultsNumber}
            onChange={(e) => {
              setShowResultsNumber(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select>
        </div>
        <Pagination>
          <Pagination.First
            onClick={() => gotoPage(0)}
            className={`${!canPreviousPage ? 'background-grey' : ''}`}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </Pagination.First>
          <Pagination.Prev
            onClick={() => previousPage()}
            className={`${!canPreviousPage ? 'background-grey' : ''}`}
            disabled={!canPreviousPage}
          >
            <ArrowLeftSVG />
          </Pagination.Prev>

          <Pagination.Item className='active'>{currentPage}</Pagination.Item>

          <Pagination.Next
            onClick={() => nextPage()}
            className={`${!canNextPage ? 'background-grey' : ''}`}
            disabled={!canNextPage}
          >
            <ArrowRightSVG />
          </Pagination.Next>
          <Pagination.Last
            onClick={() => gotoPage(totalPages)}
            className={`${!canNextPage ? 'background-grey' : ''}`}
            disabled={!canNextPage}
          >
            {'>>'}
          </Pagination.Last>
        </Pagination>
      </PaginationUI>
    </div>
  );
};

TablePagination.defaultProps = {
  meta: {},
  links: {},
  type: '',
  showResultsNumber: 10,
  setShowResultsNumber: () => {},
};

TablePagination.propTypes = {
  meta: PropTypes.object,
  links: PropTypes.object,
  type: PropTypes.string,
  showResultsNumber: PropTypes.number,
  setShowResultsNumber: PropTypes.func,
};

export default TablePagination;
