/* eslint-disable */
// import core & vendor packages below
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Nav, Accordion, Button } from 'react-bootstrap';

// import components
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import { getACLState } from 'reducers/aclReducer';
import Logo from 'assets/images/new-mcmc-logo.png';
import { ReactComponent as UnionSVG } from 'assets/svg/union.svg';
import { ReactComponent as NurseSVG } from 'assets/svg/nurse.svg';
import { ReactComponent as BillingSVG } from 'assets/svg/bill.svg';
import { ReactComponent as DoctorSVG } from 'assets/svg/doctor.svg';
import { ReactComponent as BackSVG } from 'assets/svg/back-arrow.svg';
import { ReactComponent as ACLSvg } from 'assets/svg/ACL Icon.svg';
import { ReactComponent as ReportsSVG } from 'assets/svg/reports.svg';
import { ReactComponent as PharmacySVG } from 'assets/svg/pharmacy.svg';
import { ReactComponent as EmployeeSVG } from 'assets/svg/employee.svg';
import { ReactComponent as DashboardSVG } from 'assets/svg/Union 2.svg';
import { ReactComponent as XmarkSVG } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as AccountingSVG } from 'assets/svg/document.svg';
import { ReactComponent as EmployeesSVG } from 'assets/svg/employees.svg';
import { ReactComponent as InventorySVG } from 'assets/svg/inventory.svg';
import { ReactComponent as LaboratorySVG } from 'assets/svg/laboratory.svg';
import { ReactComponent as SocialServicesSVG } from 'assets/svg/healthcare.svg';
import { ReactComponent as PhilHealthSVG } from 'assets/svg/driving_license.svg';
import { ReactComponent as EmergencyDepartmentSVG } from 'assets/svg/ambulance.svg';
import { ReactComponent as RoomBedManagementSVG } from 'assets/svg/room_reservation.svg';
import { ReactComponent as OutpatientDepartmentSVG } from 'assets/svg/outpatient_clinic.svg';
import { ReactComponent as AttendanceSVG } from 'assets/svg/calendar-check.svg';
import { ReactComponent as LeaveSVG } from 'assets/svg/Leave.svg';
import { ReactComponent as AdvanceSVG } from 'assets/svg/advances logo.svg';
import { ReactComponent as PayrollSVG } from 'assets/svg/payroll.svg';
import { ReactComponent as LoansSVG } from 'assets/svg/loans.svg';
import { ReactComponent as ChargesSVG } from 'assets/svg/charges.svg';
import { ReactComponent as ActivitiesSVG } from 'assets/svg/graph.svg';
import { ReactComponent as CredentialsManagementSVG } from 'assets/svg/documents-folder.svg';
import { ReactComponent as OvertimeTrackingSVG } from 'assets/svg/working.svg';
import { ReactComponent as FixedAssetSVG } from 'assets/svg/asset.svg';

import './Sidebar.scss';

// main component
const Sidebar = ({ setSidebarToggle }) => {
  const { pathname, search } = useLocation();
  const splitSearch = search.split('?activeKey=').pop();
  const paramsArray = splitSearch.split('&id=');
  const activeKey = paramsArray[0];

  const admissionRoutes = [
    '/dashboard/admissions',
    '/dashboard/admissions/create-new-patient',
    '/dashboard/admissions/create-temporary-profile',
    '/dashboard/admissions/edit-patient-profile',
    '/dashboard/admissions/create-inpatient-visit',
    '/dashboard/admissions/create-room-request',
    '/dashboard/admissions/patient-profile',
  ];

  const EmergencyRoutes = [
    '/dashboard/emergency-department',
    '/dashboard/emergency-department/create-new-patient',
  ];

  const OutpatientRoutes = [
    '/dashboard/outpatient-department',
    '/dashboard/outpatient-department/create-new-patient',
    '/dashboard/outpatient-department/create-temporary-profile',
    '/dashboard/outpatient-department/edit-patient-profile',
  ];

  const HRRoutes = ['/dashboard/employees'];

  const options1 = {
    eventKey: 'patient-care-management',
    icon: <EmployeeSVG />,
    label: 'Patient Care Management',
    items: [
      {
        icon: <UnionSVG />,
        label: 'Admissions',
        path: '/dashboard/admissions',
        permission: 'Can View Admission Module',
        className: admissionRoutes.includes(pathname) && 'active',
      },
      {
        icon: <DoctorSVG />,
        label: 'Doctors',
        path: '/dashboard/doctors',
        permission: 'Can View Doctors Module',
        className: pathname === '/dashboard/doctors' && 'active',
      },
      {
        icon: <NurseSVG />,
        label: 'Nursing',
        path: '/dashboard/nursing',
        permission: 'Can View Nursing Module',
        className: pathname === '/dashboard/nursing' && 'active',
      },
      {
        icon: <RoomBedManagementSVG />,
        label: 'Room & Bed Management',
        path: '/dashboard/room-bed-management',
        permission: 'Can View Room & Bed Module',
        className: pathname === '/dashboard/room-bed-management' && 'active',
      },
      {
        icon: <EmergencyDepartmentSVG />,
        label: 'Emergency Department',
        path: '/dashboard/emergency-department',
        permission: 'Can View Emergency Department Module',
        className: EmergencyRoutes.includes(pathname) && 'active',
      },
      {
        icon: <OutpatientDepartmentSVG />,
        label: 'Outpatient Department',
        path: '/dashboard/outpatient-department',
        permission: 'Can View Outpatient Department Module',
        className: OutpatientRoutes.includes(pathname) && 'active',
      },
      {
        icon: <LaboratorySVG />,
        label: 'Laboratory',
        path: '/dashboard/laboratory',
        permission: 'Can View Laboratory Module',
        className: pathname === '/dashboard/laboratory' && 'active',
      },
      {
        icon: <BillingSVG />,
        label: 'Billing',
        path: '/dashboard/billing',
        permission: 'Can View Billing Module',
        className: pathname === '/dashboard/billing' && 'active',
      },
    ],
  };

  // ACL
  const storeData = useSelector(getACLState);

  const {
    hrUsers,
    hrNewUsers,
    hospitalUsers,
    propertyUsers,
    hrDeniedUsers,
    accountingUsers,
    patientCareUsers,
    propertyNewUsers,
    hospitalNewUsers,
    accountingNewUsers,
    patientCareNewUsers,
    propertyDeniedUsers,
    hospitalDeniedUsers,
    accountingDeniedUsers,
    patientCareDeniedUsers,
  } = storeData;

  const options2 = [
    {
      eventKey: 'patient-care-management',
      icon: <EmployeeSVG />,
      label: 'Patient Care Management',
      className: 'active',
      items: [
        {
          icon: '',
          label: 'All Users',
          path: '/dashboard/acl/patient-care-management?activeKey=patient-care-management',
          className: pathname === '/dashboard/acl/patient-care-management' && 'active',
          total: patientCareUsers?.meta?.pagination?.total,
        },
        {
          icon: '',
          label: 'New Users',
          path: '/dashboard/acl/patient-care-management/new-users?activeKey=patient-care-management',
          className: pathname === '/dashboard/acl/patient-care-management/new-users' && 'active',
          total: patientCareNewUsers?.meta?.pagination?.total,
        },
        {
          icon: '',
          label: 'Denied Users',
          path: '/dashboard/acl/patient-care-management/denied-users?activeKey=patient-care-management',
          className: pathname === '/dashboard/acl/patient-care-management/denied-users' && 'active',
          total: patientCareDeniedUsers?.meta?.pagination?.total,
        },
      ],
    },
    {
      eventKey: 'property-management',
      icon: <EmployeeSVG />,
      label: 'Property Management',
      items: [
        {
          icon: '',
          label: 'All Users',
          path: '/dashboard/acl/property-management?activeKey=property-management',
          className: pathname === '/dashboard/acl/property-management' && 'active',
          total: propertyUsers?.meta?.pagination?.total,
        },
        {
          icon: '',
          label: 'New Users',
          path: '/dashboard/acl/property-management/new-users?activeKey=property-management',
          className: pathname === '/dashboard/acl/property-management/new-users' && 'active',
          total: propertyNewUsers?.meta?.pagination?.total,
        },
        {
          icon: '',
          label: 'Denied Users',
          path: '/dashboard/acl/property-management/denied-users?activeKey=patient-care-management',
          className: pathname === '/dashboard/acl/property-management/denied-users' && 'active',
          total: propertyDeniedUsers?.meta?.pagination?.total,
        },
      ],
    },
    {
      eventKey: 'accounting-management',
      icon: <EmployeeSVG />,
      label: 'Accounting Management',
      items: [
        {
          icon: '',
          label: 'All Users',
          path: '/dashboard/acl/accounting-management?activeKey=accounting-management',
          className: pathname === '/dashboard/acl/accounting-management' && 'active',
          total: accountingUsers?.meta?.pagination?.total,
        },
        {
          icon: '',
          label: 'New Users',
          path: '/dashboard/acl/accounting-management/new-users?activeKey=accounting-management',
          className: pathname === '/dashboard/acl/accounting-management/new-users' && 'active',
          total: accountingNewUsers?.meta?.pagination?.total,
        },
        {
          icon: '',
          label: 'Denied Users',
          path: '/dashboard/acl/accounting-management/denied-users?activeKey=patient-care-management',
          className: pathname === '/dashboard/acl/accounting-management/denied-users' && 'active',
          total: accountingDeniedUsers?.meta?.pagination?.total,
        },
      ],
    },
    {
      eventKey: 'hr-management',
      icon: <EmployeeSVG />,
      label: 'HR Management',
      items: [
        {
          icon: '',
          label: 'All Users',
          path: '/dashboard/acl/hr-management?activeKey=hr-management',
          className: pathname === '/dashboard/acl/hr-management' && 'active',
          total: hrUsers?.meta?.pagination?.total,
        },
        {
          icon: '',
          label: 'New Users',
          path: '/dashboard/acl/hr-management/new-users?activeKey=hr-management',
          className: pathname === '/dashboard/acl/hr-management/new-users' && 'active',
          total: hrNewUsers?.meta?.pagination?.total,
        },
        {
          icon: '',
          label: 'Denied Users',
          path: '/dashboard/acl/hr-management/denied-users?activeKey=patient-care-management',
          className: pathname === '/dashboard/acl/hr-management/denied-users' && 'active',
          total: hrDeniedUsers?.meta?.pagination?.total,
        },
      ],
    },
    {
      eventKey: 'hospital-management',
      icon: <EmployeeSVG />,
      label: 'Hospital Management',
      items: [
        {
          icon: '',
          label: 'All Users',
          path: '/dashboard/acl/hospital-management?activeKey=hospital-management',
          className: pathname === '/dashboard/acl/hospital-management' && 'active',
          total: hospitalUsers?.meta?.pagination?.total,
        },
        {
          icon: '',
          label: 'New Users',
          path: '/dashboard/acl/hospital-management/new-users?activeKey=hospital-management',
          className: pathname === '/dashboard/acl/hospital-management/new-users' && 'active',
          total: hospitalNewUsers?.meta?.pagination?.total,
        },
        {
          icon: '',
          label: 'Denied Users',
          path: '/dashboard/acl/hospital-management/denied-users?activeKey=patient-care-management',
          className: pathname === '/dashboard/acl/hospital-management/denied-users' && 'active',
          total: hospitalDeniedUsers?.meta?.pagination?.total,
        },
      ],
    },
  ];

  const getPatientCareManagement = () => {
    let haveAtLeastOnePermission = false;
    const permissions = [
      'Can View Admission Module',
      'Can View Doctors Module',
      'Can View Nursing Module',
      'Can View Room & Bed Module',
      'Can View Emergency Department Module',
      'Can View Outpatient Department Module',
      'Can View Laboratory Module',
      'Can View Billing Module',
    ];

    permissions.forEach((data) => {
      const result = checkStringPermission(data);
      if (result) haveAtLeastOnePermission = true;
    });

    return haveAtLeastOnePermission;
  };

  const employeeManagementSystem = () => {
    let haveAtLeastOnePermission = false;
    const permissions = [
      'Can View ACL Module',
      'Can View Employees Module',
      'Can View Accounting Module',
      'Can View Attendance Module',
      'Can View ACL Permission Module',
    ];

    permissions.forEach((data) => {
      const result = checkStringPermission(data);
      if (result) haveAtLeastOnePermission = true;
    });

    return haveAtLeastOnePermission;
  };

  const [showPermissions, setShowPermissions] = useState(true);

  return (
    <div className='sidebar'>
      <div className='sidebar__header'>
        <div className='header-cont'>
          <span className='sidebar__brand'>{process.env.REACT_APP_FULL_NAME}</span>
          <Button
            onClick={() => {
              setSidebarToggle(true);
            }}
            className='hide-sidebar'
          >
            <XmarkSVG />
          </Button>
        </div>

        {/* <img
          className='sidebar__logo w-75'
          src={process.env.REACT_APP_LOGO}
          alt='mandaluyong-his'
        /> */}
      </div>
      <Nav>
        {/* <Nav.Item>
          <Link className='nav-link' to='/dashboard'>
            <BackSVG />
            <span>Dashboard</span>
          </Link>
        </Nav.Item> 
        {/* <ConditionalRender
          condition={getPatientCareManagement() === true}
          renderIf={
            <Accordion defaultActiveKey={[options1.eventKey]} className='dropdown-nav' alwaysOpen>
              <Accordion.Item eventKey={options1.eventKey}>
                <Accordion.Header>
                  <div className='svg__wrapper'>{options1.icon}</div>
                  <Nav.Link className='nav-link' href='#'>
                    {options1.label}
                  </Nav.Link>
                </Accordion.Header>
                <Accordion.Body>
                  {options1.items.map((item, idx) => {
                    const result = checkStringPermission(item?.permission);

                    if (!result) return null;

                    return (
                      <Nav.Item key={`accord1-${idx}`} className={item.className}>
                        <Link className='nav-link' to={item.path}>
                          {item.icon}
                          <span>{item.label}</span>
                        </Link>
                      </Nav.Item>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Inventory Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/inventory' && 'active'}>
              <Link className='nav-link' to='/dashboard/inventory'>
                <InventorySVG />
                <span style={{ fontWeight: 700 }}>Inventory</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        <Nav.Item className={pathname === '/dashboard'}>
          <Link className='nav-link' to='/dashboard'>
            <DashboardSVG />
            <span style={{ fontWeight: 700 }}>Dashboard</span>
          </Link>
        </Nav.Item>

        <ConditionalRender
          condition={checkStringPermission('Can View Laboratory Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/laboratory' && 'active'}>
              <Link className='nav-link' to='/dashboard/laboratory'>
                <LaboratorySVG />
                <span style={{ fontWeight: 700 }}>Laboratory</span>
              </Link>
            </Nav.Item>
          }
        />

        <ConditionalRender
          condition={checkStringPermission('Can View Audit Logs Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/audit-logs' && 'active'}>
              <Link className='nav-link' to='/dashboard/audit-logs'>
                <LaboratorySVG />
                <span style={{ fontWeight: 700 }}>Audit Logs</span>
              </Link>
            </Nav.Item>
          }
        />

        {/* Employee Information Management */}

        <ConditionalRender
          condition={'Can View ACL Module'}
          renderIf={
            <Nav.Item
              className={pathname === '/dashboard/acl' && 'active'}
              onClick={() => setShowPermissions(true)} // Set showPermissions to true when ACL button is clicked>
            >
              <Link className='nav-link' to='/dashboard/acl'>
                <ACLSvg size={32} height={40} width={64} />
                <span style={{ fontWeight: 700 }}>ACL</span>
              </Link>
            </Nav.Item>
          }
        />

        {showPermissions && (
          <ConditionalRender
            condition={'Can View ACL Permission Module'}
            renderIf={
              <Nav.Item className={pathname === '/dashboard/acl-permissions' && 'active'}>
                <Link className='nav-link' to='/dashboard/acl-permissions'>
                  <ACLSvg size={32} height={40} width={64} />
                  <span style={{ fontWeight: 700 }}>Permissions</span>
                </Link>
              </Nav.Item>
            }
          />
        )}

        <ConditionalRender
          condition={checkStringPermission('Can View Medical Records Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/medical-records' && 'active'}>
              <Link className='nav-link' to='/dashboard/medical-records'>
                <ReportsSVG />
                <span style={{ fontWeight: 700 }}>Medical Records</span>
              </Link>
            </Nav.Item>
          }
        />

        <ConditionalRender
          condition={checkStringPermission('Can View Outpatient Department Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/outpatient-department' && 'active'}>
              <Link className='nav-link' to='/dashboard/outpatient-department'>
                <EmployeesSVG />
                <span style={{ fontWeight: 700 }}>Patients</span>
              </Link>
            </Nav.Item>
          }
        />

        {/* <ConditionalRender
          condition={checkStringPermission('Can View PhilHealth Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/philhealth' && 'active'}>
              <Link className='nav-link' to='/dashboard/philhealth'>
                <PhilHealthSVG />
                <span style={{ fontWeight: 700 }}>PhilHealth</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Social Services Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/social-services' && 'active'}>
              <Link className='nav-link' to='/dashboard/social-services'>
                <PhilHealthSVG />
                <span style={{ fontWeight: 700 }}>Social Services</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Reports Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/reports' && 'active'}>
              <Link className='nav-link' to='/dashboard/reports'>
                <ReportsSVG />
                <span style={{ fontWeight: 700 }}>Reports</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={employeeManagementSystem() === true}
          renderIf={<h3 className='header-text'>Employee Information Management</h3>}
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Employees Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/employees/list' && 'active'}>
              <Link className='nav-link' to='/dashboard/employees/list'>
                <EmployeesSVG />
                <span style={{ fontWeight: 700 }}>Employees</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/accounting' && 'active'}>
              <Link className='nav-link' to='/dashboard/accounting'>
                <AccountingSVG />
                <span style={{ fontWeight: 700 }}>Accounting</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/attendance' && 'active'}>
              <Link className='nav-link' to='/dashboard/attendance'>
                <AttendanceSVG />
                <span style={{ fontWeight: 700 }}>Attendance</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/leaves' && 'active'}>
              <Link className='nav-link' to='/dashboard/leaves'>
                <LeaveSVG />
                <span style={{ fontWeight: 700 }}>Leaves</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/advances' && 'active'}>
              <Link className='nav-link' to='/dashboard/advances'>
                <AdvanceSVG />
                <span style={{ fontWeight: 700 }}>Advances</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/payroll' && 'active'}>
              <Link className='nav-link' to='/dashboard/payroll'>
                <PayrollSVG />
                <span style={{ fontWeight: 700 }}>Payroll</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/loans' && 'active'}>
              <Link className='nav-link' to='/dashboard/loans'>
                <LoansSVG />
                <span style={{ fontWeight: 700 }}>Loans</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/charges' && 'active'}>
              <Link className='nav-link' to='/dashboard/charges'>
                <ChargesSVG />
                <span style={{ fontWeight: 700 }}>Charges</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/request' && 'active'}>
              <Link className='nav-link' to='/dashboard/request'>
                <EmployeeSVG />
                <span style={{ fontWeight: 700 }}>Request</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/credentials-management' && 'active'}>
              <Link className='nav-link' to='/dashboard/credentials-management'>
                <CredentialsManagementSVG />
                <span style={{ fontWeight: 700 }}>Credentials Management</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/overtime-tracking' && 'active'}>
              <Link className='nav-link' to='/dashboard/overtime-tracking'>
                <OvertimeTrackingSVG />
                <span style={{ fontWeight: 700 }}>Overtime Tracking</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/activities' && 'active'}>
              <Link className='nav-link' to='/dashboard/activities'>
                <ActivitiesSVG />
                <span style={{ fontWeight: 700 }}>Activities</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* <ConditionalRender
          condition={checkStringPermission('Can View Accounting Module')}
          renderIf={
            <Nav.Item className={pathname === '/dashboard/fixed-asset' && 'active'}>
              <Link className='nav-link' to='/dashboard/fixed-asset'>
                <FixedAssetSVG />
                <span style={{ fontWeight: 700 }}>Fixed Asset</span>
              </Link>
            </Nav.Item>
          }
        /> */}

        {/* Management List */}
        {/* <ConditionalRender

          condition={checkStringPermission('Can View ACL Module')}
          renderIf={
            <>
              <h3 className='header-text'>Management List</h3>

              <Nav.Item className={pathname === '/dashboard/acl' && 'active'}>
                <Link className='nav-link' to='/dashboard/acl'>
                  <DashboardSVG />
                  <span style={{ fontWeight: 700 }}>Dashboard</span>
                </Link>
              </Nav.Item>

              {options2.map((data) => {
                return (
                  <Accordion
                    key={data.eventKey}
                    defaultActiveKey={activeKey}
                    className='dropdown-nav'
                  >
                    <Accordion.Item eventKey={data.eventKey}>
                      <Accordion.Header>
                        <div className='svg__wrapper'>{data.icon}</div>
                        <Nav.Link className='nav-link' href='#'>
                          {data.label}
                        </Nav.Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        {data.items.map((item, idx) => (
                          <Nav.Item key={`accord1-${idx}`} className={item.className}>
                            <Link className='nav-link' to={item.path}>
                              {item.icon}
                              <p className={`item-and-total ${item.label}`}>
                                {item.label} <span>{item.total}</span>
                              </p>
                            </Link>
                          </Nav.Item>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })}
            </>
          }
        /> */}
        {/* <hr className='mt-5' style={{ width: '100%', height: 1, background: 'none' }} /> */}
      </Nav>

      <span id='sidebar-footer'>2024 HMS All Rights Reserved.</span>
    </div>
  );
};

export default Sidebar;
