/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

const ProgressNotes = ({ handleChange, stateData, setStateData }) => {
  return (
    <>
      <Row>
        <Col>
          <Form.Group className='mb-4'>
            <Form.Label>Progress Notes</Form.Label>
            <FormControl
              as='textarea'
              rows={3}
              className='bg-white'
              placeholder='Enter Here'
              value={stateData?.note_description}
              onChange={handleChange('note_description')}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className='mb-4'>
            <Form.Label>Doctor's Orders</Form.Label>
            <FormControl
              as='textarea'
              rows={3}
              className='bg-white'
              placeholder='Enter Here'
              value={stateData?.additional_notes}
              onChange={handleChange('additional_notes')}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default ProgressNotes;
