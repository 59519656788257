/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import core & vendor packages below
import { Modal, Button, Form, FormControl } from 'react-bootstrap';

// import assets below
import './DiagnosisModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';
import { toast } from 'react-toastify';

const DiagnosisModal = (props) => {
  const { status, onToggle, onConfirm } = props;

  const [formData, setFormData] = useState({
    diagnosis: '',
    final_diagnosis: 'yes',
    diagnosis_type: '',
    health_related_infection: '',
    icd_code: '',
    discharge_diagnosis: '',
    remarks: '',
  });

  const handleChange = (key) => (e) => {
    setFormData((prev) => {
      return { ...prev, [key]: e.target.value };
    });
  };

  return (
    <Modal
      aria-labelledby='contained-modal-title-vcenter'
      className='diagnosis-request-modal'
      show={status}
      onHide={() => onToggle(!status)}
      centered
      size='xl'
    >
      <Modal.Header>
        <h4>Diagnosis 55</h4>
        <Button onClick={() => onToggle(!status)} variant='transparent'>
          <CloseSVG />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='modal__body--wrapper'>
            <div className='select'>
              <Form.Group>
                <Form.Label>ICD - 10 Codes</Form.Label>
                <Form.Select aria-label='Search Diagnosis'>
                  <option value='' hidden>
                    Search Diagnosis
                  </option>
                  <option value='1'>Sample 1</option>
                  <option value='2'>Sample 2</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className='select__radio--wrapper'>
              <Form.Group>
                <Form.Label>Final Diagnosis</Form.Label>
                <div className='btn--radio-flex'>
                  <Form.Check
                    value={formData?.final_diagnosis}
                    onChange={handleChange('final_diagnosis')}
                    type='radio'
                    name='finalDiagnosis'
                    label='Yes'
                  />
                  <Form.Check
                    value={formData?.final_diagnosis}
                    onChange={handleChange('final_diagnosis')}
                    type='radio'
                    name='finalDiagnosis'
                    label='No'
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Diagnosis Type</Form.Label>
                <div className='btn--radio-flex'>
                  <Form.Check
                    value={formData?.diagnosis_type}
                    onChange={handleChange('diagnosis_type')}
                    type='radio'
                    name='type'
                    label='Primary'
                  />
                  <Form.Check
                    value={formData?.diagnosis_type}
                    onChange={handleChange('diagnosis_type')}
                    type='radio'
                    name='type'
                    label='Secondary'
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Health Associated Infection</Form.Label>
                <Form.Check
                  value={formData?.health_related_infection}
                  onChange={handleChange('health_related_infection')}
                  type='radio'
                  name='healthRelatedInfections'
                  label='Device Related Infections'
                />
                <Form.Check
                  value={formData?.health_related_infection}
                  onChange={handleChange('health_related_infection')}
                  type='radio'
                  name='healthRelatedInfections'
                  label='Non-Device Related Infection'
                />
              </Form.Group>
            </div>
            <div className='select'>
              <Form.Label>ICD CODE</Form.Label>
              <FormControl value={formData?.icd_code} onChange={handleChange('icd_code')} />
            </div>
            <div>
              <Form.Label>Discharge Diagnosis</Form.Label>
              <Form.Control
                value={formData?.discharge_diagnosis}
                onChange={handleChange('discharge_diagnosis')}
                as='textarea'
                className='text-area-input'
              />
            </div>
            <div>
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                value={formData?.remark}
                onChange={handleChange('remark')}
                as='textarea'
                placeholder='Remarks here'
                className='text-area-input'
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='light-green'
          className='add__btn'
          onClick={() => {
            toast.success('Successfully Save');
            onToggle(!status);
            onConfirm((prevState) => !prevState);
          }}
        >
          ADD
        </Button>
        <Button onClick={() => onToggle(!status)} variant='cancel'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DiagnosisModal;
