/* eslint-disable */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import components below
import Table from 'components/ReactTable';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const RoomReservationTable = ({ state, setState, setShowProfile }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // const [listOfRoomReservation, setListOfRoomReservation] = useState([]);
  const [confirmApprove, setConfirmApprove] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const closeModal = (type) => {
    switch (type) {
      case 'approve':
        setConfirmApprove(false);
        break;
      default:
        break;
    }

    setSelectedRow(null);
  };

  const confirm = (data, type) => {
    switch (type) {
      case 'approve':
        handleSubmit(selectedRow);
        break;
      default:
        break;
    }
  };

  const getData = () => {
    axios.get('/room_request?status=approved').then((response) => {
      setState(response.data.data);
    }, []);
  };

  const handleSubmit = (id) => {
    axios
      .post(`room_request/approve/${id}`)
      .then((response) => {
        toast.success('Successfully approved room request');
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const EditProfile = (item) => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        profileId: item?.id,
      };
    });

    dispatch(showPatient(item.id));
    navigate('/dashboard/outpatient-department/edit-patient-profile', { replace: true });
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Request ID', accessor: 'id' },
      { Header: 'Patient ID', accessor: 'patientId' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Room Type', accessor: 'room_type' },
      { Header: 'Floor', accessor: 'floor' },
      { Header: 'Room', accessor: 'room' },
      { Header: 'Bed', accessor: 'bed' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        patientId: dd?.patient?.id,
        name: `${dd?.patient?.first_name} ${dd?.patient?.last_name} ${dd?.patient?.middle_name}`,
        room_type: dd?.room?.type,
        floor: dd?.room?.floor,
        room: dd?.room?.room,
        bed: dd?.room?.bed,
        status: dd?.status,
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const action = {
      approve: () => {
        setSelectedRow(id);
        setConfirmApprove(true);
      },
    };

    action[eventKey]();
  };

  return (
    <div className='admissions-room-reservation-list-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        customAction={(id, data) => {
          return (
            <>
              <span
                onClick={() => handleTableAction('approve', id)}
                className='badge blue'
                style={{ cursor: 'pointer' }}
              >
                Approve
              </span>
            </>
          );
        }}
        pagination={['BOTTOM']}
      />

      <ConfirmModal
        fields={[]}
        title={'Approve Room?'}
        type={'approve'}
        confirm={confirm}
        closeModal={closeModal}
        openModal={confirmApprove}
      />
    </div>
  );
};

RoomReservationTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowProfile: () => {},
};

RoomReservationTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default RoomReservationTable;
