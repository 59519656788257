/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';

import Table from 'components/ReactTable';

import './index.scss';
import styles from './index.module.scss';
import { ReactComponent as ClockInSVG } from 'assets/svg/clock-in-icon.svg';
import { ReactComponent as CalendarSVG } from 'assets/svg/calendar-icon.svg';
import { ReactComponent as ClockOutSVG } from 'assets/svg/clock-out-icon.svg';

const sampleData = [
  {
    id: 1,
    date: 'January 2, 2023',
    time_in: '9:00 AM',
    time_out: '9:00 PM',
  },
  {
    id: 2,
    date: 'January 3, 2023',
    time_in: '9:00 AM',
    time_out: '9:00 PM',
  },
  {
    id: 3,
    date: 'January 4, 2023',
    time_in: '9:00 AM',
    time_out: '9:00 PM',
  },
  {
    id: 4,
    date: 'January 5, 2023',
    time_in: '9:00 AM',
    time_out: '9:00 PM',
  },
];

const RecentActivities = () => {
  const tableColumns = React.useMemo(
    () => [
      {
        Header: (
          <p className='table-column-header'>
            {/* <CalendarSVG /> */} Date
          </p>
        ),
        accessor: 'date',
      },
      {
        Header: (
          <p className='table-column-header'>
            {/* <ClockInSVG /> */} Time In
          </p>
        ),
        accessor: 'time_in',
      },
      {
        Header: (
          <p className='table-column-header'>
            {/* <ClockOutSVG /> */} Time Out
          </p>
        ),
        accessor: 'time_out',
      },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      sampleData?.map((dd) => ({
        id: dd?.id,
        date: dd?.date,
        time_in: dd?.time_in,
        time_out: dd?.time_out,
      })),
    [sampleData],
  );

  return (
    <div className={`${styles['recent-activites']} shadow recent-activites`}>
      <div className={styles['header']}>
        <h3>Recent Activities</h3>
      </div>

      <div className={styles['body']}>
        <Table data={tableData} columns={tableColumns} pagination={['BOTTOM']} defaultPageSize={5} />
      </div>
    </div>
  );
};

export default RecentActivities;
