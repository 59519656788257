/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormControl, Button, Tabs, Tab, Image } from 'react-bootstrap';

// import components below
import TabsUI from 'components/UI/TabsUI';
import BackUpCredentials from './BackUpCredentials';
import RestoreCredentials from './RestoreCredentials';
import GenericCredentials from './GenericCredentials';
import WindowsCredentials from './WindowsCredentials';
import ConditionalRender from 'components/ConditionalRender';
import CertificateBasedCredentials from './CertificateBasedCredentials';
import CertificateBasedModal from './CertificateBasedCredentials/components/Modal';
import { TableFiltersPermission, checkStringPermission } from 'helpers/filteredPermissions';
import { selectAuth } from 'reducers/authReducer';
import { toast } from 'react-toastify';

import { 
  addGenericCredentialItems, 
  addWindowCredentialItems,
  selectCredentialsManagement,
  updateGenericCredential,
  getGenericCredentialList,
} from 'reducers/credentialsManagementReducer';
// import assets below
import './index.scss';

// import assets below
const CredentialsManagementContainer = () => {
  const dispatch = useDispatch();

  const assetState = useSelector(selectCredentialsManagement);

  const authState = useSelector(selectAuth);
  const { userInfo } = authState;
  
  const { addGenericCredentialItemLogs, addWindowCredentialItemLogs } = assetState;
  
  const [openModal, setOpenModal] = useState();
  const [modalType, setModalType] = useState();
  
  const [activeKey, setActiveKey] = useState('');
  const [showScreen, setShowScreen] = useState(1);
  const [requestData, setRequestData] = useState();
  const [formData, setFormData] = useState({ formType: 'Generic Credentials' });

  const onChange = (key) => {
    setActiveKey(key);
  };

  const defaultTabs = [
    {
      title: 'Generic Credentials',
      component: GenericCredentials,
    },
    {
      title: 'Certificate-Based Credentials',
      component: CertificateBasedCredentials,
    },
    {
      title: 'Windows Credentials',
      component: WindowsCredentials,
    },
    {
      title: 'Back Up Credentials',
      component: BackUpCredentials,
    },
    {
      title: 'Restore Credentials',
      component: RestoreCredentials,
    },
  ];
  const handleChange = (e) => {
    const { value, name } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
    // for Add Generic Credentials data
    const handleGenericCredentials = async (e) => {
      e.preventDefault();
      const params = {
        // lines: formData?.lines,
        userId: userInfo?.id,
        internet: formData?.internet,
        username: formData?.username,
        password: formData?.password,
      };
  
      const response = await dispatch(addGenericCredentialItems(params));
  
      if (response?.meta?.requestStatus === 'rejected') {
        // error message
        toast.error('Error');
        return;
      }
      if (response?.meta?.requestStatus === 'fulfilled') {
        // success message
        toast.success('Successfully added Record');
        setFormData({});
        setShowScreen(1);
      }
    };
    // for Add Window Credentials data
    const handleWindowCredentials = async (e) => {
      e.preventDefault();
      const params = {
        // lines: formData?.lines,
        userId: userInfo?.id,
        internet: formData?.internet,
        username: formData?.username,
        password: formData?.password,
      };
  
      const response = await dispatch(addWindowCredentialItems(params));
  
      if (response?.meta?.requestStatus === 'rejected') {
        // error message
        toast.error('Error');
        return;
      }
  
      if (response?.meta?.requestStatus === 'fulfilled') {
        // success message
        toast.success('Successfully added Record');
         setFormData({});
         setShowScreen(5);
      }
    };
  
    // eslint-disable-next-line prefer-const
    let tabs = TableFiltersPermission(defaultTabs, []);
  
    useEffect(() => {
      console.log('formDatas', formData);
    }, [formData]);
    
  return (
    <>
      {/* Generic Credentials Tab */}
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>CREDENTIALS MANAGEMENT</Form.Label>

                <FormControl className='blue-control' placeholder='Search' />
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-tabs'>
                <TabsUI>
                  <Tabs
                    defaultActiveKey={tabs[0].title}
                    onSelect={(eventKey) => {
                      onChange(eventKey);
                      setFormData((prev) => {
                        console.log(eventKey);
                        return {
                          ...prev,
                          formType: eventKey,
                        };
                      });
                    }}
                  >
                    {tabs.map(({ title, component: Component }, idx) => (
                      <Tab key={idx} eventKey={title} title={title}>
                        <Component
                          activeKey={activeKey}
                          setFormData={setFormData}
                          setShowScreen={setShowScreen}
                          setRequestData={setRequestData}
                        />
                      </Tab>
                    ))}
                  </Tabs>
                </TabsUI>
              </div>
            </div>
            
          </div>
        }
      />
      {/* Window Credentials Tab */}
      <ConditionalRender
        condition={showScreen === 5}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>CREDENTIALS MANAGEMENT</Form.Label>

                <FormControl className='blue-control' placeholder='Search' />
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-tabs'>
                <TabsUI>
                  <Tabs
                    defaultActiveKey={tabs[2].title}
                    onSelect={(eventKey) => {
                      onChange(eventKey);
                      setFormData((prev) => {
                        console.log(eventKey);
                        return {
                          ...prev,
                          formType: eventKey,
                        };
                      });
                    }}
                  >
                    {tabs.map(({ title, component: Component }, idx) => (
                      <Tab key={idx} eventKey={title} title={title}>
                        <Component
                          activeKey={activeKey}
                          setFormData={setFormData}
                          setShowScreen={setShowScreen}
                          setRequestData={setRequestData}
                        />
                      </Tab>
                    ))}
                  </Tabs>
                </TabsUI>
              </div>
            </div>
            
          </div>
        }
      />

      <ConditionalRender
        condition={showScreen === 2}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ADD A GENERIC CREDENTIAL</Form.Label>
              </Form.Group>
            </div>
            <Form
              className='w-100'
              onSubmit={handleGenericCredentials}
            >
            {/* Main Content */}
              <div className='box-container pt-0'>
                <div className='credentials-details'>
                  <div className='details-content'>
                    <div className='main-content'>
                      <br />
                      <h5>Add a Website Address and your Credential Information</h5>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Internet or Network Address</Form.Label>

                          <FormControl
                            name='internet'
                            placeholder='Enter Here'
                            className='border-seven'
                            onChange={handleChange}
                            value={formData?.internet || ''}
                          />
                        </Form.Group>

                        <Row>
                          <Col>
                            <Form.Group className='vertical-align'>
                              <Form.Label>Username</Form.Label>
                              <FormControl 
                                name='username' 
                                placeholder='Enter Here' 
                                className='border-seven'
                                onChange={handleChange}
                                value={formData?.username || ''}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className='vertical-align'>
                              <Form.Label>Password</Form.Label>

                              <FormControl
                                name='password'
                                placeholder='Enter Here'
                                className='border-seven'
                                onChange={handleChange}
                                value={formData?.password || ''}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <br /> <br />
                    </div>
                  </div>

                  <div className='details-footer'>
                    <Button 
                      disabled={addGenericCredentialItemLogs?.isLoading}
                      type='submit'
                      className='save'
                    >
                      Save
                    </Button>
                    <Button onClick={() => setShowScreen(1)} className='cancel'>
                      Cancel
                    </Button>
                  </div>
                  
                </div>
              </div>
            </Form>
          </div>
        }
      />

      <ConditionalRender
        condition={showScreen === 3}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ADD A WINDOWS CREDENTIAL</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <Form
                className='w-100'
                onSubmit={handleWindowCredentials}
              >
                <div className='credentials-details'>
                  <div className='details-content'>
                    <div className='main-content'>
                      <br />
                      
                      <h5>Add a Website Address or Network Location and your Credential Information</h5>
                      
                      <Form.Group className='vertical-align'>
                        <Form.Label>Internet or Network Address</Form.Label>

                        <FormControl
                          name='internet'
                          onChange={handleChange} 
                          placeholder='Enter Here' 
                          className='border-seven'
                          value={formData?.internet || ''}
                        />
                      </Form.Group>

                      <Row>
                        <Col>
                          <Form.Group className='vertical-align'>
                            <Form.Label>Username</Form.Label>

                            <FormControl
                              name='username'
                              onChange={handleChange}
                              placeholder='Enter Here'
                              className='border-seven'
                              value={formData?.username || ''}
                            />
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group className='vertical-align'>
                            <Form.Label>Password</Form.Label>

                            <FormControl
                              name='password' 
                              onChange={handleChange} 
                              placeholder='Enter Here' 
                              className='border-seven'
                              value={formData?.password || ''} 
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <br /> <br />
                    </div>
                  </div>

                  <div className='details-footer'>
                    <Button
                      disabled={addWindowCredentialItemLogs?.isLoading}
                      type='submit'
                      className='save'
                    >Save
                    </Button>
                    <Button onClick={() => setShowScreen(5)} className='cancel'>
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        }
      />

      <ConditionalRender
        condition={showScreen === 4}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>

              <Form.Group className='inline-group'>
                <Form.Label>ADD A CERTIFICATE-BASED CREDENTIAL</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <br />
                    <h5>Add the Address of the Website or Network Location and select a Certificate</h5>
                    <Form.Group className='vertical-align'>
                      <Form.Label>Internet or Network Address</Form.Label>

                      <FormControl placeholder='Enter Here' className='border-seven' />
                    </Form.Group>
                    <br />
                  </div>

                  <Button onClick={() => {
                        setOpenModal({
                          isOpen: true,
                        });
                      }
                    }
                  >
                    Select Certificate
                  </Button>
                </div>
                <br /><br />

                <div className='details-footer'>
                  <Button className='save'>Save</Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <CertificateBasedModal modalType={modalType} openModal={openModal} setOpenModal={setOpenModal} />
          </div>
        }
      />
    </>
  );
};

export default CredentialsManagementContainer;
