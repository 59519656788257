/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Row, Col, Table, Button, Form, FormControl, Accordion } from 'react-bootstrap';

import AccordionUI from 'components/UI/AccordionUI';

import './style.scss';

const CurrentMedicalInformation = ({ setShowModal, patientProfile }) => {
  const handleOpenEditCurrentMedicalInformation = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        isShowEdit: true,
      };
    });
  };

  useEffect(() => {
    console.log('patientProfile', patientProfile);
  }, [patientProfile]);

  return (
    <div className='patientProfile__header--visitInformation'>
      <AccordionUI>
        <Accordion defaultActiveKey='0' className='mb-5'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>Current Medical Information</Accordion.Header>
            <Accordion.Body>
              <div className='d-flex justify-content-between align-items-center current-medical-information'>
                <h4 style={{ margin: '0px 0px 30px 0px' }}></h4>
                <div className='ms-auto'>
                  <Button
                    className='me-3 btn-addField'
                    onClick={handleOpenEditCurrentMedicalInformation}
                  >
                    Edit Current Medical Information
                  </Button>
                </div>
              </div>

              <Row>
                <Col>
                  <div className='patientProfile__content'>
                    <Row>
                      <Col lg='3'>
                        <div className='wrapper'>
                          <span>Current Diagnosis</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='9'>
                        <span className='value'>
                          {patientProfile?.medical_information?.current_diagnosis
                            ? patientProfile?.medical_information?.current_diagnosis
                            : '-'}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3'>
                        <div className='wrapper'>
                          <span>Prognosis</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='9'>
                        <span className='value'>
                          {patientProfile?.medical_information?.prognosis
                            ? patientProfile?.medical_information?.prognosis
                            : '-'}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3'>
                        <div className='wrapper'>
                          <span>Surgical Procedures and Dates</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='9'>
                        <span className='value'>
                          {patientProfile?.medical_information?.surgical_procedures_and_dates
                            ? patientProfile?.medical_information?.surgical_procedures_and_dates
                            : '-'}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3'>
                        <div className='wrapper'>
                          <span>Past Medical History</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='9'>
                        <span className='value'>
                          {patientProfile?.medical_information?.past_medical_history
                            ? patientProfile?.medical_information?.past_medical_history
                            : '-'}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3'>
                        <div className='wrapper'>
                          <span>Family History</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='9'>
                        <span className='value'>
                          {patientProfile?.medical_information?.family_history
                            ? patientProfile?.medical_information?.family_history
                            : '-'}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3'>
                        <div className='wrapper'>
                          <span>Personal Social History</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='9'>
                        <span className='value'>
                          {patientProfile?.medical_information?.personal_social_history
                            ? patientProfile?.medical_information?.personal_social_history
                            : '-'}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3'>
                        <div className='wrapper'>
                          <span>Is Infectios?</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='9'>
                        <span className='value'>
                          {patientProfile?.medical_information?.is_infectious
                            ? patientProfile?.medical_information?.is_infectious
                            : '-'}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3'>
                        <div className='wrapper'>
                          <span>Condition Classified as</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='9'>
                        <span className='value'>
                          {patientProfile?.medical_information?.condition_classified_as
                            ? patientProfile?.medical_information?.condition_classified_as
                            : '-'}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3'>
                        <div className='wrapper'>
                          <span>Pertinent Signs and Symptoms</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='9'>
                        <ul className='value list'>
                          {patientProfile?.medical_information &&
                            patientProfile?.medical_information?.pertinent_signs_and_symptoms.map(
                              (data, idx) => {
                                return (
                                  <li key={idx}>{`${data?.details} ${
                                    data?.details === 'Pain'
                                      ? `(${patientProfile?.medical_information?.pain})`
                                      : ''
                                  } ${
                                    data?.details === 'Others'
                                      ? `(${patientProfile?.medical_information?.other_symptoms})`
                                      : ''
                                  }`}</li>
                                );
                              },
                            )}
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </AccordionUI>
    </div>
  );
};

export default CurrentMedicalInformation;
