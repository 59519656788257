/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
import React, { Component } from 'react';
import QrReader from 'react-weblineindia-qrcode-scanner';

class QrComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 500,
    };

    this.handleScan = this.handleScan.bind(this);
  }

  handleScan(data) {
    const { setShowProfile, setShow } = this.props;
    if (data === null) return;

    setShowProfile((prev) => {
      return {
        ...prev,
        profileId: data,
        isShowProfile: true,
      };
    });

    setShow(false);
  }

  handleError(err) {
    console.error(err);
  }

  render() {
    const previewStyle = {
      height: 'auto',
      width: '100%',
    };

    const { delay } = this.state;

    return (
      <QrReader
        delay={delay}
        style={previewStyle}
        onError={this.handleError}
        onScan={this.handleScan}
      />
    );
  }
}

export default QrComponent;
