/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  getBilling,
  showBilling,
  showBillingByPatient,
  createBilling,
  dischargeBilling,
  createBillDeduction,
  getDisbursedPatient,
  getDisbursementPatient,
  getDisbursedAdvanceSearchPatient,
  getDisbursementAdvancedSearchPatient,
} from './thunks/billingThunks';

const initialState = {
  data: [],
  item: null,
  logs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  disbursementPatientData: [],
  disbursedPatientData: [],
};

const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: {
    [getBilling.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.disbursementPatientData = action.payload.data;
      state.isLoading = false;
    },
    [getBilling.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getBilling.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = true;
    },
    [showBilling.fulfilled]: (state, action) => {
      state.item = action.payload.data;
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [showBilling.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [showBilling.rejected]: (state, action) => {
      state.item = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action.payload.data;
    },
    [showBillingByPatient.fulfilled]: (state, action) => {
      state.billing = action.payload.data;
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [showBillingByPatient.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [showBillingByPatient.rejected]: (state, action) => {
      state.billing = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
    },
    [createBilling.fulfilled]: (state, action) => {
      state.billing = action.payload.data;
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [createBilling.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [createBilling.rejected]: (state, action) => {
      state.billing = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
    },
    [dischargeBilling.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [dischargeBilling.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [dischargeBilling.rejected]: (state, action) => {
      state.billing = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
    },
    [createBillDeduction.fulfilled]: (state, action) => {
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [createBillDeduction.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [createBillDeduction.rejected]: (state, action) => {
      state.item = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action.payload.data;
    },
    [getDisbursementPatient.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getDisbursementPatient.fulfilled]: (state, action) => {
      state.disbursementPatientData = action.payload.data;
      state.logs.isLoading = false;
    },
    [getDisbursementPatient.rejected]: (state) => {
      state.logs.isLoading = true;
    },
    [getDisbursementAdvancedSearchPatient.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getDisbursementAdvancedSearchPatient.fulfilled]: (state, action) => {
      state.disbursementPatientData = action.payload.data;
      state.logs.isLoading = false;
    },
    [getDisbursementAdvancedSearchPatient.rejected]: (state) => {
      state.logs.isLoading = true;
    },
    [getDisbursedPatient.fulfilled]: (state, action) => {
      state.disbursedPatientData = action.payload.data;
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [getDisbursedPatient.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getDisbursedPatient.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = true;
      state.logs.isLoading = true;
    },
    [getDisbursedAdvanceSearchPatient.fulfilled]: (state, action) => {
      state.disbursedPatientData = action.payload.data;
      state.logs.isLoading = false;
      state.logs.isSuccess = true;
    },
    [getDisbursedAdvanceSearchPatient.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getDisbursedAdvanceSearchPatient.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
    },
  },
});

export const { reducer: billingReducer } = slice;

const selectDomain = (state) => state.billing || initialState;

export const selectBilling = createSelector([selectDomain], (billingState) => billingState);

export const selectBillingData = createSelector(
  [selectDomain],
  (billingState) => billingState.data,
);
export const selectBillingLogs = createSelector(
  [selectDomain],
  (billingState) => billingState.logs,
);
export const selectSoaData = createSelector([selectDomain], (billingState) => billingState.billing);

export const selectDisbursementPatientData = createSelector(
  [selectDomain],
  (initialState) => initialState.disbursementPatientData,
);

export const selectDisbursedPatientData = createSelector(
  [selectDomain],
  (initialState) => initialState.disbursedPatientData,
);

export {
  getBilling,
  showBilling,
  showBillingByPatient,
  createBilling,
  dischargeBilling,
  createBillDeduction,
  getDisbursementPatient,
  getDisbursedPatient,
};
