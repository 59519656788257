/* eslint-disable */

// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Button, FormControl, InputGroup } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getReturnTableList } from 'reducers/returnReducer';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchInventoryFilter = ({ searchData, setSearchData, departmentID }) => {
  const dispatch = useDispatch();

  const handleChange = (key) => (e) => {
    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const handleSearch = (reset = false) => {
    if (reset) {
      const params = { per_page: 100000, q: '', department_id: departmentID };

      dispatch(getReturnTableList(params));

      return;
    }

    dispatch(
      getReturnTableList({
        per_page: 100000,
        q: searchData?.search,
        department_id: departmentID,
      }),
    );
  };

  return (
    <div className='admissions-search-inventory-filter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col className='d-flex align-items-center'>
            <Form.Group className='mb-3 me-3'>
              <Form.Label>Control Number</Form.Label>
              <InputGroup className='search'>
                <InputGroup.Text>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  value={searchData?.search}
                  onChange={handleChange('search')}
                  type='text'
                  placeholder='Search here...'
                />
              </InputGroup>
            </Form.Group>
            <div style={{ marginTop: '15px' }}>
              <Button
                onClick={() => {
                  handleSearch();
                }}
                style={{ height: '50px' }}
                className='btn btn-success me-3'
              >
                APPLY
              </Button>
              <Button
                style={{ height: '50px' }}
                className='btn btn-secondary me-3'
                onClick={() => {
                  setSearchData({
                    ...searchData,
                    search: '',
                  });
                  handleSearch(true);
                }}
              >
                RESET
              </Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

SearchInventoryFilter.defaultProps = {
  searchData: {},
  departmentID: '',
  setSearchData: () => {},
};

SearchInventoryFilter.propTypes = {
  searchData: PropTypes.object,
  setSearchData: PropTypes.func,
  departmentID: PropTypes.string,
};

export default SearchInventoryFilter;
