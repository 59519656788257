/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-closing-bracket-location */

import { useState } from 'react';

// main components below
import PatientTable from './components/Table';
import BasicSearch from './components/BasicSearch';
import AdvancedSearch from './components/AdvancedSearch';
import ConditionalRender from 'components/ConditionalRender';
import FormPatientProfile from 'components/FormPatientProfile';
import { checkStringPermission } from 'helpers/filteredPermissions';
import ModalNote from 'pages/Doctors/components/SearchPatient/components/Note';
import ModalSupply from 'pages/Nursing/components/SearchPatient/PatientWorklist/Supply';
import ModalProcedure from './../../../Nursing/components/SearchPatient/PatientWorklist/Procedure';
import ModalLabRequest from './../../../Nursing/components/SearchPatient/PatientWorklist/LabRequest';
import ModalMedication from './../../../Nursing/components/SearchPatient/PatientWorklist/Medication';

// main component
const SearchPatient = () => {
  const [state, setState] = useState([]);
  const [show, setShow] = useState(false);
  const [showProfile, setShowProfile] = useState({
    note: false,
    supply: false,
    profileId: null,
    addOrder: false,
    procedure: false,
    labRequest: false,
    medication: false,
    fileUpload: false,
    isShowProfile: false,
  });

  return (
    <>
      <div className='searchPatient'>
        {!showProfile.isShowProfile ? (
          <>
            <BasicSearch
              show={show}
              setShow={setShow}
              setState={setState}
              showProfile={showProfile}
              setShowProfile={setShowProfile}
            />

            <AdvancedSearch setState={setState} />

            <PatientTable state={state} setState={setState} setShowProfile={setShowProfile} />
          </>
        ) : (
          <FormPatientProfile setShowProfile={setShowProfile} showProfile={showProfile} />
        )}
      </div>
      <ModalLabRequest setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalProcedure setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalNote setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalMedication setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalSupply setShowProfile={setShowProfile} showProfile={showProfile} />
    </>
  );
};

export default SearchPatient;
