import { Route } from 'react-router-dom';

import Admissions from 'pages/Admissions';
import ProtectedRoute from 'components/ProtectedRoute';
import CreateNewPatient from 'pages/Admissions/components/CreateNewPatient';
import CreateRoomRequest from 'pages/Admissions/components/CreateRoomRequest';
import EditPatientProfile from 'pages/Admissions/components/EditPatientProfile';
import ViewPatientProfile from 'pages/Admissions/components/ViewPatientProfile';
import CreateInpatientVisit from 'pages/Admissions/components/CreateInpatientVisit';
import CreateTemporaryProfile from 'pages/Admissions/components/CreateTemporaryProfile';

export default [
  <Route
    key='main'
    path='/dashboard/admissions'
    element={<ProtectedRoute department={'admission'} component={Admissions} />}
  />,
  <Route
    key='create new patient'
    path='/dashboard/admissions/create-new-patient'
    element={<ProtectedRoute department={'admission'} component={CreateNewPatient} />}
  />,
  <Route
    key='create temporary profile'
    path='/dashboard/admissions/create-temporary-profile'
    element={<ProtectedRoute department={'admission'} component={CreateTemporaryProfile} />}
  />,
  <Route
    key='edit temporary profile'
    path='/dashboard/admissions/edit-patient-profile'
    element={<ProtectedRoute department={'admission'} component={EditPatientProfile} />}
  />,
  <Route
    key='create inpatient visit'
    path='/dashboard/admissions/create-inpatient-visit'
    element={<CreateInpatientVisit />}
  />,
  <Route
    key='create room request'
    path='/dashboard/admissions/create-room-request'
    element={<ProtectedRoute department={'admission'} component={CreateRoomRequest} />}
  />,
  <Route
    key='patient profile'
    path='/dashboard/admissions/patient-profile'
    element={<ProtectedRoute department={'admission'} component={ViewPatientProfile} />}
  />,
];
