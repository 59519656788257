/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import './Disbursement.scss';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, Modal, Row } from 'react-bootstrap';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MoneyCheck } from 'assets/svg/money-check.svg';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import { useDispatch, useSelector } from 'react-redux';
import { showBillingByPatient } from 'reducers/thunks/billingThunks';
import moment from 'moment';
import { selectSoaData } from 'reducers/billingReducer';
import axios from 'axios';
import { toast } from 'react-toastify';

const DisbursementModal = ({ showModal, setShowModal, profile, setProfile }) => {
  const dispatch = useDispatch();

  const soaData = useSelector(selectSoaData);
  const patientProfile = useSelector(selectPatientItem);

  const [state, setState] = useState({
    billId: '',
    patientId: '',
    amount_disbursed: '',
    name_of_receiver: '',
  });

  const [disbursementAmount, setDisbursementAmount] = useState('');

  const closeTheModal = () => {
    setProfile({ id: null });
    setState({ ...state, name_of_receiver: '' });
    setShowModal();
  };

  const createDisbursement = () => {
    axios
      .post(`/disbursement`, state)
      .then((response) => {
        toast.success('Success!');
        closeTheModal();
      })
      .catch((error) => {
        toast.warning(error.response.data.status);
      });
  };

  const handleChange = (key) => (e) => {
    setState({
      ...state,
      [key]: e.target.value.replace(/[0-9]/g, ''),
    });
  };

  useEffect(() => {
    if (profile.id) {
      dispatch(showPatient(profile?.id));
      dispatch(showBillingByPatient(profile?.id));
    }
  }, [profile]);

  useEffect(() => {
    if (soaData?.bill) {
      setState((prev) => {
        return {
          ...prev,
          billId: soaData?.bill?.id,
          patientId: soaData?.bill?.patientId,
          amount_disbursed: Math.abs(soaData?.totalNetBill),
        };
      });
    }

    setDisbursementAmount(soaData?.totalNetBill > 0 ? 0 : Math.abs(soaData?.totalNetBill));

    console.log('soaData', soaData);
  }, [soaData]);

  return (
    <Modal size='lg' show={showModal} centered>
      <Modal.Header>
        <div className='disbursement-header'>
          <div className='d-flex header'>
            <p>DISBURSEMENT</p>
            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                closeTheModal();
              }}
            />
          </div>
        </div>
      </Modal.Header>
      {disbursementAmount > 0 &&
        <Modal.Body>
          <Row>
            <Col md={3}>
              <p>Patient ID</p>
              <p>Patient Name</p>
            </Col>
            <Col md={3}>
              <p>
                <span>: {patientProfile?.id}</span>
              </p>
              <p>
                <span>: {patientProfile?.full_name}</span>
              </p>
            </Col>
            <Col md={3}>
              <p>Location</p>
              <p>Admission Date</p>
            </Col>
            <Col md={3}>
              <p>
                <span>
                  : {patientProfile?.assigned_room ? patientProfile?.assigned_room?.room?.room : '-'}
                </span>
              </p>
              <p>
                <span>
                  :{' '}
                  {patientProfile?.admission_date
                    ? moment(patientProfile?.admission_date).format('MMMM-DD-YYYY')
                    : '-'}
                </span>
              </p>
            </Col>
          </Row>
          <hr />
          <Row align='center'>
            <Col align='center' md={12}>
              <h5>AMOUNT OF DISBURSEMENT</h5>
            </Col>
            <Row>
              <Col align='center'>
                <p>
                  Total:{' '}
                  <span>{soaData?.totalNetBill > 0 ? 0 : Math.abs(soaData?.totalNetBill)}</span>
                </p>
              </Col>
            </Row>
          </Row>
          <hr />
          <Row>
            <Col align='center'>
              <Form.Group className='mb-3 amount-form'>
                <div>
                  <Form.Label>
                    <span>Amount to refund (Auto-compute) </span>
                  </Form.Label>
                  <FormControl
                    type='number'
                    placeholder='Enter Amount'
                    disabled={true}
                    value={soaData?.totalNetBill > 0 ? 0 : Math.abs(soaData?.totalNetBill)}
                    size='md'
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row align='center'>
            <Col align='center' className='mb-3'>
              <div>
                <span>Mode of payment</span>
              </div>
            </Col>
            <Col xs={12} className='mb-4'>
              <div className='flex-wrapper'>
                <div className='payment-mode is-active'>
                  <MoneyCheck />
                  <div>Cash</div>
                </div>
                {/* NO CHEQUE PAYMENT AT THE MOMENT */}
                {/* <div className='payment-mode'>
                       <MoneyCheck />
                       <div>Check</div>
                     </div> */}
              </div>
            </Col>
            <Col xs={12} align='center' className='mb-3'>
              <div>
                <span>Name of Receiver </span>
              </div>
              <div className='label danger'>( with valid Id or Authorization Letter )</div>
              <Form.Group className='mb-3'>
                <FormControl
                  type='text'
                  className='bg-white'
                  placeholder='Enter receiver name'
                  onChange={handleChange('name_of_receiver')}
                  value={state?.name_of_receiver}
                  style={{ maxWidth: 500 }}
                  size='md'
                />
              </Form.Group>
            </Col>
            {/* <Col align='center'>
                   <div className='flex-wrapper'>
                     <Button variant='search' className='btn-payment'>
                       Compute Balance
                     </Button>
                     <Button variant='light' className='btn-payment'>
                       Cancel
                     </Button>
                   </div>
                 </Col> */}
          </Row>
          <hr />
          <Row align='center'>
            <Col xs={12} className='mb-4 mt-3'>
              <p>Date / Time Disburse</p>
              <h5> {moment().format('MMM-DD-YYYY hh:mm A')}</h5>
            </Col>
            {/* <Col xs={12} className='mb-5'>
                   <p>REMAINING BALANCE DUE TO DISBURSEMENT</p>
                   <h5>Php 10,000.00</h5>
                 </Col> */}
            <Col xs={12} align='center'>
              <div className='flex-wrapper'>
                <Button variant='search' className='btn-payment' onClick={() => createDisbursement()}>
                  Save
                </Button>
                <Button variant='light' className='btn-payment' onClick={() => closeTheModal()}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>}

      {disbursementAmount === 0 &&
        <Modal.Body>
          <h1 style={{ color: 'red' }}>Patient is not eligible for disbursement!</h1>
        </Modal.Body>
      }
    </Modal>
  );
};

export default DisbursementModal;
