/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAllSpecialties = createAsyncThunk(
  'signup/getAllSpecialties',
  async (params = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/positions`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getSubSpecialties = createAsyncThunk(
  'signup/getSubSpecialties',
  async (params = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/positions`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getAllRoles = createAsyncThunk(
  'signup/getAllRoles',
  async (params = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/roles`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getAllDepartments = createAsyncThunk(
  'signup/getAllDepartments',
  async (params = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/departments`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const registerUser = createAsyncThunk(
  'signup/registerUser',
  async (params = {}, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: params,
      url: `api/register`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
