// import core & vendor packages below
import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import NursingPatientTabs from './components/Tabs';
import NursingHeader from './components/PageHeader';

// import assets below
import './style.scss';

// main component
const Nursing = () => {
  const [activeTab, setActiveTab] = useState('Search Patient');

  return (
    <Layout pageTitle='NursingPatient'>
      <NursingHeader active={activeTab} />
      <NursingPatientTabs onChange={setActiveTab} />
    </Layout>
  );
};

export default Nursing;
