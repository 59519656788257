/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup, Table } from 'react-bootstrap';

// import assets below
import './Modal.scss';
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const ModalPatientRoomInformation = ({ showProfile, setShowProfile }) => {
  const [userData, setUserData] = useState({
    case: '',
    remarks: '',
    hmo_name: '',
    date_time: '',
    payor_type: '',
    request_type: '',
    hmo_id_number: '',
    requesting_unit: '',
    price: 'Details Here',
    preferred_room_type: '',
    floor_unit: 'Details Here',
    selected_room: 'Details Here',
    floor_pricing_scheme: 'Details Here',
  });

  useEffect(() => {
    // console.log('data', userData);
  }, [userData]);

  const handleChange = (key) => (e) => {
    if (e.target.type === 'radio') {
      setUserData({
        ...userData,
        [key]: e.target.value,
      });

      return;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  // Submit data here
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    // console.log(userData);
  }, [userData]);

  return (
    <Modal
      size='lg'
      show={showProfile.isModalShow}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isModalShow: false,
          };
        })
      }
      aria-labelledby='Patient room information'
      className='patient-room-modal'
    >
      <Modal.Body>
        <div className='patient-room-request'>
          <div className='d-flex header'>
            <p>PATIENT ROOM INFORMATION</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowProfile((prevState) => {
                  return { ...prevState, isModalShow: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Request Type:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.request_type}
                    onChange={handleChange('request_type')}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Payor Type:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.payor_type}
                    onChange={handleChange('payor_type')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Preferred Room Type:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.preferred_room_type}
                    onChange={handleChange('preferred_room_type')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>HMO Name:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.hmo_name}
                    onChange={handleChange('hmo_name')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-2'>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Case:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.case}
                    onChange={handleChange('case')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>HMO ID Number:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.hmo_id_number}
                    onChange={handleChange('hmo_id_number')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-2'>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Admission / Transfer Date and Time:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.date_time}
                    onChange={handleChange('date_time')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Requesting Unit:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.requesting_unit}
                    onChange={handleChange('requesting_unit')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col>
                <Form.Group className='mb-2 line-group'>
                  <Form.Label>Remarks:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.remarks}
                    onChange={handleChange('remarks')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Col>
                <TableUI>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Selected Room</th>
                        <th>Floor / Unit</th>
                        <th>Floor Pricing Scheme</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{userData.selected_room}</td>
                        <td>{userData.floor_unit}</td>
                        <td>{userData.floor_pricing_scheme}</td>
                        <td>{userData.price}</td>
                      </tr>
                    </tbody>
                  </Table>
                </TableUI>
              </Col>
            </Row>

            <Row>
              <Col className='text-center'>
                <Button className='print-button'>Print</Button>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPatientRoomInformation;
