/* eslint-disable */
import './Table.scss';
import React, { useEffect, useState } from 'react';
import { Form, Table, Button, Pagination } from 'react-bootstrap';
// import Table from "../../../../../components/ReactTable";
// import moment from "moment/moment";
// import {calculateDayDiff, currentLocation, patientStatus} from "../../../../../helpers";
import { useDispatch } from 'react-redux';
import { getChartOfAccounts } from 'reducers/accountingReducer';
import { PrintContent } from './PrintContent';

const ChartOfAccountTable = (props) => {
    const { state, setShowModal, batchEditMode, batchEdit, setBatchEdit, printRef, filter } = props;
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerpage] = useState(10);

    console.log('test re-render');

    useEffect(() => {
        dispatch(getChartOfAccounts())
    }, [])

    const tableColumns = React.useMemo(
        () => [
            { Header: 'ID', accessor: 'id' }, // accessor is the "key" in the data
            { Header: 'NAME', accessor: 'name' },
            { Header: 'Account type', accessor: 'account_type' },
            { Header: 'Detail type', accessor: 'detail_type' },
            { Header: 'Debit balance', accessor: 'debit' },
            { Header: 'Credit Balance', accessor: 'credit' },
        ],
        []
    )

    const fieldStyles = {
        backgroundColor: !batchEditMode ? 'transparent' : '#fff',
        border: !batchEditMode ? 'none' : '1px solid #ced4da',
        borderWidth: !batchEditMode ? 0 : 1,
        borderColor: !batchEditMode ? 'transparent' : '#ced4da',
        // cursor: !batchEditMode ? 'default' : 'text',
    }

    const handleChange = (data) => (e) => {
        setBatchEdit((prevArr) => {
            const result = [...prevArr].map((account) =>
                account?.id === data?.id ? {...account, [e.target.id]: e.target.value} : account
            );
            // result[id] = {...data, [e.target.id]: e.target.value};
            console.log(result, 'result');
            return result;
        })
        /*  setBatchEdit((prev) => {
            prev.map((account) => 
                account?.id === data?.id ? {...account, data} : account
            )
        }) */
    }

    const setBatchEditModeInput = (inputId, inputValue, data) => {
        console.log('data', data);
        if (inputId === 'account_type' || inputId === 'detail_type') {
            return (
                <Form.Select
                    id={inputId}
                    value={inputValue}
                    className={!batchEditMode ? 'batch_edit_select disable_caret' : 'batch_edit_select'}
                    style={fieldStyles}
                    key={data?.id}
                    disabled={!batchEditMode}
                    onChange={handleChange(data)}
                >
                    <option value=''>-Select-</option>
                    <option value='Outpatient'>Outpatient</option>
                    <option value='Emergency'>Emergency</option>
                    <option value='Admitted'>Admitted</option>
                </Form.Select>
            )
        }
        
        return (
            <Form.Control
                id={inputId}
                value={inputValue}
                className='batch_edit_input'
                disabled={!batchEditMode}
                key={data?.id}
                style={fieldStyles}
                onChange={handleChange(data)}
            />
        )
    };

    const listToUse = batchEditMode ? batchEdit : state;

    const filterData = (list) => {
        const filteredData = list.filter((data) => {
            switch(filter?.type) {
                case 'name':
                    return list
                case 'account_type':
                    return data?.account_type === filter.filter
                case 'detail_type':
                    return data?.detail_type === filter.filter
                default:
                    return list
            }
        })
        console.log(filter, 'filter');
        return !filter ? list : filteredData;
    }

    /* const tableData = React.useMemo(
        () => listToUse?.map((item, index) => ({
            id: item?.id,
            name: setBatchEditModeInput('name', item?.name, index, item), // item?.name,
            account_type: setBatchEditModeInput('account_type', item?.account_type, index, item), // item?.account_type,
            detail_type: setBatchEditModeInput('detail_type', item?.detail_type, index, item), // item?.detail_type,
            debit: setBatchEditModeInput('debit', item?.debit, index, item), // item?.debit,
            credit: setBatchEditModeInput('credit', item?.credit, index, item) // item?.credit,
        })),
        [state, batchEditMode, batchEdit]
    ) */

    /*  const handleTableAction = (eventKey, id) => {
        const row = state.find(dd => dd.id === id);
        const action = {
            profile: () => { },
            view: () => { },
            edit: () => {
                setShowModal({
                    isShowForm: true,
                    accountId: row.id,
                })
            },
        }
        action[eventKey]()
    } */

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = filterData(listToUse).slice(indexOfFirstData, indexOfLastData);
    const pages = [];
    console.log(batchEdit, 'batchEdit');
    console.log(listToUse, 'listToUse');
    console.log(indexOfFirstData, 'indexOfFirstData');
    console.log(indexOfLastData, 'indexOfLastData');

    for (let index = 1; index <= Math.ceil(filterData(listToUse).length / dataPerPage); index++) {
        pages.push(index);
    }

    const paginate = (page) => {
        setCurrentPage(page)
    }

    return (
        <div className='chart-of-account-table'>
            <div style={{ display: 'none' }}>
                <PrintContent 
                    ref={printRef} 
                    tableData={filterData(listToUse)}
                    tableCol={tableColumns}
                />
            </div>
            {/* <Table
                data={tableData}
                columns={tableColumns}
                actions={{ hasEdit: true }}
                onAction={handleTableAction}
                pagination={['BOTTOM']}
            /> */}
            <Table striped bordered>
                <thead>
                    <tr>
                        {tableColumns.map((col) => (
                            <th>{col.Header}</th>
                        ))}
                        <th>ACTION</th>
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((account) => (
                        <tr>
                            <td>{account?.id}</td>
                            <td>{setBatchEditModeInput('name', account?.name, account)}</td>
                            <td>{setBatchEditModeInput('account_type', account?.account_type, account)}</td>
                            <td>{setBatchEditModeInput('detail_type', account?.detail_type, account)}</td>
                            <td>{setBatchEditModeInput('debit', account?.debit, account)}</td>
                            <td>{setBatchEditModeInput('credit', account?.credit, account)}</td>
                            <td>
                                <Button
                                    variant="outline-success" 
                                    className='edit_btn' 
                                    onClick={() => {
                                        setShowModal({
                                            isShowForm: true,
                                            accountId: account?.id,
                                        })
                                    }}
                                >
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className='page_controls'>
                <div className='result_count'>
                    {filterData(listToUse)?.length} Results               
                </div>
                <Pagination>
                    <Pagination.Prev 
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                    /> 
                    {pages.map((page) => (
                        <Pagination.Item active={currentPage === page}  key={page} onClick={() => paginate(page)}>
                            {page}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next 
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(filterData(listToUse).length / dataPerPage)}
                    />
                </Pagination>
            </div>
        </div>
    );
};

export default ChartOfAccountTable;
