/* eslint-disable */
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';

// import components below
import ProcedureModal from './ProcedureModal';
import Table from 'components/ReactTable';
import { calculateDayDiff, patientStatus } from 'helpers';
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import './Table.scss';

// main component
const DoctorTable = ({ state, setState }) => {
  const [showModal, setShowModal] = useState({
    id: '',
    isShowForm: false,
    procedure: null,
  });

  const refreshData = () => {
    axios
      .get('/requisition/procedure')
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Surgery Code', accessor: 'ctp_code' },
      { Header: 'Description', accessor: 'procedure' },
      { Header: 'Patient Name', accessor: 'patient' },
      { Header: 'Remarks', accessor: 'remarks' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.request_id,
        ctp_code: dd?.ctp_code,
        procedure: dd?.procedure,
        patient: dd?.patient,
        remarks: dd?.remarks,
        status: dd?.status,
      })).reverse(),
    [state],
  );

  const handleTableAction = (eventKey, request_id) => {
    const row = state.find((dd) => dd.request_id === request_id);
    const action = {
      profile: () => {},
      view: () => {
        setShowModal({
          isShowForm: true,
          procedure: row,
        });
      },
      edit: () => {},
    };
    action[eventKey]();
  };

  return (
    <div className='doctors-clearance-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasView: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />

      <ProcedureModal
        showProfile={showModal}
        setShowProfile={setShowModal}
        setState={setState}
        refreshData={refreshData}
      />
    </div>
  );
};

DoctorTable.defaultProps = {
  state: [],
  setState: () => {},
  setshowModal: () => {},
};

DoctorTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setshowModal: PropTypes.func,
};

export default DoctorTable;
