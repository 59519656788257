/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-max-props-per-line */

import axios from 'axios';
import moment from 'moment';
import Constants from 'constant';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Table, InputGroup, FormControl, Button, Form, Col } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';
import LayoutReport from 'components/LayoutReport';

import './style.scss';

const Revenues = () => {
  const input = useParams();

  const [payload, setPayload] = useState({
    type: 'revenues',
    amountfromdoh: '',
    amountfromlgu: '',
    amountfromdonor: '',
    amountfromprivateorg: '',
    amountfromphilhealth: '',
    amountfrompatient: '',
    amountfromreimbursement: '',
    amountfromothersources: '',
    grandtotal: '',
    prepared_by: '',
    designation: '',
    approved_by: '',
    date: '',
    reportingyear: moment().format('YYYY'),
    year: input.year,
  });

  const handleChange = (key) => (e) => {
    setPayload({
      ...payload,
      [key]: e.target.value,
    });
  };

  const generate = () => {
    axios
      .post('/report/revenues', payload)
      .then((response) => {
        toast.success('Success!');

        setTimeout(() => {
          window.history.back();
        }, 500);
      })
      .catch((error) => {
        toast.danger('ERROR!');
      });
  };

  const [revenues, setRevenues] = useState(null);

  useEffect(() => {
    if (typeof input.id === 'string') {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/report/revenues/${input.id}`)
        .then((response) => {
          console.log('response', response);
          setRevenues(response.data.data);
        }, []);
    }
  }, []);

  useEffect(() => {
    if (revenues) {
      const { report_revenues } = revenues;
      const data = report_revenues[0];
      const total =
        +data?.amountfromdoh +
        +data?.amountfromlgu +
        +data?.amountfromdonor +
        +data?.amountfrompatient +
        +data?.amountfromprivateorg +
        +data?.amountfromphilhealth +
        +data?.amountfromothersources +
        +data?.amountfromreimbursement;

      setPayload((prev) => {
        return {
          ...prev,
          date: data?.date,
          grandtotal: total,
          prepared_by: data?.prepared_by,
          designation: data?.designation,
          approved_by: data?.approved_by,
          amountfromdoh: data?.amountfromdoh,
          amountfromlgu: data?.amountfromlgu,
          amountfromdonor: data?.amountfromdonor,
          amountfrompatient: data?.amountfrompatient,
          amountfromprivateorg: data?.amountfromprivateorg,
          amountfromphilhealth: data?.amountfromphilhealth,
          amountfromothersources: data?.amountfromothersources,
          amountfromreimbursement: data?.amountfromreimbursement,
        };
      });
    }
  }, [revenues]);

  useEffect(() => {
    if (payload) {
      const total =
        +payload?.amountfromdoh +
        +payload?.amountfromlgu +
        +payload?.amountfromdonor +
        +payload?.amountfrompatient +
        +payload?.amountfromprivateorg +
        +payload?.amountfromphilhealth +
        +payload?.amountfromothersources +
        +payload?.amountfromreimbursement;

      setPayload((prev) => {
        if (total !== prev?.grandtotal) {
          return {
            ...prev,
            grandtotal: total,
          };
        }

        return prev;
      });
    }
  }, [payload]);

  const updateDoh = async (id) => {
    const params = { type: Constants.REVENUES };
    axios
      .put(`/report/doh/${id}`, params)
      .then((response) => {
        toast.success('Success!');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const generate = () => {
  //     axios.post(`${process.env.REACT_APP_API_BASE_URL}/report/create-pdf`, params)
  //         .then((response) => {
  //             axios.get(`${process.env.REACT_APP_API_BASE_URL}/report/fetch-pdf`, { params: { file_name: response.data }, responseType: 'blob' })
  //                 .then((res) => {
  //                     const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

  //                     saveAs(pdfBlob, 'Revenues.pdf');
  //                 });
  //         });
  // };

  return (
    <LayoutReport pageTitle='Revenues'>
      <row className='operation-report'>
        <Row className='header'>
          <h1>Revenues</h1>
        </Row>
        <Container>
          <Row className='body'>
            <Row className='mb-3 mt-3'>
              <label className='fw-bold'>
                Please report the total revenue this facility collected last year. This includes all
                monetary resources acquired by this facility from all sources, and for all purposes.
              </label>
              <div className='mt-3'>
                <TableUI>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Revenues</th>
                        <th>Amount in Pesos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Total amount of money received from the Department of Health</td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              value={payload?.amountfromdoh}
                              onChange={handleChange('amountfromdoh')}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>Total amount of money received from the local government</td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              value={payload?.amountfromlgu}
                              onChange={handleChange('amountfromlgu')}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Total amount of money received from donor agencies (for example JICA,
                          USAID, and others)
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              value={payload?.amountfromdonor}
                              onChange={handleChange('amountfromdonor')}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Total amount of money received from private organizations (donations from
                          businesses, NGOs, etc.)
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              value={payload?.amountfromprivateorg}
                              onChange={handleChange('amountfromprivateorg')}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>Total amount of money received from Phil Health</td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              value={payload?.amountfromphilhealth}
                              onChange={handleChange('amountfromphilhealth')}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Total amount of money received from direct patient/out-of-pocket
                          charges/fees
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              value={payload?.amountfrompatient}
                              onChange={handleChange('amountfrompatient')}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Total amount of money received from reimbursement from private
                          insurance/HMOs
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              value={payload?.amountfromreimbursement}
                              onChange={handleChange('amountfromreimbursement')}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Total amount of money received from other sources (PAGCOR, PCSO, etc.)
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              value={payload?.amountfromothersources}
                              onChange={handleChange('amountfromothersources')}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>TOTAL Revenue</b>
                        </td>
                        <td>
                          <InputGroup className='mb-1 mt-1'>
                            <FormControl
                              type='number'
                              placeholder='Enter Here'
                              value={payload?.grandtotal}
                              onChange={handleChange('grandtotal')}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </InputGroup>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <label className='fw-bold'>
                    If donation is in kind, please put equivalent amount in peso.
                  </label>
                  <Row>
                    <Col lg={4}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>Report Prepared By:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={handleChange('prepared_by')}
                          value={revenues && revenues?.report_revenues[0]?.prepared_by}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={4}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>Designation/Section/Department:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={handleChange('designation')}
                          value={revenues && revenues?.report_revenues[0]?.designation}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={4}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>Report Approved and Certified By:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={handleChange('approved_by')}
                          value={revenues && revenues?.report_revenues[0]?.approved_by}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={4}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>Date:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='date'
                          placeholder='Enter here'
                          onChange={handleChange('date')}
                          value={revenues && revenues?.report_revenues[0]?.date}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </TableUI>
                <div className='d-flex justify-content-end'>
                  {typeof input.type !== 'string' ? (
                    <Button className='btn-light-green' onClick={generate}>
                      Save Report
                    </Button>
                  ) : (
                    ''
                  )}

                  {typeof input.type === 'string' ? (
                    <Button
                      className='btn-light-green'
                      onClick={(e) => {
                        updateDoh(revenues?.id);
                      }}
                    >
                      Update DOH
                    </Button>
                  ) : (
                    ''
                  )}
                  <Link to='/dashboard/reports'>
                    <Button variant='secondary ms-4'>Cancel</Button>
                  </Link>
                </div>
              </div>
            </Row>
          </Row>
        </Container>
      </row>
    </LayoutReport>
  );
};

export default Revenues;
