/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import BackUpModal from './components/Modal';

// import assets below
import './index.scss';
import { ReactComponent as DriveSVG } from 'assets/svg/check-to-slot.svg';

// main component
const state = [
  {
    backup: 'Details Here',
    filename: 'File name 1',
  },
  {
    backup: 'Details Here',
    filename: 'File name 2',
  },
];

const index = ({ setShowScreen, setRequestData }) => {
  const [openModal, setOpenModal] = useState();
  const [modalType, setModalType] = useState();

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Back Up', accessor: 'backup' },
      { Header: 'File', accessor: 'filename' },
    ],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        backup: dd?.backup,
        filename: dd?.filename,
        data: dd,
      })),
    [state],
  );

  return (
    <div className='credentials-credits'>
      <div className='header'>
        <h2 className='black'>Where did you safe the backup file? </h2>
        
        <div className='functions'>

          <Button 
            className='upload' 
            onClick={() => {
                setOpenModal({
                  isOpen: true,
                });
              }
            }
          >
            <DriveSVG />
            &nbsp; Restore
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
        />
      </div>
      <BackUpModal modalType={modalType} openModal={openModal} setOpenModal={setOpenModal} />
   
      </div>
  );
};

export default index;
