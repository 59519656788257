// import assets below
import './style.scss';
import { ReactComponent as SpinnerSVG } from 'assets/svg/Rolling-1s-200px.svg';

// main component
const LoadingScreen = () => {
  return (
    <div className='loadingScreen'>
      <SpinnerSVG />
    </div>
  );
};

export default LoadingScreen;
