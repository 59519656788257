/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal, Accordion, Table } from 'react-bootstrap';

import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import seturlParams from 'helpers/seturlParams';
import TablePagination from './TablePagination';
import ConditionalRender from 'components/ConditionalRender';
import {
  getItems,
  updateRequestItem,
  getSingleRequestItem,
  selectDepartmentInventory,
} from 'reducers/departmentInventoryReducer';
import { selectInventory } from 'reducers/inventoryReducer';

// Import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

const ViewModal = ({ showModal, setShowModal, getInventoryRequestList, departmentID }) => {
  // Redux
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectDepartmentInventory);
  const { singleRequestData, singleRequestItems, singleRequestLogs } = inventoryState;

  const groupState = useSelector(selectInventory);
  const { inventoryGroupData, inventoryGroupItemsLogs } = groupState;

  // States
  const [finalItem, setFinalItem] = useState([]);
  const [groupList, setGroupList] = useState([]);

  // Get group name from included api
  const getGroupName = (id) => {
    if (!groupList) return;

    const selectedArray = groupList.find((data) => parseInt(data.id) === parseInt(id));

    return selectedArray?.attributes?.name || 'N/A';
  };

  const closeModal = () => {
    setShowModal((prevState) => {
      return {
        ...prevState,
        view: false,
      };
    });
  };

  getSingleRequestResult(setFinalItem, singleRequestItems, singleRequestLogs);

  getRowData(getSingleRequestItem, showModal, dispatch);

  setDataToGroupList(setGroupList, inventoryGroupData, inventoryGroupItemsLogs);

  return (
    <Modal
      size='lg'
      onHide={closeModal}
      show={showModal?.view}
      aria-labelledby='Request Item Modal'
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>VIEW REQUEST ITEM</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={closeModal} />
          </div>
        </div>

        <FormUI>
          <Form className='add-item-form'>
            <Row>
              <Col>
                <div className='search-item'>
                  <Accordion className='advanced-search' defaultActiveKey={'0'}>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Request Details</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col className='request-details'>
                            <Form.Group className='inline-group'>
                              <Form.Label>Request Date/Time :</Form.Label>
                              <FormControl
                                className='no-border'
                                value={singleRequestData?.attributes?.requested_at}
                              />
                            </Form.Group>

                            <Form.Group className='inline-group'>
                              <Form.Label>Requested By :</Form.Label>
                              <FormControl
                                className='no-border'
                                value={singleRequestData?.attributes?.requested_by}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <Accordion className='advanced-search' defaultActiveKey={'0'}>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Items</Accordion.Header>
                    <Accordion.Body>
                      <div className='search-table mb-4'>
                        <TableUI>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Item Code</th>
                                <th>Item Name</th>
                                <th>Item Group</th>
                                <th>Storage Bin</th>
                                <th>Quantity</th>
                                <th>UOM</th>
                              </tr>
                            </thead>

                            <tbody>
                              <ConditionalRender
                                condition={finalItem.length > 0}
                                renderIf={
                                  <>
                                    {finalItem.map((data, idx) => {
                                      const { attributes } = data.data;
                                      return (
                                        <tr key={idx}>
                                          <td>{attributes?.code}</td>
                                          <td>{attributes?.name}</td>
                                          <td>{getGroupName(data?.id)}</td>
                                          <td>{attributes?.storage_bin}</td>
                                          <td>{data?.quantity}</td>
                                          <td>{data?.unit}</td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                }
                              />
                            </tbody>
                          </Table>
                        </TableUI>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button onClick={closeModal} className='clear-button' variant='secondary'>
                    Close
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const getSingleRequestResult = (setFinalItem, singleRequestItems, singleRequestLogs) => {
  useEffect(() => {
    const { isLoading, isFailed, isSuccess, error } = singleRequestLogs;

    if (isFailed) {
      toast.error(`${error[0]?.detail} (${error[0]?.code})`);
      console.log(error);
    }

    if (isSuccess) {
      const reStockItems = singleRequestItems.filter((data) => data?.type === 'restock_items');
      const items = singleRequestItems.filter((data) => data?.type === 'items');

      const userFinalItems = reStockItems.map((data) => {
        const id = data?.relationships?.item?.data[0]?.id;
        const selectedItem = items.filter((data) => data?.id === id);

        return {
          id: id,
          data: selectedItem[0],
          quantity: data?.attributes?.quantity,
          unit: data?.attributes?.unit_of_measure,
        };
      });

      setFinalItem(userFinalItems);
    }
  }, [singleRequestLogs]);
};

const getRowData = (getSingleRequestItem, showModal, dispatch) => {
  useEffect(() => {
    const { data } = showModal;

    if (data) {
      dispatch(getSingleRequestItem(data?.id));
    }
  }, [showModal]);
};

const setDataToGroupList = (setGroupList, inventoryGroupData, inventoryGroupItemsLogs) => {
  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = inventoryGroupItemsLogs;

    if (isSuccess) {
      setGroupList(inventoryGroupData);
    }
  }, [inventoryGroupItemsLogs]);
};

export default ViewModal;
