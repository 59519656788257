/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPatientsWithRooms = createAsyncThunk(
  'department/getPatientsWithRooms',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `patient`,
      params: {
        patient_status: ['admitted', 'for_discharge'],
      },
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getPatientRoom = createAsyncThunk(
  'department/getPatientRoom',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `patient/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getAvailableRooms = createAsyncThunk(
  'department/getAvailableRooms',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `room`,
      params: {
        status: ['available'],
      },
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const submitTransferRoom = createAsyncThunk(
  'department/submitTransferRoom',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `room_request`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
