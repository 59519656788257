import React from 'react';
import { Table } from 'react-bootstrap';

function TableAdjustment({ adjustment, totalAdjustmentBill }) {
  return (
    <>
      <Table className='mt-4' responsive>
        <thead>
          <tr>
            <th style={{ borderBottom: 'none' }}>Adjustments</th>
            <th style={{ borderBottom: 'none' }}>Total Adjusted</th>
          </tr>
        </thead>
        <tbody>
          {adjustment
            ? adjustment.map((data, idx) => {
                return (
                  <tr key={idx}>
                    <td>{data?.name_of_payor}</td>
                    <td>{data?.amount}</td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>

      <Table responsive>
        <thead>
          <tr>
            <th style={{ background: '#D5D5D5', borderBottom: 'none' }}>TOTAL ADJUSTMENT</th>
            <th style={{ background: '#A5A5A5', borderBottom: 'none' }}>
              Php {totalAdjustmentBill}
            </th>
          </tr>
        </thead>
      </Table>
    </>
  );
}

export default TableAdjustment;
