// Packages
import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';

// Components
import Layout from 'components/Layout';
import BillingTabs from '../Billing/components/Tabs';
import BillingHeader from '../Billing/components/PageHeader';

// Assets
import './style.scss';

// Main Component
const Billing = () => {
  const [activeTab, setActiveTab] = useState('Billing Worklist');

  return (
    <Layout pageTitle='Billing'>
      <BillingHeader active={activeTab} />

      <Container fluid>
        <Row>
          <BillingTabs onChange={setActiveTab} />
        </Row>
      </Container>
    </Layout>
  );
};

export default Billing;
