// Packages
import { useState } from 'react';

// main components below
import DoctorFilter from './components/Filter';
import DoctorTable from './components/Table';
import ViewDoctorProfile from 'pages/Doctors/ViewProfile';

// Nursing Components
import DoctorsFee from './components/DoctorsFee';
import ModalLabRequest from 'pages/Nursing/components/SearchPatient/PatientWorklist/LabRequest';
import ModalProcedure from 'pages/Nursing/components/SearchPatient/PatientWorklist/Procedure';
import ModalMedication from 'pages/Nursing/components/SearchPatient/PatientWorklist/Medication';
import ModalAddDiagnosis from 'pages/Nursing/components/Diagnosis/AddDiagnosis';

// main component
const Clearance = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    note: false,
    supply: false,
    addOrder: false,
    profileId: null,
    procedure: false,
    medication: false,
    labRequest: false,
    isShowProfile: false,
    confirm: false,
    showDoctorFee: false,
  });

  return (
    <>
      <div className='searchPatient'>
        {!showProfile.isShowProfile ? (
          <>
            <DoctorFilter state={state} setState={setState} setShowProfile={setShowProfile} />

            <DoctorTable state={state} setState={setState} setShowProfile={setShowProfile} />
          </>
        ) : (
          <ViewDoctorProfile setShowProfile={setShowProfile} showProfile={showProfile} />
        )}
      </div>

      <ModalProcedure setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalLabRequest setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalMedication setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalAddDiagnosis setShowProfile={setShowProfile} showProfile={showProfile} />
      <DoctorsFee setShowProfile={setShowProfile} showProfile={showProfile} />
    </>
  );
};

export default Clearance;
