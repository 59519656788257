/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  updateReturnItems,
  submitReturnItems,
  getReturnTableList,
  completeReturnItem,
  getSingleReturnList,
  deleteRequestReturn,
  getDepartmentInventory,
} from './thunks/returnThunks';

const initialLogs = {
  error: null,
  isFailed: false,
  isSuccess: false,
  isLoading: false,
};

const initialState = {
  data: [],
  items: [],
  meta: null,
  logs: initialLogs,
  // For Submit Return Logs
  submitReturnLogs: initialLogs,
  // For Return Data
  returnData: [],
  returnItems: [],
  returnLogs: initialLogs,
  // For Single Data
  singleData: [],
  singleItems: [],
  singleLogs: initialLogs,
  // For Complete return
  completeLogs: initialLogs,
  // For Update Return
  updateReturnLogs: initialLogs,
  // For Delete Logs
  deleteLogs: initialLogs,
};

const slice = createSlice({
  name: 'return',
  initialState,
  reducers: {
    resetLogState: (state) => {
      state.logs = initialLogs;
      state.returnLogs = initialLogs;
      state.singleLogs = initialLogs;
      state.completeLogs = initialLogs;
      state.submitReturnLogs = initialLogs;
    },
  },
  extraReducers: {
    // For Get Inventory Items
    [getDepartmentInventory.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.meta = action.payload.meta;
      state.data = action.payload.data;
      state.items = action.payload.included;
    },
    [getDepartmentInventory.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getDepartmentInventory.rejected]: (state) => {
      state.data = [];
      state.items = [];
      state.logs.isFailed = true;
      state.logs.isLoading = false;
    },

    // For submit delete item
    [deleteRequestReturn.fulfilled]: (state, action) => {
      state.deleteLogs.isSuccess = true;
      state.deleteLogs.isLoading = false;
    },
    [deleteRequestReturn.pending]: (state) => {
      state.deleteLogs.error = '';
      state.deleteLogs.isFailed = false;
      state.deleteLogs.isLoading = true;
      state.deleteLogs.isSuccess = false;
    },
    [deleteRequestReturn.rejected]: (state, action) => {
      state.deleteLogs.error = action.payload.errors;
      state.deleteLogs.isFailed = true;
      state.deleteLogs.isLoading = false;
    },

    // For submit return items
    [submitReturnItems.fulfilled]: (state, action) => {
      state.submitReturnLogs.isSuccess = true;
      state.submitReturnLogs.isLoading = false;
    },
    [submitReturnItems.pending]: (state) => {
      state.submitReturnLogs.error = '';
      state.submitReturnLogs.isFailed = false;
      state.submitReturnLogs.isLoading = true;
      state.submitReturnLogs.isSuccess = false;
    },
    [submitReturnItems.rejected]: (state, action) => {
      state.submitReturnLogs.error = action.payload.errors;
      state.submitReturnLogs.isFailed = true;
      state.submitReturnLogs.isLoading = false;
    },

    // For submit Update  return items
    [updateReturnItems.fulfilled]: (state, action) => {
      state.updateReturnLogs.isSuccess = true;
      state.updateReturnLogs.isLoading = false;
    },
    [updateReturnItems.pending]: (state) => {
      state.updateReturnLogs.error = '';
      state.updateReturnLogs.isFailed = false;
      state.updateReturnLogs.isLoading = true;
      state.updateReturnLogs.isSuccess = false;
    },
    [updateReturnItems.rejected]: (state, action) => {
      state.updateReturnLogs.error = action.payload.errors;
      state.updateReturnLogs.isFailed = true;
      state.updateReturnLogs.isLoading = false;
    },

    // For Get Return Items
    [getReturnTableList.fulfilled]: (state, action) => {
      state.returnLogs.isSuccess = true;
      state.returnLogs.isLoading = false;
      state.returnData = action.payload.data;
      state.returnItems = action.payload.included;
    },
    [getReturnTableList.pending]: (state, action) => {
      state.returnLogs.error = '';
      state.returnLogs.isFailed = false;
      state.returnLogs.isLoading = true;
      state.returnLogs.isSuccess = false;
    },
    [getReturnTableList.rejected]: (state, action) => {
      state.returnData = [];
      state.returnItems = [];
      state.returnLogs.isFailed = true;
      state.returnLogs.isLoading = false;
    },

    [getSingleReturnList.fulfilled]: (state, action) => {
      state.singleLogs.isSuccess = true;
      state.singleLogs.isLoading = false;
      state.singleData = action.payload.data;
      state.singleItems = action.payload.included;
    },
    [getSingleReturnList.pending]: (state, action) => {
      state.singleLogs.error = '';
      state.singleLogs.isFailed = false;
      state.singleLogs.isLoading = true;
      state.singleLogs.isSuccess = false;
    },
    [getSingleReturnList.rejected]: (state, action) => {
      state.singleData = [];
      state.singleItems = [];
      state.singleLogs.isFailed = true;
      state.singleLogs.isLoading = false;
    },
    // For Complete return item
    [completeReturnItem.fulfilled]: (state, action) => {
      state.completeLogs.isSuccess = true;
      state.completeLogs.isLoading = false;
    },
    [completeReturnItem.pending]: (state) => {
      state.completeLogs.error = '';
      state.completeLogs.isFailed = false;
      state.completeLogs.isLoading = true;
      state.completeLogs.isSuccess = false;
    },
    [completeReturnItem.rejected]: (state) => {
      state.completeLogs.isFailed = true;
      state.completeLogs.isLoading = false;
    },
  },
});

export const { actions: returnActions, reducer: returnReducer } = slice;

export const { resetLogState } = returnActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.return || initialState;

export const selectReturn = createSelector([selectDomain], (inventoryState) => inventoryState);

/* --------------------------------------------------------------------------------- */

export {
  updateReturnItems,
  submitReturnItems,
  completeReturnItem,
  getReturnTableList,
  getSingleReturnList,
  deleteRequestReturn,
  getDepartmentInventory,
};
