import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

// import components below
import Layout from 'components/Layout';
import Header from './components/Header';
import UserAccounts from './components/UserAccounts';
import SideBar from 'components/ACLComponents/SideBar';

// main component
const PatientCareManagement = () => {
  const location = useLocation();

  const isAllUsers = location.pathname === '/dashboard/acl/patient-care-management';
  const isNewUsers = location.pathname === '/dashboard/acl/patient-care-management/new-users';
  const isDeniedUsers = location.pathname === '/dashboard/acl/patient-care-management/denied-users';

  return (
    <Layout pageTitle='Patient Care Management New Users'>
      <Header />

      <Row className='mt-4'>
        <Col sm={9}>
          <UserAccounts isDeniedUsers={isDeniedUsers} isAllUsers={isAllUsers} isNewUsers={isNewUsers} />
        </Col>
        <Col sm={3}>
          <SideBar />
        </Col>
      </Row>
    </Layout>
  );
};

export default PatientCareManagement;
