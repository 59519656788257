/* eslint-disable no-unused-vars */
// import components below
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const RoomBedManagementHeader = ({ active }) => {

  return (
    <div className='room-bed-management-header'>
      <PageHeaderUI>
        <h4>Room & Bed Management Module</h4>
        <p>Dashboard / Room and Bed Management / {active}</p>
      </PageHeaderUI>
    </div>
  );
};

RoomBedManagementHeader.defaultProps = {
  active: '',
};

RoomBedManagementHeader.propTypes = {
  active: PropTypes.string,
};

export default RoomBedManagementHeader;
