/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/ReactTable';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';

// import components below
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';
import { getAllSpecialties } from 'reducers/thunks/signupThunks';
import { selectSignup } from '../../../../reducers/signupReducer';

const selectStyles = {
  control: (styles) => ({
    ...styles,
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
    width: '300px',
    height: '40px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};
// main component
const AddDoctorTable = ({ setShowModal, state, setState }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(selectSignup);

  const [filterValue, setFilterValue] = useState([]);

  const getSpecialties = () => {
    dispatch(getAllSpecialties({ per_page: 100000 /** , is_sub_position: false */ }));
  };

  useEffect(() => {
    getSpecialties();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_ACL}/api/users?roles[]=Doctor`)
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const onFilterSpecialty = (value) => {
    setFilterValue(value);
  };

  /*  const filteredData = React.useMemo(() => {
    return state.filter((item) => {
      return filterValue ? item.attributes?.specialty_id.toString() === filterValue?.id : true;
    });
  }, [state, filterValue]);*/

  const filteredData = state.filter((item) =>
    filterValue?.id ? item.attributes?.specialty_id.toString() === filterValue?.id : true,
  );

  const tableColumns = React.useMemo(
    () => [
      { Header: "Physician's ID", accessor: 'id' }, // accessor is the "key" in the data
      { Header: "Physician's Name", accessor: 'physicians_name' }, // accessor is the "key" in the data
      { Header: 'Specialty / Sub-Specialty', accessor: 'specialty_sub_specialty' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      filteredData?.map((dd) => ({
        id: dd?.id,
        physicians_name: dd?.attributes?.full_name,
        specialty_sub_specialty: dd?.attributes?.specialty_id[0],
        status: (
          <span
            className={`${
              dd?.attributes?.employment?.employment_status === 'Active' ? `bg-success` : `bg-info`
            } `}
          >
            {dd?.attributes?.employment?.employment_status}
          </span>
        ),
      })),
    [filteredData],
  );

  // const handleTableAction = (eventKey, id) => {
  //   const row = state.find(dd => dd.id === id);
  //   const action = {
  //     profile: () => { },
  //     view: () => { },
  //     edit: () => { },
  //     delete: () => { }
  //   }

  //   action[eventKey]()
  // }

  return (
    <div className='nursing-add-doctor-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        // actions={{ hasView: true }}
        // onAction={handleTableAction}
        customAction={(id) => (
          <span
            onClick={() => {
              setShowModal((prevState) => {
                return {
                  ...prevState,
                  isAddDoctor: true,
                  profileData: state.find((dd) => dd.id === id),
                  doctorId: id,
                };
              });
            }}
            className='select-button'
          >
            Select
          </span>
        )}
        filter={{
          top: (
            <div className='buttons'>
              <Button className='active'>All Doctors</Button>
              <Form.Group className='specialty'>
                <Select
                  isSearchable={false}
                  placeholder='Specialty'
                  styles={selectStyles}
                  onChange={(val) => onFilterSpecialty(val)}
                  options={data?.map((data) => {
                    return {
                      // data: data,
                      id: data?.id,
                      // label: `${data?.attributes?.position} (${data?.id})`,
                      label: `${data?.attributes?.description}`,
                      value: data?.id,
                    };
                  })}
                />
              </Form.Group>
            </div>
          ),
        }}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

AddDoctorTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowModal: () => {},
};

AddDoctorTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowModal: PropTypes.func,
};

export default AddDoctorTable;
