/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal, Accordion, Table } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import ConditionalRender from 'components/ConditionalRender';
import { deleteRequestItem, selectDepartmentInventory } from 'reducers/departmentInventoryReducer';

// import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main componet
const RemoveItemModal = ({ showModal, setShowModal, getInventoryRequestList }) => {
  // Redux
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectDepartmentInventory);
  const { deleteRequestItemLogs } = inventoryState;

  const [formData, setFormData] = useState({
    remarks: '',
  });
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleDelete = () => {
    const { id } = showModal.data;
    const params = {
      id: id,
      payload: {
        remarks: formData?.remarks,
      },
    };
    dispatch(deleteRequestItem(params));
  };

  const closeModal = () => {
    setConfirmDelete(false);
    setShowModal((prevState) => {
      return {
        ...prevState,
        confirmDelete: false,
      };
    });
  };

  useEffect(() => {
    const { isLoading, isFailed, isSuccess, error } = deleteRequestItemLogs;

    if (isFailed) {
      toast.error(`${error[0]?.detail} (${error[0]?.code})`);
      console.log(error);
    }

    if (isSuccess) {
      setShowModal((prevState) => {
        return {
          ...prevState,
          confirmDelete: false,
          data: null,
        };
      });

      setFormData({
        remarks: '',
      });

      getInventoryRequestList();
      toast.success('Successfully deleted request item!');
    }
  }, [deleteRequestItemLogs]);

  return (
    <Modal
      show={showModal?.confirmDelete}
      onHide={() => {
        closeModal();
      }}
      aria-labelledby='Request Item Modal'
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>CONFIRMATION</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='remove-request-form'>
            <Row>
              <Col>
                <h3 className='mb-4'>Are you sure you want to REMOVE this item?</h3>

                <Form.Group className='buttons mb-3'>
                  <Button
                    onClick={() => {
                      setConfirmDelete(true);
                    }}
                    className='remove-button'
                  >
                    Remove
                  </Button>
                  <Button
                    onClick={() => {
                      closeModal();
                    }}
                    className='cancel-button'
                  >
                    Cancel
                  </Button>
                </Form.Group>

                <ConditionalRender
                  condition={confirmDelete === true}
                  renderIf={
                    <>
                      <Form.Group className='mb-3'>
                        <Form.Label>Remarks</Form.Label>
                        <FormControl
                          value={formData?.remarks}
                          onChange={handleChange('remarks')}
                          placeholder='Enter here'
                        />
                      </Form.Group>

                      <Form.Group className='buttons'>
                        <Button
                          disabled={deleteRequestItemLogs?.isLoading}
                          onClick={handleDelete}
                          className='submit-button'
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </>
                  }
                />
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveItemModal;
