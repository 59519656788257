/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Form, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { 
  addGenericCredentialItems, 
  addWindowCredentialItems,
  selectCredentialsManagement,
  showGenericCredentialItems,
  selectCredentialsManagementData,
  updateGenericCredential,
  resetUpdateState,
  getGenericCredentialList,
  resetAddItemState,
} from 'reducers/credentialsManagementReducer';

// import assets below
import './index.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const CredentialsCreditsModal = (props) => {
  const { 
    openModal, 
    setOpenModal, 
  } = props;
  if (!openModal?.rowData) return;
  
  const result = useSelector(selectCredentialsManagement);
  const { updateGenericCredentialLogs, updateGenericCredentialData } = result;
  const groupState = useSelector(selectCredentialsManagementData);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  console.log(groupState, 'groupStates');
  console.log(getGenericCredentialList, 'getGenericCredentialList');
 
  const closeModal = () => {
    setOpenModal((prevState) => {
      return {
        ...prevState,
        isOpen:false,
      };
    });
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  console.log('sdsd ', formData);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      id: formData?.id,
      payload: {
        internet: formData?.internet,
        username: formData?.username,
        password: formData?.password,
      },
    };
    dispatch(updateGenericCredential(params));
  };
  runOnFirstRender(dispatch);

  getDataFromRowData(setFormData, openModal, groupState);

  runAfterSubmit(
    dispatch,
    openModal,
    updateGenericCredentialLogs,
    setOpenModal,
    updateGenericCredentialData,
  );

  return (
    <Modal
      size='lg'
      centered
      onHide={closeModal}
      show={openModal?.isOpen}
      className='credentials-credits'
    >
      <Form
        onSubmit={handleSubmit}
      >    
        <Modal.Header>
        <h6>Edit Generic Credentials</h6>
        <Button onClick={closeModal}>
          <CloseSVG />
        </Button>
        </Modal.Header> 
          <Modal.Body>
            <div className='main-content'>
              <h5>Add a Website Address and your Credential Information</h5>
              <Form.Group className='vertical-align'>
                <Form.Label>Internet or Network Address</Form.Label>
                <FormControl
                  name='internet' 
                  type='text' 
                  placeholder='' 
                  className='border-seven'
                  value={formData?.internet} 
                  onChange={handleChange}
                />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className='vertical-align'>
                    <Form.Label>Username</Form.Label>
                    <FormControl
                      name='username' 
                      onChange={handleChange}
                      placeholder='' 
                      className='border-seven' 
                      value={formData?.username} 
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='vertical-align'>
                    <Form.Label>Password</Form.Label>
                    <FormControl
                      name='password' 
                      onChange={handleChange}
                      placeholder='' 
                      className='border-seven' 
                      value={formData?.password || ''} 
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        <Modal.Footer>
          <Button 
            disabled={updateGenericCredentialLogs?.isLoading === true} 
            type='submit' 
            className='save'
          >
            Save
          </Button>
          <Button 
            onClick={closeModal} 
            className='cancel'
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
const runOnFirstRender = (dispatch) => {
  useEffect(() => {
    dispatch(getGenericCredentialList());
  }, []);
};

const getDataFromRowData = (setFormData, openModal, groupState) => {
  useEffect(() => {
    if (openModal?.rowData && openModal?.isOpen && groupState) {
      setFormData({
        ...openModal?.rowData,
      });
    }
  }, [openModal, groupState]);
  console.log(openModal, 'openmodal1');
};
const runAfterSubmit = (
  dispatch,
  openModal,
  updateGenericCredentialLogs,
  setOpenModal,
) => {
  useEffect(() => {
    const { isSuccess, isFailed, error } = updateGenericCredentialLogs;
    if (isSuccess && openModal?.isOpen) {
      toast.success('Successfully updated!');
      
      setTimeout(() => {
        setOpenModal((prev) => {
          return {
            ...prev,
            isOpen: false,
            userId: null,
            rowData: [],
          };
        });
      }, 200);

      dispatch(resetUpdateState());
      dispatch(getGenericCredentialList());
    }

    if (isFailed) {
      toast.error(error);
      dispatch(resetUpdateState());
    }
  }, [updateGenericCredentialLogs]);
  console.log(updateGenericCredentialLogs, 'updateItemLogs1');
};
export default CredentialsCreditsModal;
