/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getAllEmployees,
  createEmployee,
  updateEmployee,
  deleteEmployeeById,
} from './thunks/employeesThunks';
import _ from 'lodash';

const initialState = {
  data: [],
  isLoading: true,
};

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllEmployees.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllEmployees.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [getAllEmployees.rejected]: (state) => {
      state.data = [];
      state.isLoading = false;
    },
    /* ------------------------------------------- */
    [createEmployee.pending]: (state) => {
      state.isLoading = true;
    },
    [createEmployee.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [createEmployee.rejected]: (state) => {
      state.isLoading = false;
    },
    /* ------------------------------------------- */
    [updateEmployee.pending]: (state) => {
      state.isLoading = true;
    },
    [updateEmployee.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
      /*if (action.payload.status.toLowerCase() === 'success') {
        let copyData = _.cloneDeep(state.data);
        const idx = copyData.findIndex((e) => e.id === action.payload.id);

        if (idx !== -1) {
          copyData[idx] = {
            ...copyData[idx],
            ...action.payload.data,
          };
          state.data = copyData;
        }
      }
      state.isLoading = false;*/
    },
    [updateEmployee.rejected]: (state) => {
      state.isLoading = false;
    },
    /* ------------------------------------------- */
    [deleteEmployeeById.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteEmployeeById.fulfilled]: (state, action) => {
      if (action.payload.status.toLowerCase() === 'success') {
        const copyData = _.cloneDeep(state.data);
        const newData = copyData.filter((e) => e.id !== action.payload.id);
        state.data = newData;
      }
      state.isLoading = false;
    },
    [deleteEmployeeById.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { actions: employeesActions, reducer: employeesReducer } = slice;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.employees || initialState;

export const selectEmployees = createSelector([selectDomain], (employeesState) => employeesState);

export const selectEmployeesData = createSelector(
  [selectDomain],
  (employeesState) => employeesState.data,
);

/* --------------------------------------------------------------------------------- */

export { getAllEmployees, createEmployee, updateEmployee, deleteEmployeeById };
