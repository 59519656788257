/* eslint-disable */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// import components below
import Table from 'components/ReactTable';
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './Table.scss';

// main component
const ProfessionalFee = ({ setShowModal, refreshTable, data, setData, logs }) => {
  const tableColumns = React.useMemo(
    () => [
      { Header: 'Doctor Name', accessor: 'doctor_name' },
      { Header: 'Patient Name', accessor: 'patient_name' },
      { Header: 'Professional Fee', accessor: 'professional_fee' },
      { Header: 'Default Professional Fee', accessor: 'default_professional_fee' },
      { Header: 'Is Negotiable', accessor: 'is_negotiable' },
      { Header: 'Is Approve', accessor: 'is_approve' },
      { Header: 'Action', accessor: 'action' },
    ],
    [],
  );

  const tableData = React.useMemo(() => {
    const filteredData = data.map((listData) => {
      return {
        id: listData?.id,
        patient_name: listData?.patient?.full_name,
        professional_fee: listData?.professional_fee,
        is_approve: listData?.is_approved ? 'Yes' : 'No',
        default_professional_fee: listData?.user?.default_professional_fee,
        is_negotiable: listData?.user?.patient_default_negotiable === true ? 'Yes' : 'No',
        doctor_name: 'Dr. ' + listData?.user?.first_name + ' ' + listData?.user?.last_name,
      };
    });

    return filteredData;
  }, [data]);

  const handleTableAction = (eventKey, id) => {
    const row = {
      data: data.find((dd) => parseInt(dd.id) === parseInt(id)),
    };

    const action = {
      set: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            id: id,
            data: row,
            showSet: true,
          };
        });
      },
    };

    action[eventKey]();
  };

  useEffect(() => {
    refreshTable();
  }, []);

  useEffect(() => {
    // console.log('data', data);
  }, [data]);

  return (
    <div className='diagnosis-list-table'>
      <Table
        logs={logs}
        data={tableData}
        columns={tableColumns}
        pagination={['BOTTOM']}
        customAction={(id, data) => {
          const { is_approve, is_negotiable } = data;

          return (
            <ConditionalRender
              condition={
                is_negotiable === 'Yes' && (is_approve === 'No' || is_approve === 'Pending')
              }
              renderIf={
                <span
                  onClick={() => handleTableAction('set', id)}
                  className='badge blue'
                  style={{ cursor: 'pointer' }}
                >
                  Set Professional Fee
                </span>
              }
            />
          );
        }}
      />
    </div>
  );
};

ProfessionalFee.defaultProps = {
  setShowProfile: () => {},
};

ProfessionalFee.propTypes = {
  setShowProfile: PropTypes.func,
};

export default ProfessionalFee;
