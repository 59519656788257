/* eslint-disable */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from 'components/UI/TabsUI';
import ReturnedItems from './ReturnedItems';
import BorrowedItems from './BorrowedItems';
import InventoryItems from './InventoryItems';

// import assets below
import './Tabs.scss';

// main component
const StoreInventoryTabs = ({ onChange }) => {
  const tabs = [
    {
      title: 'INVENTORY ITEMS',
      component: InventoryItems,
    },
    {
      title: 'RETURNED ITEMS',
      component: ReturnedItems,
    },
    {
      title: 'BORROWED ITEMS',
      component: BorrowedItems,
    },
  ];

  return (
    <div className='store-inventory-tabs'>
      <TabsUI>
        <Tabs unmountOnExit defaultActiveKey={tabs[0].title} onSelect={(eventKey) => onChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component departmentID={'1'} />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

StoreInventoryTabs.defaultProps = {
  onChange: () => {},
};

StoreInventoryTabs.propTypes = {
  onChange: PropTypes.func,
};

export default StoreInventoryTabs;
