/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormControl, Button, Tabs, Tab, Image } from 'react-bootstrap';

import LeaveRequest from './LeaveRequest';
import LeaveCredits from './LeaveCredits';
import TabsUI from 'components/UI/TabsUI';
import ConditionalRender from 'components/ConditionalRender';
import { TableFiltersPermission, checkStringPermission } from 'helpers/filteredPermissions';
import { showUsers, getACLState, getRoles, getDepartments, getLogs } from 'reducers/aclReducer';

import './index.scss';
import logoImage from 'assets/images/logo2.png';

const LeaveContainer = () => {
  const [activeKey, setActiveKey] = useState('');
  const [showScreen, setShowScreen] = useState(1);
  const [requestData, setRequestData] = useState();

  const onChange = (key) => {
    setActiveKey(key);
  };

  const defaultTabs = [
    {
      title: 'Leave Credits',
      component: LeaveCredits,
    },
    {
      title: 'Leave Requests',
      component: LeaveRequest,
    },
  ];

  // eslint-disable-next-line prefer-const
  let tabs = TableFiltersPermission(defaultTabs, []);

  return (
    <>
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <div className='leave-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>LEAVES</Form.Label>

                <FormControl className='blue-control' placeholder='Search Employee' />
              </Form.Group>
            </div>

            <div className='box-container'>
              <Image className='website-logo' src={logoImage} />

              <Form.Group className='employee-detail'>
                <p>DETAILS HERE</p>
                <span>Company Address</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>JOHN DOE</p>
                <span>Department Manager</span>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='leave-tabs'>
                <TabsUI>
                  <Tabs
                    defaultActiveKey={tabs[0].title}
                    onSelect={(eventKey) => onChange(eventKey)}
                  >
                    {tabs.map(({ title, component: Component }, idx) => (
                      <Tab key={idx} eventKey={title} title={title}>
                        <Component
                          activeKey={activeKey}
                          setShowScreen={setShowScreen}
                          setRequestData={setRequestData}
                        />
                      </Tab>
                    ))}
                  </Tabs>
                </TabsUI>
              </div>
            </div>
          </div>
        }
      />

      <ConditionalRender
        condition={showScreen === 2}
        renderIf={
          <div className='leave-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>LEAVE REQUEST DETAILS</Form.Label>
              </Form.Group>
            </div>

            <div className='box-container'>
              <Image className='website-logo' src={logoImage} />

              <Form.Group className='employee-detail'>
                <p>JUAN DELA CRUZ</p>
                <span>Name</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>PHYSICIAN</p>
                <span>Position</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>DETAILS HERE</p>
                <span>Department</span>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='leave-details'>
                <div className='details-header'>
                  <p>
                    Leave Type: <span>Details Here</span>
                  </p>

                  <p>
                    Leave Start: <span>MM/DD/YY</span>
                  </p>

                  <p>
                    Leave End: <span>MM/DD/YY</span>
                  </p>

                  <p>
                    Status: <span className='badge Approved'>Approved</span>
                  </p>
                </div>

                <div className='details-content'>
                  <Form.Group className='mb-4'>
                    <Form.Label>Leave Message/ Reason</Form.Label>

                    <FormControl as='textarea' rows={3} placeholder='Details Here...' />
                  </Form.Group>

                  <Form.Group className='mb-4'>
                    <Form.Label>Leave Status</Form.Label>

                    <Form.Select>
                      <option value='Approved'>Approved</option>
                      <option value='Denied'>Denied</option>
                      <option value='Pending'>Pending</option>
                    </Form.Select>
                  </Form.Group>
                </div>

                <div className='details-footer'>
                  <Button className='save'>Save</Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default LeaveContainer;
