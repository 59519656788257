/* eslint-disable */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';

// Helpers
import {
  patientStatus,
  currentLocation,
  calculateDayDiff,
} from 'helpers';

// import components below
import Table from 'components/ReactTable';
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
// import './Table.scss';

// main component
const SearchPatientListTable = ({ state, setState, setShowProfile }) => {
  useEffect(() => {
    axios
      .get('/patient')
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'MRN / PIN', accessor: 'mrn' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Sex', accessor: 'sex' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      { Header: 'Currently', accessor: 'currently' },
      { Header: 'Status', accessor: 'status' },
    ],
    []
  )

  const tableData = React.useMemo(
    () => state?.map(dd => ({
      id: dd?.id,
      mrn: dd?.MRN,
      name: dd?.full_name,
      sex: dd?.sex,
      date_of_birth: moment(dd?.date_of_birth).format('MMMM-DD-YYYY'),
      currently: currentLocation(dd?.patient_status),
      status: patientStatus(dd?.patient_status),
    })),
    [state]
  )

  const handleTableAction = (eventKey, id) => {
    const row = state.find(dd => dd.id === id);
    const action = {
      profile: () => {
        setShowProfile({
          isShowProfile: true,
          profileId: row?.id,
          admissionDays: calculateDayDiff((row?.admission_date)),
        })
      },
      view: () => { },
      edit: () => { },
      delete: () => { }
    }

    action[eventKey]()
  }

  return (
    <div className='searchPatientListTable'>
      <Table
        data={tableData}
        columns={tableColumns}
        pagination={['BOTTOM']}
        onAction={handleTableAction}
        actions={{ hasProfile: true }}
      />
    </div>
  );
};

export default SearchPatientListTable;
