/* eslint-disable */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'components/ReactTable';
import moment from 'moment';
import axios from 'axios';

import { patientStatus } from 'helpers';

// import components below
import TableUI from 'components/UI/TableUI';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const PatientTable = (data) => {
  const { state, setShowProfile } = data;
  // eslint-disable-next-line prefer-const
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const EditProfile = (item) => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        profileId: item?.id,
      };
    });

    dispatch(showPatient(item.id));
    navigate('/dashboard/outpatient-department/edit-patient-profile', { replace: true });
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'MRN / PIN', accessor: 'mrn' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Age / Sex', accessor: 'age_sex' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        mrn: dd?.MRN,
        name: dd?.full_name,
        age_sex: `${dd?.age}/${dd?.sex}`,
        date_of_birth: moment(dd?.date_of_birth).format('MMMM-DD-YYYY'),
        status: patientStatus(dd?.patient_status),
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      profile: () => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isShowView: true,
            profileId: row?.id,
          };
        });
      },
      view: () => {},
      edit: () => {},
    };

    action[eventKey]();
  };

  return (
    <div className='emergency-department-search-patient-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasProfile: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default PatientTable;
