// import components below
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const CreateRoomRequestHeader = () => {
  return (
    <div className='createRoomRequestHeader'>
      <PageHeaderUI>
        <h4>Admission Module</h4>
        <p>Dashboard / Admission / Create Room Request</p>
      </PageHeaderUI>
    </div>
  );
};

export default CreateRoomRequestHeader;
