/* eslint-disable camelcase */
// Packages
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

// Components
import BillingTable from './components/Table';
import { getBilling } from 'reducers/billingReducer';
import SearchPatientFilter from './components/Filter';
import FormPatientProfile from './components/PatientProfile';

// Main Component
const WorkList = () => {
  const [state, setState] = useState([]);
  const dispatch = useDispatch();

  const [showProfile, setShowProfile] = useState({
    profileId: null,
    billingId: null,
    isShowView: false,
  });

  useEffect(() => {
    dispatch(getBilling(''));
  }, []);

  return (
    <div>
      {!showProfile.isShowView ? (
        <div className='searchPatient'>
          <SearchPatientFilter setState={setState} state={state} />

          <BillingTable setState={setState} setShowProfile={setShowProfile} />
        </div>
      ) : (
        <FormPatientProfile showProfile={showProfile} setShowProfile={setShowProfile} />
      )}
    </div>
  );
};

export default WorkList;
