/* eslint-disable no-unused-vars */
import { Tabs, Tab, Form, Row, Col, Table, Button } from 'react-bootstrap';
import { useState } from 'react';

// import components below
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import AddTemplateModal from './components/AddTemplate';
import { ReactComponent as CirclePlus } from 'assets/svg/circle-plus-white.svg';

// CSS
import './index.scss';

const sampleData = [
  {
    id: 1,
    templateName: 'Income Statement Template',
    file: '#',
  },
  {
    id: 2,
    templateName: 'Balance Sheet Template',
    file: '#',
  },
  {
    id: 3,
    templateName: 'Cash Flow Template',
    file: '#',
  },
  {
    id: 4,
    templateName: 'Managerial Accounting Template',
    file: '#',
  },
  {
    id: 5,
    templateName: 'Cost Counting Template',
    file: '#',
  },
  {
    id: 6,
    templateName: 'Financial Statement Template',
    file: '#',
  },
  {
    id: 7,
    templateName: 'Expense Tracking Template',
    file: '#',
  },
  {
    id: 8,
    templateName: 'Expense Reimbursement Template',
    file: '#',
  },
  {
    id: 9,
    templateName: 'Inventory Template',
    file: '#',
  },
  {
    id: 10,
    templateName: 'Sales Invoice Template',
    file: '#',
  },
];

// main component
const Templates = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className='templates'>
      <FormUI>
        <Form>
          <Row className='mb-4'>
            <Col>
              <TableUI>
                <Table className='shadow-sm' responsive>
                  <thead>
                    <tr>
                      <th>Template Name</th>
                      <th>File</th>
                      <th>
                        <Button
                          onClick={() => {
                            setShowModal(true);
                          }}
                          className='add-template'
                        >
                          <CirclePlus />
                          <span>ADD TEMPLATE</span>
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sampleData.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.templateName}</td>
                          <td>
                            <a className='document' href={data.templateName}>
                              Document Template Here
                            </a>
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </TableUI>
            </Col>
          </Row>
        </Form>
      </FormUI>

      <AddTemplateModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Templates;
