// import components below
import PropTypes from 'prop-types';
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const SocialHeader = ({ active }) => {
    return (
        <div className='SocialHeader'>
            <PageHeaderUI>
                <h4>Audit Logs Module</h4>
                <p>Dashboard / Audit Logs / {active}</p>
            </PageHeaderUI>
        </div>
    );
};

SocialHeader.defaultProps = {
    active: '',
};

SocialHeader.propTypes = {
    active: PropTypes.string,
};

export default SocialHeader;
