/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-indent-props */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import FormUI from 'components/UI/FormUI';
import { Button, Row, Form } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

import { createBilling, showBillingByPatient } from 'reducers/thunks/billingThunks';

const PartialForm = ({ showModal, setShowModal, soaData }) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const handleShow = () => setShowModal({ isPartial: false });

    const [formData, setFormData] = useState({
        // eslint-disable-next-line camelcase
        name_of_payor: '',
        amount: 0,
        // eslint-disable-next-line camelcase
        payment_type: 'partial',
        // eslint-disable-next-line camelcase
        payment_method: 'cash',
        patientId: showModal.patientId ?? 0,
        billId: showModal.billingId ?? 0,
    });

    const handleSubmit = () => {
        setShowModal({ ...showModal, isPartial: false });
        dispatch(createBilling(formData));
        dispatch(showBillingByPatient(showModal.patientId));
        toast.success('Successfully created payment');

        // const form = event.currentTarget;
        // // event.preventDefault();
        // event.stopPropagation();
        // let payload = {};

        // if (!form.checkValidity() === false) {
        //     payload = { ...formData };

        //     if (parseInt(payload.amount) >= parseInt(soaData.totalNetBill)) {
        //         // eslint-disable-next-line camelcase
        //         payload = { payment_type: 'full' };
        //         setFormData({ ...formData, payment_type: 'full' });
        //     }
        //     setShowModal({ ...showModal, print: true });
        //     console.log('Success', formData);
        //     dispatch(createBilling(formData));
        // }

        // setValidated(true);
    };

    const handleChange = (key) => (e) => {
        setFormData({
            ...formData,
            [key]: e.target.value,
        });
    };

    return (
        <Row className='mt-5 container d-flex align-items-center justify-content-center"'>
            <FormUI>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className='mb-4'>
                        <Form.Label>Name of Payor</Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Name'
                            name='name'
                            onChange={handleChange('name_of_payor')}
                        />
                    </Form.Group>
                    <Form.Group className='mb-4'>
                        <Form.Label>How do you want to pay?</Form.Label>
                        <Form.Check
                            inline
                            label='Cash'
                            name='mop'
                            type='checkbox'
                            id='inline-checkbox-1'
                            value='cash'
                            onChange={handleChange('payment_method')}
                            required
                        />
                        {/* <Form.Check */}
                        {/*  inline */}
                        {/*  label='PhilHealth' */}
                        {/*  name='mop' */}
                        {/*  type='checkbox' */}
                        {/*  id='inline-checkbox-2' */}
                        {/*  value='philhealth' */}
                        {/* /> */}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            required
                            type='number'
                            name='amount'
                            width={100}
                            onChange={handleChange('amount')}
                        />
                    </Form.Group>
                    <Form.Group className='mt-4 buttons-cont'>
                        <Button className='me-3 btn btn-save' onClick={() => handleSubmit()}>
                            Confirm Payment
                        </Button>
                        <Button className='btn-clear' type='button' onClick={handleShow}>
                            Cancel
                        </Button>
                    </Form.Group>
                </Form>
            </FormUI>
        </Row>
    );
};

export default PartialForm;