// import core & vendor packages below
import { Fragment, useState } from 'react';

// import components below
import ReactHelmet from 'components/ReactHelmet';
import LoginUI from 'components/UI/LoginUI';
import FormLogin from './components/Form';

// import assets below
import './style.scss';
// import LogoLogin from 'assets/images/logo_login.png';
import LoginModal from './components/Modal';

// main component
const Login = () => {
  const [modalStatus, setModalStatus] = useState(false);

  return (
    <Fragment>
      <ReactHelmet title='Login' />
      <LoginUI>
        <div className='containerLogin'>
          <div className='containerLogin__header'>
            {/* <img className='containerLogin__logo' src={LogoLogin} alt='logo' /> */}
          </div>
          <div className='containerLogin__body'>
            <FormLogin />
          </div>
        </div>
      </LoginUI>
      
      {modalStatus && <LoginModal status={modalStatus} onToggle={setModalStatus} />}
    </Fragment>
  );
};

export default Login;
