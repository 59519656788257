/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { completeReturnItem, selectReturn } from 'reducers/returnReducer';

// Import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main components
const CompleteReturnModal = ({ showModal, fetchData, setShowModal }) => {
  const dispatch = useDispatch();

  // New Borrow Return
  const returnState = useSelector(selectReturn);
  const { singleData, completeLogs } = returnState;

  const [formData, setFormData] = useState({
    remarks: '',
  });

  // Handle POST api
  const handleSubmit = () => {
    const payload = {
      id: showModal?.data?.id,
      payload: {
        remarks: formData?.remarks,
      },
    };

    dispatch(completeReturnItem(payload));
  };

  const closeModal = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        data: null,
        completeReturn: false,
      };
    });

    setFormData({
      borrowedBy: '',
      remarks: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // Get Submit Data result
  useEffect(() => {
    const { isLoading, isFailed, isSuccess, error } = completeLogs;

    if (isFailed) {
      toast.error(`${error[0]?.detail} (${error[0]?.code})`);
      console.log(error);
    }

    if (isSuccess) {
      fetchData();
      closeModal();
      setFormData({});
      toast.success('Successfully completed.');
    }
  }, [completeLogs]);

  return (
    <Modal
      size='lg'
      show={showModal?.completeReturn}
      onHide={() => {
        closeModal();
      }}
      aria-labelledby='Request Item Modal'
      className='view-borrowed-item request-item'
    >
      <Modal.Body>
        <div className='view-borrowed-item-header'>
          <div className='d-flex header'>
            <p>COMPLETE RETURN?</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={() => closeModal()} />
          </div>
        </div>

        <FormUI>
          <Form className='item-information-form'>
            <Row className='mb-2'>
              <Col>
                <Form.Group className='mb-3 form-group'>
                  <Form.Label>Remarks</Form.Label>
                  <FormControl
                    type='text'
                    as='textarea'
                    name='remarks'
                    onChange={handleChange}
                    value={formData?.remarks}
                    placeholder='Remarks Here'
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='buttons-cont'>
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    variant='primary'
                    className='add-button'
                  >
                    Complete
                  </Button>
                  <Button
                    onClick={() => {
                      closeModal();
                    }}
                    className='clear-button'
                    variant='secondary'
                  >
                    Close
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default CompleteReturnModal;
