import PropTypes from 'prop-types';

// import components below
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const AdmissionHeader = ({ active }) => {
  return (
    <div className='billingHeader'>
      <PageHeaderUI>
        <h4>Billing Module</h4>
        <p>Dashboard / Billing / {active}</p>
      </PageHeaderUI>
    </div>
  );
};

AdmissionHeader.defaultProps = {
  active: '',
};

AdmissionHeader.propTypes = {
  active: PropTypes.string,
};

export default AdmissionHeader;
