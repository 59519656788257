/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getPatient } from 'reducers/patientReducer';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchPatientFilter = ({ setState }) => {
  const dispatch = useDispatch();

  const [patients, setPatients] = useState([]);

  const [id, setId] = useState('');
  const [MRN, setMRN] = useState('');
  const [word, setWord] = useState('');

  // Search Patient by name
  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    setWord(event.target.value);
    const newFilter = patients.filter((value) => {
      return (
        value.full_name.toLowerCase().includes(searchedWord.toLowerCase()) ||
        String(value.id) === String(searchedWord) ||
        String(value.MRN) === String(searchedWord)
      );
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  // Search patient by ID
  const onChangeHandlerID = (event) => {
    const searchedWord = event.target.value;
    setId(event.target.value);
    const newFilter = patients.filter((value) => {
      return String(value.id) === String(searchedWord);
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  // search by patient MRN
  const onChangeHandlerMRN = (event) => {
    const searchedWord = event.target.value;
    setMRN(event.target.value);
    const newFilter = patients.filter((value) => {
      return String(value.MRN) === String(searchedWord);
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  const resetFilter = () => {
    setState(patients);
    setWord('');
    setId('');
    setMRN('');
  };

  const [filter, setFilter] = useState({
    MRN: '',
    full_name: '',
    patient_status: '',
    admission_date: '',
  });

  useEffect(() => {
    dispatch(getPatient());

    axios
      .get('/patient')
      .then((response) => {
        setPatients(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='searchPatientFilter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Basic Search</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  placeholder='Search via Last Name or PIN…'
                  value={filter.keyword}
                  onChange={onChangeHandler}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Accordion className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Advance Search</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={1}>
                      <Form.Group className='mb-4'>
                        <Form.Label>Patient ID</Form.Label>
                        <FormControl
                          value={id}
                          type='text'
                          className='bg-white'
                          placeholder='Enter Here'
                          onChange={onChangeHandlerID}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className='mb-4'>
                        <Form.Label>MRN / PIN</Form.Label>
                        <FormControl
                          value={MRN}
                          type='text'
                          className='bg-white'
                          placeholder='Enter Here'
                          onChange={onChangeHandlerMRN}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Patient Name</Form.Label>
                        <FormControl
                          type='text'
                          value={word}
                          className='bg-white'
                          onChange={onChangeHandler}
                          placeholder='Enter Patient Name'
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <div className='buttonWrapper'>
                      <Button variant='clear' onClick={() => resetFilter()}>
                        CLEAR
                      </Button>
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default SearchPatientFilter;
