/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import { getActivityLog, selectInventoryLogs } from 'reducers/inventoryReducer';

// import assets below
import './index.scss';

// main Component
const ActivityLog = ({ activeKey }) => {
  const dispatch = useDispatch();

  const state = useSelector(selectInventoryLogs);

  const [filter, setFilter] = useState({ search: '', date: '' });

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Date Time', accessor: 'created_at' },
      { Header: 'Action', accessor: 'action' },
      { Header: 'User', accessor: 'user' },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Department', accessor: 'department' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        user: dd?.attributes.user,
        action: dd?.attributes.action,
        created_at: dd?.attributes.created_at,
        description: dd?.attributes.description,
        department: dd?.attributes?.department?.name,
      })),
    [state],
  );

  const handleReset = () => {
    setFilter({ search: '', date: '' });

    dispatch(
      getActivityLog({
        q: '',
        per_page: 100000,
      }),
    );
  };

  const handleSearch = () => {
    dispatch(
      getActivityLog({
        per_page: 100000,
        q: filter?.search,
      }),
    );
  };

  renderWhenTabIsActive(activeKey, filter);

  return (
    <div className='activity-log'>
      <Table
        data={tableData}
        // logs={maintenancelogs}
        columns={tableColumns}
        filter={{
          top: (
            <>
              {/* <div className='buttons' style={{ flex: 'none', whiteSpace: 'nowrap' }}>
                {filterList.map((list) => (
                  <Button
                    key={list.key}
                    className={list.key === active ? 'active' : ''}
                    onClick={() => handleOnClickFilter(list.key)}
                  >
                    {list.name}
                  </Button>
                ))}
              </div> */}
              <div className='search-filter'>
                <Form.Group className='mx-1'>
                  <FormControl
                    type='text'
                    className='bg-white'
                    value={filter?.search}
                    placeholder='Search Here'
                    onChange={(e) => {
                      setFilter((prev) => {
                        return { ...prev, search: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
                <Col>
                  <div className='buttonWrapper mx-1'>
                    <Button variant='search' onClick={handleSearch}>
                      APPLY
                    </Button>

                    <Button onClick={handleReset} variant='clear'>
                      RESET
                    </Button>
                  </div>
                </Col>
              </div>
            </>
          ),
        }}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

const renderWhenTabIsActive = (activeKey, filter) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeKey === 'Activity Log') {
      dispatch(
        getActivityLog({
          per_page: 100000,
          q: filter?.search,
        }),
      );
    }
  }, [activeKey]);
};

export default ActivityLog;
