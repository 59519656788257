// import components below
import Filter from './Filter';

// import assets below
import './index.scss';

const Records = () => {
    
    return (
      <div className='records'>
        <Filter />
      </div>
    );
  };
export default Records;