/* eslint-disable no-unused-vars */
/* eslint-disable */
/* eslint-disable multiline-comment-style */
import axios from 'axios';
import uuidv4 from 'react-uuid';
import AsyncSelect from 'react-select/async';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

import ConditionalRender from 'components/ConditionalRender';

import styles from './NoteForm.module.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/circle-minus.svg';
import tinyStick from 'assets/images/stickman.png';

const NoteForm = ({ stateData, setStateData }) => {
  const handleChange = (e, mainObj = '') => {
    const { name, value, type, checked, id } = e.target;

    if (name === 'reviewOfSystemsSub') {
      const currentData = stateData.reviewOfSystems[mainObj].symptoms;
      const newData = {
        ...currentData,
        [id]: checked,
      };

      const finalData = {
        ...stateData.reviewOfSystems[mainObj],
        symptoms: newData,
      };

      setStateData((prev) => {
        return {
          ...prev,
          reviewOfSystems: { ...stateData.reviewOfSystems, [mainObj]: finalData },
        };
      });

      return;
    }

    if (name === 'reviewOfSystems') {
      const currentData = stateData.reviewOfSystems[mainObj];
      let defaultSymptoms = {};

      switch (id) {
        case 'constitutional':
          defaultSymptoms = {
            fever: false,
            chills: false,
            weightLoss: false,
            fatigue: false,
          };
          break;

        default:
          break;
      }

      const modifiedData = {
        ...currentData,
        isTrue: checked,
        symptoms: defaultSymptoms,
      };

      setStateData((prev) => {
        return {
          ...prev,
          reviewOfSystems: { ...stateData.reviewOfSystems, [mainObj]: modifiedData },
        };
      });

      return;
    }

    if (name === 'socialHistoryInput') {
      setStateData((prev) => {
        return {
          ...prev,
          socialHistory: { ...stateData.socialHistory, [id]: value },
        };
      });

      return;
    }

    if (name === 'obHistory') {
      setStateData((prev) => {
        return {
          ...prev,
          obHistory: {
            ...stateData.obHistory,
            [id]: value,
          },
        };
      });

      return;
    }

    if (type === 'radio') {
      setStateData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });

      return;
    }

    if (type === 'checkbox') {
      setStateData((prev) => {
        return {
          ...prev,
          [name]: { ...stateData[name], [mainObj]: checked, tobaccoPacks: '' },
        };
      });

      return;
    }

    setStateData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleMultiChange =
    ({ id }, type) =>
    ({ target }) => {
      const { value, name } = target;

      if (type === 'observations') {
        const newData = stateData[type].map((s) =>
          s?.id === id
            ? {
                ...s,
                [name]: value,
              }
            : s,
        );

        setStateData((prev) => ({
          ...prev,
          [type]: newData,
        }));

        return;
      }

      const newData = stateData[type].map((s) =>
        s?.id === id
          ? {
              id,
              value,
            }
          : s,
      );

      setStateData((prev) => ({
        ...prev,
        [type]: newData,
      }));
    };

  const removeDataFromMultiple = (data, type) => {
    if (type === 'observations') {
      setStateData((prev) => {
        return {
          ...prev,
          [type]: stateData[type].filter((s) => s?.id !== data?.id),
        };
      });

      return;
    }

    setStateData((prev) => {
      return {
        ...prev,
        [type]: stateData[type].filter((s) => s?.id !== data?.id),
      };
    });
  };

  const addMultiple = (type) => {
    if (type === 'observations') {
      setStateData((prev) => ({
        ...prev,
        [type]: prev[type].concat({
          id: uuidv4(),
          time: '',
          observationRemarks: '',
          physicianOrders: '',
        }),
      }));

      return;
    }

    setStateData((prev) => ({
      ...prev,
      [type]: prev[type].concat({
        id: uuidv4(),
        value: '',
      }),
    }));
  };

  const haveHistoryOfPresentIllness =
    stateData?.historyOfIllness && stateData?.historyOfIllness.length > 0;

  const havePastSurgeryHospitalization =
    stateData?.pastSurgeryHospitalization && stateData?.pastSurgeryHospitalization.length > 0;

  const haveDevelopmentalHistory =
    stateData?.developmentalHistory && stateData?.developmentalHistory.length > 0;

  const haveObservations = stateData?.observations && stateData?.observations.length > 0;

  return (
    <>
      <Row>
        <Col>
          <div className={styles['note-container']}>
            {/* First Row */}
            <div className={styles['note-row']}>
              <div className={styles['note-column']}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>BP</Form.Label>
                  <FormControl
                    name='bp'
                    placeholder='...'
                    value={stateData?.bp}
                    onChange={handleChange}
                    className={styles['note-input']}
                    type='number'
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                    }
                  />
                </Form.Group>
              </div>
              <div className={styles['note-column']}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>PR</Form.Label>
                  <FormControl
                    name='pr'
                    placeholder='...'
                    value={stateData?.pr}
                    onChange={handleChange}
                    className={styles['note-input']}
                    type='number'
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                    }
                  />
                </Form.Group>
              </div>
              <div className={styles['note-column']}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>RR</Form.Label>
                  <FormControl
                    name='rr'
                    placeholder='...'
                    value={stateData?.rr}
                    onChange={handleChange}
                    className={styles['note-input']}
                    type='number'
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                    }
                  />
                </Form.Group>
              </div>
              <div className={styles['note-column']}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>Temp°C</Form.Label>
                  <FormControl
                    name='temp'
                    type='number'
                    placeholder='...'
                    value={stateData?.temp}
                    onChange={handleChange}
                    className={styles['note-input']}
                    type='number'
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                    }
                  />
                </Form.Group>
              </div>
              <div className={styles['note-column']}>
                <Form.Group className={`mb-2 ${styles['inline-group']} ${styles['note-data']}`}>
                  <Form.Label>O2 Sat (%)</Form.Label>
                  <FormControl
                    name='o2Sat'
                    type='number'
                    placeholder='...'
                    value={stateData?.o2Sat}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>

                <Form.Group className={styles['inline-group']}>
                  <Form.Label>ON</Form.Label>
                  <FormControl
                    name='on'
                    placeholder='...'
                    value={stateData?.on}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>
              </div>
              <div className={styles['note-column-last']}>
                <Form.Group className={`mb-2 ${styles['inline-group']} ${styles['note-data']}`}>
                  <Form.Label>Weight (kg)</Form.Label>
                  <FormControl
                    name='weight'
                    type='number'
                    placeholder='...'
                    value={stateData?.weight}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>

                <Form.Group className={styles['inline-group']}>
                  <Form.Label>Height (cm)</Form.Label>
                  <FormControl
                    name='height'
                    placeholder='...'
                    onChange={handleChange}
                    value={stateData?.height}
                    className={styles['note-input']}
                    type='number'
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                    }
                  />
                </Form.Group>
              </div>
            </div>

            {/* Second Row */}
            <div className={styles['note-row']}>
              <div className={`${styles['note-column']} ${styles['flex-2']}`}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>Chief Complaint</Form.Label>
                  <FormControl
                    name='chiefComplaint'
                    placeholder='...'
                    value={stateData?.chiefComplaint}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>
              </div>
              <div className={`${styles['note-column']} ${styles['flex-2']}`}>
                <Form.Group>
                  <Form.Label>Informat</Form.Label>
                  <FormControl
                    name='informat'
                    placeholder='...'
                    value={stateData?.informat}
                    s
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>
              </div>
              <div className={`${styles['note-column-last']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>Reliability (%)</Form.Label>
                  <FormControl
                    name='reliability'
                    placeholder='...'
                    value={stateData?.reliability}
                    onChange={handleChange}
                    className={styles['note-input']}
                    type='number'
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                    }
                  />
                </Form.Group>
              </div>
            </div>

            {/* Third Row */}
            <div className={styles['note-row']}>
              <div className={`${styles['note-column']} ${styles['flex-2']}`}>
                <Form.Group className={styles['note-data']}>
                  <div className={styles['multiple-inputs-header']}>
                    <Form.Label>History of present illness</Form.Label>
                    <AddSVG
                      className={styles['svg']}
                      onClick={() => {
                        addMultiple('historyOfIllness');
                      }}
                    />
                  </div>

                  <ConditionalRender
                    condition={haveHistoryOfPresentIllness}
                    renderIf={
                      <>
                        {stateData?.historyOfIllness.map((data, idx) => {
                          return (
                            <div className={styles['multiple-inputs']}>
                              <FormControl
                                placeholder='...'
                                value={data?.value}
                                className={styles['note-input']}
                                onChange={handleMultiChange(data, 'historyOfIllness')}
                              />
                              <ConditionalRender
                                condition={stateData?.historyOfIllness.length > 1}
                                renderIf={
                                  <MinusSVG
                                    className={styles['svg']}
                                    onClick={() => {
                                      removeDataFromMultiple(data, 'historyOfIllness');
                                    }}
                                  />
                                }
                              />
                            </div>
                          );
                        })}
                      </>
                    }
                  />
                </Form.Group>
              </div>
              <div className={`${styles['note-column']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>Past Medical History</Form.Label>

                  <Form.Check
                    type='checkbox'
                    label='Asthma / COPD'
                    name='pastMedicalHistory'
                    checked={stateData?.pastMedicalHistory?.asthmaCOPD}
                    onChange={(e) => {
                      handleChange(e, 'asthmaCOPD');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Heart Disease'
                    name='pastMedicalHistory'
                    checked={stateData?.pastMedicalHistory?.heartDisease}
                    onChange={(e) => {
                      handleChange(e, 'heartDisease');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Hypertension'
                    name='pastMedicalHistory'
                    checked={stateData?.pastMedicalHistory?.hypertension}
                    onChange={(e) => {
                      handleChange(e, 'hypertension');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Diabetes'
                    name='pastMedicalHistory'
                    checked={stateData?.pastMedicalHistory?.diabetes}
                    onChange={(e) => {
                      handleChange(e, 'diabetes');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='CVA'
                    name='pastMedicalHistory'
                    checked={stateData?.pastMedicalHistory?.CVA}
                    onChange={(e) => {
                      handleChange(e, 'CVA');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Cancer'
                    name='pastMedicalHistory'
                    checked={stateData?.pastMedicalHistory?.cancer}
                    onChange={(e) => {
                      handleChange(e, 'cancer');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Others'
                    name='pastMedicalHistory'
                    checked={stateData?.pastMedicalHistory?.others}
                    onChange={(e) => {
                      handleChange(e, 'others');
                    }}
                  />
                </Form.Group>
              </div>
              <div className={`${styles['note-column-last']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data']}>
                  <div className={styles['multiple-inputs-header']}>
                    <Form.Label>Past Surgery Hospitalization</Form.Label>
                    <AddSVG
                      className={styles['svg']}
                      onClick={() => {
                        addMultiple('pastSurgeryHospitalization');
                      }}
                    />
                  </div>

                  <ConditionalRender
                    condition={havePastSurgeryHospitalization}
                    renderIf={
                      <>
                        {stateData?.pastSurgeryHospitalization.map((data) => {
                          return (
                            <div className={styles['multiple-inputs']}>
                              <FormControl
                                placeholder='...'
                                value={data?.value}
                                className={styles['note-input']}
                                onChange={handleMultiChange(data, 'pastSurgeryHospitalization')}
                              />

                              <ConditionalRender
                                condition={stateData?.pastSurgeryHospitalization.length > 1}
                                renderIf={
                                  <MinusSVG
                                    className={styles['svg']}
                                    onClick={() => {
                                      removeDataFromMultiple(data, 'pastSurgeryHospitalization');
                                    }}
                                  />
                                }
                              />
                            </div>
                          );
                        })}
                      </>
                    }
                  />
                </Form.Group>
              </div>
            </div>

            {/* Fourth Row */}
            <div className={styles['note-row']}>
              <div className={`${styles['note-column']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data']}>
                  <div className={styles['multiple-inputs-header']}>
                    <Form.Label>Developmental History</Form.Label>
                    <AddSVG
                      className={styles['svg']}
                      onClick={() => {
                        addMultiple('developmentalHistory');
                      }}
                    />
                  </div>

                  <ConditionalRender
                    condition={haveDevelopmentalHistory}
                    renderIf={
                      <>
                        {stateData?.developmentalHistory.map((data, idx) => {
                          return (
                            <div className={styles['multiple-inputs']}>
                              <FormControl
                                placeholder='...'
                                value={data?.value}
                                className={styles['note-input']}
                                onChange={handleMultiChange(data, 'developmentalHistory')}
                              />
                              <ConditionalRender
                                condition={stateData?.developmentalHistory.length > 1}
                                renderIf={
                                  <MinusSVG
                                    className={styles['svg']}
                                    onClick={() => {
                                      removeDataFromMultiple(data, 'developmentalHistory');
                                    }}
                                  />
                                }
                              />
                            </div>
                          );
                        })}
                      </>
                    }
                  />
                </Form.Group>
              </div>
              <div className={`${styles['note-column']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>Family History</Form.Label>

                  <Form.Check
                    type='checkbox'
                    label='Asthma / COPD'
                    name='familyHistory'
                    checked={stateData?.familyHistory?.asthmaCOPD}
                    onChange={(e) => {
                      handleChange(e, 'asthmaCOPD');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Heart Disease'
                    name='familyHistory'
                    checked={stateData?.familyHistory?.heartDisease}
                    onChange={(e) => {
                      handleChange(e, 'heartDisease');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Hypertension'
                    name='familyHistory'
                    checked={stateData?.familyHistory?.hypertension}
                    onChange={(e) => {
                      handleChange(e, 'hypertension');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Diabetes'
                    name='familyHistory'
                    checked={stateData?.familyHistory?.diabetes}
                    onChange={(e) => {
                      handleChange(e, 'diabetes');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='CVA'
                    name='familyHistory'
                    checked={stateData?.familyHistory?.CVA}
                    onChange={(e) => {
                      handleChange(e, 'CVA');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Cancer'
                    name='familyHistory'
                    checked={stateData?.familyHistory?.cancer}
                    onChange={(e) => {
                      handleChange(e, 'cancer');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Others'
                    name='familyHistory'
                    checked={stateData?.familyHistory?.others}
                    onChange={(e) => {
                      handleChange(e, 'others');
                    }}
                  />
                </Form.Group>
              </div>
              <div className={`${styles['note-column-last']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>OB History</Form.Label>

                  <Form.Group
                    className={`mb-2 ${styles['inline-group']} ${styles['note-data']} ${styles['height-auto']}`}
                  >
                    <Form.Label>G</Form.Label>
                    <FormControl
                      id='g'
                      name='obHistory'
                      placeholder='...'
                      onChange={handleChange}
                      value={stateData?.obHistory?.g}
                      className={styles['note-input']}
                    />
                  </Form.Group>

                  <Form.Group className={`mb-2 ${styles['inline-group']}`}>
                    <Form.Label>P</Form.Label>
                    <FormControl
                      id='p'
                      name='obHistory'
                      placeholder='...'
                      onChange={handleChange}
                      value={stateData?.obHistory?.p}
                      className={styles['note-input']}
                    />
                  </Form.Group>

                  <Form.Group className={`mb-2 ${styles['inline-group']}`}>
                    <Form.Label>LMP</Form.Label>
                    <FormControl
                      id='lmp'
                      name='obHistory'
                      placeholder='...'
                      onChange={handleChange}
                      value={stateData?.obHistory?.lmp}
                      className={styles['note-input']}
                    />
                  </Form.Group>

                  <Form.Group className={`mb-2 ${styles['inline-group']}`}>
                    <Form.Label>EDC</Form.Label>
                    <FormControl
                      id='edc'
                      name='obHistory'
                      placeholder='...'
                      onChange={handleChange}
                      value={stateData?.obHistory?.edc}
                      className={styles['note-input']}
                    />
                  </Form.Group>

                  <Form.Group className={`mb-2 ${styles['inline-group']}`}>
                    <Form.Label>AOG</Form.Label>
                    <FormControl
                      id='aog'
                      name='obHistory'
                      placeholder='...'
                      onChange={handleChange}
                      value={stateData?.obHistory?.aog}
                      className={styles['note-input']}
                    />
                  </Form.Group>
                </Form.Group>
              </div>
            </div>

            {/* Fifth Row */}
            <div className={styles['note-row']}>
              <div className={`${styles['note-column']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>Allergies</Form.Label>

                  <Form.Check
                    type='checkbox'
                    label='None Known'
                    name='allergies'
                    checked={stateData?.allergies?.noneKnown}
                    onChange={(e) => {
                      handleChange(e, 'noneKnown');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Food'
                    name='allergies'
                    checked={stateData?.allergies?.food}
                    onChange={(e) => {
                      handleChange(e, 'food');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Drug'
                    name='allergies'
                    checked={stateData?.allergies?.drug}
                    onChange={(e) => {
                      handleChange(e, 'drug');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Others'
                    name='allergies'
                    checked={stateData?.allergies?.others}
                    onChange={(e) => {
                      handleChange(e, 'others');
                    }}
                  />
                </Form.Group>
              </div>
              <div className={`${styles['note-column']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data']}>
                  <Form.Label>Social History</Form.Label>

                  <div className={styles['inline-check-with-input']}>
                    <Form.Check
                      type='checkbox'
                      label='Tobacco'
                      name='socialHistory'
                      checked={stateData?.socialHistory?.tobacco}
                      onChange={(e) => {
                        handleChange(e, 'tobacco');
                      }}
                    />

                    <Form.Group className={styles['input-no-border']}>
                      <FormControl
                        id='tobaccoPacks'
                        placeholder='...'
                        onChange={handleChange}
                        name='socialHistoryInput'
                        disabled={!stateData?.socialHistory?.tobacco}
                        value={stateData?.socialHistory?.tobaccoPacks}
                      />
                      <Form.Label>pack years</Form.Label>
                    </Form.Group>
                  </div>

                  <Form.Check
                    type='checkbox'
                    label='Alcohol'
                    name='socialHistory'
                    checked={stateData?.socialHistory?.alcohol}
                    onChange={(e) => {
                      handleChange(e, 'alcohol');
                    }}
                  />

                  <Form.Check
                    type='checkbox'
                    label='Drugs'
                    name='socialHistory'
                    checked={stateData?.socialHistory?.drugs}
                    onChange={(e) => {
                      handleChange(e, 'drugs');
                    }}
                  />
                </Form.Group>
              </div>
              <div
                className={`${styles['note-column-last']} ${styles['flex-1']} ${styles['align-start']}`}
              >
                <Form.Group
                  className={`mb-2 ${styles['inline-group']} ${styles['note-data']} ${styles['height-auto']}`}
                >
                  <Form.Label>Current Meds</Form.Label>
                  <FormControl
                    name='currentMeds'
                    type='number'
                    placeholder='...'
                    value={stateData?.currentMeds}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>

                <Form.Group className={styles['inline-group']}>
                  <Form.Label>Last Meal</Form.Label>
                  <FormControl
                    name='lastmeal'
                    placeholder='...'
                    value={stateData?.lastmeal}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>
              </div>
            </div>

            {/* Sixth Row */}
            <div className={styles['note-row']}>
              <div
                className={`${styles['note-column-last']} ${styles['flex-1']} ${styles['align-start']}`}
              >
                <Form.Group className={styles['note-data']}>
                  <Form.Label>Review of Systems (If checked please select symptoms)</Form.Label>
                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='constitutional'
                      label='Constitutional'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      checked={stateData?.reviewOfSystems?.constitutional?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'constitutional');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='fever'
                        label='Fever'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.constitutional?.isTrue}
                        checked={stateData?.reviewOfSystems?.constitutional?.symptoms?.fever}
                        onChange={(e) => {
                          handleChange(e, 'constitutional');
                        }}
                      />

                      <Form.Check
                        id='chills'
                        label='Chills'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.constitutional?.isTrue}
                        checked={stateData?.reviewOfSystems?.constitutional?.symptoms?.chills}
                        onChange={(e) => {
                          handleChange(e, 'constitutional');
                        }}
                      />

                      <Form.Check
                        id='weightLoss'
                        label='Weight Loss'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.constitutional?.isTrue}
                        checked={stateData?.reviewOfSystems?.constitutional?.symptoms?.weightLoss}
                        onChange={(e) => {
                          handleChange(e, 'constitutional');
                        }}
                      />

                      <Form.Check
                        id='fatigue'
                        label='Fatigue'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.constitutional?.isTrue}
                        checked={stateData?.reviewOfSystems?.constitutional?.symptoms?.fatigue}
                        onChange={(e) => {
                          handleChange(e, 'constitutional');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='eyes'
                      label='Eyes'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      checked={stateData?.reviewOfSystems?.eyes?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'eyes');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='acuityChanges'
                        label='Acuity Changes'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.eyes?.isTrue}
                        checked={stateData?.reviewOfSystems?.eyes?.symptoms?.acuityChanges}
                        onChange={(e) => {
                          handleChange(e, 'eyes');
                        }}
                      />

                      <Form.Check
                        id='glasses'
                        label='Glasses'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.eyes?.isTrue}
                        checked={stateData?.reviewOfSystems?.eyes?.symptoms?.glasses}
                        onChange={(e) => {
                          handleChange(e, 'eyes');
                        }}
                      />

                      <Form.Check
                        id='symptoms'
                        label='Symptoms'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.eyes?.isTrue}
                        checked={stateData?.reviewOfSystems?.eyes?.symptoms?.symptoms}
                        onChange={(e) => {
                          handleChange(e, 'eyes');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='earsNoseThroat'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Ears, Nose, Throat'
                      checked={stateData?.reviewOfSystems?.earsNoseThroat?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'earsNoseThroat');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='hearingLoss'
                        label='Hearing Loss'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.earsNoseThroat?.isTrue}
                        checked={stateData?.reviewOfSystems?.earsNoseThroat?.symptoms?.hearingLoss}
                        onChange={(e) => {
                          handleChange(e, 'earsNoseThroat');
                        }}
                      />

                      <Form.Check
                        id='soreThroat'
                        label='Sore Throat'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.earsNoseThroat?.isTrue}
                        checked={stateData?.reviewOfSystems?.earsNoseThroat?.symptoms?.soreThroat}
                        onChange={(e) => {
                          handleChange(e, 'earsNoseThroat');
                        }}
                      />

                      <Form.Check
                        id='discharge'
                        label='Discharge'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.earsNoseThroat?.isTrue}
                        checked={stateData?.reviewOfSystems?.earsNoseThroat?.symptoms?.discharge}
                        onChange={(e) => {
                          handleChange(e, 'earsNoseThroat');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='cardiovascular'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Cardiovascular'
                      checked={stateData?.reviewOfSystems?.cardiovascular?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'cardiovascular');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='chestPain'
                        label='Chest Pain'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.cardiovascular?.isTrue}
                        checked={stateData?.reviewOfSystems?.cardiovascular?.symptoms?.chestPain}
                        onChange={(e) => {
                          handleChange(e, 'cardiovascular');
                        }}
                      />

                      <Form.Check
                        id='palpitations'
                        label='Palpitations'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.cardiovascular?.isTrue}
                        checked={stateData?.reviewOfSystems?.cardiovascular?.symptoms?.palpitations}
                        onChange={(e) => {
                          handleChange(e, 'cardiovascular');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='respiratory'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Respiratory'
                      checked={stateData?.reviewOfSystems?.respiratory?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'respiratory');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='SOB'
                        label='SOB'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.respiratory?.isTrue}
                        checked={stateData?.reviewOfSystems?.respiratory?.symptoms?.SOB}
                        onChange={(e) => {
                          handleChange(e, 'respiratory');
                        }}
                      />

                      <Form.Check
                        id='cough'
                        label='Cough'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.respiratory?.isTrue}
                        checked={stateData?.reviewOfSystems?.respiratory?.symptoms?.cough}
                        onChange={(e) => {
                          handleChange(e, 'respiratory');
                        }}
                      />

                      <Form.Check
                        id='sputum'
                        label='Sputum'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.respiratory?.isTrue}
                        checked={stateData?.reviewOfSystems?.respiratory?.symptoms?.sputum}
                        onChange={(e) => {
                          handleChange(e, 'respiratory');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='gastrointestinal'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Gastrointestinal'
                      checked={stateData?.reviewOfSystems?.gastrointestinal?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'gastrointestinal');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='nausea'
                        label='Nausea'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.gastrointestinal?.isTrue}
                        checked={stateData?.reviewOfSystems?.gastrointestinal?.symptoms?.nausea}
                        onChange={(e) => {
                          handleChange(e, 'gastrointestinal');
                        }}
                      />

                      <Form.Check
                        id='vomiting'
                        label='Vomiting'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.gastrointestinal?.isTrue}
                        checked={stateData?.reviewOfSystems?.gastrointestinal?.symptoms?.vomiting}
                        onChange={(e) => {
                          handleChange(e, 'gastrointestinal');
                        }}
                      />

                      <Form.Check
                        id='diarrhea'
                        label='Diarrhea'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.gastrointestinal?.isTrue}
                        checked={stateData?.reviewOfSystems?.gastrointestinal?.symptoms?.diarrhea}
                        onChange={(e) => {
                          handleChange(e, 'gastrointestinal');
                        }}
                      />

                      <Form.Check
                        id='abdominalPain'
                        label='Abdominal Pain'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.gastrointestinal?.isTrue}
                        checked={
                          stateData?.reviewOfSystems?.gastrointestinal?.symptoms?.abdominalPain
                        }
                        onChange={(e) => {
                          handleChange(e, 'gastrointestinal');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='genitourinary'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Genitourinary'
                      checked={stateData?.reviewOfSystems?.genitourinary?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'genitourinary');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='dysuria'
                        label='Dysuria'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.genitourinary?.isTrue}
                        checked={stateData?.reviewOfSystems?.genitourinary?.symptoms?.dysuria}
                        onChange={(e) => {
                          handleChange(e, 'genitourinary');
                        }}
                      />

                      <Form.Check
                        id='frequency'
                        label='Frequency'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.genitourinary?.isTrue}
                        checked={stateData?.reviewOfSystems?.genitourinary?.symptoms?.frequency}
                        onChange={(e) => {
                          handleChange(e, 'genitourinary');
                        }}
                      />

                      <Form.Check
                        id='urgency'
                        label='Urgency'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.genitourinary?.isTrue}
                        checked={stateData?.reviewOfSystems?.genitourinary?.symptoms?.urgency}
                        onChange={(e) => {
                          handleChange(e, 'genitourinary');
                        }}
                      />

                      <Form.Check
                        id='nocturia'
                        label='Nocturia'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.genitourinary?.isTrue}
                        checked={stateData?.reviewOfSystems?.genitourinary?.symptoms?.nocturia}
                        onChange={(e) => {
                          handleChange(e, 'genitourinary');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='musculoskeletal'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Musculoskeletal'
                      checked={stateData?.reviewOfSystems?.musculoskeletal?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'musculoskeletal');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='musclePain'
                        label='Muscle Pain'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.musculoskeletal?.isTrue}
                        checked={stateData?.reviewOfSystems?.musculoskeletal?.symptoms?.musclePain}
                        onChange={(e) => {
                          handleChange(e, 'musculoskeletal');
                        }}
                      />

                      <Form.Check
                        id='jointPain'
                        label='Joint Pain'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.musculoskeletal?.isTrue}
                        checked={stateData?.reviewOfSystems?.musculoskeletal?.symptoms?.jointPain}
                        onChange={(e) => {
                          handleChange(e, 'musculoskeletal');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='skin'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Skin'
                      checked={stateData?.reviewOfSystems?.skin?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'skin');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='rash'
                        label='Rash'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.skin?.isTrue}
                        checked={stateData?.reviewOfSystems?.skin?.symptoms?.rash}
                        onChange={(e) => {
                          handleChange(e, 'skin');
                        }}
                      />

                      <Form.Check
                        id='lesions'
                        label='Lesions'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.skin?.isTrue}
                        checked={stateData?.reviewOfSystems?.skin?.symptoms?.lesions}
                        onChange={(e) => {
                          handleChange(e, 'skin');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='neurologic'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Neurologic'
                      checked={stateData?.reviewOfSystems?.neurologic?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'neurologic');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='headache'
                        label='Headache'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.neurologic?.isTrue}
                        checked={stateData?.reviewOfSystems?.neurologic?.symptoms?.headache}
                        onChange={(e) => {
                          handleChange(e, 'neurologic');
                        }}
                      />

                      <Form.Check
                        id='dizziness'
                        label='Dizziness'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.neurologic?.isTrue}
                        checked={stateData?.reviewOfSystems?.neurologic?.symptoms?.dizziness}
                        onChange={(e) => {
                          handleChange(e, 'neurologic');
                        }}
                      />

                      <Form.Check
                        id='seizure'
                        label='Seizure'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.neurologic?.isTrue}
                        checked={stateData?.reviewOfSystems?.neurologic?.symptoms?.seizure}
                        onChange={(e) => {
                          handleChange(e, 'neurologic');
                        }}
                      />

                      <Form.Check
                        id='focalWeakness'
                        label='Focal Weakness'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.neurologic?.isTrue}
                        checked={stateData?.reviewOfSystems?.neurologic?.symptoms?.focalWeakness}
                        onChange={(e) => {
                          handleChange(e, 'neurologic');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='endocrine'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Endocrine'
                      checked={stateData?.reviewOfSystems?.endocrine?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'endocrine');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='depression'
                        label='Depression'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.endocrine?.isTrue}
                        checked={stateData?.reviewOfSystems?.endocrine?.symptoms?.depression}
                        onChange={(e) => {
                          handleChange(e, 'endocrine');
                        }}
                      />

                      <Form.Check
                        id='anxiety'
                        label='Anxiety'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.endocrine?.isTrue}
                        checked={stateData?.reviewOfSystems?.endocrine?.symptoms?.anxiety}
                        onChange={(e) => {
                          handleChange(e, 'endocrine');
                        }}
                      />

                      <Form.Check
                        id='psychHistory'
                        label='Psych History'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.endocrine?.isTrue}
                        checked={stateData?.reviewOfSystems?.endocrine?.symptoms?.psychHistory}
                        onChange={(e) => {
                          handleChange(e, 'endocrine');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='hematology'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Hematology'
                      checked={stateData?.reviewOfSystems?.hematology?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'hematology');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='adenopathy'
                        label='Adenopathy'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.hematology?.isTrue}
                        checked={stateData?.reviewOfSystems?.hematology?.symptoms?.adenopathy}
                        onChange={(e) => {
                          handleChange(e, 'hematology');
                        }}
                      />

                      <Form.Check
                        id='bruising'
                        label='Bruising'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.hematology?.isTrue}
                        checked={stateData?.reviewOfSystems?.hematology?.symptoms?.bruising}
                        onChange={(e) => {
                          handleChange(e, 'hematology');
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className={`${styles['inline-check-with-input']} ${styles['gap-2']}`}>
                    <Form.Check
                      type='checkbox'
                      id='immnuneAllergy'
                      name='reviewOfSystems'
                      className={styles['head-checkbox']}
                      label='Immnune / Allergy'
                      checked={stateData?.reviewOfSystems?.immnuneAllergy?.isTrue}
                      onChange={(e) => {
                        handleChange(e, 'immnuneAllergy');
                      }}
                    />

                    <Form.Group className={styles['inline-checkbox']}>
                      <Form.Check
                        id='urticaria'
                        label='Urticaria'
                        type='checkbox'
                        name='reviewOfSystemsSub'
                        className={styles['form-check']}
                        disabled={!stateData?.reviewOfSystems?.immnuneAllergy?.isTrue}
                        checked={stateData?.reviewOfSystems?.immnuneAllergy?.symptoms?.urticaria}
                        onChange={(e) => {
                          handleChange(e, 'immnuneAllergy');
                        }}
                      />
                    </Form.Group>
                  </div>
                </Form.Group>
              </div>
            </div>

            {/* Seventh Row */}
            {/* <div className={styles['note-row']}>
              <div className={`${styles['note-column']} ${styles['flex-2']}`}>
                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>Name: </Form.Label>
                  <FormControl
                    name='name'
                    placeholder='...'
                    value={stateData?.name}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>
              </div>
              <div className={`${styles['note-column']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>Age</Form.Label>
                  <FormControl
                    name='age'
                    type='number'
                    placeholder='...'
                    value={stateData?.age}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>
              </div>
              <div className={`${styles['note-column-last']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>Hosp. rec #</Form.Label>
                  <FormControl
                    placeholder='...'
                    name='hospitalRecord'
                    onChange={handleChange}
                    className={styles['note-input']}
                    value={stateData?.hospitalRecord}
                  />
                </Form.Group>
              </div>
            </div> */}

            {/* Eight Row */}
            {/* <div className={styles['note-row']}>
              <div className={`${styles['note-column']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>Address: </Form.Label>
                  <FormControl
                    name='address'
                    placeholder='...'
                    value={stateData?.address}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>
              </div>
              <div className={`${styles['note-column']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>Date of Birth</Form.Label>
                  <FormControl
                    name='dateOfBirth'
                    type='date'
                    placeholder='...'
                    value={stateData?.dateOfBirth}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>
              </div>
            </div> */}

            {/* nine Row */}
            <div className={`${styles['nine-row-box']} ${styles['border-down']}`}>
              <div className={`${styles['left-side']}  `}>
                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>GENERAL APPEARANCE & SKIN: </Form.Label>
                  <FormControl
                    name='general_appearance_skin'
                    placeholder='...'
                    value={stateData?.general_appearance_skin}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>MENTAL STATUS & NEURO-EXAM: </Form.Label>
                  <FormControl
                    name='mental_status'
                    placeholder='...'
                    value={stateData?.mental_status}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>HEAD, EYES, EARS, NOSE, THROAT: </Form.Label>
                  <FormControl
                    name='head_eye_ears_neuroexam'
                    placeholder='...'
                    value={stateData?.head_eye_ears_neuroexam}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>CHEST/LUNGS: </Form.Label>
                  <FormControl
                    name='chest_lungs'
                    placeholder='...'
                    value={stateData?.chest_lungs}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>HEART: </Form.Label>
                  <FormControl
                    name='heart'
                    placeholder='...'
                    value={stateData?.heart}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>ABDOMEN: </Form.Label>
                  <FormControl
                    name='abdomen'
                    placeholder='...'
                    value={stateData?.abdomen}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>RECTUM AND GENITALIA: </Form.Label>
                  <FormControl
                    name='rectum_genitalias'
                    placeholder='...'
                    value={stateData?.rectum_genitalias}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>EXTREMITIES: </Form.Label>
                  <FormControl
                    name='extremities'
                    placeholder='...'
                    value={stateData?.extremities}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>
              </div>

              <div className={`${styles['right-side']} `}>
                <div>
                  <h6>PUPIL TEST</h6>

                  <Form.Group className={styles['note-data-inline']}>
                    <Form.Label>EQUAL: </Form.Label>
                    <Form.Check
                      label='YES'
                      value='YES'
                      name='equal'
                      type='radio'
                      onChange={handleChange}
                      checked={stateData?.equal === 'YES' ? true : false}
                    />
                    <Form.Check
                      label='NO'
                      value='NO'
                      name='equal'
                      type='radio'
                      onChange={handleChange}
                      checked={stateData?.equal === 'NO' ? true : false}
                    />
                  </Form.Group>
                  <Form.Group className={styles['note-data-inline']}>
                    <Form.Label>REACTIVE: </Form.Label>
                    <Form.Check
                      label='YES'
                      value='YES'
                      name='reactive'
                      type='radio'
                      onChange={handleChange}
                      checked={stateData?.reactive === 'YES' ? true : false}
                    />
                    <Form.Check
                      label='NO'
                      value='NO'
                      name='reactive'
                      type='radio'
                      onChange={handleChange}
                      checked={stateData?.reactive === 'NO' ? true : false}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className={`${styles['glassgow-score']}`}>
                <h6>GLASGOW COMA SCORE</h6>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>EYE OPENING: </Form.Label>
                  <Form.Control
                    type='number'
                    name='eye_opening'
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                    onChange={(e) => {
                      if (e.target.value > 6) {
                        return;
                      }

                      handleChange(e);
                    }}
                    value={stateData?.eye_opening}
                    className={styles['input-number']}
                  />
                  <Form.Label>/ 6</Form.Label>
                </Form.Group>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>BEST VERBAL RESPONSE: </Form.Label>
                  <Form.Control
                    type='number'
                    name='verbal_response'
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                    onChange={(e) => {
                      if (e.target.value > 5) {
                        return;
                      }

                      handleChange(e);
                    }}
                    value={stateData?.verbal_response}
                    className={styles['input-number']}
                  />
                  <Form.Label>/ 5</Form.Label>
                </Form.Group>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>BEST MOTOR RESPONSE: </Form.Label>
                  <Form.Control
                    type='number'
                    name='best_motor_response'
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                    onChange={(e) => {
                      if (e.target.value > 14) {
                        return;
                      }

                      handleChange(e);
                    }}
                    value={stateData?.best_motor_response}
                    className={styles['input-number']}
                  />
                  <Form.Label>/ 14</Form.Label>
                </Form.Group>

                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>TOTAL: </Form.Label>
                  <Form.Control
                    type='number'
                    name='glasgow_total'
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                    onChange={(e) => {
                      if (e.target.value > 14) {
                        return;
                      }

                      handleChange(e);
                    }}
                    value={stateData?.glasgow_total}
                    className={styles['input-number']}
                  />
                  <Form.Label>/ 15</Form.Label>
                </Form.Group>
              </div>
              <div className={`${styles['drawable-area']}`}>
                <p>Motor</p>
                <img src={tinyStick} className={styles['stickman']} />
              </div>
              <div className={`${styles['drawable-area-2']}`}>
                <p>Sensory</p>
                <img src={tinyStick} className={styles['stickman']} />
              </div>
              <div className={`${styles['drawable-area-3']}`}>
                <p>Reflexes</p>
                <img src={tinyStick} className={styles['stickman']} />
              </div>
            </div>

            {/* tenth Row */}
            <div className={styles['note-row']}>
              <div className={`${styles['note-column-last']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>Working Impressions: </Form.Label>
                  <FormControl
                    name='workingImpression'
                    placeholder='...'
                    value={stateData?.workingImpression}
                    onChange={handleChange}
                    className={styles['note-input']}
                  />
                </Form.Group>
              </div>
            </div>

            {/* eleventh Row */}
            {/* <div className={styles['note-row']}>
              <div className={`${styles['note-column-last']} ${styles['flex-1']}`}>
                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>Time</Form.Label>
                </Form.Group>
              </div>

              <div className={`${styles['note-column-last']} ${styles['flex-2']}`}>
                <Form.Group className={styles['note-data-inline']}>
                  <Form.Label>Observation / Remarks</Form.Label>
                </Form.Group>
              </div>

              <div className={`${styles['note-column-last']} ${styles['flex-2']}`}>
                <Form.Group className={`${styles['note-data-inline']}`}>
                  <Form.Label>Physician Orders</Form.Label>
                  <AddSVG
                    className={styles['svg']}
                    onClick={() => {
                      addMultiple('observations');
                    }}
                  />
                </Form.Group>
              </div>
            </div> */}

            {/* Twelve Row */}
            {/* <div className={styles['note-row-last']}>
              <div className={`${styles['note-column-last']} ${styles['flex-1']}`}>
                <ConditionalRender
                  condition={haveObservations}
                  renderIf={
                    <>
                      {stateData?.observations.map((data, idx) => {
                        return (
                          <div className={styles['multiple-inputs']}>
                            <div className={styles['observation-inputs']}>
                              <div className={styles['flex-1']}>
                                <FormControl
                                  name='time'
                                  type='time'
                                  placeholder='...'
                                  value={data?.time}
                                  className={styles['note-input']}
                                  onChange={handleMultiChange(data, 'observations')}
                                />
                              </div>
                              <div className={styles['flex-2']}>
                                <FormControl
                                  as='textarea'
                                  placeholder='...'
                                  name='observationRemarks'
                                  value={data?.observationRemarks}
                                  className={styles['note-input']}
                                  onChange={handleMultiChange(data, 'observations')}
                                />
                              </div>
                              <div className={`${styles['flex-2']} ${styles['last-flex']}`}>
                                <FormControl
                                  placeholder='...'
                                  name='physicianOrders'
                                  value={data?.physicianOrders}
                                  className={styles['note-input']}
                                  onChange={handleMultiChange(data, 'observations')}
                                />

                                <ConditionalRender
                                  condition={stateData?.observations.length > 1}
                                  renderIf={
                                    <MinusSVG
                                      className={styles['svg']}
                                      onClick={() => {
                                        removeDataFromMultiple(data, 'observations');
                                      }}
                                    />
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  }
                />
              </div>
            </div> */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NoteForm;
