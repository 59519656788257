// import components below
import SearchPatientTabs from './Tabs';

// import assets below
import './style.scss';

// main component
const SearchPatient = () => {
  return (
    <div className='search-patient'>
      <SearchPatientTabs />
    </div>
  );
};

export default SearchPatient;
