/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormControl, InputGroup, Tabs, Tab, Button } from 'react-bootstrap';
import {
  showUsers,
  getACLState,
  getRoles,
  getDepartments,
  getLogs,
  getPermissions,
  getPermissionsCategories,
} from 'reducers/aclReducer';

import TabsUI from 'components/UI/TabsUI';
import ProfileTable from '../ProfileTable';
import Tables from 'components/ACLComponents/Tables';
import ConditionalRender from 'components/ConditionalRender';

import './index.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

const samplePhoto = 'https://picsum.photos/100';

const UserAccount = ({ isAllUsers, isNewUsers, isDeniedUsers }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [permissionSearch, setPermissionSearch] = useState('');
  const [permissionCategories, setPermissionCategories] = useState('');
  const [roles, setRoles] = useState('');
  const [showResultsNumber, setShowResultsNumber] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [showScreen, setShowScreen] = useState(0);
  const [userData, setUserData] = useState();

  const isDefault = showScreen === 0;
  const isViewAccess = showScreen === 1;

  const propertyTabs = ['Property Management'];

  const interval = 300;

  const searchAllTabs = () => {
    let promise = Promise.resolve();

    propertyTabs.forEach((el) => {
      promise = promise.then(() => {
        arrDispatch(el);

        return new Promise((resolve) => {
          setTimeout(resolve, interval);
        });
      });
    });
  };

  const searchPermission = () => {
    const defaultParams = { per_page: 10, q: permissionSearch };
    let params = {};

    if (permissionCategories !== '') {
      params = { ...defaultParams, category: [permissionCategories] };
    } else {
      params = { ...defaultParams };
    }

    dispatch(getPermissions(params));
  };

  const arrDispatch = (role) => {
    dispatch(
      showUsers({
        url: `q=${search}${sortBy && `&status=${sortBy}`}&per_page=${showResultsNumber}${
          isNewUsers ? `&is_new=1` : ``
        }${
          role !== 'Property Management' ? `&roles[]=${role}` : ''
        }&department[]=Property Management`,
        role: role,
      }),
    );
  };

  const changePage = (page, type) => {
    const apiType = type === 'All' ? 'Property Management' : type;

    if (isAllUsers) {
      dispatch(
        showUsers({
          url: `q=${search}&per_page=${showResultsNumber}${sortBy && `&status=${sortBy}`}${
            type !== 'All' ? `&roles[]=${type}` : ''
          }&page=${page}&department[]=Property Management`,
          role: apiType,
        }),
      );
    } else if (isNewUsers) {
      dispatch(
        showUsers({
          url: `q=${search}&per_page=${showResultsNumber}${
            sortBy && `&status=${sortBy}`
          }&page=${page}&department[]=Property Management&is_new=1`,
          role: 'New Users Property Management',
        }),
      );
    } else if (isDeniedUsers) {
      dispatch(
        showUsers({
          url: `q=${search}&per_page=${showResultsNumber}${
            sortBy && `&status=${sortBy}`
          }&page=${page}&department[]=Property Management`,
          role: 'Denied Users Property Management',
        }),
      );
    }
  };

  const changePermissionPage = (page) => {
    const defaultParams = { per_page: 10, q: permissionSearch, page: page };
    let params = {};

    if (permissionCategories !== '') {
      params = { ...defaultParams, category: [permissionCategories] };
    } else {
      params = { ...defaultParams };
    }

    dispatch(getPermissions(params));
  };

  const getDatas = () => {
    if (isAllUsers) {
      searchAllTabs();
    } else if (isNewUsers) {
      dispatch(
        showUsers({
          url: `q=${search}&is_new=1&per_page=${showResultsNumber}${
            sortBy && `&status=${sortBy}`
          }&department[]=Property Management`,
          role: 'New Users Property Management',
        }),
      );
    } else if (isDeniedUsers) {
      dispatch(
        showUsers({
          url: `q=${search}&is_denied=1&per_page=${showResultsNumber}${
            sortBy && `&status=${sortBy}`
          }&department[]=Property Management`,
          role: 'Denied Users Property Management',
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(getPermissionsCategories(''));
    dispatch(getRoles(''));
    dispatch(getLogs('?per_page=10'));
    dispatch(getDepartments(''));
    dispatch(
      getPermissions({
        per_page: 10,
        q: permissionSearch,
      }),
    );
  }, []);

  useEffect(() => {
    getDatas();
  }, [isAllUsers, isNewUsers, isDeniedUsers]);

  let timer = null;
  const delay = 500;

  useEffect(() => {
    timer = setTimeout(() => {
      getDatas();
    }, delay);

    return () => clearTimeout(timer);
  }, [search, roles, showResultsNumber, sortBy]);

  useEffect(() => {
    timer = setTimeout(() => {
      searchPermission();
    }, delay);

    return () => clearTimeout(timer);
  }, [permissionSearch, permissionCategories]);

  const storeData = useSelector(getACLState);
  const permissions = storeData.permissions;
  const permissionsCategories = storeData.permissionCategories;
  const uniqueCategories = [...new Set(permissionsCategories)];

  const tables = [
    {
      id: 1,
      condition: isAllUsers,
      defaultKey: 'All',
      tabs: [
        {
          id: 1,
          type: 'All',
          data: storeData?.propertyUsers,
        },
      ],
    },
    {
      id: 2,
      condition: isNewUsers,
      defaultKey: 'New Users',
      tabs: [
        {
          id: 1,
          type: 'New Users',
          data: storeData?.propertyNewUsers,
        },
      ],
    },
    {
      id: 3,
      condition: isDeniedUsers,
      defaultKey: 'Denied Users',
      tabs: [
        {
          id: 1,
          type: 'Denied Users',
          data: storeData?.propertyDeniedUsers,
        },
      ],
    },
  ];

  return (
    <div className='mt-4 property-management'>
      <ConditionalRender
        condition={isDefault}
        renderIf={
          <>
            <Row className='user-header'>
              <Col sm={6} className='p-0 text'>
                <h2>
                  Property Management
                  <span>Find all your user account&apos;s and their associated roles.</span>
                </h2>
              </Col>
              <Col sm={6} className='p-0 search-cont'>
                <Form.Group className='basic-search'>
                  <InputGroup>
                    <FormControl
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      type='text'
                      placeholder='Search'
                      className='bg-grey'
                    />
                    <InputGroup.Text
                      onClick={() => {
                        searchAllTabs();
                      }}
                    >
                      <MagnifyingGlassSVG />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <Form.Group className='input-group-cont' style={{ width: '100%' }}>
                  <Form.Select
                    value={sortBy}
                    onChange={(e) => {
                      setSortBy(e.target.value);
                    }}
                  >
                    <option value=''>Sort By</option>
                    <option value='active'>Active</option>
                    <option value='inactive'>Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className='user-tables'>
              <Col className='p-0 table-tabs'>
                <TabsUI>
                  {tables.map(({ id, condition, defaultKey, tabs }) => {
                    if (condition) {
                      return (
                        <Tabs key={id} defaultActiveKey={defaultKey}>
                          {tabs &&
                            tabs.map((data) => {
                              return (
                                <Tab
                                  key={data.id}
                                  eventKey={data.type}
                                  title={`${data.type} (${
                                    data?.data?.meta?.pagination?.total || '0'
                                  })`}
                                >
                                  <Tables
                                    type={data.type}
                                    getDatas={getDatas}
                                    tableData={data.data}
                                    isAllUsers={isAllUsers}
                                    isNewUsers={isNewUsers}
                                    changePage={changePage}
                                    setUserData={setUserData}
                                    searchAllTabs={searchAllTabs}
                                    setShowScreen={setShowScreen}
                                    isDeniedUsers={isDeniedUsers}
                                    showResultsNumber={showResultsNumber}
                                    setShowResultsNumber={setShowResultsNumber}
                                  />
                                </Tab>
                              );
                            })}
                        </Tabs>
                      );
                    }

                    return;
                  })}
                </TabsUI>
              </Col>
            </Row>
          </>
        }
      />

      <ConditionalRender
        condition={isViewAccess}
        renderIf={
          <>
            <Row className='user-header'>
              <Col sm={1} className='p-0 text-2'>
                <img
                  src={userData?.attributes?.profile_picture || samplePhoto}
                  alt='account photo'
                />
              </Col>
              <Col sm={2} className='p-0 text-2'>
                <p>
                  {userData?.attributes?.full_name} <span>Name</span>
                </p>
              </Col>
              <Col sm={3} className='p-0 text-2'>
                <p>
                  {userData?.attributes?.department} <span>Assigned Management</span>
                </p>
              </Col>
              <Col sm={2} className='p-0 text-2'>
                <p>
                  {userData?.attributes?.role} <span>User Role</span>
                </p>
              </Col>
              <Col sm={2} className='p-0 text-2'>
                <p className={userData?.status}>
                  {userData?.attributes?.is_active ? 'Active' : 'Inactive'} <span>Status</span>
                </p>
              </Col>
              <Col className='p-0 button-cont'>
                <Button
                  onClick={() => {
                    setShowScreen(0);
                  }}
                  className='back-to-list'
                >
                  {`< Back to List`}
                </Button>
              </Col>
            </Row>

            <Row className='user-header mt-4'>
              <Col sm={8} className='p-0 text'>
                <h2>
                  ADMISSION
                  <span>Select action to be access</span>
                </h2>
              </Col>
              <Col sm={4} className='p-0 search-cont'>
                <Form.Group className='basic-search'>
                  <InputGroup>
                    <FormControl
                      onChange={(e) => {
                        setPermissionSearch(e.target.value);
                      }}
                      type='text'
                      placeholder='Search'
                      className='bg-grey'
                    />
                    <InputGroup.Text
                      onClick={() => {
                        searchPermission();
                      }}
                    >
                      <MagnifyingGlassSVG />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <Form.Group className='blue-background input-group-cont' style={{ width: '100%' }}>
                  <Form.Select
                    value={permissionCategories}
                    onChange={(e) => {
                      setPermissionCategories(e.target.value);
                    }}
                  >
                    <option value=''>All</option>
                    {uniqueCategories.length > 0 &&
                      uniqueCategories.map((data, idx) => {
                        return (
                          <option key={idx} value={data}>
                            {data}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className='user-tables'>
              <Col className='p-0 table-tabs'>
                <ProfileTable
                  userData={userData}
                  permissions={permissions}
                  showResultsNumber={showResultsNumber}
                  setShowResultsNumber={setShowResultsNumber}
                  changePermissionPage={changePermissionPage}
                />
              </Col>
            </Row>
          </>
        }
      />
    </div>
  );
};

UserAccount.defaultProps = {
  isAllUsers: true,
  isNewUsers: false,
};

UserAccount.propTypes = {
  isAllUsers: PropTypes.bool,
  isNewUsers: PropTypes.bool,
};

export default UserAccount;
