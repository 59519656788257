import constant from 'constant';
import { useParams } from 'react-router-dom';

import Layout from 'components/Layout';
import Expenses from 'pages/Reports/components/Expenses';
import Revenues from 'pages/Reports/components/Revenues';
import DailyCensus from 'pages/Reports/components/DailyCensus';
import StaffingPattern from 'pages/Reports/components/StaffingPattern';
import HospitalOperation from 'pages/Reports/components/HospitalOperation';
import GeneralInformation from 'pages/Reports/components/GeneralInformation';

// main component
const ViewReport = () => {
  const params = useParams();

  switch (params.type) {
    case constant.GENERALINFORMATION:
      return <GeneralInformation />;
    case constant.HOSPITALOPERATION:
      return <HospitalOperation />;
    case constant.CENSUS:
      return <DailyCensus />;
    case constant.REVENUES:
      return <Revenues />;
    case constant.EXPENSES:
      return <Expenses />;
    case constant.STAFFINGPATTERNS:
      return <StaffingPattern />;
  }

  return <Layout pageTitle='view report'></Layout>;
};

export default ViewReport;
