/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getStockAdjustmentList = createAsyncThunk(
  'inventory/getStockAdjustmentList',
  async (params = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/restock_items/adjustments`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getDepartments = createAsyncThunk(
  'inventory/getDepartments',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/department`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
