/* eslint-disable */
import React from 'react';
import Table from 'react-bootstrap/Table';

// import './PrintContent.scss';

export class PrintContent extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableCol, visitData } = this.props;
        const { 
            REACT_APP_HOSPITAL_NAME, 
            REACT_APP_HOSPITAL_STREET_ADDRESS,
            REACT_APP_LOGO,
        } = process.env;

        console.log('print title', REACT_APP_HOSPITAL_NAME);
        console.log('print subtitle', REACT_APP_HOSPITAL_STREET_ADDRESS);
        console.log('visit data', REACT_APP_HOSPITAL_STREET_ADDRESS);

        return (
            <div className='print_body' ref={(el) => (this.printRef = el)}>
                <header>
                    <div className="header_logo">
                        <img src={REACT_APP_LOGO} alt='print_logo' />
                    </div>
                    <div className="header_title">{REACT_APP_HOSPITAL_NAME}</div>
                    <div className="header_subtitle">{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
                </header>
                <Table bordered>
                    <thead>
                        <tr>
                            <th colSpan={2}>Visit Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>HOSPITAL SERVICE</td>
                            <td>{visitData?.hospital_service ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>EMPLOYER</td>
                            <td>{visitData?.employer ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>EMPLOYER ADDRESS</td>
                            <td>{visitData?.employer_address ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>EMPLOYER CONTACT NUMBER</td>
                            <td>{visitData?.employer_contact_no ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>FATHER NAME</td>
                            <td>{visitData?.father_name ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>MOTHER MAIDEN NAME</td>
                            <td>{visitData?.mother_maiden_name ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>PARENT CONTACT NUMBER</td>
                            <td>{visitData?.parent_contact_no ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>SPOUSE NAME</td>
                            <td>{visitData?.spouse_name ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>SPOUSE ADDRESS</td>
                            <td>{visitData?.spouse_address ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>SPOUSE CONTACT NUMBER</td>
                            <td>{visitData?.spouse_contact_no ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>ADMISSION DATE</td>
                            <td>{visitData?.admission_date ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>ADMISSION TYPE</td>
                            <td>{visitData?.admission_type ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>ATTENDING CLERK</td>
                            <td>{visitData?.attending_clerk ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>DATA FURNISHED BY</td>
                            <td>{visitData?.date_furnished_by ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>FURNISHED BY ADDRESS</td>
                            <td>{visitData?.furnished_by_address ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>RELATION TO PATIENT</td>
                            <td>{visitData?.relation_to_patient ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>INFORMANT SIGNATURE</td>
                            <td>{visitData?.informant_signature ?? 'N/A'}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }
}