/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { 
  updateJobOrder,
  getRegisterList, 
  getDisposalList,
  getJobOrderList, 
  getEntryDateList, 
  getInventoryList, 
  addJobOrderItems,
  getEquipmentList, 
  addDisposalItems,
  addEquipmentItems,
  addInventoryItems,
  addEntryDateItems,
  getDepreciationList,
  addDepreciationItems,
  addRegistrationItems,
} from './thunks/fixedAssetsThunks';

const initialLogs = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  error: null,
};

const initialState = {
  // For Disposal List
  disposalData: [],
  disposalItem: [],
  disposalLogs: initialLogs,

  // For Add Disposal Record
  addDisposalItemLogs: initialLogs,

  // For Register List
  registerData: [],
  registerItem: [],
  registerLogs: initialLogs,

  // For Add Register Record
  addRegisterItemLogs: initialLogs,

  // For Job Order List
  jobOrderData: [],
  jobOrderItem: [],
  jobOrderLogs: initialLogs,

  // For Add Job Order Record
  addJobOrderItemLogs: initialLogs,

  // For Updating Job Order
  updateJobOrderData: [],
  updateJobOrderItem: [],
  updateJobOrderItemLogs: initialLogs,

  // For Equipment List
  equipmentData: [],
  equipmentItem: [],
  equipmentLogs: initialLogs,

  // For Add Equipment Record
  addEquipmentItemLogs: initialLogs,

  // For Inventory List
  inventoryData: [],
  inventoryItem: [],
  inventoryLogs: initialLogs,

  // For Add Inventory Record
  addInventoryItemLogs: initialLogs,

  // For entry date List
  entryDateData: [],
  entryDateItem: [],
  entryDateLogs: initialLogs,

  // For Add Entry Date Record
  addEntryDateItemLogs: initialLogs,

  // For  depreciation List
  depreciationData: [],
  depreciationItem: [],
  depreciationLogs: initialLogs,

  // For Add  depreciation Record
  addDepreciationItemLogs: initialLogs,

  // For Single Data
  singleData: [],
  singleDataIncluded: [],
  singleDataLogs: initialLogs,
};

const slice = createSlice({
  name: 'fixedAssets',
  initialState,
  reducers: {
    resetLogState: (state) => {
      state.disposalLogs = initialLogs;
      state.registerLogs = initialLogs;
      state.jobOrderLogs = initialLogs;
      state.equipmentLogs = initialLogs;
      state.inventoryLogs = initialLogs;
      state.entryDateLogs = initialLogs;
      state.depreciationLogs = initialLogs;
      state.singleDataLogs = initialLogs;
      state.updateJobOrderLogs = initialLogs;
    },
    resetAddItemLogs: (state) => {
      state.addJobOrderItemLogs = initialLogs;
      state.addDisposalItemLogs = initialLogs;
      state.addEquipmentItemLogs = initialLogs;
      state.addInventoryItemLogs = initialLogs;
      state.addRegisterItemLogs = initialLogs;
      state.addDepreciationItemLogs = initialLogs;
      state.addEntryDateItemLogs - initialLogs;
    }
  },
  extraReducers: {
    // For Disposal List
    [getDisposalList.fulfilled]: (state, action) => {
      state.disposalLogs.isSuccess = true;
      state.disposalLogs.isLoading = false;
      state.disposalData= action.payload.data;
    },
    [getDisposalList.pending]: (state) => {
      state.disposalLogs.isSuccess = false;
      state.disposalLogs.isFailed = false;
      state.disposalLogs.error = '';
      state.disposalLogs.isLoading = true;
    },
    [getDisposalList.rejected]: (state) => {
      state.disposalData= [];
      state.disposalLogs.isLoading = false;
      state.disposalLogs.isFailed = true;
    },
    
    // For Register List
    [getRegisterList.fulfilled]: (state, action) => {
      state.registerLogs.isSuccess = true;
      state.registerLogs.isLoading = false;
      state.registerData = action.payload.data;
    },
    [getRegisterList.pending]: (state) => {
      state.registerLogs.isSuccess = false;
      state.registerLogs.isFailed = false;
      state.registerLogs.error = '';
      state.registerLogs.isLoading = true;
    },
    [getRegisterList.rejected]: (state) => {
      state.registerData = [];
      state.registerLogs.isLoading = false;
      state.registerLogs.isFailed = true;
    },
    
    // For Job Order List
    [getJobOrderList.fulfilled]: (state, action) => {
      state.jobOrderLogs.isSuccess = true;
      state.jobOrderLogs.isLoading = false;
      state.jobOrderData = action.payload.data;
    },
    [getJobOrderList.pending]: (state) => {
      state.jobOrderLogs.isSuccess = false;
      state.jobOrderLogs.isFailed = false;
      state.jobOrderLogs.error = '';
      state.jobOrderLogs.isLoading = true;
    },
    [getJobOrderList.rejected]: (state) => {
      state.jobOrderData = [];
      state.jobOrderLogs.isLoading = false;
      state.jobOrderLogs.isFailed = true;
    },

    // For Update Job Order List
    [updateJobOrder.fulfilled]: (state, action) => {
      state.updateJobOrderLogs.isSuccess = true;
      state.updateJobOrderLogs.isLoading = false;
      state.updateJobOrderData = action.payload.data;
    },
    [updateJobOrder.pending]: (state) => {
      state.updateJobOrderLogs.isSuccess = false;
      state.updateJobOrderLogs.isFailed = false;
      state.updateJobOrderLogs.error = '';
      state.updateJobOrderLogs.isLoading = true;
    },
    [updateJobOrder.rejected]: (state) => {
      state.updateJobOrderData = [];
      state.updateJobOrderLogs.isLoading = false;
      state.updateJobOrderLogs.isFailed = true;
    },

    // For Equipment List
    [getEquipmentList.fulfilled]: (state, action) => {
      state.equipmentLogs.isSuccess = true;
      state.equipmentLogs.isLoading = false;
      state.equipmentData = action.payload.data;
    },
    [getEquipmentList.pending]: (state) => {
      state.equipmentLogs.isSuccess = false;
      state.equipmentLogs.isFailed = false;
      state.equipmentLogs.error = '';
      state.equipmentLogs.isLoading = true;
    },
    [getEquipmentList.rejected]: (state) => {
      state.equipmentData = [];
      state.equipmentLogs.isLoading = false;
      state.equipmentLogs.isFailed = true;
    },

    // For Inventory List
    [getInventoryList.fulfilled]: (state, action) => {
      state.inventoryLogs.isSuccess = true;
      state.inventoryLogs.isLoading = false;
      state.inventoryData = action.payload.data;
    },
    [getInventoryList.pending]: (state) => {
      state.inventoryLogs.isSuccess = false;
      state.inventoryLogs.isFailed = false;
      state.inventoryLogs.error = '';
      state.inventoryLogs.isLoading = true;
    },
    [getInventoryList.rejected]: (state) => {
      state.inventoryData = [];
      state.inventoryLogs.isLoading = false;
      state.inventoryLogs.isFailed = true;
    },

    // For Entry Date List
    [getEntryDateList.fulfilled]: (state, action) => {
      state.entryDateLogs.isSuccess = true;
      state.entryDateLogs.isLoading = false;
      state.entryDateData = action.payload.data;
    },
    [getEntryDateList.pending]: (state) => {
      state.entryDateLogs.isSuccess = false;
      state.entryDateLogs.isFailed = false;
      state.entryDateLogs.error = '';
      state.entryDateLogs.isLoading = true;
    },
    [getEntryDateList.rejected]: (state) => {
      state.entryDateData = [];
      state.entryDateLogs.isLoading = false;
      state.entryDateLogs.isFailed = true;
    },
    
    // For Depreciation List
    [getDepreciationList.fulfilled]: (state, action) => {
      state.depreciationLogs.isSuccess = true;
      state.depreciationLogs.isLoading = false;
      state.depreciationData = action.payload.data;
    },
    [getDepreciationList.pending]: (state) => {
      state.depreciationLogs.isSuccess = false;
      state.depreciationLogs.isFailed = false;
      state.depreciationLogs.error = '';
      state.depreciationLogs.isLoading = true;
    },
    [getDepreciationList.rejected]: (state) => {
      state.depreciationData = [];
      state.depreciationLogs.isLoading = false;
      state.depreciationLogs.isFailed = true;
    },
    
    // For Add Job Order Item
    [addJobOrderItems.fulfilled]: (state, action) => {
      state.addJobOrderItemLogs.isSuccess = true;
      state.addJobOrderItemLogs.isLoading = false;
    },
    [addJobOrderItems.pending]: (state) => {
      state.addJobOrderItemLogs.isLoading = true;
      state.addJobOrderItemLogs.isSuccess = false;
      state.addJobOrderItemLogs.isFailed = false;
      state.addJobOrderItemLogs.error = '';
    },
    [addJobOrderItems.rejected]: (state, action) => {
      state.addJobOrderItemLogs.isLoading = false;
      state.addJobOrderItemLogs.isFailed = true;
      state.addJobOrderItemLogs.error = action?.payload?.errors[0]?.detail;
    },
    
    // For Add Disposal Item
    [addDisposalItems.fulfilled]: (state, action) => {
      state.addDisposalItemLogs.isSuccess = true;
      state.addDisposalItemLogs.isLoading = false;
    },
    [addDisposalItems.pending]: (state) => {
      state.addDisposalItemLogs.isLoading = true;
      state.addDisposalItemLogs.isSuccess = false;
      state.addDisposalItemLogs.isFailed = false;
      state.addDisposalItemLogs.error = '';
    },
    [addDisposalItems.rejected]: (state, action) => {
      state.addDisposalItemLogs.isLoading = false;
      state.addDisposalItemLogs.isFailed = true;
      state.addDisposalItemLogs.error = action?.payload?.errors[0]?.detail;
    },
    
    // For Add Registration Item
    [addRegistrationItems.fulfilled]: (state, action) => {
      state.addRegisterItemLogs.isSuccess = true;
      state.addRegisterItemLogs.isLoading = false;
    },
    [addRegistrationItems.pending]: (state) => {
      state.addRegisterItemLogs.isLoading = true;
      state.addRegisterItemLogs.isSuccess = false;
      state.addRegisterItemLogs.isFailed = false;
      state.addRegisterItemLogs.error = '';
    },
    [addRegistrationItems.rejected]: (state, action) => {
      state.addRegisterItemLogs.isLoading = false;
      state.addRegisterItemLogs.isFailed = true;
      state.addRegisterItemLogs.error = action?.payload?.errors[0]?.detail;
    },
    
    // For Add Equipment Item
    [addEquipmentItems.fulfilled]: (state, action) => {
      state.addEquipmentItemLogs.isSuccess = true;
      state.addEquipmentItemLogs.isLoading = false;
    },
    [addEquipmentItems.pending]: (state) => {
      state.addEquipmentItemLogs.isLoading = true;
      state.addEquipmentItemLogs.isSuccess = false;
      state.addEquipmentItemLogs.isFailed = false;
      state.addEquipmentItemLogs.error = '';
    },
    [addEquipmentItems.rejected]: (state, action) => {
      state.addEquipmentItemLogs.isLoading = false;
      state.addEquipmentItemLogs.isFailed = true;
      state.addEquipmentItemLogs.error = action?.payload?.errors[0]?.detail;
    },
    
    // For Add Inventory Item
    [addInventoryItems.fulfilled]: (state, action) => {
      state.addInventoryItemLogs.isSuccess = true;
      state.addInventoryItemLogs.isLoading = false;
    },
    [addInventoryItems.pending]: (state) => {
      state.addInventoryItemLogs.isLoading = true;
      state.addInventoryItemLogs.isSuccess = false;
      state.addInventoryItemLogs.isFailed = false;
      state.addInventoryItemLogs.error = '';
    },
    [addInventoryItems.rejected]: (state, action) => {
      state.addInventoryItemLogs.isLoading = false;
      state.addInventoryItemLogs.isFailed = true;
      state.addInventoryItemLogs.error = action?.payload?.errors[0]?.detail;
    },
    
    // For Add Depreciation Item
    [addDepreciationItems.fulfilled]: (state, action) => {
      state.addDepreciationItemLogs.isSuccess = true;
      state.addDepreciationItemLogs.isLoading = false;
    },
    [addDepreciationItems.pending]: (state) => {
      state.addDepreciationItemLogs.isLoading = true;
      state.addDepreciationItemLogs.isSuccess = false;
      state.addDepreciationItemLogs.isFailed = false;
      state.addDepreciationItemLogs.error = '';
    },
    [addDepreciationItems.rejected]: (state, action) => {
      state.addDepreciationItemLogs.isLoading = false;
      state.addDepreciationItemLogs.isFailed = true;
      state.addDepreciationItemLogs.error = action?.payload?.errors[0]?.detail;
    },
    
    // For Add Entry Date Item
    [addEntryDateItems.fulfilled]: (state, action) => {
      state.addEntryDateItemLogs.isSuccess = true;
      state.addEntryDateItemLogs.isLoading = false;
    },
    [addEntryDateItems.pending]: (state) => {
      state.addEntryDateItemLogs.isLoading = true;
      state.addEntryDateItemLogs.isSuccess = false;
      state.addEntryDateItemLogs.isFailed = false;
      state.addEntryDateItemLogs.error = '';
    },
    [addEntryDateItems.rejected]: (state, action) => {
      state.addEntryDateItemLogs.isLoading = false;
      state.addEntryDateItemLogs.isFailed = true;
      state.addEntryDateItemLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: fixedAssetsActions, reducer: fixedAssetsReducer } = slice;

export const { resetLogState, resetAddItemLogs } = fixedAssetsActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.fixedAssets || initialState;

export const selectFixedAssets = createSelector([selectDomain], (inventoryState) => inventoryState);

/* --------------------------------------------------------------------------------- */

export { 
  updateJobOrder,
  getDisposalList, 
  getRegisterList, 
  getJobOrderList, 
  getEntryDateList, 
  getEquipmentList, 
  getInventoryList,
  addJobOrderItems,
  addDisposalItems,
  addEquipmentItems,
  addInventoryItems,
  addEntryDateItems,
  getDepreciationList, 
  addRegistrationItems,
  addDepreciationItems,
};