/* eslint-disable */
import _ from 'lodash';
import ACLConstants from 'constant/acl';
import {
  getLogs,
  getRoles,
  showUser,
  showUsers,
  deleteUser,
  getProfile,
  updateProfile,
  getPermissions,
  getDepartments,
  approveDenyUser,
  updatePermission,
  getPermissionsCategories,
} from './thunks/aclThunks';
import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialLogs = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  error: null,
};

const initialState = {
  logs: [],
  roles: [],
  hrUsers: {},
  allUsers: {},
  departments: [],
  isLoading: true,
  permissions: [],
  selectedUser: {},
  doctorsUsers: {},
  hospitalUsers: {},
  propertyUsers: {},
  admissionUsers: {},
  accountingUsers: {},
  patientCareUsers: {},
  erDepartmentUsers: {},
  opDepartmentUsers: {},
  patientCareNewUsers: {},
  patientCareDeniedUsers: {},
  hrNewUsers: {},
  hrDeniedUsers: {},
  hospitalNewUsers: {},
  propertyNewUsers: {},
  propertyDeniedUsers: {},
  accountingNewUsers: {},
  accountingDeniedUsers: {},
  permissionCategories: [],
  nursingServicesUsers: {},
  roomBedManagementUsers: {},
  hospitalDeniedUsers: {},
  // For Profile
  profle: null,
  profileIncluded: [],
  updateProfileLogs: initialLogs,
};

const slice = createSlice({
  name: 'acl',
  initialState,
  reducers: {
    testAction: () => {
      // console.log('ACL MODULE');
    },
  },
  extraReducers: {
    [showUsers.fulfilled]: (state, actions) => {
      if (actions.payload.request_type === ACLConstants.ALLUSERS) {
        state.allUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.PATIENTCARE) {
        state.patientCareUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.NEWUSERSPATIENTCARE) {
        state.patientCareNewUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.DENIEDUSERSPATIENTCARE) {
        state.patientCareDeniedUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.PROPERTY) {
        state.propertyUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.NEWUSERSPROPERTY) {
        state.propertyNewUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.DENIEDUSERSPROPERTY) {
        state.propertyDeniedUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.ACCOUNTING) {
        state.accountingUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.NEWUSERSACCOUNTING) {
        state.accountingNewUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.DENIEDUSERSACCOUNTING) {
        state.accountingDeniedUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.NEWUSERSHR) {
        state.hrNewUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.DENIEDHR) {
        state.hrDeniedUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.DENIEDHOSPITAL) {
        state.hospitalDeniedUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.NEWUSERSHOSPITAL) {
        state.hospitalNewUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.HR) {
        state.hrUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.HOSPITAL) {
        state.hospitalUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.ADMISSION) {
        state.admissionUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.DOCTORS) {
        state.doctorsUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.NURSINGSERVICES) {
        state.nursingServicesUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.ROOMBEDMANAGEMENT) {
        state.roomBedManagementUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.ERDEPARTMENT) {
        state.erDepartmentUsers = actions.payload.data;
      }

      if (actions.payload.request_type === ACLConstants.OPDEPARTMENT) {
        state.opDepartmentUsers = actions.payload.data;
      }
      state.isLoading = false;
    },
    [showUser.fulfilled]: (state, actions) => {
      state.selectedUser = actions.payload.data;
      state.isLoading = false;
    },
    [getProfile.fulfilled]: (state, actions) => {
      state.profile = actions.payload.data;
      state.profileIncluded = actions.payload.included;
      state.isLoading = false;
    },
    [getPermissionsCategories.fulfilled]: (state, actions) => {
      state.permissionCategories = actions.payload.data;
      state.isLoading = false;
    },
    [getRoles.fulfilled]: (state, actions) => {
      state.roles = actions.payload.data;
      state.isLoading = false;
    },
    [getDepartments.fulfilled]: (state, actions) => {
      state.departments = actions.payload.data;
      state.isLoading = false;
    },
    [getLogs.fulfilled]: (state, actions) => {
      state.logs = actions.payload.data;
      state.isLoading = false;
    },
    [deleteUser.fulfilled]: (state, actions) => {
      state.isLoading = false;
    },
    [updatePermission.fulfilled]: (state, actions) => {
      state.isLoading = false;
    },
    [getPermissions.fulfilled]: (state, actions) => {
      state.permissions = actions.payload;
      state.isLoading = false;
    },
    [approveDenyUser.fulfilled]: (state, actions) => {
      state.isLoading = false;
    },
    [approveDenyUser.fulfilled]: (state, actions) => {
      state.isLoading = false;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.updateProfileLogs.isSuccess = true;
      state.updateProfileLogs.isLoading = false;
    },
    [updateProfile.pending]: (state) => {
      state.updateProfileLogs.isSuccess = false;
      state.updateProfileLogs.isFailed = false;
      state.updateProfileLogs.error = '';
      state.updateProfileLogs.isLoading = true;
    },
    [updateProfile.rejected]: (state, action) => {
      state.updateProfileLogs.isLoading = false;
      state.updateProfileLogs.isFailed = true;
      state.updateProfileLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: aclActions, reducer: aclReducer } = slice;

export const { testAction } = aclActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state?.acl || initialState;

export const getACLState = createSelector([selectDomain], (aclState) => aclState);

export const getProfileState = createSelector([selectDomain], (aclState) => aclState.profile);
/* --------------------------------------------------------------------------------- */

export {
  getLogs,
  getRoles,
  showUser,
  showUsers,
  getProfile,
  deleteUser,
  updateProfile,
  getDepartments,
  getPermissions,
  approveDenyUser,
  updatePermission,
  getPermissionsCategories,
};
