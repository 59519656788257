/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Modal, Row, Button, Col, Accordion, Table, Form } from 'react-bootstrap';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import './index.scss';
import TableHospitalBill from './TableHospitalBill';
import TableAdjustment from './TableAdjustment';
import TableProfessionalAllocation from './TableProfessionalAllocation';
import TableUI from 'components/UI/TableUI';
import ConditionalRender from 'components/ConditionalRender';

function CreateSOAModal({
  showModal,
  setShowModal,
  checkTotal,
  dischargeBtn,
  paymentBtn,
  roomCharges,
  laboratory,
  LaboratoryGross,
  roomAndBoardGross,
  totalHospitalBill,
  adjustment,
  totalAdjustmentBill,
  Doctors,
  professionalsBill,
  diagnosis,
  soaData,
}) {
  const handleShow = () => setShowModal({ deduction: false });
  // deduction
  const filter_Deduction_type = (deduction_type) => {
    if (!soaData?.bill?.bill_deductions?.length) {
      return [];
    }
    return soaData?.bill?.bill_deductions.filter((data) => data.deduction_type === deduction_type);
  };
  const corporateDiscount = React.useMemo(
    () => filter_Deduction_type('corporate_discount'),
    [filter_Deduction_type],
  );
  const specialDiscount = React.useMemo(
    () => filter_Deduction_type('special_discount'),
    [filter_Deduction_type],
  );
  const otherGuarantor = React.useMemo(
    () => filter_Deduction_type('other_guarantor'),
    [filter_Deduction_type],
  );
  const otherDiscount = React.useMemo(
    () => filter_Deduction_type('other_guarantor'),
    [filter_Deduction_type],
  );
  const otherDeduction = React.useMemo(
    () => filter_Deduction_type('other_deduction'),
    [filter_Deduction_type],
  );
  // <- end
  const tableDatas = [
    {
      id: 1,
      tabName: 'Corporate Discount',
      permission: 'Can Add/View Corporate Discount',
      data: corporateDiscount,
    },
    {
      id: 2,
      tabName: 'Special Discount',
      permission: 'Can Add/View Special Discount',
      data: specialDiscount,
    },
    {
      id: 3,
      tabName: 'Other Guarantor',
      permission: 'Can Add/View Other Guarantor',
      data: otherGuarantor,
    },
    {
      id: 4,
      tabName: 'Other Discounts',
      permission: 'Can Add/View Discounts',
      data: otherDiscount,
    },
    {
      id: 5,
      tabName: 'Other Deductions',
      permission: 'Can Add/View Discounts',
      data: otherDeduction,
    },
  ];
  return (
    <Modal show={showModal.createSOA} size='lg' className='partial-modal'>
      <Modal.Body>
        <div className='partial-header'>
          <div className='d-flex header'>
            <p> SOA </p>
            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
          </div>
        </div>
        <div className='information'>
          <div className='paid-container'>THIS PATIENT IS TOTALLY PAID</div>
          <div
            className='justify-content-between'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div>
              <h5 className='fw-bold' style={{ margin: '0' }}>
                Status of Account (SOA)
              </h5>
            </div>
            <div>
              <Button style={{ border: 'none' }} className='bg-danger mx-1'>
                PRINT SOA
              </Button>
              <Button style={{ border: 'none' }} className='bg-success mx-1'>
                DOWNLOAD
              </Button>
            </div>
          </div>
          <Row className='my-3'>
            <Col className='d-flex'>
              <h5 className='fw-bold'>SOA No.:</h5>
              <h5 className='text-danger mx-2 fw-bold'>2022123456789</h5>
            </Col>
            <Col className='d-flex'>
              <h5 className='fw-bold'>Billing No.:</h5>
              <h5 className='text-danger mx-2 fw-bold'>2022123456789</h5>
            </Col>
            <Col className='d-flex'>
              <h5 className='fw-bold'>SOA STATUS:</h5>
              <h5 className='text-danger mx-2 fw-bold'>TO PAY</h5>
            </Col>
          </Row>

          <div className='SOA-table'>
            <h6 className='fw-bold'>HOSPITAL BILL</h6>
            <TableHospitalBill
              roomCharges={roomCharges}
              laboratory={laboratory}
              LaboratoryGross={LaboratoryGross}
              roomAndBoardGross={roomAndBoardGross}
              totalHospitalBill={totalHospitalBill}
            />
            <TableAdjustment adjustment={adjustment} totalAdjustmentBill={totalAdjustmentBill} />
            <TableProfessionalAllocation Doctors={Doctors} professionalsBill={professionalsBill} />
          </div>
          <div>
            <h6 className='fw-bold'>PATIENT'S DIAGNOSIS ( ICD10 / RSV )</h6>
            {diagnosis
              ? diagnosis.map((data) => {
                  return (
                    <p key={data.id}>
                      Diagnosis ( {data?.ICD_10_code} / {data?.diagnosis_type})
                    </p>
                  );
                })
              : null}

            <h6 className='fw-bold'>A00-B99. Certain infectious and parasitic diseases</h6>
            <hr />
          </div>
          <div>
            <h5>DEDUCTIONS</h5>
            <div className='d-flex mb-2'>
              <p style={{ margin: '0' }}>tag patient as:</p>
              <Form.Group className='d-flex'>
                <Form.Check
                  name='tag_patient'
                  className='mx-2'
                  type='radio'
                  label='PWD'
                  value='PWD'
                  disabled
                  checked={soaData?.bill?.patient_tagged_as === 'PWD' ? true : false}
                />
                <Form.Check
                  name='tag_patient'
                  className='mx-2'
                  type='radio'
                  value='Senior Citizen'
                  label='Senior Citizen'
                  disabled
                  checked={soaData?.bill?.patient_tagged_as === 'Senior Citizen' ? true : false}
                />
              </Form.Group>
            </div>
          </div>
          <div className='form-tab-table-billing'>
            <TableUI>
              <Table responsive>
                <thead>
                  <div className='d-flex justify-content-between accordion-header-custom2 p-3'>
                    <div>Deduction From</div>
                    <div>Amount</div>
                   
                  </div>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <Accordion className='form-columns' defaultActiveKey='1'>
                        {tableDatas.map((data) => {
                          return (
                            <Accordion.Item eventKey={data.id} key={data.id}>
                              <Accordion.Header>{data.tabName}</Accordion.Header>
                              <Accordion.Body>
                                <Row>
                                  <Col>
                                    {Array.isArray(data.data)
                                      ? data?.data?.map((listData) => {
                                          return (
                                            <div
                                              key={listData.id}
                                              style={{ padding: '0.5rem 1rem' }}
                                              className='d-flex justify-content-between'
                                            >
                                              <div>{listData.details}</div>
                                              <div className='text-start'>
                                                <p>{listData.amount}</p>
                                              </div>
                                            </div>
                                          );
                                        })
                                      : null}
                                  </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })}
                      </Accordion>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </TableUI>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='total-deduction mt-4'>
              <div className='text-center mx-3'>
                <h5>PHP {soaData?.totalNetBill}</h5>
                <h6>RUNNING BALANCE</h6>
              </div>
            </div>
            <div className='total-deduction mt-4 mx-3'>
              <div className='text-center'>
                <h5>PHP {soaData?.totalNetBill}</h5>
                <h6>AMOUNT DUE</h6>
              </div>
            </div>
          </div>
          <Row className='mt-5'>
            <div className='d-flex justify-content-center'>
              <ConditionalRender
                condition={checkTotal}
                renderIf={
                  <Button onClick={dischargeBtn} className='btn-light-green'>
                    Discharge
                  </Button>
                }
                renderElse={
                  <Button onClick={paymentBtn} className='btn-light-green'>
                    Confirm Payment
                  </Button>
                }
              />
            </div>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CreateSOAModal;
