/* eslint-disable */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import Clearance from './Clearance';
import Procedure from './Procedure';
import TabsUI from 'components/UI/TabsUI';
import SearchPatient from './SearchPatient';
import InventoryPerDepartment from 'components/InventoryPerDepartment';

// Redux & APIs
import { selectNotification } from 'reducers/notificationReducer';

// import assets below
import './Tabs.scss';

// main component
const DoctorTabs = ({ onChange }) => {
  const notificationState = useSelector(selectNotification);

  const tabs = [
    {
      title: 'Search Patient',
      component: SearchPatient,
    },
    {
      title: 'Clearance',
      component: Clearance,
    },
    {
      title: 'Inventory',
      component: InventoryPerDepartment,
    },
    {
      title: 'Procedure',
      component: Procedure,
    },
  ];

  const handleNofitication = () => {
    console.log('notificationState', notificationState);

    return notificationState?.procedures.length === 0 ? (
      ''
    ) : (
      <span className='pop-up'>{notificationState?.procedures.length}</span>
    );
  };

  return (
    <div className='doctorTabs'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title} onSelect={(eventKey) => onChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab
              key={idx}
              eventKey={title}
              className='tab-main'
              title={
                <>
                  {title} {title === 'Procedure' ? handleNofitication() : ''}
                </>
              }
            >
              <Component departmentID={'2'} />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

DoctorTabs.defaultProps = {
  onChange: () => {},
};

DoctorTabs.propTypes = {
  onChange: PropTypes.func,
};

export default DoctorTabs;
