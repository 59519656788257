/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal, Accordion, Table } from 'react-bootstrap';

import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import seturlParams from 'helpers/seturlParams';
import TablePagination from './TablePagination';
import ConditionalRender from 'components/ConditionalRender';
import { selectDepartmentInventory } from 'reducers/departmentInventoryReducer';

import { getEquipments, selectborrowReturn, submitBorrowItem } from 'reducers/borrowReturnReducer';
import { selectInventory } from 'reducers/inventoryReducer';

// Import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as CirlePlusSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CirleMinusSVG } from 'assets/svg/circle-minus.svg';

const BorrowItemModal = ({
  showModal,
  setShowModal,
  getInventoryRequestList,
  departmentID,
  activeTab,
}) => {
  // Redux
  const dispatch = useDispatch();

  const groupState = useSelector(selectInventory);
  const { inventoryGroupData, inventoryGroupItemsLogs } = groupState;

  // New Borrow Return
  const borrowReturnState = useSelector(selectborrowReturn);
  const { dataItems, dataItemsIncluded, dataItemsMeta, submitLogs } = borrowReturnState;

  // States
  const [search, setSearch] = useState({
    code: '',
    itemGroup: '',
  });
  const [formData, setFormData] = useState({
    borrowedBy: '',
  });
  const [finalItem, setFinalItem] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [showResultsNumber, setShowResultsNumber] = useState(5);

  // Api call
  const getInventoryItems = (clear = false) => {
    let params;

    if (clear) {
      setSearch({
        code: '',
        itemGroup: '',
      });
    }

    params = {
      is_new: 0,
      per_page: 5,
      is_medicine: 0,
      q: search?.code,
      include: ['group'],
    };

    if (search?.itemGroup && clear === false) {
      params.group_id = search?.itemGroup;
    }

    if (clear) {
      params.q = '';
    }

    dispatch(getEquipments(params));
  };

  // Change page based on selected arrow
  const changePage = (page, showResult = showResultsNumber) => {
    let params = {};

    params = {
      is_medicine: 0,
      q: search?.code,
      include: ['group'],
      per_page: showResult,
      page: page,
    };

    // const params = `?include[]=group${searchURL}&per_page=${showResult}&page=${page}&is_new=0`;
    if (search?.itemGroup !== '') {
      params.group_id = search?.itemGroup;
    }

    dispatch(getEquipments(params));
  };

  // Get group name from included api
  const getGroupName = (id) => {
    if (!dataItemsIncluded) return;
    const selectedArray = dataItemsIncluded.filter((data) => data.id === id);

    if (selectedArray.length === 0) {
      return 'N/A';
    }

    return selectedArray[0]?.attributes?.name;
  };

  // Change input and checkbox change
  const handleDataChange = (key, type) => (e) => {
    if (type === 'search') {
      setSearch({
        ...search,
        [key]: e.target.value,
      });
    }
  };

  // Add Item to final list
  const addItem = (data) => {
    const toAdd = {
      id: data.id,
      quantity: 0,
      data: data,
    };

    let duplicate = false;
    finalItem.forEach((finalData) => {
      if (finalData?.id === data.id) {
        duplicate = true;
      }
    });

    if (duplicate) {
      toast.warning(`Duplicate Item (${data?.attributes?.name})`);
    } else {
      setFinalItem((prevState) => {
        return [toAdd].concat(prevState);
      });
    }
  };

  // Delete Item from final
  const deleteItem = (data) => {
    const newData = finalItem.filter((mapData) => mapData?.id !== data?.id);

    setFinalItem(newData);
  };

  // Handle POST api
  const handleSubmit = () => {
    const newFormData = new FormData();

    newFormData.append('item_id', finalItem[0]?.id);
    newFormData.append('department_id', departmentID);
    newFormData.append('borrowed_by', formData?.borrowedBy);

    dispatch(submitBorrowItem(newFormData));
  };

  const closeModal = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        borrowItem: false,
      };
    });

    setFinalItem([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (showModal?.borrowItem) {
      console.log('yawaaa');
      getInventoryItems();
    }
  }, [showModal]);

  // Get Submit Data result
  useEffect(() => {
    const { isLoading, isFailed, isSuccess, error } = submitLogs;

    if (isFailed && activeTab === 'All Item') {
      toast.error(`${error[0]?.detail} (${error[0]?.code})`);
      console.log(error);
    }

    if (isSuccess && activeTab === 'All Item') {
      closeModal();
      setFinalItem([]);
      setFormData({
        borrowedBy: '',
      });
      getInventoryRequestList();
      toast.success('Successfully added request to borrow item!');
    }
  }, [submitLogs]);

  // Set Data to group List
  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = inventoryGroupItemsLogs;

    if (isSuccess) {
      setGroupList(inventoryGroupData);
    }
  }, [inventoryGroupItemsLogs]);

  // Conditions
  const haveItems = dataItems && dataItems.length > 0;
  const isLoading = submitLogs.isLoading === true;

  return (
    <Modal
      size='lg'
      show={showModal?.borrowItem}
      onHide={() => {
        closeModal();
      }}
      aria-labelledby='Request Item Modal'
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>BORROW ITEM DETAILS</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={() => closeModal()} />
          </div>
        </div>

        <FormUI>
          <Form className='add-item-form'>
            <Row>
              <Col>
                <div className='search-item'>
                  <Accordion className='advanced-search' defaultActiveKey={'0'}>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Search Item</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col sm={9}>
                            <Row>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Item Code/Name</Form.Label>
                                  <FormControl
                                    type='text'
                                    value={search?.code}
                                    onChange={(e) => {
                                      handleDataChange('code', 'search')(e);

                                      if (e.target.value === '') {
                                        getInventoryItems(true);
                                      }
                                    }}
                                    className='bg-white'
                                    placeholder='Search Here'
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Item Group</Form.Label>
                                  <Form.Select
                                    value={search?.itemGroup}
                                    onChange={handleDataChange('itemGroup', 'search')}
                                  >
                                    {groupList.map((data) => {
                                      return (
                                        <option key={data?.id} value={data?.id}>
                                          {data?.attributes?.name}
                                        </option>
                                      );
                                    })}
                                    <option value='' hidden>
                                      -Select-
                                    </option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col>
                                <div className='buttonWrapper'>
                                  <Button
                                    variant='search'
                                    onClick={() => {
                                      getInventoryItems();
                                    }}
                                  >
                                    GO
                                  </Button>

                                  <Button
                                    variant='secondary'
                                    onClick={() => {
                                      getInventoryItems(true);
                                    }}
                                  >
                                    RESET
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          {/* <Col sm={3}>
                            <div className='buttonWrapper add-item'>
                              <Button
                                onClick={addSelectedItems}
                                disabled={canAdd}
                                className={`add-item-button ${!canAdd}`}
                              >
                                Add Selected Item
                              </Button>
                            </div>
                          </Col> */}
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div className='search-table mb-4'>
                  <TableUI>
                    <Table responsive>
                      <thead>
                        <tr>
                          {/* <th>
                            <Form.Check checked={checkAll} onChange={toggleCheckAll} />
                          </th> */}
                          <th>Item Code</th>
                          <th>Item Name</th>
                          <th>Item Group</th>
                          <th>Generic Name</th>
                          <th>Manufacturer</th>
                          <th>Storage Bin</th>
                          <th>Section</th>
                          <th>UOM</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ConditionalRender
                          condition={haveItems}
                          renderIf={
                            <>
                              {dataItems.map((data, idx) => {
                                const { attributes, relationships } = data;
                                return (
                                  <tr key={idx}>
                                    {/* <td>
                                      <Form.Check
                                        type='checkbox'
                                        checked={selectedCheck[data?.id]}
                                        onChangeCapture={(e) => {
                                          handleDataChange(data, 'check')(e);
                                        }}
                                      />
                                    </td> */}
                                    <td>{attributes?.code}</td>
                                    <td>{attributes?.name}</td>
                                    <td>{getGroupName(relationships?.group?.data[0].id)}</td>
                                    <td>{attributes?.generic_name}</td>
                                    <td>{attributes?.manufacturer}</td>
                                    <td>{attributes?.storage_bin}</td>
                                    <td>{attributes?.section}</td>
                                    <td>{attributes?.unit_of_measure}</td>
                                    <td>
                                      {finalItem.length === 0 && (
                                        <CirlePlusSVG
                                          onClick={() => {
                                            addItem(data);
                                          }}
                                          style={{
                                            fill: '#01B425',
                                            width: '2em',
                                            cursor: 'pointer',
                                          }}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          }
                        />
                      </tbody>
                    </Table>
                  </TableUI>

                  <TablePagination
                    meta={dataItemsMeta}
                    changePage={changePage}
                    showResultsNumber={showResultsNumber}
                    setShowResultsNumber={setShowResultsNumber}
                  />
                </div>

                <Accordion className='advanced-search' defaultActiveKey={'0'}>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Items</Accordion.Header>
                    <Accordion.Body>
                      <div className='search-table mb-4'>
                        <TableUI>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Item Code</th>
                                <th>Item Name</th>
                                <th>Item Group</th>
                                <th>Storage Bin</th>
                                {/* <th>Quantity</th> */}
                                <th>UOM</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              <ConditionalRender
                                condition={finalItem.length > 0}
                                renderIf={
                                  <>
                                    {finalItem.map((data, idx) => {
                                      const { attributes } = data.data;
                                      return (
                                        <tr key={idx}>
                                          <td>{attributes?.code}</td>
                                          <td>{attributes?.name}</td>
                                          <td>{getGroupName(data?.id)}</td>
                                          <td>{attributes?.storage_bin}</td>
                                          {/* <td>
                                            <FormControl
                                              onChange={(e) => {
                                                changeFinalQuantity(e, data, idx);
                                              }}
                                              value={data?.quantity}
                                              type='number'
                                            />
                                          </td> */}
                                          <td>{attributes?.unit_of_measure}</td>
                                          <td>
                                            <CirleMinusSVG
                                              onClick={() => {
                                                deleteItem(data);
                                              }}
                                              style={{
                                                fill: '#01B425',
                                                width: '2em',
                                                cursor: 'pointer',
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                }
                              />
                            </tbody>
                          </Table>
                        </TableUI>

                        <Row className='mt-3'>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Borrowed By:</Form.Label>
                              <FormControl
                                name='borrowedBy'
                                onChange={handleChange}
                                placeholder='Enter here'
                                value={formData?.borrowedBy}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    disabled={isLoading || finalItem.length === 0}
                    onClick={handleSubmit}
                    className='add-button'
                    variant='primary'
                  >
                    Save
                  </Button>

                  <Button
                    onClick={() => {
                      setFinalItem([]);
                    }}
                    className='clear-button'
                    variant='secondary'
                  >
                    Clear
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default BorrowItemModal;
