/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  addOrder,
  deleteOrder,
  getOrderListItems,
  getOrdersList,
  getSuppliesList,
} from './thunks/nurseThunks';

const initialLogs = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  error: null,
};

const initialState = {
  // For get item list
  data: [],
  items: [],
  logs: initialLogs,

  // For submit add order
  addOrderlogs: initialLogs,

  // For Order Lists
  orderData: [],
  orderItems: [],
  orderLogs: initialLogs,

  // For Supplies Lists
  suppliesData: [],
  suppliesItems: [],
  suppliesLogs: initialLogs,

  // For delete order
  deleteLogs: initialLogs,
};

const slice = createSlice({
  name: 'nurse',
  initialState,
  reducers: {
    resetLogState: (state) => {
      state.logs = initialLogs;
      state.submitLogs = initialLogs;
    },
    resetOrderListState: (state) => {
      state.orderLogs = initialLogs;
    },
  },
  extraReducers: {
    // For Get Supplies Lists
    [getSuppliesList.fulfilled]: (state, action) => {
      state.suppliesLogs.isSuccess = true;
      state.suppliesLogs.isLoading = false;
      state.suppliesData = action.payload.data;
      state.suppliesItems = action.payload.included;
    },
    [getSuppliesList.pending]: (state) => {
      state.suppliesLogs.isSuccess = false;
      state.suppliesLogs.isFailed = false;
      state.suppliesLogs.error = '';
      state.suppliesLogs.isLoading = true;
    },
    [getSuppliesList.rejected]: (state, action) => {
      state.suppliesData = [];
      state.suppliesItems = [];
      state.suppliesLogs.isLoading = false;
      state.suppliesLogs.isFailed = true;
      state.suppliesLogs.error = action.payload.errors;
    },
    // For Get Order Lists
    [getOrdersList.fulfilled]: (state, action) => {
      state.orderLogs.isSuccess = true;
      state.orderLogs.isLoading = false;
      state.orderData = action.payload.data;
      state.orderItems = action.payload.included;
    },
    [getOrdersList.pending]: (state) => {
      state.orderLogs.isSuccess = false;
      state.orderLogs.isFailed = false;
      state.orderLogs.error = '';
      state.orderLogs.isLoading = true;
    },
    [getOrdersList.rejected]: (state, action) => {
      state.orderData = [];
      state.orderItems = [];
      state.orderLogs.isLoading = false;
      state.orderLogs.isFailed = true;
      state.orderLogs.error = action.payload.errors;
    },
    // For Get Items
    [getOrderListItems.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
      state.items = action.payload.included;
    },
    [getOrderListItems.pending]: (state) => {
      state.logs.isSuccess = false;
      state.logs.isFailed = false;
      state.logs.error = '';
      state.logs.isLoading = true;
    },
    [getOrderListItems.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
    },

    // For submit add order
    [addOrder.fulfilled]: (state, action) => {
      state.addOrderlogs.isSuccess = true;
      state.addOrderlogs.isLoading = false;
    },
    [addOrder.pending]: (state) => {
      state.addOrderlogs.isSuccess = false;
      state.addOrderlogs.isFailed = false;
      state.addOrderlogs.error = '';
      state.addOrderlogs.isLoading = true;
    },
    [addOrder.rejected]: (state) => {
      state.addOrderlogs.isLoading = false;
      state.addOrderlogs.isFailed = true;
    },

    // For delete order
    [deleteOrder.fulfilled]: (state, action) => {
      state.deleteLogs.isSuccess = true;
      state.deleteLogs.isLoading = false;
    },
    [deleteOrder.pending]: (state) => {
      state.deleteLogs.isSuccess = false;
      state.deleteLogs.isFailed = false;
      state.deleteLogs.error = '';
      state.deleteLogs.isLoading = true;
    },
    [deleteOrder.rejected]: (state) => {
      state.deleteLogs.isLoading = false;
      state.deleteLogs.isFailed = true;
    },
  },
});

export const { actions: nurseActions, reducer: nurseReducer } = slice;

export const { resetLogState, resetOrderListState } = nurseActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.nurse || initialState;

export const selectnurse = createSelector([selectDomain], (inventoryState) => inventoryState);

/* --------------------------------------------------------------------------------- */

export { addOrder, deleteOrder, getOrderListItems, getOrdersList, getSuppliesList };
