/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Button,
    Modal,
    Form,
    FormControl,
    Pagination,
    Table,
    InputGroup,
} from 'react-bootstrap';

// components below
import Details from './ViewDetails';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
import FormUI from 'components/UI/FormUI';
import ClearanceTable from './Table';
import axios from 'axios';

// CSS
import './style.scss';

// main component
const Clearance = () => {
    const [data, setData] = useState([]);
    const [state, setState] = useState([]);
    const [requests, setRequests] = useState([]);
    const [showModal, setShowModal] = useState({
        isStatusShow: false,
        isAddDoctor: false,
        profileData: null,
        profileId: null,
        doctorId: null,
        labRequestId: null,
        isViewResult: false,
        showDetails: false,
    });
    const [userData, setUserData] = useState({
        search_physician: '',
        specialty_subspecialty: '',
    });

    const [doctors, setDoctors] = useState([]);
    const [searchedWord, setSearchedWord] = useState('');
    const [searchedWordPatient, setSearchedWordPatient] = useState('');

    /** filter by patient's name */
    const onChangePatientHandler = (event) => {
        setSearchedWordPatient(event.target.value);
        const searchedWordPatient = event.target.value;
        const newFilter = requests.filter((value) => {
            return value.patient.full_name.toLowerCase().includes(searchedWordPatient.toLowerCase());
        });

        if (searchedWordPatient.length > 0) {
            setState(newFilter);
        } else {
            setState(requests);
        }
    };

    const handleChange = (key) => (e) => {
        setUserData({
            ...userData,
            [key]: e.target.value,
        });
    };

    useEffect(() => {
        axios
            .get('/user')
            .then((response) => {
                setDoctors(response.data.data);
            }, [])
            .catch((err) => {
                console.log('AXIOS ERROR: ', err);
            });

        axios
            .get('/clearance')
            .then((response) => {
                setRequests(response.data.data);
            }, [])
            .catch((err) => {
                console.log('AXIOS ERROR: ', err);
            });
    }, []);

    return (
        <>
            <FormUI>
                <div className='add-room-cont mt-4'>
                    <Form>
                        <h3>Search Patient</h3><br></br>

                        <Row>
                            <Col md={5}>
                                <Form.Group className='basic-search'>
                                    <Form.Label>Patient Name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Search Patient...'
                                        onChange={onChangePatientHandler}
                                        value={searchedWordPatient}
                                    />
                                </Form.Group>
                            </Col>

                            {/* <Col md={2}>
                                <Form.Group className='category-buttons'>
                                    <Button
                                        onClick={() => {
                                            // Update Data on Table?
                                        }}
                                        className='add-button'
                                        variant='primary'
                                    >
                                        Search
                                    </Button>
                                </Form.Group>
                            </Col> */}
                        </Row>
                    </Form><br></br><br></br>

                    <h2 className='mb-3'>Patient List</h2>
                    <ClearanceTable setShowModal={setShowModal} state={state} setState={setState} setData={setData} />
                </div>
            </FormUI>
            <Details showModal={showModal} setShowModal={setShowModal} data={data} />
        </>
    );
};

export default Clearance;
