/* eslint-disable */
/* eslint-disable camelcase */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

// import components below
import Table from 'components/ReactTable';
import { selectInventory } from 'reducers/inventoryReducer';

// import assets below
import './Table.scss';
import { ReactComponent as LockSVG } from 'assets/svg/lock white.svg';
import { ReactComponent as LockRedSVG } from 'assets/svg/lock-red.svg';
import { ReactComponent as LockOpenSVG } from 'assets/svg/lock-open.svg';

// main component
const InventoryTable = ({ state, setState, setShowProfile }) => {
  const storeInventory = useSelector(selectInventory);
  const { storeInventoryItemsLogs } = storeInventory;

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Item Code', accessor: 'code' },
      { Header: 'Item Name', accessor: 'name' },
      { Header: <LockSVG style={{ width: '2em' }} />, accessor: 'padlock' },
      { Header: 'UOM', accessor: 'uom' },
      { Header: 'Storage Bin', accessor: 'storage_bin' },
      { Header: 'Piece Price', accessor: 'piece_price' },
      { Header: 'Box Price', accessor: 'box_price' },
      { Header: 'Piece Quantity', accessor: 'piece_quantity' },
      { Header: 'Box Quantity', accessor: 'box_quantity' },
      { Header: 'Piece Per Box', accessor: 'piece_per_box' },
      { Header: 'Min', accessor: 'min' },
      { Header: 'Max', accessor: 'max' },
      { Header: 'Re-Order Point', accessor: 'reorder_point' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => {
        return {
          id: dd?.id,
          code: dd?.attributes?.code,
          name: dd?.attributes?.name,
          padlock: dd?.attributes?.is_locked ? (
            <LockRedSVG style={{ width: '2em' }} />
          ) : (
            <LockOpenSVG style={{ width: '2em' }} />
          ),
          uom: dd?.attributes?.unit_of_measure,
          storage_bin: dd?.attributes?.storage_bin,
          piece_price: dd?.attributes?.piece_price,
          box_price: dd?.attributes?.box_price,
          piece_quantity: dd?.attributes?.piece_quantity,
          box_quantity: dd?.attributes?.box_quantity,
          piece_per_box: dd?.attributes?.piece_per_box,
          min: dd?.attributes?.min_stock,
          max: dd?.attributes?.max_stock,
          reorder_point: dd?.attributes?.re_order_point,
        };
      }),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      view: () => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showView: true,
            profileId: id,
            rowData: row,
          };
        });
      },
      adjust: () => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showAdjust: true,
            profileId: id,
            rowData: row,
          };
        });
      },
    };

    action[eventKey]();
  };

  return (
    <div className='inventory-table'>
      <Row>
        <Col>
          <div className='table-button'>
            <Button
              onClick={() => {
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    showCreateItem: true,
                  };
                });
              }}
              variant='search'
            >
              Create New Item
            </Button>

            <Button
              variant='primary'
              onClick={() => {
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    showAddItem: true,
                  };
                });
              }}
            >
              Add New Item
            </Button>
          </div>
        </Col>
      </Row>

      <Table
        data={tableData}
        columns={tableColumns}
        logs={storeInventoryItemsLogs}
        customAction={(id) => (
          <div className='custom-actions'>
            <Button onClick={() => handleTableAction('view', id)} className='issue'>
              View
            </Button>
            <Button onClick={() => handleTableAction('adjust', id)} className='adjust'>
              Adjust
            </Button>
          </div>
        )}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

InventoryTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowProfile: () => {},
};

InventoryTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default InventoryTable;
