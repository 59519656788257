/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// import assets below
import './style.scss';
import FormUI from 'components/UI/FormUI';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const ModalAddRecord = ({ showModal, setShowModal }) => {
  const [userData, setUserData] = useState({
    case_id: '',
    physician_name: '',
    discharge_date: '',
    patient_name: '',
    diagnosis: '',
    admin: '',
    prescribe_medication: '',
    further_test: '',
  });

  const handleChange = (key) => (e) => {
    if (e.target.type === 'radio') {
      setUserData({
        ...userData,
        [key]: e.target.value,
      });

      return;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  // Submit data here
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      size='lg'
      show={showModal.addModal}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            addModal: false,
          };
        })
      }
      aria-labelledby='Add Diagnosis'
      className='case-history-modal'
    >
      <Modal.Body>
        <div className='case-history-request'>
          <div className='d-flex header'>
            <p>Case History</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowModal((prevState) => {
                  return { ...prevState, addModal: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col sm={4}>
                <Form.Group className='mb-4'>
                  <Form.Label>Case ID</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.case_id}
                    onChange={handleChange('case_id')}
                  />
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group className='mb-4'>
                  <Form.Label>Physician Name</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.physician_name}
                    onChange={handleChange('physician_name')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <Form.Group className='mb-4'>
                  <Form.Label>Discharge Date</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.discharge_date}
                    onChange={handleChange('discharge_date')}
                  />
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group className='mb-4'>
                  <Form.Label>Patient Name</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.patient_name}
                    onChange={handleChange('patient_name')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Diagnosis</Form.Label>
                  <FormControl
                    as={'textarea'}
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.diagnosis}
                    onChange={handleChange('diagnosis')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group className='mb-4'>
                  <Form.Label>Assessment</Form.Label>
                </Form.Group>

                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Admit</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.admit}
                    onChange={handleChange('admit')}
                  />
                </Form.Group>

                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Prescribe Medication</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.prescribe_medication}
                    onChange={handleChange('prescribe_medication')}
                  />
                </Form.Group>

                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Further Test</Form.Label>
                  <FormControl
                    type='text'
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.further_test}
                    onChange={handleChange('further_test')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    type='submit'
                    onClick={(e) => {
                      handleSubmit(e);
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          addModal: false,
                          showView: true,
                        };
                      });
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() =>
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          addModal: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddRecord;
