/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */

// import assets below
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  setActiveTab,
  setAppointmentAction,
  selectAppointmentItem,
} from 'reducers/appointmentReducer';

import './ViewDetails.scss';

// main component
const ViewDetails = () => {
  const dispatch = useDispatch();

  const appointment = useSelector(selectAppointmentItem);

  const handleClose = () => {
    dispatch(setActiveTab('New Appointment Request'));
    dispatch(setAppointmentAction({ isCreate: true }));
  };

  const handleEdit = () => {
    dispatch(setAppointmentAction({ isEdit: true }));
  };

  return (
    <div className='appointmentViewDetails'>
      <div className='d-flex justify-content-between border-bottom border-2 mb-3'>
        <p className='title fs-5'>Appointment Information</p>

        <div>
          {/* <Button variant='search' className='mb-3 me-3' onClick={() => handleEdit()}>
            Edit Appointment
          </Button> */}

          <Button variant='danger' className='mb-3' onClick={() => handleClose()}>
            Close
          </Button>
        </div>
      </div>
      <Row className='mb-3'>
        <Col md={2} className='d-flex justify-content-between'>
          <p>Patient ID</p>
          <span>:</span>
        </Col>

        <Col md={2}>
          <p className='details'>{appointment?.patientId}</p>
        </Col>

        <Col md={2} className='d-flex justify-content-between'>
          <p>Patient Name</p>
          <span>:</span>
        </Col>

        <Col>
          <p className='details'>{appointment?.patient?.full_name}</p>
        </Col>
      </Row>

      <p className='fw-bold mb-2'>APPOINTMENT INFORMATION</p>

      <Row className='mb-3'>
        {/** start */}

        {/** 1 */}
        <Row>
          <Col md={3} className='d-flex justify-content-between'>
            <p>Date and Time</p>
            <span>:</span>
          </Col>

          <Col md={3}>
            <p className='details'>{appointment?.appointment_date}</p>
          </Col>

          <Col md={2} className='d-flex justify-content-between'>
            <p>Senior Citizen ID No.</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.senior_citizen_id}</p>
          </Col>
        </Row>

        {/** 2 */}
        <Row>
          <Col md={3} className='d-flex justify-content-between'>
            <p>Mobile Number</p>
            <span>:</span>
          </Col>

          <Col md={3}>
            <p className='details'>{appointment?.mobile_number}</p>
          </Col>

          <Col md={2} className='d-flex justify-content-between'>
            <p>PWD/OSCAA ID No.</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.pwd_oscaa_id}</p>
          </Col>
        </Row>

        {/** 3 */}
        <Row>
          <Col md={3} className='d-flex justify-content-between'>
            <p>Email Address</p>
            <span>:</span>
          </Col>

          <Col md={3}>
            <p className='details'>{appointment?.email}</p>
          </Col>

          <Col md={2} className='d-flex justify-content-between'>
            <p>Senior or PWD Patient</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.is_senior_and_or_pwd ? 'yes' : 'no'}</p>
          </Col>
        </Row>

        {/** 4 */}
        <Row>
          <Col md={3} className='d-flex justify-content-between'>
            <p>Doctor</p>
            <span>:</span>
          </Col>
          <Col md={3}>
            <p className='details'>{appointment?.name_of_doctor}</p>
          </Col>

          <Col md={2} className='d-flex justify-content-between'>
            <p>With HMO</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.with_hmo ? 'yes' : 'no'}</p>
          </Col>
        </Row>

        {/** 5 */}
        <Row>
          <Col md={3} className='d-flex justify-content-between'>
            <p>Department</p>
            <span>:</span>
          </Col>
          <Col md={3}>
            <p className='details'>{appointment?.department}</p>
          </Col>

          <Col md={2} className='d-flex justify-content-between'>
            <p>Landmark/s</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.landmarks}</p>
          </Col>
        </Row>

        {/** 6 */}
        <Row>
          <Col md={3} className='d-flex justify-content-between'>
            <p>Reason for Appointment</p>
            <span>:</span>
          </Col>
          <Col md={3}>
            <p className='details'>{appointment?.reason_for_appointmen1}</p>
          </Col>

          <Col md={2} className='d-flex justify-content-between'>
            <p>Reason for Appointment</p>
            <span>:</span>
          </Col>

          <Col>
            <p className='details'>{appointment?.reason_for_appointmen2}</p>
          </Col>
        </Row>
        {/** end */}
      </Row>

      <div className='healthDeclaration'>
        <p className='fw-bold mb-2'>HEALTH DECLARATION FORM</p>
        <p style={{ fontSize: '.7em' }}>
          As required by the Philippine Government that will require you to declare any illness and
          provide information that will aid in contract tracing, should the need arise. Be sure that
          the information you give is accurate and complete. All the information submitted be
          encrypted, and strictly used only in compliance to Philippine law, guidelines, and
          ordinances, in relation to business operation in light of COVID-19 response.
        </p>

        <Row>
          <Col className='d-flex justify-content-between'>
            <p>Nationality</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.nationality}</p>
          </Col>
          <Col className='d-flex justify-content-between'>
            <p>Age</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.age}</p>
          </Col>
        </Row>

        <p className='m-0'>
          Did you travel in a place with COVID-19 local transmission in the past 14 days?
        </p>
        <p className='details mt-1 mb-3'>
          {appointment?.travelled_to_place_with_covid_in_the_past_two_weeks ? 'yes' : 'no'}
        </p>

        <p className='m-0'>
          Did you have a close contact with probable or confirmed COVID-19 case in the past 14 days?
        </p>
        <p className='details mt-1 mb-3'>
          {appointment?.close_contact_in_the_past_two_weeks ? 'yes' : 'no'}
        </p>

        <p className='m-0'>Do you have co-morbidities like Diabetes, CPD, Hypertension, Cancer?</p>
        <p className='details mt-1 mb-3'>{appointment?.have_comorbidity ? 'yes' : 'no'}</p>

        <p className='m-0'>Are you pregnant?</p>
        <p className='details mt-1 mb-3'>{appointment?.is_pregnant ? 'yes' : 'no'}</p>

        <p className='m-0'>Are you a health worker?</p>
        <p className='details mt-1 mb-3'>{appointment?.is_health_worker ? 'yes' : 'no'}</p>

        <p className='m-0'>Did you have a fever {`(>38°C)`} in a past 14 days?</p>
        <p className='details mt-1 mb-3'>
          {appointment?.have_fever_in_the_past_two_weeks ? 'yes' : 'no'}
        </p>

        <p className='m-0'>Did you have the following symptoms within the past 14 days?</p>
        <Row className='mt-1 mb-4'>
          <Col md={5} className='d-flex justify-content-between'>
            <p>Cough (59-82%)</p>
            <span>:</span>
          </Col>
          <Col md={7}>
            <p className='details'>{appointment?.have_cough ? 'yes' : 'no'}</p>
          </Col>
          <Col md={5} className='d-flex justify-content-between'>
            <p>Fatigue/Myalgia</p>
            <span>:</span>
          </Col>
          <Col md={7}>
            <p className='details'>{appointment?.have_fatigue_myalgia ? 'yes' : 'no'}</p>
          </Col>
          <Col md={5} className='d-flex justify-content-between'>
            <p>Anorexia</p>
            <span>:</span>
          </Col>
          <Col md={7}>
            <p className='details'>{appointment?.have_anorexia ? 'yes' : 'no'}</p>
          </Col>
          <Col md={5} className='d-flex justify-content-between'>
            <p>Difficulty of Breathing/Shortness of breath</p>
            <span>:</span>
          </Col>
          <Col md={7}>
            <p className='details'>{appointment?.have_difficulty_breathing ? 'yes' : 'no'}</p>
          </Col>
          <Col md={5} className='d-flex justify-content-between'>
            <p>Body Weakness</p>
            <span>:</span>
          </Col>
          <Col md={7}>
            <p className='details'>{appointment?.have_body_weakness ? 'yes' : 'no'}</p>
          </Col>
          <Col md={5} className='d-flex justify-content-between'>
            <p>Sore Throat</p>
            <span>:</span>
          </Col>
          <Col md={7}>
            <p className='details'>{appointment?.have_sore_throat ? 'yes' : 'no'}</p>
          </Col>
          <Col md={5} className='d-flex justify-content-between'>
            <p>Other Symptoms</p>
            <span>:</span>
          </Col>
          <Col md={7}>
            <p className='details'>{appointment?.other_symptoms}</p>
          </Col>
        </Row>

        <Row>
          <Col className='d-flex justify-content-between'>
            <p>Name of Dortor</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.name_of_doctor}</p>
          </Col>
          <Col className='d-flex justify-content-between'>
            <p>Specialization</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.specialization}</p>
          </Col>
        </Row>

        <Row>
          <Col className='d-flex justify-content-between'>
            <p>HMO Files</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>DETAILS HERE</p>
          </Col>
          <Col className='d-flex justify-content-between'></Col>
          <Col></Col>
        </Row>

        <Row>
          <Col className='d-flex justify-content-between'>
            <p>Blood Pressure</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.blood_pressure}</p>
          </Col>
          <Col className='d-flex justify-content-between'></Col>
          <Col></Col>
        </Row>

        <Row>
          <Col className='d-flex justify-content-between'>
            <p>Temperature</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.temperature}</p>
          </Col>
          <Col className='d-flex justify-content-between'></Col>
          <Col></Col>
        </Row>

        <Row>
          <Col className='d-flex justify-content-between'>
            <p>Pulse Rate</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.pulse_rate}</p>
          </Col>
          <Col className='d-flex justify-content-between'></Col>
          <Col></Col>
        </Row>

        <Row>
          <Col className='d-flex justify-content-between'>
            <p>Respiratory Rate</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.respiratory_rate}</p>
          </Col>
          <Col className='d-flex justify-content-between'></Col>
          <Col></Col>
        </Row>

        <Row className='mb-3'>
          <Col className='d-flex justify-content-between'>
            <p>SPO2</p>
            <span>:</span>
          </Col>
          <Col>
            <p className='details'>{appointment?.SPO2}</p>
          </Col>
          <Col className='d-flex justify-content-between'></Col>
          <Col></Col>
        </Row>
      </div>

      <p className='fw-bold mb-2'>APPOINTMENT INFORMATION</p>

      <Row>
        <Col className='d-flex justify-content-between'>
          <p>Services Type</p>
          <span>:</span>
        </Col>
        <Col>
          <p className='details'>{appointment?.services_type}</p>
        </Col>
        <Col className='d-flex justify-content-between'></Col>
        <Col></Col>
      </Row>

      <Row>
        <Col className='d-flex justify-content-between'>
          <p>Appointment Date</p>
          <span>:</span>
        </Col>
        <Col>
          <p className='details'>{appointment?.appointment_date}</p>
        </Col>
        <Col className='d-flex justify-content-between'></Col>
        <Col></Col>
      </Row>

      <Row className='mb-3'>
        <Col className='d-flex justify-content-between'>
          <p>Department</p>
          <span>:</span>
        </Col>
        <Col>
          <p className='details'>{appointment?.department}</p>
        </Col>
        <Col className='d-flex justify-content-between'></Col>
        <Col></Col>
      </Row>

      <p className='fw-bold mb-2'>SERVICES TYPE</p>

      <Row>
        <Col md={6} className='d-flex justify-content-between'>
          <p>If Service Type is Clinic Consultation</p>
          <span>:</span>
        </Col>
        <Col>
          <p className='details'>{appointment?.clinic_consultation_service}</p>
        </Col>
        <Col></Col>
      </Row>

      <Row>
        <Col md={6} className='d-flex justify-content-between'>
          <p>If Service Type is Clinic Package</p>
          <span>:</span>
        </Col>
        <Col>
          <p className='details'>{appointment?.clinic_packages}</p>
        </Col>
        <Col></Col>
      </Row>

      <Row className='mb-3'>
        <Col md={6} className='d-flex justify-content-between'>
          <p>If Service Type is Clinic Teleconsultation</p>
          <span>:</span>
        </Col>
        <Col>
          <p className='details'>{appointment?.clinic_teleconsultation}</p>
        </Col>
        <Col></Col>
      </Row>

      <Row className='mb-3'>
        <Col className='d-flex flex-column'>
          <p>RESOURCE</p>
          <div className='w-100 d-flex justify-content-between'>
            <p>Time Slot</p>
            <span>:</span>
          </div>
        </Col>
        <Col className='d-flex align-items-end'>
          <p className='details'>{appointment?.resource}</p>
        </Col>
        <Col className='d-flex flex-column'>
          <p>STATUS</p>
          <div className='w-100 d-flex justify-content-between'>
            <p>New Request</p>
            <span>:</span>
          </div>
        </Col>
        <Col className='d-flex align-items-end'>
          <p className='details'>{appointment?.appointment_status}</p>
        </Col>
        <Col className='d-flex flex-column'>
          <p>REMARKS</p>
          <div className='w-100 d-flex justify-content-between'>
            <p>Remarks</p>
            <span>:</span>
          </div>
        </Col>
        <Col className='d-flex align-items-end'>
          <p className='details'>{appointment?.remarks}</p>
        </Col>
      </Row>

      <p className='fw-bold mb-2'>MEDICAL HISTORY</p>

      <p className='m-0'>Please describe your present illness</p>
      <p className='details mt-1 mb-3'>{appointment?.other_illness_and_condition}</p>

      <p className='m-0'>Other illness and Conditions</p>
      <p className='details mt-1 mb-3'>{appointment?.other_illness_and_condition}</p>

      <p className='m-0'>Diagnosis Results and Other Clinical Documents</p>
      <p className='details mt-1 text-danger'>DETAILS HERE</p>
    </div>
  );
};

export default ViewDetails;
