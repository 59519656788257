// import components below
import PropTypes from 'prop-types';
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const PhilhealthHeader = ({ active }) => {
    return (
        <div className='PhilhealthHeader'>
            <PageHeaderUI>
                <h4>Philhealth Module</h4>
                <p>Dashboard / Philhealth / {active}</p>
            </PageHeaderUI>
        </div>
    );
};

PhilhealthHeader.defaultProps = {
    active: '',
};

PhilhealthHeader.propTypes = {
    active: PropTypes.string,
};

export default PhilhealthHeader;
