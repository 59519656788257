/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormControl, Button } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import ConfirmModal from './components/ConfirmModal';

// main components
const index = ({ requestData, setShowScreen }) => {
  const [data, setData] = useState([]);
  const [modalType, setModalType] = useState();
  const [openModal, setOpenModal] = useState(false);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Advance Amount', accessor: 'advance_amount' },
      { Header: 'Salary Advance Create Date', accessor: 'salary_advance_create_date' },
      { Header: 'Reason / Description', accessor: 'reason_description' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      data?.map((dd) => ({
        advance_amount: dd?.advance_amount,
        salary_advance_create_date: dd?.salary_advance_create_date,
        reason_description: dd?.reason_description,
        status: dd?.status,
      })),
    [data],
  );

  const handleAccept = () => {
    setModalType('accept');
    setOpenModal(true);
  };

  const handleDecline = () => {
    setModalType('decline');
    setOpenModal(true);
  };

  useEffect(() => {
    if (requestData) {
      //   console.log('yeata', requestData?.advances_data);
      setData(requestData?.data?.advances_data);
    }
  }, [requestData]);

  return (
    <div className='advances-container shadow-sm'>
      <div className='box-container blue'>
        <Form.Group className='inline-group'>
          <Form.Label>VIEW RECORD</Form.Label>
        </Form.Group>
      </div>

      <div className='box-container'>
        <div className='view-record'>
          <h4 className='mb-3'>Employee Details</h4>

          <Row className='mb-3'>
            <Col>
              <Form.Group className='inline-group'>
                <Form.Label>Employee Name</Form.Label>

                <FormControl placeholder='DETAILS HERE' value={requestData?.employee_name} />
              </Form.Group>

              <Form.Group className='inline-group'>
                <Form.Label>Employee Position</Form.Label>

                <FormControl placeholder='DETAILS HERE' value={requestData?.employee_position} />
              </Form.Group>

              <Form.Group className='inline-group'>
                <Form.Label>Department</Form.Label>

                <FormControl placeholder='DETAILS HERE' value={requestData?.department} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className='inline-group'>
                <Form.Label>Company Email Address</Form.Label>

                <FormControl
                  placeholder='DETAILS HERE'
                  value={requestData?.company_email_address}
                />
              </Form.Group>

              <Form.Group className='inline-group'>
                <Form.Label>Contact Number</Form.Label>

                <FormControl placeholder='DETAILS HERE' value={requestData?.contact_number} />
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col className='table-data'>
              <p className='title'>Salary Advance List</p>

              <Table
                data={tableData}
                columns={tableColumns}
                pagination={['BOTTOM']}
                customAction={(id, data) => {
                  return (
                    <div className='advance-actions'>
                      <Button onClick={handleAccept} className='accept'>
                        Accept
                      </Button>
                      <Button onClick={handleDecline} className='decline'>
                        Decline
                      </Button>
                    </div>
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col className='content-footer'>
              <Button
                className='back-button'
                onClick={() => {
                  setShowScreen(1);
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <ConfirmModal modalType={modalType} openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default index;
