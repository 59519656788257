import React, { useState, useEffect } from 'react';
/* eslint-disable camelcase */
// import core & vendor packages below
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';
import FormUI from 'components/UI/FormUI';

// import assets below
import './EditDietModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updatePatientDiet, selectPatient } from 'reducers/patientReducer';
import { toast } from 'react-toastify';
import moment from 'moment';

// main componet
const EditDietModal = (props) => {
  const dispatch = useDispatch();

  const { showModal, setShowModal } = props;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const groupState = useSelector(selectPatient);
  const { updatedDiet, item } = groupState;

  const [formData, setFormData] = useState({
    id: showModal.data?.id,
    user_id: item?.id + '',
    start_date: moment(showModal.data?.attributes.start_date).format('YYYY-MM-DD'),
    end_date: moment(showModal.data?.attributes.end_date).format('YYYY-MM-DD'),
    diet_type: showModal.data?.attributes.diet_type,
  });

  const handleSubmit = async () => {
    toast.info(`Updating diet`);
    dispatch(updatePatientDiet({ ...formData }));
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (isSubmitted) {
      const { isFailed, isSuccess, error } = updatedDiet;

      if (isFailed) {
        error?.map((error) => {
          toast.error(`${error.detail} (${error.code})`);
        });
      }

      if (isSuccess) {
        toast.info(`Diet updated successfully`);
        setShowModal(false);
        setIsSubmitted(false);
      }
    }
  }, [updatedDiet]);

  const handleChange = (key) => (e) => {
    let value = e.target.value;
    switch (key) {
      case 'start_date':
        value = value + ' 00:00';
        break;
      case 'end_date':
        value = value + ' 23:59';
        break;
    }

    setFormData({
      ...formData,
      [key]: value,
    });

    return;
  };

  return (
    <div className='createDietaryModal'>
      <ModalUI>
        <Modal.Dialog>
          <Modal.Header>
            <h4>CREATE DIET</h4>
            <Button onClick={() => setShowModal(!showModal)} variant='transparent'>
              <CloseSVG />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className='createDietaryModal__content'>
              <FormUI>
                <Row>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Diet Type</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter here'
                        onChange={handleChange('diet_type')}
                        defaultValue={showModal.data?.attributes.diet_type}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Start Date/Time</Form.Label>
                      <Form.Control
                        type='date'
                        onChange={handleChange('start_date')}
                        defaultValue={moment(showModal.data?.attributes.start_date).format(
                          'YYYY-MM-DD',
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>End Date/Time</Form.Label>
                      <Form.Control
                        type='date'
                        onChange={handleChange('end_date')}
                        defaultValue={moment(showModal.data?.attributes.end_date).format(
                          'YYYY-MM-DD',
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </FormUI>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSubmit} variant='addField' className='m-2'>
              Update
            </Button>
            <Button onClick={() => setShowModal(!showModal)} variant='cancel'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </ModalUI>
    </div>
  );
};

export default EditDietModal;
