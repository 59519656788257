/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

import Table from 'components/ReactTable';
import LeaveCreditsModal from './components/Modal';

import './index.scss';
import { ReactComponent as EyeSVG } from 'assets/svg/eye.svg';
import { ReactComponent as EditSVG } from 'assets/svg/pencil green.svg';

const state = [
  {
    employee_number: '001',
    employee_name: 'Juan Dela Cruz',
    positions: 'Nurse',
    department: 'Nursing Dept.',
    company_email_address: 'jdelacruz@mcmc.com',
    contact_number: '0913268578',
    advances_data: [
      {
        advance_amount: '2000',
        salary_advance_create_date: 'July-5-2022',
        reason_description: 'For Emergency',
        status: 'Pending',
      },
      {
        advance_amount: '2000',
        salary_advance_create_date: 'July-5-2022',
        reason_description: 'For Emergency',
        status: 'Accepted',
      },
    ],
  },
];

const index = ({ setShowScreen, setRequestData }) => {
  const [openModal, setOpenModal] = useState();

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Employee Number', accessor: 'employee_number' },
      { Header: 'Employee Name', accessor: 'employee_name' },
      { Header: 'Positions', accessor: 'positions' },
      { Header: 'Department', accessor: 'department' },
      { Header: 'Company Email Address', accessor: 'company_email_address' },
      { Header: 'Contact Number', accessor: 'contact_number' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        employee_number: dd?.employee_number,
        employee_name: dd?.employee_name,
        positions: dd?.positions,
        department: dd?.department,
        company_email_address: dd?.company_email_address,
        contact_number: dd?.contact_number,
        data: dd,
      })),
    [state],
  );

  return (
    <div className='advances-table'>
      <div className='main-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <div className='advance-actions'>
                <EyeSVG
                  onClick={() => {
                    setShowScreen(2);
                    setRequestData(data);
                  }}
                />
                <EditSVG />
              </div>
            );
          }}
        />
      </div>

      <LeaveCreditsModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default index;
