import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { getACLState } from 'reducers/aclReducer';
import { useSelector } from 'react-redux';

import UserLogo from 'assets/images/user.png';

import './index.scss';

const Cards = () => {
  const storeData = useSelector(getACLState);

  const patientCareUsers = storeData?.patientCareUsers;
  const propertyUsers = storeData?.propertyUsers;
  const accountingUsers = storeData?.accountingUsers;

  const getCurrentMonth = () => {
    const newDate = new Date();
    const month = newDate.toLocaleString('default', { month: 'short' });
    const year = newDate.getFullYear();

    return `${month} ${year}`;
  };

  return (
    <div className='cards'>
      <Row>
        <Col className='cards-cont'>
          <div className='top-part'>
            <img src={UserLogo} alt='user' />

            <p>
              Patient Care Management <span>as of {getCurrentMonth()}</span>
            </p>
          </div>

          <div className='bottom-part'>
            <p>Accounts</p>

            <p className='big'>{patientCareUsers?.meta?.pagination?.total}</p>
          </div>
        </Col>

        <Col className='cards-cont'>
          <div className='top-part'>
            <img src={UserLogo} alt='user' />

            <p>
              Property Management <span>as of {getCurrentMonth()}</span>
            </p>
          </div>

          <div className='bottom-part'>
            <p>Accounts</p>

            <p className='big'>{propertyUsers?.meta?.pagination?.total}</p>
          </div>
        </Col>

        <Col className='cards-cont'>
          <div className='top-part'>
            <img src={UserLogo} alt='user' />

            <p>
              Accounting Management <span>as of {getCurrentMonth()}</span>
            </p>
          </div>

          <div className='bottom-part'>
            <p>Accounts</p>

            <p className='big'>{accountingUsers?.meta?.pagination?.total}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Cards;
