/* eslint-disable no-unused-vars */
/* eslint-disable */
/* eslint-disable multiline-comment-style */
import ConditionalRender from 'components/ConditionalRender';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';
import axios from 'axios';
import AsyncSelect from 'react-select/async';

const DischargeNotes = ({ handleChange, stateData, setStateData }) => {
  const [settings, setSettings] = useState({
    followUpSchedule: false,
  });


  const handleSetttingsChange = (key) => (e) => {
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        setSettings({
          ...settings,
          [key]: true,
        });
      } else {
        setSettings({
          ...settings,
          [key]: false,
        });
      }

      return;
    }

    setSettings({
      ...settings,
      [key]: e.target.value,
    });
  };

  const [causeOfDeath, setCauseOfDeath] = useState('');
  const [causeOfDeathOptions, setCauseOfDeathOptions] = useState([]);

  const filterOptions = (input) => {
    return causeOfDeathOptions.filter((i) => {
      return i?.full_name;
    });
    // return causeOfDeathOptions;
  };

  const handleCauseOfDeath = (id, full_name) => {
    setStateData({ ...stateData, userId: id });
  };

  const loadOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(input));
      }, 1000);
    });
  }

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      padding: '0.45em',
      color: '#404040',
      borderColor: '#c8c8c8',
      borderRadius: '10px'
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: '100'
    })
  };

  useEffect(() => {
  }, [settings]);

  useEffect(() => {
    axios.get('/user')
      .then((response) => {
        const options = response?.data?.data?.map(data => ({
          id: data.id,
          value: data.full_name,
          label: data.full_name,
          category: data.full_name,
          full_name: data.full_name,
        }));
        setCauseOfDeathOptions(options);
      });
  }, []);


  return (
    <>
      <Row className='mb-5'>
        <Col>
          <Form.Group className='mb-4'>
            <Form.Check
              onChange={handleSetttingsChange('followUpSchedule')}
              value={settings?.followUpSchedule}
              type='checkbox'
              label='Followup Schedule'
            />
          </Form.Group>

          <ConditionalRender
            condition={settings.followUpSchedule === true}
            renderIf={
              <>
                <Row>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Doctor Name</Form.Label>
                      <div style={{ width: '70%', margin: '0 auto' }}>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          loadOptions={loadOptions}
                          onChange={e => (handleCauseOfDeath(e.id, e.full_name))}
                          placeholder="Search"
                          styles={selectStyles}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className='mb-4'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        className='bg-white'
                        placeholder='Enter Here'
                        value={stateData?.follow_up_date}
                        onChange={handleChange('follow_up_date')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-4'>
                      <Form.Label>Time</Form.Label>
                      <FormControl
                        className='bg-white'
                        placeholder='Enter Here'
                        value={stateData?.follow_up_time}
                        onChange={handleChange('follow_up_time')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            }
          />
        </Col>
      </Row>

      <Row className='mb-5'>
        <Col>
          <Form.Group className='mb-2'>
            <Form.Label>Home Medication</Form.Label>
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Name of medicine</Form.Label>
                <FormControl
                  className='bg-white'
                  placeholder='Enter Here'
                  value={stateData?.name_of_medicine}
                  onChange={handleChange('name_of_medicine')}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Purpose</Form.Label>
                <FormControl
                  className='bg-white'
                  placeholder='Enter Here'
                  value={stateData?.purpose}
                  onChange={handleChange('purpose')}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Amour / Dose</Form.Label>
                <FormControl
                  className='bg-white'
                  placeholder='Enter Here'
                  value={stateData?.amour_doze}
                  onChange={handleChange('amour_doze')}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Frequency / Route / Duration</Form.Label>
                <FormControl
                  className='bg-white'
                  placeholder='Enter Here'
                  value={stateData?.frequency_route_duration}
                  onChange={handleChange('frequency_route_duration')}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Precautions</Form.Label>
                <FormControl
                  className='bg-white'
                  placeholder='Enter Here'
                  value={stateData?.precautions}
                  onChange={handleChange('precautions')}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className='mb-5'>
        <Col>
          <Form.Group className='mb-2'>
            <Form.Label>Other Instructions</Form.Label>
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Diet / Feeding</Form.Label>

                <Row>
                  <Col className='checkboxes'>
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='Diabetic Diet'
                      label='Diabetic Diet'
                    />
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='Low Salt Diet'
                      label='Low Salt Diet'
                    />
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='PEG'
                      label='PEG'
                    />
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='NGT'
                      label='NGT'
                    />
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='Others'
                      label='Others'
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Home Care</Form.Label>

                <Row>
                  <Col className='checkboxes'>
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='Monitoring'
                      label='Monitoring'
                    />
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='Wound Care/Dressing'
                      label='Wound Care/Dressing'
                    />
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='ADL/IADL'
                      label='ADL/IADL'
                    />
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='Others'
                      label='Others'
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Details Here</Form.Label>

                <FormControl
                  as='textarea'
                  rows={3}
                  className='bg-white'
                  placeholder='Enter Here'
                  value={stateData?.other_details}
                  onChange={handleChange('other_details')}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Others</Form.Label>

                <Row>
                  <Col className='checkboxes'>
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='PT /OT'
                      label='PT /OT'
                    />
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='Home Health Care'
                      label='Home Health Care'
                    />
                    <Form.Check
                      onChange={handleChange('other_instructions')}
                      type='radio'
                      name='diet'
                      value='Community Resources'
                      label='Community Resources'
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DischargeNotes;
