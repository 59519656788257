// import components below
import PageHeaderUI from 'components/UI/PageHeaderUI';

// import assets below
import './PageHeader.scss';

// main component
const ReportsHeader = (props) => {
    const { active } = props;

    return (
        <div className='reportsHeader'>
            <PageHeaderUI>
                <h4>Reports</h4>
                <p>Dashboard / {active}</p>
            </PageHeaderUI>
        </div>
    );
};

export default ReportsHeader;
