/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// Main components below
import TabsUI from 'components/UI/TabsUI';

// Tab components
import InventoryItems from './index';
import ReturnedItemToInventory from './tabs/ReturnedItemToInventory';

// Assets
import './style.scss';

// Main component
const InventoryItemsTabs = ({ activeKey, refreshData, departmentID, setRefreshData }) => {
  const [subTabActiveKey, setSubTabActiveKey] = useState('');

  const tabs = [
    {
      title: 'Department Inventory',
      component: InventoryItems,
    },
    {
      title: 'Returned Item to inventory',
      component: ReturnedItemToInventory,
    },
    // {
    //   title: 'Returned Item from patient',
    //   component: InventoryItems,
    // },
  ];

  const onChange = (key) => {
    setSubTabActiveKey(key);
  };

  return (
    <div className='doctorTabs'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title} onSelect={(eventKey) => onChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component
                activeKey={activeKey}
                refreshData={refreshData}
                departmentID={departmentID}
                setRefreshData={setRefreshData}
                subTabActiveKey={subTabActiveKey}
              />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

InventoryItemsTabs.defaultProps = {
  departmentID: '',
};

InventoryItemsTabs.propTypes = {
  departmentID: PropTypes.string,
};

export default InventoryItemsTabs;
