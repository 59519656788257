/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Packages
import Proptypes from 'prop-types';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';

// Assets
import './style.scss';
import { ReactComponent as LockSVG } from 'assets/svg/lock black.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// Main component
const ItemConfiguration = ({ showProfile, setShowProfile }) => {
  return (
    <Modal
      size='lg'
      show={showProfile.itemConfiguration}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            itemConfiguration: false,
          };
        })
      }
      aria-labelledby='ITEM CONFIGURATION'
      className='create-adjustment-modal'
    >
      <Modal.Body>
        <div className='create-adjustment-request'>
          <div className='d-flex header'>
            <p>ITEM CONFIGURATION</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, itemConfiguration: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='adjustment-form'>
            <Row className='mb-4'>
              <Col sm={2}>
                <Form.Group className='mb-3'>
                  <Form.Label>On Hand</Form.Label>
                  <FormControl type='text' placeholder='Piece' />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Unit of Measurement</Form.Label>
                  <FormControl type='text' placeholder='Piece' />
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group className='mb-3'>
                  <Form.Label>Storage Bin</Form.Label>
                  <FormControl type='text' placeholder='Piece' />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Locking</Form.Label>
                  <div className='lock-cont'>
                    <div className='lock-svg'>
                      <LockSVG style={{ width: '1rem' }} />
                    </div>
                    <Form.Check type='switch' id='switch' label='Lock Automatically' />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col sm={4}>
                <Form.Group className='mb-4 bg-grey'>
                  <Form.Label>Replenishment type</Form.Label>
                  <Form.Select>
                    <option>-Select-</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group className='mb-4 bg-grey'>
                  <Form.Label>Supplying Store</Form.Label>
                  <Form.Select>
                    <option>-Select-</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Safety Level</Form.Label>
                  <FormControl type='text' placeholder='Piece' />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Target Level</Form.Label>
                  <FormControl type='text' placeholder='Piece' />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Stock Quantities</Form.Label>
                  <div className='stock-quantities'>
                    <Form.Group className='inline-group'>
                      <Form.Label>Minimum</Form.Label>
                      <FormControl type='number' placeholder='Piece' />
                    </Form.Group>

                    <Form.Group className='inline-group'>
                      <Form.Label>Maximum</Form.Label>
                      <FormControl type='number' placeholder='Piece' />
                    </Form.Group>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col sm={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Average Consumption</Form.Label>
                  <FormControl type='text' placeholder='Piece' />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Lead Time (Days)</Form.Label>
                  <FormControl type='text' placeholder='Piece' />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Re Order</Form.Label>
                  <FormControl type='text' placeholder='Piece' />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-2 buttons-cont'>
                  <Button className='create-adjustment' variant='primary'>
                    Save
                  </Button>
                  <Button
                    className='cancel-button'
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return { ...prevState, itemConfiguration: false };
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ItemConfiguration.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

ItemConfiguration.propTypes = {
  showProfile: Proptypes.object,
  setShowProfile: Proptypes.func,
};

export default ItemConfiguration;
