/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

export const getrefillGroups = createAsyncThunk(
  'inventory/refill/groups',
  async (filter = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/groups?q=&per_page=1000`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getRefillRequest = createAsyncThunk(
  'inventory/refillRequest',
  async (filter = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/restock?per_page=100000`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      params: {
        include: ['restock_items'],
        restock_type: filter.restock_type,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const denyRequest = createAsyncThunk(
  'inventory/denyrequest',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/restock/${payload?.id}/deny`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: payload,
    })
      .then((response) => {
        toast.success('Successfully Denied!');

        return response.data;
      })
      .catch((error) => {
        toast.warning(error.response.data);
        return rejectWithValue(error.response.data);
      });
  },
);

export const approveRequest = createAsyncThunk(
  'inventory/approveRequest',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/restock/${payload?.id}/approved`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        toast.success('Successfully Approved!');
        return response.data;
      })
      .catch((error) => {
        toast.warning(error.response.data);
        return rejectWithValue(error.response.data);
      });
  },
);

export const approverequesteditem = createAsyncThunk(
  'inventory/viewrequest/approve_requested_item',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/restock_items/${payload?.restock_id}/${payload.url}`,
      data: {
        requested_item: payload?.requested_item,
      },
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        toast.success('Successfully Approved!');
        return response.data;
      })
      .catch((error) => {
        console.log(error.response.data.error[0].detail);
        toast.warning(error.response.data.error[0].detail);
        return rejectWithValue(error.response.data);
      });
  },
);

export const denyrequesteditem = createAsyncThunk(
  'inventory/viewrequest/deny_requested_item',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/restock_items/${payload?.restock_id}/reject`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: {
        requested_item: payload?.requested_item,
        remarks: payload?.remarks,
      },
    })
      .then((response) => {
        toast.success('Successfully rejected!');
        return response.data;
      })
      .catch((error) => {
        toast.warning(error.response.data);
        return rejectWithValue(error.response.data);
      });
  },
);

export const forIssuanceApprove = createAsyncThunk(
  'inventory/forIssuanceApprove',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/restock/${payload?.restock_id}/finalize_issuance`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response?.data);
      });
  },
);
//reorder request
export const getReorderRequest = createAsyncThunk(
  'inventory/reorderRequest',
  async (filter = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/restock?q=${filter.search ? filter.search : ''}&requested_at=${
        filter.date ? filter.date : ''
      }&per_page=100000`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      params: {
        include: ['restock_items'],
        restock_type: filter.restock_type,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
//maintenance module
export const getMaintenance = createAsyncThunk(
  'inventory/maintenance',
  async (filter = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/maintenance?q=${filter.search ? filter.search : ''}&include[]=item`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showMaintenanceItem = createAsyncThunk(
  'inventory/showMaintenanceItem',
  async (filter = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/maintenance/${filter.id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
//maintenance module
export const getGoodreceipt = createAsyncThunk(
  'inventory/goodreceipt',
  async (filter = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/restock/goods-reciept?q=${filter.search ? filter.search : ''}${
        filter.date ? `&purchase_order_date=${filter.date}` : ''
      }&per_page=100000`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      params: {
        include: ['restock_items'],
        restock_type: 'reorder',
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// Purchase Request Module
export const forSubmitRequestReorder = createAsyncThunk(
  'inventory/forSubmitRequestReorder',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/restock/${payload?.restock_id}/${payload.url}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: {
        username: payload?.username,
        password: payload?.password,
        remarks: payload?.remark ? payload?.remark : '',
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response?.data);
      });
  },
);
// Purchase Request Module
export const forcompleteMaintenance = createAsyncThunk(
  'inventory/forcompleteMaintenance',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `/api/maintenance/${payload.id}/complete`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: payload.params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return rejectWithValue(error.response?.data);
      });
  },
);
