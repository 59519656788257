import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import PageTabs from './components/Tabs';
import PageHeader from './components/PageHeader';

// main component
const Accounting = () => {
  const [activeTab, setActiveTab] = useState('Financial Statement');

  return (
    <Layout pageTitle='Accounting'>
      <PageHeader active={activeTab} />
      <PageTabs onChange={setActiveTab} />
    </Layout>
  );
};

export default Accounting;
