/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// import components below
import Table from 'components/ReactTable';
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './ViewTable.scss';

// main component
const StatusClearanceListTable = ({ setShowModal }) => {
  const data = useSelector(selectPatientData);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Location', accessor: 'location' }, // accessor is the "key" in the data
      { Header: 'Patient ID', accessor: 'id' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      { Header: 'Age / Sex', accessor: 'age_sex' },
    ],
    []
  )

  const tableData = React.useMemo(
    () => data?.map(dd => ({
      location: dd?.patient_present_address?.present_address1,
      id: dd?.id,
      name: dd?.full_name,
      date_of_birth: dd?.date_of_birth,
      age_sex: `${dd?.age}/${dd?.sex}`,
    })),
    [data]
  )

  // const handleTableAction = (eventKey, id) => {
  //   const row = data.find(dd => dd.id === id);
  //   const action = {
  //     profile: () => { },
  //     view: () => { },
  //     edit: () => { },
  //     delete: () => { }
  //   }

  //   action[eventKey]()
  // }

  return (
    <div className='nursing-medical-records-list-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        // actions={{ hasView: true }}
        // onAction={handleTableAction}
        customAction={(id) => (
          <span className='clearance-status'>Cleared</span>
        )}
        filter={{
          top: (
            <div className='buttons'>
              <Button className='active'>For Discharge Clearance</Button>
              <Button className='active'>Cleared</Button>
            </div>
          )
        }}
        pagination={['TOP', 'BOTTOM']}
      />
    </div>
  );
};

StatusClearanceListTable.defaultProps = {
  setShowModal: () => {},
};

StatusClearanceListTable.propTypes = {
  setShowModal: PropTypes.func,
};

export default StatusClearanceListTable;
