import React from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';

const Filter = () => {
  return (
    <div className='Filter'>
      <FormUI>
        <Row className='mb-4'>
        <Col sm={6}>
            <h4>Records</h4>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <FormControl
                type='text'
                className='bg-white'
                placeholder='Search Here'
              />
            </Form.Group>
          </Col>
          <Col sm={2}>
            <div className='buttonWrapper w-100'>
              <Button><span className='me-2'>+</span>Create</Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default Filter;