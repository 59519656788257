/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  showReport,
  createReport,
  updateDoh,
  dohReportList,
  showReportsACL,
} from './thunks/reportThunks';
import Constants from 'constant';

const initialState = {
  item: [],
  general: [],
  hospital: [],
  staffing: [],
  expenses: [],
  revenues: [],
  census: [],
  data: [],
  logs: {
    isLoading: false,
  },
  reportListData: [],
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    testAction: () => {
      // console.log('aaa');
    },
  },
  extraReducers: {
    [showReportsACL.fulfilled]: (state, actions) => {
      state.staffing = actions.payload.data;
    },
    [showReport.fulfilled]: (state, actions) => {
      if (actions.payload.type === Constants.GENERALINFORMATION) {
        state.general = actions.payload.data;
      }
      if (actions.payload.type === Constants.HOSPITALOPERATION) {
        state.hospital = actions.payload.data;
      }
      if (actions.payload.type === Constants.EXPENSES) {
        state.expenses = actions.payload.data;
      }
      if (actions.payload.type === Constants.REVENUES) {
        state.revenues = actions.payload.data;
      }
      if (actions.payload.type === Constants.CENSUS) {
        state.census = actions.payload.data;
      }

      state.isLoading = false;
    },
    [createReport.fulfilled]: (state, action) => {
      state.item = action.payload.data;
      state.isLoading = false;
    },
    [updateDoh.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [dohReportList.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [dohReportList.fulfilled]: (state, action) => {
      state.reportListData = action.payload.data;
      state.logs.isLoading = false;
    },
    [dohReportList.rejected]: (state) => {
      state.reportListData = [];
      state.logs.isLoading = true;
    },
  },
});

export const { actions: reportActions, reducer: reportReducer } = slice;

export const { testAction } = reportActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.report || initialState;

export const selectReport = createSelector([selectDomain], (reportState) => reportState);

export const getReportGeneral = createSelector(
  [selectDomain],
  (reportState) => reportState.general,
);

export const getReportHospital = createSelector(
  [selectDomain],
  (reportState) => reportState.hospital,
);

export const getReportStaffing = createSelector(
  [selectDomain],
  (reportState) => reportState.staffing,
);

export const getReportExpenses = createSelector(
  [selectDomain],
  (reportState) => reportState.expenses,
);

export const getReportRevenues = createSelector(
  [selectDomain],
  (reportState) => reportState.revenues,
);

export const getReportCensus = createSelector([selectDomain], (reportState) => reportState.census);

export const selectReportItem = createSelector([selectDomain], (reportState) => reportState.item);

export const updateDohAction = createSelector(
  [selectDomain],
  (reportState) => reportState.isLoading,
);

export const selectDohReportList = createSelector(
  [selectDomain],
  (reportState) => reportState.reportListData,
);
/* --------------------------------------------------------------------------------- */

export { showReport, createReport, updateDoh, dohReportList, showReportsACL };
