/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getDisbursedPatient } from 'reducers/billingReducer';
import { getDisbursedAdvanceSearchPatient } from 'reducers/thunks/billingThunks';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchPatientFilter = () => {
  const dispatch = useDispatch();

  const [searchData, setSearchData] = useState({
    id: '',
    patient_origin: '',
    patient_status: '',
  });

  useEffect(() => {
    dispatch(getDisbursedPatient(''));
  }, [searchData]);

  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    dispatch(getDisbursedPatient(searchedWord));
  };

  const handleChange = (key) => (e) => {
    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const search = () => {
    dispatch(
      getDisbursedAdvanceSearchPatient([
        searchData.id,
        searchData.patient_class,
        searchData.patient_status,
      ]),
    );
    /* axios
      .get('/disbursement/search/cashier/patient_disbursed', {
        params: {
          id: searchData.id,
          patientClass: searchData.patient_origin,
          patientStatus: searchData.patient_status,
        },
      })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      }); */
  };

  return (
    <div className='searchPatientFilter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Basic Search</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  placeholder='Search via Last Name or PIN…'
                  value={searchData.basic_search}
                  onChange={onChangeHandler}
                />
                <Button variant='search'>SEARCH</Button>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Accordion className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Advance Search</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={4}>
                      <Form.Group className='mb-4'>
                        <Form.Label>Search Patient ID:</Form.Label>
                        <InputGroup>
                          <InputGroup.Text className='bg-white'>
                            <MagnifyingGlassSVG />
                          </InputGroup.Text>
                          <FormControl
                            type='text'
                            className='bg-white'
                            placeholder='Search Here'
                            value={searchData.id}
                            onChange={handleChange('id')}
                            style={{ maxWidth: '100%' }}
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Patient Class</Form.Label>
                        <Form.Select
                          value={searchData.patient_class}
                          onChange={handleChange('patient_class')}
                        >
                          <option value='' hidden>
                            -Select-
                          </option>
                          <option value='new'>New</option>
                          <option value='OPD'>Outpatient</option>
                          <option value='emergency'>Emergency</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                          value={searchData.patient_status}
                          onChange={handleChange('patient_status')}
                        >
                          <option value='' hidden>
                            -All-
                          </option>
                          <option value='for_billing'>For Billing</option>
                          <option value='for_clearance'>For Clearance</option>
                          <option value='for_discharge'>For Discharge</option>
                          <option value='admitted'>Admitted</option>
                          <option value='for_admission_referred_from_opd'>
                            For Admission Referred from OPD
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={2} className='mt-4 pt-10'>
                      <div className='buttonWrapper'>
                        <Button variant='search' onClick={() => search()}>
                          SEARCH
                        </Button>

                        <Button
                          variant='clear'
                          onClick={() =>
                            setSearchData({
                              id: '',
                              patient_name: '',
                              billing_number: '',
                            })
                          }
                        >
                          CLEAR
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Row></Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

SearchPatientFilter.defaultProps = {
  state: [],
  setState: () => {},
};

SearchPatientFilter.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
};

export default SearchPatientFilter;
