/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table, { PAGE_SIZE } from 'components/ReactTable';
import FormUI from 'components/UI/FormUI';
// import EmployeeProfile from './EmployeeProfile';
import { Form, Row, Col, FormControl, InputGroup, Button } from 'react-bootstrap';
import Select from 'react-select';
import ConditionalRender from 'components/ConditionalRender';
import { selectEmployees } from 'reducers/employeesReducer';
import {
  selectEmployeeSchedules,
  getAllEmployeeSchedules,
  deleteEmployeeSchedulesByEmployeeId,
} from 'reducers/employeeScheduleReducer';
import { orderBy, debounce } from 'lodash';

// Import assets below
import { ReactComponent as CirclePlus } from 'assets/svg/circle-plus-white.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// Queries
const params = {
  current_page: 1,
  per_page: PAGE_SIZE[0], // [10, 20, 30, 100]
};

const Employee = ({ setEmpData, setActiveScreen, setActiveTab }) => {
  const schedules = useSelector(selectEmployeeSchedules);
  const [filteredData, setFilteredData] = useState(schedules.data);
  const [query, setQuery] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEmployeeSchedules(params));
  }, []);

  const getLatestShift = (shiftings) => {
    return orderBy(shiftings, ['assigned_date'], ['desc'])[0] || []
  }

  const debouncedChangeHandler = React.useCallback(
    debounce((value, key) => {
      setQuery((prevState) => ({ ...prevState, [key]: value }));
    }, 300),
    [],
  );

  useEffect(() => {
    if (query?.id_name || query?.date) {
      let _filteredData = schedules.data;

      if (query?.date) {
        _filteredData = schedules.data.filter((employee) =>
          // employee?.employee_detail?.department.toLowerCase().includes(query.date.toLowerCase())
          getLatestShift(employee?.employee_schedules)?.assigned_date.toLowerCase().includes(query.date.toLowerCase())
        );
      }

      if (query?.id_name) {
        _filteredData = _filteredData.filter((employee) =>
          [employee?.employee_detail?.eId, employee?.first_name, employee?.middle_name, employee?.last_name].join(' ').toLowerCase().includes(query.id_name.toLowerCase())
        );
      }

      setFilteredData(_filteredData);
    } else setFilteredData(schedules.data);
  }, [schedules.data, query]);

  // -----------------------------------------------------------------
  // SCHEDULE LIST

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Shifting Date', accessor: 'date' }, // accessor is the "key" in the data
      { Header: 'Avatar', accessor: 'avatar' },
      { Header: 'Employee ID', accessor: 'eId' },
      { Header: 'Name', accessor: 'name' },
      // { Header: 'Position', accessor: 'position' },
      { Header: 'Department', accessor: 'department' },
      { Header: 'Contact Number', accessor: 'contact_number' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      filteredData?.map((dd) => ({
        id: dd?.id,
        date: getLatestShift(dd?.employee_schedules)?.assigned_date,
        avatar: <img src={dd?.employee_detail?.avatar} height={50} width={50} />,
        eId: dd?.employee_detail?.eId,
        name: `${dd?.last_name}, ${dd?.first_name} ${dd?.middle_name}`,
        // position: dd?.employee_detail?.position,
        department: dd?.employee_detail?.department,
        contact_number: dd?.contact_number,
      })),
    [filteredData],
  );

  const handleTableAction = (eventKey, id) => {
    const row = filteredData.find((dd) => dd.id === id);
    const action = {
      profile: () => { },
      view: () => {
        setEmpData(row);
        setActiveScreen(4);
        setActiveTab('View Employee Shifting Schedule');
      },
      edit: () => {
        setEmpData(row);
        setActiveScreen(6);
        setActiveTab('Update Employee Shifting Schedule');
      },
      delete: () => {
        dispatch(deleteEmployeeSchedulesByEmployeeId(id));
      },
    };

    action[eventKey]();
  };

  // -----------------------------------------------------------------

  return (
    <div className='shifting-tab mt-4'>
      <FormUI>
        <Row className='mb-6'>
          <Col className='d-flex shifting-filter'>
            <Form.Group className='mb-3 bas ic-search w-50'>
              <Form.Label>Search Employee</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-white'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='text'
                  className='bg-white'
                  placeholder='Search ID/name'
                  style={{ maxWidth: '100%' }}
                  onChange={(e) => debouncedChangeHandler(e.target.value, 'id_name')}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className='mb-3 inline-group'>
              <div className='label'>
                <p>Filter by:</p>
              </div>
              <FormControl
                type='date'
                onChange={(e) => debouncedChangeHandler(e.target.value, 'date')}
              />
            </Form.Group>
          </Col>
          <Col className='mb-3 d-flex justify-content-end align-items-end'>
            <Button
              onClick={() => {
                setActiveScreen(5);
                setActiveTab('Add New Schedule');
              }}
              className='add-employee-btn'
            >
              <CirclePlus />
              <span>ADD SCHEDULE</span>
            </Button>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Table
            data={tableData}
            columns={tableColumns}
            actions={{ hasView: true, hasEdit: true, hasDelete: true }}
            onAction={handleTableAction}
            pagination={['BOTTOM']}
          />
        </Row>
      </FormUI>
    </div>
  );
};

Employee.defaultProps = {
  setActiveTab: () => { },
};

Employee.propTypes = {
  setActiveTab: PropTypes.func,
};

export default Employee;
