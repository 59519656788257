/* eslint-disable */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import seturlParams from 'helpers/seturlParams';
import { getDepartmentMaintenanceRepairList } from 'reducers/departmentInventoryReducer';

// import assets below
import './Filter.scss';
import { ReactComponent as AddGroupSVG } from 'assets/svg/add-group.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchInventoryFilter = ({ departmentID, active, searchData, setSearchData }) => {
  const dispatch = useDispatch();

  const handleChange = (key) => (e) => {
    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const handleSearch = (reset = false) => {
    let params = {
      per_page: 100000,
      department_id: departmentID,
      q: reset ? '' : searchData?.search,
    };

    if (active !== '') {
      params.status = active;
    }

    dispatch(getDepartmentMaintenanceRepairList(params));
  };

  const getInventoryMaintenanceRepair = (reset = false) => {
    let params = {
      per_page: 100000,
      department_id: departmentID,
      q: reset ? '' : searchData?.search,
    };

    if (active === '') {
      params.completed_by = false;
    }

    if (active !== '') {
      params.status = active;
    }

    if (active === '12' || active === '11') {
      params.completed_by = true;
    }

    console.log(params);

    dispatch(getDepartmentMaintenanceRepairList(params));
  };

  return (
    <div className='admissions-search-inventory-filter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col className='d-flex align-items-center'>
            <Form.Group className='mb-3 me-3'>
              <Form.Label>Search</Form.Label>
              <InputGroup className='search'>
                <InputGroup.Text>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='text'
                  placeholder='Enter here'
                  value={searchData?.search}
                  onChange={handleChange('search')}
                />
              </InputGroup>
            </Form.Group>
            <div style={{ marginTop: '15px' }}>
              <Button
                onClick={() => {
                  handleSearch();
                }}
                style={{ height: '50px' }}
                className='btn btn-success me-3'
              >
                APPLY
              </Button>
              <Button
                onClick={() => {
                  setSearchData({
                    ...searchData,
                    search: '',
                  });
                  // handleSearch(true);
                  getInventoryMaintenanceRepair(true);
                }}
                style={{ height: '50px' }}
                className='btn btn-secondary me-3'
              >
                RESET
              </Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default SearchInventoryFilter;
