/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-indent-props */
// import core & vendor packages below
import axios from 'axios';
import uuidv4 from 'react-uuid';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Country, State, City } from 'country-state-city';
import { Widget, FileInfo } from '@uploadcare/react-widget';
import { Row, Col, Accordion, Form, Button } from 'react-bootstrap';

// import assets below
import FormUI from 'components/UI/FormUI';
import AccordionUI from 'components/UI/AccordionUI';
import { nationalityList } from 'helpers/nationality';
import FileUploadModal from './components/FileUpload';
import ModalAddDiagnosis from './components/AddDiagnosis';
import { redirectBackToPreviousPage } from '../../helpers';
import ConfirmationModal from './components/ConfirmationModal';
import FormMobileNumber from 'components/Form/FormMobileNumber';
import { regions, provinces, cities, barangays } from 'helpers/SelectAddress';

// assets
import './style.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as CirclePlusSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CircleMinusSVG } from 'assets/svg/circle-minus.svg';

// main component
const FormCreateNewPatient = () => {
  // For Add diagnosis
  const [showProfile, setShowProfile] = useState({
    isShowDiagnosis: false,
  });
  const [diagnosis, setDiagnosis] = useState();

  const navigate = useNavigate();
  const [sex, setSex] = useState('');
  const [age, setAge] = useState('');
  const [suffix, setSuffix] = useState('');
  const [prefix, setPrefix] = useState('');
  const [lastName, setLastName] = useState('');
  const [religion, setReligion] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [isNewBorn, setIsNewBorn] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [nationality, setNationality] = useState('');
  const [civilStatus, setCivilStatus] = useState('');
  const [aliasPrefix, setAliasPrefix] = useState('');
  const [aliasSuffix, setAliasSuffix] = useState('');
  const [aliasGender, setAliasGender] = useState('');
  const [aliasLastName, setAliasLastName] = useState('');
  const [aliasFirstName, setAliasFirstName] = useState('');
  const [aliasDiagnosis, setAliasDiagnosis] = useState('');
  const [aliasToggle, setAliasToggle] = useState(false);
  const [isStillBirth, setIsStillBirth] = useState(false);
  const [uploadedAvatar, setUploadedAvatar] = useState('');
  const [aliasMiddleName, setAliasMiddleName] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [hospitalService, setHospitalService] = useState('');
  const [isDeadOnArrival, setIsDeadOnArrival] = useState(false);
  const [referenceLocation, setReferenceLocation] = useState('');
  // const [patientEndorsement, setPatientEndorsement] = useState('');
  const [fictitiousDateOfBirth, setFictitiousDateOfBirth] = useState('');
  const [MRN, setMRN] = useState(Math.floor(Math.random() * Date.now()));
  const [cityList, setCityList] = useState(State.getStatesOfCountry('PH'));
  const [countryList, setCountryList] = useState(Country.getAllCountries());

  /** Present address */
  const [presentCity, setPresentCity] = useState('');
  const [presentBrgy, setPresentBrgy] = useState('');
  const [presentRegion, setPresentRegion] = useState('');
  const [presentZipCode, setPresentZipCode] = useState('');
  const [presentProvince, setPresentProvince] = useState('');
  const [presentAddress1, setPresentAddress1] = useState('');
  const [presentAddress2, setPresentAddress2] = useState('');
  const [presentCountry, setPresentCountry] = useState('Philippines');

  /** permanent address */
  const [permanentCity, setPermanentCity] = useState('');
  const [permanentBrgy, setPermanentBrgy] = useState('');
  const [permanentRegion, setPermanentRegion] = useState('');
  const [permanentZipCode, setPermanentZipCode] = useState('');
  const [permanentProvince, setPermanentProvince] = useState('');
  const [permanentAddress1, setPermanentAddress1] = useState('');
  const [permanentAddress2, setPermanentAddress2] = useState('');
  const [permanentRegionIndex, setPermanentRegionIndex] = useState('');
  const [permanentCountry, setPermanentCountry] = useState('Philippines');
  const [permanentProvinceIndex, setPermanentProvinceIndex] = useState('');

  const [sameAsPresentAddress, setSameAsPresentAddress] = useState(true);
  const [patientStatus, setPatientStatus] = useState('');
  const [patientOrigin, setPatientOrigin] = useState('OPD');
  const [submitType, setSubmitType] = useState('');
  const [motherId, setMotherId] = useState('');
  const [motherName, setMotherName] = useState('');

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  let maxDate = new Date().toISOString();
  maxDate = maxDate.slice(0, 10);

  const savePatient = () => {
    if (isStillBirth) {
      setPatientStatus('still_birth');
    } else if (isDeadOnArrival) {
      setPatientStatus('dead_on_arrival');
    } else {
      setPatientStatus('new');
    }

    let isSuccess = true;
    inputFields.forEach((data) => {
      const { type, details } = data;

      if (type === 'Mobile Number') {
        if (details.length !== 11 && details.length !== 0) {
          toast.error('Mobile number must have 11 digits.');
          isSuccess = false;
          return;
        }
      }

      if (type === 'Landline Number') {
        if (details.length !== 8 && details.length !== 0) {
          toast.error('Landline number must have 8 digits.');
          isSuccess = false;
          return;
        }
      }
    });

    if (!diagnosis) {
      toast.error('Please add diagnosis.');
      isSuccess = false;
      return;
    }

    // eslint-disable-next-line no-unreachable
    if (isSuccess) {
      setSubmitType('savePatient');
      setOpenModal(true);
    }
  };

  const saveAndAdmit = () => {
    if (isStillBirth) {
      setPatientStatus('still_birth');
    } else if (isDeadOnArrival) {
      setPatientStatus('dead_on_arrival');
    } else {
      setPatientStatus('admitted');
    }

    setSubmitType('saveAndAdmit');
    setOpenModal(true);
  };

  /** Contact Details start */
  const [inputFields, setInputFields] = useState([
    { uuid: uuidv4(), type: '', details: '', placeholder: 'Please select type' },
  ]);

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { uuid: uuidv4(), type: '', details: '', placeholder: 'Please select type' },
    ]);
  };

  const handleRemoveFields = (uuid) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setInputFields(values);
  };

  const newPlaceholder = (type) => {
    switch (type) {
      case '':
        return 'Please select type';
      case 'Mobile Number':
        return 'Enter mobile number (11)';
      case 'Landline Number':
        return 'Enter landline number (8)';
      default:
        return 'Please select type';
    }
  };

  const handleChangeInput = (uuid, event) => {
    const newData = inputFields.map((data) => {
      if (data?.uuid === uuid) {
        if (event.target.name === 'type') {
          const placeholder = newPlaceholder(event.target.value);

          const newData = {
            ...data,
            [event.target.name]: event.target.value,
            placeholder: placeholder,
          };

          return newData;
        }

        const newData = {
          ...data,
          [event.target.name]: event.target.value,
        };

        return newData;
      }

      return data;
    });

    setInputFields(newData);
  };

  /** Other Details start */
  const [inputOtherFields, setInputOtherFields] = useState([
    { uuid: uuidv4(), endorsement: '', details: '', status: '' },
  ]);

  const handleAddOtherFields = () => {
    setInputOtherFields([
      ...inputOtherFields,
      { uuid: uuidv4(), endorsement: '', details: '', status: '' },
    ]);
  };

  const handleRemoveOtherFields = (uuid) => {
    const values = [...inputOtherFields];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setInputOtherFields(values);
  };

  const handleChangeOtherInput = (uuid, event) => {
    const newInputOtherFields = inputOtherFields.map((i) => {
      if (uuid === i.uuid) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputOtherFields(newInputOtherFields);
  };

  const [key, setKey] = useState(false);

  const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    const now = new Date();

    const age = today.getFullYear() - birthDate.getFullYear();

    if (birthDate < now) {
      setDateOfBirth(dateString);
      setAge(age);
    } else {
      setAge(0);
      setKey((k) => !k);
    }
  };

  const handleCheckBoxValue = (event, field) => {
    switch (field) {
      case 'newBorn':
        setIsNewBorn(event.target.checked);
        break;

      case 'fictitiousDateOfBirth':
        setFictitiousDateOfBirth(event.target.checked);
        break;

      case 'sameAsPresentAddress':
        setSameAsPresentAddress(event.target.checked);
        setPermanentRegion(presentRegion);
        break;
      case 'doa':
        setIsDeadOnArrival(event.target.checked);
        break;
      case 'stillBirth':
        setIsStillBirth(event.target.checked);
        break;
    }
  };

  const handleDropdownValue = (event, field) => {
    switch (field) {
      case 'nationality':
        setNationality(event.target.value);
        break;
      case 'civil_status':
        setCivilStatus(event.target.value);
        break;
      case 'hospital_service':
        setHospitalService(event.target.value);
        break;
      case 'prefix':
        setPrefix(event.target.value);
        break;

      case 'sex':
        setSex(event.target.value);
        break;

      case 'aliasPrefix':
        setAliasPrefix(event.target.value);
        break;

      case 'presentCountry':
        setPresentCountry(event.target.value);
        break;

      case 'presentRegion':
        setPresentRegion(event.target.value);
        break;

      case 'presentCity':
        setPresentCity(event.target.value);
        break;

      case 'presentBrgy':
        setPresentBrgy(event.target.value);
        break;

      case 'permanentCountry':
        setPermanentCountry(event.target.value);
        break;

      case 'permanentRegion':
        setPermanentRegion(event.target.value);
        break;

      case 'permanentCity':
        setPermanentCity(event.target.value);
        break;

      case 'permanentBrgy':
        setPermanentBrgy(event.target.value);
        break;
    }
  };

  const [profilePic, setProfilePic] = useState([]);
  const params = {
    profilePicture: profilePic,
    motherId: motherId,
    motherName: motherName,
    reference_number: referenceNumber,
    reference_location: referenceLocation,
    MRN: MRN,
    patient_origin: patientOrigin,
    is_newborn: isNewBorn,
    prefix: prefix,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    suffix: suffix,
    sex: sex,
    date_of_birth: dateOfBirth,
    age: age,
    is_fictitious_date_of_birth: fictitiousDateOfBirth,
    nationality: nationality,
    civil_status: civilStatus,
    // religion: religion,
    // alias_prefix: aliasPrefix,
    // alias_first_name: aliasFirstName,
    // alias_middle_name: aliasMiddleName,
    // alias_last_name: aliasLastName,
    // alias_suffix: aliasSuffix,
    // alias_gender: aliasGender,
    // alias_diagnosis: aliasDiagnosis,
    patient_status: patientStatus,
    patient_present_address: {
      present_country: presentCountry,
      present_region: presentRegion,
      patient_province: presentProvince,
      present_city: presentCity,
      present_zip_code: presentZipCode,
      present_address1: presentAddress1,
      present_address2: presentAddress2,
      present_brgy: presentBrgy,
    },
    // patient_permanent_address: {
    //   permanent_country: sameAsPresentAddress ? presentCountry : permanentCountry,
    //   permanent_region: sameAsPresentAddress ? presentRegion : permanentRegion,
    //   patient_province: sameAsPresentAddress ? presentProvince : permanentProvince,
    //   permanent_city: sameAsPresentAddress ? presentCity : permanentCity,
    //   permanent_zip_code: sameAsPresentAddress ? presentZipCode : permanentZipCode,
    //   permanent_address1: sameAsPresentAddress ? presentAddress1 : permanentAddress1,
    //   permanent_address2: sameAsPresentAddress ? presentAddress2 : permanentAddress2,
    //   permanent_brgy: sameAsPresentAddress ? presentBrgy : permanentBrgy,
    // },
    contact_details: inputFields,
    other_details: inputOtherFields,

    // For Diagnosis
    diagnosis: diagnosis,
  };

  const [regionData, setRegion] = useState([]);
  const [provinceData, setProvince] = useState([]);
  const [cityData, setCity] = useState([]);
  const [barangayData, setBarangay] = useState([]);

  const [patientList, setPatientList] = useState([]);

  const region = () => {
    const response = regions();
    setRegion(response);
  };

  const province = (e) => {
    setPresentRegion(e.target.selectedOptions[0].text);

    const response = provinces(e.target.value);
    setProvince(response);
    setCity([]);
    setBarangay([]);
  };

  const city = (e) => {
    setPresentProvince(e.target.selectedOptions[0].text);

    const response = cities(e.target.value);
    setCity(response);
    setBarangay([]);
  };

  const barangay = (e) => {
    setPresentCity(e.target.selectedOptions[0].text);

    const response = barangays(e.target.value);
    setBarangay(response);
  };

  const brgy = (e) => {
    setPresentBrgy(e.target.selectedOptions[0].text);
  };

  const [permRegionData, setPermRegion] = useState([]);
  const [permProvinceData, setPermProvince] = useState([]);
  const [permCityData, setPermCity] = useState([]);
  const [permBarangayData, setPermBarangay] = useState([]);

  const permRegion = () => {
    const response = regions();
    setPermRegion(response);
  };

  const permProvince = (e) => {
    const selectedRegion = permRegionData[e.target.value];
    setPermanentRegion(selectedRegion.region_name);
    setPermanentRegionIndex(e.target.value);

    const response = provinces(selectedRegion.region_code);
    setPermProvince(response);
    setPermCity([]);
    setPermBarangay([]);
  };

  const permCity = (e) => {
    const selectedRegion = permProvinceData[e.target.value];
    setPermanentProvince(selectedRegion.province_name);
    setPermanentProvinceIndex(e.target.value);

    const response = cities(selectedRegion.province_code);
    setPermBarangay([]);
    setPermCity(response);
  };

  const permBarangay = (e) => {
    setPermanentCity(e.target.selectedOptions[0].text);

    const response = barangays(e.target.value);
    setPermBarangay(response);
  };

  const permBrgy = (e) => {
    setPermanentBrgy(e.target.selectedOptions[0].text);
  };

  useEffect(() => {
    region();
    permRegion();
  }, []);

  const handleFile = async (e) => {
    const file = e.target.files[0];
    switch (file.type) {
      case 'image/png':
        setProfilePic(file);
        setUploadedAvatar({
          url: URL.createObjectURL(e.target.files[0]),
          fileName: e.target.value.split('C:\\fakepath\\').pop(),
        });
        break;
      case 'image/jpeg':
        setProfilePic(file);
        setUploadedAvatar({
          url: URL.createObjectURL(e.target.files[0]),
          fileName: e.target.value.split('C:\\fakepath\\').pop(),
        });
        break;
      default:
        toast.warning('Invalid File format!!');
    }

    const formdata = new FormData();
  };

  useEffect(() => {
    axios
      .get('/patient')
      .then((response) => {
        const data = response?.data?.data.filter((item, key) => {
          if (item?.sex === 'female' && item?.age >= 12) {
            return item;
          }
        });
        setPatientList(data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const [validated, setValidated] = useState(false);

  return (
    <div className='formCreateNewPatient'>
      <FormUI>
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => {
            e.preventDefault();
            const ButtonName = e.nativeEvent.submitter.name;
            const form = e.currentTarget;

            if (form.checkValidity() === true) {
              if (ButtonName === 'saveAdmit') {
                saveAndAdmit();
              }

              if (ButtonName === 'savePatient') {
                savePatient();
              }
            }

            setValidated(true);
          }}
        >
          <div className='formCreateNewPatient__header'>
            <h4>Create New Patient</h4>
            <div>
              <Link className='btn btn-danger' to={{ pathname: '/dashboard/medical-records' }}>
                MEDICAL RECORDS
              </Link>
              <Button
                onClick={() => setShowUploadModal(true)}
                className='btn btn-click me-3 '
                style={{
                  marginLeft: 10,
                }}
              >
                <small>UPLOAD FILES</small>
              </Button>
            </div>
          </div>
          <div className='avatar__container'>
            <div className='svg__container'>
              {!uploadedAvatar && <UserSVG />}
              {uploadedAvatar && (
                <div
                  className='uploadedAvatar'
                  style={{ backgroundImage: `url(${uploadedAvatar.url})` }}
                ></div>
              )}
            </div>
            <Form.Group className='mt-3'>
              <Form.Label>
                <div className='fakeInput'>
                  {!uploadedAvatar && 'File'}
                  {uploadedAvatar && uploadedAvatar.fileName}
                  <span>Choose</span>
                </div>
                <Form.Control type='file' onChange={(e) => handleFile(e)} />
              </Form.Label>
            </Form.Group>
          </div>
          <AccordionUI>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Body>
                  <FormUI>
                    <Row>
                      <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Reference number
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Reference Number'
                            value={referenceNumber}
                            onChange={(event) => {
                              setReferenceNumber(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Reference Location<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Medical Records Location'
                            value={referenceLocation}
                            onChange={(event) => {
                              setReferenceLocation(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Hospital Service</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row>
                      <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Hospital Service<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) => handleDropdownValue(event, 'hospital_service')}
                          >
                            <option value=''>-Select-</option>
                            <option value='EENT'>EENT</option>
                            <option value='Medicine'>Medicine</option>
                            <option value='OB-GYNE'>OB-GYNE</option>
                            <option value='Urology'>Urology</option>
                            <option value='Cardiology'>Cardiology</option>
                            <option value='Pulmonary Medicine'>Pulmonary Medicine</option>
                            <option value='Opthalmology'>Opthalmology</option>
                            <option value='Surgery'>Surgery</option>
                            <option value='Orthopedic Surgery'>Orthopedic Surgery</option>
                            <option value='Endocrinology'>Endocrinology</option>
                            <option value='Pediatrics'>Pediatrics</option>
                            <option value='Dermatology'>Dermatology</option>
                            <option value='Oncology'>Oncology</option>
                            <option value='Neurology'>Neurology</option>
                            <option value='Gastroenterology'>Gastroenterology</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Patient Demographics</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row className='d-flex align-items-center'>
                      <Col lg={2}>
                        <Form.Group className='mb-3'>
                          <Form.Label>Hospital MRN <span className='asterisk'>*</span></Form.Label>
                          <Form.Control
                            style={{ maxWidth: '320px' }}
                            type='text'
                            readOnly
                            required
                            value={MRN}
                            placeholder='Enter Here'
                            onChange={(event) => {
                              setMRN(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={2}>
                        <Form.Group className='mt-3 mb-3'>
                          <Form.Check
                            type='checkbox'
                            label='New Born'
                            onChange={(event) => handleCheckBoxValue(event, 'newBorn')}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col lg={2}>
                        <Form.Group className='mt-3 mb-3'>
                          <Form.Check
                            type='checkbox'
                            label='New Born'
                            onChange={(event) => handleCheckBoxValue(event, 'newBorn')}
                          />
                        </Form.Group>
                      </Col>
                      {isNewBorn && (
                        <Col lg={2}>
                          <Form.Group className='mt-3 mb-3'>
                            <Form.Check
                              type='checkbox'
                              label='Stillbirth'
                              disabled={isDeadOnArrival}
                              onChange={(event) => handleCheckBoxValue(event, 'stillBirth')}
                            />
                          </Form.Group>
                        </Col>
                      )}
                      <Col lg={2}>
                        <Form.Group className='mt-3 mb-3'>
                          <Form.Check
                            type='checkbox'
                            label='Dead on Arrival'
                            disabled={isStillBirth}
                            onChange={(event) => handleCheckBoxValue(event, 'doa')}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                    {isNewBorn && (
                      <Row>
                        <Col lg='4'>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Search Mother<span className='asterisk'>*</span>
                            </Form.Label>
                            <Typeahead
                              required={isNewBorn}
                              id='name'
                              labelKey='full_name'
                              options={patientList}
                              placeholder='Enter Here'
                              onChange={(event) => {
                                if (event.length > 0) {
                                  setMotherId(event[0]?.id);
                                  setMotherName(event[0]?.full_name);
                                }
                              }}
                              inputProps={{ required: true }}
                            />
                            {/* <Form.Control
                          required
                          type='text'
                          placeholder='Enter Here'
                          value={nationality}
                          onChange={(event) => {
                            setNationality(event.target.value);
                          }}
                        /> */}
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col lg='2'>
                        <Form.Group className='mb-3'>
                          <Form.Label>Prefix/Title</Form.Label>
                          <Form.Select onChange={(event) => handleDropdownValue(event, 'prefix')}>
                            <option value=''>-</option>
                            <option value='Mr.'>Mr.</option>
                            <option value='Ms.'>Ms.</option>
                            <option value='Mrs.'>Mrs.</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            First Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter First Name'
                            value={firstName}
                            onChange={(event) => {
                              setFirstName(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Middle Name
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Middle Name'
                            value={middleName}
                            onChange={(event) => {
                              setMiddleName(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Last Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Last Name'
                            value={lastName}
                            onChange={(event) => {
                              setLastName(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Suffix</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Suffix'
                            value={suffix}
                            onChange={(event) => {
                              setSuffix(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='2'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Sex<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) => handleDropdownValue(event, 'sex')}
                          >
                            <option value=''>-Select-</option>
                            <option value='male'>Male</option>
                            <option value='female'>Female</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Date of Birth<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            key={key}
                            type='date'
                            onChange={(event) => {
                              getAge(event.target.value);
                            }}
                            max={maxDate}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Age<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter date of birth'
                            readOnly
                            value={age}
                            onChange={(event) => {
                              setAge(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col>
                        <Form.Group className='d-flex h-100 mb-3'>
                          <Form.Check
                            type='checkbox'
                            label='Fictitious Date of Birth'
                            onChange={(event) =>
                              handleCheckBoxValue(event, 'fictitiousDateOfBirth')
                            }
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col lg='4'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Nationality
                          </Form.Label>
                          {/* <Typeahead
                            id='name'
                            labelKey='name'
                            options={nationalityList}
                            placeholder='Enter Here'
                            onChange={(event) => handleNationality(event.target.value)}
                            inputProps={{ required: true }}
                          /> */}
                          <Form.Select
                            onChange={(event) => handleDropdownValue(event, 'nationality')}
                          >
                            <option value=''>-</option>
                            {nationalityList.map((data) => {
                              return (
                                <option key={data.label} value={data.label}>
                                  {data.label}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col lg='4'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Civil Status
                          </Form.Label>
                          <Form.Select
                            onChange={(event) => handleDropdownValue(event, 'civil_status')}
                          >
                            <option value=''>-</option>
                            <option value='Single'>Single</option>
                            <option value='Married'>Married</option>
                            <option value='Separated'>Separated</option>
                            <option value='Annulled'>Annulled</option>
                            <option value='Widowed'>Widowed</option>
                            <option value='Widower'>Widower</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      {/* <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Religion<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={religion}
                            onChange={(event) => {
                              setReligion(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Patient Address Details</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row className='d-flex align-items-center'>
                      <Col lg={2}>
                        <h6 className='mb-3 fw-bold'>Present Address</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Country<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select required value='Philippines'>
                            <option value=''>Select Country</option>
                            {countryList.map((item, key) => {
                              return (
                                <option key={key} value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Region<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={province}
                            onSelect={region}
                          >
                            <option value=''>-</option>
                            {regionData &&
                              regionData.length > 0 &&
                              regionData.map((item, key) => {
                                return (
                                  <option key={item.region_code} value={item.region_code}>
                                    {item.region_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Province<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={city}
                          >
                            <option value=''>-</option>
                            {provinceData &&
                              provinceData.length > 0 &&
                              provinceData.map((item, key) => {
                                return (
                                  <option key={item.province_code} value={item.province_code}>
                                    {item.province_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            City<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={barangay}
                          >
                            <option value=''>-</option>
                            {cityData &&
                              cityData.length > 0 &&
                              cityData.map((item, key) => {
                                return (
                                  <option key={item.city_code} value={item.city_code}>
                                    {item.city_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Barangay<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={brgy}
                          >
                            <option value=''>-</option>
                            {barangayData &&
                              barangayData.length > 0 &&
                              barangayData.map((item, key) => {
                                return (
                                  <option key={item.brgy_code} value={item.brgy_code}>
                                    {item.brgy_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Zip Code<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            onChange={(event) => {
                              setPresentZipCode(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Address Line 1<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            onChange={(event) => {
                              setPresentAddress1(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Subdivision/Village</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            onChange={(event) => {
                              setPresentAddress2(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row className='d-flex align-items-center'>
                      <Col>
                        <div className='d-flex align-items-center my-3'>
                          <span className='me-3 fw-bold'>Permanent Address</span>
                          <Form.Check
                            type='checkbox'
                            label='Same as Present Address'
                            onChange={(event) => handleCheckBoxValue(event, 'sameAsPresentAddress')}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Country<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select required value='Philippines'>
                            <option value=''>Select Country</option>
                            {countryList.map((item, key) => {
                              return (
                                <option key={key} className='' value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Region<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={permProvince}
                            onSelect={permRegion}
                            value={permanentRegionIndex}
                          >
                            <option value=''></option>
                            {permRegionData &&
                              permRegionData.length > 0 &&
                              permRegionData.map((item, key) => {
                                return (
                                  <option key={item.region_code} value={key}>
                                    {item.region_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Province<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={permCity}
                            value={permanentProvinceIndex}
                          >
                            <option value=''></option>
                            {permProvinceData &&
                              permProvinceData.length > 0 &&
                              permProvinceData.map((item, key) => {
                                return (
                                  <option key={item.province_code} value={key}>
                                    {item.province_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            City<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={permBarangay}
                          >
                            <option value=''>{sameAsPresentAddress ? presentCity : ''}</option>
                            {permCityData &&
                              permCityData.length > 0 &&
                              permCityData.map((item, key) => {
                                return (
                                  <option key={item.city_code} value={item.city_code}>
                                    {item.city_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Barangay<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={permBrgy}
                          >
                            <option value=''>{sameAsPresentAddress ? presentBrgy : ''}</option>
                            {permBarangayData &&
                              permBarangayData.length > 0 &&
                              permBarangayData.map((item, key) => {
                                return (
                                  <option key={item.brgy_code} value={item.brgy_code}>
                                    {item.brgy_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Zip Code<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={sameAsPresentAddress ? presentZipCode : permanentZipCode}
                            onChange={(event) => {
                              setPermanentZipCode(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Address Line 1<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={sameAsPresentAddress ? presentAddress1 : permanentAddress1}
                            onChange={(event) => {
                              setPermanentAddress1(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Subdivision/Village</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={sameAsPresentAddress ? presentAddress2 : permanentAddress2}
                            onChange={(event) => {
                              setPermanentAddress2(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row> */}
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Row>
              <Col>
                <Accordion defaultActiveKey='0'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Patient&#39;s Contact Details</Accordion.Header>
                    <Accordion.Body>
                      <FormUI>
                        <Form.Group>
                          <Row>
                            <Col lg='5'>
                              <Form.Label>Contact Type</Form.Label>
                            </Col>
                            <Col lg='5'>
                              <Form.Label>Contact Details</Form.Label>
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          {inputFields.map((inputField, key, { length }) => (
                            <div key={inputField.uuid}>
                              <Row>
                                <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Select
                                      name='type'
                                      value={inputField?.type}
                                      onChange={(event) =>
                                        handleChangeInput(inputField.uuid, event)
                                      }
                                    >
                                      <option value='' hidden>
                                        - Select -
                                      </option>
                                      <option value='Mobile Number'>Mobile Number</option>
                                      <option value='Landline Number'>Landline Number</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Control
                                      type='number'
                                      maxlength='11'
                                      name='details'
                                      placeholder={inputField?.placeholder}
                                      value={inputField.details}
                                      onKeyDown={(evt) =>
                                        (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                                        evt.preventDefault()
                                      }
                                      onChange={(event) => {
                                        if (event.target.value.length >= 12) {
                                          return;
                                        } else {
                                          handleChangeInput(inputField.uuid, event);
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </Col>

                                <Col lg='auto' className='d-flex align-items-center'>
                                  {key + 1 === length ? (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleAddFields}
                                    >
                                      <CirclePlusSVG />
                                    </Button>
                                  ) : (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleRemoveFields}
                                    >
                                      <CircleMinusSVG />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </Form.Group>
                      </FormUI>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col>
                <Accordion defaultActiveKey='0'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Patient&#39;s Other Details</Accordion.Header>
                    <Accordion.Body>
                      <FormUI>
                        <Form.Group>
                          {inputOtherFields.map((inputOtherField, key, { length }) => (
                            <div key={inputOtherField.uuid}>
                              <Row>
                                <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>Patient Endorsement</Form.Label>
                                    <Form.Control
                                      type='text'
                                      placeholder='Enter patient endorsement'
                                      name='endorsement'
                                      value={inputOtherField.endorsement}
                                      onChange={(event) =>
                                        handleChangeOtherInput(inputOtherField.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>

                                {/* <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      Patient Details<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Control
                                      type='text'
                                      placeholder='Enter patient details'
                                      name='details'
                                      value={inputOtherField.details}
                                      onChange={(event) =>
                                        handleChangeOtherInput(inputOtherField.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col> */}

                                <Col lg='auto' className='d-flex align-items-center'>
                                  {key + 1 === length ? (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleAddOtherFields}
                                    >
                                      <CirclePlusSVG />
                                    </Button>
                                  ) : (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleRemoveOtherFields}
                                    >
                                      <CircleMinusSVG />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col lg='10'>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      Status<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Select
                                      name='status'
                                      onChange={(event) =>
                                        handleChangeOtherInput(inputOtherField.uuid, event)
                                      }
                                    >
                                      <option value=''>-</option>
                                      <option value='walkin'>Walk-In</option>
                                      <option value='transferredFromOtherFacility'>
                                        Transferred from Other Facility
                                      </option>
                                      <option value='transferredToOtherFacility'>
                                        Transferred to Other Facility
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row> */}
                            </div>
                          ))}
                        </Form.Group>
                      </FormUI>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Accordion defaultActiveKey='0'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Diagnosis</Accordion.Header>
                    <Accordion.Body>
                      <Button
                        onClick={() => {
                          setShowProfile((prev) => {
                            return {
                              ...prev,
                              isShowDiagnosis: true,
                            };
                          });
                        }}
                      >
                        Add Diagnosis
                      </Button>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </AccordionUI>
          <div className='d-flex justify-content-end mt-5'>
            <Link className='btn btn-danger' to={{ pathname: '/dashboard/medical-records' }}>
              MEDICAL RECORDS
            </Link>
            &nbsp;&nbsp;
            <Button className='me-3' type='submit' variant='save' name='savePatient'>
              SAVE
            </Button>
            <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
              CANCEL
            </Button>
          </div>
        </Form>
      </FormUI>
      {openModal === true && (
        <ConfirmationModal
          params={params}
          submitType={submitType}
          setOpenModal={setOpenModal}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
      )}
      <FileUploadModal
        uploadedFiles={uploadedFiles}
        showUploadModal={showUploadModal}
        setUploadedFiles={setUploadedFiles}
        setShowUploadModal={setShowUploadModal}
      />

      <ModalAddDiagnosis
        showProfile={showProfile}
        setDiagnosis={setDiagnosis}
        setShowProfile={setShowProfile}
      />
    </div>
  );
};

export default FormCreateNewPatient;
