/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import {
    Table,
    Button,
    Dropdown,
    OverlayTrigger,
    Popover,
    Pagination,
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';

// import components below
import TableUI from 'components/UI/TableUI';
import { selectPatientData } from 'reducers/patientReducer';
import PaginationUI from 'components/UI/PaginationUI';

// import assets below
import './Table.scss';
import { ReactComponent as ThreeDotsSVG } from 'assets/svg/three-dots-svgrepo-com.svg';
import { ReactComponent as UserCircle } from 'assets/svg/user_circle.svg';
import { ReactComponent as EyeIcon } from 'assets/svg/eye-icon.svg';
import { ReactComponent as Writing } from 'assets/svg/writing-green.svg';
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/angle-left.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/svg/angle-right.svg';
import { ReactComponent as CircleCheckSVG } from 'assets/svg/circle-check.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

// main component
const ClearanceTable = ({ setShowModal, state, setState, setData }) => {
    const data = useSelector(selectPatientData);

    const tagPatientAsCleared = (id) => {
        axios.put(`/clearance/${id}`)
            .then((response) => {
                axios
                    .get('/clearance?type=PHARMACY')
                    .then((response) => {
                        setState(response.data.data);
                    }, [])
                    .catch((err) => {
                        console.log('AXIOS ERROR: ', err);
                    });
                toast.success('Successfully tag patient as cleared');
            })
            .catch((error) => {
                console.log(error);
                toast.error('ERROR');
            });
    };

    useEffect(() => {
        axios
            .get('/clearance?type=PHARMACY')
            .then((response) => {
                setState(response.data.data);
            }, [])
            .catch((err) => {
                console.log('AXIOS ERROR: ', err);
            });
    }, []);

    return (
        <>
            <div className='add-doctor-table'>
                <TableUI>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Patient Name</th>
                                <th style={{ width: '20%' }}>Is Cleared?</th>
                                <th style={{ width: '10%' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state &&
                                state.length > 0 ?
                                state.map((list) => (
                                    <tr key={list?.id}>
                                        <td><b>{list?.patient?.full_name}</b></td>
                                        <td>{list?.isCleared ? 'Yes' : 'No'}</td>
                                        <td>
                                            <OverlayTrigger
                                                trigger='focus'
                                                placement='bottom'
                                                overlay={
                                                    <Popover id='popover-positioned-bottom'>
                                                        <Popover.Body className='d-flex justify-content-between p-0'>
                                                            <EyeIcon
                                                                className='p-1 text-success'
                                                                style={{ width: '2em', height: '2em', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    setData(list.patient.medications);
                                                                    setShowModal((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            showDetails: true,
                                                                        };
                                                                    });
                                                                }}
                                                            />
                                                            <CircleCheckSVG
                                                                className='p-1 border-end'
                                                                style={{ width: '2em', height: '2em', cursor: 'pointer', fill: '#00c41a' }}
                                                                onClick={() => tagPatientAsCleared(list.id)}
                                                            />
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <Button
                                                    variant='secondary'
                                                    className='popoverButton bg-transparent p-0 border-0'
                                                >
                                                    <ThreeDotsSVG style={{ width: '1.5em' }} />
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))
                                : <tr><td>No record found.</td></tr>
                            }
                        </tbody>
                    </Table>
                </TableUI>
            </div>

            <div className='assesment-pagination'>
                <PaginationUI>
                    <span className='summary'>{2} Result</span>
                    <div className='selectWrapper'>
                        <Form.Label>Show: </Form.Label>
                        <Form.Select>
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>25</option>
                            <option>30</option>
                        </Form.Select>
                    </div>
                    <Pagination>
                        <Pagination.Prev>
                            <ArrowLeftSVG />
                        </Pagination.Prev>
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item>{3}</Pagination.Item>
                        <Pagination.Item>{4}</Pagination.Item>
                        <Pagination.Item>{5}</Pagination.Item>
                        <Pagination.Next>
                            <ArrowRightSVG />
                        </Pagination.Next>
                    </Pagination>
                </PaginationUI>
            </div>
        </>
    );
};

export default ClearanceTable;
