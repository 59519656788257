import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover, Button, Dropdown } from 'react-bootstrap';

import ConditionalRender from 'components/ConditionalRender';

import { ReactComponent as XmarkSVG } from 'assets/svg/circle-xmark-red.svg';
import { ReactComponent as EyeSVG } from 'assets/svg/eye-with-eyelashes.svg';
import { ReactComponent as CheckSVG } from 'assets/svg/circle-check-green.svg';
import { ReactComponent as ThreeDotsSVG } from 'assets/svg/three-dots-svgrepo-com.svg';

const samplePhoto = 'https://picsum.photos/100';

const TableData = ({
  setData,
  tableData,
  isAllUsers,
  isNewUsers,
  setUserData,
  setOpenModal,
  setModalType,
  setShowScreen,
  isDeniedUsers,
}) => {
  if (!tableData.data) return;

  const newTableData = tableData.data.map((data) => {
    const { attributes } = data;

    return (
      <tr key={data.id}>
        <td>
          <div className='account'>
            <img src={data?.photo || samplePhoto} alt='account photo' />
            <p>{attributes?.full_name}</p>
          </div>
        </td> <td>
          <p>{attributes?.username || 'N/A'}</p>
        </td>
        <td>
          <p>{attributes?.id_no || 'N/A'}</p>
        </td>
        <td className={`department-cont ${attributes?.department}`}>
          <p>{attributes?.department || 'N/A'}</p>
        </td>
        <td className={`department-cont ${attributes?.role}`}>
          <p>{attributes?.role || 'N/A'}</p>
        </td>

        {isDeniedUsers && (
          <td className={attributes?.account_status ? 'Active' : 'Inactive'}>
            <Dropdown className='denied-dropdown' drop='down'>
              <Dropdown.Toggle variant='success' id='button-select'>
                {attributes?.is_denied ? 'Denied' : 'N/A'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setOpenModal(true);
                    setData(data);
                    setModalType('approve');
                  }}
                >
                  Reapprove
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        )}

        {!isDeniedUsers && (
          <>
            <td className={attributes?.account_status ? 'Active' : 'Inactive'}>
              <p>{attributes?.account_status ? 'Active' : 'Inactive'}</p>
            </td>
            <td>
              <OverlayTrigger
                trigger='focus'
                placement='bottom'
                overlay={
                  <Popover id='popover-positioned-bottom'>
                    <Popover.Body className='d-flex pop-up-cont justify-content-between p-0'>
                      <ConditionalRender
                        condition={isAllUsers}
                        renderIf={
                          <>
                            <Button
                              onClick={() => {
                                setShowScreen(1);
                                setUserData(data);
                              }}
                              className='view-access'
                            >
                              <EyeSVG /> View Access
                            </Button>
                            <Button
                              onClick={() => {
                                setOpenModal(true);
                                setData(data);
                                setModalType('delete');
                              }}
                              className='remove-access'
                            >
                              <XmarkSVG /> Remove Access
                            </Button>
                          </>
                        }
                      />

                      <ConditionalRender
                        condition={isNewUsers}
                        renderIf={
                          <>
                            <Button
                              onClick={() => {
                                setOpenModal(true);
                                setData(data);
                                setModalType('approve');
                              }}
                              className='view-access approve'
                            >
                              <CheckSVG /> Approve
                            </Button>
                            <Button
                              onClick={() => {
                                setOpenModal(true);
                                setData(data);
                                setModalType('deny');
                              }}
                              className='remove-access'
                            >
                              <XmarkSVG /> Deny
                            </Button>
                          </>
                        }
                      />
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button variant='secondary' className='popoverButton bg-transparent p-0 border-0'>
                  <ThreeDotsSVG style={{ width: '1.5em' }} />
                </Button>
              </OverlayTrigger>
            </td>
          </>
        )}
      </tr>
    );
  });

  return newTableData;
};

TableData.defaultProps = {
  tableData: [],
  setShowScreen: () => { },
  setUserData: () => { },
  setModalType: () => { },
  isAllUsers: true,
  isNewUsers: false,
};

TableData.propTypes = {
  tableData: PropTypes.array,
  setShowScreen: PropTypes.func,
  setUserData: PropTypes.func,
  setModalType: PropTypes.func,
  isAllUsers: PropTypes.bool,
  isNewUsers: PropTypes.bool,
};

export default TableData;
