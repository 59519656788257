/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal, Accordion, Table } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import ModalUI from 'components/UI/ModalUI';
import {
  denyRequestItem,
  completeRequestItem,
  selectDepartmentInventory,
} from 'reducers/departmentInventoryReducer';
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main componet
const RequestCompleteModal = ({ showModal, setShowModal, getInventoryRequestList }) => {
  // Redux
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectDepartmentInventory);
  const { completeRequestItemLogs, denyRequestCompleteLogs } = inventoryState;

  const [formData, setFormData] = useState({
    remarks: '',
  });
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleComplete = () => {
    const { id } = showModal.data;

    dispatch(completeRequestItem(id));
  };

  const handleNo = () => {
    const { id } = showModal.data;

    const params = {
      id: id,
      payload: {
        remarks: formData?.remarks,
      },
    };

    dispatch(denyRequestItem(params));
  };

  const closeModal = () => {
    setShowModal((prevState) => {
      return {
        ...prevState,
        completeRequest: false,
      };
    });

    setConfirmDelete(false);
  };

  getCompleteResults(
    setShowModal,
    setFormData,
    setConfirmDelete,
    completeRequestItemLogs,
    getInventoryRequestList,
  );

  getDeniedResults(
    setShowModal,
    setFormData,
    setConfirmDelete,
    denyRequestCompleteLogs,
    getInventoryRequestList,
  );

  return (
    <Modal
      show={showModal?.completeRequest}
      onHide={() => {
        closeModal();
      }}
      aria-labelledby='Request Item Modal'
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>CONFIRMATION</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='remove-request-form'>
            <Row>
              <Col>
                <h3 className='mb-4'>Item Complete?</h3>

                <Form.Group className='buttons'>
                  <Button
                    disabled={completeRequestItemLogs?.isLoading}
                    onClick={handleComplete}
                    className='yes-button'
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setConfirmDelete(true);
                    }}
                    className='no-button'
                  >
                    No
                  </Button>
                </Form.Group>

                <ConditionalRender
                  condition={confirmDelete === true}
                  renderIf={
                    <>
                      <Form.Group className='mb-3'>
                        <Form.Label>Remarks</Form.Label>
                        <FormControl
                          value={formData?.remarks}
                          onChange={handleChange('remarks')}
                          placeholder='Enter here'
                        />
                      </Form.Group>

                      <Form.Group className='buttons'>
                        <Button
                          onClick={handleNo}
                          className='submit-button'
                          disabled={completeRequestItemLogs?.isLoading || formData?.remarks === ''}
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </>
                  }
                />
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const getCompleteResults = (
  setShowModal,
  setFormData,
  setConfirmDelete,
  completeRequestItemLogs,
  getInventoryRequestList,
) => {
  useEffect(() => {
    const { isLoading, isFailed, isSuccess, error } = completeRequestItemLogs;

    if (isFailed) {
      toast.error(`${error[0]?.detail} (${error[0]?.code})`);
      console.log(error);
    }

    if (isSuccess) {
      setShowModal((prevState) => {
        return {
          ...prevState,
          completeRequest: false,
          data: null,
        };
      });

      setFormData({
        remarks: '',
      });

      setConfirmDelete(false);
      getInventoryRequestList();
      toast.success('Successfully completed request item!');
    }
  }, [completeRequestItemLogs]);
};

const getDeniedResults = (
  setShowModal,
  setFormData,
  setConfirmDelete,
  denyRequestCompleteLogs,
  getInventoryRequestList,
) => {
  useEffect(() => {
    const { isLoading, isFailed, isSuccess, error } = denyRequestCompleteLogs;

    if (isFailed) {
      toast.error(`${error[0]?.detail} (${error[0]?.code})`);
      console.log(error);
    }

    if (isSuccess) {
      setShowModal((prevState) => {
        return {
          ...prevState,
          completeRequest: false,
          data: null,
        };
      });

      setFormData({
        remarks: '',
      });

      setConfirmDelete(false);
      getInventoryRequestList();
      toast.success('Successfully denied request item!');
    }
  }, [denyRequestCompleteLogs]);
};

export default RequestCompleteModal;
