/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal } from 'react-bootstrap';

import FormUI from 'components/UI/FormUI';
import { getSingleReturnList, selectReturn } from 'reducers/returnReducer';

// Import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import moment from 'moment';

const ViewOrderModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const returnState = useSelector(selectReturn);
  const { singleData, singleItems } = returnState;

  const closeModal = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        viewItem: false,
      };
    });
  };

  const callAPI = () => {
    const { id } = showModal?.data;

    dispatch(getSingleReturnList(id));
  };

  const renderItems = () => {
    if (!singleData) return '';

    const returnRequests = singleData?.relationships?.return_request_items.data;

    if (!returnRequests) return '';

    return returnRequests.map((data) => {
      const id = data?.id;

      const requestData = singleItems.find(
        (dd) => +dd.id === +id && dd?.type === 'return_request_item',
      );

      const { id: dept_item_id } = requestData?.relationships?.department_inventory?.data[0];

      const itemData = singleItems.find((dd) => +dd.id === +dept_item_id && dd?.type === 'items');

      return (
        <div className='unit-item'>
          <Form.Group className='mb-3 inline-group'>
            <Form.Label>Item Code</Form.Label>
            <FormControl
              readOnly
              type='text'
              className='no-border'
              placeholder='Item Code Here'
              value={itemData?.attributes?.name}
            />
          </Form.Group>

          <Form.Group className='mb-3 inline-group'>
            <Form.Label>Quantity (UOM)</Form.Label>
            <FormControl
              readOnly
              type='text'
              className='no-border'
              placeholder='Quantity Here'
              value={`${requestData?.attributes?.quantity} (${requestData?.attributes?.unit_of_measure})`}
            />
          </Form.Group>

          <Form.Group className='inline-group'>
            <Form.Label>Reason</Form.Label>
            <FormControl
              readOnly
              type='text'
              className='no-border'
              placeholder='Item Code Here'
              value={requestData?.attributes?.remarks}
            />
          </Form.Group>
        </div>
      );
    });
  };

  runWhenViewIsOpen(showModal, callAPI);

  return (
    <Modal
      size='lg'
      show={showModal?.viewItem}
      onHide={() => {
        closeModal();
      }}
      aria-labelledby='Request Item Modal'
      className='view-borrowed-item request-item'
    >
      <Modal.Body>
        <div className='view-borrowed-item-header'>
          <div className='d-flex header'>
            <p>RETURNED ITEM DETAILS</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={() => closeModal()} />
          </div>
        </div>

        <FormUI>
          <Form className='item-information-form'>
            <Row className='mb-5'>
              <Col>
                <h3 className='mb-4'>RETURNEE INFORMATION</h3>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Returned By</Form.Label>
                  <FormControl
                    className='no-border'
                    value={singleData?.attributes?.created_by}
                    type='text'
                    readOnly
                    placeholder='Borrowed By Here'
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Returned At</Form.Label>
                  <FormControl
                    readOnly
                    type='text'
                    className='no-border'
                    placeholder='Date Time Borrowed Here'
                    value={moment(singleData?.attributes?.created_at).format('MMM-DD-YYYY hh:mm A')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col>
                <h3 className='mb-4'>ITEM INFORMATION</h3>

                {singleData && renderItems()}
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    onClick={() => {
                      closeModal();
                    }}
                    className='clear-button'
                    variant='secondary'
                  >
                    Close
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const runWhenViewIsOpen = (showModal, callAPI) => {
  useEffect(() => {
    if (showModal?.viewItem) {
      callAPI();
    }
  }, [showModal]);
};

export default ViewOrderModal;
