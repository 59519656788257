/* eslint-disable react/jsx-indent-props */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup, Table } from 'react-bootstrap';
import TableUI from 'components/UI/TableUI';

// import assets below
import './style.scss';
import FormUI from 'components/UI/FormUI';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// main component
const Details = ({ showModal, setShowModal, data }) => {
    return (
        <Modal
            size='lg'
            show={showModal.showDetails}
            onHide={() =>
                setShowModal((prevState) => {
                    return {
                        ...prevState,
                        showDetails: false,
                    };
                })
            }
            aria-labelledby='Add Diagnosis'
            className='add-diagnosis-modal'
        >
            <Modal.Body>
                <div className='add-diagnosis-request'>
                    <div className='d-flex header'>
                        <p>Medications taken by the Patient{showModal?.profileId}</p>

                        <CircleXMark
                            style={{ width: '2em', cursor: 'pointer' }}
                            onClick={() =>
                                setShowModal((prevState) => {
                                    return { ...prevState, showDetails: false };
                                })
                            }
                        />
                    </div>
                </div>
                <FormUI>
                    <Form className='procedure-form'>
                        {data && data.map((item) => {
                            return (
                                <><Row>
                                    <Col>
                                        <div className='patientProfile__content'>
                                            <Row>
                                                <Col lg='3'>
                                                    <div className='wrapper'>
                                                        <span>Medication</span>
                                                        <span>:</span>
                                                    </div>
                                                </Col>
                                                <Col lg='auto'>
                                                    <span className='value'><b>{item.medication}</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='3'>
                                                    <div className='wrapper'>
                                                        <span>Doze </span>
                                                        <span>:</span>
                                                    </div>
                                                </Col>
                                                <Col lg='auto'>
                                                    <span className='value'><b>{item.doze}</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='3'>
                                                    <div className='wrapper'>
                                                        <span>Route of Administration </span>
                                                        <span>:</span>
                                                    </div>
                                                </Col>
                                                <Col lg='auto'>
                                                    <span className='value'><b>{item.route_of_administration}</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='3'>
                                                    <div className='wrapper'>
                                                        <span>Times of Administration</span>
                                                        <span>:</span>
                                                    </div>
                                                </Col>
                                                <Col lg='auto'>
                                                    <span className='value'><b>{item.times_of_administration}</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='3'>
                                                    <div className='wrapper'>
                                                        <span>Remarks</span>
                                                        <span>:</span>
                                                    </div>
                                                </Col>
                                                <Col lg='9'>
                                                    <span className='value'><b>{item.notes}</b></span>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Col>
                                </Row><hr></hr></>
                            );
                        })}
                    </Form>
                </FormUI>
            </Modal.Body>
        </Modal>
    );
};

export default Details;
