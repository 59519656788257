import { Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// import components below
import Layout from 'components/Layout';
import OutpatientHeader from './components/PageHeader';
import OutpatientTabs from '../OutpatientDepartment/components/Tabs';
import { selectAppointmentActiveTab } from 'reducers/appointmentReducer';

// import assets below
import './style.scss';
// main component
const Outpatient = () => {
  const activeTab = useSelector(selectAppointmentActiveTab);

  return (
    <Layout pageTitle='OutpatientDepartment'>
      <OutpatientHeader active={activeTab} />
      
      <Container fluid>
        <Row>
          <OutpatientTabs activeTab={activeTab} />
        </Row>
      </Container>
    </Layout>
  );
};

export default Outpatient;
