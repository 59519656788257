/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Row, Col, Button, Modal, Form, Table, FormControl } from 'react-bootstrap';

// import components
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';

// import assets below
import './style.scss';
import { ReactComponent as CirclePlus } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as CircleMinus } from 'assets/svg/circle-minus.svg';
import { ReactComponent as FileUploadSVG } from 'assets/svg/file-upload.svg';

// main component
const RequestToRepair = ({ showProfile, setShowProfile }) => {
  return (
    <Modal
      size='lg'
      show={showProfile.showRequestRepair}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showRequestRepair: false,
          };
        })
      }
      aria-labelledby='ITEM FOR REQUEST TO REPAIR'
      className='request-for-repair-modal'
    >
      <Modal.Body>
        <div className='request-for-repair-request'>
          <div className='d-flex header'>
            <p>ITEM FOR REQUEST TO REPAIR</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, showRequestRepair: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='adjustment-form'>
            <Row>
              <Col sm={3}>
                <Form.Group className='mb-2'>
                  <Form.Label>Control Number</Form.Label>
                  <FormControl type='text' className='bg-white' placeholder='Search Here' />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className='mb-2'>
                  <Form.Label>Equipment Name</Form.Label>
                  <FormControl type='text' className='bg-grey' placeholder='Equipment Name Here' />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3}></Col>
              <Col>
                <Form.Group className='mb-2'>
                  <Form.Label>Equipment Description</Form.Label>
                  <FormControl
                    as='textarea'
                    rows='5'
                    className='bg-grey'
                    placeholder='Equipment Name Here'
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3}></Col>
              <Col sm={2}>
                <Form.Group className='mb-2'>
                  <Form.Label>Item Count</Form.Label>
                  <FormControl type='text' className='bg-white' />
                </Form.Group>
              </Col>
              <Col sm={2}>
                <div className='count-control'>
                  <CirclePlus style={{ width: '1.3rem' }} />
                  <CircleMinus style={{ width: '1.3rem' }} />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='upload-image mb-4 mt-4'>
                  <p>
                    Upload Image <span>PNG and JPG files are allowed</span>
                  </p>

                  <div className='cont'>
                    <FileUploadSVG />

                    <Form.Label htmlFor='upload-image'>
                      Drag and drop or <b>browse</b> your file
                    </Form.Label>

                    <FormControl id='upload-image' type='file' />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-2'>
                  <Form.Label>Delivered By</Form.Label>
                  <FormControl type='text' className='bg-white' />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-2'>
                  <Form.Label>Received By</Form.Label>
                  <FormControl type='text' className='bg-white' />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-2'>
                  <Form.Label>Deployed To</Form.Label>
                  <FormControl type='text' className='bg-white' />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mt-4'>
              <Col>
                <div className='buttons-cont'>
                  <Button className='save'>SAVE</Button>
                  <Button
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return { ...prevState, showRequestRepair: false };
                      });
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

RequestToRepair.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

RequestToRepair.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default RequestToRepair;
