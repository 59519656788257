/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/ReactTable';
import moment from 'moment';
import axios from 'axios';

// import components below
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const RequestLaboratoryTable = ({ state, setState, setShowModal }) => {
  const data = useSelector(selectPatientData);
  useEffect(() => {
    axios
      .get('/requisition/laboratory')
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Date / Time', accessor: 'date_time' }, // accessor is the "key" in the data
      { Header: 'Patient ID', accessor: 'id' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Age/Sex', accessor: 'age_sex' },
      { Header: 'Attending Physician', accessor: 'attending_physician' },
      { Header: 'Lab Type', accessor: 'lab_type' },
      { Header: 'Internal/External?', accessor: 'internal_external' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        date_time: moment(dd?.createdAt).format('MMMM-DD-YYYY hh:mm A'),
        id: dd?.id,
        name: dd?.patient?.full_name,
        age_sex: `${dd?.patient?.age}/${dd?.patient?.sex}`,
        attending_physician: dd?.user?.full_name,
        lab_type: dd?.lab_type,
        internal_external: dd?.lab_test,
        status: (
          <div>
            {dd?.status === 'PENDING' ? (<span className='bg-warning'>Pending</span>) : dd?.status === 'COMPLETED' ? (<span className='bg-success'>Completed</span>) : (<span className='bg-success'>Completed</span>)}
          </div>
        ),
      })),
    [state],
  ); 
  return (
    <div className='request-laboratory-table'>
      <Table data={tableData} columns={tableColumns} pagination={['BOTTOM']} />
    </div>
  );
};

export default RequestLaboratoryTable;
