/* eslint-disable */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';

// import components below
import { labTestStatus } from 'helpers';
import Table from 'components/ReactTable';
import { checkStringPermission } from 'helpers/filteredPermissions';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const LabTestTable = ({ state, setState, setShowProfile }) => {
  const handelDelete = (id) => {
    axios
      .delete(`/lab_test/${id}`)
      .then((response) => {
        toast.success('Successfully deleted!');

        axios
          .get('/lab_test')
          .then((response) => {
            setState(response.data.data);
          });
      })
      .catch((error) => {
        console.log(error);
        toast.error('ERROR FETCHING LAB TESTS');
      });
  };

  useEffect(() => {
    axios
      .get('/lab_test')
      .then((response) => {
        setState(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error('ERROR FETCHING LAB TESTS');
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Test Name', accessor: 'name' },
      { Header: 'Price', accessor: 'price' },
      { Header: 'Internal/External?', accessor: 'internal_external' },
      { Header: 'Is Active?', accessor: 'is_active' },
    ],
    []
  )

  const tableData = React.useMemo(
    () => state?.map(dd => ({
      id: dd?.id,
      name: dd?.name,
      price: dd?.price.toFixed(2),
      internal_external: dd?.internal_external,
      is_active: labTestStatus(dd?.isActive),
    })),
    [state]
  )

  const handleTableAction = (eventKey, id) => {
    const row = state.find(dd => dd.id === id);
    const action = {
      profile: () => { },
      view: () => { },
      edit: () => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showScreen: 3,
            editValues: row,
            profileId: row?.id,
            id: row?.id,
          };
        });
      },
      delete: () => {
        handelDelete(row?.id);
      }
    }

    action[eventKey]()
  }

  return (
    <div className='laboratory-create-lab-table'>
      <h2>Laboratory Test List</h2>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasEdit: true, hasDelete: checkStringPermission('Can Delete Procedure') }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

LabTestTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowProfile: () => {},
};

LabTestTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default LabTestTable;
