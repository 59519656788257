/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

import { leaveStatus } from 'helpers';
import Table from 'components/ReactTable';
import ConditionalRender from 'components/ConditionalRender';

import './index.scss';
import { ReactComponent as UploadSVG } from 'assets/svg/upload.svg';

const state = [
  {
    id: '001',
    employee_name: 'Juan Dela Cruz',
    leave_start: 'July 4, 2022',
    leave_end: 'July 7, 2022',
    leave_type: 'Details Here',
    status: 'Approved',
  },
  {
    id: '002',
    employee_name: 'Juan Dela Cruz',
    leave_start: 'July 4, 2022',
    leave_end: 'July 7, 2022',
    leave_type: 'Details Here',
    status: 'Denied',
  },
  {
    id: '003',
    employee_name: 'Juan Dela Cruz',
    leave_start: 'July 4, 2022',
    leave_end: 'July 7, 2022',
    leave_type: 'Details Here',
    status: 'Pending',
  },
];

const index = ({ setShowScreen, setRequestData }) => {
  const tableColumns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Employee Name', accessor: 'employee_name' },
      { Header: 'Leave Start', accessor: 'leave_start' },
      { Header: 'Leave End', accessor: 'leave_end' },
      { Header: 'Leave Type', accessor: 'leave_type' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        leave_start: dd?.leave_start,
        employee_name: dd?.employee_name,
        leave_end: dd?.leave_end,
        leave_type: dd?.leave_type,
        status: leaveStatus(dd?.status),
      })),
    [state],
  );

  return (
    <div className='leave-request'>
      <div className='header'>
        <h2>Display Request</h2>

        <div className='functions'>
          <Form.Group className='inline-group'>
            <Form.Label>From:</Form.Label>

            <FormControl type='date' className='seven' placeholder='Enter Here' />
          </Form.Group>

          <Form.Group className='inline-group'>
            <Form.Label>To:</Form.Label>

            <FormControl type='date' className='seven' placeholder='Enter Here' />
          </Form.Group>

          <Button className='filter'>
            {/* <UploadSVG /> */}
            Apply Filter
          </Button>
        </div>
      </div>

      <dib className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <span
                className='badge red'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowScreen(2);
                  setRequestData(data);
                }}
              >
                Leave Request
              </span>
            );
          }}
        />
      </dib>
    </div>
  );
};

export default index;
