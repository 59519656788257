/* eslint-disable */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'components/ReactTable';
import moment from 'moment';
import axios from 'axios';

// import components below
import { selectPatientData, getPatients } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const PatientTable = ({ state, setState, setShowProfile, apiKey }) => {
  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient ID', accessor: 'id' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Appointment Date', accessor: 'date' },
      { Header: 'Department', accessor: 'department' },
      { Header: 'Doctor/Resource', accessor: 'doctor_resource' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.patient?.id,
        name: dd?.patient?.full_name,
        date: moment(dd?.createdAt).format('MMMM DD, YYYY'),
        department: dd?.department,
        doctor_resource: dd?.name_of_doctor,
        status: (
          <span className={`${dd?.appointment_status} badge`}>
            {dd?.appointment_status === 'NEW' ? 'New Request' : 'Accepted'}
          </span>
        ),
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      profile: () => {
        setShowProfile({ isShowProfile: true, profileId: id, });
      },
      view: () => { },
      edit: () => { },
      delete: () => { },
    };

    action[eventKey]();
  };

  return (
    <div className='outpatient-daily-worklist-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasProfile: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default PatientTable;
