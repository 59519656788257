/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Packages
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, Table, FormControl } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import ConditionalRender from 'components/ConditionalRender';
import {
  resetLogState,
  selectDepartmentInventory,
  itemRequestForRepairUpdate,
  getSingleRepairMaintenance,
} from 'reducers/departmentInventoryReducer';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as FileUploadSVG } from 'assets/svg/file-upload.svg';

const initialState = {
  id: '',
  status: '',
  item_count: '',
  image: '',
  requested_by: '',
  description: '',
};

// main component
const EditRequestToRepair = ({
  showModal,
  departmentID,
  setShowModal,
  refreshData,
  setRefreshData,
  getInventoryMaintenanceRepair,
}) => {
  const dispatch = useDispatch();
  const itemsInventory = useSelector(selectDepartmentInventory);
  const { singleRepairData, singleRepairLogs } = itemsInventory;
  const { nonMedicineItemData, nonMedicineItemLogs, updateRepairLogs } = itemsInventory;

  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleChange = (key) => (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  };

  const handleSave = () => {
    const newFormData = new FormData();

    newFormData.append('item_id', formData?.id);

    if (
      formData?.image instanceof String ||
      typeof formData?.image === 'string' ||
      formData?.image === null
    ) {
      //
    } else {
      newFormData.append('image', formData?.image);
    }

    // Remove for word from status
    const statusArr = formData?.status.split(' ');

    newFormData.append('status', statusArr[1]);
    newFormData.append('item_count', formData?.item_count);
    newFormData.append('requested_by', formData?.requested_by);
    newFormData.append('description', formData?.description);
    newFormData.append('_method', 'PUT');

    const payload = {
      id: formData?.updateID,
      params: newFormData,
    };

    dispatch(itemRequestForRepairUpdate(payload));
  };

  useEffect(() => {
    const { isSuccess } = nonMedicineItemLogs;

    if (isSuccess) {
      setItems(nonMedicineItemData);
    }
  }, [nonMedicineItemLogs]);

  useEffect(() => {
    if (formData?.id) {
      const data = items.filter((data) => parseInt(data?.id) === parseInt(formData?.id));

      setSelectedItem(data[0]);
    }
  }, [formData]);

  useEffect(() => {
    const { isSuccess, isFailed } = updateRepairLogs;

    if (isSuccess) {
      setSelectedItem(null);
      setFormData(initialState);
      getInventoryMaintenanceRepair();
      toast.success('Successfully updated requested item to repair.');

      setShowModal((prevState) => {
        return {
          ...prevState,
          editRequestRepair: false,
        };
      });

      dispatch(resetLogState());
    }

    if (isFailed) {
      toast.error('Something went wrong.');
    }
  }, [updateRepairLogs]);

  useEffect(() => {
    if (showModal?.rowData) {
      dispatch(getSingleRepairMaintenance(showModal?.rowData?.id));
    }
  }, [showModal]);

  useEffect(() => {
    const { isSuccess, isFailed, isLoading } = singleRepairLogs;

    if (isSuccess) {
      setFormData({
        updateID: singleRepairData?.id,
        id: singleRepairData?.attributes?.item_id,
        status: singleRepairData?.attributes?.status,
        image: singleRepairData?.attributes?.image_path,
        item_count: singleRepairData?.attributes?.item_count,
        description: singleRepairData?.attributes?.description,
        requested_by: singleRepairData?.attributes?.requested_by,
      });
    }
  }, [singleRepairLogs]);

  return (
    <Modal
      size='lg'
      show={showModal.editRequestRepair}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            editRequestRepair: false,
          };
        })
      }
      aria-labelledby='EDIT ITEM REQUEST'
      className='request-for-repair-modal'
    >
      <Modal.Body>
        <div className='request-for-repair-request'>
          <div className='d-flex header'>
            <p>EDIT ITEM REQUEST</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowModal((prevState) => {
                  return { ...prevState, editRequestRepair: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='adjustment-form'>
            <Row>
              <Col sm={6}>
                <Form.Group className='mb-2'>
                  <Form.Label>Select Item</Form.Label>
                  <Form.Select disabled readOnly value={formData?.id} onChange={handleChange('id')}>
                    <option value='' hidden>
                      Select Item
                    </option>

                    {items &&
                      items.length > 0 &&
                      items.map((data) => {
                        return (
                          <option key={data?.id} value={data?.id}>
                            {data?.attributes?.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-2'>
                  <Form.Label>Equipment Name</Form.Label>
                  <FormControl
                    readOnly
                    type='text'
                    className='bg-white'
                    placeholder='Equipment Name Here'
                    value={selectedItem?.attributes?.name || ''}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-2'>
                  <Form.Label>Description</Form.Label>
                  <FormControl
                    rows='5'
                    as='textarea'
                    className='bg-white'
                    value={formData?.description}
                    onChange={handleChange('description')}
                    placeholder='Enter Detailed Description Here'
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Form.Group className='mb-2'>
                  <Form.Label>Item Status</Form.Label>
                  <Form.Select value={formData?.status} onChange={handleChange('status')}>
                    <option value='' hidden>
                      Select Status
                    </option>
                    <option value='for repair'>For Repair</option>
                    <option value='for condemnation'>For Condemnation</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Form.Group className='mb-2'>
                  <Form.Label>Item Count</Form.Label>
                  <FormControl
                    type='number'
                    className='bg-white'
                    value={formData?.item_count}
                    onChange={handleChange('item_count')}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='upload-image mb-4 mt-4'>
                  <p>
                    Upload Image <span>PNG and JPG files are allowed</span>
                  </p>

                  <div className='cont'>
                    <ConditionalRender
                      condition={formData?.image === ''}
                      renderIf={<FileUploadSVG />}
                    />
                    <ConditionalRender
                      condition={formData?.image !== ''}
                      renderIf={<span>{formData?.image?.name}</span>}
                    />

                    <Form.Label htmlFor='upload-image'>
                      {/* Drag and drop or <b>browse</b> your file */}
                      Click to <b>browse</b> your file
                    </Form.Label>

                    <FormControl
                      type='file'
                      id='upload-image'
                      accept='image/png, image/jpeg'
                      onChange={(e) => {
                        // eslint-disable-next-line prefer-const
                        let docuArr = [];

                        Array.from(e.target.files).forEach((file) => {
                          docuArr.push(file);
                        });

                        if (e?.target?.files[0]) {
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              image: e.target.files[0],
                            };
                          });
                        }
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Form.Group className='mb-2'>
                  <Form.Label>Requested By</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    value={formData?.requested_by}
                    onChange={handleChange('requested_by')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mt-4'>
              <Col>
                <div className='buttons-cont'>
                  <Button onClick={handleSave} className='save'>
                    SAVE
                  </Button>
                  <Button
                    onClick={() => {
                      setShowModal((prevState) => {
                        return { ...prevState, editRequestRepair: false };
                      });
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

EditRequestToRepair.defaultProps = {
  showModal: {},
  setShowModal: () => {},
};

EditRequestToRepair.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default EditRequestToRepair;
