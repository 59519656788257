/* eslint-disable react/jsx-indent-props */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Modal, Button, Form } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './AbscondedModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// main componet
const AbscondedModal = (props) => {
    const { status, onToggle, id, onConfirm, setShowProfile } = props;
    const [password, setPassword] = useState('');
    const [button, setButton] = useState(true);
    const navigate = useNavigate();

    const passwordCheck = () => {
        axios.post(`/user/auth/check`, { password: password })
            .then((response) => {

                axios.post(`/patient/absconded/${id}`)
                    .then((response) => {
                        setTimeout(() => {
                            setShowProfile(false);
                            setShowProfile({ isShowProfile: false, profileId: null });
                        }, 2000);
                        toast.success('Successfully marked Patient as ABSCONDED!');

                        onToggle(!status);
                        onConfirm((prevState) => !prevState);
                    });
            })
            .catch((error) => {
                toast.error('Invalid Password');
            });
    };

    useEffect(() => {
        if (password.length > 3) {
            setButton(false);
        } else {
            setButton(true);
        }
    }, [password]);

    return (
        <div className='physicalDischargeModal'>
            <ModalUI>
                <Modal.Dialog>
                    <Modal.Header>
                        <h4>CONFIRM TAG AS ABSCONDED</h4>
                        <Button onClick={() => onToggle(!status)} variant='transparent'>
                            <CloseSVG />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='physicalDischargeModal__content'>
                            <div className='physicalDischargeModal__header'>
                                <h1>Warning!</h1>
                            </div>
                            <div className='physicalDischargeModal__body'>
                                <h6>You are about to officially mark patient as absconded from the system.</h6>
                                <h6>
                                    Once done, the room will be tagged as <b> For Cleaning </b> and the
                                    patient will be removed for your unit&#39;s Patient List.
                                </h6>
                                <Form.Group className='confirmation'>
                                    <Form.Label>Please enter your Password to proceed.</Form.Label>
                                    <Form.Control type='password' onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='light-red'
                            disabled={button}
                            onClick={() => {
                                passwordCheck();
                            }}
                        >
                            Confirm
                        </Button>
                        <Button onClick={() => onToggle(!status)} variant='cancel'>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </ModalUI>
        </div>
    );
};

export default AbscondedModal;
