/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table, { PAGE_SIZE } from 'components/ReactTable';
import FormUI from 'components/UI/FormUI';
import { toast } from 'react-toastify';
// import EmployeeProfile from './EmployeeProfile';
import { Form, FormControl, Row, Col, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { selectEmployees } from 'reducers/employeesReducer';
import {
  selectEmployeeSchedules,
  getAllEmployeeSchedules,
  deleteEmployeeScheduleById,
  createEmployeeSchedule,
  updateEmployeeSchedule,
} from 'reducers/employeeScheduleReducer';
import debounce from 'lodash.debounce';

// Import assets below
import './index.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import avatar from 'assets/images/avatar.jpg';

// React Select Props
const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.37em',
    // color: '#404040',
    // borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

// Queries
const params = {
  current_page: 1,
  per_page: PAGE_SIZE[0], // [10, 20, 30, 100]
};

const AddEditShifting = ({ type, title, empData, setEmpData, setActiveScreen, setActiveTab }) => {
  const dispatch = useDispatch();

  const employees = useSelector(selectEmployees);
  const schedules = useSelector(selectEmployeeSchedules);

  const [query, setQuery] = useState({});
  const [modalType, setModalType] = useState(type);
  const [validated, setValidated] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [isAddSchedule, setIsAddSchedule] = useState(false);
  const [empScheduleData, setEmpScheduleData] = useState([]);
  const [addEditSchedule, setAddEditSchedule] = useState(null);
  const [filteredData, setFilteredData] = useState(employees.data);

  const debouncedChangeHandler = React.useCallback(
    debounce((value, key) => {
      setQuery((prevState) => ({ ...prevState, [key]: value }));
    }, 300),
    [],
  );

  const displayEmployeeSchedules = () => {
    setEmpData(null);
    setEmpScheduleData([]);

    // setEmployeeId(query?.id);

    if (query?.id) {
      if (employees?.data?.length > 0) {
        const employeeData = employees.data.find((emp) => emp.id === query.id);

        if (employeeData) setEmpData(employeeData);
      }

      setAddEditSchedule({ employeeId: query.id });

      if (schedules?.data?.length > 0) {
        const scheduleData = schedules.data.find((sched) => sched.id === query.id);
        if (scheduleData) setEmpScheduleData(scheduleData?.employee_schedules || []);
      }
    }
  };

  // -----------------------------------------------------------------
  // SCHEDULE ADD & EDIT

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Date', accessor: 'date' }, // accessor is the "key" in the data
      { Header: 'Start of Shift Time', accessor: 'start_time' },
      { Header: 'End of Shift Time', accessor: 'end_time' },
      { Header: 'Deployment', accessor: 'deployment' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      empScheduleData?.map((dd) => ({
        id: dd?.id,
        date: dd?.assigned_date,
        start_time: dd?.time_in,
        end_time: dd?.time_out,
        deployment: dd?.deployment,
      })),
    [empScheduleData],
  );

  const handleTableAction = (eventKey, id) => {
    const row = empScheduleData?.find((dd) => dd.id === id);
    const action = {
      profile: () => {},
      view: () => {},
      edit: () => {
        setModalType('edit');
        setAddEditSchedule(row);
        setIsAddSchedule(true);
      },
      delete: () => {
        dispatch(deleteEmployeeScheduleById(id)).then((res) => {
          if (res.payload.status.toLowerCase() === 'success') {
            setEmpScheduleData((state) => state.filter((dd) => dd.id !== res.payload.id));
          }
        });
      },
    };

    action[eventKey]();
  };

  // -----------------------------------------------------------------

  const EMPLOYEES_OPTIONS = React.useMemo(
    () =>
      filteredData.map((employee) => ({
        ...employee,
        value: employee.id,
        label: `${employee?.attributes?.last_name}, ${employee?.attributes?.first_name} ${employee?.attributes?.middle_name}`,
      })),
    [filteredData],
  );

  // -----------------------------------------------------------------
  // ADD SCHEDULE

  const onChangeSchedule = (value, key) => {
    setAddEditSchedule((prevState) => ({
      ...(prevState || {}),
      [key]: value,
    }));
  };

  const saveSchedule = () => {
    let payload = {
      assigned_date: addEditSchedule.assigned_date,
      deployment: addEditSchedule.deployment,
      time_in: addEditSchedule.time_in,
      time_out: addEditSchedule.time_out,
      employeeId: addEditSchedule.employeeId,
    };

    const addUpdateSchedule =
      modalType === 'add'
        ? createEmployeeSchedule
        : modalType === 'edit'
        ? updateEmployeeSchedule
        : null;
    if (!addUpdateSchedule) return;

    if (modalType === 'edit') {
      payload = {
        ...payload,
        id: addEditSchedule.id,
      };
    }

    dispatch(addUpdateSchedule(payload))
      .unwrap()
      .then((result) => {
        // handle result here
        toast.success(result.message);
        setActiveScreen(0);
        setEmpData(null);
        setEmpScheduleData([]);
        setActiveTab('Shifting');
      })
      .catch((error) => {
        // handle error here
        toast.error(error.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) saveSchedule();
    setValidated(true);
  };

  useEffect(() => {
    dispatch(getAllEmployeeSchedules(params));
  }, []);

  useEffect(() => {
    if (query?.id || query?.department) {
      let _filteredData = employees.data;

      if (query?.department) {
        _filteredData = employees.data.filter((employee) =>
          employee?.employee_detail?.department
            .toLowerCase()
            .includes(query.department.toLowerCase()),
        );
      }

      if (query?.id) {
        _filteredData = _filteredData.filter((employee) => employee.id === query?.id);
      }

      setFilteredData(_filteredData);
    } else setFilteredData(employees.data);
  }, [employees.data, query]);

  useEffect(() => {
    console.log('filteredData', filteredData);
  }, [filteredData]);

  return (
    <>
      <div className='shifting-add'>
        <FormUI>
          <Form className='add-shift' noValidate validated={validated} onSubmit={handleSubmit}>
            <h3>
              {title}
              <span
                onClick={() => {
                  setEmpData(null);
                  setActiveScreen(0);
                  setEmpScheduleData([]);
                  setActiveTab('Shifting');
                }}
              >
                {'< Go Back to Shifting List'}
              </span>
            </h3>

            {type === 'add' && (
              <>
                <Row className='mt-3 mb-4 employee-details'>
                  <Col sm={4}>
                    <Form.Group className='search-wrapper'>
                      <Form.Label>Search Employee</Form.Label>
                      <Select
                        isLoading={employees.loading}
                        isClearable={true}
                        isSearchable={true}
                        options={EMPLOYEES_OPTIONS}
                        onChange={(e) => debouncedChangeHandler(e?.value, 'id')}
                        placeholder='Search'
                        styles={selectStyles}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col sm={3}>
                    <Form.Group>
                      <Form.Label>Position</Form.Label>
                      <Form.Select>
                        <option hidden value=''>
                          -Select-
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                  <Col sm={3}>
                    <Form.Group>
                      <Form.Label>Department</Form.Label>
                      <FormControl
                        type='text'
                        className='bg-white'
                        placeholder='Search department'
                        style={{ maxWidth: '100%' }}
                        onChange={(e) => debouncedChangeHandler(e.target.value, 'department')}
                      />
                      {/* <Form.Select>
                        <option hidden value=''>
                          -Select-
                        </option>
                      </Form.Select> */}
                    </Form.Group>
                  </Col>
                  <Col className='search-btn'>
                    <Button variant='primary' onClick={displayEmployeeSchedules}>
                      SELECT
                    </Button>
                  </Col>
                </Row>
                <hr />
              </>
            )}

            <Row className='d-flex mt-4 mb-4 employee-details employee-information shifting-information'>
              <Col className='d-flex justify-content-center'>
                <img
                  src={empData?.attributes?.profile_picture || avatar}
                  style={{ width: '150px', height: '150px' }}
                />
              </Col>
              <Col className='d-flex align-items-center'>
                <div className='p-header'>
                  <p>{empData?.attributes?.id_no || '---'}</p>
                  <span>Employee ID</span>
                </div>
              </Col>
              <Col sm={3} className='d-flex align-items-center'>
                <div className='p-header'>
                  {empData?.attributes?.first_name ||
                  empData?.attributes?.middle_name ||
                  empData?.attributes?.last_name ? (
                    <p>
                      {[
                        empData?.attributes?.first_name,
                        empData?.attributes?.middle_name,
                        empData?.attributes?.last_name,
                      ].join(' ')}
                    </p>
                  ) : (
                    <p>{'---'}</p>
                  )}
                  <span>Name</span>
                </div>
              </Col>
              <Col sm={3} className='d-flex align-items-center'>
                <div className='p-header'>
                  <p>{empData?.attributes?.role || '---'}</p>
                  <span>Position</span>
                </div>
              </Col>
              <Col className='d-flex align-items-center'>
                <div className='p-header'>
                  <p>{empData?.attributes?.department || '---'}</p>
                  <span>Department</span>
                </div>
              </Col>
            </Row>

            <div className='employee-details employee-information'>
              <div className='shifting-tab pt-3'>
                <Button
                  className='add-schedule-btn mb-3'
                  disabled={!empData}
                  onClick={() => {
                    setModalType('add');
                    setIsAddSchedule(true);
                  }}
                >
                  Add Schedule
                </Button>
                <p className='shifting-schedule'>Shifting Schedule</p>
                <Table
                  data={tableData}
                  columns={tableColumns}
                  actions={{ hasEdit: true, hasDelete: true }}
                  onAction={handleTableAction}
                />
              </div>
            </div>
          </Form>
        </FormUI>
      </div>

      <Modal
        size='lg'
        show={isAddSchedule}
        // onHide={() => setIsAddSchedule(false)}
        aria-labelledby='Add Schedule Shifting Modal'
        className='add-schedule-shifting-modal'
      >
        <Modal.Body>
          <div className='add-new-item-request'>
            <div className='d-flex header'>
              <p>ADD SCHEDULE SHIFTING TO STORE</p>

              <CircleXMark
                style={{ width: '2em', cursor: 'pointer' }}
                onClick={() => setIsAddSchedule(false)}
              />
            </div>
          </div>

          <FormUI>
            <Form className='add-item-form'>
              <Row className='mb-4'>
                <Col sm={6}>
                  <Form.Group className='side inline-label'>
                    <Form.Label>
                      Assigned Date <span className='text-red'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type='date'
                      placeholder='Enter Here'
                      value={addEditSchedule?.assigned_date || ''}
                      onChange={(e) => onChangeSchedule(e.target.value, 'assigned_date')}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className='side inline-label'>
                    <Form.Label>
                      Assigned Deployment <span className='text-red'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Enter Here'
                      value={addEditSchedule?.deployment || ''}
                      onChange={(e) =>
                        !!e.target.value.match('^[a-zA-Z]*$') &&
                        onChangeSchedule(e.target.value, 'deployment')
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mb-4'>
                <Col sm={6}>
                  <Form.Group className='inline-label'>
                    <Form.Label>
                      Assigned Time for Time In <span className='text-red'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type='time'
                      placeholder='Enter Here'
                      value={addEditSchedule?.time_in || ''}
                      onChange={(e) => onChangeSchedule(e.target.value, 'time_in')}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className='inline-label'>
                    <Form.Label>
                      Assigned Time for Time Out <span className='text-red'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type='time'
                      placeholder='Enter Here'
                      value={addEditSchedule?.time_out || ''}
                      onChange={(e) => onChangeSchedule(e.target.value, 'time_out')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className='mt-4 buttons-cont'>
                    <Button className='add-button' variant='primary' type='submit'>
                      SAVE CHANGES
                    </Button>
                    <Button className='cancel-button' onClick={() => setIsAddSchedule(false)}>
                      CANCEL
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </FormUI>
        </Modal.Body>
      </Modal>
    </>
  );
};

AddEditShifting.defaultProps = {
  setActiveTab: () => {},
};

AddEditShifting.propTypes = {
  setActiveTab: PropTypes.func,
};

export default AddEditShifting;
