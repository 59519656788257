/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Modal, Button, Form, FormControl, Row, Col } from 'react-bootstrap';

// import assets below
import './ConfirmModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const ConfirmModal = ({ openModal, closeModal, type, confirm, fields, title = 'CONFIRMATION' }) => {
  const [modalData, setModalData] = useState({});

  const closeTheModal = () => {
    if (type) {
      closeModal(type);
    }
  };

  const confirmModal = () => {
    confirm(modalData, type);
  };

  const handleChange = (type, e) => {
    setModalData((prev) => {
      return {
        ...prev,
        [type]: e.target.value,
      };
    });
  };

  useEffect(() => {
    if (fields && fields.length > 0) {
      fields.forEach((data) => {
        setModalData((prev) => {
          return {
            ...prev,
            [data?.label]: '',
          };
        });
      });
    }
  }, [fields]);

  useEffect(() => {
    // console.log('modalData', modalData);
  }, [modalData]);

  return (
    <Modal
      className='confirmation-component-modal'
      show={openModal}
      onHide={() => {
        closeTheModal();
      }}
      centered
    >
      <Modal.Header>
        <h6>{title}</h6>
        <Button
          onClick={() => {
            closeTheModal();
          }}
        >
          <CloseSVG />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row className='mb-3'>
          <Col>
            <div className='mb-4 fields'>
              {fields &&
                fields.length > 0 &&
                fields.map(({ type, label }, idx) => {
                  return (
                    <Form.Group key={idx} className='mb-3'>
                      <Form.Label>{label}</Form.Label>
                      <FormControl
                        required
                        type={type}
                        className='bg-white'
                        placeholder='Enter Here'
                        value={modalData[label]}
                        onChange={(e) => handleChange(label, e)}
                      />
                    </Form.Group>
                  );
                })}
            </div>

            <div className='buttons'>
              <Button
                onClick={() => {
                  confirmModal();
                }}
                className='primary'
              >
                Confirm
              </Button>
              <Button
                onClick={() => {
                  closeTheModal();
                }}
                className='cancel'
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  openModal: false,
  closeModal: () => {},
};

ConfirmModal.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default ConfirmModal;
