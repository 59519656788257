/* eslint-disable camelcase */
// import core & vendor packages below
import React from 'react';
import { useState } from 'react';
import { Modal, Row, Button, Form, Col } from 'react-bootstrap';

// import assets below
import './index.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

import { integerCharFieldRules } from 'helpers/fieldValidations';

// main component
const { inValidateInput, showValidationMsg } = integerCharFieldRules;

function DeductionModal({ showModal, setShowModal, createDeductionHandler }) {
  const [data, setData] = useState({ details: '', amount: '' });
  const [invalidData, setInvalidData] = useState(false);
  const handleShow = () =>
    setShowModal((prev) => {
      return { ...prev, deduction: false };
    });
  const saveHandler = () => {
    if (inValidateInput(data.amount)) {
      setInvalidData(true);
      return;
    } 

    createDeductionHandler(data);
    setData({ details: '', amount: '' });
  };
  return (
    <Modal show={showModal.deduction} size='md' className='partial-modal'>
      <Modal.Body>
        <div className='partial-header'>
          <div className='d-flex header'>
            <p> {showModal?.modalTitle} </p>
            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
          </div>
        </div>
        <div className='information'>
          <div>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className='no-padding'>Discounted by</Form.Label>
                  <Form.Control
                    placeholder='Enter Here'
                    value={data.details}
                    onChange={(e) => {
                      setData((prev) => {
                        return { ...prev, details: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className='no-padding'>Amount</Form.Label>
                  <Form.Control
                    placeholder='Enter Here'
                    value={data.amount}
                    type='number'
                    onChange={(e) => {
                      setData((prev) => {
                        return { ...prev, amount: e.target.value };
                      });
                    }}
                    onKeyDown={(evt) => 
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                    isInvalid={invalidData}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {showValidationMsg('Amount')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <Row className='mt-5'>
            <div className='d-flex justify-content-center'>
              <div>
                <Button className='mx-1 deduction-save' onClick={saveHandler}>
                  Save
                </Button>
              </div>
              <div>
                <Button className='mx-1 deduction-cancel' onClick={handleShow}>
                  Cancel
                </Button>
              </div>
            </div>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeductionModal;
