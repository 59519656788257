/* eslint-disable camelcase */
import moment from 'moment';
import { toast } from 'react-toastify';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useState, useEffect } from 'react';

import Table from 'components/ReactTable';
import TableUI from 'components/UI/TableUI';
import EditDietModal from './Modals/EditDietModal';
import EditMealModal from './Modals/EditMealModal';
import CreateDietaryModal from './CreateDietaryModal';
import RemoveDietModal from './Modals/RemoveDietModal';
import CreateDietaryMealModal from './CreateDietaryMealModal';
import {
  selectPatient,
  getCurrentDiet,
  getPatientDiets,
  getPatientMeals,
  selectCurrentDiet,
  selectPatientDiets,
  selectPatientMeals,
} from 'reducers/patientReducer';

import './DietInformationTab.scss';

const DietInformationTab = () => {
  const dispatch = useDispatch();

  const [dietaryModalStatus, setDietaryModalStatus] = useState(false);
  const [dietaryMealModalStatus, setDietaryMealModalStatus] = useState(false);
  const [editDietModal, setEditDietModal] = useState(false);
  const [editMealModal, setEditMealModal] = useState(false);
  const [removeDietModal, setRemoveDietModal] = useState(false);

  const [tableData, setTableData] = useState(false);
  const [tableColumns, setTableColumn] = useState(false);
  const [tab, setCurrentTab] = useState('diets');

  const patientDiets = useSelector(selectPatientDiets);
  const patientMeals = useSelector(selectPatientMeals);
  const currentDiet = useSelector(selectCurrentDiet);
  const groupState = useSelector(selectPatient);
  const { diets, meals, item } = groupState;

  const fetchData = async (data, selector) => {
    await dispatch(data);
    if (selector) {
      const { isFailed, error } = selector;

      if (isFailed) {
        error?.map((error) => {
          toast.error(`${error?.detail} (${error[0]?.code})`);
        });
      }
    }
  };

  const getPatientData = () => {
    let header = [];
    let data = [];

    switch (tab) {
      case 'diets':
        header = [
          { Header: 'Diet Type', accessor: 'diet_type' },
          { Header: 'Start Date', accessor: 'start_date' },
          { Header: 'End Date', accessor: 'end_date' },
        ];
        data = patientDiets?.map((data) => ({
          id: data.id,
          start_date: moment(data.attributes.start_date).format('MMM-DD-YYYY'),
          end_date: moment(data.attributes.end_date).format('MMM-DD-YYYY'),
          diet_type: data.attributes.diet_type,
        }));
        break;

      case 'meals':
        header = [
          { Header: 'Diet Type', accessor: 'diet_type' },
          { Header: 'Taken At', accessor: 'taken_at' },
        ];

        data = patientMeals?.map((data) => ({
          id: data.id,
          taken_at: moment(data.attributes.taken_at).format('MMM-DD-YYYY hh:mm A'),
          diet_type: data.attributes.diet_type,
        }));
        break;
    }

    setTableData(data);
    setTableColumn(header);
  };

  const handleChange = (tab) => {
    setCurrentTab(tab);
    getPatientData(tab);
  };

  const handleTableAction = (eventKey, id) => {
    let target = '';
    switch (tab) {
      case 'diets':
        target = patientDiets;
        break;
      case 'meals':
        target = patientMeals;
        break;
    }
    const data = target.find((dd) => dd.id === id);
    const action = {
      edit: () => {
        switch (tab) {
          case 'diets':
            setEditDietModal((prevState) => {
              return {
                ...prevState,
                status: true,
                data: data,
              };
            });
            break;
          case 'meals':
            setEditMealModal((prevState) => {
              return {
                ...prevState,
                status: true,
                data: data,
              };
            });
            break;
        }
      },
      delete: () => {
        setRemoveDietModal((prevState) => {
          return {
            ...prevState,
            status: true,
            data: data,
          };
        });
      },
    };

    action[eventKey]();
  };

  useEffect(() => {
    if (item?.id) {
      fetchData(getPatientMeals({ user_id: item?.id + '' }), meals);
      fetchData(getPatientDiets({ user_id: item?.id + '' }), diets);
      fetchData(getCurrentDiet({ user_id: item?.id + '' }), currentDiet);
    }
  }, [
    tab,
    item,
    editMealModal,
    editDietModal,
    removeDietModal,
    dietaryModalStatus,
    dietaryMealModalStatus,
  ]);

  useEffect(() => {
    getPatientData();
  }, [patientDiets, patientMeals, tab]);

  return (
    <Fragment>
      <div className='dietInformationTab'>
        <div className='patientProfile__header--dietInformation'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Diet Information</h4>
            <div className='ms-auto'>
              {tab === 'diets' && (
                <Button
                  className='m-1 d-inline-block'
                  variant='addField'
                  onClick={() => setDietaryModalStatus((prevState) => !prevState)}
                >
                  Create Dietary
                </Button>
              )}
              {tab === 'meals' && (
                <Button
                  className='m-1 d-inline-block'
                  variant='addField'
                  onClick={() => setDietaryMealModalStatus((prevState) => !prevState)}
                >
                  Create Meal
                </Button>
              )}
            </div>
          </div>
          <div className='patientProfile__header'>
            <Row>
              <Col>
                <div className='patientProfile__content'>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>Current Diet</span>
                        <span></span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>Diet Type</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>
                        {currentDiet?.attributes.diet_type ?? 'No Data'}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col>
                <div className='patientProfile__content'>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>Start Date</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>
                        {currentDiet?.attributes.start_date
                          ? moment(currentDiet?.attributes.start_date).format('MMM-DD-YYYY hh:mm A')
                          : 'No Data'}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>End Date</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>
                        {currentDiet?.attributes.start_date
                          ? moment(currentDiet?.attributes.end_date).format('MMM-DD-YYYY hh:mm A')
                          : 'No Data'}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <TableUI>
              <div className='mt-3'>
                <Button
                  className='me-3'
                  variant={tab === 'diets' ? 'dark-blue' : 'cancel'}
                  onClick={() => handleChange('diets')}
                >
                  Diet History
                </Button>
                <Button
                  variant={tab === 'meals' ? 'dark-blue' : 'cancel'}
                  onClick={() => handleChange('meals')}
                >
                  Meal History
                </Button>
              </div>
              {tableData && (
                <Table
                  data={tableData}
                  columns={tableColumns}
                  keys={tableData.id}
                  actions={{ hasEdit: true, hasDelete: true }}
                  onAction={handleTableAction}
                  pagination={['BOTTOM']}
                ></Table>
              )}
            </TableUI>
          </div>
        </div>
      </div>

      {dietaryModalStatus && (
        <CreateDietaryModal
          className='ml-4'
          status={dietaryModalStatus}
          onToggle={setDietaryModalStatus}
        />
      )}

      {dietaryMealModalStatus && (
        <CreateDietaryMealModal
          status={dietaryMealModalStatus}
          onToggle={setDietaryMealModalStatus}
        />
      )}

      {editDietModal && <EditDietModal showModal={editDietModal} setShowModal={setEditDietModal} />}
      {editMealModal && <EditMealModal showModal={editMealModal} setShowModal={setEditMealModal} />}
      {removeDietModal && (
        <RemoveDietModal showModal={removeDietModal} setShowModal={setRemoveDietModal} />
      )}
    </Fragment>
  );
};

export default DietInformationTab;
