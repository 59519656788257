// import core & vendor packages below
import { Fragment } from 'react';
import ReactHelmet from 'components/ReactHelmet';

// import components below
import LoginUI from 'components/UI/LoginUI';
import FormForgotPassword from 'pages/ForgotPassword/components/Form';

// import assets below
import './style.scss';

// main component
const ForgotPassword = () => {
  return (
    <Fragment>
      <ReactHelmet title='Forgot Password' />
      <LoginUI>
        <div className='containerSignup'>
          <div className='containerSignup__body'>
            <FormForgotPassword />
          </div>
        </div>
      </LoginUI>
    </Fragment>
  );
};

export default ForgotPassword;
