/* eslint-disable prefer-const */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { alphabeCharFieldRules } from 'helpers/fieldValidations';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const AddDoctorToPatient = ({ showModal, setShowModal, setState }) => {
  let ref = useRef();

  const [patients, setPatients] = useState([]);
  const [labResult, setLabResult] = useState([]);
  const [saveButton, setSaveButton] = useState(true);
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const [userData, setUserData] = useState({
    id: '',
    remarks: '',
    signed_by: '',
    conducted_by: '',
    date_performed: '',
    licensed_number: '',
    lab_test: 'Internal',
    labRequestId: showModal.labRequestId,
  });

  useEffect(() => {
    // console.log('data', userData);
  }, [userData]);

  const handleFile = (e) => {
    const file = e.target.files[0];

    if (file.type !== 'application/pdf') {
      toast.warning('Only PDF is allowed');
      ref.current.value = null;
    } else {
      setLabResult(file);
      setSaveButton(false);
    }
  };

  const { inValidateInput, showValidationMsg } = alphabeCharFieldRules;

  // Submit data here
  const handleSubmit = (e) => {
    const formdata = new FormData();
    formdata.append('result', labResult);
    formdata.append('id', showModal.labRequestId);
    formdata.append('lab_test', userData.lab_test);
    formdata.append('conducted_by', userData.conducted_by);
    formdata.append('licensed_number', userData.licensed_number);
    formdata.append('signed_by', userData.signed_by);
    formdata.append('remarks', userData.remarks);
    formdata.append('date_performed', userData.date_performed);

    if (inValidateInput(userData.conducted_by)) {
      setIsInvalidInput(true);
      return;
    }

    setIsInvalidInput(false);

    axios
      .post('/requisition/lab_result', formdata)
      .then((response) => {
        setSaveButton(true);
        setTimeout(() => {
          axios
            .get('/requisition/laboratory')
            .then((response) => {
              toast.success('Successfully uploaded lab result!');
              setState(response.data.data);
              setUserData({
                id: '',
                conducted_by: '',
                licensed_number: '',
                signed_by: '',
                date_performed: '',
                remarks: '',
              });
            }, [])
            .catch((err) => {
              console.log('AXIOS ERROR: ', err);
            });
        }, 1000);
      }, [])
      .catch((err) => {
        err?.response?.data?.errors.map((item) => {
          toast.warning(item.msg + ' ' + item.param);
        });
      });
  };

  const [searchedWord, setSearchedWord] = useState('');

  /** filter patients */
  const onChangeHandler = (event) => {
    setSearchedWord(event.target.value);
    const searchedWord = event.target.value;
    const newFilter = patients.filter((value) => {
      return value.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState([]);
    }
  };

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });

    if (!e.target.value.length > 0) {
      setSaveButton(true);
    }

    if (
      userData.conducted_by.length === 0 ||
      userData.licensed_number.length === 0 ||
      userData.signed_by.length === 0 ||
      userData.date_performed.length === 0
    ) {
      setSaveButton(true);
    } else {
      setSaveButton(false);
    }
  };

  useEffect(() => {
    /** Get patients for search patient */
    axios
      .get('/patient')
      .then((response) => {
        setPatients(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    setState([]);
  }, []);

  return (
    <Modal
      size='lg'
      show={showModal.isAddDoctor}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            isAddDoctor: false,
          };
        })
      }
      aria-labelledby='Add Doctor to Patient'
      className='add-doctor-to-patient-modal'
    >
      <Modal.Body>
        <div className='add-doctor-to-patient-request'>
          <div className='d-flex header'>
            <p>UPLOAD PATIENT LAB RESULT</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowModal((prevState) => {
                  return { ...prevState, isAddDoctor: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col md={4}>
                <Form.Group className='mb-4'>
                  <Form.Label>Internal/External ?</Form.Label>
                  <Form.Select
                    onChange={handleChange('lab_test')}
                    value={userData.route_of_administration}
                    defaultValue='Internal'
                  >
                    <option hidden>-Select-</option>
                    <option value='Internal'>Internal</option>
                    <option value='External'>External</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>File Upload</Form.Label>
                  <FormControl
                    type='file'
                    name='result'
                    ref={ref}
                    className='bg-white'
                    placeholder='Enter Here'
                    onChange={(e) => handleFile(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>
                    Conducted By: <span className='text-danger'>*</span>
                  </Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.conducted_by}
                    required={true}
                    onChange={handleChange('conducted_by')}
                    isInvalid={isInvalidInput}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {showValidationMsg('Conducted By')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>
                    PRC Licensed #: <span className='text-danger'>*</span>
                  </Form.Label>
                  <FormControl
                    type='number'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.licensed_number}
                    onChange={handleChange('licensed_number')}
                    required={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>
                    Verified By: <span className='text-danger'>*</span>
                  </Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.signed_by}
                    onChange={handleChange('signed_by')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>
                    Date Performed: <span className='text-danger'>*</span>
                  </Form.Label>
                  <FormControl
                    type='date'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.date_performed}
                    onChange={handleChange('date_performed')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Remarks</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={3}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.remarks}
                    onChange={handleChange('remarks')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    onClick={() => handleSubmit()}
                    className='add-button'
                    variant='primary'
                    disabled={saveButton}
                  >
                    SAVE
                  </Button>
                  <Button
                    className='cancel-button'
                    variant='secondary'
                    onClick={() => {
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          isAddDoctor: false,
                        };
                      });
                      setSearchedWord('');
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default AddDoctorToPatient;
