// import core & vendor packages below
import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';

// import components below
import Layout from 'components/Layout';
import AdmissionsTabs from './components/Tabs';
import AdmissionHeader from './components/PageHeader';

// import assets below
import './style.scss';

// main component
const Admissions = () => {
  const [activeTab, setActiveTab] = useState('Room Reservation List');

  return (
    <Layout pageTitle='AdmissionsInventory'>
      <AdmissionHeader active={activeTab} />
      
      <Container fluid>
        <Row>
          <AdmissionsTabs onChange={setActiveTab} />
        </Row>
      </Container>
    </Layout>
  );
};

export default Admissions;
