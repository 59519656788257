/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormControl, Button, Tabs, Tab, Image } from 'react-bootstrap';

// import components below
import AddRecord from './AddRecord';
import ViewRecord from './ViewRecord';
import TabsUI from 'components/UI/TabsUI';
import RequestTable from './RequestTable';
import ConditionalRender from 'components/ConditionalRender';
import { TableFiltersPermission, checkStringPermission } from 'helpers/filteredPermissions';
import { showUsers, getACLState, getRoles, getDepartments, getLogs } from 'reducers/aclReducer';

// import assets below
import './index.scss';
import logoImage from 'assets/images/logo2.png';
import { ReactComponent as PlusSVG } from 'assets/svg/circle-plus-blue.svg';

const LeaveContainer = () => {
  const [showScreen, setShowScreen] = useState(1);
  const [requestData, setRequestData] = useState();

  return (
    <>
      {/* Default View */}
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <div className='request-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>REQUESTS</Form.Label>

                <FormControl className='blue-control' placeholder='Search Employee' />
              </Form.Group>

              <Link className='back-to-employee' to='/dashboard/employees/list'>
                &#x3c; Go Back to Employee List
              </Link>
            </div>

            <hr />

            <div className='box-container py-0'>
              <div className='header'>
                <h2>Display Request From</h2>

                <div className='functions'>
                  <Form.Group className='inline-group'>
                    <Form.Label>From:</Form.Label>

                    <FormControl type='date' className='seven' placeholder='Enter Here' />
                  </Form.Group>

                  <Form.Group className='inline-group'>
                    <Form.Label>To:</Form.Label>

                    <FormControl type='date' className='seven' placeholder='Enter Here' />
                  </Form.Group>

                  <Button className='filter'>
                    {/* <UploadSVG /> */}
                    Apply Filter
                  </Button>
                </div>
              </div>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0 px-0'>
              <RequestTable setShowScreen={setShowScreen} setRequestData={setRequestData} />
            </div>
          </div>
        }
      />

      {/* View Record */}
      <ConditionalRender
        condition={showScreen === 2}
        renderIf={<ViewRecord requestData={requestData} setShowScreen={setShowScreen} />}
      />

      {/* Add Record */}
      <ConditionalRender
        condition={showScreen === 3}
        renderIf={<AddRecord setShowScreen={setShowScreen} />}
      />
    </>
  );
};

export default LeaveContainer;
