/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import Table from 'components/ReactTable';
import { getRegisterList, selectFixedAssets } from 'reducers/fixedAssetsReducer';

// import assets below
import './index.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';

// main comnponent

const index = ({ setShowScreen, setRequestData }) => {
  const dispatch = useDispatch();

  const fixedAssetsState = useSelector(selectFixedAssets);
  const { registerData, registerItems, registerLogs } = fixedAssetsState;

  const [tableList, setTableList] = useState([]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Number', accessor: 'number' },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Serial Number', accessor: 'serial_number' },
      { Header: 'Location', accessor: 'location' },
      { Header: 'Purchase Date', accessor: 'purchased_date' },
      { Header: 'Supplier', accessor: 'supplier' },
      { Header: 'Cost', accessor: 'cost' },
      { Header: 'Depreciation Rate', accessor: 'depreciation_rate' },
      { Header: 'Notes', accessor: 'notes' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({
        data: dd,
        id: dd?.id,
        cost: dd?.cost,
        notes: dd?.notes,
        number: dd?.number,
        location: dd?.location,
        supplier: dd?.supplier,
        description: dd?.description,
        serial_number: dd?.serial_number,
        depreciation_rate: dd?.depreciation_rate,
        purchased_date: moment(dd?.purchased_date).format('MM/DD/YYYY'),
      })),
    [tableList],
  );

  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, registerData, registerLogs);

  return (
    <div className='credentials-credits'>
      <div className='header'>

        <div className='functions'>
          <Button className='upload' onClick={() => setShowScreen(4)}>
            <AddSVG />
            Add Record
          </Button>
        </div>
      </div>

      <div className='credits-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
        />
      </div>
    </div>
  );
};

const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getRegisterList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, registerData, registerLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = registerLogs;

    if (isSuccess) {
      const newData = registerData.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          cost: dd?.cost,
          notes: dd?.notes,
          number: dd?.number,
          location: dd?.location,
          supplier: dd?.supplier,
          description: dd?.description,
          serial_number: dd?.serial_number,
          purchased_date: dd?.purchased_date,
          depreciation_rate: dd?.depreciation_rate,
        };
      });
      console.log(newData);
      setTableList(newData);
    }
  }, [registerLogs]);
};

export default index;
