/* eslint-disable multiline-comment-style */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import TabsUI from 'components/UI/TabsUI';
import PerDepartment from './components/PerDepartment';

import './style.scss';

// main component
const DailyWorkList = ({ refreshTable }) => {
  const [users, setUsers] = useState([]);

  const tabs = [
    {
      apiKey: 'all',
      title: 'All',
      component: PerDepartment,
    },
    {
      apiKey: 'ent',
      title: 'ENT',
      component: PerDepartment,
    },
    {
      apiKey: 'medicine',
      title: 'Medicine',
      component: PerDepartment,
    },
    {
      apiKey: 'ob_gyne',
      title: 'OB-GYNE',
      component: PerDepartment,
    },
    {
      apiKey: 'urology',
      title: 'Urology',
      component: PerDepartment,
    },
    {
      apiKey: 'cardiology',
      title: 'Cardiology',
      component: PerDepartment,
    },
    {
      apiKey: 'pulmonary_medicine',
      title: 'Pulmonary Medicine',
      component: PerDepartment,
    },
    {
      apiKey: 'opthalmology',
      title: 'Ophthalmology',
      component: PerDepartment,
    },
    {
      apiKey: 'surgery',
      title: 'Surgery',
      component: PerDepartment,
    },
    {
      apiKey: 'orthopedic_surgery',
      title: 'Orthopedic Surgery',
      component: PerDepartment,
    },
    {
      apiKey: 'endocrinology',
      title: 'Endocrinology',
      component: PerDepartment,
    },
    {
      apiKey: 'pediatrics',
      title: 'Pediatrics',
      component: PerDepartment,
    },
    {
      apiKey: 'dermatology',
      title: 'Dermatology',
      component: PerDepartment,
    },
    {
      apiKey: 'oncology',
      title: 'Oncology',
      component: PerDepartment,
    },
    {
      apiKey: 'neurology',
      title: 'Neurology',
      component: PerDepartment,
    },
    {
      apiKey: 'gastroenterology',
      title: 'Gastroenterology',
      component: PerDepartment,
    },
    {
      apiKey: 'internal_medicine',
      title: 'Internal Medicine',
      component: PerDepartment,
    },
    {
      apiKey: 'nephrology',
      title: 'Nephrology',
      component: PerDepartment,
    },
  ];

  useEffect(() => {
    axios
      .get('/user')
      .then((response) => {
        const renderedData = response?.data?.data.map((data) => {
          return { value: data?.full_name, label: data?.full_name, data: data };
        });

        setUsers(renderedData);
      }, [])
      .catch((err) => {
        console.log('Error: ', err);
      });
  }, []);

  return (
    <div className='doctorFilter'>
      {refreshTable?.clinicWorkList && (
        <TabsUI>
          <Tabs defaultActiveKey={tabs[0].title}>
            {tabs.map(({ title, apiKey, component: Component }, idx) => (
              <Tab key={idx} eventKey={title} title={title}>
                <Component
                  users={users}
                  title={title}
                  apiKey={apiKey}
                  refreshTable={refreshTable}
                />
              </Tab>
            ))}
          </Tabs>
        </TabsUI>
      )}
    </div>
  );
};

export default DailyWorkList;
