/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';

export const getAllEmployeeSchedules = createAsyncThunk(
  'EMPLOYEE_SCHEDULES_GET_THUNK',
  async (params = {}, { rejectWithValue }) => {
    return await axios
      .get('/employee/schedule/lists', { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createEmployeeSchedule = createAsyncThunk(
  'EMPLOYEE_SCHEDULE_CREATE_THUNK',
  async (payload = {}, { rejectWithValue }) => {
    return await axios
      .post('/employee/schedule', payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateEmployeeSchedule = createAsyncThunk(
  'EMPLOYEE_SCHEDULE_UPDATE_THUNK',
  async (payload = {}, { rejectWithValue }) => {
    return await axios
      .put(`/employee/schedule/${payload.id}`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteEmployeeScheduleById = createAsyncThunk(
  'EMPLOYEE_SCHEDULE_DELETE_THUNK',
  async (id, { rejectWithValue }) => {
    return await axios
      .delete(`/employee/schedule/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteEmployeeSchedulesByEmployeeId = createAsyncThunk(
  'EMPLOYEE_SCHEDULE_DELETE_ALL_THUNK',
  async (id, { rejectWithValue }) => {
    return await axios
      .delete(`/employee/schedule/all/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
