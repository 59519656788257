/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import { useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  FormControl,
  Pagination,
  Table,
  InputGroup,
} from 'react-bootstrap';

// components below
import FormUI from 'components/UI/FormUI';
import AddDoctorStatusTable from './Table';

// CSS
import './style.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const AddDoctorStatus = () => {
  const [state, setState] = useState([]);
  const [showModal, setShowModal] = useState({
    isShowView: false,
    profileData: null,
    profileId: null,
  });
  const [userData, setUserData] = useState({
    patient_name: '',
    attending_physician: '',
    bed_category: '',
    status: '',
  });

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  return (
    <FormUI>
      <Form>
        <Row className='mb-4'>
          <Col>
            <Form.Group className='basic-search'>
              <Form.Label>Patient Name</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-white'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-white'
                  placeholder='Enter Here'
                  value={userData.patient_name}
                  onChange={handleChange('patient_name')}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className=''>
              <Form.Label>Bed Category</Form.Label>
              <Form.Select
                aria-label='Doctor'
                value={userData.bed_category}
                onChange={handleChange('bed_category')}
              >
                <option value='' hidden>
                  - Select -
                </option>
                <option value='1'>One</option>
                <option value='2'>Two</option>
                <option value='3'>Three</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className=''>
              <Form.Label>Status</Form.Label>
              <Form.Select
                aria-label='Location'
                value={userData.status}
                onChange={handleChange('status')}
              >
                <option value='' hidden>
                  - Select -
                </option>
                <option value='1'>One</option>
                <option value='2'>Two</option>
                <option value='3'>Three</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='category-buttons'>
              <Button
                onClick={() => {
                  // Update Data on Table?
                }}
                className='add-button'
                variant='primary'
                type='submit'
              >
                Apply
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <h2 className='mb-3'>Patient List</h2>

      {/* Table */}
      <AddDoctorStatusTable setShowModal={setShowModal} />
    </FormUI>
  );
};

export default AddDoctorStatus;
