/* eslint-disable */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Table from 'components/ReactTable';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// import components below
import { patientStatus } from 'helpers';
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const DiagnosisListTable = ({ setShowProfile, basicSearch, advanceSearch }) => {
  const patientData = useSelector(selectPatientData);
  const [data, setData] = useState([]);
  const [active, setActive] = useState('all');

  useEffect(() => {
    axios
      .get('/patient', {
        params: {
          // patient_status: ['admitted'],
        },
      })
      .then((response) => {
        setData(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, [patientData]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Age / Sex', accessor: 'age_sex' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      { Header: 'Status', accessor: 'status' },
    ],
    [data],
  );

  const getSearchedData = (list) => {
    if (list.length > 0) {
      const filteredData = list.filter((data) => {
        const createdAt = () => {
          if (data?.history_logs && data?.history_logs.length > 0) {
            return data?.history_logs[0]?.createdAt;
          }

          return '';
        };

        const newData = createdAt();

        return (
          data?.MRN?.includes(advanceSearch?.pin) &&
          newData?.includes(advanceSearch?.createdDate) &&
          data?.full_name?.toLowerCase().includes(basicSearch?.toLowerCase()) &&
          data?.full_name?.toLowerCase().includes(advanceSearch?.patient.toLowerCase())
        );
      });

      return filteredData;
    }

    return list;
  };

  const tableData = React.useMemo(
    () =>
      getSearchedData(data)?.map((dd) => ({
        id: dd?.id,
        name: dd?.full_name,
        age_sex: `${dd?.age}/${dd?.sex}`,
        date_of_birth: dd?.date_of_birth,
        status: patientStatus(dd?.patient_status),
      })),
    [data, basicSearch, advanceSearch, patientData],
  );

  const filterList = [
    { key: 'all', name: 'All Patients' },
    { key: 'triage', name: 'Triage' },
    { key: 'admitted', name: 'Admitted' },
    { key: 'awaiting_bed', name: 'Awaiting Bed' },
    { key: 'for_admission', name: 'For Admission' },
    { key: 'for_discharge', name: 'For Discharge' },
  ];

  const handleOnClickFilter = (status) => {
    let filteredData = patientData;

    if (status !== 'all') {
      filteredData = patientData.filter((dd) => dd.patient_status === status);
    }

    setData(filteredData);
    setActive(status);
  };

  return (
    <div className='diagnosis-list-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        // actions={{ hasView: true }}
        // onAction={handleTableAction}
        customAction={(id) => (
          <span
            onClick={() => {
              const rowData = getSearchedData(data).find((d) => d?.id === +id);

              setShowProfile((prevState) => ({
                ...prevState,
                profileId: id,
                isShowDiagnosis: true,
                status: rowData?.patient_status,
              }));
            }}
            className='badge bg-success'
            style={{ cursor: 'pointer' }}
          >
            Add Diagnosis
          </span>
        )}
        filter={{
          top: (
            <div className='buttons'>
              {filterList.map((list) => (
                <Button
                  key={list.key}
                  className={list.key === active ? 'active' : ''}
                  onClick={() => handleOnClickFilter(list.key)}
                >
                  {list.name}
                </Button>
              ))}
            </div>
          ),
        }}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

DiagnosisListTable.defaultProps = {
  setShowProfile: () => {},
};

DiagnosisListTable.propTypes = {
  setShowProfile: PropTypes.func,
};

export default DiagnosisListTable;
