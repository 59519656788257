/* eslint-disable */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';

// import assets below
import './Filter.scss';

// main component
const DoctorFilter = ({ state, setState, setShowProfile }) => {
  const [word, setWord] = useState('');
  const [id, setId] = useState('');
  const [MRN, setMRN] = useState('');
  const [patients, setPatients] = useState([]);

  const resetFilter = () => {
    setState(patients);
    setWord('');
    setId('');
    setMRN('');
  };

  // Search Patient by name
  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    setWord(event.target.value);
    const newFilter = patients.filter((value) => {
      return value.patient.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  // Search patient by ID
  const onChangeHandlerID = (event) => {
    const searchedWord = event.target.value;
    setId(event.target.value);

    console.log('id', event.target.value);
    console.log('patients', patients);

    const newFilter = patients.filter((value) => {
      return String(value.patient.id) === String(searchedWord);
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  // search by patient MRN
  const onChangeHandlerMRN = (event) => {
    const searchedWord = event.target.value;
    setMRN(event.target.value);
    const newFilter = patients.filter((value) => {
      return String(value.patient.MRN) === String(searchedWord);
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  const handleFeeModal = () => {
    setShowProfile((prev) => {
      return {
        ...prev,
        showDoctorFee: true,
      };
    });
  };

  useEffect(() => {
    axios
      .get('/clearance/patients')
      .then((response) => {
        setState(response.data.data);
        setPatients(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='doctorFilter'>
      <FormUI>
        <Row className='custom'>
          <Col md={1} className='container-tb'>
            <Form.Group className='mb-3 text-box'>
              <Form.Label>Patient ID</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter here'
                value={id}
                onChange={onChangeHandlerID}
              />
            </Form.Group>
          </Col>

          <Col md={2} className='container-tb'>
            <Form.Group className='mb-3 text-box'>
              <Form.Label>Patient MRN/PIN</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter here'
                value={MRN}
                onChange={onChangeHandlerMRN}
              />
            </Form.Group>
          </Col>

          <Col className='container-tb'>
            <Form.Group className='mb-3 text-box'>
              <Form.Label>Patient Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter First Name'
                value={word}
                onChange={onChangeHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='patient-header'>
              <h4 className='mb-0 fw-bold'>Patient List</h4>
            </div>
          </Col>
          <Col>
            <div className='buttonWrapper'>
              {/* <Button variant='search'>Go</Button> */}
              <Button onClick={handleFeeModal} className='primary fee'>
                Professional Fee
              </Button>
              <Button variant='clear' onClick={resetFilter}>
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

DoctorFilter.defaultProps = {
  state: [],
  setState: () => {},
};

DoctorFilter.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
};

export default DoctorFilter;
