import TableUI from 'components/UI/TableUI';
import { FormControl, InputGroup, Table } from 'react-bootstrap';

const SummaryPatient = (props) => {

  const { item } = props;

  return (<>
    <label className='fs-5 fw-bold mb-1'>A. Summary of Patients in the Hospital</label>
    <label className='fs-6 fw-bold'>For each category listed below, please report the total volume of services or procedures performed.</label>
    <label className='fs-7'>{'\u2022' + ' '} Inpatient: A patient who stays in a health facilitywhile under treatmentfor morethan twenty-four ( 24) hours.\</label>
    <div className='mt-3'>
      <TableUI>
        <Table striped hover style={{ width: '80%' }}>
          <thead>
            <tr>
              <th>Inpatient Care</th>
              <th>Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total admissions (January 1, 12:01 am to December 31, 12;00 midnight</td>
              <td>
                <InputGroup className='mb-1 mt-1'>
                  <FormControl
                    placeholder='Enter Here'
                    disabled
                    value={item[0].total_inpatient}
                  />
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>Total Discharges (Alive)Include HAMA and Absconded</td>
              <td><InputGroup className='mb-1 mt-1'>
                <FormControl
                  placeholder='Enter Here'
                  disabled
                  value={item[0].total_discharges_alive}
                />
              </InputGroup></td>
            </tr>
            <tr>
              <td>Total Newborn (In facility deliveries)</td>
              <td><InputGroup className='mb-1 mt-1'>
                <FormControl
                  placeholder='Enter Here'
                  disabled
                  value={item[0].total_newborn}
                />
              </InputGroup></td>
            </tr>
            <tr>
              <td>Total patients admitted and discharged on the same day</td>
              <td><InputGroup className='mb-1 mt-1'>
                <FormControl
                  placeholder='Enter Here'
                  disabled
                  value={item[0].admitted_discharged_same_day}
                />
              </InputGroup></td>
            </tr>
            <tr>
              <td>Total number of In patient bed days(service days) (Use attached form for your daily census to come up with total in patient bed days.</td>
              <td><InputGroup className='mb-1 mt-1'>
                <FormControl
                  placeholder='Enter Here'
                  disabled
                  value={item[0].inpatient_bed_days}
                />
              </InputGroup></td>
            </tr>
            <tr>
              <td>Total number of inpatients transferred TO THIS FACILITY from another facility for inpatient care</td>
              <td><InputGroup className='mb-1 mt-1'>
                <FormControl
                  placeholder='Enter Here'
                  disabled
                  value={item[0].inpatient_transferred_to_this_facility}

                />
              </InputGroup></td>
            </tr>
            <tr>
              <td>Total number of inpatients transferred FROM THIS FACILITY to another facility for inpatient care</td>
              <td><InputGroup className='mb-1 mt-1'>
                <FormControl
                  placeholder='Enter Here'
                  disabled
                  value={item[0].inpatient_transferred_from_this_facility}
                />
              </InputGroup></td>
            </tr>
            <tr>
              <td>Total number of patients remaining in the hospital as of midnight last day of previous year</td>
              <td><InputGroup className='mb-1 mt-1'>
                <FormControl
                  placeholder='Enter Here'
                  disabled
                  value={item[0].admitted_discharged_same_day}
                />
              </InputGroup></td>
            </tr>
          </tbody>
        </Table>
      </TableUI>
    </div>
  </>
  );
};

export default SummaryPatient;
