/* eslint-disable */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from 'components/UI/TabsUI';
import InventoryPerDepartment from 'components/InventoryPerDepartment';

// import assets below
import Notes from './Notes';
import AddDoctor from './AddDoctor';
import Diagnosis from './Diagnosis';
import Clearance from './Clearance';
import Requisition from './Requisition';
import RequestRoom from './RequestRoom';
import ProfessionalFee from './ProfessionalFee';
import UpdatePatient from './UpdatePatient';
import SearchPatient from './SearchPatient';

// main component
const NursingPatientTabs = ({ onChange }) => {
  const [activeTab, setActiveTab] = useState('Search Patient');

  const handleTabOnChange = (eventKey) => {
    setActiveTab(eventKey);
    onChange(eventKey);
  };

  const tabs = [
    {
      title: 'Search Patient',
      component: SearchPatient
    },
    {
      title: 'Requisitions',
      component: Requisition
    },
    {
      title: 'Diagnosis',
      component: Diagnosis,
    },
    // {
    //   title: 'Update Patient',
    //   component: UpdatePatient,
    // },
    // {
    //   title: 'Request Room',
    //   component: RequestRoom,
    // },
    {
      title: 'Add Doctor',
      component: AddDoctor
    },
    {
      title: 'Notes',
      component: Notes
    },
    {
      title: 'Professional Fee',
      component: ProfessionalFee,
    },
    {
      title: 'Inventory',
      component: InventoryPerDepartment
    },
  ];

  return (
    <div className='nursingPatientTabs'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title} onSelect={(eventKey) => handleTabOnChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component departmentID={'3'} />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

NursingPatientTabs.defaultProps = {
  onChange: () => {},
};

NursingPatientTabs.propTypes = {
  onChange: PropTypes.func,
};

export default NursingPatientTabs;
