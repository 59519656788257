/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal } from 'react-bootstrap';

import seturlParams from 'helpers/seturlParams';
import convertToMMddYYYY from 'helpers/date-converter';
import ConditionalRender from 'components/ConditionalRender';
import { selectDepartmentInventory } from 'reducers/departmentInventoryReducer';

// Import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

const ViewItemsModal = ({ showModal, setShowModal }) => {
  const { data, items } = showModal?.itemData;

  const inventoryState = useSelector(selectDepartmentInventory);

  const { items: inventoryItems } = inventoryState;

  const getGroupName = (id) => {
    if (!inventoryItems || inventoryItems.length === 0) return;
    
    const selectedArray = inventoryItems.filter(
      (data) => parseInt(data.id) === parseInt(id) && data?.type === 'groups',
    );

    if (selectedArray.length === 0) {
      return 'N/A';
    }

    return selectedArray[0]?.attributes?.name;
  };

  const closeModal = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        view: false,
      };
    });
  };

  return (
    <Modal
      size='lg'
      show={showModal?.view}
      onHide={closeModal}
      aria-labelledby='View Items Modal'
      className='add-schedule-shifting-modal admissions-inventory-view-items-details-modal item-details'
    >
      <Modal.Body>
        <div className='add-new-item-request'>
          <div className='d-flex header'>
            <p>ITEM DETAILS</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => setShowModal(false)}
            />
          </div>
        </div>

        <div className='px-5'>
          <Row>
            <Col>
              <div className='mb-5'>
                <h4 className='my-4'>ITEM INFORMATION</h4>
                <Row className='mb-4'>
                  <Col md={6}>Item Code</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{items?.attributes?.code}</span>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Item Name</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{items?.attributes?.name}</span>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Generic Name</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{items?.attributes?.generic_name}</span>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Manufacturer</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{items?.attributes?.manufacturer}</span>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Average Consumption</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{items?.attributes?.average_consumption}</span>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Price Price</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{items?.attributes?.piece_price}</span>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Box Price</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{items?.attributes?.box_price}</span>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Item Description</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{items?.attributes?.description}</span>{' '}
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Item Group</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{getGroupName(items?.attributes?.group_id)}</span>{' '}
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Date Created</Col>
                  <Col md={6}>
                    :{' '}
                    <span className='fw-bold'>
                      {convertToMMddYYYY(data?.attributes?.created_at)}
                    </span>{' '}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col>
              <div className='mb-5'>
                <h4 className='my-4'>STORE ITEM INFORMATION</h4>
                <Row className='mb-4'>
                  <Col md={6}>Piece Quantity</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{data?.attributes?.piece_quantity}</span>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Box Quantity</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{data?.attributes?.box_quantity}</span>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={6}>Storage Bin</Col>
                  <Col md={6}>
                    : <span className='fw-bold'>{items?.attributes?.storage_bin}</span>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div className='mb-5'>
            <Row>
              <Col className='form-buttons'>
                <Button className='close-button' onClick={closeModal}>
                  Close
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewItemsModal;
