/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';

// import assets below
import './Filter.scss';

// main component
const InventoryFilter = ({ setSearch, search, fetchData, resetData, active }) => {
  return (
    <div className='InventoryFilter'>
      <FormUI>
        <Row className='mb-4'>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Control Number</Form.Label>
              <FormControl
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                value={search}
                type='text'
                className='bg-white'
                placeholder='Search Here'
              />
            </Form.Group>
          </Col>
          <Col>
            <div className='buttonWrapper'>
              <Button variant='search' onClick={() => fetchData(active)}>
                APPLY
              </Button>

              <Button
                variant='clear'
                onClick={() => {
                  resetData();
                  setSearch('');
                }}
              >
                RESET
              </Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

InventoryFilter.defaultProps = {
  setState: () => {},
};

InventoryFilter.propTypes = {
  setState: PropTypes.func,
};

export default InventoryFilter;
