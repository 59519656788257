/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Modal, Button, Row, Col, Form, FormControl } from 'react-bootstrap';

// import assets below
import './index.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const LeaveCreditsModal = ({ openModal, setOpenModal }) => {
  const closeModal = () => {
    setOpenModal({
      data: '',
      isOpen: false,
    });
  };

  return (
    <Modal
      size='lg'
      centered
      onHide={closeModal}
      show={openModal?.isOpen}
      className='leave-credits'
    >
      <Modal.Header>
        <h6>Leave Credits</h6>
        <Button onClick={closeModal}>
          <CloseSVG />
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className='main-content'>
          <Form.Group className='vertical-align'>
            <Form.Label>Vacation Leave</Form.Label>

            <FormControl placeholder='Enter Here' className='border-seven' />
          </Form.Group>

          <Form.Group className='vertical-align'>
            <Form.Label>Emergency Leave</Form.Label>

            <FormControl placeholder='Enter Here' className='border-seven' />
          </Form.Group>

          <Form.Group className='vertical-align'>
            <Form.Label>Sick Leave</Form.Label>

            <FormControl placeholder='Enter Here' className='border-seven' />
          </Form.Group>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={closeModal} className='save'>
          Save
        </Button>

        <Button onClick={closeModal} className='cancel'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeaveCreditsModal;
