/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const login = createAsyncThunk(
  'auth/login',
  async (payload, { rejectWithValue }) => {
    return await axios
      .post('https://internal.manda-his-be.highlysucceed.com/user/auth/login', payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

export const updateProfile = createAsyncThunk(
  'department/updateProfile',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `api/users/${params.id}`,
      data: params.payload,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
