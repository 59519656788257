/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getHospitalOperation = createAsyncThunk(
  'report/getHospitalOperation',
  async (id, { rejectWithValue }) => {
    return await axios
        .get(`/report/hospital_operation/${id}`, {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
