/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import React, { useState } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

// import components below
import { payrollStatus } from 'helpers';
import Table from 'components/ReactTable';
import AddPayrollModal from './components/Modal';
import sampleImage from 'assets/images/sample-doctor.jpg';

// import assets below
import './index.scss';

// main components
const state = [
  {
    avatar: sampleImage,
    description: 'Details Here',
    added_by: 'Juan Dela Cruz',
    period_start: '9:00 am',
    period_end: '6:00 pm',
    status: 'Complete',
  },
  {
    avatar: sampleImage,
    description: 'Details Here',
    added_by: 'Juan Dela Cruz',
    period_start: '9:00 am',
    period_end: '6:00 pm',
    status: 'Complete',
  },
  {
    avatar: sampleImage,
    description: 'Details Here',
    added_by: 'Juan Dela Cruz',
    period_start: '9:00 am',
    period_end: '6:00 pm',
    status: 'Complete',
  },
];

const index = ({ setShowScreen, setRequestData }) => {
  const [openModal, setOpenModal] = useState();

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Avatar', accessor: 'avatar' },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Added by', accessor: 'added_by' },
      { Header: 'Period Start', accessor: 'period_start' },
      { Header: 'Period End', accessor: 'period_end' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        added_by: dd?.added_by,
        period_end: dd?.period_end,
        description: dd?.description,
        period_start: dd?.period_start,
        status: payrollStatus(dd?.status),
        avatar: <img src={dd?.avatar} className='avatar' />,
      })),
    [state],
  );

  return (
    <div className='advances-table'>
      <div className='main-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <div className='advance-actions'>
                <Button
                  className='add-payroll'
                  onClick={() =>
                    setOpenModal({
                      data: data,
                      isOpen: true,
                    })
                  }
                >
                  Add Payroll Period
                </Button>
              </div>
            );
          }}
        />
      </div>

      <AddPayrollModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default index;
