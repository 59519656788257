// import core & vendor packages below
import { Container, Row } from 'react-bootstrap';

// main components below
import Layout from 'components/Layout';
import CreateTemporaryProfileHeader from './PageHeader';
import EditProfile from 'components/FormEditPatientProfile';

// main component
const CreateTemporaryProfile = () => {
    return (
        <Layout pageTitle='OutpatientDepartment'>
            <CreateTemporaryProfileHeader />
            <Container fluid>
                <Row>
                    <EditProfile />
                </Row>
            </Container>
        </Layout>
    );
};

export default CreateTemporaryProfile;
