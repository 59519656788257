/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Fragment, useState } from 'react';
import { Row, Col, Tabs, Tab, Table, Form, Button } from 'react-bootstrap';

// import components below
import TabsUI from 'components/UI/TabsUI';

import OrderTable from './Order';

// import assets below
import './Orders.scss';

// main component
const OrdersTab = ({ showProfile, setShowProfile }) => {

  return (
    <div className='order-tabs'>
      <div className='order-tabs__header'>
        <TabsUI>
          <Tabs defaultActiveKey='Order'>
            <Tab eventKey='Order' title='Order'>
              <OrderTable showProfile={showProfile} setShowProfile={setShowProfile} />
            </Tab>

            <Tab eventKey='Order History' title='Order History'>
              Order History
            </Tab>

            <Tab eventKey='Cancellation Request' title='Cancellation Request'>
              Cancellation Request
            </Tab>

            <Tab eventKey='Return Request' title='Return Request'>
              Return Request
            </Tab>
          </Tabs>
        </TabsUI>
      </div>
    </div>
  );
};

export default OrdersTab;
