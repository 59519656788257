/* eslint-disable */
import React, { useState } from 'react';
// main components below
import InventoryListTable from './Table';
import SearchInventoryFilter from './Filter';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

import './style.scss';

// main component
const RequestItem = ({ departmentID }) => {
  const [active, setActive] = useState('');
  const [searchData, setSearchData] = useState({
    search: '',
  });

  return (
    <div className='admissions-inventory-tab-request-item'>
      <SearchInventoryFilter
        active={active}
        searchData={searchData}
        departmentID={departmentID}
        setSearchData={setSearchData}
      />

      <InventoryListTable
        active={active}
        setActive={setActive}
        searchData={searchData}
        departmentID={departmentID}
        setSearchData={setSearchData}
      />
    </div>
  );
};

export default RequestItem;
