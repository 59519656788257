/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// import components below
import FormUI from 'components/UI/FormUI';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
import { getPatient } from 'reducers/patientReducer';
import { useSelector } from 'react-redux';
import { ReactComponent as AddGroupSVG } from 'assets/svg/add-group.svg';
import { selectPatientData } from 'reducers/patientReducer';
import axios from 'axios';

// import assets below
import './Filter.scss';

// main component
const Filter = ({ setState }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectPatientData);

  const [searchData, setSearchData] = useState({
    search: '',
  });

  const handleChange = (key) => (e) => {
    if (e.target.type === 'checkbox') {
      setSearchData((prevState) => {
        return {
          ...prevState,
          [key]: e.target.checked,
        };
      });

      return;
    }

    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  return (
    <div className='filter'>
      <FormUI>
        {/* Basic Search */}
        <Row className='mb-3'>
          <Col>
            <h3 className='header'>Pharmacy Work List</h3>
          </Col>
          <Col>
            <Form.Group className='more-filters-cont'>
              <Form.Select>
                <option value='1-7th floor Satellite'>1-7th floor Satellite</option>
              </Form.Select>
              <Button>More Filters</Button>
            </Form.Group>
          </Col>
        </Row>

        <div className='header-line'></div>

        <Row>
          <Col sm={6}>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Search Patient Name or ID/MRN</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-white'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-white'
                  placeholder='Search Here...'
                  value={searchData.search}
                  onChange={handleChange('search')}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className='mb-3 mt-3 location-cont'>
              <Form.Label>Location</Form.Label>

              <Form.Select>
                <option value=''>- Select -</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className='mb-3 mt-3 category-buttons'>
              <Button
                onClick={() => {
                  // Update Data on Table?
                }}
                className='add-button'
                variant='primary'
                type='submit'
              >
                Go
              </Button>
              <Button
                onClick={() => {
                  // Update Data on Table?
                }}
                className='reset-button'
                variant='secondary'
                type='submit'
              >
                Reset
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default Filter;
