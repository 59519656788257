/* eslint-disable */
import { Modal } from 'react-bootstrap';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import AddNewForm from './components/Form';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showChartOfAccount, getChartOfAccounts } from 'reducers/accountingReducer';

const AddNew = ({ showModal, setShowModal }) => {
    const handleShow = () => setShowModal({ isShowForm: false });
    const dispatch = useDispatch();

    useEffect(() => {
        if (showModal.accountId) {
            dispatch(showChartOfAccount(showModal.accountId));
        }
    });

    return (
        <Modal 
            show={showModal.isShowForm} 
            size='lg' 
            className='add-room-charges-modal'
            onExit={() => dispatch(getChartOfAccounts())}
        >
            <Modal.Body>
                <div className='add-room-request'>
                    <div className='d-flex header'>
                        <p>Account</p>
                        <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
                    </div>
                </div>

                <AddNewForm
                    setShowModal={setShowModal}
                    accountId={showModal.accountId}
                    showModal={showModal}
                />
            </Modal.Body>
        </Modal>
    );
};

AddNew.defaultProps = {
    show: false,
    setShow: () => {},
};

AddNew.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
};

export default AddNew;
