/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getDepartmentInventory = createAsyncThunk(
  'department/inventory',
  async (department, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/department/inventory/${department?.id}?include[]=item${department?.search}&is_new=false`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getRestockItem = createAsyncThunk(
  'department/getRestockItem',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/restock/${id}?include[]=restock_items`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getRestockItems = createAsyncThunk(
  'department/getRestockItems/request',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/restock`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getDepartmentRequestList = createAsyncThunk(
  'department/inventory/request',
  async (department, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/restock?include[]=restock_items&restock_type=refill${department?.search}${department?.status}${department?.department}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getDepartmentMaintenanceRepairList = createAsyncThunk(
  'department/inventory/getDepartmentMaintenanceRepairList',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `/api/maintenance`,
      params: {
        ...params,
        include: ['item', 'department'],
      },
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getSingleRepairMaintenance = createAsyncThunk(
  'department/inventory/getSingleRepairMaintenance',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `/api/maintenance/${id}`,
      params: {
        include: ['item', 'department'],
      },
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getItems = createAsyncThunk(
  'department/inventory/items',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/items${params}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getNonMedicineItems = createAsyncThunk(
  'department/inventory/getNonMedicineItems',
  async (filter, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/items`,
      params: {
        is_new: 0,
        is_medicine: 0,
        per_page: 100000,
        include: ['group'],
      },
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getInventory = createAsyncThunk(
  'inventory/getInventory',
  async (filter = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `search`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      params: {
        MRN: filter?.MRN,
        first_name: filter?.first_name,
        middle_name: filter?.middle_name,
        last_name: filter?.last_name,
        suffix: filter?.suffix,
        date_of_birth: filter?.date_of_birth,
        sex: filter?.sex,
        inventory_status: filter?.inventory_status,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showInventory = createAsyncThunk(
  'inventory/showInventory',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `${id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      params: {},
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const submitRequestItem = createAsyncThunk(
  'department/inventory/submitRequestItem',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/restock`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getSingleRequestItem = createAsyncThunk(
  'department/inventory/getSingleRequestItemData',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/restock/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateRequestItem = createAsyncThunk(
  'department/inventory/updateRequestItem',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `api/restock/${params.id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params.payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteRequestItem = createAsyncThunk(
  'department/inventory/deleteRequestItem',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'delete',
      url: `api/restock/${params.id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params.payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const completeRequestItem = createAsyncThunk(
  'department/inventory/completeRequestItem',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/restock/${id}/completed`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const denyRequestItem = createAsyncThunk(
  'department/inventory/denyRequestItem',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/restock/${params?.id}/deny`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params.payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const itemRequestForRepairSubmit = createAsyncThunk(
  'department/inventory/itemRequestForRepairSubmit',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/maintenance`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const itemRequestForRepairUpdate = createAsyncThunk(
  'department/inventory/itemRequestForRepairUpdate',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/maintenance/${params?.id}`,
      baseURL: process.env.REACT_APP_API_BASE_INVENTORY,
      data: params?.params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const submitCreditMemorandum = createAsyncThunk(
  'department/inventory/submitCreditMemorandum',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `credit_memorandum/${params.memo_type}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
