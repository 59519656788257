/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkStringPermission } from 'helpers/filteredPermissions';

const ProtectedRoute = ({ children, department, component: Component }) => {
  const conditions = [
    {
      key: 'admission',
      permission: 'Can View Admission Module',
    },
    {
      key: 'doctor',
      permission: 'Can View Doctors Module',
    },
    {
      key: 'nurse',
      permission: 'Can View Nursing Module',
    },
    {
      key: 'roombed',
      permission: 'Can View Room & Bed Module',
    },
    {
      key: 'emergency',
      permission: 'Can View Emergency Department Module',
    },
    {
      key: 'outpatient',
      permission: 'Can View Outpatient Department Module',
    },
    {
      key: 'laboratory',
      permission: 'Can View Laboratory Module',
    },
    {
      key: 'billing',
      permission: 'Can View Billing Module',
    },
    {
      key: 'inventory',
      permission: 'Can View Inventory Module',
    },
    {
      key: 'medical records',
      permission: 'Can View Medical Records Module',
    },
    {
      key: 'philhealth',
      permission: 'Can View PhilHealth Module',
    },
    {
      key: 'report',
      permission: 'Can View Reports Module',
    },
    {
      key: 'acl',
      permission: 'Can View ACL Module',
    },
    {
      key: 'acl-permission',
      permission: 'Can View ACL Permission Module',
    },
    {
      key: 'employees',
      permission: 'Can View Employees Module',
    },
    {
      key: 'accounting',
      permission: 'Can View Accounting Module',
    },
    {
      key: 'audit',
      permission: 'Can View Audit Logs Module',
    },
    {
      key: 'attendance',
      permission: 'Can View Accounting Module',
      // permission: 'Can View Attendance Module',
    },
    {
      key: 'social-services',
      permission: 'Can View Social Services Module',
      // permission: 'Can View Attendance Module',
    },
  ];

  const selectedDepartment = conditions.filter((data) => data.key === department);

  if (selectedDepartment.length > 0) {
    const result = checkStringPermission(selectedDepartment[0]?.permission);

    if (result) {
      return <Component />;
    }

    return <Navigate to='/' />;
  }
};

export default ProtectedRoute;
