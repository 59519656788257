// import core & vendor packages below
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

// import components below
import CardUI from 'components/UI/CardUI';

// main component
const FilterCard = ({ object }) => {
  return (
    <div className='filterCard'>
      <CardUI>
        <div className='card'>
          <div className='card__body' style={{ background: object.color }}>
            <span>{object.value}</span>
            {object.icon}
          </div>
          <div className='card__footer' style={{ borderColor: object.color }}>
            <span>{object.title}</span>
            <Button>{object.arrow}</Button>
          </div>
        </div>
      </CardUI>
    </div>
  );
};

FilterCard.defaultProps = {
  object: {},
};

FilterCard.propTypes = {
  object: PropTypes.object,
};

export default FilterCard;
