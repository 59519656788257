/* eslint-disable */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import WaitingList from './WaitingList';
import ActiveRooms from './ActiveRooms';
import TabsUI from 'components/UI/TabsUI';
import BedManagement from './BedManagement';
import SearchPatient from './NewSearchPatient';
import RoomReservation from './RoomReservationList';
import InventoryPerDepartment from 'components/InventoryPerDepartment';

// main component
const AdmissionTabs = ({ onChange }) => {
  const tabs = [
    {
      title: 'Search Patient',
      component: SearchPatient,
    },
    {
      title: 'Room Requests',
      component: RoomReservation,
    },
    // {
    //   title: 'Active Rooms',
    //   component: ActiveRooms,
    // },
    // {
    //   title: 'Waiting List',
    //   component: WaitingList,
    // },
    {
      title: 'Bed Management',
      component: BedManagement,
    },
    {
      title: 'Inventory',
      component: InventoryPerDepartment,
    },
  ];

  return (
    <div className='admissionTabs'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title} onSelect={(eventKey) => onChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component departmentID={'1'} />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

AdmissionTabs.defaultProps = {
  onChange: () => { },
};

AdmissionTabs.propTypes = {
  onChange: PropTypes.func,
};

export default AdmissionTabs;
