/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

const UseItemList = (row, items) => {
  const restock_items = row?.relationships?.restock_items?.data;

  const newdata = restock_items.map(({ type, id }) => {
    return items?.find((data) => data?.type === type && data?.id === id);
  });
  const restockID = newdata.map((data) => {
    const { type, id } = data?.relationships?.item?.data[0];
    const newlist = items?.find((data) => data?.type === type && data?.id === id);

    return {
      ...newlist,
      status: data?.attributes?.status,
      restock_id: data?.id,
      quantity: data?.attributes?.quantity,
      remarks: data?.attributes?.remarks,
      delivered_quantity: data?.attributes?.delevered_quantity,
    };
  });
  return { restockID: restockID, itemID: row };
};

export default UseItemList;
