/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Modal, Button, Row, Col, Form, FormControl } from 'react-bootstrap';

// import assets below
import './index.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const AddPayrollModal = ({ openModal, setOpenModal }) => {
  const closeModal = () => {
    setOpenModal({
      data: '',
      isOpen: false,
    });
  };

  return (
    <Modal
      size='lg'
      centered
      onHide={closeModal}
      show={openModal?.isOpen}
      className='add-payroll-modal'
    >
      <Modal.Header>
        <h6>Add Payroll Period</h6>
        <Button onClick={closeModal}>
          <CloseSVG />
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className='main-content'>
          <h4>Payroll Details</h4>
          <Form.Group className='vertical-align'>
            <Form.Label>Payroll Description</Form.Label>

            <FormControl
              as={'textarea'}
              rows={'5'}
              placeholder='Enter Here'
              className='border-seven'
            />
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className='vertical-align'>
                <Form.Label>Payroll Type</Form.Label>

                <Form.Select>
                  <option value='' hidden>
                    - Select -
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className='vertical-align'>
                <Form.Label>Payroll Start Date</Form.Label>

                <FormControl type='date' placeholder='Enter Here' className='border-seven' />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className='vertical-align'>
                <Form.Label>Payroll End Date</Form.Label>

                <FormControl type='date' placeholder='Enter Here' className='border-seven' />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className='vertical-align'>
                <Form.Label>Payroll Finalization Date</Form.Label>

                <FormControl type='date' placeholder='Enter Here' className='border-seven' />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className='vertical-align'>
                <Form.Label>Payroll Finalization Type</Form.Label>

                <Form.Select>
                  <option value='' hidden>
                    - Select -
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={closeModal} className='cancel'>
          CANCEL
        </Button>

        <Button onClick={closeModal} className='save'>
          ADD NEW RECORD
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPayrollModal;
