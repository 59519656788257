/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal } from 'react-bootstrap';

import { deleteRequestReturn, selectReturn } from 'reducers/returnReducer';

import FormUI from 'components/UI/FormUI';

// Import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

const DeleteModal = ({ showModal, getDepartment, setShowModal }) => {
  const dispatch = useDispatch();

  // New Borrow Return
  const returnState = useSelector(selectReturn);
  const { deleteLogs } = returnState;

  // Handle POST api
  const handleSubmit = () => {
    dispatch(deleteRequestReturn(showModal?.data?.id));
  };

  const closeModal = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        data: null,
        deleteModal: false,
      };
    });
  };

  // Get Submit Data result
  useEffect(() => {
    const { isLoading, isFailed, isSuccess, error } = deleteLogs;

    if (isFailed) {
      toast.error(`${error[0]?.detail} (${error[0]?.code})`);
      console.log(error);
    }

    if (isSuccess) {
      closeModal();
      getDepartment();
      toast.success('Successfully deleted.');
    }
  }, [deleteLogs]);

  return (
    <Modal
      size='lg'
      show={showModal?.deleteModal}
      onHide={() => {
        closeModal();
      }}
      aria-labelledby='Request Item Modal'
      className='view-borrowed-item request-item'
    >
      <Modal.Body>
        <div className='view-borrowed-item-header'>
          <div className='d-flex header'>
            <p>DELETE REQUEST RETURN?</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={() => closeModal()} />
          </div>
        </div>

        <FormUI>
          <Form className='item-information-form'>
            <Row>
              <Col>
                <Form.Group className='buttons-cont-2'>
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    variant='primary'
                    className='add-button'
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => {
                      closeModal();
                    }}
                    className='clear-button'
                    variant='secondary'
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
