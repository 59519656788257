/* eslint-disable react/jsx-indent-props */
import TableUI from 'components/UI/TableUI';
import { FormControl, InputGroup, Table } from 'react-bootstrap';

import './DeathTable.scss';

const MajorOperationTable = (props) => {
    const { results } = props;

    return (<div className='mt-3 major-operation'>
        <TableUI>
            <Table striped hover style={{ width: '80%' }} className='mt-3'>
                <thead>
                    <tr>
                        <th colSpan='2'>10 Leading Major Operations (EXCLUDING CAESARIAN SECTIONS)</th>
                        <th>Number</th>
                    </tr>
                </thead>
                <tbody>
                    {results?.map((item, index) => {
                        return (<tr key={index + 1}>
                            <td>{index + 1}. {item.proc_desc}</td>
                            <td><InputGroup className='mb-1 mt-1'>
                                <FormControl
                                    placeholder='Enter Here'
                                    disabled
                                    value={item.total}
                                />
                            </InputGroup></td>
                            <td><InputGroup className='mb-1 mt-1'>
                                <FormControl
                                    placeholder='Enter Here'
                                    disabled
                                    value={item.ctp_code}
                                />
                            </InputGroup></td>
                        </tr>);
                    })}
                </tbody>
            </Table>
        </TableUI>

    </div>);
};

export default MajorOperationTable;
