/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { patientDisbursementStatus } from 'helpers';
import Table from 'components/ReactTable';

// import components below
import moment from 'moment';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './Table.scss';
import {
  getDisbursedPatient,
  getDisbursementPatient,
  selectBillingData,
  selectDisbursementPatientData,
} from 'reducers/billingReducer';

// main component
const PatientDisbursementTable = (props) => {
  const { setShowProfile, setState, setShowModal, setProfile } = props;

  // eslint-disable-next-line prefer-const
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const EditProfile = (item) => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        profileId: item?.id,
      };
    });

    dispatch(showPatient(item.id));
    navigate('/dashboard/outpatient-department/edit-patient-profile', { replace: true });
  };

  const state = useSelector(selectDisbursementPatientData);

  const viewDischarge = (id) => {
    setShowProfile({ isShowView: true, profileId: id });
  };

  const viewBilling = (id, billingId) =>
    setShowProfile({ isShowView: true, profileId: id, billingId: billingId });

  useEffect(() => {
    // console.log('TABLE', state);
  }, [state]);

  const tableColumns = useMemo(
    () => [
      { Header: 'Medical Discharge Date/Time', accessor: 'createdAt' },
      { Header: 'Patient ID', accessor: 'id' },
      { Header: 'Patient Name', accessor: 'full_name' },
      { Header: 'Patient Class', accessor: 'patient_class' },
      { Header: 'Status', accessor: 'patient_status' },
      { Header: 'Location', accessor: 'location' },
    ],
    [],
  );

  const tableData = useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.patientId,
        mrn: dd?.MRN,
        full_name: dd?.full_name,
        patient_class: dd?.patient_origin,
        location: dd?.location ? dd?.location : '-',
        patient_status: patientDisbursementStatus(dd?.patient_status),
        createdAt: moment(dd?.createdAt).format('MMM-DD-YYYY hh:mm A'),
        status: patientDisbursementStatus(dd?.patient_status),
      })),
    [state],
  );
  const handleDisbursementModal = (id) => {
    setProfile({ id: id });
    setShowModal(true);
  };

  return (
    <div className='patientDisbursementTable'>
      <Table
        data={tableData}
        columns={tableColumns}
        customAction={(id) => {
          const row = state.find((dd) => dd.patientId === id);
          return (
            <Button
              className='btn-disburse'
              onClick={() => handleDisbursementModal(id)}
              // for changes depends on api
              disabled={status === 'for_discharge'}
            >
              Disburse
            </Button>
          );
        }}
        pagination={['BOTTOM']}
        // logs={logs}
      />
    </div>
  );
};

export default PatientDisbursementTable;
