/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  FormControl,
  Pagination,
  Table,
  InputGroup,
} from 'react-bootstrap';

// components below
import LabResultTable from './Table';
import LabResultModal from './ViewModal';
import FormUI from 'components/UI/FormUI';

// CSS
import './style.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const LabResult = () => {
  const [patientData, setPatientData] = useState({
    name: '',
    age: '',
    sex: '',
    doctors: [],
    location: '',
    labRequests: [],
  });
  const [state, setState] = useState([]);
  const [requests, setRequests] = useState([]);
  const [showModal, setShowModal] = useState({
    isShowView: false,
    profileData: null,
  });
  const [userData, setUserData] = useState({
    patient_name: '',
    location: '',
    doctor: '',
  });

  const patientList = useSelector((state) => state.patient.data);

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const [searchedWord, setSearchedWord] = useState('');
  const [searchedWordPatient, setSearchedWordPatient] = useState('');

  /** filter by doctor's name */
  const onChangeDoctorHandler = (event) => {
    setSearchedWord(event.target.value);
    const searchedWord = event.target.value;
    const newFilter = patientList.filter((value) => {
      return value.user.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patientList);
    }
  };

  /** filter by patient's name */
  const onChangePatientHandler = (event) => {
    setSearchedWordPatient(event.target.value);
    // const searchedWordPatient = event.target.value;
    /* const newFilter = requests.filter((value) => {
      return value.patient.full_name.toLowerCase().includes(searchedWordPatient.toLowerCase());
    });

    if (searchedWordPatient !== '') {
      setRequests(newFilter);
      console.log('new filtered result: ', newFilter);
    } else {
      setRequests(requests);
      console.log('all result: ', requests);
    } */
  };

  const searchPatientLabData = (data) => {
    return (
      data.filter((info) =>
        info?.patient?.full_name.toLowerCase().includes(searchedWordPatient.toLowerCase()),
      ) ?? data
    );
  };

  const resetFilter = () => {
    setSearchedWord('');
    setSearchedWordPatient('');
  };

  useEffect(() => {
    axios
      .get('/requisition/laboratory')
      .then((response) => {
        setRequests(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    // console.log('this is the requisition laboratory api data list: ', requests);
    
    // console.log('this is the patient api data list: ', patientList);
  }, []);

  return (
    <>
      <FormUI>
        <div className='lab-result-assessment mt-4'>
          <Form>
            <Row className='mb-4'>
              <Col xs={3}>
                <Form.Group className='basic-search'>
                  <Form.Label>Patient Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text className='bg-white'>
                      <MagnifyingGlassSVG />
                    </InputGroup.Text>
                    <FormControl
                      type='search'
                      className='bg-white'
                      placeholder='Enter Here'
                      onChange={onChangePatientHandler}
                      value={searchedWordPatient}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className=''>
                  <Form.Label>Doctor</Form.Label>
                  <FormControl
                    type='search'
                    className='bg-white'
                    placeholder='Enter Here'
                    onChange={onChangeDoctorHandler}
                    value={searchedWord}
                  />
                </Form.Group>
              </Col>
              {/* <Col>
                <Form.Group className=''>
                  <Form.Label>Location</Form.Label>
                  <Form.Select
                    aria-label='Location'
                    value={userData.location}
                    onChange={handleChange('location')}
                  >
                    <option value='' hidden>
                      - Select -
                    </option>
                    <option value='1'>One</option>
                    <option value='2'>Two</option>
                    <option value='3'>Three</option>
                  </Form.Select>
                </Form.Group>
              </Col> */}
              <Col>
                <Form.Group className='category-buttons'>
                  <Button onClick={resetFilter} className='add-button' variant='secondary'>
                    Reset
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>

          <h2 className='mb-3'>List of Result</h2>

          {/* Table */}
          <LabResultTable
            showModal={showModal}
            setShowModal={setShowModal}
            state={requests}
            setState={setState}
            setPatientData={setPatientData}
            searchPatientLabData={searchPatientLabData}
          />
        </div>
      </FormUI>

      <LabResultModal
        showModal={showModal}
        setShowModal={setShowModal}
        patientData={patientData}
        setPatientData={setPatientData}
      />
    </>
  );
};

export default LabResult;
