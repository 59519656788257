// import core & vendor packages below
import { Link } from 'react-router-dom';

// import components below
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import './Card.scss';

// main component
const DashboardCard = (props) => {
  const { link, svg, title, permission, disabled } = props;
  const result = checkStringPermission(permission);

  if (!result) {
    return null;
  }

  const cardClass = disabled ? 'dashboardCard disabled' : 'dashboardCard';

  return (
    <div className={cardClass} data-toggle='tooltip' data-placement='top' title={title}>
      {svg}
      <h5>{title}</h5>
      {disabled ? null : <Link to={link}></Link>}
    </div>
  );
};

export default DashboardCard;
