/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, FormControl, Row, Col, Table } from 'react-bootstrap';

// components
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

import {
  submitCreditMemorandum,
  selectDepartmentInventory,
} from 'reducers/departmentInventoryReducer';

// import assets below
import './CreditMemorandum.scss';
import { toast } from 'react-toastify';
import { ReactComponent as CircleXMark } from '../../../../assets/svg/circle-xmark.svg';
import { ReactComponent as Calendar } from '../../../../assets/svg/calendar-alt.svg';
import TableUI from '../../../UI/TableUI';

// main component
const CreditMemorandum = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const patientProfile = useSelector(selectPatientItem);
  const inventoryState = useSelector(selectDepartmentInventory);

  const { memorandumLogs } = inventoryState;

  const [formData, setFormData] = useState({
    amount: '',
    remarks: '',
  });

  const handleChange = (type, e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [type]: e.target.value,
      };
    });
  };

  const closeTheModal = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        credit_memorandum: false,
        rowData: null,
        id: null,
      };
    });
  };

  const handleSubmit = () => {
    const { lab_type, id } = showModal.rowData;

    const params = {
      type: lab_type,
      amount: formData?.amount,
      remarks: formData?.remarks,
      laboratoryRequestId: id,
      requestProcedureId: id,
      memo_type: showModal?.type,
    };

    dispatch(submitCreditMemorandum(params));
    dispatch(showPatient(patientProfile.id));
  };

  useEffect(() => {
    const { isSuccess, isLoading, isFailed } = memorandumLogs;

    if (isSuccess) {
      toast.success('Successfully created credit memorandum');
      setFormData({
        amount: '',
        remarks: '',
      });
      closeTheModal();
    }
  }, [memorandumLogs]);

  return (
    <Modal
      size={'lg'}
      className='credit-memorandum-modal'
      show={showModal?.credit_memorandum}
      onHide={() => {
        closeTheModal();
      }}
      centered
    >
      <Modal.Header>
        <div className='add-procedure-request'>
          <div className='d-flex header'>
            <p>Credit Credit Memorandum</p>
            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                closeTheModal();
              }}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row className='mb-3 row-border'>
          <Col>
            <p className='mb-2'>Patient Details</p>
            <p className='mb-2'>
              Patient ID: <span>{patientProfile?.MRN}</span>
            </p>
            <p className='mb-0'>
              Patient Name: <span>{patientProfile?.full_name}</span>
            </p>
          </Col>
          {/* <Col>
            <p className='mb-2'>Date Confinement</p>
            <p className='mb-2'>
              From:{' '}
              <span>
                {' '}
                <Calendar />{' '}
                {moment(showModal?.attributes?.createdAt).format('MMM-DD-YYYY hh:mm A')}
              </span>
            </p>
            <p className='mb-0'>
              To:{' '}
              <span>
                {' '}
                <Calendar />{' '}
                {moment(showModal?.attributes?.updatedAt).format('MMM-DD-YYYY hh:mm A')}
              </span>
            </p>
          </Col> */}
        </Row>
        <Row align='center' justify='center'>
          <h5>ORDER ITEM DETAILS</h5>
          <Col className='p-0'>
            <TableUI>
              <Table className='medicationsTable'>
                <thead>
                  <tr>
                    <th>
                      <p>Description</p>
                    </th>
                    <th>
                      <p>Price</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {showModal?.type === 'laboratory'
                        ? showModal?.rowData?.lab_type
                        : showModal?.rowData?.procedure}
                    </td>
                    <td>{showModal?.rowData?.price}</td>
                  </tr>
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col className='p-0'>
            <div className='mb-4 fields'>
              <Form.Group className='mb-3 amount-form'>
                <div>
                  <Form.Label className='label'>Amount</Form.Label>
                  <FormControl
                    type='number'
                    className='bg-white'
                    placeholder='Enter Amount'
                    readOnly
                    value={showModal?.rowData?.price}
                    onChange={(e) => handleChange('amount', e)}
                  />
                </div>
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label className='label'>Remarks</Form.Label>
                <FormControl
                  required
                  as='textarea'
                  className='bg-white'
                  placeholder='Enter Remarks'
                  onChange={(e) => handleChange('remarks', e)}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className='buttons'>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            className='primary'
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              closeTheModal();
            }}
            className='cancel'
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

CreditMemorandum.defaultProps = {
  openModal: false,
  closeModal: () => {},
};

CreditMemorandum.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default CreditMemorandum;
