/* eslint-disable camelcase */
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const ActivitiesList = ({ data }) => {
  return (
    <div>
      {data?.map(({ id, field, cash_in, cash_out }) => {
        return (
          <Row className='mb-4' key={id}>
            <h6 className='mb-2'>{field}</h6>
            <Row>
              <Col xs={6} md={4} className='asset-name'>
                Cash In
              </Col>
              <Col md={1} className='asset-amount'>
                {cash_in.toLocaleString()}
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4} className='asset-name'>
                Cash Out
              </Col>
              <Col md={1} className='asset-amount'>
                {cash_out.toLocaleString()}
              </Col>
            </Row>
          </Row>
        );
      })}
    </div>
  );
};

export default ActivitiesList;
