/* eslint-disable camelcase */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import InventoryFilterTable from './Table';

// import assets below
import './Filter.scss';

// main component
const InventoryFilter = ({ setState, setShowProfile, state, showProfile }) => {
  return (
    <div className='InventoryFilter'>
      <FormUI>
        <Row>
          <Col>
            <InventoryFilterTable
              state={state}
              setState={setState}
              showProfile={showProfile}
              setShowProfile={setShowProfile}
            />
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

InventoryFilter.defaultProps = {
  setState: () => {},
  setShowProfile: () => {},
};

InventoryFilter.propTypes = {
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default InventoryFilter;
