/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const ModalDietType = ({ showProfile, setShowProfile }) => {
  const [stateData, setStateData] = useState({
    dietType: '',
  });

  const handleChange = (key) => (e) => {
    setStateData({
      ...stateData,
      [key]: e.target.value,
    });
  };

  const handleSubmit = () => {
    const { noteType } = stateData;
  };

  useEffect(() => {
    // console.log(stateData);
  }, [stateData]);

  return (
    <Modal
      size='lg'
      show={showProfile.dietType}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            dietType: false,
          };
        })
      }
      aria-labelledby='Add Lab Request Modal'
      className='add-lab-request-modal'
    >
      <Modal.Body>
        <div className='add-lab-request'>
          <div className='d-flex header'>
            <p>Diet Type</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowProfile((prevState) => {
                  return { ...prevState, dietType: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='lab-request-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Diet Type</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={3}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={stateData.dietType}
                    onChange={handleChange('dietType')}
                  />
                </Form.Group>
              </Col>
            </Row>
           
            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button className='add-button' variant='primary' onClick={handleSubmit}>
                    Add
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() =>
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          dietType: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ModalDietType.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

ModalDietType.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default ModalDietType;
