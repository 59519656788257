/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Helpers
import { calculateDayDiff, patientStatus, currentLocation } from 'helpers';

// import components below
import Table from 'components/ReactTable';
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
// import './Table.scss';

// main component
const SearchPatientListTable = ({ state, setState, setShowProfile }) => {
  const data = useSelector(selectPatientData);

  useEffect(() => {
    axios
      .get('https://6628c56954afcabd0736dffc.mockapi.io/Dummydata')
      .then((response) => {

        console.log('response', response.data)

        // const data = response.data.data.filter((item, key) => {
          const data = response.data.filter((item, key) => {
          // console.log(data, 'data')
            if (item?.bills?.length > 0) {
                return item;
            }
        });


        setState(response.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'MRN', accessor: 'mrn' },
      { Header: 'Date Time', accessor: 'datetime' },
      { Header: 'Information', accessor: 'information' },
      { Header: 'User Name', accessor: 'user_full_name' },
      { Header: 'Patient Name', accessor: 'patient_full_name' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        mrn: dd?.MRN,
        user_full_name: dd?.Username,
        patient_full_name: dd?.Patient,
        datetime: moment(dd?.createdAt).format('MMMM-DD-YYYY hh:mm A'),
        information: dd?.Details + ' - ' + moment(dd?.createdAt).format('lll'),
      })),
    [state],
  );

  const handleTableAction = (eventKey, id, billId) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      profile: () => {
        setShowProfile({
          isShowProfile: true,
          profileId: row?.id,
          billId: row?.bills[0]?.id,
          admissionDays: calculateDayDiff(row?.admission_date),
        });
      },
      view: () => {},
      edit: () => {},
      delete: () => {},
    };

    action[eventKey]();
  };

  useEffect(() => {
    console.log('state', state);
  }, [state]);

  return (
    <div className='searchPatientListTable'>
      <Table
        data={tableData}
        columns={tableColumns}
        pagination={['BOTTOM']}
        onAction={handleTableAction}
        // actions={{ hasProfile: true }}
      />
    </div>
  );
};

export default SearchPatientListTable;
