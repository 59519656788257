/* eslint-disable */
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { clearAll } from 'utils/storage';
import { updateProfile, login } from './thunks/authThunks';
import { setAndStringify, setItem, getItem, getParsedItem } from 'utils/storage';

const initialState = {
  token: getItem('accessToken') ?? null,
  isAuthenticated: getParsedItem('isAuthenticated') ?? false,
  isLoading: false,
  permissions: getParsedItem('permissions') ?? [],
  userInfo: getParsedItem('userinfo') ?? null,

  updateProfileLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state) => {
      state.isAuthenticated = true;
      setItem('isAuthenticated', true);
    },
    logout: (state) => {
      state.token = null;
      clearAll();
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.permissions = action.payload.permissions;
      state.token = action.payload.token;
      state.userInfo = action.payload.user;

      // Set Data to sessionStorage
      setItem('accessToken', action.payload.token);
      setAndStringify('userinfo', action.payload.user);
      setAndStringify('permissions', action.payload.permissions);
    },
    [login.pending]: (state) => {
      state.isLoading = true;
    },
    [login.rejected]: (state) => {
      state.token = null;
      state.isLoading = false;
      toast.error('Login Failed');
    },

    // For Update User
    [updateProfile.fulfilled]: (state, action) => {
      state.updateProfileLogs.isSuccess = true;
      state.updateProfileLogs.isLoading = false;

      const newData = {
        ...action.payload.data.attributes,
        id: action.payload.data.id,
      };

      state.userInfo = newData;
      setAndStringify('userinfo', newData);
    },
    [updateProfile.pending]: (state) => {
      state.updateProfileLogs.isSuccess = false;
      state.updateProfileLogs.isFailed = false;
      state.updateProfileLogs.error = '';
      state.updateProfileLogs.isLoading = true;
    },
    [updateProfile.rejected]: (state) => {
      state.updateProfileLogs.isLoading = false;
      state.updateProfileLogs.isFailed = true;
    },
  },
});

export const { actions: authActions, reducer: authReducer } = slice;

export const { logout, setIsAuthenticated } = authActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.auth || initialState;

export const selectAuth = createSelector([selectDomain], (authState) => authState);

export const selectToken = createSelector([selectDomain], (authState) => authState.token);

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  (authState) => authState.isAuthenticated,
);

export const selectIsLoading = createSelector([selectDomain], (authState) => authState.isLoading);

/* --------------------------------------------------------------------------------- */

export { login };
