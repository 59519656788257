/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';

// import assets below
import './Filter.scss';

// main component
const InventoryFilter = ({ setState, setSearch, search, setShowProfile, getInventoryItems }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;

    setSearch((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleReset = () => {
    setSearch({
      q: '',
      type: '',
    });

    getInventoryItems(true);
  };

  const handleSearch = () => {
    getInventoryItems();
  };

  const handlePriceLogs = () => {
    setShowProfile((prev) => ({ ...prev, priceLogs: true }));
  };

  return (
    <div className='InventoryFilter'>
      <FormUI>
        <Row className='mb-4'>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Item Code / Name</Form.Label>
              <FormControl
                type='text'
                name='q'
                value={search?.q}
                onChange={handleChange}
                className='bg-white'
                placeholder='Search Here'
              />
            </Form.Group>
          </Col>
          <Col sm={2}>
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Select name='type' value={search?.type} onChange={handleChange}>
                <option value='' hidden>
                  Select Item Type
                </option>
                <option value='1'>Medicine</option>
                <option value='0'>Equipment</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <div className='last-col'>
              <div className='buttonWrapper'>
                <Button variant='search' onClick={handleSearch}>
                  APPLY
                </Button>

                <Button variant='clear' onClick={handleReset}>
                  RESET
                </Button>
              </div>

              <div className='price-log-wrapper'>
                <Button variant='secondary' onClick={handlePriceLogs}>
                  PRICE LOGS
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

InventoryFilter.defaultProps = {
  setState: () => {},
};

InventoryFilter.propTypes = {
  setState: PropTypes.func,
};

export default InventoryFilter;
