/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */

// import core & vendor packages below
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getPatient } from 'reducers/patientReducer';
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const Filter = ({ setState }) => {
  const dispatch = useDispatch();

  const [patients, setPatients] = useState([]);

  const [word, setWord] = useState('');
  const [id, setId] = useState('');
  const [MRN, setMRN] = useState('');
  const [dateAdmitted, setDateAdmitted] = useState('');

  // search by registered date / readmit date
  const onChangeHandlerDateAdmitted = (event) => {
    const searchedWord = event.target.value;
    setDateAdmitted(event.target.value);

    const newFilter = patients.filter((value) => {
      return value.history_logs[0].createdAt.includes(searchedWord);
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  // Search Patient by name
  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    setWord(event.target.value);
    const newFilter = patients.filter((value) => {
      return value.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  // Search patient by ID
  const onChangeHandlerID = (event) => {
    const searchedWord = event.target.value;
    setId(event.target.value);
    const newFilter = patients.filter((value) => {
      return String(value.id) === String(searchedWord);
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  // search by patient MRN
  const onChangeHandlerMRN = (event) => {
    const searchedWord = event.target.value;
    setMRN(event.target.value);
    const newFilter = patients.filter((value) => {
      return value.MRN.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  const resetFilter = () => {
    setState(patients);
    setWord('');
    setId('');
    setMRN('');
    setDateAdmitted('');
  };

  const data = useSelector(selectPatientData);

  const [filter, setFilter] = useState({
    MRN: '',
    full_name: '',
    patient_status: '',
    admission_date: '',
  });

  const handleChange = (key) => (e) => {
    setFilter({
      ...filter,
      [key]: e.target.value,
    });
  };

  useEffect(() => {
    dispatch(getPatient());

    axios
      .get('/patient')
      .then((response) => {
        setPatients(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='Filter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Basic Search</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  placeholder='Search via MRN/PIN'
                  value={filter.keyword}
                  onChange={onChangeHandlerMRN}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Accordion className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Advance Search</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={2}>
                      <Form.Group className='mb-4'>
                        <Form.Label>MRN / PIN</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Enter Here'
                          value={MRN}
                          onChange={onChangeHandlerMRN}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Patient Name</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Enter Patient Name'
                          value={word}
                          onChange={onChangeHandler}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Visit Date</Form.Label>
                        <FormControl
                          type='date'
                          className='bg-white'
                          placeholder='Enter Patient Name'
                          value={dateAdmitted}
                          onChange={onChangeHandlerDateAdmitted}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <div className='buttonWrapper'>
                      <Button variant='clear' onClick={() => resetFilter()}>
                        CLEAR
                      </Button>
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default Filter;
