import { useState } from 'react';

// main components below
import Filter from './components/Filter';
import ModalAddDiagnosis from './AddDiagnosis';
import DiagnosisTable from './components/Table';
import ModalViewDiagnosis from './ViewDiagnosis';
import ViewDiagnosisListTable from './components/TableShow';

// main component
const Diagnosis = () => {
  // eslint-disable-next-line no-unused-vars
  const [showProfile, setShowProfile] = useState({
    showView: false,
    profileId: null,
    viewModal: false,
    isShowDiagnosis: false,
  });

  const [basicSearch, setBasicSearch] = useState('');
  const [advanceSearch, setAdvanceSearch] = useState({
    pin: '',
    patient: '',
    createdDate: '',
  });

  return (
    <>
      <div className='diagnosis'>
        <Filter
          basicSearch={basicSearch}
          advanceSearch={advanceSearch}
          setBasicSearch={setBasicSearch}
          setAdvanceSearch={setAdvanceSearch}
        />

        <div className='diagnosis'>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='fw-bold'>Patient List</h5>
          </div>

          {!showProfile.showView ? (
            <DiagnosisTable
              basicSearch={basicSearch}
              advanceSearch={advanceSearch}
              setShowProfile={setShowProfile}
            />
          ) : (
            <ViewDiagnosisListTable setShowProfile={setShowProfile} />
          )}
        </div>
      </div>

      <ModalAddDiagnosis setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalViewDiagnosis setShowProfile={setShowProfile} showProfile={showProfile} />
    </>
  );
};

export default Diagnosis;
