import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row } from 'react-bootstrap';

// import components below
import Layout from 'components/Layout';
import DoctorTabs from '../Doctors/components/Tabs';
import AdmissionHeader from '../Doctors/components/PageHeader';

// Redux & APIs
import { getProcedures } from 'reducers/notificationReducer';

// import assets below
import './style.scss';

// main component
const Doctors = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('Search Patient');

  const changeActiveKey = (data) => {
    dispatch(getProcedures({}));

    setActiveTab(data);
  };

  useEffect(() => {
    dispatch(getProcedures({}));
  }, []);

  return (
    <Layout pageTitle='Doctor'>
      <AdmissionHeader active={activeTab} />

      <Container fluid>
        <Row>
          <DoctorTabs onChange={changeActiveKey} />
        </Row>
      </Container>
    </Layout>
  );
};

export default Doctors;
