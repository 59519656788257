/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/ReactTable';
import axios from 'axios';

// import components below
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const CaseHistoryActionTable = ({ setShowModal, data, filter, filteredData }) => {
  // const data = useSelector(selectPatientData);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Case ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Physician Name', accessor: 'physician_name' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Date', accessor: 'date' },
    ],
    [],
  );

  const displayedData =
    !filter.physician_name && !filter.case_id && !filter.date ? data : filteredData;

  const tableData = React.useMemo(
    () =>
      displayedData?.map((dd) => ({
        id: dd?.id,
        physician_name: dd?.physician_name,
        name: dd?.full_name,
        date: dd?.admission_date,
      })),
    [data, filteredData],
  );

  const handleTableAction = (eventKey, id) => {
    const row = data.find((dd) => dd.id === id);
    const action = {
      profile: () => {},
      view: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            viewRecordModal: true,
            profileData: row,
            profileId: row?.id,
          };
        });
      },
      edit: () => {},
      delete: () => {},
    };

    action[eventKey]();
  };

  return (
    <div className='nursing-case-history-action-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasView: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default CaseHistoryActionTable;
