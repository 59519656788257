/* eslint-disable no-unused-vars */
import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import SocialHeader from './components/PageHeader';
import SocialTabs from './components/Tabs';

// import assets below
import './style.scss';

// main component
const SocialServices = () => {
  const [activeTab, setActiveTab] = useState('Search Patient');
  return (
    <Layout pageTitle='Social Services'>
      <SocialHeader active={activeTab} />
      <SocialTabs onChange={setActiveTab} />
    </Layout>
  );
};

export default SocialServices;
