/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// import assets below
import './style.scss';
import FormUI from 'components/UI/FormUI';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const ModalViewDiagnosis = ({ showProfile, setShowProfile }) => {
  const [userData, setUserData] = useState({
    ICD: '',
    final_diagnosis: '',
    diagnosis_type: '',
    diagnosis: '',
    remarks: '',
  });

  useEffect(() => {
    // console.log('data', userData);
  }, [userData]);

  const handleChange = (key) => (e) => {
    if (e.target.type === 'radio') {
      setUserData({
        ...userData,
        [key]: e.target.value,
      });

      return;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  // Submit data here
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    // console.log(userData);
  }, [userData]);

  return (
    <Modal
      size='lg'
      show={showProfile.viewModal}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            viewModal: false,
          };
        })
      }
      aria-labelledby='Patient Diagnosis'
      className='patient-diagnosis-modal'
    >
      <Modal.Body>
        <div className='patient-diagnosis-request'>
          <div className='d-flex header'>
            <p>PATIENT DIAGNOSIS</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowProfile((prevState) => {
                  return { ...prevState, viewModal: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>ICD - 10 Codes:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.ICD}
                    onChange={handleChange('ICD')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Final Diagnosis:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.final_diagnosis}
                    onChange={handleChange('final_diagnosis')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Diagnosis Type:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.diagnosis_type}
                    onChange={handleChange('diagnosis_type')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col>
                <Form.Group className='mb-2 line-group'>
                  <Form.Label>Diagnosis:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.diagnosis}
                    onChange={handleChange('diagnosis')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Col>
                <Form.Group className='mb-2 line-group'>
                  <Form.Label>Remarks:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey no-border'
                    placeholder='Details Here'
                    value={userData.remarks}
                    onChange={handleChange('remarks')}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ModalViewDiagnosis.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

ModalViewDiagnosis.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default ModalViewDiagnosis;
