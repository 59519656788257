/* eslint-disable */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import ReturnModal from './ReturnModal';
import Table from 'components/ReactTable';
import seturlParams from 'helpers/seturlParams';
import {
  getDepartmentInventory,
  selectDepartmentInventory,
} from 'reducers/departmentInventoryReducer';

// Assets below
import './Table.scss';
import ViewItemsModal from './ViewItemsModal';

// main component
const InventoryListTable = ({ searchData, departmentID }) => {
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectDepartmentInventory);
  const { data: inventoryData, items: inventoryItems, logs } = inventoryState;

  const [showModal, setShowModal] = useState({
    itemId: '',
    view: false,
    itemData: {
      data: {},
      items: null,
    },
    returnItem: false,
  });

  const getGroupName = (id) => {
    const selectedArray = inventoryItems.filter(
      (data) => parseInt(data.id) === parseInt(id) && data?.type === 'groups',
    );

    if (selectedArray.length === 0) {
      return 'N/A';
    }

    return selectedArray[0]?.attributes?.name;
  };

  const getPrice = (item) => {
    const { unit_of_measure, piece_price, box_price } = item?.attributes;

    if (unit_of_measure === 'piece') {
      return piece_price;
    }

    if (unit_of_measure === 'box') {
      return box_price;
    }
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Item Code', accessor: 'item_code' },
      { Header: 'Item Name', accessor: 'item_name' },
      { Header: 'Item Group', accessor: 'item_group' },
      { Header: 'Generic Name', accessor: 'generic_name' },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Unit Price', accessor: 'unit_price' },
      { Header: 'Piece Quantity', accessor: 'piece_quantity' },
      { Header: 'Box Quantity', accessor: 'box_quantity' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      inventoryData?.map((dd) => {
        const item = inventoryItems.filter(
          (d) => parseInt(d.id) === parseInt(dd?.attributes?.item_id) && d.type === 'items',
        );
        const selectedItem = item[0];

        return {
          id: dd?.id,
          selectedItem: selectedItem,
          item_id: dd?.attributes?.item_id,
          unit_price: getPrice(selectedItem),
          item_code: selectedItem?.attributes?.code,
          item_name: selectedItem?.attributes?.name,
          description: selectedItem?.attributes?.description,
          box_quantity: selectedItem?.attributes?.box_quantity,
          generic_name: selectedItem?.attributes?.generic_name,
          piece_quantity: selectedItem?.attributes?.piece_quantity,
          item_group: getGroupName(selectedItem?.attributes?.group_id),
        };
      }),
    [inventoryData],
  );

  const handleTableAction = (eventKey, id, data) => {
    const row = {
      data: inventoryData.find((dd) => parseInt(dd.id) === parseInt(id)),
      items: inventoryItems.find(
        (dd) => parseInt(dd.id) === parseInt(data?.item_id) && dd?.type === 'items',
      ),
    };

    const action = {
      view: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            view: true,
            itemData: row,
            itemId: id,
          };
        });
      },
      edit: () => {},
      remove: () => {},
      profile: () => {},
      complete: () => {},
    };

    action[eventKey]();
  };

  const getDepartment = () => {
    dispatch(
      getDepartmentInventory({
        id: departmentID,
        per_page: 100000,
        search: seturlParams(searchData?.search, 'q'),
      }),
    );
  };

  runOnFirstRender(getDepartment);

  return (
    <>
      <div className='admissions-inventory-list-table'>
        <Row>
          <Col className='table-header'>
            <h4 className='mb-0 fw-bold mt-4 mb-2'>Department Inventory List</h4>

            <div className='table-buttons'>
              <Button
                onClick={() => {
                  setShowModal((prev) => {
                    return {
                      ...prev,
                      returnItem: true,
                    };
                  });
                }}
                className='return-button'
              >
                Return Items
              </Button>
              <Button className='print-button'>Print</Button>
            </div>
          </Col>
        </Row>
        <Table
          logs={logs}
          data={tableData}
          columns={tableColumns}
          customAction={(id, data) => (
            <span
              onClick={() => handleTableAction('view', id, data)}
              className='badge blue'
              style={{ cursor: 'pointer' }}
            >
              View
            </span>
          )}
          pagination={['BOTTOM']}
        />
      </div>

      <ReturnModal
        showModal={showModal}
        setShowModal={setShowModal}
        departmentID={departmentID}
        getDepartment={getDepartment}
      />
      <ViewItemsModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

const runOnFirstRender = (getDepartment) => {
  useEffect(() => {
    getDepartment();
  }, []);
};

InventoryListTable.defaultProps = {
  searchData: {},
  departmentID: '',
};

InventoryListTable.propTypes = {
  searchData: PropTypes.object,
  departmentID: PropTypes.string,
};

export default InventoryListTable;
