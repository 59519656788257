/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages 
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// import components 
import FormUI from 'components/UI/FormUI';

// import assets
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const DenyRequestModal = ({ showProfile, setShowProfile, denyRequestedItemHandler }) => {
  const [remark, setRemark] = useState('');
  return (
    <Modal
      size='lg'
      show={showProfile.isShowDenyItemModal}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isShowDenyItemModal: false,
            isShowViewRequestModal: true,
          };
        })
      }
      aria-labelledby='CONFIRMATION'
      className='adjustment-modal'
    >
      <Modal.Body>
        <div className='adjustment-request'>
          <div className='d-flex header'>
            <p>REJECT ITEM</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, isShowDenyItemModal: false, isShowViewRequestModal: true };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='adjustment-form'>
            <Row>
              <Col>
                <div className='receiving-deny'>
                  <p>Remarks</p>

                  <Row className='w-100 mt-4'>
                    <Col sm={12}>
                      <Form.Group className='mb-4'>
                        <FormControl
                          as='textarea'
                          className='bg-white'
                          placeholder='Enter Here'
                          onChange={(e) => setRemark(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className='d-flex justify-content-end'>
                <Form.Group className='mt-2 buttons-cont'>
                  <Button
                    className={'green'}
                    variant='primary'
                    onClick={() => {
                      denyRequestedItemHandler(remark);
                      setRemark('');
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          isShowDenyItemModal: false,
                          isShowViewRequestModal: true,
                        };
                      });
                    }}
                  >
                    SAVE
                  </Button>
                  <Button
                    className='grey'
                    onClick={() => {
                      setRemark('');
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          isShowDenyItemModal: false,
                          isShowViewRequestModal: true,
                        };
                      });
                    }}
                  >
                    CANCEL
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

DenyRequestModal.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

DenyRequestModal.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default DenyRequestModal;
