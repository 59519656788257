/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-key */
/* eslint-disable arrow-parens */
/* eslint-disable camelcase */

// import core & vendor packages below
import { useEffect, useState } from 'react';
import { Row, Col, Accordion, Form, Button } from 'react-bootstrap';
import uuidv4 from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import FormPatientProfile from 'components/FormPatientProfile';

// import assets below
import './style.scss';
import FormUI from 'components/UI/FormUI';
import AccordionUI from 'components/UI/AccordionUI';
import { ReactComponent as CirclePlusSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CircleMinusSVG } from 'assets/svg/circle-minus.svg';
import SuccessModal from './components/SuccessModal';
import { toast } from 'react-toastify';

// eslint-disable-next-line no-unused-vars
import axios from 'axios';

// main component
const FormCreateTemporaryProfile = () => {
  // eslint-disable-next-line newline-per-chained-call
  const todayDate = new Date().toISOString().slice(0, 10); // 2-22-07-15
  const [countryList, setCountryList] = useState(Country.getAllCountries());

  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [aliasToggle, setAliasToggle] = useState(false);
  const [MRN, setMRN] = useState(Math.floor(Math.random() * Date.now()));
  const [isNewBorn, setIsNewBorn] = useState(false);
  const [prefix, setPrefix] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [suffix, setSuffix] = useState('');
  const [sex, setSex] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [age, setAge] = useState('');
  const [religion, setReligion] = useState('');
  const [nationality, setNationality] = useState('');
  const [civilStatus, setCivilStatus] = useState('');
  const [fictitiousDateOfBirth, setFictitiousDateOfBirth] = useState('');
  const [aliasPrefix, setAliasPrefix] = useState('');
  const [aliasFirstName, setAliasFirstName] = useState('');
  const [aliasMiddleName, setAliasMiddleName] = useState('');
  const [aliasLastName, setAliasLastName] = useState('');
  const [aliasSuffix, setAliasSuffix] = useState('');
  const [aliasGender, setAliasGender] = useState('');
  const [aliasDiagnosis, setAliasDiagnosis] = useState('');

  /** Present address */
  const [presentCountry, setPresentCountry] = useState('Philippines');
  const [presentRegion, setPresentRegion] = useState('');
  const [presentProvince, setPresentProvince] = useState('');
  const [presentCity, setPresentCity] = useState('');
  const [presentZipCode, setPresentZipCode] = useState('');
  const [presentAddress1, setPresentAddress1] = useState('');
  const [presentAddress2, setPresentAddress2] = useState('');
  const [presentBrgy, setPresentBrgy] = useState('');

  /** permanent address */
  const [permanentCountry, setPermanentCountry] = useState('Philippines');
  const [permanentRegion, setPermanentRegion] = useState('');
  const [permanentProvince, setPermanentProvince] = useState('');
  const [permanentCity, setPermanentCity] = useState('');
  const [permanentZipCode, setPermanentZipCode] = useState('');
  const [permanentAddress1, setPermanentAddress1] = useState('');
  const [permanentAddress2, setPermanentAddress2] = useState('');
  const [permanentBrgy, setPermanentBrgy] = useState('');

  const [sameAsPresentAddress, setSameAsPresentAddress] = useState(false);

  const [inputFields, setInputFields] = useState([{ uuid: uuidv4(), type: '', details: '' }]);

  const handleAddFields = () => {
    setInputFields([...inputFields, { uuid: uuidv4(), type: '', details: '' }]);
  };

  const [showProfile, setShowProfile] = useState({
    isShowProfile: false,
    profileId: null,
  });

  const handleRemoveFields = (uuid) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setInputFields(values);
  };

  const handleChangeInput = (uuid, event) => {
    const newInputFields = inputFields.map((i) => {
      if (uuid === i.uuid) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleCheckBoxValue = (event, field) => {
    switch (field) {
      case 'newBorn':
        setIsNewBorn(event.target.checked);
        break;

      case 'fictitiousDateOfBirth':
        setFictitiousDateOfBirth(event.target.checked);
        break;

      case 'sameAsPresentAddress':
        setSameAsPresentAddress(event.target.checked);
        break;
    }
  };

  const handleDropdownValue = (event, field) => {
    switch (field) {
      case 'prefix':
        setPrefix(event.target.value);
        break;

      case 'sex':
        setSex(event.target.value);
        break;

      case 'aliasPrefix':
        setAliasPrefix(event.target.value);
        break;

      case 'presentCountry':
        setPresentCountry(event.target.value);
        break;

      case 'presentRegion':
        setPresentRegion(event.target.value);
        break;

      case 'presentCity':
        setPresentCity(event.target.value);
        break;

      case 'presentBrgy':
        setPresentBrgy(event.target.value);
        break;

      case 'permanentCountry':
        setPermanentCountry(event.target.value);
        break;

      case 'permanentRegion':
        setPermanentRegion(event.target.value);
        break;

      case 'permanentCity':
        setPermanentCity(event.target.value);
        break;

      case 'permanentBrgy':
        setPermanentBrgy(event.target.value);
        break;
    }
  };

  const params = {
    MRN: MRN,
    is_newborn: isNewBorn,
    prefix: prefix,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    suffix: suffix,
    sex: sex,
    date_of_birth: isNewBorn ? todayDate : dateOfBirth,
    age: isNewBorn ? 0 : age,
    is_fictitious_date_of_birth: fictitiousDateOfBirth,
    nationality: nationality,
    civil_status: civilStatus,
    religion: religion,
    alias_prefix: aliasPrefix,
    alias_first_name: aliasFirstName,
    alias_middle_name: aliasMiddleName,
    alias_last_name: aliasLastName,
    alias_suffix: aliasSuffix,
    alias_gender: aliasGender,
    alias_diagnosis: aliasDiagnosis,
    patient_status: 'temporary',
    patient_present_address: {
      present_country: presentCountry,
      present_region: presentRegion,
      patient_province: presentProvince,
      present_city: presentCity,
      present_zip_code: presentZipCode,
      present_address1: presentAddress1,
      present_address2: presentAddress2,
      present_brgy: presentBrgy,
    },
    patient_permanent_address: {
      permanent_country: sameAsPresentAddress ? presentCountry : permanentCountry,
      permanent_region: sameAsPresentAddress ? presentRegion : permanentRegion,
      patient_province: sameAsPresentAddress ? presentProvince : permanentProvince,
      permanent_city: sameAsPresentAddress ? presentCity : permanentCity,
      permanent_zip_code: sameAsPresentAddress ? presentZipCode : permanentZipCode,
      permanent_address1: sameAsPresentAddress ? presentAddress1 : permanentAddress1,
      permanent_address2: sameAsPresentAddress ? presentAddress2 : permanentAddress2,
      permanent_brgy: sameAsPresentAddress ? presentBrgy : permanentBrgy,
    },
    contact_details: inputFields,
  };

  const [regionData, setRegion] = useState([]);
  const [provinceData, setProvince] = useState([]);
  const [cityData, setCity] = useState([]);
  const [barangayData, setBarangay] = useState([]);

  const region = () => {
    regions().then((response) => {
      setRegion(response);
    });
  };

  const province = (e) => {
    setPresentRegion(e.target.selectedOptions[0].text);
    provinces(e.target.value).then((response) => {
      setProvince(response);
      setCity([]);
      setBarangay([]);
    });
  };

  const city = (e) => {
    setPresentProvince(e.target.selectedOptions[0].text);
    cities(e.target.value).then((response) => {
      setCity(response);
    });
  };

  const barangay = (e) => {
    setPresentCity(e.target.selectedOptions[0].text);
    barangays(e.target.value).then((response) => {
      setBarangay(response);
    });
  };

  const brgy = (e) => {
    setPresentBrgy(e.target.selectedOptions[0].text);
  };

  const [permRegionData, setPermRegion] = useState([]);
  const [permProvinceData, setPermProvince] = useState([]);
  const [permCityData, setPermCity] = useState([]);
  const [permBarangayData, setPermBarangay] = useState([]);

  const permRegion = () => {
    regions().then((response) => {
      setPermRegion(response);
    });
  };

  const permProvince = (e) => {
    setPermanentRegion(e.target.selectedOptions[0].text);
    provinces(e.target.value).then((response) => {
      setPermProvince(response);
      setPermCity([]);
      setPermBarangay([]);
    });
  };

  const permCity = (e) => {
    setPermanentProvince(e.target.selectedOptions[0].text);
    cities(e.target.value).then((response) => {
      setPermCity(response);
    });
  };

  const permBarangay = (e) => {
    setPermanentCity(e.target.selectedOptions[0].text);
    barangays(e.target.value).then((response) => {
      setPermBarangay(response);
    });
  };

  const permBrgy = (e) => {
    setPermanentBrgy(e.target.selectedOptions[0].text);
  };

  useEffect(() => {
    region();
    permRegion();
  }, []);

  const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setAge(age);
  };

  const save = (saveAndAssign) => {
    axios
      .post('/patient', params)
      .then((response) => {
        /*
         setModal(true);
         */
        toast.success('Successfully created!');

        // Need to add function here to set the ID on selectPatientItem state from Redux
        setTimeout(() => {
          if (saveAndAssign) {
            setShowProfile((prevState) => {
              // Set the ID Here profileId from response
              return { ...prevState, profileId: null };
            });

            navigate('/dashboard/admissions/create-room-request');
          } else {
            setShowProfile((prevState) => {
              // Set the ID Here profileId from response
              return { ...prevState, isShowProfile: !saveAndAssign, profileId: null };
            });
          }
        }, 2000);
      })
      .catch((err) => {
        toast.warning(err.response.data.errors.message);
      });
  };

  const redirectBackToPreviousPage = () => {
    window.history.back();
  };

  useEffect(() => {
    region();
    permRegion();
  }, []);

  return (
    <div className='formCreateTemporaryProfile'>
      {!showProfile.isShowProfile ? (
        <>
          <FormUI>
            <div className='formCreateTemporaryProfile__header'>
              <h4>Create Temporary Patient Profile</h4>
            </div>
            <AccordionUI>
              <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>Patient Demographics</Accordion.Header>
                  <Accordion.Body>
                    <FormUI>
                      <Row className='d-flex align-items-center'>
                        <Col lg={2}>
                          <Form.Group className='mb-3'>
                            <Form.Label>MRN</Form.Label>
                            <Form.Control
                              style={{ maxWidth: '320px' }}
                              type='text'
                              value={MRN}
                              readOnly
                              placeholder='Enter Here'
                              onChange={(event) => {
                                setMRN(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2}>
                          <Form.Group className='mt-3 mb-3'>
                            <Form.Check
                              type='checkbox'
                              label='New Born'
                              onChange={(event) => handleCheckBoxValue(event, 'newBorn')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>Prefix/Title</Form.Label>
                            <Form.Select onChange={(event) => handleDropdownValue(event, 'prefix')}>
                              <option value=''>-</option>
                              <option value='mr'>Mr.</option>
                              <option value='mrs'>Mrs.</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              First Name<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter First Name'
                              onChange={(event) => {
                                setFirstName(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Middle Name<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Middle Name'
                              onChange={(event) => {
                                setMiddleName(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Last Name<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Last Name'
                              onChange={(event) => {
                                setLastName(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>Suffix</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Suffix'
                              onChange={(event) => {
                                setSuffix(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Sex<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select onChange={(event) => handleDropdownValue(event, 'sex')}>
                              <option value=''>Select Gender</option>
                              <option value='male'>Male</option>
                              <option value='female'>Female</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Date of Birth<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='date'
                              onChange={(event) => {
                                setDateOfBirth(event.target.value);
                                getAge(event.target.value);
                              }}
                              value={isNewBorn ? todayDate : dateOfBirth}
                              disabled={isNewBorn ? 'false' : ''}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Age<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              disabled
                              onChange={(event) => {
                                setAge(event.target.value);
                              }}
                              value={isNewBorn ? 0 : age}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='d-flex h-100 mb-3'>
                            <Form.Check
                              type='checkbox'
                              label='Fictitious Date of Birth'
                              onChange={(event) =>
                                handleCheckBoxValue(event, 'fictitiousDateOfBirth')
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Nationality<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Here'
                              onChange={(event) => {
                                setNationality(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Civil Status<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Here'
                              onChange={(event) => {
                                setCivilStatus(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Religion<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Here'
                              onChange={(event) => {
                                setReligion(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex nowrap align-items-center mb-3'>
                            <Button
                              className={aliasToggle && 'active'}
                              variant='alias'
                              onClick={() => setAliasToggle((prevState) => !prevState)}
                            >
                              <span className='aliasRound'></span>
                            </Button>
                            <span> Alias / Fictitious Name</span>
                          </div>
                        </Col>
                        {aliasToggle && (
                          <>
                            <Row>
                              {/* <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>Prefix/Title</Form.Label>
                              <Form.Select
                                onChange={(event) => handleDropdownValue(event, 'aliasPrefix')}
                              >
                                <option value=''>-</option>
                                <option value='male'>Mr.</option>
                                <option value='female'>Mrs.</option>
                              </Form.Select>
                            </Form.Group>
                          </Col> */}
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    First Name<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type='text'
                                    placeholder='Enter First Name'
                                    value={aliasFirstName}
                                    onChange={(event) => {
                                      setAliasFirstName(event.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              {/* <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                Middle Name<span className='asterisk'>*</span>
                              </Form.Label>
                              <Form.Control requied
                                type='text'
                                placeholder='Enter Middle Name'
                                onChange={(event) => {
                                  setAliasMiddleName(event.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col> */}
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    Last Name<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type='text'
                                    placeholder='Enter Last Name'
                                    value={aliasLastName}
                                    onChange={(event) => {
                                      setAliasLastName(event.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              {/* <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>Suffix</Form.Label>
                              <Form.Control
                                type='text'
                                placeholder='Enter Suffix'
                                onChange={(event) => {
                                  setAliasSuffix(event.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col> */}
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>Sex</Form.Label>
                                  <Form.Select
                                    name='sex'
                                    onChange={(event) => setAliasGender(event.target.value)}
                                  >
                                    <option value=''>- select -</option>
                                    <option value='male'>Male</option>
                                    <option value='femail'>Female</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    Diagnosis<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type='text'
                                    placeholder='Enter Diagnosis'
                                    value={aliasDiagnosis}
                                    onChange={(event) => {
                                      setAliasDiagnosis(event.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Row>
                    </FormUI>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>Patient Address Details</Accordion.Header>
                  <Accordion.Body>
                    <FormUI>
                      <Row className='d-flex align-items-center'>
                        <Col lg={2}>
                          <h6 className='mb-3 fw-bold'>Present Address</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Country<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select value='Philippines'>
                              <option value=''>Select Country</option>
                              {countryList.map((item, key) => {
                                return (
                                  <option key={key} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Region<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select
                              // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                              onChange={province}
                              onSelect={region}
                            >
                              <option value=''>-</option>
                              {regionData &&
                                regionData.length > 0 &&
                                regionData.map((item, key) => {
                                  return (
                                    <option key={item.region_code} value={item.region_code}>
                                      {item.region_name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Province<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select
                              // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                              onChange={city}
                            >
                              <option value=''>-</option>
                              {provinceData &&
                                provinceData.length > 0 &&
                                provinceData.map((item, key) => {
                                  return (
                                    <option key={item.province_code} value={item.province_code}>
                                      {item.province_name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              City<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select
                              // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                              onChange={barangay}
                            >
                              <option value=''>-</option>
                              {cityData &&
                                cityData.length > 0 &&
                                cityData.map((item, key) => {
                                  return (
                                    <option key={item.city_code} value={item.city_code}>
                                      {item.city_name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Barangay<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select
                              // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                              onChange={brgy}
                            >
                              <option value=''>-</option>
                              {barangayData &&
                                barangayData.length > 0 &&
                                barangayData.map((item, key) => {
                                  return (
                                    <option key={item.brgy_code} value={item.brgy_code}>
                                      {item.brgy_name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Here'
                              onChange={(event) => {
                                setPresentZipCode(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Address Line 1<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Here'
                              onChange={(event) => {
                                setPresentAddress1(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Subdivision/Village<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Here'
                              onChange={(event) => {
                                setPresentAddress2(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='d-flex align-items-center'>
                        <Col>
                          <div className='d-flex align-items-center my-3'>
                            <span className='me-3 fw-bold'>Permanent Address</span>
                            <Form.Check
                              type='checkbox'
                              label='Same as Present Address'
                              onChange={(event) =>
                                handleCheckBoxValue(event, 'sameAsPresentAddress')
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Country<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select value='Philippines'>
                              <option value=''>Select Country</option>
                              {countryList.map((item, key) => {
                                return (
                                  <option key={key} className='' value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Region<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select onChange={permProvince} onSelect={permRegion}>
                              <option value=''>{sameAsPresentAddress ? presentRegion : ''}</option>
                              {permRegionData &&
                                permRegionData.length > 0 &&
                                permRegionData.map((item, key) => {
                                  return (
                                    <option key={item.region_code} value={item.region_code}>
                                      {item.region_name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Province<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select
                              // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                              onChange={permCity}
                            >
                              <option value=''>
                                {sameAsPresentAddress ? presentProvince : ''}
                              </option>
                              {permProvinceData &&
                                permProvinceData.length > 0 &&
                                permProvinceData.map((item, key) => {
                                  return (
                                    <option key={item.province_code} value={item.province_code}>
                                      {item.province_name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              City<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select
                              // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                              onChange={permBarangay}
                            >
                              <option value=''>{sameAsPresentAddress ? presentCity : ''}</option>
                              {permCityData &&
                                permCityData.length > 0 &&
                                permCityData.map((item, key) => {
                                  return (
                                    <option key={item.city_code} value={item.city_code}>
                                      {item.city_name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Barangay<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select
                              // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                              onChange={permBrgy}
                            >
                              <option value=''>{sameAsPresentAddress ? presentBrgy : ''}</option>
                              {permBarangayData &&
                                permBarangayData.length > 0 &&
                                permBarangayData.map((item, key) => {
                                  return (
                                    <option key={item.brgy_code} value={item.brgy_code}>
                                      {item.brgy_name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Here'
                              value={sameAsPresentAddress ? presentZipCode : permanentZipCode}
                              onChange={(event) => {
                                setPermanentZipCode(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Address Line 1<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Here'
                              value={sameAsPresentAddress ? presentAddress1 : permanentAddress1}
                              onChange={(event) => {
                                setPermanentAddress1(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              Subdivision/Village<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Here'
                              value={sameAsPresentAddress ? presentAddress2 : permanentAddress2}
                              onChange={(event) => {
                                setPermanentAddress2(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </FormUI>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Row>
                <Col lg='6'>
                  <Accordion defaultActiveKey='0'>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Patient&#39;s Contact Details</Accordion.Header>
                      <Accordion.Body>
                        <FormUI>
                          <Form.Group>
                            <Row>
                              <Col lg='5'>
                                <Form.Label>
                                  Contact Type<span className='asterisk'>*</span>
                                </Form.Label>
                              </Col>
                              <Col lg='5'>
                                <Form.Label>
                                  Contact Details<span className='asterisk'>*</span>
                                </Form.Label>
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group>
                            {inputFields.map((inputField) => (
                              <div key={inputField.uuid}>
                                <Row>
                                  <Col lg='5'>
                                    <Form.Group className='mb-3'>
                                      <Form.Select
                                        name='type'
                                        onChange={(event) =>
                                          handleChangeInput(inputField.uuid, event)
                                        }
                                      >
                                        <option value=''>- Select -</option>
                                        <option value='Mobile Number'>Mobile Number</option>
                                        <option value='Landline Number'>Landline Number</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>

                                  <Col lg='5'>
                                    <Form.Group className='mb-3'>
                                      <Form.Control
                                        type='text'
                                        placeholder='Enter contact details'
                                        name='details'
                                        // value={inputField.contactDetails}
                                        onChange={(event) =>
                                          handleChangeInput(inputField.uuid, event)
                                        }
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col lg='auto' className='d-flex align-items-center'>
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleRemoveFields}
                                    >
                                      <CircleMinusSVG />
                                    </Button>
                                  </Col>
                                  <Col lg='auto' className='d-flex align-items-center'>
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleAddFields}
                                    >
                                      <CirclePlusSVG />
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </Form.Group>
                        </FormUI>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            </AccordionUI>
            <div className='d-flex justify-content-end mt-5'>
              <Button className='me-3' variant='save' onClick={() => save(false)}>
                SAVE
              </Button>
              <Button className='me-3' variant='light-blue' onClick={() => save(true)}>
                SAVE & ASSIGN BED/ROOM
              </Button>
              <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
                CANCEL
              </Button>
            </div>
          </FormUI>
          {modal && <SuccessModal />}
        </>
      ) : (
        <FormPatientProfile showProfile={showProfile} setShowProfile={setShowProfile} />
      )}
    </div>
  );
};

export default FormCreateTemporaryProfile;
