/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import axios from 'axios';

const Admission = (props) => {
    const [dailyCensus, setDailyCensus] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/report/daily_census`,
        ).then((response) => {
            setDailyCensus(response.data.data);
        }, []);
    }, []);

    return (
        <>
            <thead>
                <tr>
                    <th colSpan='2'><small>Hospital Number</small></th>
                    <th colSpan='2'><small>Room Number</small></th>
                    <th colSpan='2'><small>Patient's Name</small></th>
                    <th colSpan='2'><small>Time</small></th>
                </tr>
                <tr>
                    <th rowSpan='1' colSpan='8'>
                        <center><small>ADMISSIONS</small></center>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    dailyCensus.daily_census?.admissions?.map((item, index) => {
                        return (

                            <tr>
                                <td colSpan='2'><small>{item.hospital_number}</small></td>
                                <td colSpan='2'><small>{item.room_number}</small></td>
                                <td colSpan='2'><small>{item.patient_name}</small></td>
                                <td colSpan='2'><small>{item.time}</small></td>
                            </tr>
                        );

                    })

                }
            </tbody>
        </>
    );
};

export default Admission;
