/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getAllRoles,
  getAllDepartments,
  getAllSpecialties,
  getSubSpecialties,
  registerUser,
} from './thunks/signupThunks';

const initialLogs = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  error: null,
};

const initialState = {
  data: [],
  included: null,
  item: null,
  logs: initialLogs,

  // For Submit
  submitLogs: initialLogs,

  // For Roles
  rolesData: [],
  rolesLogs: initialLogs,

  // For Departments
  departmentsData: [],
  departmentsLogs: initialLogs,

  // For Sub Specialties
  subData: [],
  subIncluded: null,
  subLogs: initialLogs,
};

const slice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    resetSubmitLogs: (state) => {
      state.submitLogs = initialLogs;
    },
  },
  extraReducers: {
    [getSubSpecialties.fulfilled]: (state, action) => {
      state.subLogs.isSuccess = true;
      state.subLogs.isLoading = false;
      state.subData = action.payload.data;
      state.subIncluded = action.payload.included;
    },
    [getSubSpecialties.pending]: (state) => {
      state.subLogs.isSuccess = false;
      state.subLogs.isFailed = false;
      state.subLogs.error = '';
      state.subLogs.isLoading = true;
    },
    [getSubSpecialties.rejected]: (state) => {
      state.subData = [];
      state.subLogs.isLoading = false;
      state.subLogs.isFailed = true;
      state.subLogs.error = action?.payload?.errors[0]?.detail;
    },
    [registerUser.fulfilled]: (state, action) => {
      state.submitLogs.isSuccess = true;
      state.submitLogs.isLoading = false;
    },
    [registerUser.pending]: (state) => {
      state.submitLogs.isSuccess = false;
      state.submitLogs.isFailed = false;
      state.submitLogs.error = '';
      state.submitLogs.isLoading = true;
    },
    [registerUser.rejected]: (state, action) => {
      state.submitLogs.isLoading = false;
      state.submitLogs.isFailed = true;
      state.submitLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getAllSpecialties.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
      state.included = action.payload.included;
    },
    [getAllSpecialties.pending]: (state) => {
      state.logs.isSuccess = false;
      state.logs.isFailed = false;
      state.logs.error = '';
      state.logs.isLoading = true;
    },
    [getAllSpecialties.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [getAllRoles.fulfilled]: (state, action) => {
      state.rolesLogs.isSuccess = true;
      state.rolesLogs.isLoading = false;
      state.rolesData = action.payload.data;
    },
    [getAllRoles.pending]: (state) => {
      state.rolesLogs.isSuccess = false;
      state.rolesLogs.isFailed = false;
      state.rolesLogs.error = '';
      state.rolesLogs.isLoading = true;
    },
    [getAllRoles.rejected]: (state) => {
      state.rolesData = [];
      state.rolesLogs.isLoading = false;
      state.rolesLogs.isFailed = true;
      state.rolesLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getAllDepartments.fulfilled]: (state, action) => {
      state.departmentsLogs.isSuccess = true;
      state.departmentsLogs.isLoading = false;
      state.departmentsData = action.payload.data;
    },
    [getAllDepartments.pending]: (state) => {
      state.departmentsLogs.isSuccess = false;
      state.departmentsLogs.isFailed = false;
      state.departmentsLogs.error = '';
      state.departmentsLogs.isLoading = true;
    },
    [getAllDepartments.rejected]: (state) => {
      state.departmentsData = [];
      state.departmentsLogs.isLoading = false;
      state.departmentsLogs.isFailed = true;
      state.departmentsLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: signupActions, reducer: signupReducer } = slice;

export const { resetSubmitLogs } = signupActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.signup || initialState;

export const selectSignup = createSelector([selectDomain], (inventoryState) => inventoryState);

/* --------------------------------------------------------------------------------- */

export { getAllRoles, getAllDepartments, getAllSpecialties, getSubSpecialties, registerUser };
