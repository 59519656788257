/* eslint-disable no-unused-vars */
import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import ACLPermissionHeader from './components/PageHeader';
import ACLPermissionTabs from './components/Tabs';

import DropdownFilter from './components/SearchPatient/components/DropdownFilter';

import { useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
// import assets below
import './style.scss';

// main component
const ACLPermissions = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('ALL');

  return (
    <Layout pageTitle='Permissions'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button onClick={() => navigate(-1)} className='Back-btn'>
          <MdOutlineKeyboardArrowLeft className='Back-icon' />

          <span>Back To List</span>
        </button>

        <DropdownFilter />
      </div>

      <section className='ACLP-content'>
        <ACLPermissionHeader active={activeTab} />
        <ACLPermissionTabs onChange={setActiveTab} />
      </section>
    </Layout>
  );
};

export default ACLPermissions;
