// import assets below
import './style.scss';

// main component
const CardUI = (props) => {
  const { children } = props;
  return <div className='cardUI'>{children}</div>;
};

export default CardUI;
