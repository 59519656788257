/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { searchCashFlow } from './thunks/cashFlowThunks';

const initialLogs = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  // For Reports -> Cash Flow Statement
  reportsCashFlow: [],
  reportsCashFlowLogs: initialLogs,
};

const slice = createSlice({
  name: 'cashFlow',
  initialState,
  reducers: {
    resetLogState: (state, action) => {
      state.reportsCashFlowLogs = initialLogs;
    },
  },
  extraReducers: {
    // For reportsCashFlow
    [searchCashFlow.fulfilled]: (state, action) => {
      state.reportsCashFlow = action.payload.data;
      state.reportsCashFlowLogs = {
        ...state.reportsCashFlowLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [searchCashFlow.pending]: (state) => {
      state.reportsCashFlowLogs = {
        ...state.reportsCashFlowLogs,
        error: '',
        isFailed: false,
        isLoading: true,
        isSuccess: false,
      };
    },
    [searchCashFlow.rejected]: (state, action) => {
      state.reportsCashFlow = [];
      state.reportsCashFlowLogs = {
        ...state.reportsCashFlowLogs,
        isFailed: true,
        isLoading: false,
      };
    },
  },
});

export const { actions: cashFlowActions, reducer: cashFlowReducer } = slice;

export const { resetLogState } = cashFlowActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.cashFlow || initialState;

export const selectcashFlow = createSelector([selectDomain], (cashFlowState) => cashFlowState);

export const selectReportCashFlow = createSelector(
  [selectDomain],
  (cashFlowState) => cashFlowState.reportsCashFlow,
);

/* --------------------------------------------------------------------------------- */

export { searchCashFlow };
