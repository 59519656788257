// Packages
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Row, Col, Form, Table, Button, ListGroup } from 'react-bootstrap';

// Components
import TableUI from 'components/UI/TableUI';
import ConditionalRender from 'components/ConditionalRender';
import ConfirmStartStockCheck from './ConfirmStartStockCheck';

const individualItems = [
  {
    id: 'MED00001',
    itemGroup: 'Cotton Balls Setrille 10s',
    storageBin: 'S-1',
    department: 'Central Sterile Supplies',
    systemCount: 300,
  },
  {
    id: 'MED00002',
    itemGroup: 'Cotton Balls Setrille 10s',
    storageBin: 'S-1',
    department: 'Central Sterile Supplies',
    systemCount: 300,
  },
  {
    id: 'MED00003',
    itemGroup: 'Cotton Balls Setrille 10s',
    storageBin: 'S-1',
    department: 'Central Sterile Supplies',
    systemCount: 300,
  },
];

const items = {
  ITEMGROUP: {
    title: 'Item Group',
    items: ['Item Group', 'Medical Supplies', 'Medications', 'Office Supplies'],
  },
  STORAGEBIN: {
    title: 'Storage Bin',
    items: ['S-1', 'S-2', 'S-3'],
  },
  INDIVIDUAL: {
    title: 'Individual',
    items: ['MED00001', 'MED00002', 'MED00003'],
  },
};

// Main Components
function NewStockStepThree({ onPrevious, setShowProfile }) {
  const [show, showModal] = useState(false);
  const [stockCheck, setStockCheck] = useState(false);
  const [selected, setSelected] = useState({
    ...items.ITEMGROUP,
    itemType: 'ITEMGROUP',
    items: [],
  });

  const isFinalizing = stockCheck === true;

  const onChange = (value) => {
    const { items } = selected;
    const isValueSelected = items.includes(value);

    if (isValueSelected) {
      const temp = [...items];
      temp.splice(items.indexOf(value), 1);
      setSelected({ ...selected, items: temp });
      return;
    }

    setSelected({ ...selected, items: [...selected.items, value] });
  };

  const onChangeItemType = (e) => {
    const { value: itemType } = e.currentTarget;

    setSelected({
      ...items[itemType],
      itemType,
      items: [],
    });

    document.getElementById('item-groups-head').checked = false;
  };

  const onSelectAll = (e) => {
    if (e.currentTarget.checked) {
      setSelected({
        ...selected,
        items: [...items[selected.itemType].items],
      });
      return;
    }

    setSelected({
      ...selected,
      items: [],
    });
  };

  const selectedItems = selected.items.length;
  const itemTypes = Object.keys(items);

  return (
    <Fragment>
      <Row>
        <Col sm={8}>
          <Row>
            <Col sm={4}>
              <Form.Group className='mb-4'>
                <Form.Label>Selected Item By:</Form.Label>
                <Form.Select onChange={onChangeItemType}>
                  {itemTypes.map((itemType) => (
                    <option key={itemType} value={itemType}>
                      {items[itemType].title}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col className='d-flex align-items-end'>
              <Form.Group className='mb-4'>
                <div className='selected-items'>
                  Total Selected Item
                  <div className='counter'>{selectedItems}</div>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm={4}>
          <ConditionalRender
            condition={!isFinalizing}
            renderIf={
              <div className='start-stock-check d-flex flex-column align-items-end'>
                <Button
                  variant='success'
                  onClick={() => {
                    showModal(true);
                  }}
                >
                  Start Stock Check
                </Button>
              </div>
            }
          />

          <ConditionalRender
            condition={isFinalizing}
            renderIf={
              <div className='print-and-time'>
                <Button variant='danger' className='mb-4'>
                  Print Stock Check Item Ist
                </Button>

                <Form.Group className='time-started'>
                  <Form.Label>Stock Check Started on:</Form.Label>
                  <Form.Control type='text' readOnly value='June 25, 2022 1:35 PM' />
                </Form.Group>
              </div>
            }
          />
        </Col>
      </Row>

      <ConditionalRender
        condition={selected.itemType === 'INDIVIDUAL'}
        renderIf={
          <Row>
            <Col sm={3}>
              <Form.Group className='mb-4'>
                <Form.Label>Item Code/Name:</Form.Label>
                <Form.Select>
                  <option value='Cotton Balls'>Cotton Balls</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group className='mb-4'>
                <Form.Label>Item Group:</Form.Label>
                <Form.Select>
                  <option value='Cotton Balls'>Cotton Balls</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4} className='d-flex align-items-end'>
              <Form.Group className='mb-4'>
                <Button variant='success' type='button'>
                  Go
                </Button>
              </Form.Group>
            </Col>
          </Row>
        }
      />

      <ConditionalRender
        condition={selected.itemType === 'INDIVIDUAL'}
        renderIf={
          <Row>
            <Col>
              <Form.Group className='mb-4 pt-4'>
                <TableUI>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>
                          <Form.Check type='checkbox' onChange={onSelectAll} />
                        </th>
                        <th>Item Code</th>
                        <th>Item Group</th>
                        <th>Storage Bin</th>
                        <th>Departent</th>
                        <th>System Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {individualItems.map(
                        ({ id, itemGroup, storageBin, department, systemCount }) => (
                          <tr key={id}>
                            <td>
                              <Form.Check
                                value={id}
                                type='checkbox'
                                checked={selected.items.includes(id)}
                                onChange={() => onChange(id)}
                              />
                            </td>
                            <td>{id}</td>
                            <td>{itemGroup}</td>
                            <td>{storageBin}</td>
                            <td>{department}</td>
                            <td>{systemCount}</td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </TableUI>
              </Form.Group>
            </Col>
          </Row>
        }
        renderElse={
          <Row>
            <Col sm={8}>
              <Form.Group className='mb-4 pt-4'>
                <Form.Label>
                  Select Item Groups for Stock Check ( Selecting an item group will add all items in
                  that group to the Stock Check).
                </Form.Label>
                <ListGroup as='ul'>
                  <ListGroup.Item as='li' active>
                    <Form.Check
                      id='item-groups-head'
                      type='checkbox'
                      label={selected.title}
                      onClick={onSelectAll}
                    />
                  </ListGroup.Item>
                  {items[selected.itemType].items.map((i) => (
                    <ListGroup.Item key={i} as='li' onClick={() => onChange(i)}>
                      <Form.Check label={i} type='checkbox' checked={selected.items.includes(i)} />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Form.Group>
            </Col>
          </Row>
        }
      />

      <Row>
        <Col className='d-flex justify-content-between action-btns'>
          <div className='d-flex flex-column'>
            <Button className='mb-1' variant='primary' type='button' onClick={onPrevious}>
              Previous
            </Button>
            <Button
              variant='danger'
              type='button'
              onClick={() => {
                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    showScreen: 1,
                  };
                });
              }}
            >
              Back to Stock Check List
            </Button>
          </div>

          <div>
            <ConditionalRender
              condition={isFinalizing}
              renderIf={
                <div className='finalize-and-draft d-flex'>
                  <Button
                    variant='primary'
                    className='mb-4'
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          showScreen: 1,
                          tableShow: 3,
                        };
                      });
                    }}
                  >
                    FINALIZE STOCK CHECK
                  </Button>

                  <Button
                    variant='secondary'
                    className='mb-4'
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          showScreen: 1,
                          tableShow: 2,
                        };
                      });
                    }}
                  >
                    SAVE AS DRAFT
                  </Button>
                </div>
              }
            />
          </div>
        </Col>
      </Row>

      <ConfirmStartStockCheck show={show} showModal={showModal} setStockCheck={setStockCheck} />
    </Fragment>
  );
}

NewStockStepThree.defaultProps = {
  onPrevious: () => {},
  setShowProfile: () => {},
};

NewStockStepThree.propTypes = {
  onPrevious: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default NewStockStepThree;
