/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table, { PAGE_SIZE } from 'components/ReactTable';
import FormUI from 'components/UI/FormUI';
import { Form, Row, Col } from 'react-bootstrap';
import { selectEmployees } from 'reducers/employeesReducer';
import { selectEmployeeSchedules, getAllEmployeeSchedules } from 'reducers/employeeScheduleReducer';

// Import assets below
import './index.scss';
import avatar from 'assets/images/avatar.jpg';
import { employeeStatus } from 'helpers';

// Queries
const params = {
  current_page: 1,
  per_page: PAGE_SIZE[0], // [10, 20, 30, 100]
};

const ViewShifting = ({ empData, setEmpData, setActiveScreen, setActiveTab }) => {
  const [empScheduleData, setEmpScheduleData] = useState([]);
  const employees = useSelector(selectEmployees);
  const schedules = useSelector(selectEmployeeSchedules);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEmployeeSchedules(params));
  }, []);

  useEffect(() => {
    if (empData) {
      if (schedules?.data?.length > 0) {
        const scheduleData = schedules.data.find((sched) => sched.id === empData.id);
        if (scheduleData) {
          setEmpScheduleData(scheduleData?.employee_schedules || []);
        }
      }
    }
  }, [empData]);

  // -----------------------------------------------------------------
  // SCHEDULE VIEW

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Date', accessor: 'date' }, // accessor is the "key" in the data
      { Header: 'Start of Shift Time', accessor: 'start_time' },
      { Header: 'End of Shift Time', accessor: 'end_time' },
      { Header: 'Deployment', accessor: 'deployment' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      empScheduleData?.map((dd) => ({
        id: dd?.id,
        date: dd?.assigned_date,
        start_time: dd?.time_in,
        end_time: dd?.time_out,
        deployment: dd?.deployment,
        status: employeeStatus(dd?.status),
      })),
    [empScheduleData],
  );

  // -----------------------------------------------------------------

  const EMPLOYEES_OPTIONS = React.useMemo(
    () =>
      employees.data.map((employee) => ({
        ...employee,
        value: employee.id,
        label: `${employee?.last_name}, ${employee?.first_name} ${employee?.middle_name}`,
      })),
    [employees.data],
  );

  // -----------------------------------------------------------------

  return (
    <div className='shifting-view'>
      <FormUI>
        <Form className='view-shift'>
          <h3>
            EDIT SHIFTING SCHEDULE
            <span
              onClick={() => {
                setActiveScreen(0);
                setEmpData(null);
                setEmpScheduleData(null);
                setActiveTab('Shifting');
              }}
            >
              {'< Go Back to Shifting List'}
            </span>
          </h3>

          <Row className='d-flex mt-4 mb-4 employee-details employee-information shifting-information'>
            <Col className='d-flex justify-content-center'>
              <img
                src={empData?.employee_detail?.avatar || avatar}
                style={{ width: '150px', height: '150px' }}
              />
            </Col>
            <Col className='d-flex align-items-center'>
              <div className='p-header'>
                <p>{empData?.employee_detail?.eId || '---'}</p>
                <span>Employee ID</span>
              </div>
            </Col>
            <Col sm={3} className='d-flex align-items-center'>
              <div className='p-header'>
                {empData?.first_name || empData?.middle_name || empData?.last_name ? (
                  <p>{[empData?.first_name, empData?.middle_name, empData?.last_name].join(' ')}</p>
                ) : (
                  <p>{'---'}</p>
                )}
                <span>Name</span>
              </div>
            </Col>
            <Col sm={3} className='d-flex align-items-center'>
              <div className='p-header'>
                <p>{empData?.employee_detail?.position || '---'}</p>
                <span>Position</span>
              </div>
            </Col>
            <Col className='d-flex align-items-center'>
              <div className='p-header'>
                <p>{empData?.employee_detail?.department || '---'}</p>
                <span>Department</span>
              </div>
            </Col>
          </Row>

          <hr />

          <div className='employee-details employee-information'>
            <div className='shifting-tab pt-3'>
              <p className='shifting-schedule'>Shifting Schedule</p>
              <Table data={tableData} columns={tableColumns} />
            </div>
          </div>
        </Form>
      </FormUI>
    </div>
  );
};

ViewShifting.defaultProps = {
  setActiveTab: () => { },
};

ViewShifting.propTypes = {
  setActiveTab: PropTypes.func,
};

export default ViewShifting;
