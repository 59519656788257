/* eslint-disable camelcase */
// import core & vendor packages 
import Moment from 'moment';
import Table from 'components/ReactTable';
import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Modal, Accordion } from 'react-bootstrap';

// import components 
import FormUI from 'components/UI/FormUI';

// Import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
  const ViewRequestModal = ({ showProfile, setShowProfile, setState, state }) => {
  const { item_list, filtered_item_list } = state;
  
  // States
  const [groupchange, setGroupchange] = useState('');

  // table action handler
  const actionHandler = (item) => {
    return (
      <Button
        onClick={() => {
          setState((prev) => {
            return { ...prev, selected_item: item };
          });

          setShowProfile((prev) => {
            return { ...prev, viewItem: true, isShowViewRequestModal: false };
          });
        }}
        className='custom-actions-refill-request  text-white px-4 py-2'
        style={{ backgroundColor: '#03A8FF' }}
      >
        View
      </Button>
    );
  };

  const tableColumns = React.useMemo(
    () =>
      state.itemID?.attributes?.status === 'approved' ||
      state.itemID?.attributes?.status === 'in progress'
        ? [
            { Header: 'Item Code', accessor: 'item_code' }, // accessor is the "key" in the data
            { Header: 'Item Name', accessor: 'item_name' },
            { Header: 'Ordered Quantity & UOM', accessor: 'requested_quantity' },

            { Header: 'Partial/Completed', accessor: 'status' },
            { Header: 'Delivered Quantity ', accessor: 'delivered_quantity' },
            { Header: 'Action', accessor: 'action' },
          ]
        : [
            { Header: 'Item Code', accessor: 'item_code' }, // accessor is the "key" in the data
            { Header: 'Item Name', accessor: 'item_name' },

            { Header: 'Ordered Quantity & UOM', accessor: 'requested_quantity' },
            { Header: 'Status', accessor: 'status' },

            { Header: 'Delivered Quantity ', accessor: 'delivered_quantity' },

            { Header: 'Action', accessor: 'action' },
          ],
    [state?.itemID],
  );

  const tableData = React.useMemo(
    () =>
      filtered_item_list
        ? filtered_item_list?.map((dd) => ({
            id: dd?.id,
            item_code: dd?.attributes?.code,
            item_name: dd?.attributes?.name,
            requested_quantity: (
              <p>
                {dd?.quantity} {dd?.attributes?.unit_of_measure}
              </p>
            ),
            status: dd?.status,
            delivered_quantity: dd?.delivered_quantity,
            action: actionHandler(dd),
          }))
        : [],
    [filtered_item_list],
  );

  useEffect(() => {
    setState((prev) => {
      return { ...prev, filtered_item_list: item_list };
    });
  }, []);

  useEffect(() => {
    // store temporary the item list
    let filtered_item_list = item_list;
    if (groupchange !== '') {
      filtered_item_list = item_list.filter((data) => {
        const groupID = data.relationships.group.data[0].id;
        return groupID === groupchange;
      });

      setState((prev) => {
        return { ...prev, filtered_item_list: filtered_item_list };
      });
    }
    // return the original item list
    if (groupchange === '') {
      setState((prev) => {
        return { ...prev, filtered_item_list: filtered_item_list };
      });
    }
  }, [groupchange]);

  useEffect(() => {
    // return the original item list
    if (filtered_item_list) {
      setGroupchange('');
      setState((prev) => {
        return { ...prev, filtered_item_list: item_list };
      });
    }
  }, [showProfile?.isShowViewRequestModal]);
  return (
    <Modal
      size='lg'
      show={showProfile?.isShowViewRequestModal}
      onHide={() => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isShowViewRequestModal: false,
          };
        });
      }}
      aria-labelledby='Request Item Modal'
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>REQUEST Details</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                // setGroupchange('');

                setShowProfile((prevState) => {
                  return {
                    ...prevState,
                    isShowViewRequestModal: false,
                  };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <div className='add-item-form'>
            <Row>
              <Col>
                <div>
                  <Row>
                    <Col className='request-details'>
                      <Form.Group className='inline-group'>
                        <Form.Label>Purchase Order Date/Time :</Form.Label>
                        <span className='ms-5 fw-bold' style={{ color: '#006397' }}>
                          {Moment(state?.itemID?.attributes?.requested_at).format(
                            'MMMM Do YYYY, h:mm:ss a',
                          )}
                        </span>
                      </Form.Group>
                      <Form.Group className='inline-group'>
                        <Form.Label>Purchase Order No.</Form.Label>
                        <span className='ms-5 fw-bold' style={{ color: '#006397' }}>
                          {state?.itemID?.attributes?.request_number}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col className='text-end'>
                      <Form.Group>
                        <Form.Label>Requested By :</Form.Label>
                        <span className='ms-5 fw-bold' style={{ color: '#006397' }}>
                          {state?.itemID?.attributes?.requested_by}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>

                <div className='search-item'>
                  <Accordion className='advanced-search' defaultActiveKey={'0'}>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Items</Accordion.Header>
                      <Accordion.Body>
                        {/* <Row>
                          <Col sm={6}>
                            <Row>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Item Code/Name</Form.Label>
                                  <FormControl
                                    type='text'
                                    onChange={(e) => setEnteredSearch(e.target.value)}
                                    className='bg-white'
                                    placeholder='Search '
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Sort By</Form.Label>
                                  <Form.Select
                                    value={groupchange}
                                    onChange={(e) => setGroupchange(e.target.value)}
                                  >
                                    <option value=''>Item Group</option>
                                    {groups?.map((data) => (
                                      <option key={data.id} value={data.id}>
                                        {data.attributes.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row> */}
                        <div className='search-table mb-4'>
                          <Table data={tableData} columns={tableColumns} pagination={['BOTTOM']} />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ViewRequestModal;
