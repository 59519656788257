/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// import components
import FormUI from 'components/UI/FormUI';
import AddDoctorToPatientTable from './AddDoctorToPatientTable';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const AddDoctorToPatient = ({ showModal, setShowModal }) => {
  const [state, setState] = useState([]);
  const [patients, setPatients] = useState([]);
  const [userData, setUserData] = useState({
    id: '',
    patient_name: '',
    patient_class: '',
    location: '',
  });

  useEffect(() => {
    // console.log('data', userData);
  }, [userData]);

  const handleChange = (key) => (e) => {
    if (e.target.type === 'radio') {
      setUserData({
        ...userData,
        [key]: e.target.value,
      });

      return;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  // Submit data here
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const [searchedWord, setSearchedWord] = useState('');

  /** filter patients */
  const onChangeHandler = (event) => {
    setSearchedWord(event.target.value);
    const searchedWord = event.target.value;
    const newFilter = patients.filter((value) => {
      return value.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState([]);
    }
  };

  const [id, setId] = useState('');
  /** filter ID */
  const onChangeHandlerID = (event) => {
    setId(event.target.value);
    const searchedWord = event.target.value;
    const newFilter = patients.filter((value) => {
      return value.MRN === event.target.value;
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState([]);
    }
  };

  useEffect(() => {

    /** Get patients for search patient */
    axios
      .get('/patient')
      .then((response) => {
        setPatients(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    setState([]);
  }, []);

  return (
    <Modal
      size='lg'
      show={showModal.isAddDoctor}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            isAddDoctor: false,
          };
        })
      }
      aria-labelledby='Add Doctor to Patient'
      className='add-doctor-to-patient-modal'
    >
      <Modal.Body>
        <div className='add-doctor-to-patient-request'>
          <div className='d-flex header'>
            <p>ADD DOCTOR TO PATIENT</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowModal((prevState) => {
                  return { ...prevState, isAddDoctor: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col sm={3}>
                <Form.Group className='mb-4'>
                  <Form.Label>Patient ID</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={id}
                    onChange={onChangeHandlerID}
                  />
                </Form.Group>
              </Col>
              <Col sm={7}>
                <Form.Group className='mb-4'>
                  <Form.Label>Patient Name</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    onChange={onChangeHandler}
                    value={searchedWord}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='category-buttons'>
                  <Button
                    onClick={() => {
                      // Update Data on Table?
                    }}
                    className='add-button'
                    variant='primary'
                    type='submit'
                  >
                    Select
                  </Button>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <AddDoctorToPatientTable state={state} setState={setState} showModal={showModal} />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() => {
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          isAddDoctor: false,
                        };
                      });
                      setState([]);
                      setSearchedWord('');
                    }
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

AddDoctorToPatient.defaultProps = {
  showModal: {},
  setShowModal: () => {},
};

AddDoctorToPatient.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default AddDoctorToPatient;
