/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormControl, Button, Tabs, Tab, Image, InputGroup } from 'react-bootstrap';

import Tasks from './Tasks';
import Project from './Project';
import TabsUI from 'components/UI/TabsUI';
import ConditionalRender from 'components/ConditionalRender';
import { TableFiltersPermission, checkStringPermission } from 'helpers/filteredPermissions';
import { showUsers, getACLState, getRoles, getDepartments, getLogs } from 'reducers/aclReducer';

import './index.scss';

const samplePhoto = 'https://picsum.photos/id/237/200/300';

const ActivitiesContainer = () => {
  const [activeKey, setActiveKey] = useState('');
  const [showScreen, setShowScreen] = useState(1);
  const [requestData, setRequestData] = useState();

  const onChange = (key) => {
    setActiveKey(key);
  };

  const defaultTabs = [
    {
      title: 'Tasks',
      component: Tasks,
    },
    {
      title: 'Project',
      component: Project,
    },
  ];

  // eslint-disable-next-line prefer-const
  let tabs = TableFiltersPermission(defaultTabs, []);

  return (
    <>
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <div className='activities-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ACTIVITIES</Form.Label>

                <FormControl className='blue-control' placeholder='Search Employee' />
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='activities-tabs'>
                <TabsUI>
                  <Tabs
                    defaultActiveKey={tabs[0].title}
                    onSelect={(eventKey) => onChange(eventKey)}
                  >
                    {tabs.map(({ title, component: Component }, idx) => (
                      <Tab key={idx} eventKey={title} title={title}>
                        <Component
                          activeKey={activeKey}
                          setShowScreen={setShowScreen}
                          setRequestData={setRequestData}
                        />
                      </Tab>
                    ))}
                  </Tabs>
                </TabsUI>
              </div>
            </div>
          </div>
        }
      />

      <ConditionalRender
        condition={showScreen === 2}
        renderIf={
          <div className='activities-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>Add Task</Form.Label>
              </Form.Group>
            </div>
            
            <div className='box-container pt-0'>
              <div className='activities-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <br />
                    
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Task Assigned to</Form.Label>
                          
                          <Form.Control className='border-seven' placeholder='Search Employee' />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className='box-container'>
              <Image className='avatar' src={samplePhoto} />

              <Form.Group className='employee-detail'>
                <p>JUAN DELA CRUZ</p>
                <span>Name</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>PHYSICIAN</p>
                <span>Position</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>DETAILS HERE</p>
                <span>Department</span>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='activities-details'>
                <div className='details-content'>    
                  <div className='main-content'>
                    <br />

                    <Form.Group className='vertical-align'>
                      <Form.Label>Task Name</Form.Label>

                      <FormControl placeholder='Enter Here' className='border-seven' />
                    </Form.Group>

                    <Row>
                      <Col>
                      <Form.Group className='vertical-align'>
                        <Form.Label>Start Date:</Form.Label>

                        <FormControl type='date' className='border-seven' placeholder='Enter Here' />
                      </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Due Date:</Form.Label>

                          <FormControl type='date' className='border-seven' placeholder='Enter Here' />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group className='vertical-align'>
                      <Form.Label>Description/Note</Form.Label>

                      <FormControl placeholder='Enter Here' className='border-seven' />
                    </Form.Group>
                    
                    <br />

                    <div className='details-footer'>
                    <Button className='save'>Save</Button>
                    <Button onClick={() => setShowScreen(1)} className='cancel'>
                      Cancel
                    </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <ConditionalRender
        condition={showScreen === 3}
        renderIf={
          <div className='activities-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>Add Task</Form.Label>
              </Form.Group>
            </div>
            
            <div className='box-container pt-0'>
              <div className='activities-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <br />
                    
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Assign Project Manager</Form.Label>
                          
                          <Form.Control className='border-seven' placeholder='Search Employee' />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className='box-container'>
              <Image className='avatar' src={samplePhoto} />

              <Form.Group className='employee-detail'>
                <p>JUAN DELA CRUZ</p>
                <span>Name</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>PHYSICIAN</p>
                <span>Position</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>DETAILS HERE</p>
                <span>Department</span>
              </Form.Group>
            </div>

            <div className='box-container pt-0'>
              <div className='activities-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <br />
                    
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Assign Project to(optional/employees)</Form.Label>
                          
                          <Form.Control className='border-seven' placeholder='Search Employee' />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className='box-container'>
              <Image className='avatar' src={samplePhoto} />

              <Form.Group className='employee-detail'>
                <p>JUAN DELA CRUZ</p>
                <span>Name</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>PHYSICIAN</p>
                <span>Position</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>DETAILS HERE</p>
                <span>Department</span>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='activities-details'>
                <div className='details-content'>    
                  <div className='main-content'>
                    <br />

                    <Form.Group className='vertical-align'>
                      <Form.Label>Project Title</Form.Label>

                      <FormControl placeholder='Enter Here' className='border-seven' />
                    </Form.Group>

                    <Row>
                      <Col>
                      <Form.Group className='vertical-align'>
                        <Form.Label>Start Date:</Form.Label>

                        <FormControl type='date' className='border-seven' placeholder='Enter Here' />
                      </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Due Date:</Form.Label>

                          <FormControl type='date' className='border-seven' placeholder='Enter Here' />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group className='vertical-align'>
                      <Form.Label>Description/Note</Form.Label>

                      <FormControl placeholder='Enter Here' className='border-seven' />
                    </Form.Group>
                    
                    <br />

                    <div className='details-footer'>
                    <Button className='save'>Save</Button>
                    <Button onClick={() => setShowScreen(1)} className='cancel'>
                      Cancel
                    </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default ActivitiesContainer;
