/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, approveDenyUser } from 'reducers/aclReducer';

// import components below
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './ConfirmModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const ConfirmModal = ({ modalType, openModal, setOpenModal }) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    setOpenModal(false);
  };

  if (!modalType) return;

  const isAccept = modalType === 'accept';
  const isDenied = modalType === 'decline';

  const approve = () => {};
  const denyApproveUser = () => {};

  return (
    <Modal className='accept-decline-modal' show={openModal} onHide={closeModal} centered>
      <Modal.Header>
        <h6>CONFIRMATION</h6>
        <Button onClick={closeModal}>
          <CloseSVG />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <ConditionalRender
          condition={isAccept}
          renderIf={
            <Button onClick={approve} className='approve'>
              Accept
            </Button>
          }
        />

        <ConditionalRender
          condition={isDenied}
          renderIf={
            <Button onClick={denyApproveUser} className='remove'>
              Decline
            </Button>
          }
        />

        <Button onClick={closeModal} className='cancel'>
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  openModal: false,
  setOpenModal: () => {},
  data: {},
  searchAllTabs: () => {},
  getDatas: () => {},
};

ConfirmModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  data: PropTypes.object,
  searchAllTabs: PropTypes.func,
  getDatas: PropTypes.func,
};

export default ConfirmModal;
