// main components below
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// import components
import Filter from './components/Filter';
// import UserClearance from './UserClearance';
import MedicalRecordActionTable from './components/Table';
import StatusClearanceListTable from './components/ViewTable';
import { selectPatientData } from 'reducers/patientReducer';
import ViewRecord from './view/index';

// import assets below
import './style.scss';

// main component
const MedicalRecords = () => {
  // eslint-disable-next-line no-unused-vars
  const [showModal, setShowModal] = useState({
    viewRecordModal: false,
    profileId: null,
    profileData: null,
    isViewStatus: false,
    isProfileShow: false,
  });

  const [filter, setFilter] = useState({
    patient_name: '',
    bed_category: '',
    status: '',
  });

  const [filteredData, setFilteredData] = useState([]);

  const data = useSelector(selectPatientData);

  return (
    <>
      <div className='medicalrecord mt-4'>
        {!showModal.isProfileShow ? (
          <>
            <Filter
              data={data}
              filter={filter}
              setFilter={setFilter}
              setFilteredData={setFilteredData}
            />
            <div className='medicalrecordList'>
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className='fw-bold mb-3'>Patient List</h5>
              </div>
              {!showModal.isViewStatus ? (
                <MedicalRecordActionTable
                  filter={filter}
                  data={data}
                  filteredData={filteredData}
                  setShowModal={setShowModal}
                />
              ) : (
                <StatusClearanceListTable setShowModal={setShowModal} />
              )}
            </div>
          </>
        ) : (
          <>Test Here</>
          // <UserClearance showModal={showModal} setShowModal={setShowModal} />
        )}
      </div>
      <ViewRecord showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default MedicalRecords;
