/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState } from 'react';

// import components below
import PurchaseOrderList from './components/PurchaseOrderList';
import RequestToRepair from './components/RequestToRepair';

// import assets below
import './index.scss';

// main Component
const RepairMaintenance = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    isShowProfile: false,
    profileId: null,
    showView: false,
    profileData: null,
    showRequestRepair: false,
  });

  return (
    <div className='repair-maintenance'>
      <PurchaseOrderList
        setState={setState}
        showProfile={showProfile}
        setShowProfile={setShowProfile}
      />

      {/* Modals */}

      <RequestToRepair showProfile={showProfile} setShowProfile={setShowProfile} />
    </div>
  );
};

export default RepairMaintenance;
