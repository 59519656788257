/* eslint-disable */
import React from 'react';
import { useTable, usePagination } from 'react-table';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

import BTable from 'react-bootstrap/Table';
import TableUI from 'components/UI/TableUI';
import TablePagination from 'components/TablePagination/Pagination';
import ConditionalRender from 'components/ConditionalRender';

import './style.scss';
import { ReactComponent as ViewSVG } from 'assets/svg/eye-icon.svg';
import { ReactComponent as UserSVG } from 'assets/svg/user_circle.svg';
import { ReactComponent as EditSVG } from 'assets/svg/writing-green.svg';
import { ReactComponent as ApproveSVG } from 'assets/svg/circle-check.svg';
import { ReactComponent as DeleteSVG } from 'assets/svg/circle-xmark-red.svg';
import { ReactComponent as ThreeDotsSVG } from 'assets/svg/three-dots-svgrepo-com.svg';

const defaultLogs = {
  error: null,
  isSuccess: true,
  isFailed: false,
  isLoading: false,
};

export default ({
  data,
  filter,
  actions,
  columns,
  onAction,
  pagination,
  customAction,
  defaultPageSize = 10,
  logs = defaultLogs,
}) => {
  if (actions || customAction) {
    Object.assign(
      columns,
      [...columns, { Header: 'Action', accessor: 'action' }].filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t.Header === value.Header && t.accessor === value.accessor),
      ),
    );
  }

  if (actions && !customAction) {
    Object.assign(
      data,
      data.map((dd) => ({ ...dd, action: RenderAction(dd.id, actions, onAction) })),
    );
  } else if (!actions && customAction) {
    Object.assign(
      data,
      data.map((dd) => ({ ...dd, action: customAction(dd.id, dd) })),
    );
  }

  const config = { columns, data, initialState: { pageIndex: 0 } };
  const tableProps = useTable(config, usePagination);

  const {
    page,
    pageCount,
    prepareRow,
    canNextPage,
    headerGroups,
    getTableProps,
    canPreviousPage,
    getTableBodyProps,
    state: { pageIndex, pageSize },
  } = tableProps;

  const isSuccess = logs.isSuccess === true;
  const isLoading = logs.isLoading === true;
  const isFailed = logs.isFailed === true;

  return (
    <>
      <TableUI>
        {(!!filter || pagination?.includes('TOP')) && (
          <div className='filter mt-3'>
            {filter?.top}
            {pagination?.includes('TOP') && (
              <TablePagination pageSize={defaultPageSize} total={data.length} {...tableProps} />
            )}
          </div>
        )}
        <BTable {...getTableProps()} responsive>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={`tr-${i}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, k) => (
                  <th key={`th-${k}`} {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Success Load */}
          <ConditionalRender
            condition={isSuccess}
            renderIf={
              <tbody {...getTableBodyProps()}>
                {!!data.length ? (
                  page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr key={`tr-${i}`} {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>No record found.</td>
                  </tr>
                )}
              </tbody>
            }
          />

          {/* Loading */}
          <ConditionalRender
            condition={isLoading}
            renderIf={
              <tbody>
                <tr>
                  <td>Loading</td>
                </tr>
              </tbody>
            }
          />

          {/* Error Occured */}
          <ConditionalRender
            condition={isFailed}
            renderIf={
              <tbody>
                <tr>
                  <td>Error Occured ({logs.error})</td>
                </tr>
              </tbody>
            }
          />
        </BTable>
        {(!!filter || pagination?.includes('BOTTOM')) && (
          <div className='filter mt-3'>
            {filter?.bottom}
            {pagination?.includes('BOTTOM') && (
              <TablePagination total={data.length} {...tableProps} />
            )}
          </div>
        )}
      </TableUI>
    </>
  );
};

function RenderAction(id, actions, onAction) {
  const {
    hasEyeView = false,
    hasProfile = false,
    hasView = false,
    hasEdit = false,
    hasDelete = false,
    hasApprove = false,
  } = actions;

  return (
    <OverlayTrigger
      trigger='click'
      placement='left'
      rootClose
      overlay={
        <Popover id='popover-positioned-bottom'>
          <Popover.Body className='d-flex justify-content-between p-0'>
            {hasEyeView && (
              <ViewSVG
                className='p-1'
                onClick={() => onAction('profile', id)}
                style={{ width: '2em', height: '2em', cursor: 'pointer' }}
              />
            )}
            {hasProfile && (
              <UserSVG
                className='p-1'
                onClick={() => onAction('profile', id)}
                style={{ width: '2em', height: '2em', cursor: 'pointer' }}
              />
            )}
            {hasView && (
              <ViewSVG
                className='p-1'
                onClick={() => onAction('view', id)}
                style={{ width: '2em', height: '2em', cursor: 'pointer' }}
              />
            )}
            {hasEdit && (
              <EditSVG
                className='p-1'
                onClick={() => onAction('edit', id)}
                style={{ width: '2em', height: '2em', cursor: 'pointer' }}
              />
            )}
            {hasDelete && (
              <DeleteSVG
                className='p-1'
                onClick={() => onAction('delete', id)}
                style={{ width: '2em', height: '2em', cursor: 'pointer' }}
              />
            )}
            {hasApprove && (
              <ApproveSVG
                className='p-1'
                onClick={() => onAction('approve', id)}
                style={{ width: '2em', height: '2em', cursor: 'pointer', fill: '#00c41a' }}
              />
            )}
          </Popover.Body>
        </Popover>
      }
    >
      <Button variant='secondary' className='popoverButton bg-transparent p-0 border-0'>
        <ThreeDotsSVG style={{ width: '1.5em' }} />
      </Button>
    </OverlayTrigger>
  );
}

export const PAGE_SIZE = [5, 10, 20, 30, 100];
