/* eslint-disable */
// import core & vendor packages below
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal, Accordion, Table } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import seturlParams from 'helpers/seturlParams';
import TablePagination from './TablePagination';
import { getReorderRequest } from 'reducers/refillRequest';
import ConditionalRender from 'components/ConditionalRender';
import { getGroupItems, selectInventory } from 'reducers/inventoryReducer';
import {
  getItems,
  submitRequestItem,
  selectDepartmentInventory,
} from 'reducers/departmentInventoryReducer';

// import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as CirlePlusSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CirleMinusSVG } from 'assets/svg/circle-minus.svg';

// main component
const PurchaseRequestModal = ({
  showModal,
  showProfile,
  setShowModal,
  departmentID,
  setShowProfile,
}) => {
  // Redux
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectDepartmentInventory);
  const { itemData, itemMeta, submitRequestItemLogs, itemIncluded } = inventoryState;

  const groupState = useSelector(selectInventory);
  const { inventoryGroupData, inventoryGroupItemsLogs } = groupState;

  // States
  const [search, setSearch] = useState({
    code: '',
    itemGroup: '',
  });
  const [canAdd, setCanAdd] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [finalItem, setFinalItem] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [selectedCheck, setSelectedCheck] = useState({});
  const [showResultsNumber, setShowResultsNumber] = useState(5);
  const [trigger, setTrigger] = useState(false);
  // Api call
  const getInventoryItems = (clear = false) => {
    let searchURL;
    let groupID;
    let params;

    if (clear) {
      searchURL = '';
      groupID = '';

      setSearch({
        code: '',
        itemGroup: '',
      });
    } else {
      searchURL = seturlParams(search?.code, 'q');
      groupID = seturlParams(search?.itemGroup, 'group_id');
    }

    params = `?include[]=group${searchURL}&per_page=${showResultsNumber}&is_new=0${groupID}`;

    dispatch(getItems(params));
    dispatch(getGroupItems('?q=&per_page=100'));
  };

  // Change page based on selected arrow
  const changePage = (page, showResult = showResultsNumber) => {
    const searchURL = seturlParams(search?.code, 'q');

    const params = `?include[]=group${searchURL}&per_page=${showResult}&page=${page}&is_new=0`;
    dispatch(getItems(params));
  };

  // Get group name from included api
  const getGroupName = (id) => {
    const selectedArray = itemIncluded.filter((data) => data.id === id);

    if (selectedArray.length === 0) {
      return 'N/A';
    }

    return selectedArray[0]?.attributes?.name;
  };

  // Change input and checkbox change
  const handleDataChange = (key, type) => (e) => {
    if (type === 'search') {
      setSearch({
        ...search,
        [key]: e.target.value,
      });
    }

    if (type === 'check') {
      setSelectedCheck({
        ...selectedCheck,
        [key?.id]: !selectedCheck[key?.id],
      });

      checkIfCanAdd({
        ...selectedCheck,
        [key?.id]: !selectedCheck[key?.id],
      });
    }
  };

  // Function to check/uncheck all items
  const toggleCheckAll = (e) => {
    let object = {};

    itemData.forEach((data) => {
      object[data?.id] = e.target.checked;
    });

    setCheckAll(e.target.checked);
    setSelectedCheck(object);
    checkIfCanAdd(object);
  };

  // Add Item to final list
  const addItem = (data) => {
    const toAdd = {
      id: data.id,
      quantity: 0,
      data: data,
      unit: data?.attributes?.unit_of_measure,
    };

    let duplicate = false;
    finalItem.forEach((finalData) => {
      if (finalData?.id === data.id) {
        duplicate = true;
      }
    });

    if (duplicate) {
      toast.warning(`Duplicate Item (${data?.attributes?.name})`);
    } else {
      setFinalItem((prevState) => {
        return [toAdd].concat(prevState);
      });
    }

    setSelectedCheck((prevState) => {
      return { ...prevState, [data?.id]: false };
    });
  };

  // Delete Item from final
  const deleteItem = (data) => {
    const newData = finalItem.filter((mapData) => mapData?.id !== data?.id);

    setFinalItem(newData);
  };

  // Add item using the checkbox
  const addSelectedItems = () => {
    itemData.forEach((data) => {
      if (selectedCheck[data?.id] === true) {
        addItem(data);
      }
    });

    setCheckAll(false);
    setCanAdd(true);
  };

  // Check whether you can add checked items
  const checkIfCanAdd = (checkData) => {
    let condition = true;

    itemData.forEach((data) => {
      if (checkData[data?.id]) {
        condition = false;
        return;
      }
    });

    setCanAdd(condition);
    return;
  };

  // Change quantity based on selected final item
  const handleChange = (e, data) => {
    const { value, name } = e.target;

    const newData = finalItem.map((mapData) => {
      if (mapData.id === data?.id) {
        return { ...data, [name]: value };
      }

      return mapData;
    });

    setFinalItem(newData);
  };

  // Change quantity based on selected final item
  const changeFinalQuantity = (e, data, idx) => {
    const newData = finalItem.map((mapData) => {
      if (mapData.id === data?.id) {
        return { ...data, quantity: e.target.value };
      }

      return mapData;
    });

    setFinalItem(newData);
  };

  // Handle POST api
  const handleSubmit = () => {
    const params = {
      department_id: 10,
      restock_type: 'reorder',
      request_data: finalItem.map((data) => {
        return {
          item_id: data?.id,
          quantity: data?.quantity,
          unit_of_measure: data?.unit,
        };
      }),
    };
    setTrigger(true);
    dispatch(submitRequestItem(params));
  };

  useEffect(() => {
    getInventoryItems();
  }, []);
  useEffect(() => {}, []);
  useEffect(() => {}, [finalItem]);

  useEffect(() => {
    // console.log(selectedCheck);
    // console.log('IS DISABLED?', canAdd);
  }, [selectedCheck, canAdd]);

  useEffect(() => {
    let object = {};

    itemData.forEach((data) => {
      object[data?.id] = false;
    });

    setSelectedCheck(object);
  }, [itemData]);

  // Get Submit Data result
  useEffect(() => {
    const { isLoading, isFailed, isSuccess, error } = submitRequestItemLogs;

    if (isFailed && trigger) {
      toast.error(`${error[0]?.detail} (${error[0]?.code})`);
      console.log(error);
      setTrigger(false);
    }

    if (isSuccess && trigger) {
      setFinalItem([]);
      setShowProfile((prev) => {
        return { ...prev, isPurchaseRequestModal: false };
      });
      dispatch(getReorderRequest({ restock_type: 'reorder' }));
      toast.success('Successfully added request item!');
      setTrigger(false);
    }
  }, [submitRequestItemLogs]);

  // Set Data to group List
  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = inventoryGroupItemsLogs;

    if (isSuccess) {
      setGroupList(inventoryGroupData);
    }
  }, [inventoryGroupItemsLogs]);

  // Conditions
  const haveItems = itemData && itemData.length > 0;
  const isLoading = submitRequestItemLogs.isLoading === true;
  const isSuccess = submitRequestItemLogs.isSuccess === true;
  const isFailed = submitRequestItemLogs.isFailed === true;

  return (
    <Modal
      size='lg'
      show={showProfile?.isPurchaseRequestModal}
      onHide={() => {
        setShowProfile((prev) => {
          return { ...prev, isPurchaseRequestModal: false };
        });
      }}
      aria-labelledby='Request Item Modal'
      className='department-inventory request-item'
    >
      <Modal.Body>
        <div className='department-inventory-header'>
          <div className='d-flex header'>
            <p>REQUEST ITEM TO INVENTORY</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prev) => {
                  return { ...prev, isPurchaseRequestModal: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='add-item-form'>
            <Row>
              <Col>
                <div className='search-item'>
                  <Accordion className='advanced-search' defaultActiveKey={'0'}>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Search Item</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col sm={9}>
                            <Row>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Item Code/Name</Form.Label>
                                  <FormControl
                                    type='text'
                                    value={search?.code}
                                    onChange={(e) => {
                                      handleDataChange('code', 'search')(e);

                                      if (e.target.value === '') {
                                        getInventoryItems(true);
                                      }
                                    }}
                                    className='bg-white'
                                    placeholder='Search Here'
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Item Group</Form.Label>
                                  <Form.Select
                                    value={search?.itemGroup}
                                    onChange={handleDataChange('itemGroup', 'search')}
                                  >
                                    {groupList.map((data) => {
                                      return (
                                        <option key={data?.id} value={data?.id}>
                                          {data?.attributes?.name}
                                        </option>
                                      );
                                    })}
                                    <option value='' hidden>
                                      -Select-
                                    </option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col>
                                <div className='buttonWrapper'>
                                  <Button
                                    variant='search'
                                    onClick={() => {
                                      getInventoryItems();
                                    }}
                                  >
                                    GO
                                  </Button>

                                  <Button
                                    variant='secondary'
                                    onClick={() => {
                                      getInventoryItems(true);
                                    }}
                                  >
                                    RESET
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={3}>
                            <div className='buttonWrapper add-item'>
                              <Button
                                onClick={addSelectedItems}
                                disabled={canAdd}
                                className={`add-item-button ${!canAdd}`}
                              >
                                Add Selected Item
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div className='search-table mb-4'>
                  <TableUI>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>
                            <Form.Check checked={checkAll} onChange={toggleCheckAll} />
                          </th>
                          <th>Item Code</th>
                          <th>Item Name</th>
                          <th>Item Group</th>
                          <th>Generic Name</th>
                          <th>Manufacturer</th>
                          <th>Storage Bin</th>
                          <th>Section</th>
                          <th>UOM</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ConditionalRender
                          condition={haveItems}
                          renderIf={
                            <>
                              {itemData.map((data, idx) => {
                                const { attributes } = data;

                                return (
                                  <tr key={idx}>
                                    <td>
                                      <Form.Check
                                        type='checkbox'
                                        checked={selectedCheck[data?.id]}
                                        onChangeCapture={(e) => {
                                          handleDataChange(data, 'check')(e);
                                        }}
                                      />
                                    </td>
                                    <td>{attributes?.code}</td>
                                    <td>{attributes?.name}</td>
                                    <td>{getGroupName(data?.id)}</td>
                                    <td>{attributes?.generic_name}</td>
                                    <td>{attributes?.manufacturer}</td>
                                    <td>{attributes?.storage_bin}</td>
                                    <td>{attributes?.section}</td>
                                    <td>{attributes?.unit_of_measure}</td>
                                    <td>
                                      <CirlePlusSVG
                                        onClick={() => {
                                          addItem(data);
                                        }}
                                        style={{ fill: '#01B425', width: '2em', cursor: 'pointer' }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          }
                        />
                      </tbody>
                    </Table>
                  </TableUI>

                  <TablePagination
                    meta={itemMeta}
                    changePage={changePage}
                    showResultsNumber={showResultsNumber}
                    setShowResultsNumber={setShowResultsNumber}
                  />
                </div>

                <Accordion className='advanced-search' defaultActiveKey={'0'}>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Items</Accordion.Header>
                    <Accordion.Body>
                      <div className='search-table mb-4'>
                        <TableUI>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Item Code</th>
                                <th>Item Name</th>
                                <th>Item Group</th>
                                <th>Storage Bin</th>
                                <th>Quantity</th>
                                <th>UOM</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              <ConditionalRender
                                condition={finalItem.length > 0}
                                renderIf={
                                  <>
                                    {finalItem.map((data, idx) => {
                                      const { attributes } = data.data;

                                      return (
                                        <tr key={idx}>
                                          <td>{attributes?.code}</td>
                                          <td>{attributes?.name}</td>
                                          <td>{getGroupName(data?.id)}</td>
                                          <td>{attributes?.storage_bin}</td>
                                          <td>
                                            <FormControl
                                              type='number'
                                              value={data?.quantity}
                                              autoComplete='new-off'
                                              onChange={(e) => {
                                                changeFinalQuantity(e, data, idx);
                                              }}
                                              onKeyDown={(evt) =>
                                                (evt.key === 'e' || evt.key === 'E' ||
                                                  evt.key === '-' ||
                                                  evt.key === '.') &&
                                                evt.preventDefault()
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Form.Select
                                              name='unit'
                                              value={data?.unit}
                                              onChange={(e) => {
                                                handleChange(e, data);
                                              }}
                                            >
                                              <option value='' hidden>
                                                Select Unit
                                              </option>
                                              <option value='piece'>Piece</option>
                                              <option value='box'>Box</option>
                                            </Form.Select>
                                          </td>
                                          <td>
                                            <CirleMinusSVG
                                              onClick={() => {
                                                deleteItem(data);
                                              }}
                                              style={{
                                                width: '2em',
                                                fill: '#01B425',
                                                cursor: 'pointer',
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                }
                              />
                            </tbody>
                          </Table>
                        </TableUI>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    variant='primary'
                    disabled={isLoading}
                    className='add-button'
                    onClick={handleSubmit}
                  >
                    Create
                  </Button>

                  <Button
                    variant='secondary'
                    className='clear-button'
                    onClick={() => {
                      setFinalItem([]);
                    }}
                  >
                    Clear
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default PurchaseRequestModal;
