import React from 'react';
import { Row, Col } from 'react-bootstrap';

// import CompanyLogo from 'assets/images/logo2.png';
import DoctorLogo from 'assets/images/acl-logo.png';

import './index.scss';

const Header = () => {
  return (
    <div className='acl-header'>
      <Row>
        <Col className='header-cont'>
          {/* <img src={CompanyLogo} alt='Company Logo' /> */}

          <div className='hr' />

          <div className='text-header'>
            <h1>
              Welcome To <span>{process.env.REACT_APP_FULL_NAME}</span>
            </h1>
          </div>

          <div className='doctor-header'>
            <img src={DoctorLogo} alt='doctor' />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
