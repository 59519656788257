/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';
import moment from 'moment';

// import components below
import { overtimeStatus } from 'helpers';
import Table from 'components/ReactTable';
import { getOvertimesList, selectOvertimeTracking } from 'reducers/overtimeTrackingReducer';

//  import assets below
import './index.scss';

//  main component
const samplePhoto = 'https://picsum.photos/id/237/200/300';

const index = () => {
  const dispatch = useDispatch();
  
  const overtimeTrackingState = useSelector(selectOvertimeTracking);
  const { overtimeListData, overtimeListLogs } = overtimeTrackingState;
  const [tableList, setTableList] = useState([]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Avatar', accessor: 'avatar' },
      { Header: 'ID', accessor: 'employee_id' },
      { Header: 'Name', accessor: 'fullname' },
      { Header: 'Date', accessor: 'overtime_date' },
      { Header: 'Time-in', accessor: 'time_in' },
      { Header: 'Time-out', accessor: 'time_out' },
      { Header: 'status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      tableList?.map((dd) => ({
        data: dd,
        id: dd?.id,
        employee_id: dd?.employee_id,
        overtime_date:moment(dd?.overtime_date).format('MM-DD-YYYY'),
        time_in: dd?.time_in,
        time_out: dd?.time_out,
        status: overtimeStatus(dd?.status),
      })),
    [tableList],
  );
  renderOnMount(dispatch);

  renderOnSuccessCallback(setTableList, overtimeListData, overtimeListLogs);
  return (
    <div className='employee-list'>

      <div className='employee-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
        />
      </div>
    </div>
  );
};

const renderOnMount = (dispatch) => {
  useEffect(() => {
    dispatch(getOvertimesList());
  }, []);
};

const renderOnSuccessCallback = (setTableList, overtimeListData, overtimeListLogs) => {
  useEffect(() => {
    const { isSuccess, isFailed } = overtimeListLogs;
    
    if (isSuccess) {
      const newData = overtimeListData.map((dd) => {
        return {
        data: dd,
        id: dd?.id,
        employee_id: dd?.employee_id,
        overtime_date: dd?.overtime_date,
        time_in: dd?.time_in,
        time_out: dd?.time_out,
        status: dd?.status,
        };

      });
      console.log(newData);
      setTableList(newData);
    }

  }, [overtimeListLogs]);

};

export default index;
