/* eslint-disable no-unused-vars */
// Packages
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import AddModal from './components/AddModal';

// CSS
import './index.scss';
import { ReactComponent as EditSVG } from 'assets/svg/edit.svg';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus-white.svg';
import { ReactComponent as DeleteSVG } from 'assets/svg/circle-xmark-red.svg';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { selectIncomeStatementDate, getIncomeStatementDate } from 'reducers/accountingReducer';

// Main Component
const IncomeStatement = () => {
  const dispatch = useDispatch();
  const date = useSelector(selectIncomeStatementDate);

  const [openModal, setOpenModal] = useState({
    type: '',
    isOpen: false,
    singleData: null,
  });

  const [data, setData] = useState({
    income: [],
    expenses: [],
  });
  // const [date, setDate] = useState('');

  const [fields, setFields] = useState({
    amount: null,
    details: '',
  });

  const handleAddField = (type) => {
    setOpenModal({
      type: type,
      isOpen: true,
      singleData: null,
    });
  };

  const handleUpdateField = (type, data) => {
    setOpenModal({
      type: type,
      isOpen: true,
      singleData: data,
    });
    setFields({
      ...data,
      type: type,
      date: date,
    });
  };

  const handleDateChange = (e) => {
    // !!REVISIT: for refactoring
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/income_statement?search=${e.target.value}`)
      .then((response) => {
        setData({
          income: response.data?.data?.income_list,
          expenses: response.data?.data?.expenses_list,
        });
        // setDate(e.target.value);
        dispatch(getIncomeStatementDate(e.target.value));
      })
      .catch((error) => console.log('Error fetching financial assets data: ', error));
  };

  const handleFieldChange = (type) => (e) => {
    setFields({
      ...fields,
      type: type,
      date: date,
      [e.target.name]: e.target.value,
    });
  };

  const handlePostRequest = () => {
    // !!REVISIT: for refactoring
    axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/income_statement`,
      fields,
    ).then((response) => {
      if (response?.data?.data.length) {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/income_statement?search=${date}`)
            .then((response) => {
              setData({
                income: response.data?.data?.income_list,
                expenses: response.data?.data?.expenses_list,
              });
            })
            .catch((error) => console.log('Error fetching financial assets data: ', error));
      }
    })
    .catch((error) => console.log('Error posting financial assets data: ', error));
  };

  const handleUpdateRequest = () => {
    // !!REVISIT: for refactoring
    axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/income_statement`,
      fields,
    ).then((response) => {
      console.log('status update: ', response);
      if (response?.data?.status === 'Success') {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/income_statement?search=${date}`)
            .then((response) => {
              setData({
                income: response.data?.data?.income_list,
                expenses: response.data?.data?.expenses_list,
              });
            })
            .catch((error) => console.log('Error fetching financial assets data: ', error));
      }
    })
    .catch((error) => console.log('Error updating financial assets data: ', error));
  };

  const handleDeleteRequest = (id) => {
    // !!REVISIT: for refactoring
    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/income_statement/${id}`)
    .then((deleted) => {
      console.log(deleted.data);
      if (deleted.data?.status === 'Success') {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/income_statement?search=${date}`)
          .then((response) => {
            setData({
              income: response.data?.data?.income_list,
              expenses: response.data?.data?.expenses_list,
            });
          })
          .catch((error) => console.log('Error fetching financial assets data: ', error));
        return;
      }
    })
    .catch((error) => console.log('Error deleting financial assets data: ', error));
  };

  useEffect(() => {
    date === '' && setData({
      income: [],
      expenses: [],
    });
  }, [date]);

  const calculateTotalIncome = () => {
    const total = data?.income.length ? _.sum(_.map(data?.income, (income) => income?.amount)) : 0;
    return total === 0 ? 0 : total;
  };

  const calculateTotalExpenses = () => {
    const total = data?.expenses.length ? _.sum(_.map(data?.expenses, (expenses) => expenses?.amount)) : 0;
    return total === 0 ? 0 : total;
  };

  const calculateNetProfit = () => {
    const totalIncome = calculateTotalIncome();
    const totalExpenses = calculateTotalExpenses();

    return totalIncome - totalExpenses;
  };

  return (
    <div className='income-statement'>
      <FormUI>
        <Form>
          <Row>
            <Col>
              <div className='income-details'>
                <Row className='mb-4'>
                  <Col sm={3}>
                    <Form.Group className='inline-label'>
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type='date'
                        placeholder='Enter Here'
                        onChange={handleDateChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {date && (
                  <>
                    <h5 className='mt-2 mb-4'>Income List:</h5>

                    <Row className='mb-4 income-container'>
                      <Button
                        onClick={() => {
                          handleAddField('Income');
                        }}
                        className='mb-4 add-field'
                      >
                        <AddSVG />
                        Add Field
                      </Button>

                      <Col className='p-0'>
                        {data?.income.length > 0 &&
                          data?.income.map((row) => {
                            return (
                              <Row className='mb-4' key={row?.id}>
                                <Col md={4} className='asset-name'>
                                    { row?.details }
                                </Col>
                                <Col md={1}>
                                    { row?.amount }
                                </Col>
                                <Col md={2} className='icon'>
                                    <EditSVG className='p-1' onClick={() => handleUpdateField('Income', row)} />
                                    <DeleteSVG className='p-1' onClick={() => handleDeleteRequest(row.id)} />
                                </Col>
                              </Row>
                            );
                          })}

                        <div className='mb-3 form-group-inline total-group'>
                          <p>
                            Total Income: <span>Php {calculateTotalIncome()}</span>
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <hr />

                    <h5 className='mt-2 mb-4'>Expense List:</h5>

                    <Row className='mb-4 expense-container'>
                      <Button
                        onClick={() => {
                          handleAddField('Expenses');
                        }}
                        className='mb-4 add-field'
                      >
                        <AddSVG />
                        Add Field
                      </Button>

                      <Col className='p-0'>
                        {data?.expenses?.length > 0 &&
                          data?.expenses?.map((row) => {
                            return (
                              <Row className='mb-4' key={row?.id}>
                                <Col md={4} className='asset-name'>
                                    { row?.details }
                                </Col>
                                <Col md={1}>
                                    { row?.amount }
                                </Col>
                                <Col md={2} className='icon'>
                                    <EditSVG className='p-1' onClick={() => handleUpdateField('Expenses', row)} />
                                    <DeleteSVG className='p-1' onClick={() => handleDeleteRequest(row.id)} />
                                </Col>
                              </Row>
                            );
                          })}

                        <div className='mb-3 form-group-inline total-group'>
                          <p>
                            Total Expenses: <span>Php {calculateTotalExpenses()}</span>
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <div className='bold' />

                    <Row className='mt-4 mb-2'>
                      <Col className='total-cont'>
                        <p>
                          Net Profit: <span>Php {calculateNetProfit()}</span>
                        </p>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </FormUI>

      {/* Modal */}
      <AddModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        // handleAddFieldSubmit={handleAddFieldSubmit}
        handleUpdateRequest={handleUpdateRequest}
        handleFieldChange={handleFieldChange}
        fields={fields}
        setFields={setFields}
        handlePostRequest={handlePostRequest}
      />
    </div>
  );
};

export default IncomeStatement;
