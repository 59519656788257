/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const showUsers = createAsyncThunk('All', async (payload = {}, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `api/users?${payload.url}`,
    baseURL: process.env.REACT_APP_API_BASE_ACL,
  })
    .then((response) => {
      return { data: response.data, request_type: payload.department || payload.role };
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const getProfile = createAsyncThunk(
  'Get Profile',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/users/profile`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showUser = createAsyncThunk('Show User', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `api/users/${id}`,
    baseURL: process.env.REACT_APP_API_BASE_ACL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const getRoles = createAsyncThunk('Get Roles', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `api/roles`,
    baseURL: process.env.REACT_APP_API_BASE_ACL,
    params: params,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const getDepartments = createAsyncThunk(
  'Get Departments',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/departments`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
      params: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getLogs = createAsyncThunk('Get Logs', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `api/logs${params}`,
    baseURL: process.env.REACT_APP_API_BASE_ACL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const deleteUser = createAsyncThunk('Delete User', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'delete',
    url: `api/users/${id}`,
    baseURL: process.env.REACT_APP_API_BASE_ACL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const approveDenyUser = createAsyncThunk(
  'Deny User',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `api/users/${params?.id}/status`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
      data: params?.payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getPermissions = createAsyncThunk(
  'Get Permissions',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/permissions`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getPermissionsCategories = createAsyncThunk(
  'Get Permission Categories',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/permissions/categories${params}`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updatePermission = createAsyncThunk(
  'Update Permissions',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `api/users/${params.id}/permissions`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
      data: params.payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateProfile = createAsyncThunk(
  'signup/updateProfile',
  async (params = {}, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      data: params?.payload,
      url: `/api/users/${params?.id}`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
