/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { Row, Form, Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// import components below
import Modal from './Modal';
import Text from 'components/Text';
import FormLoginUI from 'components/UI/FormLoginUI';
import ConditionalRender from 'components/ConditionalRender';
import {
  getAllRoles,
  selectSignup,
  registerUser,
  resetSubmitLogs,
  getSubSpecialties,
  getAllDepartments,
  getAllSpecialties,
} from 'reducers/signupReducer';

// import assets below
import './Form.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/chevron-left.svg';

// main component
const FormSignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signupStore = useSelector(selectSignup);
  const { data, submitLogs, rolesData, departmentsData, subData } = signupStore;

  const [openModal, setOpenModal] = useState(false);

  // Form Options
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [specialties, setSpecialties] = useState({
    subSpecialty: [],
    parentSpecialty: [],
  });

  // Form Values
  const [formData, setFormData] = useState({});

  const getDepartments = () => {
    dispatch(getAllDepartments({ per_page: 100000 }));
  };

  const getRoles = () => {
    dispatch(getAllRoles({ per_page: 100000 }));
  };

  const getSpecialties = () => {
    dispatch(getAllSpecialties({ per_page: 100000 /** , is_sub_position: false */ }));
  };

  const apiSubSpecialties = () => {
    dispatch(getSubSpecialties({ per_page: 100000, is_sub_position: true }));
  };

  const signup = () => {
    const res = checkForEmptyFields();

    if (res) {
      toast.error('Fill in the empty fields');
      return;
    }

    let specialty = [];
    let subSpecialty = [];

    // eslint-disable-next-line prefer-const
    let data = {
      management: 'non',
      id_no: formData?.idNo,
      email: formData?.email,
      remarks: formData?.remarks,
      role: formData?.role?.value,
      password: formData?.password,
      username: formData?.username,
      last_name: formData?.lastname,
      first_name: formData?.firstname,
      middle_name: formData?.middlename,
      contact_number: formData?.contactNumber,
      department_id: formData?.department?.value,
    };

    if (formData?.department === '') {
      toast.error('Please Select Department');
      return;
    }

    if (formData?.role === 'Doctor') {
      data.default_professional_fee = formData?.professionalFee;

      specialty = formData?.specialty.map((data) => {
        return data?.id;
      });

      subSpecialty = formData?.subSpecialty.map((data) => {
        return data?.id;
      });

      data.positions = specialty.concat(subSpecialty);
    }

    if (formData?.contactNumber === '') {
      toast.error('Contact number is required');
      return;
    }

    if (formData?.contactNumber.length !== 11) {
      toast.error('Contact number must have 11 digits.');
      return;
    }

    if (formData?.role === '') {
      toast.error('Please Select Role');
      return;
    }

    dispatch(registerUser(data));
  };

  const checkForEmptyFields = () => {
    const {
      role,
      idNo,
      email,
      remarks,
      username,
      lastname,
      password,
      firstname,
      department,
      middlename,
      contactNumber,
    } = formData;

    if (!department) return true;

    // eslint-disable-next-line prefer-const
    let dataArr = [
      idNo,
      role,
      email,
      remarks,
      password,
      username,
      lastname,
      firstname,
      middlename,
      department,
      contactNumber,
    ];

    if (+department === 1 && role === 'Doctor') {
      const { professionalFee, specialty /* subSpecialty */ } = formData;
      dataArr.push(professionalFee, specialty /* ,subSpecialty */);
    }

    const resultEmpty = dataArr.includes('');
    const resultUndefined = dataArr.includes(undefined);
    const finalResult = resultEmpty || resultUndefined;

    return finalResult;
  };

  const handleDepartment = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        role: '',
        department: e,
      };
    });

    allRoles.forEach((roleData) => {
      if (parseInt(e?.value) === parseInt(roleData.id)) {
        const { acceptedRoles } = roleData;

        const newFilteredRoles = roles.filter((data) => {
          const { name } = data.attributes;
          const result = acceptedRoles.indexOf(name);
          if (result > -1) {
            return data;
          }
        });

        setFilteredRoles(newFilteredRoles);
      }
    });
  };

  const handleRole = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        role: e,
      };
    });
  };

  const handleChange = (e) => {
    const { value, type, name } = e.target;

    // if (name === 'department') {
    //   const { value, name } = e.target;

    //   setFormData((prev) => {
    //     return {
    //       ...prev,
    //       [name]: value,
    //       role: '',
    //     };
    //   });

    //   allRoles.forEach((roleData) => {
    //     if (parseInt(value) === parseInt(roleData.id)) {
    //       const { acceptedRoles } = roleData;

    //       const newFilteredRoles = roles.filter((data) => {
    //         const { name } = data.attributes;
    //         const result = acceptedRoles.indexOf(name);
    //         if (result > -1) {
    //           return data;
    //         }
    //       });

    //       setFilteredRoles(newFilteredRoles);
    //     }
    //   });

    //   return;
    // }

    if (name === 'contactNumber') {
      if (value.length >= 12) {
        return;
      }
    }

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSpecialtyChange = (e, type) => {
    if (type === 'specialty') {
      setFormData((prev) => {
        return {
          ...prev,
          specialty: e,
          subSpecialty: [],
        };
      });
    } else if (type === 'sub specialty') {
      setFormData((prev) => {
        return {
          ...prev,
          subSpecialty: e,
        };
      });
    }
  };

  const getSubSpecialtyOptions = () => {
    if (!formData?.specialty) return;

    const newData = specialties?.subSpecialty.filter((data) => {
      let isIncluded = false;

      formData?.specialty.forEach((d) => {
        if (+data?.attributes?.parent_position_id === +d?.id) {
          isIncluded = true;
        }
      });

      return isIncluded === true;
    });

    return newData?.map((data) => {
      return {
        data: data,
        id: data?.id,
        label: data?.attributes?.position,
        value: data?.attributes?.position,
      };
    });
  };

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  useEffect(() => {
    console.log('filteredRoles', filteredRoles);
  }, [filteredRoles]);

  renderWhenRolesIsChanged(rolesData, setRoles);
  renderWhenDataIsChanged(data, subData, setSpecialties);
  renderWhenDepartmentsIsChanged(departmentsData, setDepartments);
  renderOnFirstRun(getRoles, getDepartments, getSpecialties, apiSubSpecialties);
  renderWhenSubmitLogsisUpdated(submitLogs, setOpenModal, setFormData, dispatch);

  return (
    <FormLoginUI>
      <div className='container-fluid'>
        <div className='form__header'>
          <div className='wrapper text-center'>
            <Button variant='transparent' onClick={() => navigate('/')}>
              <ArrowLeftSVG />
            </Button>
            <h6>Request User Account</h6>
          </div>
          <Row>
            <h4 className='my-4'>Request for your Account</h4>
          </Row>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  name='username'
                  autoComplete='off'
                  onChange={handleChange}
                  value={formData?.username}
                  placeholder='Enter Username'
                />
              </Form.Group>
            </div>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name='email'
                  type='email'
                  autoComplete='off'
                  onChange={handleChange}
                  value={formData?.email}
                  placeholder='Enter Email'
                />
              </Form.Group>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  name='firstname'
                  autoComplete='off'
                  onChange={handleChange}
                  value={formData?.firstname}
                  placeholder='Enter First Name'
                />
              </Form.Group>
            </div>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  name='middlename'
                  autoComplete='off'
                  onChange={handleChange}
                  value={formData?.middlename}
                  placeholder='Enter Middle Name'
                />
              </Form.Group>
            </div>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  name='lastname'
                  autoComplete='off'
                  onChange={handleChange}
                  value={formData?.lastname}
                  placeholder='Enter Last Name'
                />
              </Form.Group>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>Assigned Management</Form.Label>
                {/* <Form.Select name='department' onChange={handleChange} value={formData?.department}>
                  <option value='' hidden>
                    Select Department
                  </option>

                  {departments.map((data) => {
                    return (
                      <option key={data.id} value={data.id}>
                        {data.attributes.name}
                      </option>
                    );
                  })}
                </Form.Select> */}

                <Select
                  name='department'
                  isSearchable={false}
                  styles={selectStyles}
                  onChange={handleDepartment}
                  value={formData?.department}
                  placeholder='Select Department'
                  options={departments.map((data) => {
                    return {
                      data: data,
                      id: data?.id,
                      value: data?.id,
                      label: data?.attributes?.name,
                    };
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>Role</Form.Label>
                {/* <Form.Select name='role' onChange={handleChange} value={formData?.role}>
                  <option value='' hidden>
                    Select Role
                  </option>

                  {filteredRoles.length > 0 &&
                    filteredRoles.map((data) => {
                      return (
                        <option key={data.id} value={data.attributes.name}>
                          {data.attributes.name}
                        </option>
                      );
                    })}
                </Form.Select> */}

                <Select
                  name='role'
                  isSearchable={false}
                  styles={selectStyles}
                  onChange={handleRole}
                  value={formData?.role}
                  placeholder='Select Role'
                  options={filteredRoles.map((data) => {
                    return {
                      data: data,
                      id: data?.id,
                      value: data?.attributes?.name,
                      label: data?.attributes?.name,
                    };
                  })}
                />
              </Form.Group>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm'>
              <ConditionalRender
                condition={formData?.role && formData?.role?.value === 'Doctor'}
                renderIf={
                  <Form.Group className='mb-3'>
                    <Form.Label>Professional Fee (PHP)</Form.Label>
                    <Form.Control
                      type='number'
                      autoComplete='off'
                      name='professionalFee'
                      onChange={handleChange}
                      placeholder='Enter Here'
                      value={formData?.professionalFee}
                    />
                  </Form.Group>
                }
              />

              <Form.Group className='mb-3'>
                <Form.Label>Specialty</Form.Label>

                <Select
                  isMulti
                  isSearchable={false}
                  styles={selectStyles}
                  value={formData?.specialty}
                  placeholder='Select Specialty'
                  onChange={(e) => handleSpecialtyChange(e, 'specialty')}
                  options={specialties?.parentSpecialty.map((data) => {
                    return {
                      data: data,
                      id: data?.id,
                      // label: `${data?.attributes?.position} (${data?.id})`,
                      label: `${data?.attributes?.description} ${data?.id}`,
                      value: data?.id,
                    };
                  })}
                />
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>Sub Specialty</Form.Label>

                <Select
                  isMulti
                  isSearchable={false}
                  styles={selectStyles}
                  value={formData?.subSpecialty}
                  placeholder='Select Sub Specialty'
                  onChange={(e) => handleSpecialtyChange(e, 'sub specialty')}
                  options={getSubSpecialtyOptions()}
                />
              </Form.Group>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>ID No.</Form.Label>
                <Form.Control
                  name='idNo'
                  autoComplete='off'
                  placeholder='Enter ID'
                  value={formData?.idNo}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type='number'
                  autoComplete='off'
                  name='contactNumber'
                  placeholder='Enter Contact Number'
                  value={formData?.contactNumber}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  name='password'
                  autoComplete='off'
                  onChange={handleChange}
                  value={formData?.password}
                  placeholder='Enter Password'
                />
              </Form.Group>
            </div>
            <div className='col-sm'>
              <Form.Group className='mb-3'>
                <Form.Label>Request for Account</Form.Label>
                <Form.Control
                  name='remarks'
                  autoComplete='off'
                  onChange={handleChange}
                  value={formData?.remarks}
                  placeholder='Enter remarks'
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className='form__footer'>
          <Button disabled={submitLogs?.isLoading} onClick={() => signup()}>
            Sign up
          </Button>
          <div className='d-flex justify-content-center align-items-center'>
            <Text color='#2E2E2E' fontSize={15}>
              Already have an account?&nbsp;
            </Text>
            <Link to='/'>Sign in</Link>
          </div>
        </div>
      </div>

      <Modal openModal={openModal} setOpenModal={setOpenModal} />
    </FormLoginUI>
  );
};

const renderWhenDataIsChanged = (data, subData, setSpecialties) => {
  useEffect(() => {
    if (data && data.length > 0) {
      // const parentSpecialty = data.filter((data) => {
      //   return data?.attributes?.parent_position_id === '';
      // });

      // const subSpecialty = data.filter((data) => {
      //   return data?.attributes?.parent_position_id !== '';
      // });

      // setSpecialties({
      //   parentSpecialty: parentSpecialty,
      //   subSpecialty: subSpecialty,
      // });

      setSpecialties((prev) => {
        return {
          ...prev,
          parentSpecialty: data,
        };
      });
    }

    if (subData && subData.length > 0) {
      setSpecialties((prev) => {
        return {
          ...prev,
          subSpecialty: subData,
        };
      });
    }
  }, [data, subData]);
};

const renderWhenRolesIsChanged = (rolesData, setRoles) => {
  useEffect(() => {
    if (rolesData && rolesData.length > 0) {
      setRoles(rolesData);
    }
  }, [rolesData]);
};

const renderOnFirstRun = (getRoles, getDepartments, getSpecialties, apiSubSpecialties) => {
  useEffect(() => {
    getRoles();
    getDepartments();
    getSpecialties();
    apiSubSpecialties();
  }, []);
};

const renderWhenDepartmentsIsChanged = (departmentsData, setDepartments) => {
  useEffect(() => {
    if (departmentsData && departmentsData.length > 0) {
      setDepartments(departmentsData);
    }
  }, [departmentsData]);
};

const renderWhenSubmitLogsisUpdated = (submitLogs, setOpenModal, setFormData, dispatch) => {
  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = submitLogs;

    if (isFailed) {
      toast.error(error || 'Something went wrong, Please refresh the page.');
    }

    if (isSuccess) {
      setFormData({});
      setOpenModal(true);
      dispatch(resetSubmitLogs());
    }
  }, [submitLogs]);
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.45em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

const allRoles = [
  {
    id: 1,
    acceptedRoles: [
      'Admission',
      'Doctor',
      'Er Nurse',
      'Regular Nurse',
      'Station Head Nurse',
      'Nurse Manager',
      'Lab. Tech',
      'Radiology Tech',
      'Encoder',
      'Cashier',
    ],
  },
  {
    id: 2,
    acceptedRoles: ['Inventory Manager'],
  },
  {
    id: 3,
    acceptedRoles: ['Accounting'],
  },
  {
    id: 4,
    acceptedRoles: ['HR'],
  },
  {
    id: 5,
    acceptedRoles: ['Medical Records', 'Maintenance', 'Social Services'],
  },
];

export default FormSignup;
