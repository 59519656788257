/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal } from 'react-bootstrap';

// import components below
import {
  getEquipments,
  selectborrowReturn,
  getSingleBorrowedData,
} from 'reducers/borrowReturnReducer';
import FormUI from 'components/UI/FormUI';

// Import assets below
import './modal.scss';
import moment from 'moment';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';


// main component
const ViewItemModal = ({ showModal, fetchData, setShowModal }) => {
  // Redux
  const dispatch = useDispatch();

  // New Borrow Return
  const borrowReturnState = useSelector(selectborrowReturn);
  const { dataItems, submitLogs, updateLogs, singleData, dataItemsIncluded, singleDataIncluded } =
    borrowReturnState;

  // States
  const [search, setSearch] = useState({
    code: '',
    itemGroup: '',
  });
  const [formData, setFormData] = useState({
    borrowedBy: '',
    remarks: '',
  });
  const [finalItem, setFinalItem] = useState([]);

  // Api call
  const getInventoryItems = (clear = false) => {
    let params;

    if (clear) {
      setSearch({
        code: '',
        itemGroup: '',
      });
    }

    params = {
      per_page: 5,
      is_medicine: 0,
      q: search?.code,
      include: ['group'],
    };

    if (search?.itemGroup && clear === false) {
      params.group_id = search?.itemGroup;
    }

    if (clear) {
      params.q = '';
    }

    dispatch(getEquipments(params));
  };

  // Get group name from included api
  const getGroupName = (id) => {
    if (!dataItemsIncluded) return;

    const selectedArray = dataItemsIncluded.filter((data) => data.id === id);

    if (selectedArray.length === 0) {
      return 'N/A';
    }

    return selectedArray[0]?.attributes?.name;
  };

  // Handle POST api
  const handleSubmit = () => {
    const payload = {
      id: singleData?.id,
      payload: {
        remarks: formData?.remarks,
      },
    };

    // dispatch(returnItem(payload));
  };

  const closeModal = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        data: null,
        viewItem: false,
      };
    });

    setFinalItem([]);
    setFormData({
      borrowedBy: '',
      remarks: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    getInventoryItems();
  }, []);

  // Get Submit Data result
  useEffect(() => {
    const { isLoading, isFailed, isSuccess, error } = updateLogs;

    if (isFailed) {
      toast.error(`${error[0]?.detail} (${error[0]?.code})`);
      console.log(error);
    }

    if (isSuccess) {
      closeModal();
      fetchData();
      toast.success('Successfully updated request to borrow item!');
    }
  }, [updateLogs]);

  // Modal Data
  useEffect(() => {
    if (showModal?.data && showModal?.viewItem === true) {
      dispatch(getSingleBorrowedData(showModal?.data?.id));
    }
  }, [showModal]);

  // Get data from API
  useEffect(() => {
    if (singleData && finalItem.length === 0) {
      const itemID = singleData?.relationships?.item?.data[0]?.id;

      const itemData = singleDataIncluded.find((dd) => {
        return parseInt(dd?.id) === parseInt(itemID) && dd?.type === 'items';
      });

      if (itemID && itemData) {
        const newFinalData = {
          id: itemID,
          data: itemData,
        };

        setFormData({
          borrowedBy: singleData?.attributes?.borrowed_by,
          remarks: singleData?.attributes?.remarks,
        });
        setFinalItem([newFinalData]);
      }
    }
  }, [singleData]);

  useEffect(() => {
    // console.log('finalItem', finalItem);
  }, [finalItem]);

  return (
    <Modal
      size='lg'
      show={showModal?.viewItem}
      onHide={() => {
        closeModal();
      }}
      aria-labelledby='Request Item Modal'
      className='view-borrowed-item request-item'
    >
      <Modal.Body>
        <div className='view-borrowed-item-header'>
          <div className='d-flex header'>
            <p>BORROWED ITEM DETAILS</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={() => closeModal()} />
          </div>
        </div>

        <FormUI>
          <Form className='item-information-form'>
            <Row className='mb-5'>
              <Col>
                <h3 className='mb-4'>ITEM INFORMATION</h3>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Date Time Borrowed</Form.Label>
                  <FormControl
                    className='no-border'
                    value={moment(singleData?.attributes?.borrowed_at).format(
                      'MMM-DD-YYYY hh:mm A',
                    )}
                    type='text'
                    readOnly
                    placeholder='Date Time Borrowed Here'
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Code</Form.Label>
                  <FormControl
                    className='no-border'
                    value={finalItem[0]?.data?.attributes?.code}
                    type='text'
                    readOnly
                    placeholder='Item Code Here'
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Name</Form.Label>
                  <FormControl
                    className='no-border'
                    value={finalItem[0]?.data?.attributes?.name}
                    type='text'
                    readOnly
                    placeholder='Item Name Here'
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Group</Form.Label>
                  <FormControl
                    className='no-border'
                    value={getGroupName(finalItem[0]?.id)}
                    type='text'
                    readOnly
                    placeholder='Item Group Here'
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col>
                <h3 className='mb-4'>BORROWER INFORMATION</h3>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Borrowed By</Form.Label>
                  <FormControl
                    className='no-border'
                    value={singleData?.attributes?.borrowed_by}
                    type='text'
                    readOnly
                    placeholder='Borrowed By Here'
                  />
                </Form.Group>
                {/* 
                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Department</Form.Label>
                  <FormControl
                    className='no-border'
                    value={singleData?.attributes?.borrowed_by}
                    type='text'
                    readOnly
                    placeholder='Department Here'
                  />
                </Form.Group> */}
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col>
                <h3 className='mb-4'>STATUS</h3>

                <p className={`mb-4 status ${singleData?.attributes?.status}`}>
                  {singleData?.attributes?.status}
                </p>

                <Form.Group className='mb-3 form-group'>
                  <Form.Label>Remarks</Form.Label>
                  <FormControl
                    readOnly
                    type='text'
                    as='textarea'
                    name='remarks'
                    // onChange={handleChange}
                    value={singleData?.attributes?.remarks || ''}
                    placeholder='Remarks Here'
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    onClick={() => {
                      closeModal();
                    }}
                    className='clear-button'
                    variant='secondary'
                  >
                    Close
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ViewItemModal;
