/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { deleteUser } from 'reducers/aclReducer';
import { useDispatch, useSelector } from 'react-redux';

// import assets below
import './ConfirmModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main component
const ConfirmModal = ({ openModal, setOpenModal, dataToDelete, searchAllTabs }) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    setOpenModal(false);
  };

  const deleteData = async () => {
    const result = await dispatch(deleteUser(dataToDelete.id));

    if (result) {
      if (result.meta.requestStatus === 'fulfilled') {
        searchAllTabs();
        closeModal();
      }
    }
  };

  return (
    <Modal className='deleteModal-confirmation' show={openModal} onHide={closeModal} centered>
      <Modal.Header>
        <h6>CONFIRMATION</h6>
        <Button onClick={closeModal}>
          <CloseSVG />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Button onClick={deleteData} className='remove'>
          Remove Access
        </Button>
        <Button onClick={closeModal} className='cancel'>
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  openModal: false,
  setOpenModal: () => {},
  dataToDelete: {},
  searchAllTabs: () => {},
};

ConfirmModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  dataToDelete: PropTypes.object,
  searchAllTabs: PropTypes.func,
};

export default ConfirmModal;
