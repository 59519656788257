/* eslint-disable */
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

// import components below
import Table from 'components/ReactTable';
import { calculateDayDiff, currentLocation, patientStatus } from 'helpers';

// import assets below
import './Table.scss';

// main component
const DoctorTable = ({ state, setState, setShowProfile }) => {
  const tableColumns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'MRN / PIN', accessor: 'mrn' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Sex', accessor: 'sex' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      { Header: 'Currently', accessor: 'currently' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        mrn: dd?.MRN,
        name: dd?.full_name,
        sex: dd?.sex,
        date_of_birth: moment(dd?.date_of_birth).format('MMMM-DD-YYYY'),
        currently: currentLocation(dd?.patient_status),
        status: patientStatus(dd?.patient_status),
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      profile: () => {
        setShowProfile({
          isShowProfile: true,
          profileId: row.id,
          admissionDays: calculateDayDiff(row?.admission_date),
        });
      },
      view: () => {},
      edit: () => {},
    };

    action[eventKey]();
  };

  useEffect(() => {
    axios
      .get('/patient')
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='doctors-search-patient-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasProfile: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

DoctorTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowProfile: () => {},
};

DoctorTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default DoctorTable;
