/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-closing-bracket-location */
import { Col, Modal, Row } from 'react-bootstrap';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

import { selectPatientItem } from 'reducers/patientReducer';
import { useSelector } from 'react-redux';
import ConditionalRender from 'components/ConditionalRender';
import { calculateDayDiff, patientStatus, currentLocation } from 'helpers';

import PartialForm from './PartialForm';
import PrintForm from './PrintForm';
import './style.scss';

const PartialModal = ({ showModal, setShowModal, soaData }) => {
    const handleShow = () => setShowModal({ isPartial: false });
    const patientProfile = useSelector(selectPatientItem);

    const modalTitle = parseInt(soaData?.totalNetBill) > 0 ? 'Partial' : 'Full';

    return (
        <Modal show={showModal.isPartial} size='lg' className='partial-modal'>
            <Modal.Body>
                <div className='partial-header'>
                    <div className='d-flex header'>
                        <p>PATIENT {modalTitle} PAYMENT</p>
                        <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
                    </div>
                </div>
                <div className='information'>
                    <Row>
                        <Col>
                            <Row>
                                <Col sm={4}>Patient ID <div className='float-end'>:</div></Col>
                                <Col className='fw-bold'>{patientProfile?.MRN}</Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col sm={4}>Location <div className='float-end'>:</div></Col>
                                <Col className='fw-bold'> {patientProfile?.assigned_room
                                    ? patientProfile?.assigned_room?.room?.room
                                    : '-'}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col sm={4}>Patient Name <div className='float-end'>:</div></Col>
                                <Col className='fw-bold'>{patientProfile?.last_name} {patientProfile?.first_name}</Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col sm={4}>Admission Date <div className='float-end'>:</div></Col>
                                <Col className=''>{patientProfile?.admission_date ? patientProfile?.admission_date : '-'}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Row><h4 className='d-flex justify-content-center fw-bold'>PHP {soaData?.totalNetBill.toLocaleString('en') ?? 0}</h4></Row>
                        <Row><h6 className='d-flex justify-content-center'>TOTAL FEES DUE TO PATIENT</h6></Row>
                    </Row>
                    <hr />
                    <ConditionalRender
                        condition={!showModal.print}
                        renderIf=<PartialForm
                        showModal={showModal}
                        setShowModal={setShowModal}
                        soaData={soaData}
                    />
                    renderElse=<PrintForm
                        setShowModal={setShowModal}
                        soaData={soaData}
                    />
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PartialModal;
