/* eslint-disable camelcase */
import React from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

import logo from 'assets/images/new-mcmc-logo.png';
import './PrintContent.scss';

// eslint-disable-next-line react/display-name
export const PrintContent = React.forwardRef((props, ref) => {
  const { REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } =
  process.env;
  const { date, filterList, calculateTotalCurrent, calculateTotal } = props;
  return (
    <div id='print_financial_statement' ref={ref}>
      <div className='header'>
        <img src={logo} alt='print_logo' />
        <h1>{REACT_APP_HOSPITAL_NAME}</h1>
        <h3>{REACT_APP_HOSPITAL_STREET_ADDRESS}</h3>
      </div>
      <div>
        <p style={{ float: 'right' }}>Date: {moment(date).format('MMMM DD, YYYY')}</p>
        <h5 className='mt-2 mb-4'>Assets List:</h5>
        <Row className='mb-4'>
          <Col>
            {filterList('Asset')?.map((record) => {
              return (
                <Row className='mb-2' key={record?.id}>
                  <Col className='asset-name'>{record?.details}</Col>
                  <Col className='asset-amount'>{record?.amount}</Col>
                  <Col
                    className={record.category === 'asset' ? 'current-asset' : 'non-current-asset'}
                  >
                    {record?.category}
                  </Col>
                </Row>
              );
            })}
          </Col>

          <Col className='total-container'>
            <p>
              Total Current Assets :{' '}
              <span className='asset-total'>Php {calculateTotalCurrent('Asset')}</span>
            </p>
            <p>
              Total Assets : <span className='asset-total'>Php {calculateTotal('Asset')}</span>
            </p>
          </Col>
        </Row>

        <br />

        <h5 className='mt-4 mb-4'>Liabilities List:</h5>

        <Row className='mb-4'>
          <Col>
            {filterList('Liability')?.map((record) => {
              return (
                <Row className='mb-2' key={record?.id}>
                  <Col className='asset-name'>{record?.details}</Col>
                  <Col className='asset-amount'>{record?.amount}</Col>
                  <Col
                    className={record.category === 'asset' ? 'current-asset' : 'non-current-asset'}
                  >
                    {record?.category}
                  </Col>
                </Row>
              );
            })}
          </Col>

          <Col className='total-container'>
            <p>
              Total Current Liabilities :{' '}
              <span className='asset-total'>Php {calculateTotalCurrent('Liability')}</span>
            </p>
            <p>
              Total Liabilities :{' '}
              <span className='asset-total'>Php {calculateTotal('Liability')}</span>
            </p>
          </Col>
        </Row>

        <br />

        <h5 className='mt-2 mb-4'>Equity List</h5>

        <Row className='mb-4'>
          <Col>
            {filterList('Equity')?.map((record) => {
              return (
                <Row className='mb-2' key={record?.id}>
                  <Col className='asset-name'>{record?.details}</Col>
                  <Col className='asset-amount'>{record?.amount}</Col>
                  <Col
                    className={record.category === 'asset' ? 'current-asset' : 'non-current-asset'}
                  >
                    {record?.category}
                  </Col>
                </Row>
              );
            })}
          </Col>

          <Col className='total-container'>
            <p>
              Total Current Equity :{' '}
              <span className='asset-total'>Php {calculateTotalCurrent('Equity')}</span>
            </p>
            <p>
              Total Equity : <span className='asset-total'>Php {calculateTotal('Equity')}</span>
            </p>
          </Col>
        </Row>
        {/* <Row className='mb-4'>
          <Col xs={6}>
            <h6>Current Assets</h6>

            <div>
              <Form.Label className='asset-name'>Bank and Cash Accounts </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE</Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Receivables </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE</Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Current Assets </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Prepayments: </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <br />
            <div>
              <Form.Label className='asset-name'>Plus Fixed Assets </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Plus Non-Fixed Assets </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
          </Col>

          <Col className='total-container'>
            <div>
              <Form.Label className='asset-name'>Total Current Assets </Form.Label>
              <Form.Label className='asset-total'>DETAILS HERE </Form.Label>
            </div>

            <div>
              <Form.Label className='asset-name'>Total Assets </Form.Label>
              <Form.Label className='asset-total'>DETAILS HERE </Form.Label>
            </div>
          </Col>
        </Row>

        <h6 className='mt-2 mb-2'>Liabilities List:</h6>

        <Row className='mb-4'>
          <h6>Current Liabilities</h6>

          <Col>
            <div>
              <Form.Label className='asset-name'>Current Liabilities </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Receivables </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Total Current Liabilities </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Total Non-Current Liabilities </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <br />
            <div>
              <Form.Label className='asset-name'>Fixed Assets </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Non-Fixed Assets </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
          </Col>

          <Col className='total-container'>
            <div>
              <Form.Label className='asset-name'>Total Current Assets </Form.Label>
              <Form.Label className='asset-total'>DETAILS HERE </Form.Label>
            </div>

            <div>
              <Form.Label className='asset-name'>Total Assets </Form.Label>
              <Form.Label className='asset-total'>DETAILS HERE </Form.Label>
            </div>
          </Col>
        </Row>
        <h6 className='mt-2 mb-4'>Equity List</h6>
        <Row className='mb-4'>
          <Col>
            <h6>Unallocated Earnings</h6>

            <div>
              <Form.Label className='asset-name'>Current Year Earnings </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Current Year Allocated Earnings </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Total Current Year Unallocated </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Earnings </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Previous Years Unallocated Earnings </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
            <br />
            <div>
              <Form.Label className='asset-name'>Retained Earnings </Form.Label>
              <Form.Label className='asset-amount'>DETAILS HERE </Form.Label>
            </div>
          </Col>
          <Col className='total-container'>
            <div>
              <Form.Label className='asset-name'>Total Unallocated Earnings </Form.Label>
              <Form.Label className='asset-total'>DETAILS HERE </Form.Label>
            </div>
            <div>
              <Form.Label className='asset-name'>Total Equity </Form.Label>
              <Form.Label className='asset-total'>DETAILS HERE </Form.Label>
            </div>
          </Col>
        </Row> */}
      </div>
    </div>
  );
});
