/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import * as XLSX from 'xlsx';

// import React, { useEffect, useState } from 'react';
import { getChartOfAccounts, selectChartOfAccounts } from 'reducers/accountingReducer';
// eslint-disable-next-line no-unused-vars
import ChartOfAccountTable from './components/Table';
import './index.scss';
import ChartOfAccountFilter from './components/Filter';
import AddNew from './components/New';
import UploadSheet from './components/UploadSheet';

const ChartOfAccount = () => {
  // eslint-disable-next-line no-unused-vars
  const [showNew, setNew] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState([]);
  const [showModal, setShowModal] = useState({
    isShowForm: false,
    accountId: null,
  });

  const [batchEditMode, setBatchEditMode] = useState(false);
  const [batchEdit, setBatchEdit] = useState([]);
  const [showSheetUpload, setShowSheetUpload] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [filter, setFilter] = useState(null);

  // useEffect(() => {
  //   dispatch(getChartOfAccounts());
  // }, []);

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  const handleShowUploadModal = () => {
    setShowSheetUpload(true);
  }

  const handleCloseUploadModal = () => {
    setShowSheetUpload(false);
    setUploadedData([]);
  }


  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const workseheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(workseheet);
    setUploadedData(json);
  }

  return (
    <div className='chartOfAccount'>
      <>
        <ChartOfAccountFilter 
          setShowModal={setShowModal} 
          setBatchEditMode={setBatchEditMode} 
          setBatchEdit={setBatchEdit}
          batchEdit={batchEdit}
          batchEditMode={batchEditMode}
          state={useSelector(selectChartOfAccounts)}
          handlePrint={handlePrint}
          handleShowUploadModal={handleShowUploadModal}
          setFilter={setFilter}
          filter={filter}
        />
        <ChartOfAccountTable
          state={useSelector(selectChartOfAccounts)}
          setState={setState}
          setShowModal={setShowModal}
          batchEditMode={batchEditMode}
          batchEdit={batchEdit}
          setBatchEdit={setBatchEdit}
          printRef={printRef}
          filter={filter}
        />

        <AddNew 
          showModal={showModal} 
          setShowModal={setShowModal} 
        />

        <UploadSheet 
          handleCloseUploadModal={handleCloseUploadModal}
          showSheetUpload={showSheetUpload}
          handleFileUpload={handleFileUpload}
          uploadedData={uploadedData}
          setUploadedData={setUploadedData}
        />
      </>
    </div>
  );
};

export default ChartOfAccount;
