// import assets below
import './Banner.scss';
import { ReactComponent as DoctorSVG } from 'assets/svg/dashboard_doctor.svg';

// main component
const DashboardBanner = () => {
  return (
    <div className='dashboardBanner'>
      {/* <img className='dashboardBanner__logo' src={Logo} alt='mandaluyong-his' /> */}
      <div className='text-white'>
        <h1 className='fw-bold fs-1'>Welcome to BigHealthMed</h1>
        <span className='fs-2'>Hospital Management System</span>
      </div>
      <DoctorSVG />
    </div>
  );
};

export default DashboardBanner;
