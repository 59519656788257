// import style below
import './style.scss';

// main component
const HeaderUI = (props) => {
  const { children } = props;
  return <div className='headerUI'>{children}</div>;
};

export default HeaderUI;
