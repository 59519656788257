/* eslint-disable */
import FormUI from 'components/UI/FormUI';
import { Button, Col, Form, FormControl, Row } from 'react-bootstrap';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createChartOfAccounts,
  selectChartOfAccountItem,
  updateChartOfAccounts,
  getChartOfAccounts,
} from 'reducers/accountingReducer';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import moment from 'moment';

import './Form.scss';

const NewForm = (props) => {
  const { setShowModal, accountId, showModal } = props;
  const result = useSelector(selectChartOfAccountItem);

  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [formData, updateFormData] = useState();

  /* if (typeof accountId === 'undefined') {
        result = [];
    } */

  useEffect(() => {
    if (showModal.accountId !== undefined) {
      updateFormData(result);
    } else {
      updateFormData({});
    }
  }, [result, showModal]);

  console.log(result, 'result');
  console.log(showModal, 'showModal');

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() !== false) {
      if (typeof accountId === 'undefined') {
        dispatch(createChartOfAccounts(formData));
      } else {
        let data = { ...formData, id: accountId };
        dispatch(updateChartOfAccounts(data));
        console.log('dsdadas');
      }
      //            dispatch(createChartOfAccounts(formData));
      toast.success('Success');
      setShowModal({ isShowForm: false, accountId: undefined });
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });
    console.log('chart of account single result: ', typeof formData.as_of);
    console.log('formData', formData);
  };

  const handleCheck = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.checked,
    });
  };

  function getFormattedDate(date) {
    if (typeof date === 'undefined') {
      date = new Date();
      return date.toLocaleDateString('en-CA');
    }

    date = new Date(date);
    return date.toLocaleDateString('en-CA');
  }

  function getFormattedDate(date) {
    if (typeof date === 'undefined') {
      date = new Date();
      return date.toLocaleDateString('en-CA');
    }

    date = new Date(date);
    return date.toLocaleDateString('en-CA');
  }

  return (
    <FormUI>
      <Form className='add-charges-form' noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group className='mb-4'>
              <Form.Label>Account Type</Form.Label>
              <Form.Select
                required
                name='account_type'
                onChange={handleChange}
                value={formData?.account_type}
              >
                <option value=''>-Select-</option>
                <option value='Outpatient'>Outpatient</option>
                <option value='Emergency'>Emergency</option>
                <option value='Admitted'>Admitted</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-4'>
              <Form.Label>Detail Type</Form.Label>
              <Form.Select
                required
                name='detail_type'
                onChange={handleChange}
                value={formData?.detail_type}
              >
                <option value=''>-Select-</option>
                <option value='Outpatient'>Outpatient</option>
                <option value='Emergency'>Emergency</option>
                <option value='Admitted'>Admitted</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-4'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type='text'
                placeholder='Name'
                name='name'
                onChange={handleChange}
                value={formData?.name}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-4'>
              <Form.Label>Description</Form.Label>
              <FormControl
                as='textarea'
                rows={3}
                className='bg-white'
                placeholder='Enter Here'
                name='description'
                onChange={handleChange}
                value={formData?.description}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-4'>
              <Form.Check
                type='checkbox'
                label='is sub-account'
                name='is_sub_account'
                value='1'
                onChange={handleCheck}
                checked={formData?.is_sub_account}
              />
              <Form.Label></Form.Label>
              <Form.Control
                required
                type='text'
                placeholder='Enter parent account'
                name='parent_account'
                onChange={handleChange}
                value={formData?.parent_account}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-4'>
              <Form.Label>Debit Balance</Form.Label>
              <Form.Control
                required
                name='debit'
                type='number'
                onChange={handleChange}
                value={formData?.debit}
                placeholder='Enter Here'
                onKeyDown={(evt) =>
                  (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                  evt.preventDefault()
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-4'>
              <Form.Label>Credit Balance</Form.Label>
              <Form.Control
                required
                type='number'
                name='credit'
                onChange={handleChange}
                value={formData?.credit}
                placeholder='Enter Here'
                onKeyDown={(evt) =>
                  (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                  evt.preventDefault()
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-4'>
              <Form.Label>as of</Form.Label>
              <FormControl
                required
                type='date'
                className='bg-white'
                placeholder='mm/dd/yyyy'
                name='as_of'
                onChange={handleChange}
                // value={'2022-03-19'}
                value={formData?.as_of && moment(formData?.as_of).format('YYYY-MM-DD')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mt-4 buttons-cont'>
              <Button
                className='add-button'
                // type='submit'
                variant='secondary'
                onClick={() => setShowModal({ isShowForm: false, accountId: undefined })}
              >
                Cancel
              </Button>
              <Button className='add-button' type='submit' variant='success'>
                Save & Close
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </FormUI>
  );
};

export default NewForm;
