/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */

// import assets below
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAppointmentItem,
    setActiveTab,
    setAppointmentAction,
} from 'reducers/appointmentReducer';

import './ViewDetails.scss';
import moment from 'moment';

// main component
const ViewDetails = ({ setViewDetails }) => {
    const dispatch = useDispatch();

    const appointment = useSelector(selectAppointmentItem);

    const handleClose = () => {
        setViewDetails(false);
    };

    const handleEdit = () => {
        dispatch(setAppointmentAction({ isEdit: true }));
    };

    return (
        <div className='appointmentViewDetails'>
            <div className='d-flex justify-content-between border-bottom border-2 mb-3'>
                <p className='title fs-5'>Appointment Information</p>

                <div>
                    {/* <Button variant='search' className='mb-3 me-3' onClick={() => handleEdit()}>
                        Edit Appointment
                    </Button> */}

                    <Button variant='danger' className='mb-3' onClick={() => handleClose()}>
                        Close
                    </Button>
                </div>
            </div>
            <Row className='mb-3'>

                <Col md={2} className='d-flex justify-content-between'>
                    <p>Patient ID</p>
                    <span>:</span>
                </Col>

                <Col md={2}>
                    <p className='details'>{appointment?.patientId}</p>
                </Col>

                <Col md={2} className='d-flex justify-content-between'>
                    <p>Patient Name</p>
                    <span>:</span>
                </Col>

                <Col>
                    <p className='details'>{appointment?.patient?.full_name}</p>
                </Col>
            </Row>

            <p className='fw-bold mb-2'>APPOINTMENT INFORMATION</p>

            <Row className='mb-3'>
                {/** start */}

                {/** 1 */}
                <Row>
                    <Col md={3} className='d-flex justify-content-between'>
                        <p>Date and Time</p>
                        <span>:</span>
                    </Col>

                    <Col md={3}>
                        {/* <p className='details'>{appointment?.appointment_date}</p> */}
                        <p className='details'>{moment(appointment?.appointment_date).format('MMMM DD, YYYY hh:mm A')}</p>
                    </Col>
                </Row>

                {/** 2 */}
                <Row>
                    <Col md={3} className='d-flex justify-content-between'>
                        <p>Mobile Number</p>
                        <span>:</span>
                    </Col>

                    <Col md={3}>
                        <p className='details'>{appointment?.mobile_number}</p>
                    </Col>
                </Row>

                {/** 3 */}
                <Row>
                    <Col md={3} className='d-flex justify-content-between'>
                        <p>Email Address</p>
                        <span>:</span>
                    </Col>

                    <Col md={3}>
                        <p className='details'>{appointment?.email}</p>
                    </Col>
                </Row>

                {/** 4 */}
                <Row>
                    <Col md={3} className='d-flex justify-content-between'>
                        <p>Doctor</p>
                        <span>:</span>
                    </Col>
                    <Col md={3}>
                        <p className='details'>{appointment?.name_of_doctor}</p>
                    </Col>
                </Row>
                {/** 5 */}
                <Row>
                    <Col md={3} className='d-flex justify-content-between'>
                        <p>Department</p>
                        <span>:</span>
                    </Col>
                    <Col md={3}>
                        <p className='details'>{appointment?.department}</p>
                    </Col>
                </Row>
                {/** end */}
            </Row>
        </div>
    );
};

export default ViewDetails;
