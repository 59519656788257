/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import uuidv4 from 'react-uuid';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Router, Route } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import { Widget, FileInfo } from '@uploadcare/react-widget';
import { Row, Col, Accordion, Form, Button } from 'react-bootstrap';
import { regions, provinces, cities, barangays } from 'helpers/SelectAddress';

// import assets below
import FormUI from 'components/UI/FormUI';
import AccordionUI from 'components/UI/AccordionUI';
import FileUploadModal from './components/FileUpload';
import { redirectBackToPreviousPage } from '../../helpers';
import ConfirmationModal from './components/ConfirmationModal';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

import './style.scss';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as CirclePlusSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CircleMinusSVG } from 'assets/svg/circle-minus.svg';

// main component
const FormEditPatientProfile = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const patientProfile = useSelector(selectPatientItem);

  const [openModal, setOpenModal] = useState(false);
  const [MRN, setMRN] = useState(patientProfile?.MRN);
  const [sex, setSex] = useState(patientProfile?.sex);
  const [age, setAge] = useState(patientProfile?.age);
  const [aliasToggle, setAliasToggle] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedAvatar, setUploadedAvatar] = useState('');
  const [prefix, setPrefix] = useState(patientProfile?.prefix);
  const [suffix, setSuffix] = useState(patientProfile?.suffix);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [religion, setReligion] = useState(patientProfile?.religion);
  const [lastName, setLastName] = useState(patientProfile?.last_name);
  const [firstName, setFirstName] = useState(patientProfile?.first_name);
  const [isNewBorn, setIsNewBorn] = useState(patientProfile?.is_new_born);
  const [middleName, setMiddleName] = useState(patientProfile?.middle_name);
  const [nationality, setNationality] = useState(patientProfile?.nationality);
  const [civilStatus, setCivilStatus] = useState(patientProfile?.civil_status);
  const [aliasPrefix, setAliasPrefix] = useState(patientProfile?.alias_prefix);
  const [aliasSuffix, setAliasSuffix] = useState(patientProfile?.alias_suffix);
  const [aliasGender, setAliasGender] = useState(patientProfile?.alias_gender);
  const [dateOfBirth, setDateOfBirth] = useState(patientProfile?.date_of_birth);
  const [aliasLastName, setAliasLastName] = useState(patientProfile?.alias_last_name);
  const [aliasDiagnosis, setAliasDiagnosis] = useState(patientProfile?.alias_diagnosis);
  const [aliasFirstName, setAliasFirstName] = useState(patientProfile?.alias_first_name);
  const [referenceNumber, setReferenceNumber] = useState(patientProfile?.reference_number);
  const [aliasMiddleName, setAliasMiddleName] = useState(patientProfile?.alias_middle_name);
  const [referenceLocation, setReferenceLocation] = useState(patientProfile?.reference_location);
  const [fictitiousDateOfBirth, setFictitiousDateOfBirth] = useState(
    patientProfile?.is_fictitious_date_of_birth,
  );

  /** Present address */
  const [presentCountry, setPresentCountry] = useState(
    patientProfile?.patient_present_address?.present_country,
  );
  const [presentRegion, setPresentRegion] = useState(
    patientProfile?.patient_present_address?.present_region,
  );
  const [presentProvince, setPresentProvince] = useState(
    patientProfile?.patient_present_address?.patient_province,
  );
  const [presentCity, setPresentCity] = useState(
    patientProfile?.patient_present_address?.present_city,
  );
  const [presentZipCode, setPresentZipCode] = useState(
    patientProfile?.patient_present_address?.present_zip_code,
  );
  const [presentAddress1, setPresentAddress1] = useState(
    patientProfile?.patient_present_address?.present_address1,
  );
  const [presentAddress2, setPresentAddress2] = useState(
    patientProfile?.patient_present_address?.present_address2,
  );
  const [presentBrgy, setPresentBrgy] = useState(
    patientProfile?.patient_present_address?.present_brgy,
  );

  /** permanent address */
  const [permanentCountry, setPermanentCountry] = useState(
    patientProfile?.patient_permanent_address?.permanent_country,
  );
  const [permanentRegion, setPermanentRegion] = useState(
    patientProfile?.patient_permanent_address?.permanent_region,
  );
  const [permanentProvince, setPermanentProvince] = useState(
    patientProfile?.patient_permanent_address?.patient_province,
  );
  const [permanentCity, setPermanentCity] = useState(
    patientProfile?.patient_permanent_address?.permanent_city,
  );
  const [permanentZipCode, setPermanentZipCode] = useState(
    patientProfile?.patient_permanent_address?.permanent_zip_code,
  );
  const [permanentAddress1, setPermanentAddress1] = useState(
    patientProfile?.patient_permanent_address?.permanent_address1,
  );
  const [permanentAddress2, setPermanentAddress2] = useState(
    patientProfile?.patient_permanent_address?.permanent_address2,
  );
  const [permanentBrgy, setPermanentBrgy] = useState(
    patientProfile?.patient_permanent_address?.permanent_brgy,
  );

  const [sameAsPresentAddress, setSameAsPresentAddress] = useState(false);
  const [emergencyContactPrefix, setEmergencyContactPrefix] = useState(
    patientProfile?.patient_emergency_contact?.prefix,
  );
  const [emergencyContactFirstName, setEmergencyContactFirstName] = useState(
    patientProfile?.patient_emergency_contact?.first_name,
  );
  const [emergencyContactMiddleName, setEmergencyContactMiddleName] = useState(
    patientProfile?.patient_emergency_contact?.middle_name,
  );
  const [emergencyContactLastName, setEmergencyContactName] = useState(
    patientProfile?.patient_emergency_contact?.last_name,
  );
  const [emergencyContactSuffix, setEmergencyContactSuffix] = useState(
    patientProfile?.patient_emergency_contact?.suffix,
  );
  const [emergencyContactRelation, setEmergencyContactRelation] = useState(
    patientProfile?.patient_emergency_contact?.relationship,
  );
  const [emergencyContactPurpose, setEmergencyContactPurpose] = useState(
    patientProfile?.patient_emergency_contact?.purpose,
  );
  const [emergencyContactNumber, setEmergencyContacNumber] = useState(
    patientProfile?.patient_emergency_contact?.contact_number,
  );

  const [MPIN, setMPIN] = useState(patientProfile?.patient_phil_health?.MPIN);
  const [industry, setIndustry] = useState(patientProfile?.patient_occupation?.industry);
  const [employer, setEmployer] = useState(patientProfile?.patient_occupation?.employer);
  const [occupation, setOccupation] = useState(patientProfile?.patient_occupation?.occupation);

  const [philHealthFirstName, setPhilHealthFirstName] = useState(
    patientProfile?.patient_phil_health?.first_name,
  );
  const [philHealthMiddleName, setPhilHealthMiddleName] = useState(
    patientProfile?.patient_phil_health?.middle_name,
  );
  const [philHealthLastName, setPhilHealthLastName] = useState(
    patientProfile?.patient_phil_health?.last_name,
  );
  const [philHealthSuffix, setPhilHealthSuffix] = useState(
    patientProfile?.patient_phil_health?.suffix,
  );
  const [philHealthMemberType, setPhilHealthMemberType] = useState(
    patientProfile?.patient_phil_health?.member_type,
  );
  const [philHealthIsOwnAccount, setPhilHealthIsOwnAccount] = useState(
    patientProfile?.patient_phil_health?.is_own_account,
  );
  const [philHealthRelation, setPhilHealthRelation] = useState(
    patientProfile?.patient_phil_health?.relation_to_member,
  );
  const [philHealthPatientFirstName, setPhilHealthPatientFirstName] = useState(
    patientProfile?.patient_phil_health?.patient_first_name,
  );
  const [philHealthPatientMiddleName, setPhilHealthPatientMiddleName] = useState(
    patientProfile?.patient_phil_health?.patient_middle_name,
  );
  const [philHealthPatientLastName, setPhilHealthPatientLastName] = useState(
    patientProfile?.patient_phil_health?.patient_last_name,
  );
  const [philHealthPatientSuffix, setPhilHealthPatientSuffix] = useState(
    patientProfile?.patient_phil_health?.patient_suffix,
  );
  const [philHealthPatientDateOfBirth, setPhilHealthPatientDateOfBirth] = useState(
    patientProfile?.patient_phil_health?.patient_date_of_birth,
  );
  const [philHealthPatientSex, setPhilHealthPatientSex] = useState(
    patientProfile?.patient_phil_health?.patient_sex,
  );
  const [philHealthPatientAdmissionDate, setPhilHealthPatientAdmissionDate] = useState(
    patientProfile?.patient_phil_health?.admission_date,
  );
  const [philHealthPatientDischargeDate, setPhilHealthPatientDischargeDate] = useState(
    patientProfile?.patient_phil_health?.discharge_date,
  );
  const [philHealthPatientIcdCode, setPhilHealthPatientIcdCode] = useState('');
  const [philHealthTotalAmount, setPhilHealthTotalAmount] = useState(
    patientProfile?.patient_phil_health?.actual_amount,
  );
  const [philHealthAmountClaimed, setPhilHealthAmountClaimed] = useState(
    patientProfile?.patient_phil_health?.amount_claimed,
  );
  const [philHealthIsFinal, setPhilHealthIsFinal] = useState(
    patientProfile?.patient_phil_health?.is_final,
  );
  const [patientStatus, setPatientStatus] = useState(patientProfile?.patient_status);
  const [patientOtherDetailsStatus, setPatientOtherDetailsStatus] = useState('');

  const [countryList, setCountryList] = useState(Country.getAllCountries());

  const [regionData, setRegion] = useState([]);
  const [provinceData, setProvince] = useState([]);
  const [cityData, setCity] = useState([]);
  const [barangayData, setBarangay] = useState([]);

  const region = () => {
    const response = regions();
    setRegion(response);
  };

  const province = (e) => {
    setPresentRegion(e.target.selectedOptions[0].text);

    const response = provinces(e.target.value);
    setProvince(response);
    setCity([]);
    setBarangay([]);
  };

  const city = (e) => {
    setPresentProvince(e.target.selectedOptions[0].text);

    const response = cities(e.target.value);
    setCity(response);
    setBarangay([]);
  };

  const barangay = (e) => {
    setPresentCity(e.target.selectedOptions[0].text);

    const response = barangays(e.target.value);
    setBarangay(response);
  };

  const brgy = (e) => {
    setPresentBrgy(e.target.selectedOptions[0].text);
  };

  const savePatientInformation = () => {
    const fromDb = inputFields;
    let isSuccess = true;

    const arr = fromDb || [];

    arr.forEach((data) => {
      const { type, details } = data;

      if (type === 'Mobile Number') {
        if (details.length !== 11 && details.length !== 0) {
          toast.error('Mobile number must have 11 digits.');
          console.log(inputFields, 'inputFields');
          isSuccess = false;
          return;
        }
      }

      if (type === 'Landline Number') {
        if (details.length !== 8 && details.length !== 0) {
          toast.error('Landline number must have 8 digits.');
          console.log(inputFields, 'inputFields1');
          isSuccess = false;
          return;
        }
      }
    });

    if (isSuccess) {
      setOpenModal(true);
    }
  };

  const [permRegionData, setPermRegion] = useState([]);
  const [permProvinceData, setPermProvince] = useState([]);
  const [permCityData, setPermCity] = useState([]);
  const [permBarangayData, setPermBarangay] = useState([]);

  const permRegion = () => {
    const response = regions();

    setPermRegion(response);
  };

  const permProvince = (e) => {
    setPermanentRegion(e.target.selectedOptions[0].text);

    const response = provinces(e.target.value);
    setPermProvince(response);
    setPermCity([]);
    setPermBarangay([]);
  };

  const permCity = (e) => {
    setPermanentProvince(e.target.selectedOptions[0].text);

    const response = cities(e.target.value);
    setPermBarangay([]);
    setPermCity(response);
  };

  const permBarangay = (e) => {
    setPermanentCity(e.target.selectedOptions[0].text);

    const response = barangays(e.target.value);
    setPermBarangay(response);
  };

  const permBrgy = (e) => {
    setPermanentBrgy(e.target.selectedOptions[0].text);
  };

  useEffect(() => {
    region();
    permRegion();
  }, []);

  /** Patient Identity Form Start */
  const [identityDocumentDetails, setIdentityDocumentDetails] = useState(
    patientProfile?.patient_identity_documents,
  );

  const handleAddIdentityDocForm = () => {
    setIdentityDocumentDetails([
      ...identityDocumentDetails,
      {
        uuid: uuidv4(),
        id_type: '',
        id_number: '',
        link: '',
        allow_remove: true,
      },
    ]);
  };

  const handleRemoveIdentityDocForm = (uuid) => {
    const values = [...identityDocumentDetails];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setIdentityDocumentDetails(values);
  };

  const handleChangeIdentifyDocFormInput = (uuid, event) => {
    identityDocumentDetails.map((i) => {
      if (uuid === i.uuid) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setIdentityDocumentDetails(identityDocumentDetails);
  };

  const uploadFileChange = (value, info) => {
    switch (value.type) {
      case 'identityDoc':
        identityDocumentDetails.map((i) => {
          if (value.uuid === i.uuid) {
            i['file_uuid'] = info.uuid;
            i['link'] = info.cdnUrl;
          }
          return i;
        });

        setIdentityDocumentDetails(identityDocumentDetails);
        break;

      default:
        break;
    }
  };

  const uploadFileSelect = (value, file) => {
    if (!file) {
      switch (value.type) {
        case 'identityDoc':
          identityDocumentDetails.map((i) => {
            if (value.uuid === i.uuid) {
              i['link'] = '';
              deleteUploadcareFile(value.file_uuid);
            }
            return i;
          });

          setIdentityDocumentDetails(identityDocumentDetails);
          break;

        default:
          break;
      }
      return;
    }
  };

  const deleteUploadcareFile = (uuid) => {
    const URI = `${process.env.REACT_APP_UPLOADCARE_API_BASE_URL}/files/${uuid}/`;
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Uploadcare.Simple ${process.env.REACT_APP_UPLOADCARE_API_PUBLIC_KEY}:${process.env.REACT_APP_UPLOADCARE_API_SECRET_KEY}`,
      },
    };

    fetch(URI, options);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const fileSizeLimit = (bytes) => {
    return (fileInfo) => {
      if (fileInfo.size > bytes) {
        alert(`File is too big! File limit is ${formatBytes(bytes)}`);
        throw new Error(`File is too big! File limit is ${formatBytes(bytes)}`);
      }
    };
  };

  const validators = [fileSizeLimit(1024 * 1024 * 3)];

  /** Contact Details start */
  const [inputFields, setInputFields] = useState(patientProfile?.patient_contact_details);

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { id: uuidv4(), type: '', details: '', placeholder: 'Please select type' },
    ]);
  };

  const handleRemoveFields = (uuid) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setInputFields(values);
  };

  const newPlaceholder = (type) => {
    switch (type) {
      case '':
        return 'Please select type';
      case 'Mobile Number':
        return 'Enter mobile number (11)';
      case 'Landline Number':
        return 'Enter landline number (8)';
      default:
        return 'Please select type';
    }
  };

  const handleChangeInput = (id, event) => {
    const newData = inputFields.map((data) => {
      if (data?.id === id) {
        if (event.target.name === 'type') {
          const placeholder = newPlaceholder(event.target.value);

          const newData = {
            ...data,
            [event.target.name]: event.target.value,
            placeholder: placeholder,
          };

          return newData;
        }

        const newData = {
          ...data,
          [event.target.name]: event.target.value,
        };

        return newData;
      }

      return data;
    });

    setInputFields(newData);
  };

  useEffect(() => {
    // console.log('inputFields', inputFields);
  }, [inputFields]);

  /** Family Details start */
  const [familyDetails, setFamilyDetails] = useState(patientProfile?.patient_families);

  const handleAddFamilyForm = () => {
    setFamilyDetails([
      ...familyDetails,
      {
        uuid: uuidv4(),
        prefix: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        sex: '',
        relationship: '',
        purpose: '',
        contact_number: '',
        MRN: '',
      },
    ]);
  };

  const handleRemoveFamilyForm = (uuid) => {
    const values = [...familyDetails];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setFamilyDetails(values);
  };

  const handleChangeFamilyFormInput = (uuid, event) => {
    const newFamilyDetails = familyDetails.map((i) => {
      if (uuid === i.uuid) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setFamilyDetails(newFamilyDetails);
  };

  /** Other Details start */
  const [inputOtherFields, setInputOtherFields] = useState(patientProfile?.patient_other_details);

  const handleAddOtherFields = () => {
    setInputOtherFields([...inputOtherFields, { uuid: uuidv4(), endorsement: '', details: '' }]);
  };

  const handleRemoveOtherFields = (uuid) => {
    const values = [...inputOtherFields];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setInputOtherFields(values);
  };

  const handleChangeOtherInput = (id, event) => {
    const newInputOtherFields = inputOtherFields.map((i) => {
      // eslint-disable-next-line prefer-const
      let result = {};

      if (id === i.id) {
        result = { ...i, endorsement: event.target.value };

        return result;
      }

      return i;
    });
    setInputOtherFields(newInputOtherFields);
  };

  const [key, setKey] = useState(false);

  const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    const now = new Date();

    const age = today.getFullYear() - birthDate.getFullYear();

    if (birthDate < now) {
      // selected date is in the past
      setDateOfBirth(dateString);
      setAge(age);
    } else {
      setAge(0);
      setKey((k) => !k);
    }
  };

  const handleCheckBoxValue = (event, field) => {
    switch (field) {
      case 'newBorn':
        setIsNewBorn(event.target.checked);
        break;

      case 'fictitiousDateOfBirth':
        setFictitiousDateOfBirth(event.target.checked);
        break;

      case 'sameAsPresentAddress':
        setSameAsPresentAddress(event.target.checked);
        break;

      case 'philHealthMemberType':
        setPhilHealthMemberType(event.target.checked);
        break;
    }
  };

  const handleDropdownValue = (event, field) => {
    switch (field) {
      case 'civil_status':
        setCivilStatus(event.target.value);
        break;
      case 'prefix':
        setPrefix(event.target.value);
        break;

      case 'sex':
        setSex(event.target.value);
        break;

      case 'aliasPrefix':
        setAliasPrefix(event.target.value);
        break;

      case 'presentCountry':
        setPresentCountry(event.target.value);
        break;

      case 'presentRegion':
        setPresentRegion(event.target.value);
        break;

      case 'presentCity':
        setPresentCity(event.target.value);
        break;

      case 'presentBrgy':
        setPresentBrgy(event.target.value);
        break;

      case 'permanentCountry':
        setPermanentCountry(event.target.value);
        break;

      case 'permanentRegion':
        setPermanentRegion(event.target.value);
        break;

      case 'permanentCity':
        setPermanentCity(event.target.value);
        break;

      case 'permanentBrgy':
        setPermanentBrgy(event.target.value);
        break;

      case 'emergencyContactprefix':
        setEmergencyContactPrefix(event.target.value);
        break;

      case 'emergencyContactRelation':
        setEmergencyContactRelation(event.target.value);
        break;

      case 'emergencyContactPurpose':
        setEmergencyContactPurpose(event.target.value);
        break;

      case 'philHealthMemberType':
        setPhilHealthMemberType(event.target.value);
        break;

      case 'philHealthSex':
        setPhilHealthPatientSex(event.target.value);
        break;

      case 'philHealthIcdCode':
        setPhilHealthPatientIcdCode(event.target.value);
        break;
      case 'patientOtherDetailsStatus':
        setPatientOtherDetailsStatus(event.target.value);
        break;
    }
  };

  const occupationJson = [
    {
      occupation: occupation,
      industry: industry,
      employer: employer,
    },
  ];

  const emergencyContact = [
    {
      prefix: emergencyContactPrefix,
      first_name: emergencyContactFirstName,
      middle_name: emergencyContactMiddleName,
      last_name: emergencyContactLastName,
      suffix: emergencyContactSuffix,
      relationship: emergencyContactRelation,
      purpose: emergencyContactPurpose,
      contact_number: emergencyContactNumber,
      MRN: MRN,
    },
  ];

  const philHealth = [
    {
      MPIN: MPIN,
      first_name: philHealthFirstName,
      middle_name: philHealthMiddleName,
      last_name: philHealthLastName,
      suffix: philHealthSuffix,
      member_type: philHealthMemberType,
      is_own_account: philHealthIsOwnAccount,
      relation_to_member: philHealthRelation,
      patient_first_name: philHealthPatientFirstName,
      patient_middlename_name: philHealthPatientMiddleName,
      patient_last_name: philHealthPatientLastName,
      patient_suffix: philHealthPatientSuffix,
      patient_date_of_birth: philHealthPatientDateOfBirth,
      patient_sex: philHealthPatientSex,
      admission_date: philHealthPatientAdmissionDate,
      discharge_date: philHealthPatientDischargeDate ? philHealthPatientDischargeDate : null,
      icd_code: philHealthPatientIcdCode,
      actual_amount: philHealthTotalAmount,
      amount_claimed: philHealthAmountClaimed,
      is_final: philHealthIsFinal,
    },
  ];

  const params = {
    MRN: MRN,
    age: age,
    sex: sex,
    suffix: suffix,
    prefix: prefix,
    religion: religion,
    last_name: lastName,
    first_name: firstName,
    is_new_born: isNewBorn,
    middle_name: middleName,
    id: patientProfile?.id,
    nationality: nationality,
    civil_status: civilStatus,
    alias_prefix: aliasPrefix,
    alias_suffix: aliasSuffix,
    alias_gender: aliasGender,
    date_of_birth: dateOfBirth,
    contact_details: inputFields,
    patient_status: patientStatus,
    alias_last_name: aliasLastName,
    alias_diagnosis: aliasDiagnosis,
    other_details: inputOtherFields,
    alias_first_name: aliasFirstName,
    reference_number: referenceNumber,
    alias_middle_name: aliasMiddleName,
    reference_location: referenceLocation,
    is_fictitious_date_of_birth: fictitiousDateOfBirth,
    patient_present_address: {
      present_brgy: presentBrgy,
      present_city: presentCity,
      present_region: presentRegion,
      present_country: presentCountry,
      present_zip_code: presentZipCode,
      present_address1: presentAddress1,
      present_address2: presentAddress2,
      patient_province: presentProvince,
    },
    patient_permanent_address: {
      permanent_brgy: sameAsPresentAddress ? presentBrgy : permanentBrgy,
      permanent_city: sameAsPresentAddress ? presentCity : permanentCity,
      permanent_region: sameAsPresentAddress ? presentRegion : permanentRegion,
      permanent_country: sameAsPresentAddress ? presentCountry : permanentCountry,
      patient_province: sameAsPresentAddress ? presentProvince : permanentProvince,
      permanent_zip_code: sameAsPresentAddress ? presentZipCode : permanentZipCode,
      permanent_address1: sameAsPresentAddress ? presentAddress1 : permanentAddress1,
      permanent_address2: sameAsPresentAddress ? presentAddress2 : permanentAddress2,
    },
  };

  useEffect(() => {
    if (patientProfile === null) {
      navigate(-1);
    } else {
      patientProfile;
    }
  }, [patientProfile]);

  return (
    <div className='formEditPatientProfile'>
      <FormUI>
        <div className='formEditPatientProfile__header'>
          <h4>Edit Patient Profile</h4>
          <div>
            <Button className='me-3' variant='save' onClick={savePatientInformation}>
              SAVE
            </Button>
            <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
              CANCEL
            </Button>
          </div>
        </div>
        <div className='avatar__container'>
          <div className='svg__container'>
            <UserSVG className='image_circle' />
          </div>
          <h2 className='mt-4 text-uppercase'>
            <span className='patient__lastName fw-bold'>{patientProfile?.last_name}</span>,{' '}
            {patientProfile?.first_name}
          </h2>
          <h5 className='patient__number mt-1'>{patientProfile?.id}</h5>
          <div className='patient__info mt-1'>
            <span>
              {patientProfile?.age} / {patientProfile?.sex}
            </span>
            <span>{moment(patientProfile?.date_of_birth).format('MMMM-DD-YYYY')}</span>
          </div>
        </div>

        <AccordionUI>
          <Accordion defaultActiveKey='0'>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Body>
                  <FormUI>
                    <Row>
                      <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Reference number<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Reference Number'
                            value={patientProfile?.reference_number}
                            onChange={(event) => {
                              setReferenceNumber(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Reference Location<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Medical Records Location'
                            value={referenceLocation}
                            onChange={(event) => {
                              setReferenceLocation(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Hospital Service</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row>
                      <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Triage Categorization<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) => handleDropdownValue(event, 'er_status')}
                          >
                            <option>-Select-</option>
                            <option value='Resuscitation'>RESUSCITATION</option>
                            <option value='Emergent'>EMERGENT</option>
                            <option value='Urgent'>URGENT</option>
                            <option value='Less_Urgent'>LESS URGENT</option>
                            <option value='Non_Urgent'>NON-URGENT</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Hospital Service<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) => handleDropdownValue(event, 'hospital_service')}
                          >
                            <option value='all'>-Select-</option>
                            <option value='ent'>ENT</option>
                            <option value='medicine'>Medicine</option>
                            <option value='ob_gyne'>OB-GYNE</option>
                            <option value='urology'>Urology</option>
                            <option value='cardiology'>Cardiology</option>
                            <option value='pulmonary Medicine'>Pulmonary Medicine</option>
                            <option value='opthalmology'>Opthalmology</option>
                            <option value='surgery'>Surgery</option>
                            <option value='orthopedic Surgery'>Orthopedic Surgery</option>
                            <option value='endocrinology'>Endocrinology</option>
                            <option value='pediatrics'>Pediatrics</option>
                            <option value='dermatology'>Dermatology</option>
                            <option value='oncology'>Oncology</option>
                            <option value='neurology'>Neurology</option>
                            <option value='gastroenterology'>Gastroenterology</option>
                            <option value='internal_medicine'>Internal Medicine</option>
                            <option value='medico_legal'>Medico Legal</option>
                            <option value='nephrology'>Nephrology</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Patient Demographics</Accordion.Header>
              <Accordion.Body>
                <FormUI>
                  <Row className='d-flex align-items-center'>
                    <Col lg={2}>
                      <Form.Group className='mb-3'>
                        <Form.Label>MRN</Form.Label>
                        <Form.Control
                          required
                          style={{ maxWidth: '320px' }}
                          type='text'
                          value={MRN}
                          placeholder='Enter Here'
                          onChange={(event) => {
                            setMRN(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col lg={2}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Check
                          type='checkbox'
                          label='New Born'
                          checked={patientProfile?.is_new_born === false ? 'false' : 'true'}
                          onChange={(event) => handleCheckBoxValue(event, 'newBorn')}
                        />
                      </Form.Group>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Prefix/Title</Form.Label>
                        <Form.Select
                          disabled={aliasToggle}
                          required={aliasToggle}
                          type='text'
                          name='prefix'
                          value={prefix}
                          onChange={(event) => handleDropdownValue(event, 'prefix')}
                        >
                          <option value=''>-</option>
                          <option value='mr'>Mr.</option>
                          <option value='mrs'>Mrs.</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          First Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          disabled={aliasToggle}
                          required={aliasToggle}
                          type='text'
                          name='firstName'
                          placeholder='Enter First Name'
                          value={firstName}
                          onChange={(event) => {
                            setFirstName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Middle Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          disabled={aliasToggle}
                          required={aliasToggle}
                          type='text'
                          placeholder='Enter Middle Name'
                          value={middleName}
                          onChange={(event) => {
                            setMiddleName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Last Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          disabled={aliasToggle}
                          required={aliasToggle}
                          type='text'
                          placeholder='Enter Last Name'
                          value={lastName}
                          onChange={(event) => {
                            setLastName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Suffix</Form.Label>
                        <Form.Control
                          disabled={aliasToggle}
                          type='text'
                          placeholder='Enter Suffix'
                          value={suffix}
                          onChange={(event) => {
                            setSuffix(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Sex<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          disabled={aliasToggle}
                          required={aliasToggle}
                          onChange={(event) => handleDropdownValue(event, 'sex')}
                          value={sex}
                        >
                          <option value=''>Select Gender</option>
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Date of Birth<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          disabled={aliasToggle}
                          required={aliasToggle}
                          type='date'
                          key={key}
                          value={dateOfBirth}
                          onChange={(event) => {
                            setDateOfBirth(event.target.value);
                            getAge(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Age<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          readOnly
                          value={age}
                          type='number'
                          disabled={aliasToggle}
                          required={aliasToggle}
                          onChange={(event) => {
                            setAge(event.target.value);
                          }}
                          onKeyDown={(evt) =>
                            (evt.key === 'e' ||
                              evt.key === 'E' ||
                              evt.key === '-' ||
                              evt.key === '.') &&
                            evt.preventDefault()
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Nationality<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          disabled={aliasToggle}
                          required={aliasToggle}
                          type='text'
                          placeholder='Enter Here'
                          value={nationality}
                          onChange={(event) => {
                            setNationality(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Civil Status<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          disabled={aliasToggle}
                          required={aliasToggle}
                          onChange={(event) => handleDropdownValue(event, 'civil_status')}
                        >
                          <option value=''>-</option>
                          <option value='Single'>Single</option>
                          <option value='Married'>Married</option>
                          <option value='Separated'>Separated</option>
                          <option value='Annulled'>Annulled</option>
                          <option value='Widowed'>Widowed</option>
                          <option value='Widower'>Widower</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {/* <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Religion<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          type='text'
                          value={religion}
                          placeholder='Enter Here'
                          onChange={(event) => {
                            setReligion(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col>
                      <div className='d-flex nowrap align-items-center mb-3'>
                        <Button
                          className={aliasToggle && 'active'}
                          variant='alias'
                          onClick={() => setAliasToggle((prevState) => !prevState)}
                        >
                          <span className='aliasRound'></span>
                        </Button>
                        <span> Alias / Fictitious Name</span>
                      </div>
                    </Col>
                    {aliasToggle && (
                      <>
                        <Row>
                          {/* <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>Prefix/Title</Form.Label>
                              <Form.Select
                                onChange={(event) => handleDropdownValue(event, 'aliasPrefix')}
                              >
                                <option value=''>-</option>
                                <option value='male'>Mr.</option>
                                <option value='female'>Mrs.</option>
                              </Form.Select>
                            </Form.Group>
                          </Col> */}
                          <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                First Name<span className='asterisk'>*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Enter First Name'
                                value={aliasFirstName}
                                onChange={(event) => {
                                  setAliasFirstName(event.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                          {/* <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                Middle Name<span className='asterisk'>*</span>
                              </Form.Label>
                              <Form.Control requied
                                type='text'
                                placeholder='Enter Middle Name'
                                onChange={(event) => {
                                  setAliasMiddleName(event.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col> */}
                          <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                Last Name<span className='asterisk'>*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Enter Last Name'
                                value={aliasLastName}
                                onChange={(event) => {
                                  setAliasLastName(event.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                          {/* <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>Suffix</Form.Label>
                              <Form.Control
                                type='text'
                                placeholder='Enter Suffix'
                                onChange={(event) => {
                                  setAliasSuffix(event.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col> */}
                          <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>Sex</Form.Label>
                              <Form.Select
                                name='sex'
                                onChange={(event) => setAliasGender(event.target.value)}
                              >
                                <option value=''>- select -</option>
                                <option value='male'>Male</option>
                                <option value='femail'>Female</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                Diagnosis<span className='asterisk'>*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Enter Diagnosis'
                                value={aliasDiagnosis}
                                onChange={(event) => {
                                  setAliasDiagnosis(event.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Row>
                </FormUI>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Patient Address Details</Accordion.Header>
              <Accordion.Body>
                <FormUI>
                  <Row className='d-flex align-items-center'>
                    <Col lg={2}>
                      <h6 className='mb-3 fw-bold'>Present Address</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Country<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select value='Philippines'>
                          <option value=''>Select Country</option>
                          {countryList.map((item, key) => {
                            return (
                              <option key={key} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Region<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={province} onSelect={region} value={presentRegion}>
                          <option value=''>{presentRegion}</option>
                          {regionData &&
                            regionData.length > 0 &&
                            regionData.map((item, key) => {
                              return (
                                <option key={item.region_code} value={item.region_code}>
                                  {item.region_name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Province<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={city} value={presentProvince}>
                          <option value=''>{presentProvince}</option>
                          {provinceData &&
                            provinceData.length > 0 &&
                            provinceData.map((item, key) => {
                              return (
                                <option key={item.province_code} value={item.province_code}>
                                  {item.province_name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          City<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={barangay} value={presentCity}>
                          <option value=''>{presentCity}</option>
                          {cityData &&
                            cityData.length > 0 &&
                            cityData.map((item, key) => {
                              return (
                                <option key={item.city_code} value={item.city_code}>
                                  {item.city_name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Barangay<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select value={presentBrgy} onChange={brgy}>
                          <option value=''>{presentBrgy}</option>
                          {barangayData &&
                            barangayData.length > 0 &&
                            barangayData.map((item, key) => {
                              return (
                                <option key={item.brgy_code} value={item.brgy_code}>
                                  {item.brgy_name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          value={presentZipCode}
                          onChange={(event) => {
                            setPresentZipCode(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Address Line 1<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          value={presentAddress1}
                          onChange={(event) => {
                            setPresentAddress1(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Subdivision/Village<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          value={presentAddress2}
                          onChange={(event) => {
                            setPresentAddress2(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row className='d-flex align-items-center'>
                    <Col>
                      <div className='d-flex align-items-center my-3'>
                        <span className='me-3 fw-bold'>Permanent Address</span>
                        <Form.Check
                          type='checkbox'
                          label='Same as Present Address'
                          onChange={(event) => handleCheckBoxValue(event, 'sameAsPresentAddress')}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row> */}
                  {/* <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Country<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select value='Philippines'>
                          <option value=''>Select Country</option>
                          {countryList.map((item, key) => {
                            return (
                              <option key={key} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Region<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={permProvince} onSelect={permRegion}>
                          <option value=''>{permanentRegion}</option>
                          {permRegionData &&
                            permRegionData.length > 0 &&
                            permRegionData.map((item, key) => {
                              return (
                                <option key={item.region_code} value={item.region_code}>
                                  {item.region_name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                  {/* <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Province<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                          onChange={permCity}
                        >
                          <option value=''>{permanentProvince}</option>
                          {permProvinceData &&
                            permProvinceData.length > 0 &&
                            permProvinceData.map((item, key) => {
                              return (
                                <option key={item.province_code} value={item.province_code}>
                                  {item.province_name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          City<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                          onChange={permBarangay}
                        >
                          <option value=''>{permanentCity}</option>
                          {permCityData &&
                            permCityData.length > 0 &&
                            permCityData.map((item, key) => {
                              return (
                                <option key={item.city_code} value={item.city_code}>
                                  {item.city_name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Barangay<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                          onChange={permBrgy}
                        >
                          <option value=''>{permanentBrgy}</option>
                          {permBarangayData &&
                            permBarangayData.length > 0 &&
                            permBarangayData.map((item, key) => {
                              return (
                                <option key={item.brgy_code} value={item.brgy_code}>
                                  {item.brgy_name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          value={permanentZipCode}
                          onChange={(event) => {
                            setPermanentZipCode(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Address Line 1<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          value={permanentAddress1}
                          onChange={(event) => {
                            setPermanentAddress1(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Subdivision/Village<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          value={permanentAddress2}
                          onChange={(event) => {
                            setPermanentAddress2(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col> */}
                  {/* </Row> */}
                </FormUI>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Row>
            <Col>
              <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>Patient&#39;s Contact Details</Accordion.Header>
                  <Accordion.Body>
                    <FormUI>
                      <Form.Group>
                        <Row>
                          <Col lg='5'>
                            <Form.Label>
                              Contact Type<span className='asterisk'>*</span>
                            </Form.Label>
                          </Col>
                          <Col lg='5'>
                            <Form.Label>
                              Contact Details<span className='asterisk'>*</span>
                            </Form.Label>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        {Array.isArray(inputFields) === true &&
                          inputFields.length > 0 &&
                          inputFields.map((inputField, key, { length }) => (
                            <div key={inputField.id}>
                              <Row>
                                <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Select
                                      required
                                      name='type'
                                      value={inputField?.type}
                                      onKeyDown={(evt) =>
                                        (evt.key === 'e' ||
                                          evt.key === 'E' ||
                                          evt.key === '-' ||
                                          evt.key === '.') &&
                                        evt.preventDefault()
                                      }
                                      onChange={(event) => handleChangeInput(inputField.id, event)}
                                    >
                                      <option value='' hidden>
                                        - Select -
                                      </option>
                                      <option value='Mobile Number'>Mobile Number</option>
                                      <option value='Landline Number'>Landline Number</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Control
                                      required
                                      type='number'
                                      name='details'
                                      placeholder={inputField?.placeholder}
                                      value={inputField.details}
                                      onChange={(event) => {
                                        if (event.target.value.length >= 12) {
                                          return;
                                        } else {
                                          handleChangeInput(inputField.id, event);
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </Col>

                                <Col lg='auto' className='d-flex align-items-center'>
                                  {key + 1 === length ? (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleAddFields}
                                    >
                                      <CirclePlusSVG />
                                    </Button>
                                  ) : (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleRemoveFields}
                                    >
                                      <CircleMinusSVG />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          ))}
                      </Form.Group>
                    </FormUI>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col>
              <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>Patient&#39;s Other Details</Accordion.Header>
                  <Accordion.Body>
                    <FormUI>
                      <Form.Group>
                        {Array.isArray(inputOtherFields) === true &&
                          inputOtherFields.length > 0 &&
                          inputOtherFields.map((inputOtherField, key, { length }) => (
                            <div key={inputOtherField.uuid}>
                              <Row>
                                <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      Patient Endorsement<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Control
                                      type='text'
                                      placeholder='Enter patient endorsement'
                                      name='endorsement'
                                      value={inputOtherField.endorsement}
                                      onChange={(event) =>
                                        handleChangeOtherInput(inputOtherField.id, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>

                                {/* <Col lg='5'>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    Patient Details<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    placeholder='Enter patient details'
                                    name='details'
                                    value={inputOtherField.details}
                                    onChange={(event) =>
                                      handleChangeOtherInput(inputOtherField.uuid, event)
                                    }
                                  />
                                </Form.Group>
                              </Col> */}

                                <Col lg='auto' className='d-flex align-items-center'>
                                  {key + 1 === length ? (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleAddOtherFields}
                                    >
                                      <CirclePlusSVG />
                                    </Button>
                                  ) : (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleRemoveOtherFields}
                                    >
                                      <CircleMinusSVG />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                              {/* <Row>
                              <Col lg='10'>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    Status<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Select
                                    onChange={(event) => handleDropdownValue(event, 'patientOtherDetailsStatus')}
                                  >
                                    <option value=''>-</option>
                                    <option value='walkin'>Walk-In</option>
                                    <option value='transferredFromOtherFacility'>Transferred from Other Facility</option>
                                    <option value='transferredToOtherFacility'>Transferred to Other Facility</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row> */}
                            </div>
                          ))}
                      </Form.Group>
                    </FormUI>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Row>
            <Col>
              <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>Patient&#39;s Uploaded Files</Accordion.Header>
                  <Accordion.Body>
                    <div className='uploaded_files'>
                      <Row>
                        <Col>
                          <Row>
                            <Col lg='4'>
                              <span>
                                <b className='uploaded_files_list_header'>Upload Timestamp</b>
                              </span>
                            </Col>
                            <Col>
                              <span>
                                <b className='uploaded_files_list_header'>Filename</b>
                              </span>
                            </Col>
                          </Row>
                          {patientProfile?.file_uploads?.length > 0 ? (
                            patientProfile?.file_uploads?.map((item, key) => {
                              return (
                                <div className='uploaded_files_content' key={key}>
                                  <Row>
                                    <Col lg='4'>
                                      <span>
                                        {moment(item?.createdAt).format('MMMM-DD-YYYY hh:mm A')}
                                      </span>
                                    </Col>
                                    <Col lg='auto'>
                                      <span className='value'>
                                        <a
                                          href={`${process.env.REACT_APP_API_BASE_URL}/files/${item?.filename}`}
                                          target='_blank'
                                          rel='noreferrer'
                                        >
                                          {item?.originalname}
                                        </a>
                                      </span>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })
                          ) : (
                            <div className='uploaded_files_content'>
                              <span>N/A</span>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>

                    <Button
                      variant='outline-primary'
                      id='upload_files'
                      onClick={() => setShowUploadModal(true)}
                    >
                      UPLOAD FILES
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          {/* <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Patient&#39;s Identity Documents</Accordion.Header>
              <Accordion.Body>
                <FormUI>
                  <div className='patientIdentity__container'>
                    {identityDocumentDetails &&
                      identityDocumentDetails.map((item, key, { length }) => {
                        return (
                          <div className='patientIdentity' key={item.uuid}>
                            <Row>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    ID Type<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Select
                                    name='id_type'
                                    value={item.id_type}
                                    onChange={(event) =>
                                      handleChangeIdentifyDocFormInput(item.uuid, event)
                                    }
                                  >
                                    <option value=''>- Select -</option>
                                    <option value='philHealth'>PhilHealth</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>ID Number</Form.Label>
                                  <Form.Control
                                    type='text'
                                    value={item.id_number}
                                    placeholder='Enter Here'
                                    name='id_number'
                                    onChange={(event) =>
                                      handleChangeIdentifyDocFormInput(item.uuid, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className='file-upload'>
                                  <Widget
                                    publicKey={process.env.REACT_APP_UPLOADCARE_API_PUBLIC_KEY}
                                    id='link'
                                    name='link'
                                    tabs='file camera url'
                                    imagesOnly='true'
                                    Clearable={true}
                                    onChange={(info) =>
                                      uploadFileChange(
                                        { type: 'identityDoc', uuid: item.uuid },
                                        info,
                                      )
                                    }
                                    onFileSelect={(file) =>
                                      uploadFileSelect(
                                        {
                                          type: 'identityDoc',
                                          uuid: item.uuid,
                                          file_uuid: item.file_uuid,
                                        },
                                        file,
                                      )
                                    }
                                    validators={validators}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {key + 1 === length ? (
                              <Button
                                className='add'
                                variant='transparent'
                                onClick={() => handleAddIdentityDocForm(item.uuid)}
                              >
                                <CirclePlusSVG />
                              </Button>
                            ) : (
                              <Button
                                className='remove'
                                variant='transparent'
                                onClick={() => handleRemoveIdentityDocForm()}
                              >
                                <CircleMinusSVG />
                              </Button>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </FormUI>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
          {/* <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Contact in Case of Emergency</Accordion.Header>
              <Accordion.Body>
                <FormUI>
                  <Row className='d-flex align-items-center'>
                    <Col lg={2}>
                      <h6 className='mb-3 fw-bold'>Contact Person Name</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Prefix/Title</Form.Label>
                        <Form.Select
                          onChange={(event) => handleDropdownValue(event, 'emergencyContactprefix')}
                          value={emergencyContactPrefix}
                        >
                          <option value=''>-</option>
                          <option value='mr'>Mr.</option>
                          <option value='mrs'>Mrs.</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          First Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          value={emergencyContactFirstName}
                          placeholder='Enter First Name'
                          onChange={(event) => {
                            setEmergencyContactFirstName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Middle Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Middle Name'
                          value={emergencyContactMiddleName}
                          onChange={(event) => {
                            setEmergencyContactMiddleName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Last Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          value={emergencyContactLastName}
                          placeholder='Enter Last Name'
                          onChange={(event) => {
                            setEmergencyContactName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Suffix</Form.Label>
                        <Form.Control
                          type='text'
                          value={emergencyContactSuffix}
                          placeholder='Enter Suffix'
                          onChange={(event) => {
                            setEmergencyContactSuffix(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='d-flex align-items-center'>
                    <Col lg={2}>
                      <h6 className='mb-3 fw-bold'>Contact Details</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Relationship<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          value={emergencyContactRelation}
                          onChange={(event) =>
                            handleDropdownValue(event, 'emergencyContactRelation')
                          }
                        >
                          <option value=''>-</option>
                          <option value='spouse'>Spouse</option>
                          <option value='son'>Son</option>
                          <option value='daughter'>Daughter</option>
                          <option value='mother'>Mother</option>
                          <option value='father'>Father</option>
                          <option value='otherRelatives'>Other Relatives</option>
                          <option value='notRelative'>Not Relative</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Purpose<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          value={emergencyContactPurpose}
                          onChange={(event) =>
                            handleDropdownValue(event, 'emergencyContactPurpose')
                          }
                        >
                          <option value=''>-</option>
                          <option value='financial'>Financial</option>
                          <option value='support'>Moral Support</option>
                          <option value='basicneeds'>Basic needs</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Contact Number<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='number'
                          value={emergencyContactNumber}
                          placeholder='Enter Contact Number'
                          onChange={(event) => {
                            setEmergencyContacNumber(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </FormUI>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Patient Occupation Details</Accordion.Header>
              <Accordion.Body>
                <FormUI>
                  <Row className='d-flex align-items-center'>
                    <Col lg={2}>
                      <h6 className='mb-3 fw-bold'>Present Job</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Occupation<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          value={occupation}
                          placeholder='Enter Here'
                          onChange={(event) => {
                            setOccupation(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Industry<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          value={industry}
                          placeholder='Enter Here'
                          onChange={(event) => {
                            setIndustry(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Employer<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          value={employer}
                          placeholder='Enter Here'
                          onChange={(event) => {
                            setEmployer(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </FormUI>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Family Details</Accordion.Header>
              <Accordion.Body>
                <FormUI>
                  <div className='familyDetails__container'>
                    {familyDetails &&
                      familyDetails.map((item) => {
                        return (
                          <div className='familyDetails' key={item.uuid}>
                            <Row className='d-flex align-items-center'>
                              <Col lg={2}>
                                <h6 className='mb-3 fw-bold'>Family Member Name</h6>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg='auto'>
                                <Form.Group className='mb-3'>
                                  <Form.Label>Prefix/Title</Form.Label>

                                  <Form.Select
                                    name='prefix'
                                    value={item.prefix}
                                    onChange={(event) =>
                                      handleChangeFamilyFormInput(item.uuid, event)
                                    }
                                  >
                                    <option>-</option>
                                    <option>Mr.</option>
                                    <option>Mrs.</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    First Name<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    value={item.first_name}
                                    placeholder='Enter First Name'
                                    name='first_name'
                                    onChange={(event) =>
                                      handleChangeFamilyFormInput(item.uuid, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    Middle Name<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    placeholder='Enter Middle Name'
                                    value={item.middle_name}
                                    name='middle_name'
                                    onChange={(event) =>
                                      handleChangeFamilyFormInput(item.uuid, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    Last Name<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    placeholder='Enter Last Name'
                                    value={item.last_name}
                                    name='last_name'
                                    onChange={(event) =>
                                      handleChangeFamilyFormInput(item.uuid, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>Suffix</Form.Label>
                                  <Form.Control
                                    type='text'
                                    placeholder='Enter Suffix'
                                    name='suffix'
                                    value={item.suffix}
                                    onChange={(event) =>
                                      handleChangeFamilyFormInput(item.uuid, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg='auto'>
                                <Form.Group className='mb-3'>
                                  <Form.Label>Sex</Form.Label>
                                  <Form.Select
                                    name='sex'
                                    value={item.sex}
                                    onChange={(event) =>
                                      handleChangeFamilyFormInput(item.uuid, event)
                                    }
                                  >
                                    <option value=''>- select -</option>
                                    <option value='male'>Male</option>
                                    <option value='femail'>Female</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className='d-flex align-items-center'>
                              <Col lg={2}>
                                <h6 className='mb-3 fw-bold'>Contact Details</h6>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>Relationship</Form.Label>
                                  <Form.Select
                                    name='relationship'
                                    value={item.relationship}
                                    onChange={(event) =>
                                      handleChangeFamilyFormInput(item.uuid, event)
                                    }
                                  >
                                    <option value=''>- select -</option>
                                    <option value='Parent'>Parent</option>
                                    <option value='Child'>Child</option>
                                    <option value='Sibling'>Sibling</option>
                                    <option value='Guarantor'>Guarantor</option>
                                    <option value='Guardian'>Guardian</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    Purpose<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Select
                                    value={item.purpose}
                                    name='purpose'
                                    onChange={(event) =>
                                      handleChangeFamilyFormInput(item.uuid, event)
                                    }
                                  >
                                    <option value=''>- select -</option>
                                    <option value='Financial'>Financial</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    Contact Number<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    placeholder='Enter Here'
                                    value={item.contact_number}
                                    name='contact_number'
                                    onChange={(event) =>
                                      handleChangeFamilyFormInput(item.uuid, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className='mb-3'>
                                  <Form.Label>
                                    MRN / PIN<span className='asterisk'>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    placeholder='Enter Here'
                                    name='MRN'
                                    value={item.MRN}
                                    onChange={(event) =>
                                      handleChangeFamilyFormInput(item.uuid, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Button
                              variant='transparent'
                              onClick={() => handleRemoveFamilyForm(item.uuid)}
                            >
                              <CircleMinusSVG />
                            </Button>
                          </div>
                        );
                      })}
                  </div>
                  <Row>
                    <Col>
                      <div className='wrapper'>
                        <Button variant='addField' onClick={() => handleAddFamilyForm()}>
                          Add field
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </FormUI>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>PhilHealth Eligibility Form</Accordion.Header>
              <Accordion.Body>
                <FormUI>
                  <Row className='d-flex align-items-center'>
                    <Col lg={3}>
                      <h6 className='mb-3 fw-bold'>Member&#39;s PhilHealth Information</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Member&#39;s PhilHealth Identification No
                          <span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          value={MPIN}
                          onChange={(event) => {
                            setMPIN(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          First Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter First Name'
                          value={philHealthFirstName}
                          onChange={(event) => {
                            setPhilHealthFirstName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Middle Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Middle Name'
                          value={philHealthMiddleName}
                          onChange={(event) => {
                            setPhilHealthMiddleName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Last Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Last Name'
                          value={philHealthLastName}
                          onChange={(event) => {
                            setPhilHealthLastName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Suffix</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Suffix'
                          value={philHealthSuffix}
                          onChange={(event) => {
                            setPhilHealthSuffix(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Member Type<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          onChange={(event) => handleDropdownValue(event, 'philHealthMemberType')}
                          value={philHealthMemberType}
                        >
                          <option value=''>-</option>
                          <option value='basic'>Basic</option>
                          <option value='advance'>Advance</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg='auto'>
                      <Form.Group className='mb-3'>
                        <Form.Label>Member is the Patient?</Form.Label>
                        <div className='d-flex flex-row nowrap align-items-center mt-2'>
                          <Form.Check
                            name='philHealthMemberType'
                            inline
                            label='Yes'
                            type='radio'
                            value='yes'
                            check={patientProfile?.patient_phil_health?.is_own_account ? 'true' : 'true'}
                            onChange={(e) => setPhilHealthIsOwnAccount(true)}
                          />
                          <Form.Check
                            name='philHealthMemberType'
                            inline
                            label='No'
                            type='radio'
                            value='no'
                            onChange={(e) => setPhilHealthIsOwnAccount(false)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='d-flex align-items-center'>
                    <Col lg={3}>
                      <h6 className='mt-3 mb-3 fw-bold'>Patient&#39;s Basic Information</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='auto'>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Relationship to Member<span className='asterisk'>*</span>
                        </Form.Label>
                        <div className='d-flex flex-row nowrap align-items-center mt-2'>
                          <Form.Check
                            inline
                            label='Spouse'
                            name='relationship'
                            value='spouse'
                            type='radio'
                            checked={patientProfile?.patient_phil_health?.relation_to_member === 'spouse' ? 'true' : 'false'}
                            onChange={(e) => setPhilHealthRelation('spouse')}
                          />
                          <Form.Check
                            inline
                            label='Child'
                            name='relationship'
                            value='child'
                            type='radio'
                            checked={patientProfile?.patient_phil_health?.relation_to_member === 'child' ? 'true' : 'false'}
                            onChange={(e) => setPhilHealthRelation('child')}
                          />
                          <Form.Check
                            inline
                            label='Parent'
                            name='relationship'
                            value='parent'
                            type='radio'
                            checked={patientProfile?.patient_phil_health?.relation_to_member === 'parent' ? 'true' : 'false'}
                            onChange={(e) => setPhilHealthRelation('parent')}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          First Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          disabled
                          placeholder='Enter First Name'
                          value={philHealthPatientFirstName}
                          onChange={(event) => {
                            setPhilHealthPatientFirstName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Middle Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Middle Name'
                          disabled
                          value={philHealthPatientMiddleName}
                          onChange={(event) => {
                            setPhilHealthPatientMiddleName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Last Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Last Name'
                          value={philHealthPatientLastName}
                          disabled
                          onChange={(event) => {
                            setPhilHealthPatientLastName(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Suffix</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Suffix'
                          value={philHealthPatientSuffix}
                          disabled
                          onChange={(event) => {
                            setPhilHealthPatientSuffix(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='4'>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Date of Birth<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='date'
                          value={philHealthPatientDateOfBirth}
                          disabled
                          onChange={(event) => {
                            setPhilHealthPatientDateOfBirth(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='2'>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Sex<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select
                          onChange={(event) => handleDropdownValue(event, 'philHealthSex')}
                          value={philHealthPatientSex}
                        >
                          <option value=''>Select Gender</option>
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='d-flex align-items-center'>
                    <Col lg={3}>
                      <h6 className='mt-3 mb-3 fw-bold'>Confinement Information</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>Admission Date</Form.Label>
                        <Form.Control
                          type='date'
                          value={philHealthPatientAdmissionDate}
                          onChange={(event) => {
                            setPhilHealthPatientAdmissionDate(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>Discharge Date</Form.Label>
                        <Form.Control
                          type='date'
                          value={philHealthPatientDischargeDate}
                          onChange={(event) => {
                            setPhilHealthPatientDischargeDate(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>ICD - Codes</Form.Label>
                        <Form.Select
                          onChange={(event) => handleDropdownValue(event, 'philHealthIcdCode')}
                        >
                          <option value=''>-</option>
                          <option value='1'>1CD-1</option>
                          <option value='2'>ICD-2</option>
                          <option value='3'>ICD-3</option>
                          <option value='4'>ICD-4</option>
                          <option value='5'>ICD-5</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Total Amount Actual<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='number'
                          value={philHealthTotalAmount}
                          onChange={(event) => {
                            setPhilHealthTotalAmount(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Total Amount Claimed<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='number'
                          value={philHealthAmountClaimed}
                          onChange={(event) => {
                            setPhilHealthAmountClaimed(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>Final?</Form.Label>
                        <div className='d-flex flex-row nowrap align-items-center mt-2'>
                          <h1>{philHealthIsFinal}</h1>
                          <Form.Check
                            inline
                            label='Yes'
                            value='yes'
                            name='final'
                            checked={patientProfile?.patient_phil_health?.is_final === true ? 'yes' : 'false'}
                            type='radio'
                            onChange={(e) => setPhilHealthIsFinal(true)}
                          />
                          <Form.Check
                            inline
                            label='No'
                            value='no'
                            name='final'
                            type='radio'
                            checked={!patientProfile?.patient_phil_health?.is_final === true ? 'yes' : 'false'}
                            onChange={(e) => setPhilHealthIsFinal(false)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </FormUI>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
        </AccordionUI>
        <div className='d-flex justify-content-end mt-5'>
          <Button className='me-3' variant='save' onClick={savePatientInformation}>
            SAVE
          </Button>
          <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
            CANCEL
          </Button>
        </div>
      </FormUI>
      {openModal && (
        <ConfirmationModal
          params={params}
          setOpenModal={setOpenModal}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
      )}
      <FileUploadModal
        showUploadModal={showUploadModal}
        setShowUploadModal={setShowUploadModal}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />
    </div>
  );
};

export default FormEditPatientProfile;
