/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import Status from 'constant/status';
import axios from 'axios';
import _ from 'lodash';

export const showActivePatients = createAsyncThunk(
  'ACTIVE_PATIENT',
  async (params, { rejectWithValue }) => {
    return await axios
      .get('/patient', { params: { tab: params.tab, patient_status: params?.patient_status } })
      .then((response) => {
        // console.log('params: ', response.data)
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showPatientHistory = createAsyncThunk(
  'PATIENT_HISTORY',
  async (params, { rejectWithValue }) => {
    return await axios
      .get(`/medical_records/${params.id}`)
      .then((response) => {
        // console.log('API RESONSE thunks', response.data);
        return response.data;
      })
      .catch((error) => {
        console.log('ERROR', error);
        return rejectWithValue(error);
      });
  },
);

export const showVisitDetails = createAsyncThunk(
  'PATIENT_VISIT_DETAILS',
  async (params, { rejectWithValue }) => {
    return await axios
      .get(`/medical_records/history/${params.visitNumber}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log('ERROR', error);
        return rejectWithValue(error);
      });
  },
);

export const createCertificateConfinement = createAsyncThunk(
  'certificate_of_confinement',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/certificate_of_confinement',
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createMedicalCertificate = createAsyncThunk(
  'MEDICAL_CERTIFICATE',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/medical_certificate',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createMedicoLegal = createAsyncThunk(
  'MEDICO_LEGAL',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/medico_legal_cert',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createDismembermentForm = createAsyncThunk(
  'CERT_OF_DISMEMBERED_BODY_PART',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/cert_of_dismembered_body_part',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createAdTestificandum = createAsyncThunk(
  'SUBPOENA_AD_TESTIFICANDUM',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/subpoena_ad_testificandum',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createDucesTecum = createAsyncThunk(
  'SUBPOENA_DUCES_TECUM',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/subpoena_duces_tecum',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createOutpatientRecord = createAsyncThunk(
  'OUTPATIENT_RECORD',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/outpatient_record',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createMedicalRecord = createAsyncThunk(
  'MEDICAL_RECORD',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/medical_record',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getMedicalForms = createAsyncThunk(
  'MEDICAL_FORMS',
  async (params = {}, { rejectWithValue }) => {
    return await axios
      .get('/medical_records/form', { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getMedicalRecordID = createAsyncThunk(
  'MEDICAL_FORMS_RECORD_BY_ID',
  async (id = '', { rejectWithValue }) => {
    return await axios
      .get(`/medical_records/form/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getMedicalFormsbyID = createAsyncThunk(
  'MEDICAL_FORMS_by_ID',
  async (params = {}, { rejectWithValue }) => {
    return await axios
      .get(`/medical_records/form/patient/${params}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createEmergencyTreatmentRecord = createAsyncThunk(
  'EMERGENCY_TREATMENT_RECORD',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/emergency_treatment',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createAdmissionandDischargeRecord = createAsyncThunk(
  'AdmissionandDischarge',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/admission_and_discharge',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createDischargeSummaryRecord = createAsyncThunk(
  'DischargeSummary',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/discharge_summary',
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createHistoryandPhysicalExamRecord = createAsyncThunk(
  'HistoryandPhysicalExam',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/history_and_physical_exam',
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createLiveBirthFormRecord = createAsyncThunk(
  'LiveBirthForm',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/live_birth_form',
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createCertofDeath = createAsyncThunk(
  'certofDeath',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/cert_of_death',
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createCertofFetalDeath = createAsyncThunk(
  'certofFetalDeath',
  async (payload = {}, { rejectWithValue }) => {
    return await axios({
      method: payload.method ? payload.method : 'post',
      url: '/medical_records/form/cert_of_fetal_death',
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
