/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */

// import core & vendor packages below
import { useEffect, useState } from 'react';
import { Row, Col, Accordion, Form, Button, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// import components below
import FormUI from 'components/UI/FormUI';
import AccordionUI from 'components/UI/AccordionUI';
import {
  createAppointment,
  selectAppointmentItem,
  setAppointmentAction,
  updateAppointment,
} from 'reducers/appointmentReducer';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './FormEdit.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const FormEdit = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({});
  const [patientId, setPatientId] = useState('');
  const patientItem = useSelector(selectPatientItem);
  const appointment = useSelector(selectAppointmentItem);

  useEffect(() => {
    setPayload(JSON.parse(JSON.stringify(appointment)));
    payload.HMOs = [];
    payload.clinical_documents = [];
  }, []);

  const handleChange = (key) => (e) => {
    if (e.target.type === 'file') {
      setPayload({
        ...payload,
        [key]: e.target.files,
      });
      return;
    }

    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        setPayload({
          ...payload,
          [key]: e.target.value,
        });
      } else {
        setPayload({
          ...payload,
          [key]: '',
        });
      }

      return;
    }

    setPayload({
      ...payload,
      [key]: e.target.value,
    });

    if (key === 'patient_id') {
      dispatch(showPatient(payload.patient_id === '' ? 0 : payload.patient_id));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    payload.patientId = patientId;

    dispatch(updateAppointment(payload));
  };

  const handleClose = () => {
    dispatch(setAppointmentAction({ isView: true }));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(showPatient(patientId !== '' ? patientId : 0));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [patientId]);
  return (
    <div className='formEditAppointment'>
      <div className='d-flex justify-content-between border-bottom border-2 mb-3'>
        <p className='title fs-5'>Edit Appointment</p>

        <div>
          <Button variant='danger' className='mb-3' onClick={() => handleClose()}>
            Close
          </Button>
        </div>
      </div>
      <FormUI>
        <Form onSubmit={(e) => handleSubmit(e)}>
          {/* <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Search Patient ID</Form.Label>
                <InputGroup>
                  <InputGroup.Text className='bg-white'>
                    <MagnifyingGlassSVG />
                  </InputGroup.Text>
                  <Form.Control
                    type='number'
                    className='bg-white'
                    placeholder='Search here...'
                    readOnly
                    onChange={(e) => setPatientId(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col md={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Patient ID</Form.Label>
                <Form.Control type='text' readOnly value={patientItem ? patientItem?.patient?.id : ''} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Patient Name</Form.Label>
                <Form.Control
                  type='text'
                  readOnly
                  value={patientItem ? patientItem?.patient?.full_name : ''}
                />
              </Form.Group>
            </Col>
          </Row>

          <p className='fw-bold mb-4 mt-3'>
            Please fill out fields marked with <span className='text-danger'>*</span>
          </p>

          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Date and Time <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  type='date'
                  required
                  value={payload.appointment_date}
                  onChange={handleChange('appointment_date')}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Mobile Number <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Here'
                  required
                  value={payload.mobile_number}
                  onChange={handleChange('mobile_number')}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Email Address <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Here'
                  required
                  value={payload.email}
                  onChange={handleChange('email')}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={8}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Doctor <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Here'
                  required
                  value={payload.name_of_doctor}
                  onChange={handleChange('name_of_doctor')}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Department <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Here'
                  required
                  value={payload.department}
                  onChange={handleChange('department')}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className='mb-3'>
            <Form.Label>
              Reason for Appointment <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              placeholder='Enter Here'
              required
              value={payload.reason_for_appointment1}
              onChange={handleChange('reason_for_appointment1')}
            />
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Senior Citizen ID No. <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Here'
                  required
                  value={payload.senior_citizen_id}
                  onChange={handleChange('senior_citizen_id')}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  PWD/OSCAA ID No. <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Here'
                  required
                  value={payload.pwd_oscaa_id}
                  onChange={handleChange('pwd_oscaa_id')}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Senior or PWD Patient <span className='text-danger'>*</span>
                </Form.Label>
                <div className='d-flex flex-row nowrap align-items-center mt-2'>
                  <Form.Check
                    inline
                    label='Yes'
                    name='is_senior_and_or_pwd'
                    type='radio'
                    value='yes'
                    required
                    checked={payload.is_senior_and_or_pwd === 'yes' && true}
                    onChange={handleChange('is_senior_and_or_pwd')}
                  />
                  <Form.Check
                    inline
                    label='No'
                    name='is_senior_and_or_pwd'
                    type='radio'
                    value='no'
                    required
                    checked={payload.is_senior_and_or_pwd === 'no' && true}
                    onChange={handleChange('is_senior_and_or_pwd')}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  With HMO <span className='text-danger'>*</span>
                </Form.Label>
                <div className='d-flex flex-row nowrap align-items-center mt-2'>
                  <Form.Check
                    inline
                    label='Yes'
                    name='with_hmo'
                    type='radio'
                    value='yes'
                    required
                    checked={payload.with_hmo === 'yes' && true}
                    onChange={handleChange('with_hmo')}
                  />
                  <Form.Check
                    inline
                    label='No'
                    name='with_hmo'
                    type='radio'
                    value='no'
                    required
                    checked={payload.with_hmo === 'no' && true}
                    onChange={handleChange('with_hmo')}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className='mb-3'>
            <Form.Label>
              Landmark/s <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              placeholder='Enter Here'
              required
              value={payload.landmarks}
              onChange={handleChange('landmarks')}
            />
          </Form.Group>

          <p style={{ fontSize: '.8em' }}>
            Please upload a scanned copy of your HMO Letter of Authorization (LOA). If you do not
            have a LOA, please upload a photo of your HMO ID, Government ID and your doctor&apos;s
            request.
          </p>
          <Row>
            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  HMO Files <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Label className='file-form-label'>
                  <div className='fakeInput'>
                    {payload.HMOs?.length > 0 ? payload.HMOs[0]['name'] : 'File'}
                    <span>Choose File</span>
                  </div>
                  <Form.Control type='file' multiple onChange={handleChange('HMOs')} />
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>

          <AccordionUI>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Health Declaration Form</Accordion.Header>
                <Accordion.Body>
                  <p style={{ fontSize: '.7em' }}>
                    Please fill out this form as required by the Philippine Government that will
                    require you to declare any illness and provide information that will aid in
                    contract tracing, should the need arise. Be sure that the information you give
                    is accurate and complete. All the information submitted be encrypted, and
                    strictly used only in compliance to Philippine law, guidelines, and ordinances,
                    in relation to business operation in light of COVID-19 response.
                  </p>
                  <Row>
                    <Col md={3}>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Nationality <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          value={payload.nationality}
                          onChange={handleChange('nationality')}
                        >
                          <option value=''>- Select -</option>
                          <option value='Filipino'>Filipino</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Age <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='number'
                          placeholder='Enter Here'
                          required
                          value={payload.age}
                          onChange={handleChange('age')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      Did you travel in a place with COVID-19 local transmission in the past 14
                      days? <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Check
                      inline
                      label='Yes'
                      name='travel'
                      type='radio'
                      value='yes'
                      className='mb-2'
                      required
                      checked={
                        payload.travelled_to_place_with_covid_in_the_past_two_weeks === 'yes' &&
                        true
                      }
                      onChange={handleChange('travelled_to_place_with_covid_in_the_past_two_weeks')}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name='travel'
                      type='radio'
                      value='no'
                      required
                      checked={
                        payload.travelled_to_place_with_covid_in_the_past_two_weeks === 'no' && true
                      }
                      onChange={handleChange('travelled_to_place_with_covid_in_the_past_two_weeks')}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      If YES, kindly indicate place visited <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      placeholder='Enter Here'
                      required
                      value={payload.travelled_places_in_the_past_two_weeks}
                      onChange={handleChange('travelled_places_in_the_past_two_weeks')}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      Do you have a close contact with probable or confirmed COVID-19 case in the
                      past 14 days? <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Check
                      inline
                      label='Yes'
                      name='close_contact'
                      type='radio'
                      value='yes'
                      className='mb-2'
                      required
                      checked={payload.close_contact_in_the_past_two_weeks === 'yes' && true}
                      onChange={handleChange('close_contact_in_the_past_two_weeks')}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name='close_contact'
                      type='radio'
                      value='no'
                      required
                      checked={payload.close_contact_in_the_past_two_weeks === 'no' && true}
                      onChange={handleChange('close_contact_in_the_past_two_weeks')}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      Do you have co-morbidities like Diabetes, CPD, Hypertension, Cancer?{' '}
                      <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Check
                      inline
                      label='Yes'
                      name='co_morbidities'
                      type='radio'
                      value='yes'
                      className='mb-2'
                      required
                      checked={payload.have_comorbidity === 'yes' && true}
                      onChange={handleChange('have_comorbidity')}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name='co_morbidities'
                      type='radio'
                      value='no'
                      required
                      checked={payload.have_comorbidity === 'no' && true}
                      onChange={handleChange('have_comorbidity')}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      If YES, kindly indicate others <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      placeholder='Enter Here'
                      value={payload.have_comorbidity_details}
                      onChange={handleChange('have_comorbidity_details')}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      Are you pregnant? <span className='text-danger'>*</span>
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        label='Yes'
                        name='is_pregnant'
                        type='radio'
                        value='yes'
                        className='mb-2'
                        required
                        checked={payload.is_pregnant === 'yes' && true}
                        onChange={handleChange('is_pregnant')}
                      />
                      <Form.Check
                        inline
                        label='No'
                        name='is_pregnant'
                        type='radio'
                        value='no'
                        required
                        checked={payload.is_pregnant === 'no' && true}
                        onChange={handleChange('is_pregnant')}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      Are you a health worker? <span className='text-danger'>*</span>
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        label='Yes'
                        name='is_health_worker'
                        type='radio'
                        value='yes'
                        className='mb-2'
                        required
                        checked={payload.is_health_worker === 'yes' && true}
                        onChange={handleChange('is_health_worker')}
                      />
                      <Form.Check
                        inline
                        label='No'
                        name='is_health_worker'
                        type='radio'
                        value='no'
                        required
                        checked={payload.is_health_worker === 'no' && true}
                        onChange={handleChange('is_health_worker')}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      If YES, where <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      placeholder='Enter Here'
                      value={payload.travelled_places_as_health_worker}
                      onChange={handleChange('travelled_places_as_health_worker')}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      Did you have a fever {`(>38°C)`} in a past 14 days?{' '}
                      <span className='text-danger'>*</span>
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        label='Yes'
                        name='has_fever'
                        type='radio'
                        value='yes'
                        className='mb-2'
                        required
                        checked={payload.have_fever_in_the_past_two_weeks === 'yes' && true}
                        onChange={handleChange('have_fever_in_the_past_two_weeks')}
                      />
                      <Form.Check
                        inline
                        label='No'
                        name='has_fever'
                        type='radio'
                        value='no'
                        required
                        checked={payload.have_fever_in_the_past_two_weeks === 'no' && true}
                        onChange={handleChange('have_fever_in_the_past_two_weeks')}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      Did you have the following symptoms within the past 14 days?{' '}
                      <span className='text-danger'>*</span>
                    </Form.Label>
                    <div className='formCheckWrapper'>
                      <Form.Check
                        className='mb-2'
                        type='checkbox'
                        label='Cough (59-82%)'
                        value='yes'
                        checked={payload.have_cough === 'yes' && true}
                        onChange={handleChange('have_cough')}
                      />
                      <Form.Check
                        className='mb-2'
                        type='checkbox'
                        label='Fatigue/Myalgia'
                        value='yes'
                        checked={payload.have_fatigue_myalgia === 'yes' && true}
                        onChange={handleChange('have_fatigue_myalgia')}
                      />
                      <Form.Check
                        className='mb-2'
                        type='checkbox'
                        label='Anorexia'
                        value='yes'
                        checked={payload.have_anorexia === 'yes' && true}
                        onChange={handleChange('have_anorexia')}
                      />
                      <Form.Check
                        className='mb-2'
                        type='checkbox'
                        label='Difficulty of Breathing/Shortness of breath'
                        value='yes'
                        checked={payload.have_difficulty_breathing === 'yes' && true}
                        onChange={handleChange('have_difficulty_breathing')}
                      />
                      <Form.Check
                        className='mb-2'
                        type='checkbox'
                        label='Body Weakness'
                        value='yes'
                        checked={payload.have_body_weakness === 'yes' && true}
                        onChange={handleChange('have_body_weakness')}
                      />
                      <Form.Check
                        className='mb-2'
                        type='checkbox'
                        label='Sore Throat'
                        value='yes'
                        checked={payload.have_sore_throat === 'yes' && true}
                        onChange={handleChange('have_sore_throat')}
                      />
                      <Form.Check
                        className='mb-2'
                        type='checkbox'
                        label='Others'
                        value='yes'
                        checked={payload.other_symptoms === 'yes' && true}
                        onChange={handleChange('other_symptoms')}
                      />
                    </div>
                  </Form.Group>

                  {/* Todo */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      Specify Others <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control as='textarea' rows={3} placeholder='Enter Here' />
                  </Form.Group>

                  <Row>
                    <Col md={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Name of Doctor <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          required
                          value={payload.name_of_doctor}
                          onChange={handleChange('name_of_doctor')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Specialization <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          required
                          value={payload.specialization}
                          onChange={handleChange('specialization')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Blood Pressure <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          required
                          value={payload.blood_pressure}
                          onChange={handleChange('blood_pressure')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className='mb-3'>
                    <Form.Label>Declaration and Data Privacy Consent Form</Form.Label>
                    <p style={{ fontSize: '.7em' }}>
                      I AGREE by signing this form, I declare that the information I have given is
                      true, correct, and complete. I understand that failure to answer any question
                      or giving a false answer can be penalized in accordance with law. I also
                      voluntarily and freely consent to the collection and sharing of the above
                      personal information only in relation to Dagupan Doctors Villaflor Memorial
                      Hospital compliance to Republic of the Philippines COVID-19 business operation
                      requirements and in accordance with the Data Privacy Act and Mandatory
                      Reporting of Notifiable Diseases and Health Events of Public Health Concern
                      Act (RA 11322).
                    </p>
                    <Form.Check type='checkbox' label='I Agree' required checked={true} />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </AccordionUI>

          <AccordionUI>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Appointment Information Form</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Services Type <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          value={payload.services_type}
                          onChange={handleChange('services_type')}
                        >
                          <option value=''>- select -</option>
                          <option value={'Clinic Consultation'}>Clinic Consultation</option>
                          <option value={'Clinic Packages'}>Clinic Packages</option>
                          <option value={'Clinic Teleconsultation'}>Clinic Teleconsultation</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Appointment Date <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='date'
                          required
                          value={payload.appointment_date}
                          onChange={handleChange('appointment_date')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Department <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          value={payload.department}
                          onChange={handleChange('department')}
                        >
                          <option value=''>- select -</option>
                          <option value='Nursing Department'>Nursing Department</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <p className='fw-bold mb-0'>
                    Services Type {'(ST)'} <span className='text-danger'>*</span>
                  </p>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          If {'(ST)'} is Clinic Consultation <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Select
                          value={payload.clinic_consultation_service}
                          onChange={handleChange('clinic_consultation_service')}
                        >
                          <option value=''>- select -</option>
                          <option value='Executive Checkup'>Executive Checkup</option>
                          <option value=' Annual Physical Examination'>
                            Annual Physical Examination
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          If {'(ST)'} is Clinic Packages <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Select
                          value={payload.clinic_packages}
                          onChange={handleChange('clinic_packages')}
                        >
                          <option value=''>- select -</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          If {'(ST)'} is Clinic Teleconsultation{' '}
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Select
                          value={payload.clinic_teleconsultation}
                          onChange={handleChange('clinic_teleconsultation')}
                        >
                          <option value=''>- select -</option>
                          <option value='Teleconsultation '>Teleconsultation </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Resource <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='time'
                          required
                          value={payload.resource}
                          onChange={handleChange('resource')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Appointment Status <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          value={payload.appointment_status}
                          onChange={handleChange('appointment_status')}
                        >
                          <option value=''>- select -</option>
                          <option value='New Request'>New Request</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Remarks <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          required
                          value={payload.remarks}
                          onChange={handleChange('remarks')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </AccordionUI>

          <AccordionUI>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Medical History</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      Please describe your present illness <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      size={3}
                      placeholder='Enter Here'
                      required
                      value={payload.medical_history}
                      onChange={handleChange('medical_history')}
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label>
                      Other illness and Conditions <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      size={3}
                      placeholder='Enter Here'
                      value={payload.other_illness_and_condition}
                      onChange={handleChange('other_illness_and_condition')}
                    />
                  </Form.Group>

                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>
                          Diagnostic Results and Other Clinical Documents{' '}
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Label className='file-form-label'>
                          <div className='fakeInput'>
                            {payload.clinical_documents?.length > 0
                              ? payload.clinical_documents[0]['name']
                              : 'File'}
                            <span>Choose File</span>
                          </div>
                          <Form.Control
                            type='file'
                            multiple
                            onChange={handleChange('clinical_documents')}
                          />
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>

                  <p style={{ fontSize: '.7em' }}>
                    Please upload any laboratory or diagnosis result and other clinical documents
                    that you wish to share to the provider
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </AccordionUI>

          <Row className='d-flex justify-content-end'>
            <Col md={4}>
              <Button variant='search' type='submit' className='w-100'>
                SAVE Appointment
              </Button>
            </Col>
          </Row>
        </Form>
      </FormUI>
    </div>
  );
};

export default FormEdit;
