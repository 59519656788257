/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getStockAdjustmentList, getDepartments } from './thunks/stockAdjustmentThunk';

const initialState = {
  data: [],
  included: null,
  item: null,
  logs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },

  // For departments
  departmentData: [],
  departmentLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'stockAdjustment',
  initialState,
  reducers: {},
  extraReducers: {
    [getStockAdjustmentList.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
      state.included = action.payload.included;
    },
    [getStockAdjustmentList.pending]: (state) => {
      state.logs.isSuccess = false;
      state.logs.isFailed = false;
      state.logs.error = '';
      state.logs.isLoading = true;
    },
    [getStockAdjustmentList.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [getDepartments.fulfilled]: (state, action) => {
      state.departmentData = action.payload.data;
      state.departmentLogs.isSuccess = true;
      state.departmentLogs.isLoading = false;
    },
    [getDepartments.pending]: (state) => {
      state.departmentLogs.isSuccess = false;
      state.departmentLogs.isFailed = false;
      state.departmentLogs.error = '';
      state.departmentLogs.isLoading = true;
    },
    [getDepartments.rejected]: (state) => {
      state.departmentData = [];
      state.departmentLogs.isLoading = false;
      state.departmentLogs.isFailed = true;
      state.departmentLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: stockAdjustmentActions, reducer: stockAdjustmentReducer } = slice;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.stockAdjustment || initialState;

export const selectstockAdjustment = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState,
);

/* --------------------------------------------------------------------------------- */

export { getStockAdjustmentList, getDepartments };
