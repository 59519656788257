/* eslint-disable camelcase */

// import core & vendor packages 
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import components 
import FormUI from 'components/UI/FormUI';

// import assets
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const DenyRequestModal = ({ showProfile, setShowProfile, state, denyRestockById }) => {
  const [remark, setRemark] = useState('');
  return (
    <Modal
      size='lg'
      show={showProfile.isShowDenyRequestModal}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isShowDenyRequestModal: false,
          };
        })
      }
      aria-labelledby='CONFIRMATION'
      className='adjustment-modal'
    >
      <Modal.Body>
        <div className='adjustment-request'>
          <div className='d-flex header'>
            <p>CONFIRMATION</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, isShowDenyRequestModal: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='adjustment-form'>
            <Row>
              <Col>
                <div className='receiving-cont mt-4'>
                  <p>You are about to denied this request.</p>

                  <h6>Please enter your reason.</h6>

                  <Row className='w-100 mt-4'>
                    <Col sm={12}>
                      <Form.Group className='mb-4'>
                        <FormControl
                          as='textarea'
                          className='bg-white'
                          placeholder='Enter Here'
                          onChange={(e) => setRemark(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-2 buttons-cont'>
                  <Button
                    className={`${showProfile.forApprove ? 'green' : 'pure-red'}`}
                    variant='primary'
                    onClick={() => {
                      denyRestockById(state?.id, remark);
                      setRemark('');
                      setShowProfile((prevState) => {
                        return { ...prevState, isShowDenyRequestModal: false };
                      });
                    }}
                  >
                    Denied
                  </Button>
                  <Button
                    className='grey'
                    onClick={() => {
                      setRemark('');
                      setShowProfile((prevState) => {
                        return { ...prevState, isShowDenyRequestModal: false };
                      });
                    }}
                  >
                    CANCEL
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

DenyRequestModal.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

DenyRequestModal.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default DenyRequestModal;
