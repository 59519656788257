// Packages
import { useState } from 'react';

// components below
import ModalSupply from './Supply';
import ModalAddOrder from './AddOrder';
import ModalProcedure from './Procedure';
import Filter from './components/Filter';
import ModalMedication from './Medication';
import ModalLabRequest from './LabRequest';
import FileUploadModal from './FileUpload';
import FormPatientProfile from './FormPatientProfile';
import NursingPatientListTable from './components/Table';
import ModalNote from 'pages/Doctors/components/SearchPatient/components/Note';

// main component
const PatientWorklist = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    note: false,
    supply: false,
    profileId: null,
    addOrder: false,
    procedure: false,
    labRequest: false,
    medication: false,
    fileUpload: false,
    isShowProfile: false,
  });

  return (
    <>
      <div className='searchPatient mt-2'>
        {!showProfile.isShowProfile ? (
          <>
            <Filter setState={setState} />

            <div className='nursingPatientList'>
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className='fw-bold'>Patient List</h5>
              </div>

              <NursingPatientListTable
                state={state}
                setState={setState}
                setShowProfile={setShowProfile}
              />
            </div>
          </>
        ) : (
          <FormPatientProfile setShowProfile={setShowProfile} showProfile={showProfile} />
        )}
      </div>

      <ModalNote setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalSupply setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalAddOrder setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalProcedure setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalMedication setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalLabRequest setShowProfile={setShowProfile} showProfile={showProfile} />
      <FileUploadModal setShowProfile={setShowProfile} showProfile={showProfile} />
    </>
  );
};

export default PatientWorklist;
