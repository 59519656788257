import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getOvertimesList } from './thunks/overtimeTrackingThunks';

const initialLogs = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  // OvertimeList
  overtimeListData: [],
  overtimeListItem: [],
  overtimeListLogs: initialLogs,

};

const slice = createSlice({
  name: 'overtimeList',
  initialState,
  reducers: {
    resetOvertimeListLogState: (state) => {
      state.overtimeListLogs = initialLogs;
    },
  },

  extraReducers: {
    // OvertimeList
    [getOvertimesList.fulfilled]: (state, action) => {
      state.overtimeListLogs.isSuccess = true;
      state.overtimeListLogs.isLoading = false;
      state.overtimeListData = action.payload.data;
    },
    [getOvertimesList.pending]: (state) => {
      state.overtimeListLogs.isSuccess = true;
      state.overtimeListLogs.isFailed = false;
      state.overtimeListLogs.error = '';
      state.overtimeListLogs.isLoading = true;
    },
    [getOvertimesList.rejected]: (state) => {
      state.overtimeListLogs.isLoading = false;
      state.overtimeListLogs.isFailed = true;
    },

  },
});

export const { actions: overtimeTrackingActions, reducer: overtimeTrackingReducer } = slice;
export const { resetOvertimeListLogState } = overtimeTrackingActions;

const selectDomain = (state) => state.overtimeTracking || initialState;

export const selectOvertimeTracking = createSelector([selectDomain], (inventoryState) => inventoryState);

export { getOvertimesList };