// Packages
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

// Components
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import ConditionalRender from 'components/ConditionalRender';

// Assets
import './index.scss';

// Main Component
const CreateNewStockCheck = ({ setShowProfile, showProfile }) => {
  const [step, setStep] = useState(1);

  const isStepOne = step === 1;
  const isStepTwo = step === 2;
  const isStepThree = step === 3;

  const onNext = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const onPrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }

    if (step === 1) {
      setShowProfile({
        ...showProfile,
        showScreen: 1,
      });
    }
  };

  return (
    <div className='create-new-stock-check'>
      <h5>Create New Stock Check</h5>

      <Row className='mt-4 mb-4'>
        <Col>
          <div className={`step ${isStepOne ? 'active' : ''}`}>
            <p>Stock Check Information</p>
            <div className='arrow' />
          </div>
        </Col>
        <Col>
          <div className={`step ${isStepTwo ? 'active' : ''}`}>
            <p>Items for Stock Check</p>
          </div>
        </Col>
        <Col>
          <div className={`step ${isStepThree ? 'active' : ''}`}>
            <p>Perform Stock Check</p>
          </div>
        </Col>
      </Row>

      <ConditionalRender
        condition={isStepOne}
        renderIf={<StepOne onNext={onNext} onPrevious={onPrevious} />}
      />

      <ConditionalRender
        condition={isStepTwo}
        renderIf={
          <StepTwo onNext={onNext} onPrevious={onPrevious} setShowProfile={setShowProfile} />
        }
      />

      <ConditionalRender
        condition={isStepThree}
        renderIf={
          <StepThree onNext={onNext} onPrevious={onPrevious} setShowProfile={setShowProfile} />
        }
      />
    </div>
  );
};

CreateNewStockCheck.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

CreateNewStockCheck.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default CreateNewStockCheck;
