import { Modal, Form, Row, Col, Button } from 'react-bootstrap';

import './index.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import React, { useState } from 'react';

import FormUI from 'components/UI/FormUI';
import { getCashFlow, setCashFlow, updateCashFlow } from 'reducers/thunks/accountingThunks';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const NetIncreaseCashFlowStatementModal = (props) => {
  const dispatch = useDispatch();
  const { setShowModal, showModal } = props;
  const [validated, setValidated] = useState(false);
  const handleShow = () => setShowModal({ isShowForm: false });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      const params = { ...showModal.data, ...showModal.id };
      if (!params.id) {
        await dispatch(setCashFlow(params));
        toast.success('Successfully Added');
      } else {
        await dispatch(updateCashFlow(params));
        toast.success('Successfully Updated');
      }

      const p = {
        search: showModal.date,
      };

      await dispatch(getCashFlow(p));
      handleShow();
    }
    setValidated(true);
  };

  const handleChange = (event) => {
    setShowModal({
      ...showModal,
      data: {
        ...showModal.data,
        [event.target.name]: event.target.value,
        date: showModal.date,
      },
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Modal show={showModal.isShowForm} size='md' className='cashflow-modal'>
      <Modal.Body>
        <div className='add-room-request'>
          <div className='d-flex header'>
            <p>Add Cash Flow Statement</p>
            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
          </div>
        </div>

        <FormUI>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='mb-3 width-90 right-auto inline-label'>
                  <Form.Label>Detail Type</Form.Label>
                  <Form.Control
                    type='text'
                    name='field'
                    placeholder='Enter Here'
                    value={showModal.data?.field}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mt-4'>
                <Form.Group className='mb-3 width-90 right-auto inline-label'>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type='number'
                    name='amount'
                    placeholder='Enter Here'
                    value={showModal.data?.amount}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mt-6'>
              <hr />
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mb-4'>
                <Form.Group className='me-2'>
                  <Button className='btn btn-save' type='submit'>
                    Save
                  </Button>
                </Form.Group>
                <Form.Group className='ms-2'>
                  <Button variant='secondary' onClick={handleShow}>
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default NetIncreaseCashFlowStatementModal;
