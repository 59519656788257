/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
// main components below
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
// import UserClearance from './UserClearance';
// import StatusClearanceListTable from './components/ViewTable';

import ViewRecord from './view/index';
import Filter from './components/Filter';
import ModalAddRecord from './AddRecord';
import { selectPatientData } from 'reducers/patientReducer';
import DischargeSummaryActionTable from './components/Table';

// import assets below
import './style.scss';

// main component
const DischargeSummary = () => {
  // eslint-disable-next-line no-unused-vars
  const [showModal, setShowModal] = useState({
    addModal: false,
    profileId: null,
    profileData: null,
    isViewStatus: false,
    isProfileShow: false,
    viewRecordModal: false,
  });

  const [filter, setFilter] = useState({
    patient_name: '',
    bed_category: '',
    status: '',
  });

  const [filteredData, setFilteredData] = useState([]);

  const data = useSelector(selectPatientData);

  return (
    <>
      <div className='discharge-summary mt-4'>
        {!showModal.isProfileShow ? (
          <>
            <Filter
              data={data}
              filter={filter}
              setFilter={setFilter}
              setFilteredData={setFilteredData}
            />

            <div className='discharge-summary-list'>
              <div className='header-cont d-flex justify-content-between align-items-center'>
                <h5 className='fw-bold mb-3'>Patient List</h5>
                <Button
                  onClick={() => {
                    setShowModal((prevState) => {
                      return {
                        ...prevState,
                        addModal: true,
                      };
                    });
                  }}
                >
                  ADD RECORD
                </Button>
              </div>
              {!showModal.isViewStatus ? (
                <DischargeSummaryActionTable
                  data={data}
                  filter={filter}
                  filteredData={filteredData}
                  setShowModal={setShowModal}
                />
              ) : (
                // <StatusClearanceListTable setShowModal={setShowModal} />
                <>View Action Here</>
              )}
            </div>
          </>
        ) : (
          <>Test Here</>
          // <UserClearance showModal={showModal} setShowModal={setShowModal} />
        )}
      </div>
      <ViewRecord showModal={showModal} setShowModal={setShowModal} />
      <ModalAddRecord showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default DischargeSummary;
