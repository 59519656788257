/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

// import core & vendor packages below
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormControl, Button, Tabs, Tab, Image } from 'react-bootstrap';

// import components below
import Register from './Register';
import Disposal from './Disposal';
import Inventory from './Inventory';
import Equipment from './Equipment';
import JobOrders from './JobOrders';
import EntryDate from './EntryDate';
import Depreciation from './Depreciation';
import TabsUI from 'components/UI/TabsUI';
import { selectAuth } from 'reducers/authReducer';
import ConditionalRender from 'components/ConditionalRender';
import { TableFiltersPermission, checkStringPermission } from 'helpers/filteredPermissions';
import {
  updateJobOrder,
  addJobOrderItems,
  addDisposalItems,
  addEquipmentItems,
  addInventoryItems,
  addEntryDateItems,
  selectFixedAssets,
  addDepreciationItems,
  addRegistrationItems,
} from 'reducers/fixedAssetsReducer';

// import assets below
import './index.scss';
import { toast } from 'react-toastify';

// import assets below
const FixedAssetContainer = () => {
  const dispatch = useDispatch();

  const assetState = useSelector(selectFixedAssets);

  const authState = useSelector(selectAuth);
  const { userInfo } = authState;

  const {
    addJobOrderItemLogs,
    addDisposalItemLogs,
    addRegisterItemLogs,
    addEquipmentItemLogs,
    addInventoryItemLogs,
    addEntryDateItemLogs,
    addDepreciationItemLogs,
  } = assetState;

  const [activeKey, setActiveKey] = useState('');
  const [showScreen, setShowScreen] = useState(1);
  const [requestData, setRequestData] = useState();
  const [formData, setFormData] = useState({ formType: 'Job Orders' });

  const onChange = (key) => {
    setActiveKey(key);
  };

  const defaultTabs = [
    {
      title: 'Job Orders',
      component: JobOrders,
    },
    {
      title: 'Disposal',
      component: Disposal,
    },
    {
      title: 'Register',
      component: Register,
    },
    {
      title: 'Equipment',
      component: Equipment,
    },
    {
      title: 'Inventory',
      component: Inventory,
    },
    {
      title: 'Depreciation',
      component: Depreciation,
    },
    {
      title: 'Entry Date',
      component: EntryDate,
    },
  ];

  const handleChange = (e) => {
    const { value, name } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // for Jor Orders data
  const handleJobOrders = async () => {
    const params = {
      // lines: formData?.lines,
      userId: userInfo?.id,
      name: formData?.jobOrders,
      type: formData?.jobOrdersType,
      // cost_type: formData?.costType,
      criticality: formData?.criticality,
      description: formData?.description,
      parent_id: formData?.parentJobOrders,
      service_level: formData?.serviceLevel,
      related_id: formData?.relatedJobOrders,
      // job_orders_pool: formData?.jobOrdersPool,
      life_cycle_state: formData?.lifeCycleState,
    };

    const response = await dispatch(addJobOrderItems(params));

    if (response?.meta?.requestStatus === 'rejected') {
      // error message
      toast.error('Error');
      return;
    }

    if (response?.meta?.requestStatus === 'fulfilled') {
      // success message
      toast.success('Successfully added Record');
      setFormData({});
    }
  };

  // for Disposal data
  const handleDisposal = async () => {
    const params = {
      userId: userInfo?.id,
      date: formData?.date,
      debit: formData?.debit,
      credit: formData?.credit,
      account: formData?.account,
      value_model: formData?.valueModel,
      description: formData?.description,
      offset_account: formData?.offsetAccount,
      transaction_type: formData?.transactionType,
      offset_account_type: formData?.offsetAccountType,
    };

    const response = await dispatch(addDisposalItems(params));

    if (response?.meta?.requestStatus === 'rejected') {
      // error message
      toast.error('Error');
      return;
    }

    if (response?.meta?.requestStatus === 'fulfilled') {
      // success message
      toast.success('Successfully added Record');
      setFormData({});
    }
  };

  // for Registration data
  const handleRegister = async () => {
    const params = {
      userId: userInfo?.id,
      cost: formData?.cost,
      notes: formData?.notes,
      number: formData?.number,
      supplier: formData?.supplier,
      location: formData?.location,
      description: formData?.description,
      purchased_date: formData?.puchaseDate,
      serial_number: formData?.serialNumber,
      depreciation_rate: formData?.depreciationRate,
    };

    const response = await dispatch(addRegistrationItems(params));

    if (response?.meta?.requestStatus === 'rejected') {
      // error message
      toast.error('Error');
      return;
    }

    if (response?.meta?.requestStatus === 'fulfilled') {
      // success message
      toast.success('Successfully added Record');
      setFormData({});
    }
  };

  // for Equipment data
  const handleEquipment = async () => {
    const params = {
      userId: userInfo?.id,
      location: formData?.location,
      loan_rate: formData?.loanRate,
      condition: formData?.condition,
      down_payment: formData?.downpayment,
      intial_value: formData?.initialValue,
      current_value: formData?.currentValue,
      serial_number: formData?.serialNumber,
      expected_value: formData?.expectedValue,
      date_purchased: formData?.datePurchased,
      monthly_payment: formData?.monthlyPayment,
      item_description: formData?.description,
      loan_term_in_years: formData?.loanTermInYears,
      total_monthly_cost: formData?.totalMonthlyCost,
      years_of_service_left: formData?.yearsOfServiceLeft,
      monthly_operating_cost: formData?.monthlyOperatingCost,
      annual_straight_line_dep: formData?.annualStraightLineDepreciation,
      monthly_straight_line_dep: formData?.monthlyStraightLineDepreciation,
    };

    const response = await dispatch(addEquipmentItems(params));

    if (response?.meta?.requestStatus === 'rejected') {
      // error message
      toast.error('Error');
      return;
    }

    if (response?.meta?.requestStatus === 'fulfilled') {
      // success message
      toast.success('Successfully added Record');
      setFormData({});
    }
  };

  // for Inventory data
  const handleInventory = async () => {
    const params = {
      userId: userInfo?.id,
      vendor: formData?.vendor,
      location: formData?.location,
      po_number: formData?.poNumber,
      description: formData?.description,
      asset_number: formData?.assetNumber,
      purchase_price: formData?.purchasePrice,
      acquisition_date: formData?.acquisitionDate,
    };

    const response = await dispatch(addInventoryItems(params));

    if (response?.meta?.requestStatus === 'rejected') {
      // error message
      toast.error('Error');
      return;
    }

    if (response?.meta?.requestStatus === 'fulfilled') {
      // success message
      toast.success('Successfully added Record');
      setFormData({});
    }
  };

  // for Depreciation data
  const handleDepreciation = async () => {
    const params = {
      userId: userInfo?.id,
      class: formData?.class,
      block: formData?.block,
      number: formData?.number,
      asset_type: formData?.assetType,
      depreciation_date: formData?.depreciationDate,
    };

    const response = await dispatch(addDepreciationItems(params));

    if (response?.meta?.requestStatus === 'rejected') {
      // error message
      toast.error('Error');
      return;
    }

    if (response?.meta?.requestStatus === 'fulfilled') {
      // success message
      toast.success('Successfully added Record');
      setFormData({});
    }
  };

  // for EntryDate data
  const handleEntryDate = async () => {
    const params = {
      userId: userInfo?.id,
      years: formData?.years,
      amount: formData?.amount,
      asset_gl: formData?.assetGL,
      asset_no: formData?.assetNo,
      location: formData?.location,
      entry_date: formData?.entryDate,
      description: formData?.description,
      deep_expense: formData?.deepExpense,
      purchased_price: formData?.purchasedPrice,
      year_convention: formData?.yearConvention,
      acc_depreciation: formData?.accDepreciation,
      depreciation_date: formData?.depreciationDate,
      depreciation_method: formData?.depreciationMethod,
    };

    const response = await dispatch(addEntryDateItems(params));

    if (response?.meta?.requestStatus === 'rejected') {
      // error message
      toast.error('Error');
      return;
    }

    if (response?.meta?.requestStatus === 'fulfilled') {
      // success message
      toast.success('Successfully added Record');
      setFormData({});
    }
  };

  const handleUpdateJobOrder = async () => {
    const params = {
      // ilalagay dito yung data
    };

    const response = await dispatch(updateJobOrder({ id: formData?.id, params: params }));

    if (response?.meta?.requestStatus === 'rejected') {
      // error message
      toast.error('Error');
      return;
    }

    if (response?.meta?.requestStatus === 'fulfilled') {
      // success message
      toast.success('Successfully updated Record');
      setFormData({});
    }
  };

  const handleSave = () => {
    switch (formData?.formType) {
      case 'Job Orders':
        console.log('Clicked');
        handleJobOrders();
        break;
      case 'Disposal':
        handleDisposal();
        break;
      case 'Register':
        handleRegister();
        break;
      case 'Equipment':
        handleEquipment();
        break;
      case 'Inventory':
        handleInventory();
        break;
      case 'Depreciation':
        handleDepreciation();
        break;
      case 'Entry Date':
        handleEntryDate();
        break;
      default:
        break;
    }
  };

  const handleUpdate = () => {
    switch (formData?.formType) {
      case 'Job Orders':
        handleUpdateJobOrder();
        break;

      default:
        break;
    }
  };

  // eslint-disable-next-line prefer-const
  let tabs = TableFiltersPermission(defaultTabs, []);

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  // useEffect(() => {
  //   if (userInfo) {
  //     setFormData((prev) => {
  //       return {
  //         ...prev,
  //         userId: userInfo?.id,
  //       };
  //     });
  //   }
  // }, [userInfo]);

  return (
    <>
      {/* Fixed Assets Main */}
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>FIXED ASSETS</Form.Label>

                <FormControl className='blue-control' placeholder='Search' />
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-tabs'>
                <TabsUI>
                  <Tabs
                    defaultActiveKey={tabs[0].title}
                    onSelect={(eventKey) => {
                      onChange(eventKey);
                      setFormData((prev) => {
                        console.log(eventKey);
                        return {
                          ...prev,
                          formType: eventKey,
                        };
                      });
                    }}
                  >
                    {tabs.map(({ title, component: Component }, idx) => (
                      <Tab key={idx} eventKey={title} title={title}>
                        <Component
                          activeKey={activeKey}
                          setFormData={setFormData}
                          setShowScreen={setShowScreen}
                          setRequestData={setRequestData}
                        />
                      </Tab>
                    ))}
                  </Tabs>
                </TabsUI>
              </div>
            </div>
          </div>
        }
      />

      {/* Add Job Order */}
      <ConditionalRender
        condition={showScreen === 2}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ADD JOB ORDER</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Job Orders<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='jobOrders'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.jobOrders || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <h6>Identification</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Job Order Type<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='jobOrdersType'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.jobOrdersType || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Life Cycle State<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='lifeCycleState'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.lifeCycleState || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <h6>Job Orders</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Description<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='description'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.description || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Service Level<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='serviceLevel'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.serviceLevel || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Criticality<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='criticality'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.criticality || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <h6>Related Job Orders</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Parent Job Orders<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='parentJobOrders'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.parentJobOrders || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Related Job Orders<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='relatedJobOrders'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.relatedJobOrders || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <h6>Cost</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Cost Type<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='costType'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.costType || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <h6>Details</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Lines<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='lines'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.lines || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Job Orders Pool<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='jobOrdersPool'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.jobOrdersPool || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br></br>
                  </div>
                </div>

                <div className='details-footer'>
                  <Button
                    disabled={addJobOrderItemLogs?.isLoading}
                    onClick={handleSave}
                    className='save'
                  >
                    Save
                  </Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* View/Edit Job Order */}
      <ConditionalRender
        condition={showScreen === 2.5}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>VIEW / EDIT JOB ORDER</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Job Orders</Form.Label>
                          <FormControl
                            readOnly
                            name='jobOrders'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.jobOrders || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button className='edit'>&emsp;&emsp; Edit </Button>
                      </Col>
                    </Row>

                    <h6>Identification</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Job Order Type</Form.Label>
                          <FormControl
                            name='jobOrdersType'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.jobOrdersType || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Life Cycle State</Form.Label>
                          <FormControl
                            name='lifeCycleState'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.lifeCycleState || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <h6>Job Orders</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Description</Form.Label>
                          <FormControl
                            name='description'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.description || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Service Level</Form.Label>
                          <FormControl
                            name='serviceLevel'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.serviceLevel || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Criticality</Form.Label>
                          <FormControl
                            name='criticality'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.criticality || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <h6>Related Job Orders</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Parent Job Orders</Form.Label>
                          <FormControl
                            name='parentJobOrders'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.parentJobOrders || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Related Job Orders</Form.Label>
                          <FormControl
                            name='relatedJobOrders'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.relatedJobOrders || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <h6>Cost</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Cost Type</Form.Label>
                          <FormControl
                            name='costType'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.costType || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <h6>Details</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Lines</Form.Label>
                          <FormControl
                            name='lines'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.lines || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Job Orders Pool</Form.Label>
                          <FormControl
                            name='jobOrdersPool'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.jobOrdersPool || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br></br>
                  </div>
                </div>

                <div className='details-footer'>
                  <Button onClick={handleUpdate} className='save'>
                    Save Changes
                  </Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* Add Disposal */}
      <ConditionalRender
        condition={showScreen === 3}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ADD DISPOSAL</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Date<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            type='date'
                            name='date'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.date || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Transaction Type<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='transactionType'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.transactionType || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Account<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='account'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.account || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Value Model<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='valueModel'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.valueModel || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Description<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='description'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.description || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Debit<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='debit'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.debit || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Credit<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='credit'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.credit || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Offset Account Type<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            onChange={handleChange}
                            name='offsetAccountType'
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.offsetAccountType || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Offset Account<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='offsetAccount'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.offsetAccount || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br /> <br />
                  </div>
                </div>

                <div className='details-footer'>
                  <Button
                    variant='search' 
                    disabled={addDisposalItemLogs?.isLoading}
                    // disabled={disabled}
                    onClick={() => handleSave()} 
                    className='save'
                  >
                    Save
                  </Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* Add Registration */}
      <ConditionalRender
        condition={showScreen === 4}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ADD REGISTRATION</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Number<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='number'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.number || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <Row>
                      <Form.Group className='vertical-align'>
                        <Form.Label>Description<span className='asterisk'> *</span></Form.Label>
                        <FormControl
                          name='description'
                          onChange={handleChange}
                          placeholder='Enter Here'
                          className='border-seven'
                          value={formData?.description || ''}
                        />
                      </Form.Group>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Serial Number<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='serialNumber'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.serialNumber || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Location<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='location'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.location || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Purchase Date<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            type='date'
                            name='puchaseDate'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.puchaseDate || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Supplier<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='supplier'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.supplier || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Cost<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='cost'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.cost || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Depreciation Rate<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='depreciationRate'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.depreciationRate || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Form.Group className='vertical-align'>
                        <Form.Label>Notes<span className='asterisk'> *</span></Form.Label>
                        <FormControl
                          name='notes'
                          onChange={handleChange}
                          placeholder='Enter Here'
                          className='border-seven'
                          value={formData?.notes || ''}
                        />
                      </Form.Group>
                    </Row>
                  </div>
                </div>
                <br />
                <br />

                <div className='details-footer'>
                  <Button
                    disabled={addRegisterItemLogs?.isLoading}
                    onClick={handleSave}
                    className='save'
                  >
                    Save
                  </Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* Add Equipment */}
      <ConditionalRender
        condition={showScreen === 5}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ADD EQUIPMENT</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <h6>Physical Condition</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Serial Number<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='serialNumber'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.serialNumber || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <Row>
                      <Form.Group className='vertical-align'>
                        <Form.Label>Item Description<span className='asterisk'> *</span></Form.Label>
                        <FormControl
                          name='description'
                          onChange={handleChange}
                          placeholder='Enter Here'
                          className='border-seven'
                          value={formData?.description || ''}
                        />
                      </Form.Group>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Location<span className='asterisk'> *</span></Form.Label>
                          <Form.Select
                            name='location'
                            onChange={handleChange}
                            value={formData?.location || ''}
                          >
                            <option value='' hidden>
                              - Select -
                            </option>
                            <option value='Option 1'>Option 1</option>
                            <option value='Option 2'>Option 2</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Condition<span className='asterisk'> *</span></Form.Label>
                          <Form.Select
                            name='condition'
                            onChange={handleChange}
                            value={formData?.condition || ''}
                          >
                            <option value='' hidden>
                              Enter Here
                            </option>
                            <option value='Option 1'>Option 1</option>
                            <option value='Option 2'>Option 2</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Years of Service Left<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            name='yearsOfServiceLeft'
                            value={formData?.yearsOfServiceLeft || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <h6>Financial Status</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Initial Value<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='initialValue'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.initialValue || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Down Payment<span className='asterisk'> *</span></Form.Label>
                          <Form.Select
                            name='downpayment'
                            onChange={handleChange}
                            value={formData?.downpayment || ''}
                          >
                            <option value='' hidden>
                              Enter Here
                            </option>
                            <option value='Option 1'>Option 1</option>
                            <option value='Option 2'>Option 2</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Date Purchase or Leased<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            type='date'
                            name='datePurchased'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.datePurchased || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Loan term in years<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='loanTermInYears'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.loanTermInYears || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Loan rate<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='loanRate'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.loanRate || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Monthly Payment<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='monthlyPayment'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.monthlyPayment || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Monthly Operating Cost<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            name='monthlyOperatingCost'
                            value={formData?.monthlyOperatingCost || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Total Monthly Cost<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='totalMonthlyCost'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.totalMonthlyCost || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Expected Value at End of Loan Term<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='expectedValue'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.expectedValue || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Annual Straight-Line Depreciation<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            name='annualStraightLineDepreciation'
                            value={formData?.annualStraightLineDepreciation || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Monthly Straight-Line Depreciation<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            name='monthlyStraightLineDepreciation'
                            value={formData?.monthlyStraightLineDepreciation || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Current Value<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='currentValue'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.currentValue || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                </div>
                <br />
                <br />

                <div className='details-footer'>
                  <Button
                    disabled={addEquipmentItemLogs?.isLoading}
                    onClick={handleSave}
                    className='save'
                  >
                    Save
                  </Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* View/Edit Equipment */}
      <ConditionalRender
        condition={showScreen === 5.5}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>VIEW / EDIT EQUIPMENT</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <h6>Physical Condition</h6>
                      </Col>
                      <Col>
                        <Button className='edit'>&emsp;&emsp; Edit </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Serial Number</Form.Label>
                          <FormControl
                            name='serialNumber'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.serialNumber || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <Row>
                      <Form.Group className='vertical-align'>
                        <Form.Label>Item Description</Form.Label>
                        <FormControl
                          name='description'
                          onChange={handleChange}
                          placeholder='Enter Here'
                          className='border-seven'
                          value={formData?.description || ''}
                        />
                      </Form.Group>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Location</Form.Label>
                          <FormControl
                            name='location'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.location || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Condition</Form.Label>
                          <FormControl
                            name='condition'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.condition || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Years of Service Left</Form.Label>
                          <FormControl
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            name='yearsOfServiceLeft'
                            value={formData?.yearsOfServiceLeft || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <h6>Financial Status</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Initial Value</Form.Label>
                          <FormControl
                            name='initialValue'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.initialValue || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Down Payment</Form.Label>
                          <FormControl
                            name='downpayment'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.downpayment || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Date Purchase or Leased</Form.Label>
                          <FormControl
                            name='datePurchased'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.datePurchased || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Loan term in years</Form.Label>
                          <FormControl
                            name='loanTermInYears'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.loanTermInYears || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Loan rate</Form.Label>
                          <FormControl
                            name='loanRate'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.loanRate || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Monthly Payment</Form.Label>
                          <FormControl
                            name='monthlyPayment'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.monthlyPayment || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Monthly Operating Cost</Form.Label>
                          <FormControl
                            name='monthlyOperatingCost'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.monthlyOperatingCost || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Total Monthly Cost</Form.Label>
                          <FormControl
                            name='totalMonthlyCost'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.totalMonthlyCost || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Expected Value at End of Loan Term</Form.Label>
                          <FormControl
                            name='expectedValue'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.expectedValue || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Annual Straight-Line Depreciation</Form.Label>
                          <FormControl
                            name='annualStraightLineDepreciation'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.annualStraightLineDepreciation || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Monthly Straight-Line Depreciation</Form.Label>
                          <FormControl
                            name='monthlyStraightLineDepreciation'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.monthlyStraightLineDepreciation || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Current Value</Form.Label>
                          <FormControl
                            name='currentValue'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.currentValue || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                </div>
                <br />
                <br />

                <div className='details-footer'>
                  <Button className='save'>Save Changes</Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* Add Inventory */}
      <ConditionalRender
        condition={showScreen === 6}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ADD INVENTORY</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Asset No.<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='assetNumber'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.assetNumber || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Row>
                      <Form.Group className='vertical-align'>
                        <Form.Label>Item Description<span className='asterisk'> *</span></Form.Label>
                        <FormControl
                          name='description'
                          onChange={handleChange}
                          placeholder='Enter Here'
                          className='border-seven'
                          value={formData?.description || ''}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Purchase Price<span className='asterisk'> *</span></Form.Label>
                          <Form.Select name='purchasePrice'>
                            <option value='' hidden>
                              -Select-
                            </option>
                            <option value='Option 1'>Option 1</option>
                            <option value='Option 2'>Option 2</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Acquisition Date<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            type='date'
                            name='acquisitionDate'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.acquisitionDate || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Location<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='location'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.location || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Vendor<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='vendor'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.vendor || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>PO Number<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='poNumber'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.poNumber || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br /> <br />
                  </div>
                </div>

                <div className='details-footer'>
                  <Button
                    disabled={addInventoryItemLogs?.isLoading}
                    onClick={handleSave}
                    className='save'
                  >
                    Save
                  </Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* View/Edit Inventory */}
      <ConditionalRender
        condition={showScreen === 6.5}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>VIEW / EDIT INVENTORY</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Asset No.</Form.Label>
                          <FormControl
                            name='assetNumber'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.assetNumber || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button className='edit'>&emsp;&emsp; Edit </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Group className='vertical-align'>
                        <Form.Label>Item Description</Form.Label>
                        <FormControl
                          name='description'
                          onChange={handleChange}
                          placeholder='Enter Here'
                          className='border-seven'
                          value={formData?.description || ''}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Purchase Price</Form.Label>
                          <FormControl
                            name='purchasePrice'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.purchasePrice || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Acquisition Date</Form.Label>
                          <FormControl
                            name='acquisitionDate'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.acquisitionDate || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Location</Form.Label>
                          <FormControl
                            name='location'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.location || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Vendor</Form.Label>
                          <FormControl
                            name='vendor'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.vendor || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>PO Number</Form.Label>
                          <FormControl
                            name='poNumber'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.poNumber || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br /> <br />
                  </div>
                </div>

                <div className='details-footer'>
                  <Button className='save'>Save Changes</Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* Add Depreciation */}
      <ConditionalRender
        condition={showScreen === 7}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ADD DEPRECIATION</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Depreciate to Date<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            type='date'
                            name='depreciationDate'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.depreciationDate || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Asset Type<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='assetType'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.assetType || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Number<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='number'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.number || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Class<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='class'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.class || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Block<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='block'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.block || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br /> <br />
                  </div>
                </div>

                <div className='details-footer'>
                  <Button
                    disabled={addDepreciationItemLogs?.isLoading}
                    onClick={handleSave}
                    className='save'
                  >
                    Save
                  </Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* View/Edit Depreciation */}
      <ConditionalRender
        condition={showScreen === 7.5}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>VIEW / EDIT DEPRECIATION</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Depreciate to Date</Form.Label>
                          <FormControl
                            name='depreciationDate'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.depreciationDate || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button className='edit'>&emsp;&emsp; Edit </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Asset Type</Form.Label>
                          <FormControl
                            name='assetType'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.assetType || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Number</Form.Label>
                          <FormControl
                            name='number'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.number || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Class</Form.Label>
                          <FormControl
                            name='class'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.class || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Block</Form.Label>
                          <FormControl
                            name='block'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.block || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br /> <br />
                  </div>
                </div>

                <div className='details-footer'>
                  <Button className='save'>Save Changes</Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* Add Entry Date */}
      <ConditionalRender
        condition={showScreen === 8}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>ADD ENTRY DATE</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Entry Date<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            type='date'
                            name='entryDate'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.entryDate || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Amount<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='amount'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.amount || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Location<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='location'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.location || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Deep Expense<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='deepExpense'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.deepExpense || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Acc Depreciation<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='accDepreciation'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.accDepreciation || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <h6>Maintenance</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Asset No.<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='assetNo'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.assetNo || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Description<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='description'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.description || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Asset G/L<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='assetGL'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.assetGL || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Purchase Price<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='purchasedPrice'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.purchasedPrice || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Depreciation Method<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='depreciationMethod'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.depreciationMethod || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Depreciation Date<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            type='date'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            name='depreciationDate'
                            value={formData?.depreciationDate || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Years<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='years'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.years || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Year Convention<span className='asterisk'> *</span></Form.Label>
                          <FormControl
                            name='yearConvention'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.yearConvention || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br /> <br />
                  </div>
                </div>

                <div className='details-footer'>
                  <Button
                    disabled={addEntryDateItemLogs?.isLoading}
                    onClick={handleSave}
                    className='save'
                  >
                    Save
                  </Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* View/Edit Entry Date */}
      <ConditionalRender
        condition={showScreen === 8.5}
        renderIf={
          <div className='credentials-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>VIEW / EDIT ENTRY DATE</Form.Label>
              </Form.Group>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0'>
              <div className='credentials-details'>
                <div className='details-content'>
                  <div className='main-content'>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Entry Date</Form.Label>
                          <FormControl
                            name='entryDate'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.entryDate || ''}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button className='edit'>&emsp;&emsp; Edit </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Amount</Form.Label>
                          <FormControl
                            name='amount'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.amount || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Location</Form.Label>
                          <FormControl
                            name='location'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.location || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Deep Expense</Form.Label>
                          <FormControl
                            name='deepExpense'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.deepExpense || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Acc Depreciation</Form.Label>
                          <FormControl
                            name='accDepreciation'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.accDepreciation || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <h6>Maintenance</h6>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Asset No.</Form.Label>
                          <FormControl
                            name='assetNo'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.assetNo || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Description</Form.Label>
                          <FormControl
                            name='description'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.description || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Asset G/L</Form.Label>
                          <FormControl
                            name='assetGL'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.assetGL || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Purchase Price</Form.Label>
                          <FormControl
                            name='purchasedPrice'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.purchasedPrice || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Depreciation Method</Form.Label>
                          <FormControl
                            name='depreciationMethod'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.depreciationMethod || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Depreciation Date</Form.Label>
                          <FormControl
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            name='depreciationDate'
                            value={formData?.depreciationDate || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Years</Form.Label>
                          <FormControl
                            name='years'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.years || ''}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='vertical-align'>
                          <Form.Label>Year Convention</Form.Label>
                          <FormControl
                            name='yearConvention'
                            onChange={handleChange}
                            placeholder='Enter Here'
                            className='border-seven'
                            value={formData?.yearConvention || ''}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br /> <br />
                  </div>
                </div>

                <div className='details-footer'>
                  <Button className='save'>Save Changes</Button>
                  <Button onClick={() => setShowScreen(1)} className='cancel'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default FixedAssetContainer;
