/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import async from 'async';

export const showReport = createAsyncThunk('report/', async (params = '', { rejectWithValue }) => {
  return await axios
    .get(`/report/?type=${params.type}&year=${params.year}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

/* STAFFING PATTERN */
export const showReportsACL = createAsyncThunk(
  'report/staffing',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/reports?year=${params.year}`,
      baseURL: process.env.REACT_APP_API_BASE_ACL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });

    // return await axios
    //   .get(`/acl/api/reports?year=${params.year}`)
    //   .then((response) => {
    //     return response.data;
    //   })
    //   .catch((error) => {
    //     return rejectWithValue(error.response.data);
    //   });
  },
);

export const createReport = createAsyncThunk(
  'createReport',
  async (payload, { rejectWithValue }) => {
    return await axios
      .post(
        '/report',
        { year: payload.year, type: payload.type },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateDoh = createAsyncThunk('updateDoh', async (params, { rejectWithValue }) => {
  return await axios
    .put(
      `/report/doh/${params.id}`,
      { ...params },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.status;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const updateDohCallback = createAsyncThunk(
  'updateDohCallback',
  async (params, { rejectWithValue }) => {
    return await axios
      .get(`/report/show/${params.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const dohReportList = createAsyncThunk('reportList', async (id, { rejectWithValue }) => {
  return await axios
    .get(`/report/doh/${id}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const sendDoh = createAsyncThunk('sendDoh', async (id, { rejectWithValue }) => {
  return await axios
    .get(`${process.env.REACT_APP_API_BASE_ACL}/api/reports/${id}/send-doh`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.status;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
