/* eslint-disable no-unused-vars */
// Packages
import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import NetIncreaseList from './components/NetIncreaseList';
import CashInOutList from './components/CashInOutList';

// CSS
import './index.scss';
import NetIncreaseCashFlowStatementModal from './components/Modal/NetIncreaseCashFlowStatementModal';
import { useDispatch, useSelector } from 'react-redux';
import { getCashFlow, selectCashFlow } from 'reducers/accountingReducer';
import CashInOutModal from './components/Modal/CashInOutModal';

// Main Component
const CashFlowStatement = () => {
  const [isDate, setIsDate] = useState('');
  const [showModal, setShowModal] = useState({
    isShowForm: false,
    assetId: null,
    data: null,
  });
  const [showModal2, setShowModal2] = useState({
    isShowForm: false,
    assetId: null,
    data: null,
  });

  const cashFlowData = useSelector(selectCashFlow);

  const dispatch = useDispatch();

  const handleChangeDate = (e) => {
    const value = e.target.value;
    setIsDate(value);
    const params = {
      search: value,
    };
    dispatch(getCashFlow(params));
  };

  /*  useEffect(() => {
    dispatch(getCashFlow());
  }, []); */

  return (
    <div className='cash-flow-statement'>
      <div className='cash-flow-details'>
        <Row className='mb-4'>
          <Col sm={3}>
            <Form.Group className='inline-label'>
              <Form.Label>Date</Form.Label>
              <Form.Control type='date' placeholder='Enter Here' onChange={handleChangeDate} />
            </Form.Group>
          </Col>
        </Row>
        {isDate && (
          <Row className='mb-4'>
            <Col>
              {cashFlowData.length > 0 && (
                <>
                  <NetIncreaseList
                    date={isDate}
                    data={cashFlowData[0]}
                    setShowModal={setShowModal}
                  />
                  <CashInOutList
                    date={isDate}
                    data={cashFlowData[1]}
                    setShowModal={setShowModal2}
                  />
                </>
              )}
            </Col>
          </Row>
        )}
      </div>
      <NetIncreaseCashFlowStatementModal setShowModal={setShowModal} showModal={showModal} />
      <CashInOutModal setShowModal2={setShowModal2} showModal2={showModal2} />
    </div>
  );
};

export default CashFlowStatement;
