/* eslint-disable */
import './ViewTransactions.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

import Table from 'components/ReactTable';

import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';
import { dohTransactionStatus } from 'helpers';
import { dohReportList, selectDohReportList, updateDoh } from 'reducers/reportReducer';
import ConditionalRender from 'components/ConditionalRender';
import { toast } from 'react-toastify';
import { updateDohCallback } from '../../../../reducers/thunks/reportThunks';

const ViewTransactions = ({ show, onHide, onClose }) => {
  const input = useParams();
  const dispatch = useDispatch();
  const state = useSelector(selectDohReportList);

  const [report, setReport] = useState([]);

  useEffect(() => {
    dispatch(dohReportList(typeof input.id === 'undefined' ? '' : input.id));
  }, []);

  useEffect(() => {
    setReport(state);
  }, [state]);

  const tableColumns = useMemo(
    () => [
      { Header: 'Report Type', accessor: 'name' },
      { Header: 'Sent by', accessor: '' },
      { Header: 'Received by', accessor: '' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = useMemo(
    () =>
      report?.map((dd) => ({
        id: dd.id,
        name: dd.name,
        type: dd.short_code,
        status: dohTransactionStatus(dd.status),
      })),
    [report],
  );

  const update = async (value) => {
    const response = await dispatch(updateDoh({ type: 'hospital', id: value.id }));

    const newArrReport = [...report];
    const index = newArrReport.findIndex((obj) => obj.id === response?.meta?.arg?.id);
    newArrReport[index] = {
      id: response?.meta?.arg?.id,
      status: 'In Progress',
      name: '...UPDATING',
    };
    setReport(newArrReport);
    await dispatch(updateDohCallback({ id: value.id }));
    toast.info('In Progress');
  };

  return (
    <Modal size='xl' show={show} className='view-transaction-modal' onHide={onHide}>
      <Modal.Header>
        <h6>HOSPITAL OPERATIONS REPORT STATUS</h6>
        <Button onClick={onClose}>
          <CloseSVG />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Table
          data={tableData}
          columns={tableColumns}
          customAction={(id) => {
            const row = tableData.find((dd) => dd.id === id);
            show = true;

            const buttons = [
              {
                text: 'Update',
                color: 'bg-update',
                action: () => {
                  update(row);
                },
                condition: row.status.key !== 'Complete',
              },
            ];
            return (
              <>
                {buttons.map((buttonData, id) => {
                  return (
                    <ConditionalRender
                      key={id}
                      condition={buttonData.condition}
                      renderIf={
                        <span
                          className={`badge ${buttonData?.color}`}
                          style={{ cursor: 'pointer' }}
                          onClick={buttonData?.action}
                        >
                          <p className='text-white'>{buttonData?.text}</p>
                        </span>
                      }
                    />
                  );
                })}
              </>
            );
          }}
          pagination={['BOTTOM']}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ViewTransactions;
