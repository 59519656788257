import React from 'react';

const UseStatusAction = (status) => {
  return (
    <>
      {status === 'new' && (
        <span
          className='px-3 py-1 text-white'
          style={{ backgroundColor: '#00a8ff', borderRadius: '5px' }}
        >
          {status}
        </span>
      )}
      {status === 'approved' && (
        <span
          className='px-3 py-1 text-white'
          style={{ backgroundColor: '#20C997', borderRadius: '5px' }}
        >
          {status}
        </span>
      )}
      {status === 'denied' && (
        <span
          className='px-3 py-1 text-white'
          style={{ backgroundColor: '#ff0000', borderRadius: '5px' }}
        >
          {status}
        </span>
      )}
      {status === 'completed' && (
        <span
          className='px-3 py-1 text-white'
          style={{ backgroundColor: '#02961C', borderRadius: '5px' }}
        >
          {status}
        </span>
      )}
      {status !== 'new' && status !== 'approved' && status !== 'completed' && status !== 'denied' && (
        <span
          className='px-3 py-1 text-white'
          style={{ backgroundColor: '#0069F2', borderRadius: '5px' }}
        >
          {status}
        </span>
      )}
    </>
  );
};

export default UseStatusAction;
