/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getRefillRequest,
  getReorderRequest,
  denyRequest,
  approveRequest,
  approverequesteditem,
  denyrequesteditem,
  getrefillGroups,
  forIssuanceApprove,
  forSubmitRequestReorder,
  getMaintenance,
  showMaintenanceItem,
  getGoodreceipt,
  forcompleteMaintenance,
} from './thunks/refillRequestThunk';
import _ from 'lodash';

const initialState = {
  data: [],
  item: null,
  reorderdata: [],
  reorderitem: null,
  groups: [],
  maintenanceList: [],
  maintenanceItem: null,

  goodreceiptdata: [],
  showMaintenanceItem: [],
  goodreceiptitem: null,
  logs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  getGoodreceiptlogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  getMaintenancelogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  getRefillRequestlogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  getReorderRequestlogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  // forissuancelogs
  forIssuancelogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  // tableList request deny button
  denyrequestedLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  // tableList request approve button
  approverequestLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  // Logs for Modal approverequesteditem
  approverequesteditemLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  // Logs for Modal denyrequesteditem
  denyrequesteditemLogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  // Purchase module
  forSubmitRequestReorderlogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  maintenancelogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  showMaintenanceItemlogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
  forcompleteMaintenancelogs: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'refillRequest',
  initialState,
  reducers: {},
  extraReducers: {
    [getRefillRequest.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.item = action.payload.included;
      state.getRefillRequestlogs.isSuccess = true;
      state.getRefillRequestlogs.isLoading = false;
    },
    [getRefillRequest.pending]: (state) => {
      state.getRefillRequestlogs.isSuccess = false;
      state.getRefillRequestlogs.isFailed = false;
      state.getRefillRequestlogs.error = '';
      state.getRefillRequestlogs.isLoading = true;
    },
    [getRefillRequest.rejected]: (state) => {
      state.data = [];
      state.getRefillRequestlogs.isLoading = false;
      state.getRefillRequestlogs.isFailed = true;
      state.getRefillRequestlogs.error = action?.payload?.errors[0]?.detail;
    },
    [getReorderRequest.fulfilled]: (state, action) => {
      state.reorderdata = action.payload.data;
      state.reorderitem = action.payload.included;
      state.getReorderRequestlogs.isSuccess = true;
      state.getReorderRequestlogs.isLoading = false;
    },
    [getReorderRequest.pending]: (state) => {
      state.getReorderRequestlogs.isSuccess = false;
      state.getReorderRequestlogs.isFailed = false;
      state.getReorderRequestlogs.error = '';
      state.getReorderRequestlogs.isLoading = true;
    },
    [getReorderRequest.rejected]: (state) => {
      state.data = [];
      state.getReorderRequestlogs.isLoading = false;
      state.getReorderRequestlogs.isFailed = true;
      state.getReorderRequestlogs.error = action?.payload?.errors[0]?.detail;
    },
    [denyRequest.fulfilled]: (state, action) => {
      state.denyrequestedLogs.isSuccess = true;
      state.denyrequestedLogs.isLoading = false;
    },
    [denyRequest.pending]: (state) => {
      state.denyrequestedLogs.isSuccess = false;
      state.denyrequestedLogs.isFailed = false;
      state.denyrequestedLogs.error = '';
      state.denyrequestedLogs.isLoading = true;
    },
    [denyRequest.rejected]: (state, action) => {
      state.denyrequestedLogs.isLoading = false;
      state.denyrequestedLogs.isFailed = true;
      state.denyrequestedLogs.error = action?.payload?.errors[0]?.detail;
    },
    [approveRequest.fulfilled]: (state, action) => {
      state.approverequestLogs.isSuccess = true;
      state.approverequestLogs.isLoading = false;
    },
    [approveRequest.pending]: (state) => {
      state.approverequestLogs.isSuccess = false;
      state.approverequestLogs.isFailed = false;
      state.approverequestLogs.error = '';
      state.approverequestLogs.isLoading = true;
    },
    [approveRequest.rejected]: (state, action) => {
      state.approverequestLogs.isLoading = false;
      state.approverequestLogs.isFailed = true;
      state.approverequestLogs.error = action?.payload?.errors[0]?.detail;
    },
    [approverequesteditem.fulfilled]: (state, action) => {
      state.approverequesteditemLogs.isSuccess = true;
      state.approverequesteditemLogs.isLoading = false;
    },
    [approverequesteditem.pending]: (state) => {
      state.approverequesteditemLogs.isSuccess = false;
      state.approverequesteditemLogs.isFailed = false;
      state.approverequesteditemLogs.error = '';
      state.approverequesteditemLogs.isLoading = true;
    },
    [approverequesteditem.rejected]: (state, action) => {
      state.approverequesteditemLogs.isLoading = false;
      state.approverequesteditemLogs.isFailed = true;
      state.approverequesteditemLogs.error = action?.payload?.errors[0]?.detail;
    },
    [denyrequesteditem.fulfilled]: (state, action) => {
      state.denyrequesteditemLogs.isSuccess = true;
      state.denyrequesteditemLogs.isLoading = false;
    },
    [denyrequesteditem.pending]: (state) => {
      state.denyrequesteditemLogs.isSuccess = false;
      state.denyrequesteditemLogs.isFailed = false;
      state.denyrequesteditemLogs.error = '';
      state.denyrequesteditemLogs.isLoading = true;
    },
    [denyrequesteditem.rejected]: (state, action) => {
      state.denyrequesteditemLogs.isLoading = false;
      state.denyrequesteditemLogs.isFailed = true;
      state.denyrequesteditemLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getrefillGroups.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.groups = action.payload.data;
    },
    [getrefillGroups.pending]: (state) => {
      state.logs.isLoading = true;
    },
    [getrefillGroups.rejected]: (state) => {
      state.logs.isLoading = true;
      state.groups = [];
    },
    [forIssuanceApprove.fulfilled]: (state, action) => {
      state.forIssuancelogs.isSuccess = true;
      state.forIssuancelogs.isLoading = false;
    },
    [forIssuanceApprove.pending]: (state) => {
      state.forIssuancelogs.isSuccess = false;
      state.forIssuancelogs.isFailed = false;
      state.forIssuancelogs.error = '';
      state.forIssuancelogs.isLoading = true;
    },
    [forIssuanceApprove.rejected]: (state, action) => {
      state.forIssuancelogs.isLoading = false;
      state.forIssuancelogs.isFailed = true;
      state.forIssuancelogs.error = action?.payload?.error[0]?.detail;
    },
    // Purchase Module
    [forSubmitRequestReorder.fulfilled]: (state, action) => {
      state.forSubmitRequestReorderlogs.isSuccess = true;
      state.forSubmitRequestReorderlogs.isLoading = false;
    },
    [forSubmitRequestReorder.pending]: (state) => {
      state.forSubmitRequestReorderlogs.isSuccess = false;
      state.forSubmitRequestReorderlogs.isFailed = false;
      state.forSubmitRequestReorderlogs.error = '';
      state.forSubmitRequestReorderlogs.isLoading = true;
    },
    [forSubmitRequestReorder.rejected]: (state, action) => {
      state.forSubmitRequestReorderlogs.isLoading = false;
      state.forSubmitRequestReorderlogs.isFailed = true;
      state.forSubmitRequestReorderlogs.error = action?.payload?.error[0]?.detail;
    },
    //maintenance module
    [getMaintenance.fulfilled]: (state, action) => {
      state.maintenancelogs.isSuccess = true;
      state.maintenancelogs.isLoading = false;
      state.maintenanceList = action.payload.data;
      state.maintenanceItem = action.payload.included;
    },
    [getMaintenance.pending]: (state) => {
      state.maintenancelogs.isSuccess = false;
      state.maintenancelogs.isFailed = false;
      state.maintenancelogs.error = '';
      state.maintenancelogs.isLoading = true;
    },
    [getMaintenance.rejected]: (state, action) => {
      state.maintenancelogs.isLoading = false;
      state.maintenancelogs.isFailed = true;
      state.maintenancelogs.error = action?.payload?.error[0]?.detail;
    },
    [showMaintenanceItem.fulfilled]: (state, action) => {
      state.showMaintenanceItemlogs.isSuccess = true;
      state.showMaintenanceItemlogs.isLoading = false;
      state.showMaintenanceItem = action.payload;
    },
    [showMaintenanceItem.pending]: (state) => {
      state.showMaintenanceItemlogs.isSuccess = false;
      state.showMaintenanceItemlogs.isFailed = false;
      state.showMaintenanceItemlogs.error = '';
      state.showMaintenanceItemlogs.isLoading = true;
    },
    [showMaintenanceItem.rejected]: (state, action) => {
      state.showMaintenanceItemlogs.isLoading = false;
      state.showMaintenanceItemlogs.isFailed = true;
      state.showMaintenanceItemlogs.error = action?.payload?.error[0]?.detail;
    },
    [getGoodreceipt.fulfilled]: (state, action) => {
      state.getGoodreceiptlogs.isSuccess = true;
      state.getGoodreceiptlogs.isLoading = false;
      state.goodreceiptdata = action.payload.data;
      state.goodreceiptitem = action.payload.included;
    },
    [getGoodreceipt.pending]: (state) => {
      state.getGoodreceiptlogs.isSuccess = false;
      state.getGoodreceiptlogs.isFailed = false;
      state.getGoodreceiptlogs.error = '';
      state.getGoodreceiptlogs.isLoading = true;
    },
    [getGoodreceipt.rejected]: (state, action) => {
      state.getGoodreceiptlogs.isLoading = false;
      state.getGoodreceiptlogs.isFailed = true;
      state.getGoodreceiptlogs.error = action?.payload?.error[0]?.detail;
    },
    [forcompleteMaintenance.fulfilled]: (state, action) => {
      state.forcompleteMaintenancelogs.isSuccess = true;
      state.forcompleteMaintenancelogs.isLoading = false;
      state.goodreceiptdata = action.payload.data;
      state.goodreceiptitem = action.payload.included;
    },
    [forcompleteMaintenance.pending]: (state) => {
      state.forcompleteMaintenancelogs.isSuccess = false;
      state.forcompleteMaintenancelogs.isFailed = false;
      state.forcompleteMaintenancelogs.error = '';
      state.forcompleteMaintenancelogs.isLoading = true;
    },
    [forcompleteMaintenance.rejected]: (state, action) => {
      console.log(action);
      state.forcompleteMaintenancelogs.isLoading = false;
      state.forcompleteMaintenancelogs.isFailed = true;
      state.forcompleteMaintenancelogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: refillRequestActions, reducer: refillRequestReducer } = slice;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.refillRequest || initialState;

export const selectrefillRequest = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState,
);

export const selectGroups = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.groups,
);
export const selectrefillRequestData = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.data,
);
export const selectreorderRequestData = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.reorderdata,
);
export const selectrefillRequestItem = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.item,
);
export const selectreorderRequestItem = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.reorderitem,
);
export const selectInventoryLogs = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.logs,
);
export const selectMaintenance = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.maintenanceList,
);
export const selectMaintenanceItems = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.maintenanceItem,
);
export const selectMaintenanceItem = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.showMaintenanceItem,
);
export const selectGoodreceiptData = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.goodreceiptdata,
);
export const selectGoodreceiptItem = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.goodreceiptitem,
);

/* --------------------------------------------------------------------------------- */

export {
  getRefillRequest,
  denyRequest,
  approveRequest,
  approverequesteditem,
  denyrequesteditem,
  getrefillGroups,
  forIssuanceApprove,
  forSubmitRequestReorder,
  getMaintenance,
  showMaintenanceItem,
  getReorderRequest,
  getGoodreceipt,
  forcompleteMaintenance,
};
