/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import { Row, Col, Form, Button, FormControl, InputGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// import components below
import FormUI from 'components/UI/FormUI';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
import { getAppointment } from 'reducers/appointmentReducer';
import axios from 'axios';

// import assets below
import './Filter.scss';

// main component
const AppointmentListFilter = ({ setState }) => {

  const [searchWordDoctor, setSearchWordDoctor] = useState('');
  const [searchWordPatient, setSearchWordPatient] = useState('');

  const dispatch = useDispatch();
  const [departments, setDepartments] = useState([]);

  // filter params
  const [appointmentDate, setAppointmentDate] = useState([]);
  const [doctor, setDoctor] = useState('');
  const [department, setDepartment] = useState('');
  const [serviceType, setServiceType] = useState('');

  const [listOfDoctors, setListOfDoctors] = useState([]);

  /** filter by patient's name */
  const onChangePatientNameHandler = (event) => {
    const searchedWord = event.target.value;
    setSearchWordPatient(event.target.value);
    const newFilter = listOfDoctors.filter((value) => {
      return value.patient.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(listOfDoctors);
    }
  };

  /** filter by doctor's name */
  const onChangeHandler = (event) => {
    setSearchWordDoctor(event.target.value);
    const searchedWord = event.target.value;
    const newFilter = listOfDoctors.filter((value) => {
      return value.name_of_doctor.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(listOfDoctors);
    }
  };

  function changeDepartment(e) {
    setDepartment(e.target.value);
  }

  const [filter, setFilter] = useState({
    appointment_date: '',
    name_of_doctor: '',
    services_type: '',
    department: '',
    full_name: '',
  });

  const resetFilter = () => {
    setState(listOfDoctors);
    setDepartment('');
    setAppointmentDate('');
    setServiceType('');
    setSearchWordDoctor('');
    setSearchWordPatient('');
  };

  const search = () => {
    axios.get('/appointment', {
      params: {
        appointment_date: appointmentDate,
        appointment_status: status,
        department: department,
        name_of_doctor: doctor,
        services_type: serviceType,
      },
    }).then((response) => {
      setState(response.data.data.appointments);
    }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  useEffect(() => {
    /** Get departments for dropdown */
    axios
      .get('/public/department')
      .then((response) => {
        setDepartments(response.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    /** Get departments for dropdown */
    axios
      .get('/appointment')
      .then((response) => {
        setListOfDoctors(response.data.data.appointments);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    dispatch(getAppointment());
  }, []);

  return (
    <div className='appointmentListFilter'>
      <FormUI>
        <Row className='d-flex align-items-end'>
          <Col md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Appointment Date</Form.Label>
              <Form.Control type='date' value={appointmentDate} onChange={(event) => { setAppointmentDate(event.target.value); }} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Doctor / Resource</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Here'
                onChange={onChangeHandler}
                value={searchWordDoctor}
              />

            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Select Department</Form.Label>
              <Form.Select value={department} onChange={changeDepartment}>
                <option disabled value=''>-Select-</option>
                {departments && departments.length > 0 && departments.map((item, key) => {
                  return (
                    <option value={item.name}>{item.name}</option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Services</Form.Label>
              <Form.Select value={serviceType} onChange={(e) => setServiceType(e.target.value)}>
                <option value=''>- Select -</option>
                <option value={'Clinic Consultation'}>Clinic Consultation</option>
                <option value={'Clinic Packages'}>Clinic Packages</option>
                <option value={'Clinic Teleconsultation'}>Clinic Teleconsultation</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Search Patient</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-white'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-white'
                  placeholder='Search Patient Name'
                  onChange={onChangePatientNameHandler}
                  value={searchWordPatient}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <div className='buttonWrapper'>
            <Button variant='search' onClick={() => search()}>
              SEARCH
            </Button>
            <br />
            <Button variant='clear' onClick={resetFilter}>RESET</Button>
          </div>
        </Row>
      </FormUI>
    </div>
  );
};

export default AppointmentListFilter;
