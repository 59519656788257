/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages 
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormControl, Button } from 'react-bootstrap';

// main component
const initialFormData = {
  amount: '',
  remarks: '',
  loan_type: '',
  deduct_from: '',
  date_of_loan: '',
  interest_rate: '',
  number_of_installments: '',
  demand_promissory_note: false,
  monthly_installment_amount: '',
};

const index = ({ setShowScreen }) => {
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleCheckbox = (e) => {
    const { checked, name } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: checked,
      };
    });
  };

  return (
    <div className='loans-container shadow-sm'>
      <div className='box-container blue'>
        <Form.Group className='inline-group'>
          <Form.Label>ADD NEW RECORD</Form.Label>
        </Form.Group>
      </div>

      <div className='box-container'>
        <div className='add-record'>
          <h4 className='mb-3'>Loan Details</h4>

          <Row className='mb-3'>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Loan Type</Form.Label>

                <FormControl name='loan_type' onChange={handleChange} value={formData?.loan_type} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Date of Loan</Form.Label>

                <FormControl
                  type='date'
                  name='date_of_loan'
                  onChange={handleChange}
                  value={formData?.date_of_loan}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Deduct From</Form.Label>

                <Form.Select
                  name='deduct_from'
                  onChange={handleChange}
                  value={formData?.deduct_from}
                >
                  <option value='' hidden>
                    - Select -
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Number of Installments</Form.Label>

                <FormControl
                  type='number'
                  name='number_of_installments'
                  onChange={handleChange}
                  value={formData?.number_of_installments}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Amount</Form.Label>

                <FormControl
                  type='number'
                  name='amount'
                  onChange={handleChange}
                  value={formData?.amount}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Interest Rate</Form.Label>

                <Form.Select
                  name='interest_rate'
                  onChange={handleChange}
                  value={formData?.interest_rate}
                >
                  <option value='' hidden>
                    - Select -
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col>
              <h6>Installment Details</h6>

              <Form.Group className='normal-group'>
                <Form.Label>Monthly Installments Amount</Form.Label>

                <FormControl
                  type='number'
                  onChange={handleChange}
                  name='monthly_installment_amount'
                  value={formData?.monthly_installment_amount}
                />
              </Form.Group>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>

          <Row className='mb-3'>
            <Col>
              <h6>Other Information</h6>

              <Form.Group className='normal-group inline-check'>
                <Form.Check
                  name='demand_promissory_note'
                  onChange={handleCheckbox}
                  checked={formData?.demand_promissory_note}
                />
                <Form.Label>Demand Promissory Note</Form.Label>
              </Form.Group>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>

          <Row className='mb-5'>
            <Col>
              <Form.Group className='normal-group'>
                <Form.Label>Remarks</Form.Label>

                <FormControl
                  onChange={handleChange}
                  name='reason_of_advance'
                  value={formData?.reason_of_advance}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className='content-footer'>
              <Button className='save-button'>Save</Button>

              <Button
                className='back-button'
                onClick={() => {
                  setShowScreen(1);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default index;
