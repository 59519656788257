/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// main components below
import InventoryListTable from './Table';
import SearchInventoryFilter from './Filter';

import './style.scss';

// main component
const InventoryItems = ({ departmentID, refreshData, setRefreshData, activeKey }) => {
  const [searchData, setSearchData] = useState({
    search: '',
  });

  return (
    <div className='admissions-inventory-tab-inventory-items'>
      <SearchInventoryFilter
        searchData={searchData}
        departmentID={departmentID}
        setSearchData={setSearchData}
      />

      <InventoryListTable
        activeKey={activeKey}
        searchData={searchData}
        refreshData={refreshData}
        departmentID={departmentID}
        setSearchData={setSearchData}
        setRefreshData={setRefreshData}
      />
    </div>
  );
};

InventoryItems.defaultProps = {
  departmentID: '',
};

InventoryItems.propTypes = {
  departmentID: PropTypes.string,
};

export default InventoryItems;
