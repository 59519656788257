/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchRoomFilter = ({ setState, setShowProfile }) => {
  const [rooms, setRooms] = useState([]);
  const [searchData, setSearchData] = useState({
    id: '',
    room_type: '',
    floor: '',
    bed: '',
  });

  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    const newFilter = rooms.filter((value) => {
      return (
        value?.type?.toLowerCase().includes(searchedWord.toLowerCase()) ||
        value?.floor?.toLowerCase().includes(searchedWord.toLowerCase()) ||
        value?.room?.toLowerCase().includes(searchedWord.toLowerCase()) ||
        value?.bed?.toLowerCase().includes(searchedWord.toLowerCase()) ||
        value?.status?.toLowerCase().includes(searchedWord.toLowerCase()) ||
        value?.price === searchedWord
      );
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(rooms);
    }
  };

  const handleChange = (key) => (e) => {
    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const search = () => {
    axios
      .get('/room', { params: searchData })
      .then((response) => {
        setState(response.data.data.room_list);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  const resetFilter = async () => {
    setSearchData({
      bed: '',
      type: '',
      floor: '',
    });

    await axios.get('/room').then((response) => {
      setState(response.data.data.room_list);
    });
  };

  useEffect(() => {
    axios.get('/room').then((response) => {
      setRooms(response.data.data.room_list);
    });
  }, []);

  return (
    <div className='searchRoomFilter'>
      <FormUI>
        <Row>
          <Col>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Basic Search</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  placeholder='Enter here'
                  value={searchData.basic_search}
                  onChange={onChangeHandler}
                />
                {/* <Button variant='search'>SEARCH</Button> */}
              </InputGroup>
            </Form.Group>
          </Col>

          <Col>
            <div className='buttonWrapper'>
              <Button
                className='btn btn-light-blue'
                onClick={() => {
                  setShowProfile((prevState) => {
                    return {
                      ...prevState,
                      createRoom: true,
                      isDefault: false,
                    };
                  });
                }}
              >
                <span>Create Room</span>
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Accordion className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Advance Search</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>Room Type</Form.Label>
                        <Form.Select onChange={handleChange('type')} value={searchData.type}>
                          <option value='' hidden>
                            -Select-
                          </option>
                          <option value='Private'>Private</option>
                          <option value='Suite'>Suite</option>
                          <option value='Ward'>Ward</option>
                          <option value='Delivery Room'>Delivery Room</option>
                          <option value='Operating Room'>Operating Room</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Floor</Form.Label>
                        <Form.Select onChange={handleChange('floor')} value={searchData.floor}>
                          <option>-Select-</option>
                          <option value='1st'>1st</option>
                          <option value='2nd'>2nd</option>
                          <option value='3rd'>3rd</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {/* <Col>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Unit</Form.Label>
                        <Form.Select onChange={handleChange('unit')}>
                          <option>-Select-</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                    <Col>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Bed</Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Enter Here'
                          onChange={handleChange('bed')}
                          value={searchData.bed}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className='buttonWrapper'>
                        <Button variant='search' onClick={() => search()}>
                          SEARCH
                        </Button>

                        <Button variant='clear' onClick={() => resetFilter()}>
                          CLEAR
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

SearchRoomFilter.defaultProps = {
  setState: () => {},
  setShowProfile: () => {},
  handleTabOnChange: () => {},
};

SearchRoomFilter.propTypes = {
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
  handleTabOnChange: PropTypes.func,
};

export default SearchRoomFilter;
