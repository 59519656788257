/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, FormControl, Button, Modal } from 'react-bootstrap';

import seturlParams from 'helpers/seturlParams';
import convertToMMddYYYY from 'helpers/date-converter';
import { selectDepartmentInventory } from 'reducers/departmentInventoryReducer';

// Import assets below
import './modal.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import ConditionalRender from 'components/ConditionalRender';

const ViewItemsModal = ({ showModal, setShowModal }) => {
  return (
    <Modal
      size='lg'
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      aria-labelledby='Returned Details'
      className='add-schedule-shifting-modal admissions-inventory-view-items-details-modal item-details'
    >
      <Modal.Body>
        <div className='add-new-item-request'>
          <div className='d-flex header'>
            <p>RETURNED DETAILS</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => setShowModal(false)}
            />
          </div>
        </div>

        <div className='px-5 content-container'>
          <Row>
            <Col>
              <h3>PATIENT INFORMATION</h3>
              <p>
                Date / Time Returned : <span>11-24-2022 10:15 AM</span>
              </p>
              <p>
                Patient ID : <span>0001</span>
              </p>
              <p>
                Patient Name : <span>Juan Dela Cruz</span>
              </p>
            </Col>
            <Col>
              <h3>ITEM INFORMATION</h3>
              <p>
                Item Code : <span>PHA00001</span>
              </p>
              <p>
                Item Name : <span>Biogesic</span>
              </p>
              <p>
                Department : <span>Pharmacy</span>
              </p>
            </Col>
          </Row>
        </div>

        <hr />

        <div className='px-5 content-container'>
          <Row>
            <Col className='returned-container'>
              <h3>REASON TO RETURNED</h3>

              <p className='reason'>Excess of quantity</p>

              <div className='mb-5 form-group'>
                <h3>REMARKS</h3>
                <p>Lorem ipsum dolor</p>
              </div>

              <div className='form-group'>
                <h3>RETURNED BY</h3>
                <p className='returned-by'>Yassi Gonzales</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className='px-5'>
          <div className='mb-5'>
            <Row>
              <Col className='form-buttons'>
                <Button className='close-button' onClick={() => setShowModal(false)}>
                  Close
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewItemsModal;
