// import components below
import RequisitionTabs from './Tabs';

// import assets below
import './style.scss';

// main component
const Requisition = () => {
  return (
    <div className='requisition'>
      <RequisitionTabs />
    </div>
  );
};

export default Requisition;
