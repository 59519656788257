/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getGenericCredentialList, showGenericCredentialItems, addGenericCredentialItems , getWindowCredentialList, addWindowCredentialItems, updateGenericCredential } from './thunks/credentialsManagementThunks';

const initialLogs = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  error: null,
};

const initialState = {
  // For Generic Credentials List
  data: [],
  item: null,
  logs: initialLogs,

  // For Add Generic Credentials Record
  addGenericCredentialItemLogs: initialLogs,
  
  // For Item Update Generic
  updateGenericCredentialData: {},
  updateGenericCredentialLogs: initialLogs,
  
  // For Window Credentials List
  windowCredentialData: [],
  windowCredentialItem: [],
  windowCredentialLogs: initialLogs,

  // For Add Window Credentials Record
  addWindowCredentialItemLogs: initialLogs,
};

const slice = createSlice({
  name: 'credentialsManagement',
  initialState,
  reducers: {
    // reset Log State
    resetLogState: (state) => {
      state.logs = initialLogs;
      state.windowCredentialLogs = initialLogs;
    },
    resetUpdateState: (state) => {
      state.updateGenericCredentialData = {};
      state.updateGenericCredentialLogs = initialLogs;
      state.updateWindowCredentialLogs = initialLogs;
    },
    // reset Add Item State
    resetAddItemState: (state) => {
      state.addGenericCredentialItemLogs = initialLogs;
      state.addWindowCredentialItemLogs = initialLogs;
      state.showGenericCredentialItemsLogs = initialLogs;
    },
    
  },
  extraReducers: {

    // For Generic Credentials List
    [getGenericCredentialList.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
    },
    [getGenericCredentialList.pending]: (state) => {
      state.logs.isSuccess = false;
      state.logs.isFailed = false;
      state.logs.error = '';
      state.logs.isLoading = true;
    },
    [getGenericCredentialList.rejected]: (state) => {
      state.data = [];
      state.logs.isLoading = false;
      state.logs.isFailed = true;
    },
     // For Update Generic Credential List
     [updateGenericCredential.fulfilled]: (state, action) => {
      state.updateGenericCredentialData = action.payload.data;
      state.updateGenericCredentialLogs.isSuccess = true;
      state.updateGenericCredentialLogs.isLoading = false;
    },
    
    [updateGenericCredential.pending]: (state) => {
      state.updateGenericCredentialLogs.isSuccess = false;
      state.updateGenericCredentialLogs.isFailed = false;
      state.updateGenericCredentialLogs.isLoading = true;
    },
    [updateGenericCredential.rejected]: (state) => {
      state.updateGenericCredentialLogs.isLoading = false;
      state.updateGenericCredentialLogs.isFailed = true;
      state.updateGenericCredentialData.error = '';
    },
     // For Update Generic Credential List
     [showGenericCredentialItems.fulfilled]: (state, action) => {
      state.showGenericCredentialItemsLogs.isSuccess = true;
      state.showGenericCredentialItemsLogs.isLoading = false;
      state.showGenericCredentialItemsData = action.payload.data;
    },
    
    [showGenericCredentialItems.pending]: (state) => {
      state.showGenericCredentialItemsLogs.isSuccess = false;
      state.showGenericCredentialItemsLogs.isFailed = false;
      state.showGenericCredentialItemsLogs.isLoading = true;
    },
    [showGenericCredentialItems.rejected]: (state) => {
      state.showGenericCredentialItemsLogs.isLoading = false;
      state.showGenericCredentialItemsLogs.isFailed = true;
      state.showGenericCredentialItemsData.error = '';
    },
    // For Add Generic Credential Item
    [addGenericCredentialItems.fulfilled]: (state, action) => {
      state.addGenericCredentialItemLogs.isSuccess = true;
      state.addGenericCredentialItemLogs.isLoading = false;
    },
    [addGenericCredentialItems.pending]: (state) => {
      state.addGenericCredentialItemLogs.isLoading = true;
      state.addGenericCredentialItemLogs.isSuccess = false;
      state.addGenericCredentialItemLogs.isFailed = false;
      state.addGenericCredentialItemLogs.error = '';
    },
    [addGenericCredentialItems.rejected]: (state, action) => {
      state.addGenericCredentialItemLogs.isFailed = true;
      state.addGenericCredentialItemLogs.isLoading = false;
      state.addGenericCredentialItemLogs.error ='';
    },
    
    // For Window Credentials List
    [getWindowCredentialList.fulfilled]: (state, action) => {
      state.windowCredentialLogs.isSuccess = true;
      state.windowCredentialLogs.isLoading = false;
      state.windowCredentialData = action.payload.data;
    },
    [getWindowCredentialList.pending]: (state) => {
      state.windowCredentialLogs.isSuccess = false;
      state.windowCredentialLogs.isFailed = false;
      state.windowCredentialLogs.error = '';
      state.windowCredentialLogs.isLoading = true;
    },
    [getWindowCredentialList.rejected]: (state) => {
      state.windowCredentialData = [];
      state.windowCredentialLogs.isLoading = false;
      state.windowCredentialLogs.isFailed = true;
    },
    
    // For Add Window Credential Item
    [addWindowCredentialItems.fulfilled]: (state, action) => {
      state.addWindowCredentialItemLogs.isSuccess = true;
      state.addWindowCredentialItemLogs.isLoading = false;
    },
    [addWindowCredentialItems.pending]: (state) => {
      state.addWindowCredentialItemLogs.isLoading = true;
      state.addWindowCredentialItemLogs.isSuccess = false;
      state.addWindowCredentialItemLogs.isFailed = false;
      state.addWindowCredentialItemLogs.error = '';
    },
    [addWindowCredentialItems.rejected]: (state, action) => {
      state.addWindowCredentialItemLogs.isLoading = false;
      state.addWindowCredentialItemLogs.isFailed = true;
      state.addWindowCredentialItemLogs.error ='';
    },
  },
});

export const { actions: credentialsManagementActions, reducer: credentialsManagementReducer } = slice;

export const { resetLogState, resetAddItemState, resetUpdateState } = credentialsManagementActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.credentialsManagement || initialState;

export const selectCredentialsManagement = createSelector(
  [selectDomain], 
  (inventoryState) => inventoryState);

export const selectCredentialsManagementData = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.data,
);
  
export const selectCredentialsManagementItem = createSelector(
  [selectDomain],
  (inventoryState) => inventoryState.item,
);
/* --------------------------------------------------------------------------------- */

export { 
  showGenericCredentialItems, 
  getGenericCredentialList, 
  addGenericCredentialItems, 
  getWindowCredentialList, 
  addWindowCredentialItems, 
  updateGenericCredential,
};
