import { Route } from 'react-router-dom';

import ReportPage from 'pages/Reports';
import ProtectedRoute from 'components/ProtectedRoute';
import Expenses from 'pages/Reports/components/Expenses';
import Revenues from 'pages/Reports/components/Revenues';
import DailyCensus from 'pages/Reports/components/DailyCensus';
import StaffingPattern from 'pages/Reports/components/StaffingPattern';
import ViewReport from 'pages/OutpatientDepartment/components/ViewReport';
import HospitalOperation from 'pages/Reports/components/HospitalOperation';
import GeneralInformation from 'pages/Reports/components/GeneralInformation';

export default [
  <Route
    key='main'
    path='/dashboard/reports'
    element={<ProtectedRoute department={'report'} component={ReportPage} />}
  />,
  <Route
    key='general information'
    path='/dashboard/reports/general/:year'
    element={<GeneralInformation />}
  />,
  <Route
    key='hospital information'
    path='/dashboard/reports/hospital'
    element={<HospitalOperation />}
  />,
  <Route key='staffing pattern' path='/dashboard/reports/staffing' element={<StaffingPattern />} />,
  <Route key='expenses' path='/dashboard/reports/expenses/:year' element={<Expenses />} />,
  <Route key='revenues' path='/dashboard/reports/revenues/:year' element={<Revenues />} />,
  <Route key='daily census' path='/dashboard/reports/census' element={<DailyCensus />} />,
  <Route key='view report' path='/dashboard/reports/view/:type/:id' element={<ViewReport />} />,
];
