// import assets below
import './Footer.scss';

// main component
const DashboardFooter = () => {
  return (
    <div className='dashboardFooter'>
      <span>Patient Care Management</span>
      <span>2022 {process.env.REACT_APP_NAME}. All Rights Reserved.</span>
    </div>
  );
};

export default DashboardFooter;
