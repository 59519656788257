/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import { useEffect, useState } from 'react';
import { InputGroup, FormControl, Form, Button } from 'react-bootstrap';

// import components below
import FilterUI from 'components/UI/FilterUI';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const BedManagementList = ({ setState }) => {
  const [listOfRooms, setListOfRooms] = useState([]);
  const [searchData, setSearchData] = useState({
    id: '',
    bed: '',
    type: '',
    floor: '',
  });

  const handleChange = (key) => (e) => {
    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const search = (clear = false) => {
    if (clear) {
      axios
        .get('/room')
        .then((response) => {
          setState(response.data.data.room_list);
        }, [])
        .catch((err) => {
          console.log('AXIOS ERROR: ', err);
        });
    } else {
      axios
        .get('/room', { params: searchData })
        .then((response) => {
          setState(response.data.data.room_list);
        }, [])
        .catch((err) => {
          console.log('AXIOS ERROR: ', err);
        });
    }
  };

  const resetFilter = async () => {
    setSearchData({
      bed: '',
      type: '',
      floor: '',
    });

    search(true);
  };

  const handleOnChangeRoom = (event) => {
    const searchedWord = event.target.value;
    const newFilter = listOfRooms.filter((value) => {
      return value.room.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(listOfRooms);
    }
  };

  useEffect(() => {
    axios
      .get('/room')
      .then((response) => {
        setListOfRooms(response.data.data.room_list);
        // setState(response.data.data.room_list);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='bedManagementList'>
      <FilterUI>
        <Form.Group className='mb-3'>
          <Form.Label>Floor</Form.Label>
          <Form.Select onChange={handleChange('floor')} value={searchData.floor}>
            <option>- Select -</option>
            <option value='1st'>1st</option>
            <option value='2nd'>2nd</option>
            <option value='3rd'>3rd</option>
          </Form.Select>
        </Form.Group>
        {/* <Form.Group className='mb-3'>
          <Form.Label>Unit</Form.Label>
          <Form.Select>
            <option>- Select -</option>
          </Form.Select>
        </Form.Group> */}
        <Form.Group className='mb-3'>
          <Form.Label>Room Type</Form.Label>
          <Form.Select onChange={handleChange('type')} value={searchData.type}>
            <option>- Select -</option>
            <option value='Private'>Private</option>
            <option value='Suite'>Suite</option>
            <option value='Ward'>Ward</option>
            <option value='Delivery Room'>Delivery Room</option>
            <option value='Operating Room'>Operating Room</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Room</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <MagnifyingGlassSVG />
            </InputGroup.Text>
            <FormControl onChange={handleOnChangeRoom} type='search' placeholder='Search here...' />
          </InputGroup>
        </Form.Group>
        <div className='buttonWrapper mb-3'>
          <Button variant='search' onClick={() => search()}>
            Search
          </Button>
          <Button variant='reset' onClick={() => resetFilter()}>
            Reset
          </Button>
        </div>
      </FilterUI>
    </div>
  );
};

export default BedManagementList;
